export interface ScattergraphDisplayGroupScales {
    displayGroupId: number;
    name: string;
    min: number;
    max: number;
}

export interface UserSettingsWidget {
    p: number;
    e: boolean;
}

export interface UserSettings {
    markDataPoints?: boolean;
    notificationPrimaryEmail?: boolean;
    notificationSMS?: boolean;
    notificationSecondaryEmail?: boolean;
    theme?: string;
    acceptedTerms?: boolean;
    syncZoom?: boolean;
    scattergraphToolbar?: boolean;
    staticTracer?: boolean;
    showEvents?: boolean;
    includeRainLocations?: boolean;
    studyAuthorIDFilter?: string[];
    fmrTemplateIDFilter?: string[];
    displayGroupScales?: DisplayGroupScales[];
    quickSpanCumulativeRain?: UserPreferencesDateQuickSpan;
    widgetSettings?: UserSettingsWidget[];
}

export interface DisplayGroupScales {
    displayGroupId: number;
    autoScale?: boolean;
    manualMin: number;
    manualMax: number;
}

export enum UserPreferencesDateQuickSpan {
    OneDay = 0,
    OneWeek,
    OneMonth,
        LastMonth,
        ThreeMonths,
    SixMonths,
    OneYear,
    Custom
}
