import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

export const getConfigUrl: (string) => string = (url: string) => {
    return (url.endsWith('.json') ? environment.apiUrl : environment.serviceUrl) + url;
}

@Injectable()
export class Config {
    public static serviceUrl = environment.serviceUrl;
    public static apiUrl = environment.apiUrl;
    public static legacySliicerImportUrl = environment.legacySliicerImportUrl;
    public static powerBIServiceUrl = environment.powerBIServiceUrl;
    public static exportStormUrl = environment.exportStormUrl;
    public static geoserverDomain = environment.geoserverUrl;

    public static formats = {
        date: 'DD MMM, YYYY',
    };

    public static getUrl(url: string): string {
        return getConfigUrl(url);
    }

    // tslint:disable-next-line:member-ordering
    public static urls = {
        alarms: getConfigUrl('api/Alarms'),
        alarmsLite: getConfigUrl('api/Alarms/lite'),
        alarmsNotify: getConfigUrl('api/Alarms/notify'),
        anomalies: getConfigUrl('api/anomalies'),
        anomaliesCount: getConfigUrl('api/anomalies/count'),
        anomaliesSummary: getConfigUrl('api/anomalies/summary'),
        anomaliesReferesh: getConfigUrl('api/anomalies/refres'),
        batteryStatusUrl: getConfigUrl('api/BatteryStatus/locations'),
        batteryStatusTotalUrl: getConfigUrl('api/BatteryStatus/total'),
        eventDefinition: getConfigUrl('api/EventDefinition'),
        getAllEvents: getConfigUrl('api/EventDefinition/all'),
        getAllEventsbyDateRange: getConfigUrl('api/EventDefinition/span'),
        blockagePrediction: getConfigUrl('ml/BlockagePrediction/span'),
        blockagePredictionCount: getConfigUrl('ml/BlockagePrediction/count'),
        blockagePredictionDaily: getConfigUrl('ml/BlockagePrediction/summary'),
        collectsFailedCount: getConfigUrl('api/collections/count'),
        collectsHistory: getConfigUrl('api/collections/history'),
        collectsDetails: getConfigUrl('api/collections/details'),
        collectsUpcoming: getConfigUrl('api/collections/api/collect/next'),
        crowdcoreTopScore: getConfigUrl('api/Judgements/top5'),
        crowdcoreJudgements: getConfigUrl('api/Judgements'),
        crowdcoreStatus: getConfigUrl('api/Judgements/status'),
        customers: {
            get: getConfigUrl('data/customers.json'),
            pipeElements: getConfigUrl('data/pipe-elements.json'),
            flowDataGraphics: getConfigUrl('data/flow-data-graphics.json'),
            isoQLines: getConfigUrl('data/iso-q-lines.json'),
            dayStatusAnalysis: getConfigUrl('data/day-status-analysis.json'),
            analysisBatch: getConfigUrl('data/analysis-batch.json'),
            dayStausReviewers: getConfigUrl('data/day-status-reviewers.json'),
        },
        dataSummaryReportOverview: getConfigUrl('api/reports/DailySummary/v2'),
        dataSummaryReportDetails: getConfigUrl('api/reports/DailySummary/details/v2'),
        dataSummaryReportExport: getConfigUrl('api/reports/DailySummary/export'),
        dataSummaryReportGraph: getConfigUrl('api/reports/DailySummary/graph'),
        dataSummaryReportSettings: getConfigUrl('api/usersettings/dailySummary'),
        rainfallReportUserSettings: getConfigUrl('api/UserSettings/dailyrainfall'),
        email: getConfigUrl('api/emails/send'),
        flowMonitoring: getConfigUrl('api/flowmonitor'),
        FlowMonitoringConfig: getConfigUrl('api/Config/configs'),
        monitorSeries: getConfigUrl('api/Monitor/series'),
        hydrograph: getConfigUrl('data/hydrograph-sample.json'),
        installationType: `${window.location.origin}/data/installation-type.json`,
        locationDetails: getConfigUrl('data/Locations/details'),
        locationDetailsSlim: getConfigUrl('data/Locations/slim'),
        locationChannel: getConfigUrl('data/Locations/channel'),
        locationElevation: getConfigUrl('data/Locations/elevation'),
        flowBalanceDetails: getConfigUrl('api/flowbalancelocations'),
        locationConfigurationReport: getConfigUrl('api/reports/MonitorConfigurations'),
        GISServices: getConfigUrl('api/GIS'),
        geoserverUrl: environment.geoserverUrl + 'api/geoserver/',
        userPermission: getConfigUrl('api/permissions/userPermissions'),
        locationFlume: getConfigUrl('data/Locations/flume'),
        locationPipe: getConfigUrl('data/Locations/pipe'),
        locationRain: getConfigUrl('data/Locations/rain'),
        locationWeir: getConfigUrl('data/Locations/weir'),
        locations: getConfigUrl('data/Locations'),
        locationsCreate: getConfigUrl('data/Locations/create/v2'),
        locationsUpdate: getConfigUrl('data/Locations/update/v2'),
        locationsList: getConfigUrl('data/Locations/list'),
        rainLocations: getConfigUrl('data/Locations/rainlocations'),
        rainLocGroups: getConfigUrl('api/locationgroups/rainlocations'),
        monitoringPoints: getConfigUrl('data/Locations/monitoringpoints'),
        customPropNames: getConfigUrl('data/Locations/custompropertysuggestions'),
        rainfallFreq: getConfigUrl('api/RainfallFrequency/rainfalls'),
        rainfallFreqReport: getConfigUrl('api/RainfallFrequency/report'),
        rainCumulative: getConfigUrl('api/rain/cumulative'),
        locationGroup: {
            prefixUrl: 'api/customers/',
            getUrl: 'api/locationgroups',
            postUrl: 'api/locationgroups',
            deleteUrl: 'api/locationgroups/',
        },
        LocationCosmoDetails: getConfigUrl('data/Locations/details/cosmos'),
        LocationConfig: getConfigUrl('api/dashboardconfig'),
        LocationConfigMru: getConfigUrl('api/dashboardconfig/mru'),
        mapviews: getConfigUrl('data/map-views.json'),
        getCustomerUsers: 'api/users/customerusers',
        assignUsers: 'api/permissions/assigncustomerusers',
        maptypes: `${window.location.origin}/data/map-types.json`,
        monitorInstallationMapping: `${window.location.origin}/data/monitor-installation-type-mappings.json`,
        markerLocationDetails: getConfigUrl('data/locations/details/'),
        permission: getConfigUrl('api/permissions'),
        permissionv2: getConfigUrl('api/permissions/v2'),
        permissionCustomerv2: getConfigUrl('api/permissions/customer/v2'),
        refresh: getConfigUrl('api/users/refresh'),
        usersAdminUpdate: getConfigUrl('api/users/adminupdate'),
        usersAdminGet: getConfigUrl('api/users/adminget'),
        subscriptionTiers: getConfigUrl('api/permissions/subscriptionTiers/v2'),
        subscriptionsInfo: getConfigUrl('api/customers/subscriptions'),
        usersResetPassword: getConfigUrl('api/users/v2/changepwdbyadmin'),
        checkUsernameExists: getConfigUrl('api/users/DoesUserExist'),
        checkUserEmailExists: getConfigUrl('api/users/DoesUserEmailExist'),
        staticTracer: getConfigUrl('api/usersettings/staticTrace'),
        features1: getConfigUrl('api/permissions/features'),
        customerSummary: getConfigUrl('api/customers/summary'),
        customerSummaryV2: getConfigUrl('api/customers/v2/summary'),
        qstartBaseUrl: getConfigUrl('api/monitor/'),
        submissionsCount: getConfigUrl('api/Judgements/submissions/count'),
        percentfull: getConfigUrl('api/reports/PercentFull'),
        telemetryImport: getConfigUrl('api/telemetry/import'),
        telemetryImportAll: getConfigUrl('api/Telemetry/v2/import'),
        telemetryImportAllV3: getConfigUrl('api/Telemetry/v3/import'),
        confirmationImport: getConfigUrl('api/Confirmation/Import'),
        confirmationExport: getConfigUrl('api/Confirmation/Export'),
        telemetryValidation: getConfigUrl('api/telemetry/validate'),
        telemetryValidateAll: getConfigUrl('api/vault/Validate'),
        telemetryExport: getConfigUrl('api/telemetry/export'),
        allAvailableFeatures: getConfigUrl('api/permissions/allAvailableFeatures'),
        locationsImportValidate: getConfigUrl('data/Locations/validate'),
        locationsImportValidates: getConfigUrl('data/Locations/v2/validate/'),
        locationNotes: {
            put: getConfigUrl('api/locationnotes/updatenotes'),
            post: getConfigUrl('api/locationnotes/addnote'),
            get: getConfigUrl('api/locationnotes'),
            delete: getConfigUrl('api/locationnotes/notes')
        },
        vaultLocationExport: getConfigUrl('api/Telemetry/export/V2'), // I need to add space as space is available in API also
        vaultExcelExport: getConfigUrl('api/Export'),
        vaultExportSettings: getConfigUrl('api/usersettings/export'),
        locationsExport: getConfigUrl('data/Locations/export'),
        timeZone: `${window.location.origin}/data/time-zone.json`,
        uptime: getConfigUrl('api/reports/Uptime'),
        uptimeExport: getConfigUrl('api/reports/Uptime/export'),
        vaultStructure: getConfigUrl('api/vault'),
        vaultDownlodFile: getConfigUrl('api/vault/file'),
        vaultDeleteFile: getConfigUrl('api/vault/file'),
        vaultMoveFile: getConfigUrl('api/vault/files/'),
        vaultLinkShare: getConfigUrl('api/vault/urlAuthorization'),
        vaultUpload: getConfigUrl('api/vault/upload'),
        vaultUploadLocation: getConfigUrl('api/vault/uploadLocation'),
        vaultDeleteLocationFile: getConfigUrl('api/vault/location/file'),
        depthDurationUpload: getConfigUrl('api/depthdurationfrequency/import'),
        uiBuildNumber: `${window.location.origin}/data/build-numbers.json`,
        apiBuildNumber: getConfigUrl('api/application/about'),
        billingMetricsForTimeSpan: getConfigUrl('api/BillingReport/span'),
        costAllocationReportForTimeSpan: getConfigUrl('api/CostAllocationReport/costReports'),
        createFolder: getConfigUrl('api/vault/folder'),
        percentFullReport: `${window.location.origin}/data/percent-full-report.json`,
        usersSummary: getConfigUrl('api/users/summary'),
        userSync: getConfigUrl('api/users/update'),
        userSyncPermissions: `${window.location.origin}/data/user-sync-permitted.json`,
        configureRainAlert: getConfigUrl('api/Monitor/configuration/rainalert/v2?customerId='),
        configureEchoAlert: getConfigUrl('api/Monitor/configuration/echo/v2?customerId='),
        configureForeSiteUl: getConfigUrl('api/Monitor/configuration/foresite-ul?customerId='),
        configureforeSITEFlex: getConfigUrl('api/Monitor/configuration/foresite-flex?customerId='),
        locationsImport: getConfigUrl('data/Locations/import'),
        customPipeImport: getConfigUrl('data/Locations/import/custompipe'),
        downloadFile: getConfigUrl('api/vault'),
        collectSchedules: 'api/collections/schedules?customerId=',
        collectionCountForCustomer: 'api/collections/count?customerID=',
        editUser: 'api/users',
        notifications: 'api/Notifications',
        notificationDetailsAll: 'api/Notifications/notificationdetails/all',
        notificationDetails: 'api/Notifications/notificationdetails',
        notificationValidate: 'api/Notifications/notificationdetails/validate',
        locationNotificationsConfiguration: getConfigUrl('api/reports/LocationNotifications'),
        rainfallTabular: getConfigUrl('api/reports/RainTabular/total/v2'),
        totalActiveAlarms: 'api/Alarms/activetotal?customerId=',
        activeAlarms: 'api/Alarms/active?customerId=',
        hydrographData: 'api/Hydrograph/V2?customerId=',
        entityInfo: `api/Hydrograph/entityInfo`,
        alarmsType: 'api/Alarms/types',
        acknowledgeAlarms: 'api/Alarms/acknowledge?alarmID=',
        clearedAlarms: 'api/Alarms/clear?alarmID=',
        reasonedAnomalies: 'api/anomalies/reasons',
        collections: 'api/collections/location?customerId=',
        getOnlyCustomersWithLocations: 'api/customers/?getOnlyCustomersWithLocations=',
        getActiveCustomersOnlyFlag: '&getActiveCustomersOnly=',
        getActiveCustomersOnly: 'api/customers?getActiveCustomersOnly=',
        entities: 'api/entities',
        entitiesByCustomer: 'api/entities/getEntities',
        entitiesBylocations: 'api/entities/getEntitiesV2',
        entitiesAndLocations: 'data/Locations/v2/list',
        widgetPermission: 'api/permissions/widget',
        activatedMonitor: 'api/Monitor/activate?customerId=',
        scheduledCollections: 'api/collections/schedules/',
        scheduleByLocationId: 'api/collections/schedulebylocation/v2?customerId=',
        availableLocations: getConfigUrl('api/collections/schedules/availablelocs'),
        liteScheduledCollections: 'api/collections/schedules/v2?customerId=',
        apiInstallationType: {
            pipe: 'data/Locations/pipe?customerID=',
            rain: 'data/Locations/rain?customerID=',
            flume: 'data/Locations/flume?customerID=',
            weir: 'data/Locations/weir?customerID=',
            channel: 'data/Locations/channel?customerID=',
            elevation: 'data/Locations/elevation?customerID=',
            other: 'data/Locations?customerID=',
        },
        adsToken: 'api/users/token',
        features: 'api/permissions/features',
        roles: 'api/permissions/roles',
        registerUser: 'api/users/v2/register?',
        customAlarms: 'api/WetDryOverflow',
        apiKeySummary: 'api/Access?cid=',
        auditLogs: 'api/audits',
        auditReports: 'api/audits/logs',
        createCompositeLocation: 'data/Locations/composite?cid=',
        userAuthentication: getConfigUrl('api/users/login'),
        refreshToken: getConfigUrl('api/users/refreshToken'),
        resetPassword: getConfigUrl('api/users/changepassword'),
        nonAuthResetPassword: getConfigUrl('api/users/v2/changepassword'),
        generateToken: getConfigUrl('api/users/requestchangepasswordtoken'),
        nonAuthGenerateToken: getConfigUrl('api/users/v2/requestchangepasswordtoken'),
        gisToken: getConfigUrl('api/gis/basemaps/token'),
        enableDisableAlarms: 'api/WetDryOverflow/status',
        userSettingsTheme: getConfigUrl('api/usersettings/theme'),
        userSettings: getConfigUrl('api/usersettings'),
        userSettingsAdminGet: getConfigUrl('api/usersettings/adminget'),
        userSettingsAdminUpdate: getConfigUrl('api/usersettings/adminupdate'),
        dataEditReasons: getConfigUrl('api/DataEditReasons'),
        flowBalance: getConfigUrl('api/flowbalancelocations'),
        flowBalanceReport: getConfigUrl('api/flowbalancelocations/reportdata'),
        monitorCounts: getConfigUrl('api/Monitor/counts'),
        monitorNames: getConfigUrl('api/Monitor/names'),
        stormDefinition: getConfigUrl('api/StormDefinition'),
        depthDurationFrequency: getConfigUrl('api/DepthDurationFrequency'),
        WasteWaterMethod: getConfigUrl('api/WasteWaterBaseInfiltration'),
        dryDayDefinition: getConfigUrl('api/DryDayDefinition'),
        configureTritonAlert: getConfigUrl('api/Monitor/configuration/tritonplus?cid='),
        activatedTritonMonitor: 'api/Monitor/activatev2?cid=',
        activateCustomer: 'api/customers',
        activateUser: 'api/users/status',
        customerLocationsSummary: 'data/Locations/getLocations?cid=',
        customerLocationActivation: 'data/Locations/activatelocation?cid=',
        telogImportSSO: getConfigUrl('api/ImportTelogFile/sso'),
        telogImportRain: getConfigUrl('api/ImportTelogFile/rain'),
        telogImportfm: getConfigUrl('api/ImportTelogFile/fm'),
        getUnits: 'api/Units',
        unitsCategories: getConfigUrl('api/Units/conv/categories'),
        parseEquation: 'api/CalculationEngine/parseEquation',
        createCalculatedEntity: 'api/CalculationEngine/createEntity',
        getCalculatedEntities: 'api/CalculationEngine',
        getEntities: 'api/CalculationEngine/getEntities',
        deleteCalculatedEntity: 'api/CalculationEngine',
        updateCalculatedEntity: 'api/CalculationEngine',
        getPreDefinedEntitiesOnly: 'api/CalculationEngine/getEntities',
        getDisplayGroups: 'api/CalculationEngine/getDisplayGroupsUnits',
        getCalculatedEntityById: 'api/CalculationEngine/getEntity',
        mapCalculatedEntityByLocation: 'api/CalculationEngine/mapEntity',
        rainDataForRainMonitor: getConfigUrl('api/rain/V2'),
        confirmation: 'api/Confirmation',
        confirmationATP: 'api/Confirmation/atp',
        confirmationReport: 'api/Confirmation/Report',
        confirmationReadings: 'api/Confirmation/readings',
        dataEditing: 'api/DataTable',
        customDashboard: 'api/CustomDashboard/configuration',
        customDashboardGraph: 'api/CustomDashboard/graphs',
        customDashboardGraphById: 'api/CustomDashboard/graphbyid',
        graphsByConfigurationId: 'api/CustomDashboard/configurationbyid',
        refreshstudydata: getConfigUrl('api/CaseStudy/refreshstudydata'),
        configs: 'api/Config/configs/',
        modemSetup: {
            allModems: 'api/apnprovider/all',
            modemByProvider: 'api/apnprovider',
            createModem: 'api/apnprovider',
            updateModem: 'api/apnprovider',
            deleteModem: 'api/apnprovider',
        },
        DataEdit: {
            preview: 'api/DataEdit/preview',
            session: 'api/DataEdit/session',
            submit: 'api/DataEdit/submit',
            recalculate: 'api/DataEdit/dependencies',
            forceUpdateRawVelocity: 'api/DataEdit/forceUpdateRawVelocity',
            delete: 'api/DataEdit/delete',
            auditReport: 'api/DataEdit/report/v2',
            revert: 'api/DataEdit/revert',
            approval: 'api/DataEdit/approval',
            mostRecentApproval: 'api/DataEdit/approval/mostrecent',
            saveCurve: 'api/DataEdit/curve',
            getSavedCurves: 'api/DataEdit/curves/names',
        },
        Telemetry: {
            allEntityData: 'api/Telemetry/allentitydata',
            gainTableData: 'api/Monitor/gaintable',
            monitorData: 'api/Monitor/monitorDetails',
            gainUpdate: 'api/Monitor/updateGain',
        },
        DataTable: {
            fetch: 'api/DataTable/V2',
        },
        collectall: 'api/collections/collectall',
        message: 'api/Message',
        diagnosticLogs: 'api/Monitor/diagnostics/logs',
        diagnostics: 'api/Monitor/diagnostics',
        tritonDataDeliveryIPAdress: '13.82.147.226',
        scattergraph: {
            historicalcurve: 'api/Scattergraph/historicalcurve',
        },
        customerProject: 'api/customers/project',
        sliicerUserConfig: 'api/sliicer/config/',
        ftpsweep: {
            server: 'api/collections/ftpsweep',
            allServers: 'api/collections/ftpsweep/All',
        },
       announcement: getConfigUrl("api/Announcements"),
    };
}
