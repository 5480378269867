<div id="FlowMonitoringDetails">
    <mat-card id="flowMoniterId">
        <mat-card-header>
            <div class="auto-margin">
                <b>{{ ('FLOW_MONITORING.FLOW_MONITOR_WIZARD' | translate) | uppercase }}</b>
            </div>
            <div class="app-flex-filler"></div>
        </mat-card-header>
        <mat-card-content>
            <div>
                <div class="custom-50p">
                    <div class="each-Item">
                        <mat-form-field class="each-Item">
                            <input
                                #nameField
                                matInput
                                placeholder= "{{ 'FLOW_MONITORING.FMR_NAME' | translate }}"
                                [(ngModel)]="fmrName"
                                maxlength="25"
                                ngDefaultControl
                                required
                            />
                        </mat-form-field>
                    </div>
                </div>
                <div class="custom-50p">
                    <div class="each-Item">
                        <mat-form-field class="full-width fmrHintLocationGroup">
                            <mat-select
                                placeholder="{{ 'COMMON.TYPE_PLACEHOLDER' | translate }} *"
                                #locationTypeInput="ngModel"
                                [(ngModel)]="locationType"
                                (selectionChange)="handleLocationTypeChange($event.value)"
                            >
                                <mat-option *ngFor="let location of locationTypeCollection; trackBy: trackByValue" [value]="location.value">
                                    {{ location.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="each-Item" [hidden]="locationType !== locType.locationGroup">
                        <app-auto-complete
                            [items]="locationGroupList"
                            [isShowAll]="false"
                            (inputChanged)="handleLocationGroupInput($event)"
                            (selectedItems)="handleSelectedLocationsGroup($event)"
                            placeholder="{{ 'COMMON.SELECT_LOCATION_GROUP' | translate }} *"
                        >
                        </app-auto-complete>
                        <div class="mat-error" *ngIf="isLocationGroupInvalid && !locationGroupsUnavailable">
                            <span>{{ 'FLOW_MONITORING.LOCATIONGROUP_INVALID_MESSAGE' | translate }}</span>
                        </div>
                        <div class="mat-error" *ngIf="locationGroupsUnavailable">
                            <span>{{ 'FLOW_MONITORING.LOCATIONGROUP_UNAVAILABLE_MESSAGE' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="locationType === locType.location">
                    <div class="mat-error" *ngIf="locationsUnavailable">
                        <span>{{ 'FLOW_MONITORING.LOCATIONS_UNAVAILABLE_MESSAGE' | translate }}</span>
                    </div>
                    <section class="custom-Section margin-b-10" *ngIf="!locationsUnavailable">
                        <mat-checkbox
                            class="each-location-item auto-margin"
                            (ngModelChange)="toggleSelectAllLocations($event)"
                            [checked]="selectAllLocations"
                            [(ngModel)]="selectAllLocations"
                            name="selectallcheckbox"
                            ngDefaultControl
                            [hidden]="!locations.length"
                        >
                            {{ 'COMMON.SELECT_All_OPTION' | translate }}
                        </mat-checkbox>
                        <span class="app-flex-filler"></span>
                        <div class="auto-margin search-section">
                            <span class="each-Item includeLocations fmrHintIncludeInactiveLocations">
                                <mat-slide-toggle
                                    class="toggle"
                                    [(ngModel)]="shouldIncludeInactiveLocations"
                                    (change)="getLocations()"
                                >
                                </mat-slide-toggle>
                                <span>{{ 'COMMON.INCLUDE_INACTIVE_LOCS' | translate }}</span>
                            </span>
                            <mat-form-field class="half-width fmrHintSearch">
                                <mat-icon matPrefix class="search-icon">search</mat-icon>
                                <input
                                    class=""
                                    matInput
                                    type="text"
                                    placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                                    [formControl]="locationSearch"
                                />
                            </mat-form-field>
                        </div>
                    </section>
                    <div class="customMessage" *ngIf="minimumLocationError">
                        <span class="mat-error">{{ 'COMMON.SECARCH_LOCATION_ERROR' | translate }}</span>
                    </div>
                    <div class="custom-SectionList margin-b-10 fmrHintLocationGroupList" *ngIf="!locationsUnavailable">
                        <div class="each-location-item" *ngFor="let location of locations; trackBy: trackById">
                            <mat-checkbox
                                [checked]="location.isChecked"
                                name="location.locationName"
                                [(ngModel)]="location.isChecked"
                                (change)="onLocationChange()"
                            >
                                <span>{{ location.locationName }}</span>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Date Range and Entities -->
            <div class="custom-50p">
                <div class="each-Item custom-error fmrHintDateRange">
                    <app-date-range-picker
                        class="full-width"
                        [buttonClasses]="['full-width', 'datepicker-escape-margin']"
                        [startDate]="startDate"
                        [endDate]="endDate"
                        [defaultTimeSpan]="QUICK_DATE_RANGES.PREVIOUS_MONTH"
                        (startDateChange)="selectedStartDate($event)"
                        (endDateChange)="selectedEndDate($event)"
                    ></app-date-range-picker>
                </div>
                <div class="each-Item">
                    <div class="full-width custom-error fmrHintEntities" id="entities">
                        <app-multi-select-group
                            #entitiesGroupMultiselect
                            [items]="entities"
                            matNativeControl
                            [preselectedItems]="selectedEntities"
                            [isShowAll]="entities.length > 0"
                            (currentlySelectedItems)="handleSelectedEntities($event)"
                            placeholder="{{ 'COMMON.ENTITIES_TEXT' | translate }} *"
                            [minDisplayLimit]="1"
                            [maxDisplayLimit]="20"
                        >
                        </app-multi-select-group>
                        <div class="custom-error-message" *ngIf="invalidEntities">
                            {{ 'COMMON.ENTITY_VALIDATION_MESSSAGE' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            class="fmrHintGroupBy"
                            [(ngModel)]="flowMonitoringTemplate.groupBy"
                            placeholder="{{ 'COMMON.GROUPED_BY' | translate }}"
                        >
                            <mat-option *ngFor="let selection of groupedBySelections; trackBy: trackById" [value]="selection.id">
                                {{ selection.title }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> 
            </div>

            <div class="custom-50p">
                <div class="each-Item">
                    <div class="custom-50p jc-start">
                        <div class="export-section-wrapper">
                            <small>{{ 'COMMON.EXPORT_SECTIONS' | translate }}</small>

                            <mat-checkbox *ngFor="let section of sections; trackBy: trackById"
                                name="section.name"
                                [(ngModel)]="section.isChecked"
                                (change)="handleSelectedSections()"
                            >
                                <span>{{ section.name }}</span>
                            </mat-checkbox>
                        </div>
                        <div class="averaging-section-wrapper">
                            <small>{{ 'COMMON.DATA_AVERAGING' | translate }}</small>

                            <mat-form-field class="full-width fmrHintDataAveraging">
                                <mat-select [disabled]="checkSelectedSection(0)" [(ngModel)]="flowMonitoringTemplate.SummarizeIntervalCommentary">
                                    <mat-option *ngFor="let selection of dataAveragingSelections; trackBy: trackById" [value]="selection.id">
                                        {{ selection.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full-width fmrHintDataAveraging">
                                <mat-select [disabled]="checkSelectedSection(2)" [(ngModel)]="flowMonitoringTemplate.SummarizeIntervalHydrograph"
                                    (selectionChange)="handleGraphAveragingChange($event)">
                                    <mat-option *ngFor="let selection of graphDataAveraging; trackBy: trackById" [value]="selection.id">
                                        {{ selection.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full-width fmrHintDataAveraging">
                                <mat-select [disabled]="checkSelectedSection(3)" [(ngModel)]="flowMonitoringTemplate.SummarizeIntervalScattergraph"
                                    (selectionChange)="handleGraphAveragingChange($event)">
                                    <mat-option *ngFor="let selection of graphDataAveraging; trackBy: trackById" [value]="selection.id">
                                        {{ selection.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full-width fmrHintDataAveraging">
                                <mat-select [disabled]="checkSelectedSection(4)" [(ngModel)]="flowMonitoringTemplate.SummarizeIntervalTabular">
                                    <mat-option *ngFor="let selection of dataAveragingSelections; trackBy: trackById" [value]="selection.id">
                                        {{ selection.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <div class="each-Item as-start">
                    <div class="scattergraph-filters fmrHintSGOptions" *ngIf="showScattergraphFilters">
                        <p>{{ 'FLOW_MONITORING.SCATTERGRAPH_OPTIONS' | translate }}</p>
                        <mat-slide-toggle [(ngModel)]="scattergraphFroude">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.FROUDE_OPTION' | translate }}
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LINES_TEXT' | translate }}
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="scattergraphIsoQ">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ISO_Q_OPTION' | translate }}
                            <sup> {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SUP_TM' | translate }}</sup>
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LINES_TEXT' | translate }}
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="scattergraphPipeOverlay">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.PIPE_OVERLAY_OPTION' | translate }}
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="app-flex-filler"></div>
            <div data-html2canvas-ignore class="auto-margin custom-Flat-Button non-printable pad-b-20">
                <button mat-button (click)="navigateToDashboard()"  class="fmrHintExport">
                    {{ 'COMMON.CANCEL_TEXT' | translate }}
                </button>
                <button mat-button (click)="saveFlowMonitoringTemplate()"  class="fmrHintExport">
                    {{ 'COMMON.SAVE_CLOSE_BUTTON' | translate }}
                </button>
            </div>

            
        </mat-card-content>
    </mat-card>
</div>
