import { GISService } from 'app/shared/services/gis-service';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyMenuTrigger as MatMenuTrigger, MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, NavigationEnd, NavigationStart, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Entities, EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';
import { LocationDashboardFilterData, SeriesType } from 'app/shared/models/location-dashboard-filter-data';
// import { ViewDataService, DateutilService, CustomerService, EntityService, ConfigService, LocationGroupService } from 'app/shared/services';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { TritonLocationService } from 'app/shared/services/tritonLocation.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { DynamicFilterWidgetService } from 'app/shared/services/dynamic-filter-widget.service';
import { DatePickerComponent } from 'app/shared/components/date-picker/date-picker.component';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import {
    INSTALLATION_TYPE,
    LocationDetails,
} from 'app/shared/models/location-details';
import { DeviceTypeCode } from 'app/shared/enums/device-type-code';
import {
    RollbackEditorComponent,
    RollbackEditorDialogData,
} from '../rollback-editor-dialog/rollback-editor-dialog.component';
import { ApproveDataDialogComponent } from '../graphs/approve-data-dialog/approve-data-dialog.component';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { UsersService } from 'app/pages/admin/users.service';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, first, map, mergeMap } from 'rxjs/operators';
import { SiltEditorComponent } from 'app/pages/silt-editor/silt-editor.component';
import { VelocityGainTableComponent, VelocityGainTableComponentResponse } from 'app/shared/components/velocity-gain-table/velocity-gain-table.component';
import {
    SILT_ENTITY,
    DEPTH_DISPLAY_GROUP,
    VELOCITY_DISPLAY_GROUP,
    FLOW_DISPLAY_GROUP,
    RAIN_DISPLAY_GROUP,
    ELEVATION_DISPLAY_GROUP,
    QUANTITY_ENTITY,
    QCONTINUITY_ENTITY,
    EntityGroupId,
    V_FINAL_ENTITY,
    DFINAL_ENTITY,
    Q_FINAL_ENTITY,
    R_FINAL_ENTITY,
    LEVEL_ENTITY,
    FEET_DISPLAY_GROUP,
    FLOW_ENTITY,
    AQUIFER_LEVEL_ENTITY,
    ELEVATION_ENTITY,
    RAW_VELOCITY_ENTITY,
    Series
} from 'app/shared/constant';
import { RAIN_ENTITY, VELOCITY_ENTITY, DEPTH_ENTITY } from 'app/shared/constant';
import {
    BlockDataEditorComponent,
    BlockDataEditorDialogData,
} from 'app/pages/block-data-editor/block-data-editor.component';
import { RecalculateEntitiesComponent, RecalculateEntitiesComponentRequest, RecalculateEntitiesComponentResponse } from 'app/shared/components/recalculate-entities/recalculate-entities.component';
import { DataSource } from '@angular/cdk/table';
import { gisUserSettings } from 'app/shared/models/gis-service-list';
import { ConfirmationFilter, ViewDataService } from 'app/shared/services/view-data.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { EntityService } from 'app/shared/services/entity.service';
import { ConfigService } from 'app/shared/services/config.service';
import { Selectable, SelectableGroup } from 'app/shared/models/selectable';
import { ConfirmationEntities, ConfirmationEntitiesEnum, MultipleLocationsDialogData, SavedConfiguration, ViewDataFilterArgs } from 'app/shared/models/view-data-filter';
import { LocationData, LocationEntitiesData, LocationListArgs } from 'app/shared/models/locations-entities-data';
import { customerLocationGroupQueryParam, customerQueryParam, locationIdQueryParam, reloadCacheQueryParam } from 'app/shared/models/customer';
import { AnnotationSettings, compactData, DataApprovalRange, HydrographTickInterval, SeparateWindowActionTypes } from 'app/shared/models/view-data';
import { Locations, LocationStatus, RAIN_ALERT_III, TimeSpanNumeric } from 'app/shared/models/locations';
import { HydrographArgs } from 'app/shared/models/hydrograph';
import { combineLatest } from 'rxjs';
import { Approval, DATA_AVERAGING_DAILY, DataEditLog, DataEditPreview, DataEditReport, DataEditStoreActionTypes, UpdatePointForSG } from 'app/shared/models/data-edit';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { DateService } from 'app/shared/services/date.service';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { OtherLocationsGraphingDialogComponent } from 'app/shared/components/other-locations-graphing-dialog/other-locations-graphing-dialog.component';
import { SeriesProperty } from 'app/pages/dashboards/custom-dashboard/custom-dashboard-model/custom-dashboard.model';
import { LocationNotesDialogComponent, LocationNotesDialogData } from '../location-notes-dialog/location-notes-dialog.component';
import { LocationSelectionBarComponent } from 'app/shared/components/location-selection-bar/location-selection-bar.component';
import { LocationService } from 'app/shared/services/location.service';
import { UserSettings } from 'app/shared/models/user-settings';
import { sgCurveType } from '../graphs/advance-scattergraph/scatter-graph-constants';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import {
    PrimarySensors,
    SecondarySensors,
    NoneSensor,
    MonitorSeriesNames,
    MonitorSeries,
} from 'app/shared/components/location-card/location-card.constants';
import { VelocityGainDialogData } from 'app/shared/models/gain-data';
import { SensorElement } from 'app/shared/components/location-card/models/location-card.model';
import { MapService } from 'app/shared/services/map.service';
import { BasicSeriesData } from 'app/shared/models/hydrographNEW';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { start } from 'repl';
import moment from 'moment';

// If no dashboard config is set then default value is "00000000-0000-0000-0000-000000000000"
const DASHBOARD_CONFIG_NOT_SET = '00000000-0000-0000-0000-000000000000';

export const APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
        monthYearLabel: { year: 'numeric' },
    },
};

export enum ConfirmationDateRangeType {
    All = 'all',
    Date = 'date'
}
export interface ConfirmationDateRange {
    type?: ConfirmationDateRangeType;
    text?: string;
    dateRange?: { start?: Date; end?: Date }
}

export interface GainResult {
    updated: boolean;
}

export type VelocityGainData = {
    customerId: number,
    locationDetails: LocDetails,
}

export type LocDetails = {
    locationID: number;
}

const siltEntityInfo: SelectableGroup = { id: SILT_ENTITY, name: 'SILT' };

const DEFAULT_SELECTED_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: VELOCITY_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VELOCITY', isChecked: true, isANSR: false },
    { id: QUANTITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QUANTITY', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];

const DEFAULT_SELECTED_ECHO_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: QUANTITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QUANTITY', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];

const DEFAULT_SELECTED_RAIN_ENTITIES = [
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];

const DEFAULT_FORESITE_SELECTED_ENTITIES = [
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
];

const DEFAULT_ELEVATION_FORESITE_SELECTED_ENTITIES = [
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
    { id: ELEVATION_ENTITY, groupId: ELEVATION_DISPLAY_GROUP, name: 'ELEVATION', isChecked: true, isANSR: false },
];

export const DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: DFINAL_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DFINAL', isChecked: true, isANSR: false },
    { id: V_FINAL_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VFINAL', isChecked: true, isANSR: false },
    { id: Q_FINAL_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QFINAL', isChecked: true, isANSR: false },
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
];

const DEFAULT_SG_SELECTED_ENTITIES = [
    { id: DEPTH_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DEPTH', isChecked: true, isANSR: false },
    { id: VELOCITY_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VELOCITY', isChecked: true, isANSR: false },
];
const DEFAULT_SG_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: DFINAL_ENTITY, groupId: DEPTH_DISPLAY_GROUP, name: 'DFINAL', isChecked: true, isANSR: false },
    { id: V_FINAL_ENTITY, groupId: VELOCITY_DISPLAY_GROUP, name: 'VFINAL', isChecked: true, isANSR: false },
];
const USGS_SELECTED_ENTITIES = [
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAIN', isChecked: true, isANSR: false },
    { id: LEVEL_ENTITY, groupId: FEET_DISPLAY_GROUP, name: 'LEVEL', isChecked: true, isANSR: false },
    { id: AQUIFER_LEVEL_ENTITY, groupId: FEET_DISPLAY_GROUP, name: 'AQUIFER LEVEL', isChecked: true, isANSR: false },
];
const USGS_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: RAIN_ENTITY, groupId: RAIN_DISPLAY_GROUP, name: 'RAINFINAL', isChecked: true, isANSR: false },
    { id: LEVEL_ENTITY, groupId: FEET_DISPLAY_GROUP, name: 'LEVEL', isChecked: true, isANSR: false },
    { id: AQUIFER_LEVEL_ENTITY, groupId: FEET_DISPLAY_GROUP, name: 'AQUIFER LEVEL', isChecked: true, isANSR: false },
];
const COMPOSITE_SELECTED_ENTITIES = [
    { id: QCONTINUITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QContinuity', isChecked: true, isANSR: false },
];
const COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS = [
    { id: Q_FINAL_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: 'QFINAL', isChecked: true, isANSR: false },
];

const NONE = 'none';
const EDIT_VIEW_DATA = 'EDIT_VIEW_DATA';
const EDIT_VIEW_DATA_ROllBACK = 'EDIT_VIEW_DATA_ROllBACK';
const USGS = 'USGS';


@Component({
    selector: 'app-view-data-filter',
    templateUrl: './view-data-filter.component.html',
    styleUrls: ['./view-data-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: APP_DATE_FORMATS,
        },
        DatePipe,
    ],
})
export class ViewDataFilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public defaultFilterSettings: ViewDataFilterArgs;
    @Input() public defaultCustomerId: number;
    @Input() public locationGroupId: number;
    @Input() public includeInactiveLocations: boolean;
    @Input() public filterValuesForOverlay: LocationDashboardFilterData;
    @Input() public enableButtons: boolean;
    @Input() public noDataForScatterChart: boolean;
    @Input() public noDataForHydrographChart: boolean;
    @Input() public enableAccept = false;
    @Input() public entityData: LocationEntitiesData;
    @Input() public allLocations: LocationData[];
    @Input() public defaultSelectedLocation: LocationData;
    @Input() public summarizeInterval = 0;
    @Input() public workOrderLink: string;
    @Input() public isDataEditingEnabled = false;
    @Input() public showScatterGraph: boolean;
    @Input() public editingDisabled = false;
    @Input() public minDate: Date;
    @Input() public maxDate: Date;
    @Input() public isUSGS: BehaviorSubject<boolean>;
    @Input() public rainGaugeId: number;
    @Input() public isRawVelExistsForLocation: boolean;
    @Input() public isElevationExistsForLocation: boolean;
    @Input() public series: string;
    @Input() public installationShapeId: number;
    @Input() public isMetric: boolean;
    @Input() public selectedConfirmationEntity: ConfirmationEntitiesEnum;

    @Output() public cancelClick = new EventEmitter<LocationDashboardFilterData>();
    @Output() public acceptClick = new EventEmitter();
    @Output() public notifyViewData = new EventEmitter<{
        filtersData?: LocationDashboardFilterData;
        forceAPICall?: boolean;
        enableAcceptButtonEditor?: boolean;
        reloadSg?: boolean;
        reloadHg?: boolean;
        enableAcceptButtonNoReason?: boolean;
        fromLocationChange?: boolean
    }>();
    @Output() private hideScattergraph = new EventEmitter();
    @Output() private hideHydrograph = new EventEmitter();
    @Output() private scatterEntityBlur = new EventEmitter();
    @Output() private scatterEntitiesChange = new EventEmitter();
    @Output() public notifyAnnotationSelection = new EventEmitter<AnnotationSettings>();
    @Output() public exportTelemetryData = new EventEmitter<boolean>();
    @Output() public printDashboard = new EventEmitter<boolean>();
    @Output() public locationChanged = new EventEmitter<{ location: LocationData, notify: boolean }>();
    @Output() public refreshGraphData = new EventEmitter<boolean>();
    @Output() public refreshVelocityGainData = new EventEmitter<null>();
    @Output() public dataEditingClick = new EventEmitter<boolean>();
    @Output() public showToolbar = new EventEmitter<boolean>();
    @Output() public scatterConfirmationData = new EventEmitter();
    @Output() public userPermissionLoaded = new EventEmitter<boolean>();
    @Output() public dateRangeChange = new EventEmitter<boolean>();
    @Output() public editedPointsForSG = new EventEmitter<UpdatePointForSG[]>(); // Emit edited points for SG
    @Output() public editsReverted = new EventEmitter<{ points: { eid: number, timestamp: string }[], response: DataEditPreview }>();
    @Output() public prevApprovalDates = new EventEmitter<{ prevApprStartDate : Date, prevApprEndDate : Date }>();
    @Output() public notifyEventsChange = new EventEmitter<boolean>();
    @Output() private resetEditingParams = new EventEmitter();
    @Output() private loadOtherLocationsData = new EventEmitter();
    @Output() private updateOtherLocationsData = new EventEmitter();
    @Output() private delaySyncEntities = new EventEmitter();
    @Output() public confirmationEntityChange = new EventEmitter<ConfirmationEntitiesEnum>();
    @Output() private resetOriginalData = new EventEmitter<BasicSeriesData[]>();
    @Output() private recalculateEntities = new EventEmitter<RecalculateEntitiesComponentResponse>();
    @ViewChild('confirmationMenuTrigger') public confirmationMenuTrigger: MatMenuTrigger;
    @ViewChild('accordionMenu') public accordionMenu: ElementRef;
    @ViewChild('toggleButton') public toggleButton: ElementRef;
    @ViewChild('appMenu2') public matMenu: MatMenu;
    @ViewChild(DatePickerComponent) public datePickerComponent: DatePickerComponent;

    @ViewChild('locPagPublic') paginatorPublic: MatPaginator;
    @ViewChild('locPagPrivate') paginatorPrivate: MatPaginator;
    @ViewChild(LocationSelectionBarComponent) public locationSelector: LocationSelectionBarComponent;

    public isLoading = false;
    public locationId: number;
    public locationIdForOverlay: number;
    public startDateForOverlay: Date;
    public endDateForOverlay: Date;
    public isVelocityGainDisabled: boolean;

    public primarySensors: SensorElement[] = [NoneSensor, ...JSON.parse(JSON.stringify(PrimarySensors)).sort((a, b) => a.text.localeCompare(b.text))];
    public secondarySensors: SensorElement[] = [NoneSensor, ...JSON.parse(JSON.stringify(SecondarySensors)).sort((a, b) => a.text.localeCompare(b.text))];

    public returnFromLDDetails: boolean; // Whenever user return from Location Dashboard Details Page

    public availableEntities$ = new BehaviorSubject<EntitySelectorObject[]>([]);
    public availableANSREntities$ = new BehaviorSubject<EntitySelectorObject[]>([]);

    public dataAveragingList = [
        { id: 0, text: 'COMMON.NONE' },
        { id: 8, text: 'COMMON.AVERAGING_TWO_MIN' },
        { id: 1, text: 'COMMON.AVERAGING_FIVE_MIN' },
        { id: 2, text: 'COMMON.AVERAGING_FIFTEEN_MIN' },
        { id: 3, text: 'COMMON.AVERAGING_HOUR' },
        { id: DATA_AVERAGING_DAILY, text: 'COMMON.AVERAGING_DAILY' },
    ];

    public confirmationEntitiesList: ConfirmationEntities[] = [];

    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
    /** Dates picked up by user. Stored to override configuration ones, bug #21401 */
    private defaultStartDate;
    private defaultEndDate;

    public annotationSettings: AnnotationSettings;
    public customDateFormat: string;
    public selectedEntityData = new FormControl();
    public selectableLocations: Array<Selectable>;
    public selectedLocationId: number;
    public locationName: string;

    public prevApprovedStartDate: Date;
    public prevApprovedEndDate: Date;

    public showConfirmationDialog = false;
    public confirmationFilter: ConfirmationFilter = {
        type: 'date',
    };
    public confirmationFilterText: string;
    public approvedDataOnly = true;

    // Variable which represents the selected entity collection
    public selectedEntities: SelectableGroup[] = [];
    public selectedEntityIds: number[] = [];
    private prevSelectedEntities: SelectableGroup[] = [];
    public defaultEntities: SelectableGroup[] = [];

    // Variable that holds all entities information
    public entitiesDetails = new Array<Entities>();

    public customerDateFormat: string;
    public locations = new Array<Locations>();
    public displayEntityError: boolean;

    // Flag to determine the status of Inactive location
    public graphTypes = new Array<Selectable>();

    public tickInterval: HydrographTickInterval;
    public isRedirecting: boolean;
    // Represents the selected tab index
    public displayAnnotations = false;
    public availablePageHint = false;
    public selectedHintPageName: string;
    public showAddNewGraphFlag = false;
    public locationSelectableList: Array<Selectable>;
    public preselectedLocationItems = new Array<Selectable>();
    public locationsSelectedForOverlay = new Array<Locations>();
    public locationsRemovedForOverlay = new Array<Locations>();
    public selectableLocationsUpdated = new Array<Selectable>();
    public isUpdateChartInValid = false;
    public selectedLocationNames: string;
    public selectedEntityNames: string;
    public customizedEntities: EntitySelectorEntity[];
    public isToolbarEnabled = false;
    public markEachDataPoint = false;
    public isComposite: boolean;
    public dataEditingSection: string;
    public isAnnotationOpen = false;
    public userHasPermissionOnEdit = false;
    public customerHasBasicEditPermission = false;
    public customerHasAdvancedEditPermission = false;
    public userHasRawDataPermission = false;
    public hasDateOrEntityError = false;
    public entityErrorMessage: string;
    public filterSettings: ViewDataFilterArgs;
    public selectedLocation: LocationData;
    public monitorSeries: string;
    public customerId: number;
    public isConfirmPointsDateChanged = false;

    public locationDashboardFilterData = new LocationDashboardFilterData();
    private isResetScale = false;
    private subscriptions = new Array<Subscription>();
    private dismissButton: string;
    private siltError: string;

    public dataAveragingText: string = this.dataAveragingList.find((x) => x.id === 0).text;

    public availableSGEntities: EntitySelectorObject[] = [];
    public allAvailableSGEntities: EntitySelectorObject[] = [];
    public defaultSGEntities: SelectableGroup[] = [];
    public selectedSGEntities: SelectableGroup[] = [];
    public selectedDefaultSGEntityIds: number[] = [];
    public selectedSGEntityIds: number[] = [];
    public displaySGEntityError: boolean;
    private previousSGEntities: SelectableGroup[] = [];
    public sgEntitiesPlaceholder: string;
    public missingPipeInformation: string;

    public myConfigColumns = ['name', 'createdOn', 'public', 'actions'];
    public publicConfigColumns = ['name', 'createdOn', 'actions'];
    public loadConfigOpen: boolean;
    public saveConfigOpen: boolean;
    public locationConfigs: MatTableDataSource<any>;
    public locationConfigsPublic: MatTableDataSource<any>;
    public configName: string;
    public isPublicConfig: boolean;
    public isLoadConfigLoading: boolean;
    public recentLoads: any[] = [];
    public lastUsedConfig: any;
    private currentLocationGroup: any;
    menuOpened: boolean;
    private userSettings: gisUserSettings;
    public configEntitiesArePopulated = false;
    private isFirstLoad = true;
    private usgsSetEntities: boolean
    private foresiteSetEntities: boolean;
    private isEchoLocation: boolean;
    private lastLoadedConfig: SavedConfiguration;

    public userPermissionOnEvent: boolean;
    public customerPermissionOnEvent: boolean;
    // #23459 hold displayed confirmation settings - they are stored to confirmationFilter once ACCEPT
    confimationDisplayFilter: { type?: 'all' | 'date'; text?: string; dateRange?: { start?: Date; end?: Date } } = {
        type: 'date',
    };
    // #23459 whenever Confirmation Dialog is opened to disable default close menu click
    isConfirmationMenuOpen = false;

    public isShowEvents = false;
    public hasUnsavedEdits: boolean;
    public secondaryLocationsDialogData: SeriesProperty[] = null;
    okText: any;
    cancelText: any;
    attention: any;
    cancelConfigEdit: any;

    public monitorSeriesTypes = MONITOR_SERIES_TYPES;

    public showPopover: boolean = false;
    public popoverDetails: LocationDetails;

    // #38812 Whenever selected location is Rain Alert III
    public isRAIII = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private statusCodeService: StatusCodeService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private uiUtilService: UiUtilsService,
        private viewDataService: ViewDataService,
        private domOperationUtilsService: DomOperationUtilsService,
        private router: Router,
        private filterWidgetService: DynamicFilterWidgetService,
        private dateutilService: DateutilService,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private matDialog: MatDialog,
        private userService: UsersService,
        private customerService: CustomerService,
        private renderer: Renderer2,
        private entityService: EntityService,
        private configService: ConfigService,
        private locationGroupService: LocationGroupService,
        private gisService: GISService,
        private dataEditService: DataEditService,
        private dateService: DateService,
        private tritonLocationService: TritonLocationService,
        public separateWindowHydrographService: SeparateWindowHydrographService,
        private locationService: LocationService,
        private mapService: MapService
    ) {
        const translateKeys: Array<string> = [
            'COMMON.DISMISS_TEXT',
            'COMMON.SILT_ERROR',
            'COMMON.SCATTERGRAPH_ENTITIES',
            'LOCATION_DASHBOARD.MISSING_PIPE_INFORMATION',
            'COMMON.OK',
            'COMMON.CANCEL_BUTTON',
            'COMMON.ATTENTION',
            'COMMON.CONFIGEDIT'
        ];
        this.subscriptions.push(this.translate.get(translateKeys).subscribe((response) => {
            this.dismissButton = response['COMMON.DISMISS_TEXT'];
            this.siltError = response['COMMON.SILT_ERROR'];
            this.sgEntitiesPlaceholder = response['COMMON.SCATTERGRAPH_ENTITIES'];
            this.missingPipeInformation = response['LOCATION_DASHBOARD.MISSING_PIPE_INFORMATION'];
            this.okText = response['COMMON.OK'];
            this.cancelText = response['COMMON.CANCEL_BUTTON'];
            this.attention = response['COMMON.ATTENTION'];
            this.cancelConfigEdit = response['COMMON.CONFIGEDIT'];
        }));

        this.graphTypes = [
            {
                id: 1,
                name: 'HYDROGRAPH',
                isChecked: false,
            },
            {
                id: 2,
                name: 'SCATTERGRAPH',
                isChecked: false,
            },
            {
                id: 3,
                name: 'PERCENT FULL',
                isChecked: false,
            },
            {
                id: 4,
                name: 'DAILY SUMMARY',
                isChecked: false,
            },
            {
                id: 5,
                name: 'UPTIME',
                isChecked: false,
            },
        ];

        this.annotationSettings = <AnnotationSettings>{
            isDataQuality: false,
            isManholeDepth: false,
            isPipeHeight: false,
            isRainOnTop: false,
            isSilt: false,
            isScatterInvert: false,
            isIsoQ: false,
            isPipeOverlay: false,
            isBestFit: false,
            isToleranceLines: false,
            isSSCurve: false,
            isFroude: false,
            isManualLinear: false,
            isManualSmooth: false,
            isToolbarEnabled: false,
        };
        this.renderer.listen('window', 'click', (e: Event) => {
            if (this.toggleButton && this.accordionMenu) {
                if (
                    !this.isConfirmationMenuOpen &&
                    !this.toggleButton.nativeElement.contains(e.target) &&
                    !this.accordionMenu.nativeElement.contains(e.target)
                ) {
                    this.displayAnnotations = false;
                    this.isAnnotationOpen = false;
                    this.uiUtilService.safeChangeDetection(this.cdr);
                }
            }
        });
        this.subscriptions.push(this.translate.get('COMMON.ENTITY_SELECT_VALIDATION').subscribe((res: string) => {
            this.entityErrorMessage = res;
        }));
    }

    // Component life cycle hook
    public ngOnInit() {
        const pageNameHint = this.domOperationUtilsService.selectedHintPageName.subscribe((pageName: string) => {
            this.selectedHintPageName = pageName;
            this.uiUtilService.safeChangeDetection(this.cdr);
        });
        const hintSubs = this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
            this.availablePageHint = result;
            this.uiUtilService.safeChangeDetection(this.cdr);
        });
        this.isVelocityGainDisabled = false;
        const selectedLocGroupSubs = this.locationGroupService.locationGroupSelected.subscribe((groups) => {
            if (groups && groups['locationGroups']) this.currentLocationGroup = groups['locationGroups'];
        });
        this.populateConfirmationEntities();
        this.populatePermissions();
        this.checkForEditsToDisableAcceptButton();
        this.setConfirmationDialogInitialValues();
        this.updateConfirmationFilterTextOnDate();
        this.listenRouteEvents();
        // subscribe to changes in dateFormat
        const dateFormatSubs = this.dateutilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat =
                this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
            const dateFormat = this.dateutilService.getFormat();
            const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
            this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;
            this.confirmationFilter = {
                type: 'date',
                dateRange: { start: this.startDate, end: new Date(this.endDate) },
            };
            this.updateConfirmationFilterTextOnDate();
            this.uiUtilService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.push(pageNameHint, hintSubs, selectedLocGroupSubs, dateFormatSubs);
        // this.loadMruConfig();

        //  for routing from the map and routing from the location dashboard
        const activatedRouteSubscripton = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const newCustomer = Number(params.get(customerQueryParam));
            const newLocationGroup = Number(params.get(customerLocationGroupQueryParam));
            this.locationId = Number(params.get(locationIdQueryParam));
            this.returnFromLDDetails = !!Number(params.get(reloadCacheQueryParam));
            this.isVelocityGainDisabled = false;

            //  if there is a customer change or location group change
            if ((this.customerId && this.customerId !== newCustomer) || this.locationGroupId !== newLocationGroup) {
                //  reset annotations and hide the menu
                this.displayAnnotations = false; // Bug#10401-Locations options menu displayed on customer change
                StringUtils.clearSessionStorage();

                this.viewDataService.annotationsSettings.next({ ...this.annotationSettings });

                this.customerId = newCustomer;
                this.checkUserSettings();
                this.statusCodeService.currentCustomerId.next(this.customerId);
                this.locationGroupId = newLocationGroup;

                this.notifyParentComponent();
            }
            if (!this.locationId) {
                // update location if change from details page or hydrograph page
                this.subscriptions.push(
                    this.statusCodeService.currentLocationId.subscribe((saveLocationId) => {
                        if (saveLocationId && this.locationId !== saveLocationId) {
                            this.locationId = saveLocationId;
                        }
                        this.uiUtilService.safeChangeDetection(this.cdr);
                    }),
                );
            }

            if (this.returnFromLDDetails) {
                const cachedFilters = this.viewDataService.cachedFiltersGet();
                if (cachedFilters) {
                    if (cachedFilters.annotations) {
                        this.annotationSettings = cachedFilters.annotations;
                    }
                    if (cachedFilters.startDate && cachedFilters.endDate) {
                        this.startDate = cachedFilters.startDate;
                        this.endDate = cachedFilters.endDate;
                    }
                    if(cachedFilters.confirmationFilter) {
                        this.confirmationFilter = cachedFilters.confirmationFilter;
                        this.selectedConfirmationEntity = cachedFilters.selectedConfirmationEntity;
                        this.confirmationEntityChange.emit(this.selectedConfirmationEntity);
                    }
                }
            }
        });
        this.subscriptions.push(activatedRouteSubscripton);

        this.subscriptions.push(this.notifyAnnotationSelection.subscribe((annot) => {
            this.viewDataService.cachedFiltersGet().annotations = JSON.parse(JSON.stringify(this.annotationSettings));
        }))

        // When running on Map it does not have isUSGS subscription
        if (this.isUSGS) {
            this.subscriptions.push(this.isUSGS.subscribe((isUsgs) => {
                this.processEntities();
                // 22533 set default entities for USGS
                if (isUsgs && (!this.lastLoadedConfig || this.lastLoadedConfig?.cid !== this.customerId)) {
                    if (this.userHasRawDataPermission) {
                        if (!this.usgsSetEntities) {
                            this.prevSelectedEntities = this.selectedEntities;
                        }
                        this.selectedEntities = this.customizedEntities && this.customizedEntities.length > 0 ? this.customizedEntities : [...USGS_SELECTED_ENTITIES];
                        this.selectedEntityIds = this.selectedEntities.map((entity) => entity.id);
                    } else {
                        if (!this.usgsSetEntities) {
                            this.prevSelectedEntities = this.selectedEntities;
                        }
                        this.selectedEntities = [...USGS_SELECTED_ENTITIES_NO_PERMISSIONS];
                        this.selectedEntityIds = USGS_SELECTED_ENTITIES_NO_PERMISSIONS.map((entity) => entity.id);
                    }
                    this.usgsSetEntities = true;
                } else {
                    this.usgsSetEntities = false;
                }
            }));
        }

        if (!this.entityData) {
            return;
        }

        this.loadEntities();
        this.filterSettings = this.defaultFilterSettings;

        if (this.filterValuesForOverlay) {
            this.locationIdForOverlay = this.filterValuesForOverlay.locationIDs[0];
            this.startDateForOverlay = this.filterValuesForOverlay.startDate;
            this.endDateForOverlay = this.filterValuesForOverlay.endDate;
        }

        const locGroupSubs = this.statusCodeService.isLocationGroupChanged.subscribe((result) => {
            if (result) {
                this.isResetScale = result;
            }
        });

        const cusChangeSubs = this.statusCodeService.isCustomerChanged.subscribe((result) => {
            if (result) {
                this.isResetScale = result;
            }
        });

        const timeIntervalSubs = this.statusCodeService.timeInterval.subscribe((result) => {
            const today = new Date();
            if (result) {
                if (result === 'today') {
                    this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0);
                    this.statusCodeService.setQuickRangeInDateFilter.next(0);
                } else if (result === 'lastWeek') {
                    this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0);
                    this.statusCodeService.setQuickRangeInDateFilter.next(1);
                } else if (result === 'lastMonth') {
                    this.startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0);
                    this.statusCodeService.setQuickRangeInDateFilter.next(2);
                } else if (result === 'lastThreeMonths') {
                    this.startDate = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate(), 0, 0, 0);
                    this.statusCodeService.setQuickRangeInDateFilter.next(2);
                } else if (result === 'lastSixMonths') {
                    this.startDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate(), 0, 0, 0);
                    this.statusCodeService.setQuickRangeInDateFilter.next(2);
                } else if (result === 'lastYear') {
                    this.startDate = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate(), 0, 0, 0);
                    this.statusCodeService.setQuickRangeInDateFilter.next(2);
                }
            } else {
                //  save default time frame to 1 week on shared service
                this.statusCodeService.setQuickRangeInDateFilter.next(1);
            }
        });

        this.subscriptions.push(locGroupSubs, cusChangeSubs, timeIntervalSubs);
        const savedAnnotations = this.viewDataService.annotationsSettings.getValue();

        if (savedAnnotations) {
            this.annotationSettings = { ...savedAnnotations };
        }

        const navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            this.subscriptions.push(this.statusCodeService.isThemeChanged.subscribe((result: boolean) => {
                if (result) {
                    if (e instanceof NavigationEnd) {
                        this.notifyParentComponent();
                    }
                }
            }));
        });

        this.subscriptions.push(navigationSubscription);
        this.filterSettings = this.defaultFilterSettings;

        this.uiUtilService.safeChangeDetection(this.cdr);

        const isDataEditingModeSubscription = this.viewDataService.isDataEditingOpen.subscribe((response) => {
            if (response !== undefined || response !== null) {
                this.isDataEditingEnabled = response;
                this.uiUtilService.safeChangeDetection(this.cdr);
            }
        });
        this.subscriptions.push(isDataEditingModeSubscription);

        this.selectedLocation = this.defaultSelectedLocation;
        this.fetchPopupData();
        this.customerId = this.defaultCustomerId;
    }

    private checkForEditsToDisableAcceptButton() {
        const editsSubs = this.dataEditService.onEditsChanged.subscribe(() => {
            const { currentEditTimestamp: currentTs, storedEdits: edits } = this.dataEditService;

            const currentEditIndex = edits.findIndex(v => v.ts === currentTs);
            const currentEdits = edits.slice(0, currentEditIndex + 1);

            this.hasUnsavedEdits = currentEdits.filter(v => v.action !== DataEditStoreActionTypes.Ignore && v.action !== DataEditStoreActionTypes.Unignore).length > 0 && currentTs !== null;
            this.uiUtilService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.push(editsSubs);
    }

    private GetMostRecentApprovalData() {
        this.prevApprovedStartDate = null;
        this.prevApprovedEndDate = null;

        this.dataEditService.mostRecentApproval(this.customerId, [this.locationId]).subscribe(
            (result: Approval[]) => {
                if(result)
                {
                    this.prevApprovedStartDate = result[0].approvedStartDate;
                    this.prevApprovedEndDate = result[0].approvedThrough;

                    this.prevApprovalDates.emit( { prevApprStartDate : this.prevApprovedStartDate, prevApprEndDate: this.prevApprovedEndDate });
                    
                }
            }
        );  
    }

    private checkUserSettings() {
        if (!this.isFirstLoad) {
            return;
        }
        this.subscriptions.push(
            this.gisService.gisUserSettingsSubject$
                .pipe(
                    filter((x) => !!x),
                    first(),
                )
                .subscribe((res) => {
                    if (this.isFirstLoad && this.separateWindowHydrographService.isNewTab) {
                        this.separateWindowHydrographService.dispatchAction<string>({ type: SeparateWindowActionTypes.ping, payload: this.separateWindowHydrographService.id });
                    }
                    this.isFirstLoad = false;
                    this.userSettings = res;

                    // #42987 reset selected entities only when there is no configuration loaded
                    // we cannot use this.configEntitiesArePopulated to check, because its value is not set at this point
                    const customerSettings = this.userSettings.customerSettings.find((x) => x.cid === this.customerId);
                    if (
                        !customerSettings || !customerSettings.lastViewedDashboardConfig ||
                        customerSettings.lastViewedDashboardConfig === DASHBOARD_CONFIG_NOT_SET
                    ) {
                        this.selectedEntities = [];
                        this.selectedEntityIds = [];
                        this.selectedSGEntities = [];
                        this.selectedSGEntityIds = [];
                    }

                    this.loadLastConfig();
                }),
        );
    }

    private loadLastConfig() {
        if (!this.userSettings) return;

        if (this.returnFromLDDetails) {
            const conf = this.viewDataService.cachedFiltersGet();
            if (conf.startDate) this.startDate = conf.startDate;
            if (conf.endDate) this.endDate = conf.endDate;
        }

        if (this.separateWindowHydrographService.isNewTab) {
            return this.setDefaultEntitiesByPermission();
        }
        const customerSettings = this.userSettings.customerSettings.find((x) => x.cid === this.customerId);
        this.userHasRawDataPermission = this.userService.isRawDataEditingAllowed.getValue();
        this.userPermissionOnEvent = this.userService.isEventEditorOnUserAllowed.getValue();
        this.isShowEvents = this.userService.userSettings.getValue()?.showEvents;
        this.notifyEventsChange.emit(this.isShowEvents);
        // If no dashboard config is set then default value is "00000000-0000-0000-0000-000000000000"
        if (
            customerSettings &&
            customerSettings.lastViewedDashboardConfig &&
            customerSettings.lastViewedDashboardConfig !== '00000000-0000-0000-0000-000000000000'
        ) {
            this.subscriptions.push(
                this.configService.getDashboardConfig(this.customerId).subscribe((res) => {
                    const conf = res.find(
                        (x) => x.guid === customerSettings.lastViewedDashboardConfig && x.cid === this.customerId,
                    );
                    if (conf) {

                        this.lastLoadedConfig = conf;
                        this.loadConfiguration(conf, false);
                    } else {
                        this.configEntitiesArePopulated = false;
                        this.setDefaultEntitiesByPermission();
                    }
                }),
            );
        } else {
            this.configEntitiesArePopulated = false;
            this.setDefaultEntitiesByPermission();
        }
    }

    private listenRouteEvents() {
        //  annotations and location ID are reset when navigated to any other page other than Hydrograph
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!event.url.includes('viewData')) {
                    this.statusCodeService.currentLocationId.next(null);

                    this.viewDataService.annotationsSettings.next(<AnnotationSettings>{
                        isPipeHeight: false,
                        isManholeDepth: false,
                        isSilt: false,
                        isDataQuality: false,
                        isRainOnTop: false,
                        isScatterInvert: false,
                        isBestFit: false,
                        isToleranceLines: false,
                        isSSCurve: false,
                        isManualLinear: false,
                        isManualSmooth: false
                    });
                }
            }
        });

        this.subscriptions.push(routerSubscription);
    }

    private populateConfirmationEntities() {
        const averageText = this.translate.instant('COMMON.AVERAGE_VEL');
        const peakText = this.translate.instant('COMMON.PEAK_VELOCITY');
        this.confirmationEntitiesList = [
            { name: averageText, id: ConfirmationEntitiesEnum.Average },
            { name: peakText, id: ConfirmationEntitiesEnum.Peak },
        ];
    }

    private populatePermissions() {
        this.subscriptions.push(
            combineLatest([
                this.userService.isRawDataEditingAllowed,
                this.userService.isBasicDataEditingAllowed,
                this.userService.isAdvancedDataEditingAllowed,
                this.userService.isApprovedDataOnly,
                this.userService.isEventEditorOnUserAllowed,
                this.userService.isEventEditorOnCustomerAllowed
            ]).subscribe(
                ([
                    userHasRawDataPermission,
                    customerHasBasicEditPermission,
                    customerHasAdvancedEditPermission,
                    approvedDataOnly,
                    userEventPermission,
                    customerHasEventsPermission
                ]) => {
                    this.approvedDataOnly = approvedDataOnly;
                    this.userHasRawDataPermission = userHasRawDataPermission;
                    this.customerHasBasicEditPermission = customerHasBasicEditPermission;
                    this.userHasPermissionOnEdit = customerHasBasicEditPermission;
                    this.customerHasAdvancedEditPermission = customerHasAdvancedEditPermission.value;
                    this.userPermissionOnEvent = userEventPermission;
                    this.customerPermissionOnEvent = customerHasEventsPermission.value;
                    this.userPermissionLoaded.emit(true);
                },
            ),
        );
    }

    public scrollToSelectedLocation() {
        if (!this.locationSelector) return;

        this.locationSelector.scrollToSelectedLocation();
    }

    // For #21971 - using by location-gis-quick-look.component
    public resetEntitiesByPermission() {
        this.prevSelectedEntities = [];
        this.previousSGEntities = [];
        this.setDefaultEntitiesByPermission(false);
        this.prevSelectedEntities = this.selectedEntities;
        this.previousSGEntities = this.selectedSGEntities;
    }
    private setDefaultEntitiesByPermission(refreshView = true) {
        if (this.separateWindowHydrographService.isNewTab) {
            return;
        }
        if (this.prevSelectedEntities.length || this.previousSGEntities.length) {
            if (this.prevSelectedEntities.length) {
                this.selectedEntities = [...this.prevSelectedEntities];

                if (this.isComposite) {
                    this.selectedEntities = this.userHasRawDataPermission ? [...COMPOSITE_SELECTED_ENTITIES] : [...COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS];
                }
                else if (this.foresiteSetEntities) {
                    if (this.selectedLocation.s.toLowerCase() === MONITOR_SERIES_TYPES.FORE_SITE.toLowerCase() || MONITOR_SERIES_TYPES.FORE_SITE_FLEX.toLowerCase()) {
                        if (this.installationShapeId === Series.ChannelStandard && (!this.lastLoadedConfig || this.customerId !== this.lastLoadedConfig?.cid)) {
                            this.selectedEntities = this.customizedEntities && this.customizedEntities.length > 0 ? this.customizedEntities : [...DEFAULT_FORESITE_SELECTED_ENTITIES];

                        } else if (this.installationShapeId === Series.Elevation  && (!this.lastLoadedConfig || this.customerId !== this.lastLoadedConfig?.cid)) {
                                this.selectedEntities = this.customizedEntities && this.customizedEntities.length > 0 ? this.customizedEntities : [...DEFAULT_ELEVATION_FORESITE_SELECTED_ENTITIES];
                        }
                    } else {
                        this.selectedEntities = [...DEFAULT_FORESITE_SELECTED_ENTITIES];
                    }
                }
                else if (this.isEchoLocation && (!this.lastLoadedConfig || this.customerId !== this.lastLoadedConfig?.cid)) {
                    this.selectedEntities = this.customizedEntities && this.customizedEntities.length > 0 ? this.customizedEntities : [...DEFAULT_SELECTED_ECHO_ENTITIES];
                } else if (this.isRAIII && (!this.lastLoadedConfig || this.customerId !== this.lastLoadedConfig?.cid)) {
                    this.selectedEntities = this.customizedEntities && this.customizedEntities.length > 0 ? this.customizedEntities : [...DEFAULT_SELECTED_RAIN_ENTITIES];
                }  else if (this.usgsSetEntities && (!this.lastLoadedConfig || this.customerId !== this.lastLoadedConfig?.cid)) {
                    this.selectedEntities = this.userHasRawDataPermission ?
                   this.customizedEntities && this.customizedEntities.length > 0 ? this.customizedEntities : [...USGS_SELECTED_ENTITIES] : [...USGS_SELECTED_ENTITIES_NO_PERMISSIONS];
                }
                else if(!this.lastLoadedConfig || this.customerId !== this.lastLoadedConfig?.cid || (!this.lastLoadedConfig && this?.selectedLocation?.s.toUpperCase() === MonitorSeriesNames.triton)){
                    this.selectedEntities = this.userHasRawDataPermission ? this.customizedEntities && this.customizedEntities.length > 0 ? this.customizedEntities :  [...DEFAULT_SELECTED_ENTITIES]: [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
                    this.selectedSGEntities = this.userHasRawDataPermission ? [...DEFAULT_SG_SELECTED_ENTITIES] : [...DEFAULT_SG_SELECTED_ENTITIES_NO_PERMISSIONS];
                }
                this.selectedEntityIds = this.selectedEntities.map(v => v.id);
            }

            if (this.previousSGEntities.length) {
                this.selectedSGEntities = [...this.previousSGEntities];
                this.selectedSGEntityIds =
                    this.previousSGEntities.map((entity) => entity.id);
            }

            this.syncSGEntities(this.selectedEntities);

            if(this.lastLoadedConfig && this.customerId === this.lastLoadedConfig.cid){
                this.selectedEntityIds = this.lastLoadedConfig.selected_entities;
                this.selectedSGEntityIds = this.lastLoadedConfig.selected_sg_entities;
            }

            this.uiUtilService.safeChangeDetection(this.cdr);
        }

        this.isEntitiesSelected();

        if ((this.prevSelectedEntities.length || this.previousSGEntities.length) && this.selectedEntities.length) {
            return;
        }

        if (this.isComposite) {
            this.selectedEntities = this.userHasRawDataPermission ? [...COMPOSITE_SELECTED_ENTITIES] : [...COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.selectedSGEntities = [];
        } else if (this.foresiteSetEntities){
            if (this.selectedLocation.s.toLowerCase() === MONITOR_SERIES_TYPES.FORE_SITE.toLowerCase() || MONITOR_SERIES_TYPES.FORE_SITE_FLEX.toLowerCase()) {
                if (this.installationShapeId === Series.ChannelStandard) {
                    this.selectedEntities = [...DEFAULT_FORESITE_SELECTED_ENTITIES];
                } else if (this.installationShapeId === Series.Elevation) {
                    this.selectedEntities = [...DEFAULT_ELEVATION_FORESITE_SELECTED_ENTITIES];
                }
            } else {
                this.selectedEntities = [...DEFAULT_FORESITE_SELECTED_ENTITIES];
            }

            this.selectedSGEntities = [];
        } else if (this.usgsSetEntities) {
            this.selectedEntities = this.userHasRawDataPermission ? [...USGS_SELECTED_ENTITIES] : [...USGS_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.selectedSGEntities = [];
        }
        else if (this.isEchoLocation) {
            this.selectedEntities = this.userHasRawDataPermission ? [...DEFAULT_SELECTED_ECHO_ENTITIES] : [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.selectedSGEntities = this.userHasRawDataPermission ? [...DEFAULT_SG_SELECTED_ENTITIES] : [...DEFAULT_SG_SELECTED_ENTITIES_NO_PERMISSIONS];
        } else if (this.isRAIII) {
            this.selectedEntities = this.userHasRawDataPermission ? [...DEFAULT_SELECTED_RAIN_ENTITIES] : [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.selectedSGEntities = this.userHasRawDataPermission ? [...DEFAULT_SG_SELECTED_ENTITIES] : [...DEFAULT_SG_SELECTED_ENTITIES_NO_PERMISSIONS];
        } else {
            this.selectedEntities = this.userHasRawDataPermission ? [...DEFAULT_SELECTED_ENTITIES] : [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            this.selectedSGEntities = this.userHasRawDataPermission ? [...DEFAULT_SG_SELECTED_ENTITIES] : [...DEFAULT_SG_SELECTED_ENTITIES_NO_PERMISSIONS];
        }

        this.defaultEntities = [...this.selectedEntities];
        this.defaultSGEntities = [...this.selectedSGEntities];
        this.selectedEntityIds = this.selectedEntities.map(v => v.id);
        this.selectedSGEntityIds = this.selectedSGEntities.map(v => v.id);

        // #21971 - location-gis-quick-look.component do not want it to refresh view
        if (refreshView) this.uiUtilService.safeChangeDetection(this.cdr);
    }

    private listenFilterValues() {
        const entitiesSubs = this.viewDataService.filterValues.pipe(filter(v => !!v), distinctUntilKeyChanged('entities')).subscribe((data) => {
            if (!data || !data.entities) return;

            if(data.locationIDs && data.locationIDs.length && !data.locationIDs.includes(this.locationId)) return;

            if (!this.isDataEditingEnabled) {
                this.syncSGEntities(data.entities);
            }
        });

        this.subscriptions.push(entitiesSubs);
    }

    private setAvailableSGEntities(filteredEntities: SelectableGroup[], dataEditEntsOnly = false) {
        const allowedEntities = [DEPTH_ENTITY, VELOCITY_ENTITY, RAW_VELOCITY_ENTITY];
        const availableEntities = [];

        this.allAvailableSGEntities.forEach((v) => {
            let entities: SelectableGroup[];

            if (dataEditEntsOnly) {
                entities = filteredEntities.filter((i) => i.groupId === v.groupId && allowedEntities.includes(i.id));
            } else {
                entities = filteredEntities.filter((i) => i.groupId === v.groupId);
            }

            const group = {
                groupName: v.groupName,
                groupId: v.groupId,
                entities: entities,
            };

            availableEntities.push(group);
        });

        this.availableSGEntities = [...availableEntities];
        this.viewDataService.scattergraphAvailableEntities = this.availableSGEntities;
    }

    public syncSGEntities(data: SelectableGroup[], notifyOtherWindows = true) {
        if(this.lastLoadedConfig){
            this.configEntitiesArePopulated = this.customerId === this.lastLoadedConfig.cid;
        }
        const filteredEntities = data.filter(
            (v) => v.groupId === EntityGroupId.Depth || v.groupId === EntityGroupId.Velocity,
        );
        // #22784 If user choose entities for HG, but DEPTH and VELOCITY are not included, then do nothing with SG
        if (this.isDataEditingEnabled) {
            const enitiesIds = data.map(x => x.id);
            const hgIncludesVelocity = enitiesIds.includes(VELOCITY_ENTITY);
            const hgIncludesDepth = enitiesIds.includes(DEPTH_ENTITY);
            const sgDisplayVelocity = this.selectedSGEntityIds.includes(VELOCITY_ENTITY);
            const sgDisplayDepth = this.selectedSGEntityIds.includes(DEPTH_ENTITY);
            if (hgIncludesVelocity && hgIncludesDepth && (!sgDisplayVelocity || !sgDisplayDepth)) {
                // #22784 If we do not have VELOCITY and DEPTH, hide SG and update available SG entities
                this.selectedSGEntityIds = [];
                this.setAvailableSGEntities(filteredEntities);
                this.hideScattergraph.emit();
                return;
            }

        }

        this.setAvailableSGEntities(filteredEntities);

        if (this.configEntitiesArePopulated) {
            const isDiff = this.separateWindowHydrographService.checkIfEntitiesDifferent(this.selectedSGEntities, filteredEntities);
            this.selectedSGEntities = this.customerId === this.lastLoadedConfig.cid ? filteredEntities.filter(v => this.lastLoadedConfig.selected_sg_entities.includes(v.id)) : filteredEntities;
            this.selectedSGEntityIds = this.selectedSGEntities.map(v => v.id);

            if (this.selectedSGEntityIds.length < 2) {
                this.hideScattergraph.emit();
            } else {
                this.scatterEntitiesChange.emit(this.selectedSGEntityIds);
            }

            if (isDiff && notifyOtherWindows) {
                this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.sgEntitiesChange, payload: this.selectedSGEntities });
            }
            return;
        }
        if (filteredEntities.length) {
            this.previousSGEntities = filteredEntities;
        }
        this.populateSelectedEntities(filteredEntities, notifyOtherWindows);
    }

    private populateSelectedEntities(list: SelectableGroup[], notifyOtherWindows = true) {
        if (this.isDataEditingEnabled) {
            const isDepthVelocityExist = this.checkEntitiesOnEdit(list);

            if (!isDepthVelocityExist) return;
        }

        const depthGroup = list.filter((v) => v.groupId === EntityGroupId.Depth);
        const velGroup = list.filter((v) => v.groupId === EntityGroupId.Velocity);

        const selectedEntities: SelectableGroup[] = [];

        // #22784 In edit mode choose only DEPTH, VELOCITY if present. Do not choose VFINAL or DFINAL
        if (this.checkEntityInList(list, V_FINAL_ENTITY) && !this.isDataEditingEnabled) {
            selectedEntities.push(this.getEntityFromListById(list, V_FINAL_ENTITY));
        } else if (this.checkEntityInList(list, VELOCITY_ENTITY)) {
            selectedEntities.push(this.getEntityFromListById(list, VELOCITY_ENTITY));
        } else if (velGroup.length) {
            selectedEntities.push(velGroup[0]);
        }

        // #22784 In edit mode choose only DEPTH, VELOCITY if present. Do not choose VFINAL or DFINAL
        if (this.checkEntityInList(list, DFINAL_ENTITY) && !this.isDataEditingEnabled) {
            selectedEntities.push(this.getEntityFromListById(list, DFINAL_ENTITY));
        } else if (this.checkEntityInList(list, DEPTH_ENTITY)) {
            selectedEntities.push(this.getEntityFromListById(list, DEPTH_ENTITY));
        } else if (depthGroup.length) {
            selectedEntities.push(depthGroup[0]);
        }

        const isDiff = this.separateWindowHydrographService.checkIfEntitiesDifferent(this.selectedSGEntities, selectedEntities);
        this.selectedSGEntityIds = selectedEntities.map((v) => v.id);
        this.selectedSGEntities = [...selectedEntities];

        if (isDiff && notifyOtherWindows) {
            this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.sgEntitiesChange, payload: this.selectedSGEntities });
        }
        if (this.selectedSGEntityIds.length < 2) {
            this.hideScattergraph.emit();
        } else {
            this.scatterEntitiesChange.emit(this.selectedSGEntityIds);
        }
    }

    private syncEntitiesToSGwithinDE(selectedEntities: Selectable[]) {
        this.setAvailableSGEntities(selectedEntities, true);

        if (this.selectedSGEntityIds.length === 2) {
            return;
        }

        const allowedEntities = [DEPTH_ENTITY, RAW_VELOCITY_ENTITY, VELOCITY_ENTITY];
        const allowedFromList = allowedEntities.filter(v => selectedEntities.find(x => x.id === v));

        const toAddEntities = selectedEntities.filter(v => allowedFromList.includes(v.id)).slice(0, 2);

        this.selectedSGEntities.push(...toAddEntities);
        this.selectedSGEntityIds = this.selectedSGEntities.map((v) => v.id);

        if (this.selectedSGEntityIds.length < 2) {
            this.hideScattergraph.emit();
        } else {
            this.scatterEntitiesChange.emit(this.selectedSGEntityIds);
        }
    }

    private getEntityFromListById(list: SelectableGroup[], id: number) {
        return list.find((v) => v.id === id);
    }

    private checkEntityInList(list: SelectableGroup[], entityId: number) {
        return list.some((v) => v.id === entityId);
    }

    public checkEntitiesOnEdit(list?: SelectableGroup[]) {
        const allEntities = this.availableEntities$.getValue().reduce((acc, curr) => [...acc, ...curr.entities], []);

        const isDepthSelected = this.selectedEntityIds.includes(DEPTH_ENTITY);
        const isVelSelected = this.selectedEntityIds.includes(VELOCITY_ENTITY);

        const depth = isDepthSelected ? allEntities.find((v) => v.id === DEPTH_ENTITY) : null;
        const velocity =  isVelSelected ? allEntities.find((v) => v.id === VELOCITY_ENTITY) : null;
        const rawVelocity = this.isRawVelExistsForLocation ? allEntities.find((v) => v.id === RAW_VELOCITY_ENTITY) : null;

        const velEntity = rawVelocity ? rawVelocity : velocity;

        if (depth && velEntity) {
            this.selectedSGEntities = [velEntity, depth];
            this.selectedSGEntityIds = this.selectedSGEntities.map(v => v.id);

            this.updateListOnEdit(depth, velEntity, list);
            return true;
        } else {
            this.hideScattergraph.emit();

            return false;
        }
    }

    private updateListOnEdit(depth: SelectableGroup, velocity: SelectableGroup, list?: SelectableGroup[]) {
        if (list) {
            if (!list.find((v) => v.id === depth.id)) {
                list.push(depth);
            }

            if (!list.find((v) => v.id === velocity.id)) {
                list.push(velocity);
            }
            return;
        }

        const depthGroup = this.availableSGEntities.find((v) => v.groupId === EntityGroupId.Depth);
        const velGroup = this.availableSGEntities.find((v) => v.groupId === EntityGroupId.Velocity);

        if (!depthGroup || !velGroup) return;

        if (!depthGroup.entities.find((v) => v.id === depth.id)) {
            depthGroup.entities.push(depth as any);
        }

        if (!velGroup.entities.find((v) => v.id === velocity.id)) {
            velGroup.entities.push(velocity as any);
        }
    }

    public selectSGEntities(entities: EntitySelectorEntity[], notifyOtherWindows = true, forceUpdate = false) {
        const isDiff = forceUpdate || this.separateWindowHydrographService.checkIfEntitiesDifferent(this.selectedSGEntities, entities);

        this.selectedSGEntities = entities;
        this.selectedSGEntityIds = entities.map((v) => v.id);
        this.previousSGEntities = entities;

        this.scatterEntitiesChange.emit(this.selectedSGEntityIds);

        this.displaySGEntityError = this.selectedSGEntities.length < 1;

        if (isDiff && notifyOtherWindows) {
            this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.sgEntitiesChange, payload: this.selectedSGEntities });
        }

        if (forceUpdate) {
            setTimeout(() => {
                this.onEntitySelectorBlur();
            }, 0);
        }
    }

    public onEntitySelectorBlur() {
        this.selectedSGEntityIds = this.selectedSGEntities.map((entity) => entity.id);
        if (this.selectedSGEntityIds.length < 2) {
            this.hideScattergraph.emit();
        } else {
            this.scatterEntityBlur.emit();
        }
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public ngOnChanges(changes: SimpleChanges) {
        // check for specific keys and if they exist, do not process the route?
        if (this.customerId && this.checkForDisableKeys(Object.keys(changes))) {
            this.isLoading = true;
        }

        if (
            changes.locationGroupId &&
            changes.locationGroupId.currentValue &&
            changes.locationGroupId.currentValue !== changes.locationGroupId.previousValue
        ) {
            this.locationId = -1;
            this.isResetScale = true;
        }

        const onEntityDataChanged = () => {
            this.loadEntities();
            this.checkUserSettings();
            this.uiUtilService.safeChangeDetection(this.cdr);
        };

        if (changes.defaultCustomerId) {
            this.isResetScale = changes.defaultCustomerId.currentValue !== changes.defaultCustomerId.previousValue;
            // set customer id
            this.customerId = changes.defaultCustomerId.currentValue || this.customerId;

            if (
                changes.defaultSelectedLocation &&
                changes.defaultSelectedLocation.currentValue &&
                changes.defaultSelectedLocation.currentValue.lid
            ) {
                this.selectedLocation = changes.defaultSelectedLocation.currentValue;
                this.locationId = changes.defaultSelectedLocation.currentValue.lid;
                this.fetchPopupData();
            }

            if (changes.entityData && changes.entityData.currentValue) {
                onEntityDataChanged();
            }

            this.loadMruConfig();

            return;
        }

        if (changes.entityData && changes.entityData.currentValue && changes.entityData.previousValue) {
            const currentEntData: LocationEntitiesData = changes.entityData.currentValue;
            const prevEntData: LocationEntitiesData = changes.entityData.previousValue;

            const sameCustomer = currentEntData.l.length === prevEntData.l.length && currentEntData.l.every(v => prevEntData.l.find(x => x.lid === v.lid));

            let locationChange = false;
            if (
                changes.defaultSelectedLocation &&
                changes.defaultSelectedLocation.currentValue &&
                changes.defaultSelectedLocation.currentValue.lid
            ) {
                this.selectedLocation = changes.defaultSelectedLocation.currentValue;
                this.locationId = changes.defaultSelectedLocation.currentValue.lid;

                this.fetchPopupData();
                locationChange = true;
            }


            onEntityDataChanged();

            if (this.selectedLocation) {
                this.isEchoLocation = this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.echo ;
                this.isRAIII = this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.rainAlert ;
                this.usgsSetEntities = this.selectedLocation.s === USGS;
                this.foresiteSetEntities = this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex;
                this.setDefaultEntitiesByPermission();
                this.notifyParentComponent(locationChange);
            }
            return;
        }

        if (
            changes.defaultSelectedLocation &&
            (!changes.defaultSelectedLocation.previousValue ||
                (changes.defaultSelectedLocation.currentValue &&
                    changes.defaultSelectedLocation.currentValue.lid &&
                    changes.defaultSelectedLocation.currentValue.lid !==
                    changes.defaultSelectedLocation.previousValue.lid) ||
                (changes.locationId &&
                    changes.locationId.currentValue &&
                    changes.locationId.currentValue !== changes.locationId.previousValue))
        ) {
            onEntityDataChanged();
        }
        this.setMonitorSeries();

        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    // Framework level life cycle hook.
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public openCustomerLink() {
        window.open(this.workOrderLink, '_blank');
    }

    public getLocationName(lid: number): string {
        if (!this.allLocations) {
            return '';
        }
        const loc = this.allLocations.find((l) => l.lid === lid);

        return loc ? loc.n : '';
    }

    public applyFilterPublic(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.locationConfigsPublic.filter = filterValue.trim().toLowerCase();

        if (this.locationConfigsPublic.paginator) {
            this.locationConfigsPublic.paginator.firstPage();
        }
    }

    public applyFilterPrivate(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.locationConfigs.filter = filterValue.trim().toLowerCase();

        if (this.locationConfigs.paginator) {
            this.locationConfigs.paginator.firstPage();
        }
    }

    public onUnselectLocation(foundItem: Selectable) {
        const foundLocationItem = this.locations.find((location: Locations) => location.locationId === foundItem.id);
        this.locationsRemovedForOverlay.push(foundLocationItem);
    }

    public onSelectLocation(selectedLocationSelector: Selectable) {
        const foundLocationItem = this.locations.find(
            (location: Locations) => location.locationId === selectedLocationSelector.id,
        );
        this.locationsSelectedForOverlay.push(foundLocationItem);
    }

    public onFilterLocationsUpdated(filterLocations: Selectable[]) {
        this.preselectedLocationItems = filterLocations;
        // extract selected items
        const selectedItem = filterLocations[filterLocations.length - 1];

        // map selected item to a location
        if (selectedItem) {
            const foundLocation = this.locations.find((location: Locations) => location.locationId === selectedItem.id);
            if (!foundLocation) {
                return;
            }
            this.locationId = foundLocation.locationId;
            this.locationName = foundLocation.locationName;
            this.uiUtilService.safeChangeDetection(this.cdr);
        }
    }

    /**
     * Below function will set locations on Locations Template Markup. By Default first location will be selected on location input field.
     * @param locations -> Array of locations which will be get from Locations API
     */
    private setLocationsOnTemplate() {
        if (!this.locations || this.locations.length < 1) {
            return;
        }

        this.locations[0].status = LocationStatus.Active;

        this.selectableLocations = this.locations.map(
            (loc: Locations) =>
                <Selectable>{
                    id: loc.locationId,
                    name: loc.locationName,
                    isComposite: loc.locationType === 3,
                },
        );

        // if dashboard is loaded with stored items.
        if (this.filterWidgetService.getisEmptyDashboardState()) {
            this.preselectedLocationItems = JSON.parse(sessionStorage.getItem('preselectedLocationItems'));
            if (this.preselectedLocationItems) {
                const locationIdArray = new Array<number>();
                this.preselectedLocationItems.forEach((selectableitem: Selectable, index) => {
                    locationIdArray.push(selectableitem.id);
                });
                this.selectableLocations.forEach(({ name, id }: Selectable) => {
                    const isChecked = locationIdArray.includes(id);
                    this.selectableLocationsUpdated.push({
                        id,
                        name,
                        isChecked,
                    });
                });
                this.uiUtilService.safeChangeDetection(this.cdr);
            }
            // location is loaded from dynamic dashboard.
        } else if (this.locationIdForOverlay !== undefined) {
            const preselectedLocationItemForOverlay = {
                id: this.selectableLocations.find((x) => x.id === this.locationIdForOverlay).id,
                isChecked: true,
                name: this.selectableLocations.find((x) => x.id === this.locationIdForOverlay).name,
            };
            this.preselectedLocationItems.push(preselectedLocationItemForOverlay);
            this.selectableLocations.forEach((selectableitem: Selectable, index) => {
                const isChecked = selectableitem.id === this.locationIdForOverlay ? true : false;
                this.selectableLocationsUpdated.push({
                    id: selectableitem.id,
                    name: selectableitem.name,
                    isChecked: isChecked,
                });
            });
            this.preselectedLocationItems = this.selectableLocationsUpdated.filter((item) => item.isChecked);
            this.uiUtilService.safeChangeDetection(this.cdr);
        }
        // set label for selected lcoations.
        if (this.preselectedLocationItems && this.preselectedLocationItems.length > 0) {
            this.selectedLocationNames = '';
            this.preselectedLocationItems.forEach((selectableitem: Selectable, index) => {
                this.selectedLocationNames =
                    this.preselectedLocationItems.length - 1 === index
                        ? this.selectedLocationNames + selectableitem.name
                        : this.selectedLocationNames + selectableitem.name + ', ';
            });
        }

        const foundLocation = this.locations.find((loc: Locations) => loc.locationId === this.locationId);
        if (this.locationId && foundLocation) {
            // set selected location name
            this.locationName = foundLocation.locationName;
        } else {
            //  set the first location as selected location
            this.locationId = this.locations[0].locationId;
            this.locationName = this.locations[0].locationName;

            if(!this.installationShapeId && this.locationId && this.customerId){
                this.subscriptions.push(
                    this.mapService.getMarkerLocationDetails(this.locationId, this.customerId).subscribe(
                        (result: LocationDetails) => {
                            if (
                                !(
                                    result === undefined ||
                                    result === null ||
                                    result.locationID === undefined ||
                                    result.locationID === 0
                                )
                            ) {
                                this.installationShapeId = result.installationShapeTypeID;
                                this.setDefaultEntitiesByPermission();
                                this.isEntitiesSelected();
                            }

                        },
                        () => {
                            this.isLoading = false;
                        },
                    ),
                );
            }


            this.onChangeLocation(this.locationName);
            if (!this.locationId) {
                this.isComposite = this.locations[0].locationType === 3;
                this.uiUtilService.safeChangeDetection(this.cdr);
                if (window.location.pathname.indexOf('viewDataDynamic') < 0) {
                    this.statusCodeService.isCustomerChanged.next(true);
                    if (
                        this.statusCodeService.isCustomerChanged.getValue() ||
                        this.statusCodeService.isLocationGroupChanged.getValue()
                    ) {
                        this.notifyParentComponent();
                        this.statusCodeService.isCustomerChanged.next(false);
                        this.statusCodeService.isLocationGroupChanged.next(false);
                    }
                }
            }
        }
    }

    private isEntitiesSelected(){
        if (this.selectedEntities.length < 1) {
            this.displayEntityError = true;
            this.hideHydrograph.emit();
        } else {
            this.displayEntityError = false;
        }
    }

    private loadEntities(): void {
        if (!this.entityData || !this.entityData.l || this.entityData.l.length < 1) {
            return;
        }

        this.isLoading = true;

        this.locations = this.entityData.l.map(
            (locData: LocationData) =>
                <Locations>{
                    locationId: locData.lid,
                    name: locData.n,
                    locationName: locData.n,
                    latitude: locData.lat,
                    longitude: locData.lon,
                    locationType: locData.t,
                },
        );

        this.setLocationsOnTemplate();

        // check if selected location is selected
        // if not, select it
        if (!this.selectedLocation) {
            this.selectedLocation =
                this.entityData.l.find((loc: LocationData) => loc.lid === this.locationId) || this.entityData.l[0];
            // this is being overwriten for a reason in case location id was not pre-selected
            this.locationId = this.selectedLocation.lid;

             this.setMonitorSeries();

            this.fetchPopupData();
        }

        // #38812 Enable or disable not RAIII options
        this.isRAIII = this.selectedLocation.s?.toLowerCase() === RAIN_ALERT_III.toLowerCase();

        this.foresiteSetEntities = this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex;

        this.isComposite = this.selectedLocation.t === 3 ? true : false;

        this.processEntities();
        this.GetMostRecentApprovalData();
    }

    private fetchPopupData() {
        if (!this.customerId) return;

        this.mapService.getMarkerLocationDetails(this.selectedLocation.lid, this.customerId).subscribe((details: LocationDetails) => {
            this.popoverDetails = details;
        });
    }

    private processEntities() {
        if (!this.entityData || !this.entityData.l || this.entityData.l.length < 1) return;
        if (!this.selectedLocation) return;

        const isUSGS = this.selectedLocation.s ? this.selectedLocation.s === USGS : (this.isUSGS && this.isUSGS.getValue());
        const selectedDevices = this.entityService.getSelectedDevices(
            this.entityData,
            isUSGS ? USGS : this.selectedLocation.s,
            this.selectedLocation.t,
        );

        // ensure selected device
        if (!selectedDevices || selectedDevices.length < 1 || !selectedDevices[0].g) {
            this.isLoading = false;

            this.uiUtilService.safeChangeDetection(this.cdr);

            this.notifyParentComponent();

            return;
        }

        const location = this.entityData.l.find((x) => x.lid === this.locationId);
        if (!location) {
            return;
        }
        const ansrEntities = location.ae;
        if (this.isComposite && !this.userHasRawDataPermission) {
            const QFinalOnly = [...selectedDevices[0].g];
            QFinalOnly[0].entities = [
                ...COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS.map((v) => ({ e: v.name, color: '#000000', id: v.id })),
            ];
            this.availableEntities$.next(this.entityService.seriesEntityGroupToEntitySelectorObject(QFinalOnly));
        } else {
            this.availableEntities$.next(
                this.entityService.seriesEntityGroupToEntitySelectorObject([...selectedDevices[0].g]),
            );
        }

        this.availableANSREntities$.next(
            this.entityService.ansrEntityGroupToEntitySelectorObject([...ansrEntities], [], true),
        );
        this.allAvailableSGEntities = this.entityService
            .seriesEntityGroupToEntitySelectorObject([...selectedDevices[0].g])
            .filter((v) => v.groupId === EntityGroupId.Depth || v.groupId === EntityGroupId.Velocity);

        this.listenFilterValues();
        this.listenUserSettings();

        if (this.annotationSettings.isConfirmationPoints) {
            this.scatterConfirmationData.emit({ filter: this.confirmationFilter, isConfirmationPoints: true, reloadHyg: this.isConfirmPointsDateChanged });
        }

        this.isLoading = false;

        this.checkPreviousEntitiesOnLocationChange();
        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    public onHGSelectorBlur() {
        // #22637 - update Ids ONLY on user change, otherwise keep them loaded from configuration
        this.selectedEntityIds = this.selectedEntityIds = this.selectedEntities.map((entity) => entity.id);
    }


    public customizeEntities(entities: EntitySelectorEntity[]){
        this.customizedEntities = entities;
    }

    public selectEntities(entities: EntitySelectorEntity[], notifyOtherWindows = true, syncWithSg = true) {
        const isEntitiesChanged = this.prevSelectedEntities.length !== entities.length || !entities.every(v => this.prevSelectedEntities.find(x => x.id === v.id));
        if (this.isDataEditingEnabled && isEntitiesChanged) {
            this.delaySyncEntities.emit(null);
        }
        // First compare to see if changes were made
        // Only update if changes were in fact made
        if (this.isComposite) {
            // Separately track composite locations...
            if (this.userHasRawDataPermission) {
                if (
                    entities.findIndex((x) => x.id === COMPOSITE_SELECTED_ENTITIES[0]['id']) !== -1 ||
                    entities.findIndex((x) => x.id === COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS[0]['id']) !== -1
                ) {
                    this.selectedEntities = [...entities];
                } else {
                    this.selectedEntities = [...COMPOSITE_SELECTED_ENTITIES];
                }
            } else {
                this.selectedEntities = [...COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS];
            }
        } else if (this.foresiteSetEntities) {
            if (isEntitiesChanged) {
                this.prevSelectedEntities = [...this.selectedEntities];
                this.selectedEntities = [...entities];
            }
        } else if (this.usgsSetEntities) {
            // UGGS location, allow them to select anything within USGS entities
            this.selectedEntities = [...entities];
        } else {
            // Not a composite location
            if (
                // Nothing has changed
                this.prevSelectedEntities.length === entities.length &&
                entities.every((x) => this.prevSelectedEntities.findIndex((y) => y.id === x.id) !== -1)
            ) {
                return;
            }

            this.selectedEntities = [];
            if (this.userHasRawDataPermission) {
                if (
                    entities.length === COMPOSITE_SELECTED_ENTITIES.length &&
                    entities.every((x) => COMPOSITE_SELECTED_ENTITIES.findIndex((y) => y.id === x.id) !== -1)
                ) {
                    // If switching from composite back to normal location, reset the master list
                    this.prevSelectedEntities.forEach((x) => {
                        this.selectedEntities.push({
                            id: x.id,
                            name: x.name,
                            groupId: x.groupId,
                        });
                    });
                } else {
                    // Otherwise update the master list
                    entities.forEach((x) => {
                        this.selectedEntities.push({
                            id: x.id,
                            name: x.name,
                            groupId: x.groupId,
                        });
                    });

                    this.prevSelectedEntities = [...this.selectedEntities];
                }
            } else {
                if (
                    entities.length === COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS.length &&
                    entities.every(
                        (x) => COMPOSITE_SELECTED_ENTITIES_NO_PERMISSIONS.findIndex((y) => y.id === x.id) !== -1,
                    )
                ) {
                    // If switching from composite back to normal location, reset the master list
                    this.prevSelectedEntities.forEach((x) => {
                        this.selectedEntities.push({
                            id: x.id,
                            name: x.name,
                            groupId: x.groupId,
                        });
                    });
                } else {
                    // Otherwise update the master list
                    entities.forEach((x) => {
                        this.selectedEntities.push({
                            id: x.id,
                            name: x.name,
                            groupId: x.groupId,
                        });
                    });
                    this.prevSelectedEntities = this.selectedEntities;
                }
            }
        }
        // #22533 If previous was USGS then do not overwrite them
        if (!this.usgsSetEntities) {
            this.selectedEntities = this.selectedEntities.filter(
                (v, i) => i === this.selectedEntities.findIndex((x) => x.id === v.id),
            );
            this.prevSelectedEntities = this.prevSelectedEntities.filter(
                (v, i) => i === this.prevSelectedEntities.findIndex((x) => x.id === v.id),
            );
        }
        if(entities.length === 0 && this.selectedEntities.length === 0){
            if(this.usgsSetEntities){
                this.selectedEntities = this.userHasRawDataPermission ? [...USGS_SELECTED_ENTITIES] : [...USGS_SELECTED_ENTITIES_NO_PERMISSIONS];
            }else if(this.isRAIII){
                this.selectedEntities = this.userHasRawDataPermission ? [...DEFAULT_SELECTED_RAIN_ENTITIES] : [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            }else if(this.isEchoLocation){
                this.selectedEntities = this.userHasRawDataPermission ? [...DEFAULT_SELECTED_ECHO_ENTITIES] : [...DEFAULT_SELECTED_ENTITIES_NO_PERMISSIONS];
            }
        }
        if (this.foresiteSetEntities) {
            this.selectedEntities = this.selectedEntities.filter(
                (v, i) => i === this.selectedEntities.findIndex((x) => x.id === v.id),
            );
            this.prevSelectedEntities = this.prevSelectedEntities.filter(
                (v, i) => i === this.prevSelectedEntities.findIndex((x) => x.id === v.id),
            );
        }

        this.selectedEntityIds = this.selectedEntities.map(v => v.id);

        const sgEntitiesBeforeSync = [...this.selectedSGEntityIds];
        if (syncWithSg && !this.isDataEditingEnabled) {
            this.syncSGEntities(this.selectedEntities, notifyOtherWindows);
        } else if (syncWithSg) {
            this.syncEntitiesToSGwithinDE(this.selectedEntities);
        }

        const sgEntitiesChanged = this.selectedSGEntityIds.length !== sgEntitiesBeforeSync.length ||
            this.selectedSGEntityIds.some(v => !sgEntitiesBeforeSync.includes(v));

        if (this.selectedEntities.length < 1) {
            this.displayEntityError = true;
            this.hideHydrograph.emit();
        } else {
            this.displayEntityError = false;
            this.notifyParentComponent(false, sgEntitiesChanged);
        }

        if (notifyOtherWindows) {
            this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.hgEntitiesChange, payload: this.selectedEntities });
        }
    }

    public onDateRangeClosed() {
        const secondaryLocsData = this.dataEditService.otherLocationsData$.getValue();

        if (secondaryLocsData && secondaryLocsData.length) {
            const syncedData = secondaryLocsData.filter(v => v.syncDates === true);
            const notSyncedData = secondaryLocsData.filter(v => !v.syncDates);

            const toRemove = notSyncedData.filter(v => {
                if (new Date(v.start).getTime() >= new Date(this.startDate).getTime() && new Date(v.end).getTime() <= new Date(this.endDate).getTime()) {
                    return false;
                }

                return true;
            }).map(v => v.id);

            if (toRemove.length) {
                this.snackBar.open(this.translate.instant('COMMON.FILTER_SECONDARY_LOCS_DATA_ON_DATE_CHANGE_MSG'), this.dismissButton, {
                    panelClass: 'custom-error-snack-bar',
                    duration: 10000
                });

                const filteredData = secondaryLocsData.filter(v => !toRemove.includes(v.id));
                this.dataEditService.otherLocationsData$.next(filteredData);
            }

            // if date range is changed, we need to reload secondary locations data with new date range
            const toUpdateIds = syncedData.filter(v => {
                if (v.start.getTime() !== this.startDate.getTime() || v.end.getTime() !== this.endDate.getTime()) {
                    return true;
                }

                return false;
            }).map(v => v.id);


            secondaryLocsData.forEach(v => {
                if (toUpdateIds.includes(v.id)) {
                    v.start = this.startDate;
                    v.end = this.endDate;
                }
            });
            this.secondaryLocationsDialogData = this.secondaryLocationsDialogData.filter((v) => !toRemove.includes(v.id));

            if (toUpdateIds.length) {
                let updateModels: SeriesProperty[] = this.secondaryLocationsDialogData.filter(v => toUpdateIds.includes(v.id));
                updateModels = updateModels.map(v => ({ ...v, startDate: this.startDate, endDate: this.endDate }));

                updateModels = updateModels.map((item: SeriesProperty) => {
                    const params: HydrographArgs = {
                        start: item.startDate,
                        end: item.endDate,
                        entityIds: [item.eid],
                        locationId: item.lid
                    }

                    const request = this.viewDataService.getHydrograph(this.customerId, item.lid, params);
                    return { ...item, request };
                });

                combineLatest(updateModels.map(v => v.request)).pipe(
                    map((responses) => responses.map((v, i) => ({ ...updateModels[i], data: v, originLocationId: this.locationId })))
                ).subscribe((results: SeriesProperty[]) => {
                    this.updateOtherLocationsData.emit(results);
                });
            }
        }

        this.viewDataService.cachedFiltersGet().startDate = this.startDate;
        this.viewDataService.cachedFiltersGet().endDate = this.endDate;

        this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.dateClose, payload: { start: this.startDate, end: this.endDate } });
        this.notifyParentComponent();
    }

    public onSiltChange() {
        this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.siltChange, payload: this.annotationSettings.isSilt });
        this.notifyParentComponent();
    }

    public notifyParentComponent(fromLocationChange = false, sgEntitiesChanged = false) {
        let allAvailableEntites = [...this.availableEntities$.getValue(), ...this.availableANSREntities$.getValue()];

        allAvailableEntites = allAvailableEntites.filter(
            (v, i) =>
                i ===
                allAvailableEntites.findIndex(
                    (x) => x.groupId === v.groupId && x.entities.length === v.entities.length,
                ),
        );
        const selectedEntities = this.selectedEntities.filter((entity) =>
            allAvailableEntites.some((availableEntityGroup) =>
                availableEntityGroup.entities.some((availableEntity) => availableEntity.id === entity.id),
            ),
        );

        if (!this.entityData) {
            return;
        }
        const foundLoc = this.entityData.l.find((x) => x.lid === this.locationId) || this.entityData.l[0];

        if (!foundLoc) {
            return;
        }
        const isEchoSeries = !foundLoc.s || foundLoc.s.toLowerCase().startsWith('echo');

        this.dataAveragingText = this.dataAveragingList.find((x) => x.id === this.summarizeInterval).text;

        if (this.annotationSettings.isSilt && selectedEntities.length > 0) {
            if (this.entityService.isSiltAvailable) {
                selectedEntities.push(siltEntityInfo);
            } else {
                this.snackBar.open(this.siltError, this.dismissButton, {
                    panelClass: 'custom-error-snack-bar',
                });
            }
        }
        this.locationDashboardFilterData = <LocationDashboardFilterData>{
            startDate: new Date(this.startDate),
            endDate: new Date(this.endDate),
            locationIDs: [this.locationId],
            tickInterval: this.tickInterval,
            entities: selectedEntities,
            locationName: this.locationName,
            annotationsSettings: { ...this.annotationSettings },
            isCompositeLoc: this.isComposite,
            isResetScale: this.isResetScale,
            summarizeInterval: this.summarizeInterval,

            // the following is ;set for graph to know if echo so it can hide scattergraph
            seriesType: isEchoSeries ? SeriesType.Echo : SeriesType.Unknown,
        };

        this.viewDataService.filterValues.next(this.locationDashboardFilterData);
        if (this.selectedEntities && this.selectedEntities.length > 0) {
            this.notifyViewData.emit({
                filtersData: this.locationDashboardFilterData,
                // Fixes #21964 reload SG on load configuration
                forceAPICall: true,
                fromLocationChange,
                reloadSg: sgEntitiesChanged
            });
            this.isResetScale = false;
            const hydrographArgs: HydrographArgs = {
                start: undefined,
                end: undefined,
                entityIds: undefined,
                locationId: undefined,
                summarizeInterval: this.locationDashboardFilterData.summarizeInterval,
            };
            this.viewDataService.storeHydroGraphFilterData(hydrographArgs);
        } else if (selectedEntities.length === 0 && fromLocationChange) {   // #33830 data from last location was displayed when switching to no entity location
            this.hideHydrograph.emit();
        }

        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    private cacheConfirmationFilter() {
        this.viewDataService.cachedFiltersGet().confirmationFilter = this.confirmationFilter;
        this.viewDataService.cachedFiltersGet().selectedConfirmationEntity = this.selectedConfirmationEntity;
    }

    // Method notifies the parent component of annotation selections changes
    public annotationChange(notifyOtherWindows = true) {
        if (this.annotationSettings.isConfirmationPoints) {
            this.scatterConfirmationData.emit({ filter: this.confirmationFilter, isConfirmationPoints: true, reloadHyg: this.isConfirmPointsDateChanged });
        } else {
            this.scatterConfirmationData.emit({ filter: {}, isConfirmationPoints: false });
        }
        this.notifyAnnotationSelection.emit({ ...this.annotationSettings });
        this.cacheConfirmationFilter();

        if (notifyOtherWindows) {
            this.separateWindowHydrographService.dispatchAction<AnnotationSettings>({
                type: SeparateWindowActionTypes.annotationChange,
                payload: this.annotationSettings
            });
        }
    }

    public onConfirmationEntityChange(event: MatSelectChange) {
        this.viewDataService.cachedFiltersGet().selectedConfirmationEntity = event.value;
        this.confirmationEntityChange.emit(event.value);
    }

    public eventChange() {
        this.notifyEventsChange.emit(this.isShowEvents);
        const oldSettings = this.userService.userSettings.getValue();

        oldSettings.showEvents = this.isShowEvents;
        this.userService.updateUserSettings({ ...oldSettings }).subscribe();
        this.userService.userSettings.next({ ...oldSettings });
    }

    // #22650 forceChange to force reload as GIS Quick Look won't refresh same location on page change
    public onChangeLocationManual(name: string, forceChange = false) {
        const newlySelectedLocation = this.entityData.l.find((loc: LocationData) => loc.n === name);

        if (!newlySelectedLocation && (!this.selectedLocation || (!forceChange && newlySelectedLocation && newlySelectedLocation.lid === this.locationId))) {
            this.loadEntities();
            this.notifyParentComponent(); // Ensure load graphs on initial load
            return;
        }
        this.selectedLocation = newlySelectedLocation;
        this.locationId = this.selectedLocation.lid;
        this.locationName = this.selectedLocation.n;
        this.isComposite = this.selectedLocation.t === 3 ? true : false;
        // set location change data
        this.statusCodeService.currentLocationId.next(this.locationId);
        this.selectedLocationId = this.locationId;
        this.loadEntities();
        this.checkUserSettings();
        this.uiUtilService.safeChangeDetection(this.cdr);
        this.onChangeLocation(name, forceChange);
    }

    // #22650 forceChange to force reload as GIS Quick Look won't refresh same location on page change
    public onChangeLocation(name: string, forceChange = false, notifyOtherWindows = true) {
        this.viewDataService.lockSGCurve.next(false);
        this.isResetScale = true;

        if(this.series) {
            this.checkGainEligibility();
        }

        if(!this.series && this.customerId && this.locationId && this.customerId !== 0 && this.locationId !== 0) {
            this.locationService
                .getLocationDetailsV2(this.customerId, this.locationId)
                .subscribe((res: LocationDetails[]) => {
                    if (res && res[0]) {
                        this.series = res[0].series;
                        this.checkGainEligibility();
                        }
                    });
        }


        const newlySelectedLocation = this.entityData.l.find((loc: LocationData) => loc.n === name);

        if (!newlySelectedLocation) {
            return;
        }

        // #38812 Enable or disable not RAIII options
        this.isRAIII = newlySelectedLocation.s?.toLowerCase() === RAIN_ALERT_III.toLowerCase();

        if ((!this.selectedLocation || (!forceChange && newlySelectedLocation && newlySelectedLocation.lid === this.locationId))) {
            this.notifyParentComponent(); // Ensure load graphs on initial load
            return;
        }
        const prevMonitorSeries = (this.selectedLocation && this.selectedLocation.s) ? this.selectedLocation.s.toLowerCase() : '';
        this.selectedLocation = newlySelectedLocation;
        this.locationId = this.selectedLocation.lid;
        this.locationName = this.selectedLocation.n;
        this.isComposite = this.selectedLocation.t === 3 ? true : false;
        // set location change data
        this.statusCodeService.currentLocationId.next(this.locationId);
        this.selectedLocationId = this.locationId;
        this.fetchPopupData();

        this.usgsSetEntities = this.selectedLocation.s ? this.selectedLocation.s === USGS : (this.isUSGS && this.isUSGS.getValue());
        this.foresiteSetEntities = this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex;
        this.isRAIII =  this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.rainAlert ;
        this.isEchoLocation =  this.selectedLocation.s?.toUpperCase() === MonitorSeriesNames.echo ;
        this.locationChanged.emit({ location: this.selectedLocation, notify: notifyOtherWindows });

        // Update entity selector
        let deviceType = this.entityData.d.filter(
            (x) => this.selectedLocation.s && x.s.toLowerCase() === this.selectedLocation.s.toLowerCase(),
        )[0];
        if (!deviceType) {
            deviceType = this.entityData.d.find((x) => x.s.toLowerCase() === 'other');
        }

        const stdEntities = deviceType.g;
        const ansrEntities = this.entityData.l.find((x) => x.lid === this.locationId).ae;
        this.availableEntities$.next(this.entityService.seriesEntityGroupToEntitySelectorObject([...stdEntities]));
        this.availableANSREntities$.next(
            this.entityService.ansrEntityGroupToEntitySelectorObject([...ansrEntities], [], true),
        );
        this.allAvailableSGEntities = this.entityService
            .seriesEntityGroupToEntitySelectorObject([...stdEntities])
            .filter((v) => v.groupId === EntityGroupId.Depth || v.groupId === EntityGroupId.Velocity);

        if (prevMonitorSeries !== this.selectedLocation.s && !this.configEntitiesArePopulated) {
            this.checkPreviousEntitiesOnLocationChange();
        }
        if (this.configEntitiesArePopulated) {
            const hgEntitiesFromConfig: EntitySelectorEntity[] = this.availableEntities$.getValue().reduce((acc, curr) => {
                const entitiesFromConfig = curr.entities.filter(v => this.lastLoadedConfig.selected_entities.includes(v.id));

                return [...acc, ...entitiesFromConfig];
            }, []);
            const ansrEntitiesFromConfig: EntitySelectorEntity[] = this.availableANSREntities$.getValue().reduce((acc, curr) => {
                const entitiesFromConfig = curr.entities.filter(v => this.lastLoadedConfig.selected_entities.includes(v.id));

                return [...acc, ...entitiesFromConfig];
            }, []);

            this.selectedEntities = [...hgEntitiesFromConfig, ...ansrEntitiesFromConfig];
            this.selectedEntityIds = this.selectedEntities.map(v => v.id);
        }
        // no scattergraph for USGS, composite, RG locations
        const monitorType = (this.selectedLocation && this.selectedLocation.s) ? this.selectedLocation.s.toLowerCase() : null;
        if (!monitorType || monitorType.includes('composite') || monitorType.includes('usgs') || monitorType.includes('rain')) {
            this.hideScattergraph.emit();
        }

        this.series = '';
        this.checkGainEligibility();
        this.setMonitorSeries();

        this.notifyParentComponent(true);
    }

    public setMonitorSeries(){
        if (this.selectedLocation?.s) {
            this.monitorSeries = MonitorSeries.find(a=> a.value.toLowerCase() === this.selectedLocation.s.toLowerCase())?.text;
        }
    }

    public checkGainEligibility() {
        this.isVelocityGainDisabled = false;
        if (this.locationId && this.customerId && (this.series === MONITOR_SERIES_TYPES.TRITON_PLUS || (this.selectedLocation && this.selectedLocation.s === MONITOR_SERIES_TYPES.TRITON_PLUS))) {
            this.tritonLocationService.getTritonMonitorConfiguration(
                this.locationId,
                this.customerId,
            ).subscribe((monitorConfig: TritonConfiguration) => {
                this.isVelocityGainDisabled = false;
                const sensorsList = [...this.secondarySensors, ...this.primarySensors];

                const devices = monitorConfig?.devices?.filter((v) => sensorsList?.find((i) => v.name === i.value || v.name === i.altValue));
                const devicesArray = [ DeviceTypeCode.TritonSmartBat1, DeviceTypeCode.TritonSmartBat2, DeviceTypeCode.LongRangeDepth1, DeviceTypeCode.LongRangeDepth2 ]

                if (devices && devices.length === 1) {
                    const paraDepthCode = DeviceTypeCode.getDeviceTypeCode(devices[0].name);
                    if (devicesArray.includes(paraDepthCode)) {
                        this.isVelocityGainDisabled = true;
                    }
                }
            });
        }
    }

    public checkPreviousEntitiesOnLocationChange() {
        // fix bug #19975 need to refresh when change.
        // if (!prevSeries.includes('composite') || this.prevSelectedEntities.length) {
        //     return;
        // }

        this.setDefaultEntitiesByPermission();
    }

    // Handler for location change using dropdown for dynamic dashboard.
    public currentlySelectedLocations(selectables: Array<Selectable>) {
        // ensure something is selected
        if (!selectables || selectables.length < 1) {
            this.locationId = -1;
            return;
        }
        const filterLocations = selectables.filter((item) => item.isChecked);

        if (filterLocations.length === 0) {
            this.isUpdateChartInValid = true;
        } else {
            this.isUpdateChartInValid = false;
        }

        const locationIdArray = new Array<number>();
        filterLocations.forEach((selectableitem: Selectable) => {
            locationIdArray.push(selectableitem.id);
        });

        this.preselectedLocationItems.forEach((item) => {
            if (!locationIdArray.includes(item.id)) {
                const foundItem = item;
                // map selected item to a location
                const foundLocationItem = this.locations.find(
                    (location: Locations) => location.locationId === foundItem.id,
                );
                this.locationsRemovedForOverlay.push(foundLocationItem);
            }
        });
        const locationPreselectedIdArray = new Array<number>();
        this.preselectedLocationItems.forEach((selectableitem: Selectable) => {
            locationPreselectedIdArray.push(selectableitem.id);
        });
        // Case when user selects multiple locations from the dropdown.
        filterLocations.forEach((item, index) => {
            // if only on location is left out
            if (this.preselectedLocationItems && this.preselectedLocationItems.length === 1) {
                // extract selected items
                if (this.preselectedLocationItems[0].id !== item.id) {
                    const selectedLocationSelector = filterLocations[index];
                    // map selected item to a location
                    const foundLocationItem = this.locations.find(
                        (location: Locations) => location.locationId === selectedLocationSelector.id,
                    );
                    this.locationsSelectedForOverlay.push(foundLocationItem);
                }
            } else if (this.preselectedLocationItems && !locationPreselectedIdArray.includes(item.id)) {
                // extract selected items
                const selectedLocationSelector = filterLocations[index];
                // map selected item to a location
                if (selectedLocationSelector) {
                    const foundLocationItem = this.locations.find(
                        (location: Locations) => location.locationId === selectedLocationSelector.id,
                    );
                    this.locationsSelectedForOverlay.push(foundLocationItem);
                }
            }
        });

        this.preselectedLocationItems = filterLocations;
        // extract selected items
        const selectedItem = filterLocations[filterLocations.length - 1];

        // map selected item to a location
        if (selectedItem) {
            const foundLocation = this.locations.find((location: Locations) => location.locationId === selectedItem.id);
            if (!foundLocation) {
                return;
            }

            this.locationId = foundLocation.locationId;
            this.locationName = foundLocation.locationName;
        }
        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    public showAddNewGraph() {
        this.showAddNewGraphFlag = !this.showAddNewGraphFlag;
    }

    public showHideAnnotation(value: boolean) {
        this.displayAnnotations = value;
        this.isAnnotationOpen = true;
    }

    public close() {
        !this.isAnnotationOpen ? (this.displayAnnotations = false) : (this.isAnnotationOpen = false);
        this.showConfirmationDialog = false;
    }

    private updateConfirmationFilterTextOnDate(updateCache = false) {
        const defaultText = 'Show confirmations between: ';
        const { start, end } = this.confirmationFilter.dateRange;

        const formattedStartDate = this.datePipe.transform(start, this.customerDateFormat).split(' ')[0];
        const formattedEndDate = this.datePipe.transform(end, this.customerDateFormat).split(' ')[0];
        if(this.confirmationFilter.type === 'date')
        {
            this.confirmationFilterText = defaultText + formattedStartDate + ' - ' + formattedEndDate;
        }
        if(updateCache) {
            this.cacheConfirmationFilter();
        }
    }

    public confirmationMenuOpened() {
        this.isConfirmationMenuOpen = true;
        this.confimationDisplayFilter = JSON.parse(JSON.stringify(this.confirmationFilter));
        this.updateConfirmationFilterTextOnDate();
    }

    public confirmationDateChanged(event) {
        this.confimationDisplayFilter.dateRange.start = event.startDate.currentValue;
        this.confimationDisplayFilter.dateRange.end = event.endDate.currentValue;
    }

    public otherLocationsSeriesProperties: SeriesProperty[];

    public openOtherDataDialog() {
        if (this.noDataForHydrographChart) return;

        const prevData = this.getPreviousSecondaryLocationsData()
        const data: MultipleLocationsDialogData = {
            customerId: this.customerId, startDate: this.startDate,
            endDate: this.endDate, currentLocation: this.selectedLocation,
            prevData: JSON.parse(JSON.stringify(prevData))
        };

        this.matDialog.open(OtherLocationsGraphingDialogComponent, {
            disableClose: true,
            data,
            autoFocus: false
        }).afterClosed().subscribe((res: { success: boolean; result?: SeriesProperty[] }) => {
            if (!res || !res.success || !res.result) return;

            if (this.secondaryLocationsDialogData && res.result.length === 0) {
                this.dataEditService.otherLocationsData$.next(null);
                this.secondaryLocationsDialogData = null;

                return;
            }
            this.otherLocationsSeriesProperties = [...res.result];

            this.fetchOtherLocations();
        });
    }

    public fetchOtherLocations() {
        this.dataEditService.otherLocationsData$.next(null);

        // #33575 Set Data averaging in request
        this.otherLocationsSeriesProperties = this.otherLocationsSeriesProperties?.map(v =>
            ({...v, request: this.viewDataService.getOtherLocationHydrograph(this.customerId, v, this.summarizeInterval)}));

        combineLatest(this.otherLocationsSeriesProperties?.map(v => v.request)).pipe(
            map((responses) => responses.map((v, i) => (
                { ...this.otherLocationsSeriesProperties[i], data: v, originLocationId: this.locationId })))
        ).subscribe((results: SeriesProperty[]) => {
            results.forEach((v, i) => {
                v.id = i + 1;
            });

            this.loadOtherLocationsData.emit(results);
        });
    }

    private getPreviousSecondaryLocationsData() {
        if (!this.secondaryLocationsDialogData) return null;

        const secondaryDataForCurrentLocation = this.secondaryLocationsDialogData.filter(v => v.originLocationId === this.locationId);

        return (secondaryDataForCurrentLocation.length > 0) ? secondaryDataForCurrentLocation : null;
    }

    private setConfirmationDialogInitialValues() {
        const today = new Date();
        const from = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate(), 0, 0, 0);
        this.confirmationFilter.dateRange = {
            start: from,
            end: today,
        };
        this.confimationDisplayFilter.dateRange = this.confirmationFilter.dateRange;
    }

    public onConfirmationFilterChange(filterType: 'all' | 'date', setDefaults = true) {
        if (filterType === 'date') {
            if (this.datePickerComponent) {
                this.datePickerComponent.openMenu();
            }
        } else {
            this.confirmationFilterText = 'Show all confirmation points';
        }

        this.confimationDisplayFilter.type = filterType;
    }

    public onConfirmationDialogCancel() {
        this.confirmationMenuTrigger.closeMenu();
        if (this.datePickerComponent) {
            this.datePickerComponent.closeMenu();
        }
        this.isConfirmationMenuOpen = false;
    }

    public onConfirmationDialogAccept() {
        if (this.datePickerComponent) {
            this.datePickerComponent.closeMenu();
        }

        if (this.confimationDisplayFilter.dateRange && this.confirmationFilter.dateRange &&
            ((new Date(this.confimationDisplayFilter.dateRange.start).getTime() !== new Date(this.confirmationFilter.dateRange.start).getTime() ||
                new Date(this.confimationDisplayFilter.dateRange.end).getTime() !== new Date(this.confirmationFilter.dateRange.end).getTime()) || !this.annotationSettings.isConfirmationPoints)) {
            this.isConfirmPointsDateChanged = true;
        }

        this.confirmationFilter = this.confimationDisplayFilter;
        this.confirmationMenuTrigger.closeMenu();
        this.annotationSettings.isConfirmationPoints = true;
        this.isConfirmationMenuOpen = false;

        this.annotationChange();
        this.updateConfirmationFilterTextOnDate(true);
    }

    public confirmationDialogAcceptDisabled() {
        if (!this.confimationDisplayFilter.type) {
            return true;
        }

        if (this.confimationDisplayFilter.type === 'all') {
            return false;
        }

        if (!this.confimationDisplayFilter.dateRange.end || !this.confirmationFilter.dateRange.start) {
            return true;
        }

        return false;
    }

    public updateConfirmationFiltersDateByDashboard() {
        // Assign default dates, bug #21401
        this.defaultStartDate = this.startDate;
        this.defaultEndDate = this.endDate;

        this.dateRangeChange.emit(true);

        this.confimationDisplayFilter.dateRange = { start: this.startDate, end: this.endDate };
        this.confirmationFilter.dateRange = { start: this.startDate, end: this.endDate };
        this.scatterConfirmationData.emit({ filter: this.confimationDisplayFilter, isConfirmationPoints: this.annotationSettings.isConfirmationPoints, isFromDate: true });
        this.updateConfirmationFilterTextOnDate(true);
    }

    public addGraphlocationInputChanged(locationName: string) {
        // setup locationId
        if (this.locationId !== 0) {
            this.locationId = locationName ? this.locationId : this.locations[0].locationId;
        }
    }

    public refreshGraphs() {
        const filtersData = this.viewDataService.filterValues.getValue();
        this.notifyViewData.emit({
            filtersData,
            reloadSg: true,
            reloadHg: true
        });

        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    /**
     * This is used as a way to change the state in the component without actually using another component.
     * Tomek: bad practice
     */
    public checkForDisableKeys(keys): boolean {
        return keys.every((key) =>
            ['enableButtons', 'noDataForScatterChart', 'noDataForHydrographChart'].includes(key),
        );
    }

    public closeDataEditingMode() {
        this.domOperationUtilsService.hintPageName = NONE;
        this.cancelClick.emit();
    }

    private listenUserSettings() {
        const userSettingsSubs = this.userService.userSettings
            .pipe(
                filter((v) => !!v),
                distinctUntilChanged(),
            )
            .subscribe((settings: UserSettings) => {
                this.markEachDataPoint = settings.markDataPoints;

                this.isToolbarEnabled = settings.scattergraphToolbar;
                this.toolbarChange();
            },
            () => {},
            () => {
            });

        this.subscriptions.push(userSettingsSubs);
    }

    private toolbarChange() {
        this.showToolbar.emit(this.isToolbarEnabled);

        if (!this.isToolbarEnabled && !this.isDataEditingEnabled) {
            this.annotationSettings.isBestFit = false;
            this.annotationSettings.isSSCurve = false;
            this.annotationSettings.isManualLinear = false;
            this.annotationSettings.isManualSmooth = false;
            this.annotationSettings.isToleranceLines = false;
        }

        this.annotationSettings.isToolbarEnabled = this.isToolbarEnabled;
        this.notifyAnnotationSelection.emit({ ...this.annotationSettings });
    }

    public onShowToolbarChanged(event: MatSlideToggleChange) {
        const userSettings = this.userService.userSettings.getValue();
        const showToolbarSubs = this.userService.updateUserSettings({ ...userSettings, scattergraphToolbar: event.checked }).subscribe(() => {
            this.userService.userSettings.next({ ...userSettings, scattergraphToolbar: event.checked });
        });

        this.subscriptions.push(showToolbarSubs);
    }

    public onMarkEachPointChanged(event: MatSlideToggleChange) {
        const userSettings = this.userService.userSettings.getValue();
        const markersSubs = this.userService.updateUserSettings({ ...userSettings, markDataPoints: event.checked }).subscribe(() => {
            this.userService.userSettings.next({ ...userSettings, markDataPoints: event.checked });
        });

        this.subscriptions.push(markersSubs);
    }

    public acceptAllAutoCorrectedVal() {
        this.viewDataService.acceptAllAutoCorrectedVal.next(true);
    }

    public acceptDataEditing() {
        this.acceptClick.emit();
    }

    public clearFilter() {
        this.viewDataService.clearFilter.next(true);
    }

    public handleDataAveraging() {
        this.separateWindowHydrographService.dispatchAction<number>({ type: SeparateWindowActionTypes.dataAverageChange, payload: this.summarizeInterval });
        this.fetchOtherLocations();
        this.notifyParentComponent();
    }

    public openSiltEditor() {
        const data = {
            cid: this.customerId,
            lid: this.locationId,
            locationName: this.locationName,
            entities: this.selectedEntities.map((x) => x.id),
            endDate: this.endDate,
            startDate: this.startDate,
            user: this.userService.userName,
            isInverted: this.annotationSettings.isScatterInvert,
            curve: sgCurveType(this.annotationSettings)
        };

        const dialogRef = this.matDialog.open(SiltEditorComponent, {
            disableClose: true,
            data,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.updated) {
                this.refreshGraphData.emit(null);
                this.uiUtilService.safeChangeDetection(this.cdr);
            }
        });
    }

    public openBlockDataEditor() {
        const data: BlockDataEditorDialogData = {
            minDate: this.startDate,
            maxDate: this.endDate,
            startDate: this.startDate,
            endDate: this.endDate,
            locationName: this.locationName,
            locations: this.locations,
            locationId: this.locationId,
            customerId: this.customerId,
            isInverted: this.annotationSettings.isScatterInvert,
            curve: sgCurveType(this.annotationSettings),
            isRawVelExistsForLocation: this.isRawVelExistsForLocation,
            isElevationExistsForLocation: this.isElevationExistsForLocation
        };

        this.resetEditingParams.emit(null);
        this.domOperationUtilsService.blockEditorOpened = true;
        this.uiUtilService.scrollDisable();
        const dialogRef = this.matDialog.open(BlockDataEditorComponent, {
            data,
            disableClose: true,
            panelClass: 'no-padding-nor-overflow-relative-dialog',
            hasBackdrop: false,
        });

        dialogRef.afterClosed().subscribe((v) => {
            this.uiUtilService.scrollEnable();
            this.domOperationUtilsService.blockEditorOpened = false;
            if (v && v.result) {
                // #34314 Edits contains '0' value for flagged instead of boolean. Have to map them before go
                // EXAMPLE: {stamp: 1680314400000, id: 4202, value: 0, flagged: '0'}
                v.edits = v.edits.map(e => {return {...e, flagged: e.flagged === true ? true : e.flagged === false ? false : e.flagged === '1' ? true : false, edited: true}});
                this.editedPointsForSG.emit(v.edits);
                this.notifyViewData.emit({
                    enableAcceptButtonEditor: true,
                });
            }
        });
    }

    public togglePopover(show: boolean) {
        this.showPopover = show;
    }

    public openLocationNotesDialog() {
        const data: LocationNotesDialogData = {
            customerId: this.customerId,
            locationId: this.locationId,
            locations: this.locations
        };

        const canOpen = this.locationService.setOpenNotesDialog();


        if (canOpen) {
            this.matDialog.open(LocationNotesDialogComponent, {
                disableClose: true,
                data,
                hasBackdrop: false,
                panelClass: 'no-padding-nor-overflow-dialog',
            });
        }
    }

    public openRollbackEditor() {
        this.domOperationUtilsService.rollbackDialog = true;
        const data: RollbackEditorDialogData = {
            customerId: this.customerId,
            locationId: this.locationId,
            startDate: this.startDate,
            endDate: this.endDate,
            depthOnY: !this.annotationSettings.isScatterInvert,
            editingCurve: sgCurveType(this.annotationSettings) as number
        };

        this.uiUtilService.scrollDisable();
        const dialogRef = this.matDialog.open(RollbackEditorComponent, {
            disableClose: true,
            data,
            hasBackdrop: false,
            panelClass: 'no-padding-nor-overflow-dialog',
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.uiUtilService.scrollEnable();
            this.domOperationUtilsService.rollbackDialog = false;
            if (result && result.isReverted) {
                // need to emit reverted points to revert snapped points on scattergraph
                const response: DataEditPreview = result.response;

                this.editsReverted.emit({ points: result.points, response: response });
            }
        });
    }

    public toggleLoadConfiguration() {
        this.displayAnnotations = false;
        this.loadConfigOpen = !this.loadConfigOpen;
        if (this.loadConfigOpen) {
            this.uiUtilService.scrollDisable();
            this.isLoadConfigLoading = true;
            this.loadAllConfigs();
        } else {
            this.uiUtilService.scrollEnable();
        }
    }

    public loadAllConfigs() {
        this.configService.getDashboardConfig(this.customerId).subscribe((response: SavedConfiguration[]) => {
            this.locationConfigs = new MatTableDataSource(response.filter((x) => x.is_writable));
            this.locationConfigsPublic = new MatTableDataSource(response.filter((x) => x.is_public));

            //paginator
            this.locationConfigs.paginator = this.paginatorPrivate;
            this.locationConfigsPublic.paginator = this.paginatorPublic;

            // rewrite predicate \\
            this.locationConfigs.filterPredicate = (data: Element, filter: string) =>
                data['name'] && data['name'].toLowerCase().indexOf(filter) != -1;
            this.locationConfigsPublic.filterPredicate = (data: Element, filter: string) =>
                data['name'] && data['name'].toLowerCase().indexOf(filter) != -1;

            this.isLoadConfigLoading = false;
            this.uiUtilService.safeChangeDetection(this.cdr);
        });
    }

    public toggleSaveConfiguration() {
        this.displayAnnotations = false;
        this.saveConfigOpen = !this.saveConfigOpen;
        this.isPublicConfig = false;
        if (this.saveConfigOpen) {
            this.loadAllConfigs();
        }
        if (this.lastUsedConfig) {
            this.isPublicConfig = this.lastUsedConfig.is_public;
        } else {
            this.configName = '';
        }
    }

    public loadConfiguration(config, storeAsDefault = true, notifyChanges = true) {
        if (!this.customerId) {
            return;
        }
        this.isLoadConfigLoading = true;
        this.displayAnnotations = false;

        if (notifyChanges) {
            this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.loadConfig, payload: config });
        }
        this.configService.updateMostRecent(this.customerId, config.guid).subscribe((res) => {
            this.isLoadConfigLoading = false;
            this.loadConfigOpen = false;
            this.uiUtilService.scrollEnable();
            if (config.is_writable) {
                this.lastUsedConfig = config;
            }

            //load annotations
            this.annotationSettings.isHighHigh = config.annotations.allAlarmThresholdsHighHigh;
            this.annotationSettings.isHighFlow = config.annotations.allAlarmThresholdsHighFlow;
            this.annotationSettings.isHighLevel = config.annotations.allAlarmThresholdsHighLevel;
            this.annotationSettings.isLowDepth = config.annotations.allAlarmTHresholdsLowDepth;
            this.annotationSettings.isManholeDepth = config.annotations.manholeDepth;
            this.annotationSettings.isPipeHeight = config.annotations.pipeHeight;
            this.annotationSettings.isConfirmationPoints = config.annotations.confirmationPoints;
            this.annotationSettings.isDataQuality = config.annotations.dataQuality;
            this.annotationSettings.isRainOnTop = config.annotations.rainOnTop;
            this.annotationSettings.isSilt = config.annotations.silt;
            this.annotationSettings.isShowEdits = config.annotations.showEdits;
            this.annotationSettings.isScatterInvert = config.annotations.scatterInvert;
            this.annotationSettings.isFroude = config.annotations.fraudLines;
            this.annotationSettings.isBestFit = config.annotations.bestFitCurve;
            this.annotationSettings.isSSCurve = config.annotations.ssCurve;
            this.annotationSettings.isIsoQ = config.annotations.isoQLines;
            this.annotationSettings.isPipeOverlay = config.annotations.pipeOverlay;
            this.annotationSettings.isToleranceLines = config.annotations.toleranceLines;

            const newDates = this.dateService.setTimespanSetting(config.timeSpan);
            if (newDates.startDate) {
                this.startDate = newDates.startDate;
                this.viewDataService.cachedFiltersGet().startDate = this.startDate;
            }
            if (newDates.endDate) {
                this.endDate = newDates.endDate;
                this.viewDataService.cachedFiltersGet().endDate = this.endDate;
            }
            this.uiUtilService.safeChangeDetection(this.cdr);


            this.lastLoadedConfig = config;
            this.summarizeInterval = config.useDataAveraging;
            this.configEntitiesArePopulated = true;
            this.selectedEntityIds = config.selected_entities;

            if (config.selected_sg_entities) {
                this.selectedSGEntityIds = config.selected_sg_entities;
                this.selectedDefaultSGEntityIds = config.selected_sg_entities;
                this.previousSGEntities
                if (this.selectedSGEntityIds.length < 2) {
                    this.hideScattergraph.emit();
                } else {
                    this.scatterEntitiesChange.emit(this.selectedSGEntityIds);
                }
            }

            if ((config.cid = this.customerId)) {
                // TODO: Find out if this is still used ! Ask @Lee about it. Seems it never load location with ID, just send 0
                if (
                    !config.locationGroupID ||
                    !this.currentLocationGroup ||
                    this.currentLocationGroup.findIndex((x) => x.locationGroupID === config.locationGroupID) !== -1
                ) {
                    //   this.configService.loadLocationId.next(config.locationGroupID);
                } else {
                    //   this.configService.loadLocationId.next(0);
                    this.snackBar.open(
                        'The location group that was associated with this dashboard configuration no longer exists.',
                        this.dismissButton,
                        {
                            panelClass: 'custom-error-snack-bar',
                        },
                    );
                }
            }

            if (config.selectedCurve || config.selectedRange) {
                const dataTool = {};
                config.selectedCurve ? (dataTool['sCurve'] = JSON.parse(config.selectedCurve)) : {};
                config.selectedRange !== null && config.selectedRange !== undefined && config.selectedRange !== '' ? (dataTool['sRange'] = config.selectedRange) : '';
                config.selectedTolerance ? (dataTool['sTolerance'] = config.selectedTolerance) : '';
                config.selectedGenerationOptions
                    ? (dataTool['sGenerationOptions'] = config.selectedGenerationOptions)
                    : '';
                config.selectedSyncZoom ? (dataTool['sSyncZoom'] = config.selectedSyncZoom) : '';

                this.viewDataService.scatterToleranceRangeValue = config.selectedRange;
                this.viewDataService.loadScatterGraphToolData.next(dataTool);
            }
            if (notifyChanges) {
                this.notifyAnnotationSelection.emit({ ...this.annotationSettings });
            } else {
                this.notifyParentComponent();
            }

            this.uiUtilService.safeChangeDetection(this.cdr);

            if (storeAsDefault) {
                this.updateUserSettings(config.guid);
            }
            this.notifyParentComponent();
        });
    }

    public editConfig(config, name) {
        if (!name) {
            return;
        }
        config.name = name;
        this.configService.updateDashboardConfig(config).subscribe(
            (response) => {
                this.loadMruConfig();
                this.snackBar.open(`Configuration saved successfully.`, this.dismissButton, {
                    duration: 10000,
                });
                this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.updateConfigs, payload: null });
            },
            (err) => {
                this.snackBar.open(err.message, this.dismissButton, {
                    panelClass: 'custom-error-snack-bar',
                });
            },
        );
    }

    public openRecentsMenu(event) {
        if (!this.menuOpened) {
            this.menuOpened = true;
            event.target.click();
        }
    }

    public closeRecentsMenu(event) {
        if (event.target.id !== 'mru' && this.menuOpened && this.matMenu) {
            this.menuOpened = false;
            this.matMenu.closed.emit('click');
        }
    }

    public deleteConfig(config) {
        this.configService.deleteDashboardConfig(this.customerId, config.guid).subscribe((res) => {
            this.isLoadConfigLoading = true;
            this.configService.getDashboardConfig(this.customerId).subscribe((response) => {
                this.locationConfigs = new MatTableDataSource(response.filter((x) => !x.is_public || x.is_writable));
                this.locationConfigsPublic = new MatTableDataSource(
                    response.filter((x) => x.is_public && !x.is_writable),
                );
                this.isLoadConfigLoading = false;
                this.loadMruConfig();
                this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.updateConfigs, payload: null });
                this.uiUtilService.safeChangeDetection(this.cdr);
            });
        });
    }

    public loadMruConfig() {
        if (!this.customerId) {
            return;
        }
        this.subscriptions.push(
            this.configService.getMostRecent(this.customerId).subscribe((res) => {
                this.recentLoads = res;
            })
        );
    }

    public updateUserSettings(guid: string) {
        const newUserSettings = JSON.parse(JSON.stringify(this.userSettings));
        const customerSettings = newUserSettings.customerSettings.find((x) => x.cid === this.customerId);

        if (customerSettings) {
            customerSettings.lastViewedDashboardConfig = guid;
        }

        this.gisService.updateGisUserSettings(newUserSettings).subscribe();
    }

    private getDaysInMonth(m: number, y: number) {
        return /8|3|5|10/.test(m.toString()) ? 30 : m == 1 ? ((!(y % 4) && y % 100) || !(y % 400) ? 29 : 28) : 31;
    }

    public setTimespanSetting(timeSpan: number) {
        const now = new Date();
        if (timeSpan < 0) {
            this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            switch (timeSpan) {
                case TimeSpanNumeric.Today:
                    this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);
                    this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
                    break;
                case TimeSpanNumeric.LastWeek:
                    this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0);
                default:
                    break;
                case TimeSpanNumeric.PreviousMonth:
                    this.startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0);
                    this.endDate = new Date(
                        now.getFullYear(),
                        now.getMonth() - 1,
                        this.getDaysInMonth(this.startDate.getMonth(), now.getFullYear()),
                        0,
                        0,
                        0,
                    );
                    break;
                case TimeSpanNumeric.LastMonth:
                    this.startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0);
                    break;
                case TimeSpanNumeric.LastThreeMonths:
                    this.startDate = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate(), 0, 0, 0);
                    break;
                case TimeSpanNumeric.LastSixMonths:
                    this.startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate(), 0, 0, 0);
                    break;
                case TimeSpanNumeric.LastYear:
                    this.startDate = new Date(now.getFullYear(), now.getMonth() - 12, now.getDate(), 0, 0, 0);
                    break;
            }
        } else {
            this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - timeSpan, 0, 0, 0);
            this.endDate = now;
        }
    }

    public getTimespanSetting(start: Date, end: Date) {
        let timeSpan = Math.ceil(Math.abs((this.endDate as any) - (this.startDate as any)) / (1000 * 60 * 60 * 24));

        if (
            start.getFullYear() === end.getFullYear() &&
            start.getMonth() === end.getMonth() &&
            start.getDate() === 1 &&
            end.getDate() === this.getDaysInMonth(end.getMonth(), end.getFullYear())
        ) {
            timeSpan = TimeSpanNumeric.PreviousMonth;
        } else if (
            start.getFullYear() === end.getFullYear() &&
            start.getMonth() === end.getMonth() &&
            start.getDate() === end.getDate()
        ) {
            timeSpan = TimeSpanNumeric.Today;
        } else {
            let months = (end.getFullYear() - start.getFullYear()) * 12;
            months += -start.getMonth() + end.getMonth();
            const diffTime = Math.abs(end.getTime() - start.getTime());
            const minuteTime = 1000 * 60 * 60;
            const dayTime = minuteTime * 24;

            // Week here is last week + today, so 8 days
            if (diffTime > 8 * dayTime - minuteTime * 10 && diffTime < 8 * dayTime + minuteTime * 10) {
                timeSpan = TimeSpanNumeric.LastWeek;
            } else if (months === 1 && start.getDate() === 1 && end.getDate() === this.getDaysInMonth(end.getMonth(), end.getFullYear())) {
                timeSpan = TimeSpanNumeric.LastMonth;
            } else if (months === 3) {
                timeSpan = TimeSpanNumeric.LastThreeMonths;
            } else if (months === 6) {
                timeSpan = TimeSpanNumeric.LastSixMonths;
            } else if (months === 12) {
                timeSpan = TimeSpanNumeric.LastYear;
            } else {

            }
        }
        return timeSpan;
    }

    public preSaveConfig() {
        const prevConfig = this.locationConfigs.data.find(x => x.name === this.configName)
        if (prevConfig) {
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.attention,
                        message: this.cancelConfigEdit,
                        okText: this.okText,
                        cancelText: this.cancelText,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.saveConfiguration();
                    }
                });
        } else {
            this.saveConfiguration();
        }
    }

    private saveConfiguration() {
        const timeSpan = this.dateService.getTimespanSetting(this.startDate, this.endDate);

        const config: SavedConfiguration = {
            name: this.configName,
            selected_entities: this.selectedEntityIds,
            selected_sg_entities: this.selectedSGEntityIds,
            timeSpan: timeSpan,
            annotations: {
                allAlarmThresholdsHighHigh: this.annotationSettings.isHighHigh,
                allAlarmThresholdsHighFlow: this.annotationSettings.isHighFlow,
                allAlarmThresholdsHighLevel: this.annotationSettings.isHighLevel,
                allAlarmThresholdsLowDepth: this.annotationSettings.isLowDepth,
                manholeDepth: this.annotationSettings.isManholeDepth,
                pipeHeight: this.annotationSettings.isPipeHeight,
                dataQuality: this.annotationSettings.isDataQuality,
                confirmationPoints: this.annotationSettings.isConfirmationPoints,
                rainOnTop: this.annotationSettings.isRainOnTop,
                silt: this.annotationSettings.isSilt,
                showEdits: this.annotationSettings.isShowEdits,
                scatterInvert: this.annotationSettings.isScatterInvert,
                fraudLines: this.annotationSettings.isFroude,
                isoQLines: this.annotationSettings.isIsoQ,
                pipeOverlay: this.annotationSettings.isPipeOverlay,
                bestFitCurve: this.annotationSettings.isBestFit,
                ssCurve: this.annotationSettings.isSSCurve,
                toleranceLines: this.annotationSettings.isToleranceLines,
            },
            is_public: this.isPublicConfig,
            locationGroupID: this.locationGroupId,
            useDataAveraging: this.summarizeInterval,
            cid: this.customerId,
        };
        if (this.viewDataService.scatterGraphToolData) {
            const scatterGraphTooldata = this.viewDataService.scatterGraphToolData.getValue();
            if (scatterGraphTooldata) {
                config['selectedCurve'] = scatterGraphTooldata['sCurve']
                    ? JSON.stringify(scatterGraphTooldata['sCurve'])
                    : '';
                config['selectedRange'] = scatterGraphTooldata['sRange'] ? scatterGraphTooldata['sRange'] : 0;
                config['selectedTolerance'] = scatterGraphTooldata['sTolerance']
                    ? scatterGraphTooldata['sTolerance']
                    : '';
                config['selectedGenerationOptions'] = scatterGraphTooldata['sGenerationOptions']
                    ? scatterGraphTooldata['sGenerationOptions']
                    : 0;
                config['selectedSyncZoom'] = scatterGraphTooldata['sSyncZoom']
                    ? scatterGraphTooldata['sSyncZoom']
                    : false;
            }
        }

        if (this.lastUsedConfig && this.configName === this.lastUsedConfig.name) {
            config['guid'] = this.lastUsedConfig.guid;
        }
        const prevConfig = this.locationConfigs.data.find(x => x.name === this.configName)
        if (prevConfig) {
            config['guid'] = prevConfig.guid;
        }
        this.locationConfigs.filter
        this.configService.updateDashboardConfig(config).subscribe(
            (response) => {
                if (!response) return;

                this.loadMruConfig();

                this.updateUserSettings(response['guid'] || config['guid']);
                this.snackBar.open(`Configuration saved successfully.`, this.dismissButton, {
                    duration: 10000,
                });

                this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.updateConfigs, payload: null });
            },
            (err) => {
                this.snackBar.open(err.message, this.dismissButton, {
                    panelClass: 'custom-error-snack-bar',
                });
            },
        );
        this.toggleSaveConfiguration();
        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    public approveData() {
        const dialogRef = this.matDialog.open(ApproveDataDialogComponent, {
            disableClose: false,
            panelClass: 'no-padding-nor-overflow-dialog',
            hasBackdrop: true,
            data: { endDate: this.endDate, startDate: this.startDate, prevApprStartDate: this.prevApprovedStartDate, prevApprEndDate: this.prevApprovedEndDate, customDateFormat: this.customDateFormat },
        });

        dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((result: DataApprovalRange) => {
                if (result) {
                    let startDate = '';
                    if(result.startDate)
                    {
                        startDate = new Date(result.startDate.getTime() - result.startDate.getTimezoneOffset() * 60000).toISOString();
                        startDate = startDate.substring(0,19);
                        startDate += '.000Z';
                    }

                    let endDate = new Date(result.endDate.getTime() - result.endDate.getTimezoneOffset() * 60000).toISOString();
                    endDate = endDate.substring(0, 19);
                    endDate += '.000Z';
                    this.viewDataService.dataEditApproval(this.customerId, this.locationId, startDate, endDate).subscribe((data) => {
                        this.GetMostRecentApprovalData();
                        this.refreshGraphData.emit(null);
                    });
                }
            });
    }

    public openVelocityGainTable() {
        const velocityGainData = {
            customerId: this.customerId,
            isRawVelSelected: this.selectedEntities?.some(a =>a .id === RAW_VELOCITY_ENTITY),
            locationDetails: { locationID: this.locationId, locationName: this.locationName, startDate: this.startDate , endDate: this.endDate },
            user: this.userService.userName,
            showEditMenu: this.dataEditService.showEditMenu,
        };
        const dialogRef = this.matDialog.open<VelocityGainTableComponent, VelocityGainDialogData, VelocityGainTableComponentResponse>(VelocityGainTableComponent, { data: velocityGainData });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.success) {
                if(result.data) {
                    if(
                        (result.start >= this.startDate && result.start <= this.endDate)
                        ||
                        (result.end >= this.startDate && result.end <= this.endDate)
                    ) {
                        const originalData = this.dataEditService.handleAPIpreviewResponse(result.data, false, null, true);
                        this.resetOriginalData.emit(originalData);
                    }
                }

                this.refreshVelocityGainData.emit(null);
                this.uiUtilService.safeChangeDetection(this.cdr);
            }
        });
    }

    public onRecalculateEntities() {
        const { startDate, endDate, customerId, locationId } = this;
        const isRawVelRecalculated = false;
        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            panelClass: 'no-padding-nor-overflow-relative-dialog',
            data: { startDate, endDate, locationId, customerId, isRawVelRecalculated }
        };
        this.matDialog.open<RecalculateEntitiesComponent, RecalculateEntitiesComponentRequest, RecalculateEntitiesComponentResponse>(
            RecalculateEntitiesComponent, dialogOptions
        ).afterClosed().subscribe((res) => {
            if(!res.success) return;

            this.recalculateEntities.emit(res);
        });
    }
    public updateLocationId(locationid) {
        this.locationId = locationid;
    }
}
