// THIS IS THE ENTIRETY OF THE LOCATION DASHBOARD

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    HostListener,
    Inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
    DataEditType,
    OTHER,
    QCONTINUITY,
    VELOCITY,
    SILT_ENTITY,
    VELOCITY_ENTITY,
    DEPTH_ENTITY,
    RAIN_ENTITY,
    MANHOLE_DEPTH_ANNOTATION,
    PIPE_HEIGHT_ANNOTATION,
    EntityGroupId,
    RED_COLOR_HEX,
    ANNOTATION_MARKER_COLOR,
    LEVEL_ENTITY,
    RAW_VELOCITY_ENTITY,
    VELOCITY_DISPLAY_GROUP,
    DEPTH_DISPLAY_GROUP,
    RAIN_DISPLAY_GROUP,
    UnitOfMeasureType,
    ELEVATION_ENTITY,
    primeMeridian,
    TODAY,
} from 'app/shared/constant';
import { DynamicFilterWidgetService } from 'app/shared/services/dynamic-filter-widget.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { AdsPrismDataEditingTableComponent } from 'app/pages/data-editing-table/data-editing-table.component';
import { MinMaxAvgTotalTableComponent } from './min-max-avg-total-table/min-max-avg-total-table.component';
import { DataRowItem } from 'app/pages/data-editing-table/data-editing-table.model';
import { ConfirmationDateRange, ConfirmationDateRangeType, ViewDataFilterComponent } from 'app/pages/view-data/view-data-filter/view-data-filter.component';
import { first, filter, tap, map, debounceTime, combineLatestWith, throwIfEmpty } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { UsersService, USER_ROLES } from 'app/pages/admin/users.service';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { MapService } from 'app/shared/services/map.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { LocationService } from 'app/shared/services/location.service';
import { Approval, DataEditOriginalValue, DataEditStoreActionTypes, EntitiesUpdate, StoredEdit } from 'app/shared/models/data-edit';
import {
    DataExportFile,
    DataExportType,
    EntityExportType,
    LocationType,
    TelemetryService,
} from 'app/shared/services/telemetry.service';
import { Selectable, SelectableGroup } from 'app/shared/models/selectable';
import { ConfirmationEntitiesEnum, ViewDataFilterArgs } from 'app/shared/models/view-data-filter';
import {
    AnnotationSettings,
    ChartPointMap,
    compactData,
    DataEditingData,
    DataEditingReasons,
    DataEditingRow,
    EditedData,
    EntitySeries,
    OptimizedViewData,
    ScatterRevertData,
    SeparateWindowActionTypes,
} from 'app/shared/models/view-data';
import {
    IComponentCustomizedConfirmation,
    IComponentCustomizedConfirmationResult,
} from 'app/shared/models/customized-confirmation-box';
import { ActionTypes, AnnotationOptions, BasicSeriesData, DataType, HGGraphData } from 'app/shared/models/hydrographNEW';
import {
    LocationData,
    LocationEntitiesData,
    LocationListArgs,
    SeriesEntityGroup,
} from 'app/shared/models/locations-entities-data';
import { LocationGroup } from 'app/shared/models/location-group';
import {
    activeInactiveLocationQueryParam,
    AppQueryParams,
    Customer,
    customerLocationGroupQueryParam,
    customerQueryParam,
    isNewTab,
    locationIdQueryParam,
    parentId,
    reloadCacheQueryParam,
} from 'app/shared/models/customer';
import { HydrographArgs, ManualScale } from 'app/shared/models/hydrograph';
import { Entities } from 'app/shared/models/entities';
import { LocationDashboardFilterData } from 'app/shared/models/location-dashboard-filter-data';
import { VaultLocationExport } from 'app/shared/models/vault';
import { ScatterData } from 'app/shared/models/scatter-data';
import { DataEditLog, DataEditPreview, DataEditPreviewParams, UpdatePointForSG, UpdatePoints } from 'app/shared/models/data-edit';
import { AdvanceScattergraphContainerComponent } from 'app/pages/view-data/graphs/advance-scattergraph-container/advance-scattergraph-container.component';
import { DataDeleteComponent } from 'app/pages/view-data/graphs/data-delete/data-delete.component';
import { DataEditingComponent } from 'app/pages/view-data/graphs/data-editing/data-editing.component';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { Locations } from 'app/shared/models/locations';
import { LocationDetails } from 'app/shared/models/location-details';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { EventService } from 'app/shared/services/event.service';
import { EventModel, EventTypes } from 'app/shared/models/event';
import { AddEventDialogComponent } from 'app/pages/add-event-dialog/add-event-dialog.component';
import { environment } from 'app/environments/environment';
import { StringUtils } from 'app/shared/utils/string-utils';
import { HydrographNEWService } from 'app/shared/services/hydrographNEW.service';
import { SeriesProperty } from '../custom-dashboard/custom-dashboard-model/custom-dashboard.model';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import _, { isError } from 'lodash';
import { DOCUMENT } from '@angular/common';
import { LightningChartBuilder } from 'app/shared/components/hydrograph/lightning-chart-builder/lightning-chart-builder';
import { LightningChartObject } from 'app/shared/components/hydrograph/lightning-chart-object/lightning-chart-object';
import { LightningChartReceiver } from 'app/shared/components/hydrograph/lightning-chart-object/lightning-chart-receiver';
import { HydrographEditingMenuComponent } from 'app/shared/components/hydrograph-editing-menu/hydrograph-editing-menu.component';
import { LightningChartBuildDataConfig } from 'app/shared/components/hydrograph/lightning-chart-builder/lightning-chart-data-model';
import { HydrographDataBuilder } from 'app/shared/components/hydrograph/hydrograph-data/hydrograph-data-builder';
import { DisplayGroupScales } from 'app/shared/models/user-settings';
import { GainTableResponse } from 'app/shared/models/gain-data';
import { MathUtils } from 'app/shared/utils/math-utils';
import { RANGE_BUTTONS } from 'app/shared/components/hydrograph/hydrograph-tracer-panel/hydrograph-tracer-panel.component';
import { RecalculateEntitiesComponentResponse } from 'app/shared/components/recalculate-entities/recalculate-entities.component';
import { TrackBy } from 'app/shared/utils/track-by';

const EDIT_VIEW_DATA = 'EDIT_VIEW_DATA';
const RAINALERT = 'RainAlert III';
const OTHERTEXT = 'other';

@Component({
    templateUrl: './location-dashboard.component.html',
    styleUrls: ['./location-dashboard.component.scss', './location-dashboard-print.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDashboardComponent implements OnInit, OnDestroy, LightningChartReceiver {
    @Input() public locationIdFromDynamicDashboard: number;
    @Input() public filterValues: LocationDashboardFilterData;

    @ViewChild('advanceScatterGraph') public advanceScatterGraph: AdvanceScattergraphContainerComponent;
    @ViewChild('hydrographChartContainer') public hydrographChartContainer: ElementRef<HTMLDivElement>;
    @ViewChild('dataEditingAccordianPanel') public dataEditingAccordianPanel: MatExpansionPanel;
    @ViewChild('dataEditingTable') public dataEditingTableComponent: AdsPrismDataEditingTableComponent;
    @ViewChild('printSection') private elPrintSection: ElementRef;
    @ViewChild('trigger') public menuTrigger;
    @ViewChild(MatPaginator) public dataEditingPaginator: MatPaginator;
    @ViewChild(ViewDataFilterComponent) public viewDataFilter: ViewDataFilterComponent;
    @ViewChild(HydrographEditingMenuComponent) public editMenu: HydrographEditingMenuComponent;

    handToogleHydrograph: any;
    unit: object = { unit: 'in' };

    @HostListener('window:beforeprint', ['$event']) public onBeforePrint(event?) {
        this.isPrinting = true;
        if (this.viewDataService.advancedCharts.length === 1) {
            this.document.body.classList.add('one-page-print');
        }
        // Printing takes a screenshot of the screen to render
        // Since highcharts is not straight SVG, you need to adjust the chart pixel width

        setTimeout(() => {
            if (this.viewDataService.advancedCharts[1]) this.viewDataService.advancedCharts[1].setSize(650, 600, false);
        }, 0);
    }
    @HostListener('window:afterprint', ['$event']) public onAfterPrint(event) {
        this.isPrinting = false;
        document.body.className = document.body.className.replace('one-page-print','');
        // After printing remove hard sizes on charts
        setTimeout(() => {
            if (this.viewDataService.advancedCharts[1]) this.viewDataService.advancedCharts[1].setSize(null, null);
        }, 0);
    }

    lcChart: LightningChartObject;
    chartId = 1;
    fetchGraphSub: Subscription;

    private subscriptions = new Array<Subscription>();
    public enableDataEditing: boolean;
    public requiredComment: boolean;

    // hydrpgraph start and end dates for the summary section
    public hydrographStartDate: Date;
    public hydrographEndDate: Date;

    public prevApprStartDate: Date;
    public prevApprEndDate: Date;

    public approvedBy: string;
    public isErrorOnApproveData: boolean;

    public selectedEntities: SelectableGroup[];

    // generic options for entities having no information
    public customerId: number;
    public locationId: number;
    public locationGroupId: number;
    public locationName: string;
    public filterSettings: ViewDataFilterArgs = {
        isExportPrintEnabled: true,
        isDisplayExportMenu: false,
        isDisplayEntities: true,
        isDisplayDatePicker: true,
        isDisplayDataAveraging: true,
    };

    public prevFiltersData: LocationDashboardFilterData;
    public startDate: Date;
    public endDate: Date;
    private dataMin: number;
    public datesDisabled: boolean = true;

    // Annotations
    public annotationSettings: AnnotationSettings;
    public hideScatterGraph = true;

    // Date and time format
    public tooltipTimeFormat = '';

    // parameters to render the Advanced Graphs
    public getHydrographParams: HydrographArgs = <HydrographArgs>{
        summarizeInterval: 0
    }

    public filterSelectedValues: LocationDashboardFilterData;

    public locIndex: number;
    public locString = new Array<string>();

    // Snackbar messages
    public csvExportSuccessMsg: string;
    public csvExportErrorMsg: string;
    public dismissButton: string;
    public correctedText: string;

    private confirmationText: any;
    public reportLocationName: string;

    public startDte: Date = null;
    public endDte: Date = null;

    // Overall page
    public previewLoader$ = new BehaviorSubject<boolean>(false);

    // Header bar parameters
    public enableHeaderButtons: boolean;

    // Scatter parameters
    public showCurveEnabled = false;
    public scattergraphLoading: boolean;
    public scatterGraphData: ScatterData;
    public scatterDateFormat: string;
    public scatterTimeFormat: string;
    public showScatterGraph = true;
    public noDataForScatterChart: boolean;
    public allDataFlagged = false; // #22888 Whenever there are data, but all of them are flagged
    public scatterGraphStatus: boolean;
    public scatterGraphEnableSnapToCurveOption = true;

    // Hydro parameters
    public showHydroGraph = true;
    public noDataForHydrographChart: boolean;
    public entityGroupingsHydroNEW: number[][];
    public hydroGraphLoading$ = new BehaviorSubject<boolean>(false);
    public newHydroBackupData: BasicSeriesData[];
    public newHydroDates: number[];
    public newHydroAnnotations$ = new BehaviorSubject<AnnotationOptions>(undefined);
    public showHydrographManualScales = false;
    public showScattergraphManualScales = false;
    public missingParameters = '';
    public missingSecondaryLocsData = '';
    public missingRain = false;
    public missingLevel = false;
    public rainGaugeId?: number;
    public userPrefHGMarkers$ = new BehaviorSubject<boolean>(false);
    private overallEntityGroupingInformation: SeriesEntityGroup[] | any;
    public zoomForScatter$ = new BehaviorSubject<number[]>([]);
    public zoomForHydro = RANGE_BUTTONS.None;

    // parameters for Data Editing
    public enableAcceptEditor = false;
    public enableAcceptNoReason = false;
    public totalPaginationLength = 0;
    public customerDateFormat: string;
    public timeFormat: string;
    public customerDateTimeFormat: string;

    // parameters to pass data for data-editing
    public dataEditingArray = new Array<DataEditingData>();
    public isBasicDataEditingAllowed: boolean;
    public dataEditRowSelected: DataEditingRow;
    public dataEditingDialog: MatDialogRef<DataEditingComponent>;
    public editedOptions: any;
    public dataDeleteDialog: MatDialogRef<DataDeleteComponent>;

    // Represents inpus for Confirmation Box
    public conformationDataInput: IComponentCustomizedConfirmation;
    public showConfirmation: boolean;

    public includeInactiveLocations: boolean;

    public showEditingMenu = false;
    public entitiesList: Entities[];
    public graphAlertText: string;
    public isPreviewCalled: boolean;
    public numeric3PlaceDecimalRegEx = REGEX_CONFIG.numericDecimal3PlacePattern;
    public locationGroupsSelected: LocationGroup;
    public customDateTimeFormat: string;
    // flag to track if averaging turned off before launching data editing mode
    public isAveragingTurnedOff: boolean;
    // public isRequestProcessing = false;
    public editedDataArray: Array<EditedData> = [];

    public startDateReason: Date;
    public reasons;
    public entitiesEdited: Array<string> = [];
    public editedEntitiesTracker: UpdatePoints[] = [];
    public showResonForEdit = false;
    public reasonsSelector = new Array<Array<Selectable>>();
    public selectedReason = '';
    // #22834 feature setting
    private isDataEditCommentsAllowed = true;
    public isReasonSelected = true;
    public hideCommentSection = new Array<boolean>();
    public otherReason: string;
    public hideFilters = false;

    public numberOfEntries: number;
    public okText: string;
    public noDataMsg: string;
    public cancelText: string;
    public workOrderLink: string;
    public dataEditingConfirmText: string;
    public dataEditingConfirmTitle: string;

    public setDataAveraging = 0;

    //  array to loop input values for timespan depending upon numberOfEntries
    public numberOfTimes: Array<number>;
    // private applyScale = false;
    public canceDataEdit: string;
    public attention: string;
    public flagText: string;
    public locationEntityData: LocationEntitiesData;
    public selectedLocation: LocationData;

    private disableHeader$: BehaviorSubject<boolean>;
    public isRefreshDataTable = true;
    public scatterGraphConfirmationData: { type?: 'all' | 'date'; text?: string; dateRange?: { start?: Date; end?: Date } } = {};
    public hydrographFilterPointsDateRange: ConfirmationDateRange;

    public enableApprovalDatesForAccept = false;
    public today = new Date();
    public approvalDateControl = new FormControl({ value: null, disabled: true });
    public is12HourFormat = false;
    public dateFormat: string;
    public customDateFormat: string;
    private currentCustomer;
    public customerHasBasicEditPermisstion: boolean;

    private tempSelectedSnappedPoints;
    private missingPipeInfoText: string;
    public editingDisabled = true;

    public telemetryStart: Date;
    public telemetryEnd: Date;
    public locationDetails: LocationDetails;

    // #22011 - if RG is not present within same group, has to get it's name somehow
    public allLocations: LocationData[];

    public selectedSGEntityIds: number[];
    public isUSGS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isRainGauge: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public editedPointsForSg: UpdatePointForSG[] = [];

    // #22784 entities that was choosen before user enter edit mode
    private sgEntitiesBeforeEdit?: number[] = null;
    public needToSyncSGentitiesAfterEdit = false;

    // #16939 whenever should force reload cache
    public reloadCache: boolean;

    public events: EventModel[];
    // #40597 Whenever events was modified
    public eventsLoad$: BehaviorSubject<void> = new BehaviorSubject(null);
    private allEvents: EventModel[];

    private userPermissionLoaded$ = new Subject<boolean>();

    public userPermissionOnEvent: boolean;
    public eventShouldSeeRain: boolean;
    public customerPermissionOnEvent: boolean;
    public selectedHintPageName: string;
    public confirmationEntity: ConfirmationEntitiesEnum = ConfirmationEntitiesEnum.Average;
    public isShowEvents: boolean;
    public isPrinting: boolean;

    public effectiveRoughness: number;
    public slope: number;


    public isRawVelExistsForLocation = false;
    public isElevationExistsForLocation = false;
    public series: string;
    public installationShapeId: number;
    public gainData: GainTableResponse[] = [];
    private flagOnRedoInterpolate: boolean;
    private shouldEnterEditMode = false;
    private shouldEnterEditModeSG = false;
    public isForeSITELocation = false;
    public isRainAlertOrEcholoc = false;

    public selectedDateRange$: Subject<Date[]> = new BehaviorSubject(null);
    public selectedAreaRange$: Subject<number[]> = new BehaviorSubject(null);
    public debouncedZoomForLongTable = this.selectedAreaRange$.pipe(debounceTime(1000));
    public chartInfoText$: Subject<string> = new Subject();
    public customRanges: ManualScale[];
    prevZoom: number[];

    public isCustomerMetric: boolean;
    private shouldRefetchHydrograph: boolean;

    // #37990 #37405 #37991 Do not keep current zoom level when switching between locations or customers
    hgKeepZoomLevel = false;
    // #37990 #37405 #37991 Keep current Zoom Level
    currentHGInterval = null;
    isMetric: boolean;
    public trackByIndex = TrackBy.byIndex;

    constructor(
        private activatedRoute: ActivatedRoute,
        private viewDataService: ViewDataService,
        public changeDetectorRef: ChangeDetectorRef,
        public uiUtilService: UiUtilsService,
        private router: Router,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
        private filterWidgetService: DynamicFilterWidgetService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private telemetryService: TelemetryService,
        private domOperationUtilsService: DomOperationUtilsService,
        private matDialog: MatDialog,
        public dataEditService: DataEditService,
        private locationService: LocationService,
        private customerService: CustomerService,
        private locationGroupService: LocationGroupService,
        private userService: UsersService,
        private mapService: MapService,
        private snackBarNotificationService: SnackBarNotificationService,
        private eventService: EventService,
        private usersService: UsersService,
        public separateWindowHydrographService: SeparateWindowHydrographService,
        private hydrographNewService: HydrographNEWService,
        private lightningChartBuilder: LightningChartBuilder,
        private hydrographDataBuilder: HydrographDataBuilder,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.separateWindowHydrographService.init(this);
        this.applyTranslation();
        // Set variables from parameters
        this.customerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam) || 0);
        this.locationId = Number(this.activatedRoute.snapshot.queryParamMap.get(locationIdQueryParam) || 0);
        this.reloadCache = !(this.activatedRoute.snapshot.queryParamMap.get(reloadCacheQueryParam) === '0') || true;
        this.separateWindowHydrographService.isNewTab = !!Number(this.activatedRoute.snapshot.queryParamMap.get(isNewTab));
        this.separateWindowHydrographService.parentId = this.activatedRoute.snapshot.queryParamMap.get(parentId);
        this.locationGroupId = Number(
            this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam) || 0,
        );
        this.includeInactiveLocations = Boolean(
            Number(this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam)) || 0,
        );

        // Set graphs to loading state
        this.enableHeaderButtons = false;
        this.scattergraphLoading = true;
        this.disableHeader$ = this.locationService.disableHeaderButtons$;

        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                if (this.advanceScatterGraph) {
                    this.advanceScatterGraph.graph.zoomEvent = null;
                }

                this.reloadCache = !(params.get(reloadCacheQueryParam) === '0');

                // When active route changes, check user and location and update if needed
                const locationId = params.get(locationIdQueryParam);
                const customerId = Number(params.get(customerQueryParam));
                const inactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
                const locGroupId = Number(params.get(customerLocationGroupQueryParam));

                if (this.customerId !== customerId) {
                    this.resetHGZoom();
                    this.viewDataService.hydrographManualScales = null;
                    this.viewDataService.scattergraphManualScales = null;
                    this.getAllLocations(customerId);
                    this.locationId = 0;
                    this.dataEditService.otherLocationsData$.next(null);
                    this.dataEditService.clearSgIgnoredPoints();
                    this.updateSecondaryLocationsDialogData(null);
                    this.missingSecondaryLocsData = '';
                }
                if (
                    this.customerId !== customerId ||
                    (this.locationId !== Number(locationId) && locationId !== null) ||
                    this.includeInactiveLocations !== inactiveLocations ||
                    this.locationGroupId !== locGroupId
                ) {
                    // Only need to update if things changed
                    this.customerId = customerId;
                    this.locationId = locationId !== null ? Number(locationId) : this.locationId ? this.locationId : 0;
                    this.includeInactiveLocations = inactiveLocations;

                    this.locationGroupId = locGroupId;
                    this.scatterGraphData = null;
                    this.hideScatterGraph = true;
                    this.zoomForScatter$.next([]);
                    this.viewDataService.lockSGCurve.next(false);
                    this.dataEditService.updatedData$.next(undefined);
                    this.loadEntities();
                    this.dataEditService.clearSgIgnoredPoints();

                    this.viewDataService.scatterManualCurveSelectedPoints.next([]);
                }

                this.checkPipeInfo();
            }),
        );
    }

    public updateSelectedDateRange(newZoom: number[]) {
        if (!newZoom || (isNaN(newZoom[0]) && isNaN(newZoom[1]))) {
            return;
        }

        this.hydrographStartDate = new Date(newZoom[0]);
        this.hydrographEndDate = new Date(newZoom[1]);

        const ldStart = new Date(this.startDate).getTime();
        const ldEnd = new Date(this.endDate).getTime();

        // need to remove timezone offset
        const [start, end] = newZoom;
        const startTs = this.dateutilService.formatDateAsTimeZone(new Date(start)).getTime();
        const endTs = this.dateutilService.formatDateAsTimeZone(new Date(end)).getTime();

        // #37913 If zoom does match previous one, no need to do anything
        if(this.prevZoom && this.prevZoom[0] === startTs && this.prevZoom[1] === endTs) {
            return;
        }

        this.prevZoom = [startTs, endTs];

        this.selectedAreaRange$.next([startTs, endTs]);

        if (ldStart === startTs && ldEnd === endTs) {
            this.zoomForScatter$.next([]);
        } else {
            this.updateScatterGraphZoom([startTs, endTs]);
        }
    }

    public updateScatterGraphZoom(newZoom: number[]) {
        this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.zoomPoints, payload: newZoom });
        this.zoomForScatter$.next(newZoom);
    }

    public detectChanges() {
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    public dataEdit(pointsEdited: UpdatePoints[]) {
        this.setAcceptButton(pointsEdited);
    }

    public setPointsForInterpolation(isSelected: boolean) {
        this.editMenu.setPointsForInterpolation(isSelected);
    }

    public customRangesChange(customRanges: ManualScale[]) {
        const formattedCustomRanges: DisplayGroupScales[] = customRanges?.map(x => {
            return {
                displayGroupId: x.displayGroupId,
                autoScale: x.autoOpt,
                manualMin: x.min,
                manualMax: x.max
            };
        });
        const rangesToSave = formattedCustomRanges.filter(x => x.autoScale !== undefined && x.autoScale !== null && x.displayGroupId === RAIN_DISPLAY_GROUP).map((v) => {
            return {displayGroupId: v.displayGroupId, autoScale: v.autoScale, manualMin: undefined, manualMax: undefined}
        });

        this.viewDataService.hydrographManualScales = formattedCustomRanges;

        const userSettings = this.usersService.userSettings.getValue();
        userSettings.displayGroupScales = rangesToSave;
        this.usersService.updateUserSettings(userSettings).subscribe(() => {
            this.usersService.userSettings.next(userSettings);
        })

        this.setGraphObject();
    }

    // #37990 #37405 #37991 Reset zoom. Next HG create wont keep current zoom level
    public resetHGZoom() {
        this.hgKeepZoomLevel = false;
    }

    public setGraphObject() {
        this.fetchGraphSub?.unsubscribe();
        this.fetchGraphSub = null;


        this.fetchGraphSub = combineLatest([
            this.dataEditService.hydrographDataSelector,
            this.newHydroAnnotations$,
            this.userPrefHGMarkers$,
            this.statusCodeService.userInfoThemeBS,
            this.dateutilService.isCustomerUnitsMetric,
            this.eventsLoad$
        ]).pipe(debounceTime(50)).subscribe(([seriesData, annotations, userPrefHGMarkers, darkTheme, isMetric]) => {
            if(!seriesData || !annotations) return;
            const userSettings = this.userService.userSettings.getValue();

            // #37398 Filter other data location
            const filterSeriesFn = (seriesData: BasicSeriesData[]): BasicSeriesData[] => seriesData.filter((entity) => entity.originLocationId === undefined || entity.originLocationId === null || entity.originLocationId === this.locationId);
            const filteredSeriesData = filterSeriesFn(seriesData);

            // #37506 Ignored points are not any persistent. Combine them and mark them here.
            const filteredDataObservable = this.dataEditService.hydrographDataSelector.pipe(
                combineLatestWith(this.dataEditService.sgIgnoredPointsData$),
                map(([entities, ignored]) => this.lightningChartBuilder.mapWithIgnoredPoints(entities, ignored.points, filterSeriesFn))
            );

            if(!this.viewDataService.hydrographManualScales) {
                this.viewDataService.hydrographManualScales = this.usersService.userSettings.getValue().displayGroupScales?.filter((v) => v.displayGroupId === RAIN_DISPLAY_GROUP);
            }

            const config: LightningChartBuildDataConfig = {
                graphGrouping: this.entityGroupingsHydroNEW,
                annotationOptions: annotations,
                seriesData: filteredSeriesData,
                events: this.events,
                locationId: this.locationId,
                showAllMarkers: userPrefHGMarkers,
                selectedDateRange: this.newHydroDates,
                displayGroupScales: this.viewDataService.hydrographManualScales,
                isMetric: isMetric,
                selectedConfirmationEntity: this.confirmationEntity,
                startDate: this.startDate,
                endDate: this.endDate,

                lightningChartReceiver: this,
                seriesData$: filteredDataObservable,
                chartId: `${this.chartId}`,
                allowEditMode: true,
                userSettings: userSettings,
                annotationSettings: this.annotationSettings,
                showEditMenu: () => this.showEditingMenu,
                darkTheme: darkTheme,
                dataAveraging: this.getHydrographParams.summarizeInterval
            }
            this.customRanges = this.hydrographDataBuilder.createCustomRanges(isMetric, seriesData, this.viewDataService.hydrographManualScales);

            if(this.hgKeepZoomLevel) {
                if(!this.currentHGInterval) this.currentHGInterval = this.lcChart?.currentInterval();
                if(this.currentHGInterval) config.defaultZoom = [this.currentHGInterval.start, this.currentHGInterval.end];
            } else {
                this.currentHGInterval = null;
            }

            this.lcChart = this.lightningChartBuilder.rebuildChart(this.lcChart, config);
            this.viewDataService.advancedCharts[0] = this.lcChart;

            if (this.currentHGInterval === null || this.currentHGInterval === undefined) {
                this.selectedAreaRange$.next([this.startDate.getTime(), this.endDate.getTime()]);
            }

            if(this.lcChart) this.currentHGInterval = null;
            this.hgKeepZoomLevel = true;

            this.dataEditService.hydrographDataSelector.subscribe((res) => {
                if(this.lcChart) {
                    this.lcChart.editableSeriesdata = res.filter((x) => x.entityId % 1 === 0)
                }
            }).unsubscribe();

            this.numberOfTimes = [0]; // Nowhere else is this changed to be a larger array currently...
            this.hydroGraphLoading$.next(false);

            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        });
    }

    public tracerZoomButtonClicked(buttonId: RANGE_BUTTONS) {
        this.zoomForHydro = buttonId;
        this.lcChart?.zoomButtonClicked(buttonId);
    }

    public setAcceptButton(pointsEdited: UpdatePoints[]) {
        if (!pointsEdited || pointsEdited.length === 0) {
            return;
        }
        this.enableAcceptNoReason = false;
        this.enableAcceptEditor = false;
        const isRawVelEntitySelected = this.selectedSGEntityIds.includes(RAW_VELOCITY_ENTITY);
        const isoStartDate = this.dateutilService.getStartDateAsTimeZone(this.startDate);
        const rawStartDate = isoStartDate.split('.');
        const startDate = rawStartDate[0];

        const isoEndDate = this.dateutilService.getEndDateAsTimeZone(
            new Date(new Date(this.endDate).setHours(23, 59, 59)),
        );
        const rawEndDate = isoEndDate.split('.');
        const endDate = rawEndDate[0];
        const previewObject = {} as DataEditPreviewParams;
        previewObject.dataEditType = DataEditType.EntityUpdate;
        previewObject.start = startDate;
        previewObject.end = endDate;
        previewObject.updatePoints = pointsEdited;
        previewObject.depthOnY = !this.annotationSettings.isScatterInvert;
        previewObject.isRawVelEntitySelected = isRawVelEntitySelected;

        previewObject.editingCurve = this.dataEditService.apiCurveForUICurve(this.annotationSettings);
        const shouldStoreEdit = this.flagOnRedoInterpolate ? false : true;

        this.setOriginalDistancesToEditParams(previewObject);
        this.subscriptions.push(
            this.dataEditService.dataEditPreview(this.customerId, this.locationId, previewObject, shouldStoreEdit).subscribe(
                (res) => {
                    this.flagOnRedoInterpolate = false;
                    this.separateWindowHydrographService.dispatchAction({
                        type: SeparateWindowActionTypes.hgPreviewResponse, payload: { res, points: pointsEdited }
                    });
                },
                (error) => {
                    this.flagOnRedoInterpolate = false;
                    this.dataEditService.editRequestLoading.next(false);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                },
            ),
        );
        this.entitiesEdited = [];
        this.editedEntitiesTracker = this.editedEntitiesTracker.concat(pointsEdited);
        const editedEntityIds = uniqBy(pointsEdited, (x) => x.eid);
        editedEntityIds.forEach((x) =>
            this.entitiesEdited.push(
                this.dataEditService.newHydroData$.value.find((y) => y.entityId === x.eid).entityName,
            ),
        );

        this.dataEditService.onEditsChanged.next(null);
    }

    private setOriginalDistancesToEditParams(params: DataEditPreviewParams) {
        const originalDistances = new Map<number, number>();
        if (this.advanceScatterGraph && this.advanceScatterGraph.graph) {
            const { graph } = this.advanceScatterGraph;
            graph.editedDistances.forEach((v, k) => originalDistances.set(k, v));
        }

        if (originalDistances.size > 0) {
            // this will be deleted before sending to API
            params['originalDistances'] = originalDistances;
        }
    }

    public onSubmitInterpolate({ requestParams: params, editedEntityId, isSelectedPoints }) {
        params.dataEditType = DataEditType.DataInterpolate;
        params.start = this.dateutilService.getStartDateAsTimeZone(this.startDate);
        params.end = this.dateutilService.getStartDateAsTimeZone(this.endDate);

        const start = this.dateutilService.formatDateAsTimeZone(params.iss);
        const end = this.dateutilService.formatDateAsTimeZone(params.ise);
        params.iss = this.dateutilService.formatDateToBeginningOfDay(start);
        params.ise = this.dateutilService.formatDateToEndOfDay(end);
        // need to cache with edit
        params.isSelectedPoints = isSelectedPoints;
        params.depthOnY = !this.annotationSettings.isScatterInvert;

        params.editingCurve = this.dataEditService.apiCurveForUICurve(this.annotationSettings);

        this.setOriginalDistancesToEditParams(params);
        this.subscriptions.push(
            this.dataEditService.dataEditPreview(this.customerId, this.locationId, params, true).subscribe(
                (response: any) => {
                    this.handleInterpolationResponse(response, isSelectedPoints, editedEntityId);
                },
                (error) => {
                    this.dataEditService.editRequestLoading.next(false);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                },
            ),
        );
    }

    private handleInterpolationResponse(
        response: DataEditPreview,
        isSelectedPoints: boolean,
        editedEntityId?: number,
        fromApiCall?: boolean
    ) {
        if(fromApiCall === undefined || fromApiCall === null) fromApiCall = true;
        // in case of redo interpolate
        if (!editedEntityId) {
            // need to get entity id from first response value
            const firstValue = (Object.values(response.d)[0] as string);
            editedEntityId = firstValue.includes(String(VELOCITY_ENTITY)) ? VELOCITY_ENTITY : DEPTH_ENTITY;
        }
        if (this.advanceScatterGraph && this.advanceScatterGraph.graph) {
            this.advanceScatterGraph.graph.handleInterpolateResponse(response, isSelectedPoints, editedEntityId);
        }

        if (isSelectedPoints) {
            this.handleInterpolateSelectedPointsResponse(response, fromApiCall);
        } else {
            this.handleInterpolateGapsResponse(response, fromApiCall);
        }
    }

    private handleInterpolateSelectedPointsResponse(response: DataEditPreview, fromApiCall = true) {
        const responsePointsMap: Map<number, Map<number, number>> = new Map(); // in order to get response points by key, to avoid iterations
        const editedPoints: UpdatePoints[] = [];

        Object.keys(response.d).forEach((v) => {
            const pointData = response.d[v].substr(1, response.d[v].length - 2); //remove [  ]  brackets
            const entities = pointData.split(';').reduce((acc, curr) => {
                const [entityId, value] = curr.split(':');

                acc.set(Number(entityId), Number(value));

                return acc;
            }, new Map());

            responsePointsMap.set(Number(v) * 1000, entities);
        });

        const hgSeries = this.dataEditService.newHydroData$.getValue();

        hgSeries.forEach((series) => {
            const modifiedPoints = [];
            series.data &&
                series.data.forEach((point, index) => {
                    const pointFromResponse = responsePointsMap.get(point.x);

                    if (!pointFromResponse) return;

                    const newValue = pointFromResponse.get(series.entityId);
                    if (!newValue) return;

                    point.y = newValue;
                    point.interpolationAccepted = true;
                    point.index = index;

                    editedPoints.push({
                        timeStamp: new Date(point.x),
                        eid: series.entityId,
                        dateTime: point.x,
                        reading: point.y,
                        reason: null,
                        ignore: point.flagged,
                    });
                });
        });

        this.dataEditService.newHydroData$.next([...hgSeries]);

        this.dataEditService.onEditsChanged.next(null);

        if (this.editMenu) {
            this.editMenu.isPointsSelectedForInterpolation = false;
        }
    }

    private handleInterpolateGapsResponse(response: DataEditPreview, fromApiCall = true) {
        const hgSeries = this.dataEditService.newHydroData$.getValue();
        const editedPoints: UpdatePoints[] = [];

        const responsePointsMap = new Map<number, { x: number; y: number; interpolationAccepted: boolean; index?: number }[]>();
        Object.keys(response.d).forEach((v) => {
            const pointData = response.d[v].substr(1, response.d[v].length - 2); //remove [  ]  brackets
            pointData.split(';').forEach((item) => {
                const [entityId, value] = item.split(':');

                const entity = responsePointsMap.get(Number(entityId));

                if (!entity) {
                    responsePointsMap.set(Number(entityId), [
                        { x: Number(v) * 1000, y: Number(value), interpolationAccepted: true },
                    ]);
                } else {
                    entity.push({ x: Number(v) * 1000, y: Number(value), interpolationAccepted: true });
                }
            });
        });

        hgSeries.forEach((series) => {
            const newPoints = responsePointsMap.get(series.entityId);
            if (!newPoints) return;

            const insertIndex = series.data.findIndex(pp => pp.x > newPoints[0].x);

            // We need to fill index, those are new points and Chart is not aware of them
            // For example copy/paste passes original points, computed on UI side before calling API, interpolate DOES NOT
            editedPoints.push(
                ...newPoints.map((v, i) => ({
                    timeStamp: new Date(v.x),
                    dateTime: v.x,
                    eid: series.entityId,
                    reading: v.y,
                    reason: null,
                    ignore: false,
                    index: insertIndex + i
                })),
            );


        });

        if(fromApiCall) {
            response.isSelectPoints = false; // Set to GAPS interpolate
            this.dataEditService.cacheEdit(editedPoints.map((v, i) => { return {...v, reading: null, y: null}}), response);
        }
        this.dataEditService.onEditsChanged.next(null);
    }

    private shipDataToNEWHydrograph(entityData: BasicSeriesData[]) {
        if (!this.selectedEntities) return;

        this.missingParameters = '';
        this.missingRain = false;
        this.missingLevel = false;

        // This is all temporary, will allow ease of custom axis selection in future
        // Convert from desired IDs not desired data types for correct axes
        const axesToGraph: { dataType: number, entityId: number }[] = [];
        this.selectedEntities?.forEach((x) => {
            const data = entityData?.find((y) => y.entityId === x.id);

            if ((!data || !data.data) && x.groupId) {
                // This isn't temporary but easiest to include in here for now
                if (x.id === RAIN_ENTITY) {
                    this.missingRain = true;
                } else if (x.id === LEVEL_ENTITY) {
                    this.missingLevel = true;
                } else {
                    // Fix for #21956
                    this.missingParameters = this.missingParameters.length > 0 ? this.missingParameters.concat(', ' + x.name) : x.name;
                    // remove duplicates
                    const missingArray = this.missingParameters.split(',').map(v => v.trim());
                    this.missingParameters = missingArray.filter((v, i) => i === missingArray.indexOf(v)).join(', ');
                }

                if (entityData.filter((y) => x.id === Math.abs(y.entityId)).length !== 0) {
                    const entityInformation = this.overallEntityGroupingInformation.find((y) =>
                        y.entities.some((z) => z.id === Math.abs(x.id)),
                    );
                    axesToGraph.push({
                        dataType: entityInformation.precendence,
                        entityId: Math.abs(x.id),
                    });
                }
            } else {
                const entityInformation = this.overallEntityGroupingInformation.find((y) =>
                    y.entities.some((z) => z.id === x.id),
                );
                axesToGraph.push({
                    dataType: entityInformation.precendence,
                    entityId: x.id,
                });
            }
        });
        this.entityGroupingsHydroNEW = [[], [], [], [], []];

        // #31018 need to include other locations data to graph axes
        const otherLocsData = this.dataEditService.otherLocationsData$.getValue();
        if (otherLocsData && otherLocsData.length) {
            otherLocsData.forEach(v => axesToGraph.push({ dataType: v.dataType, entityId: v.entityId }));
        }

        const confirmationSeriesToAdd = entityData.filter(v => v.entityId < 0 && this.selectedEntities.find(i => i.id  !== v.entityId));

        if (confirmationSeriesToAdd && confirmationSeriesToAdd.length) {
            axesToGraph.push(...confirmationSeriesToAdd.map(v => ({ dataType: v.dataType, entityId: Math.abs(v.entityId) })));
        }

        this.setEntityGroupings(axesToGraph);
        // need to cache data before applying edits
        this.newHydroBackupData = cloneDeep(entityData); // Immutable clone
        Object.seal(this.newHydroBackupData);

        this.applyEditsToHgData(entityData);
        this.dataEditService.newHydroData$.next(entityData);

        this.newHydroAnnotations$.next({
            manholeDepth: this.annotationSettings.isManholeDepth,
            pipeHeight: this.annotationSettings.isPipeHeight,
            silt: this.annotationSettings.isSilt,
            showEdits: this.annotationSettings.isShowEdits,
            highHigh: this.annotationSettings.isHighHigh,
            highFlow: this.annotationSettings.isHighFlow,
            highLevel: this.annotationSettings.isHighLevel,
            lowDepth: this.annotationSettings.isLowDepth,
            confirmationPoints: this.annotationSettings.isConfirmationPoints,
            showDataQuality: this.annotationSettings.isDataQuality,
            flipRain: this.annotationSettings.isRainOnTop,
            isShowEvents: this.isShowEvents
        });

        this.setGraphObject();
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    private setEntityGroupings(axesToGraph: { dataType: number, entityId: number }[]) {
        const CHART_INDEX_DEPTH_VELOCITY = 0;
        const CHART_INDEX_ELEVATION = 1;
        const CHART_INDEX_RAIN_QUANTITY = 2;
        const CHART_INDEX_TEMPERATURE_VOLTAGE = 3;
        const CHART_INDEX_OTHERS = 4;

        // TODO: Currently assigning to groups is based on G field from /Locations/v2/
        for(const entity of axesToGraph) {
            let chartIndex = CHART_INDEX_OTHERS;

            switch(entity.dataType) {
                case DataType.Depth: case DataType.Velocity:
                    chartIndex = CHART_INDEX_DEPTH_VELOCITY;
                    break;
                case DataType.Elevation:
                    chartIndex = CHART_INDEX_ELEVATION;
                    break;
                case DataType.Quantity: case DataType.Rain:
                    chartIndex = CHART_INDEX_RAIN_QUANTITY;
                    break;
                case DataType.Temperature: case DataType.Voltage:
                    chartIndex = CHART_INDEX_TEMPERATURE_VOLTAGE;
                    break;
                default:
                    chartIndex = CHART_INDEX_OTHERS;
                    break;
            }

            this.entityGroupingsHydroNEW[chartIndex].push(entity.entityId)
        }
    }

    private applyEditsToHgData(entityData: BasicSeriesData[]) {
        const toApplyEdits = this.dataEditService.getAllAppliedEdits();

        if (!toApplyEdits) return;

        entityData.forEach((series: BasicSeriesData) => {
            const affectedEntity = toApplyEdits.get(series.entityId);

            if (!affectedEntity) {
                return;
            }

            series.data = series.data.map((point: HGGraphData) => {
                const editedPoint = affectedEntity.get(point.x);

                if (editedPoint && !MathUtils.compareWithMinimalDecimal(point.y, editedPoint.y)) {
                    point.correctedY = editedPoint.y;
                    point.flagged = editedPoint.flagged !== null ? editedPoint.flagged : point.flagged;
                }

                return point;
            });
        });

        this.editedPointsForSg = [DEPTH_ENTITY, VELOCITY_ENTITY].reduce((acc, id: 4122 | 4202) => {
            const editedEntity = toApplyEdits.get(id);

            if (!editedEntity) return acc;

            const formatted: UpdatePointForSG[] = Array.from(editedEntity.values()).map(v => ({ id: v.eid, value: v.y, stamp: v.x, edited: true }));
            return [...acc, ...formatted];
        }, new Array<UpdatePointForSG>());
    }

    public prevApprovalDates(result : {prevApprStartDate :Date, prevApprEndDate: Date, approvedBy: string})
    {
        this.startDte = null;
        this.prevApprStartDate = this.viewDataFilter.prevApprovedStartDate = result?.prevApprStartDate;
        this.prevApprEndDate = this.viewDataFilter.prevApprovedEndDate = result?.prevApprEndDate
        this.approvedBy = this.viewDataFilter.approvedBy =  result?.approvedBy;

        if(result && result.prevApprStartDate)
            this.startDte = result.prevApprStartDate;
    }

    public editsReverted(result: {points: DataEditLog[], response: DataEditPreview}) {

        const apiResponse = result.response;

        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.storeRawvelEdits(null);
            this.advanceScatterGraph.onRevertChanges(result.points);
        }

        this.shouldRefetchHydrograph = true;

        const dataIncludingReverted = this.dataEditService.handleAPIpreviewResponse(apiResponse, false, null, true);
        // #32416 revert HG points - we need to modify original data source: newHydroBackupData
        // #32416 revert SG points - we need to modify original data source: scatterGraphData
        const sgRevertedPoints: {depth?: number, velocity?: number, ts?: number, flagged?: boolean}[] = [];
        const sgRevertedEditedPoints = [];

        const velEntity = this.selectedSGEntityIds.includes(VELOCITY_ENTITY) ? VELOCITY_ENTITY : RAW_VELOCITY_ENTITY;
        const depthEntityData = dataIncludingReverted.find(entity => entity.entityId === DEPTH_ENTITY)
        const velocityEntityData = dataIncludingReverted.find(entity => entity.entityId === velEntity);

        let reverted = false;
        dataIncludingReverted.forEach(entity => {
            const origEntity = this.newHydroBackupData.find(oe => oe.entityId === entity.entityId);
            const isDepth = entity.entityId === DEPTH_ENTITY;
            const isVelocity = entity.entityId === velEntity;

            if (!entity.data || !entity.data.length) {
                return;
            }

            entity.data.forEach(point => {
                if(point.reverted) {
                    origEntity.data[point.index].flagged = point.flagged;
                    origEntity.data[point.index].y = point.y;
                    origEntity.data[point.index].edited = false;
                    origEntity.data[point.index].correctedY = undefined;
                    reverted = true;
                    if (this.advanceScatterGraph) {
                        if(isDepth || isVelocity) {
                            if(!sgRevertedPoints[point.index]) sgRevertedPoints[point.index] = {};
                            sgRevertedPoints[point.index].ts = point.x / 1000;
                            sgRevertedPoints[point.index].flagged = point.flagged;
                        }
                        if(isDepth) sgRevertedPoints[point.index].depth = point.y;
                        if(isVelocity) sgRevertedPoints[point.index].velocity = point.y;
                        sgRevertedEditedPoints[point.x] = true;
                    }
                }
            });
        });

        if(reverted) {
            this.shipDataToNEWHydrograph(this.newHydroBackupData);
        }

        if (this.advanceScatterGraph && this.scatterGraphData && this.scatterGraphData.d) {
            const sgDataMap = this.convertSgDataToMap();
            sgRevertedPoints.forEach((p, i) => {
                if(p.velocity === undefined && velocityEntityData.data[i]) p.velocity = velocityEntityData.data[i].y;
                if(p.depth === undefined && depthEntityData.data[i]) p.depth = depthEntityData.data[i].y;

                const sgPoint = sgDataMap.get(p.ts);

                if (sgPoint) {
                    sgPoint.x = p.velocity;
                    sgPoint.y = p.depth;

                    if (sgPoint.flagged !== undefined) {
                        sgPoint.flagged = Number(p.flagged);
                    }
                }
            });

            this.editedPointsForSg = [...this.editedPointsForSg.filter(p => !sgRevertedEditedPoints[p.stamp])];

            this.scatterGraphData = { ...this.scatterGraphData, d: this.convertMapToSgData(sgDataMap) };
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);

        }
    }

    private convertSgDataToMap(): Map<number, ScatterRevertData> {
        return (this.scatterGraphData.d as string[]).reduce((result, current: string) => {
            const noBrackets = current.slice(1, current.length - 1);
            const [x, y, ts, dataQuality, flagged, ...rest] = noBrackets.split(':');

            result.set(Number(ts), { x: Number(x), y: Number(y), ts: Number(ts) });

            if (dataQuality && flagged) {
                const item = result.get(Number(ts));

                item.dataQuality = dataQuality;
                item.flagged = Number(flagged);
            }

            if (rest && rest.length) {
                const item = result.get(Number(ts));

                item.rest = rest;
            }

            return result;
        }, new Map<number, ScatterRevertData>());
    }

    private convertMapToSgData(data: Map<number, ScatterRevertData>) {
        return Array.from(data.values()).map((item) => {
            let result = `${item.x}:${item.y}:${item.ts}`;

            if (item.dataQuality !== undefined && item.flagged !== undefined) {
                result += `:${item.dataQuality}:${item.flagged}`;
            }

            if (item.rest && item.rest.length) {
                result += `:${item.rest.join(':')}`;
            }

            return `[${result}]`;
        });
    }

    public resetEditingParams() {
    }

    private checkSecondaryLocationsDataValidity() {
        const secondaryLocsData = this.dataEditService.otherLocationsData$.getValue();

        if (!secondaryLocsData || !secondaryLocsData.length) {
            return;
        }

        const locationIds = this.locationEntityData.l.map(v => v.lid);
        const filtered = secondaryLocsData.filter(v => locationIds.includes(v.lid));

        this.dataEditService.otherLocationsData$.next(filtered);

        if (this.viewDataFilter) {
            this.updateSecondaryLocationsDialogData(this.viewDataFilter.secondaryLocationsDialogData.filter(v => locationIds.includes(v.lid)));
        }

        if (filtered.length !== secondaryLocsData.length) {
            this.snackBar.open(this.translate.instant('COMMON.FILTER_SECONDARY_LOCS_DATA_ON_ACTIVE_INACTIVE_MSG'), this.dismissButton, {
                panelClass: 'custom-error-snack-bar',
                duration: 10000
            });
        }
    }

    private updateSecondaryLocationsDialogData(newValue: SeriesProperty[] | null) {
        if (!this.viewDataFilter) {
            return;
        }

        this.viewDataFilter.secondaryLocationsDialogData = newValue;
    }

    public updateOtherLocationsData(results: SeriesProperty[]) {
        const filteredResults = results.filter((item: SeriesProperty) => {
            if (item.data) {
                return true;
            }

            const name = `${item.locationName} (${item.entityName})`;
            this.missingSecondaryLocsData = this.missingSecondaryLocsData.length > 0 ? this.missingSecondaryLocsData.concat(', ' + name) : name;

            return false;
        });

        const entitiesData = this.formatOtherLocationsData(filteredResults);
        const allSecondaryLocsData = this.dataEditService.otherLocationsData$.getValue();

        const updatedData = allSecondaryLocsData.map((series: BasicSeriesData) => {
            const newValues = entitiesData.find(v => v.id === series.id);

            return newValues ? newValues : series;
        });

        this.dataEditService.otherLocationsData$.next(updatedData);
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    private formatOtherLocationsData(results: SeriesProperty[]) {
        return results.map((item: SeriesProperty) => {
            const apiData = item.data;
            let tracker;
            const annotationData = this.dataEditService.handleApiAnnotationsData(apiData, tracker);
            const { entitiesData } = this.handleApiEntitiesData(apiData, tracker, annotationData, false, item.lid);

            let name: string;

            if (entitiesData && entitiesData.length) {
                name = `${item.locationName} (${entitiesData[0].entityName})`;
            } else {
                name = `${item.locationName} (${item.entityName})`
            }

            const data = entitiesData[0];

            // if user had used default date range we sync this secondary data row date with LD
            if (item.startDate.getTime() === this.startDate.getTime() && item.endDate.getTime() === this.endDate.getTime()) {
                data.syncDates = true;
            }

            data.id = item.id;
            data.color = item.color;
            data.entityName = name;
            data.annotations = [];
            data.start = item.startDate;
            data.end = item.endDate;
            data.lid = item.lid;
            data.originLocationId = this.locationId;

            return data;
        });
    }

    public confirmationEntityChange(event: ConfirmationEntitiesEnum, notifyOtherWindow = true) {
        this.confirmationEntity = event;

        if (notifyOtherWindow) {
            this.separateWindowHydrographService.dispatchAction<ConfirmationEntitiesEnum>({ type: SeparateWindowActionTypes.confirmationEntity, payload: event });
        }

        this.setGraphObject();
    }

    public loadOtherLocationsData(results: SeriesProperty[]) {
        this.updateSecondaryLocationsDialogData(results);
        this.missingSecondaryLocsData = '';
        const filteredResults = results.filter((item: SeriesProperty) => {
            if (item.data) {
                return true;
            }

            const name = `${item.locationName} (${item.entityName})`;
            this.missingSecondaryLocsData = this.missingSecondaryLocsData.length > 0 ? this.missingSecondaryLocsData.concat(', ' + name) : name;

            return false;
        });

        const entitiesData = this.formatOtherLocationsData(filteredResults);

        this.setEntityGroupings(entitiesData);
        this.dataEditService.otherLocationsData$.next(entitiesData);

        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    private handleAPIdata(apiData: OptimizedViewData, siltChanged = false, confirmationPointsDates?: ConfirmationDateRange) {
        if (!this.locationId) {
            return;
        }

        if (!apiData || Object.keys(apiData.compactData).length === 0 || this.selectedEntities.length === 0) {

            if(apiData && Object.keys(apiData.compactData).length === 0 && this.selectedEntities && this.selectedEntities.some(e => e.id === RAIN_ENTITY)) {
                this.missingRain = true;
            }

            this.dataEditService.newHydroData$.next(null);
            this.newHydroBackupData = null;
            this.noDataForHydrographChart = true;

            return;
        }

        /* Tracker.groups.flagged is the data flag from API. Values can be:
           Good (0), Bad (1, consider that a flagged point), Manual (2), Anonaly (3), or Missing (4)
        */

        // Expand and refactor compacted annotation data
        let tracker;
        const annotationData = this.dataEditService.handleApiAnnotationsData(apiData, tracker);
        const result = this.handleApiEntitiesData(apiData, tracker, annotationData);
        const { colors, siltData, dataMaxDate, dataMinDate, entityData } = result;
        let { entitiesData } = result;

        if(confirmationPointsDates && confirmationPointsDates.type !== ConfirmationDateRangeType.All
            && confirmationPointsDates.dateRange && confirmationPointsDates.dateRange.start && confirmationPointsDates.dateRange.end)
        {
            entitiesData.forEach((entity => {
                if(entity.entityId < 0 && entity.entityId % 1 === 0)
                {
                    entity.data = entity.data.filter((data) =>
                    {
                        const dataDate = new Date(data.x).toISOString();
                        return dataDate >= new Date(confirmationPointsDates.dateRange.start).toISOString() && dataDate <= new Date(confirmationPointsDates.dateRange.end).toISOString();
                    })
                }
            }))
        }

        this.hydrographNewService.entityColorsSet(colors);

        if (siltData && entitiesData.filter((x) => x.dataType === DataType.Depth).length > 0) {
            const depthEntity = entitiesData.filter((x) => x.dataType === DataType.Depth)[0];
            depthEntity.annotations.push(siltData);
            entitiesData = entitiesData.filter((x) => x.entityId !== SILT_ENTITY);
        }

        // TODO: FIXIT: this.startDate seems to be unavailable when delivering data instantly from cache
        const areDatesSetUp = this.startDate && this.endDate;
        if(!this.startDate) this.startDate = new Date(apiData.start);
        if(!this.endDate) this.endDate = new Date(apiData.end);
        if(!areDatesSetUp) this.selectedDateRange$.next([this.startDate, this.endDate]);

        // Because we can't trust 'this.startDate' or the data min date for the graph actual extents...
        // Plus a shim to add 0 value points to start and end of 1 data series to ensure correct range on graph
        // Seems to be a highcharts issue, not an issue with how xAxis is created for the graph
        const tzOffset = this.startDate.getTimezoneOffset() * 60 * 1000; // in ms
        const graphStart = this.startDate.getTime() - tzOffset;
        this.dataMin = graphStart;
        const graphEnd = this.endDate.getTime() - tzOffset;
        if (dataMinDate * 1000 !== graphStart && entitiesData.length > 0) {
            let i = 0;
            while (entitiesData.length > i) {
                if (entitiesData[i].data) {
                    entitiesData[i].data?.unshift({ x: graphStart, y: undefined, flagged: false });
                    break;
                }
                i++;
            }

        }
        if (dataMaxDate * 1000 !== graphEnd && entitiesData.length > 0 && entitiesData[0].data) {
            let i = 0;
            while (entitiesData.length > i) {
                if (entitiesData[i].data) {
                    entitiesData[i].data?.push({ x: graphEnd, y: undefined, flagged: false });
                    break;
                }
                i++;
            }
        }
        this.newHydroDates = [graphStart, graphEnd];

        let mint = Number.MAX_SAFE_INTEGER,
            maxt = 0;
        entitiesData.forEach((g) => {
            // Fix "Type error cannot read property forEach of undefined"
            g &&
                g.data &&
                g.data.forEach((e) => {
                    if (e.y) {
                        if (e.x > maxt) maxt = e.x;
                        if (e.x < mint) mint = e.x;
                    }
                });
        });

        if (siltChanged && !entityData[SILT_ENTITY]) {
            this.snackBar.open(this.translate.instant('COMMON.SILT_ERROR'), this.dismissButton, {
                panelClass: 'custom-error-snack-bar',
            });
        }

        this.isElevationExistsForLocation = entitiesData !== null && entitiesData.some(ent => ent.entityId === ELEVATION_ENTITY) ;

        this.shipDataToNEWHydrograph(entitiesData);
        this.editedPointsForSg = [...this.editedPointsForSg];
        this.onFlagPoints();
    }

    private handleApiEntitiesData(apiData: OptimizedViewData, tracker, annotationData, isMainCall = true, secondaryLocationId = null) {
        const { entityData, dataMinDate, dataMaxDate, isAnyData, editedPointsForSg } = this.dataEditService.parseCompactData(apiData.compactData, tracker, apiData.entityIds, isMainCall, this.annotationSettings.isShowEdits, this.selectedSGEntityIds);

        if (editedPointsForSg.length > 0) {
            this.editedPointsForSg.push(...editedPointsForSg);
        }

        // #22888 Set up based on if all data are flagged
        if (isMainCall) {
            this.noDataForHydrographChart = !isAnyData;
            if(!isAnyData) {
                this.allDataFlagged = true;
            } else {
                this.allDataFlagged = false;
            }
        }

        let thisLocation: LocationData;

        if (!secondaryLocationId) {
            thisLocation = this.locationEntityData.l.find((x) => x.lid === this.locationId);
        } else {
            thisLocation = this.locationEntityData.l.find((x) => x.lid === secondaryLocationId);
        }
        if (!thisLocation) {
            return;
        }
        const monitorType = thisLocation.s ? thisLocation.s.toLowerCase() : null;
        const ansrEntities = thisLocation.ae;
        let entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === monitorType);
        if (!entityGroups) {
            entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === OTHERTEXT);
        }
        this.overallEntityGroupingInformation = [...entityGroups.g, ...ansrEntities];

        // Create new data structure that hydrograph needs
        const entitiesData: BasicSeriesData[] = [];
        let siltData = undefined;
        // Entity colors for #14192
        const colors: {[key: number]: string} = [];
        apiData.displayGroups.forEach((group) => {
            group.entities.forEach((entity) => {
                const databaseEntity = this.overallEntityGroupingInformation.find(
                    (x) => x.entities.filter((y) => y.id === Math.abs(entity.id)).length > 0,
                );

                if (databaseEntity) {
                    // Check for database entity to ensure entity is valid for device type
                    let entityName = entity.name;
                    if (entity.id < 0) {
                        const databaseEntitys = this.overallEntityGroupingInformation.find(
                            (x) => x.entities.filter((y) => y.id === -entity.id).length > 0,
                        );
                        const entityType: string = DataType[databaseEntitys.precendence].toLowerCase();

                        entityName = this.confirmationText[entityType];

                        if (Math.abs(entity.id) === RAW_VELOCITY_ENTITY) {
                            entityName = this.translate.instant('LOCATION_DASHBOARD.CONFIRMATION_PEAK_VELOCITY');
                        } else if (Math.abs(entity.id) === VELOCITY_ENTITY) {
                            entityName = this.translate.instant('LOCATION_DASHBOARD.CONFIRMATION_AVG_VELOCITY');
                        }
                    }

                    if (entityName === 'Depth') {
                        this.unit = { unit: group.unit };
                    }

                    colors[entity.id] = entity.color;
                    const color = this.hydrographNewService.entityColorParse(entity.id, entity.color);

                    let groupedData: BasicSeriesData = {
                        entityName: entityName,
                        axisName: group.label,
                        unitOfMeasure: group.unit,
                        entityId: entity.id,
                        color: entity.id < 0 ? ANNOTATION_MARKER_COLOR : color,
                        precision: group.precision,
                        data: entityData[entity.id],
                        dataType: databaseEntity.precendence,
                        displayGroupId: databaseEntity.id,
                        annotations: [],
                    };

                    if (entity.id > 0 && group.annotations && group.annotations.length > 0) {
                        group.annotations.forEach((annot) => {
                            if(annot.factor && annotationData){
                                annotationData[annot.id].forEach(element => {
                                    element.y = element.y * annot.factor;
                                 });
                            }
                            groupedData.annotations.push({
                                name: annot.name,
                                id: annot.id,
                                color: annot.color,
                                data: annotationData ? annotationData[annot.id] : null,
                                unitOfMeasure: group.unit,
                                precision: group.precision,
                            });
                        });
                    }
                    entitiesData.push(groupedData);

                    const flagged = entityData[entity.id + '_flag'];
                    const edited = entityData[entity.id + '_orig'];

                    // Remove edited if same data point is flagged, need to show only flagged marker
                    if (flagged && edited) {
                        entityData[entity.id + '_orig'] = edited.filter(v => !flagged.find(i => i.x === v.x));
                    }

                    if (entity.id > 0 && flagged) {
                        groupedData = {
                            entityName: entityName + ' (Flagged)',
                            axisName: group.label,
                            unitOfMeasure: group.unit,
                            entityId: -(entity.id + 0.2),
                            color: RED_COLOR_HEX,
                            displayGroupId: group.id,
                            precision: group.precision,
                            data: flagged,
                            dataType: entity.id > 0 ? databaseEntity.precendence : DataType.Annotation,
                            annotations: [],
                        };
                        entitiesData.push(groupedData);
                    }
                    if (entity.id > 0 && entityData[entity.id + '_orig']) {
                        groupedData = {
                            entityName: entityName + ' (Orig)',
                            axisName: group.label,
                            unitOfMeasure: group.unit,
                            entityId: -(entity.id + 0.1),
                            color: entity.color,
                            displayGroupId: group.id,
                            precision: group.precision,
                            data: entityData[entity.id + '_orig'],
                            dataType: entity.id > 0 ? databaseEntity.precendence : DataType.Annotation,
                            annotations: [],
                        };
                        entitiesData.push(groupedData);
                    } else if (entity.id === SILT_ENTITY) {
                        siltData = {
                            name: entity.name,
                            id: entity.id,
                            color: entity.color,
                            data: entityData[entity.id],
                            unitOfMeasure: group.unit,
                            precision: group.precision,
                        };
                    }
                }
            });
        });

        return { entitiesData, colors, siltData, entityData, dataMaxDate, dataMinDate };
    }

    public ngOnInit() {
        this.subscriptions.push(this.dataEditService.undoScattergraphEdits.subscribe((edit: StoredEdit) => {
            if (edit && edit.action === DataEditStoreActionTypes.HGedit && this.lcChart && this.lcChart.edit) {
                this.lcChart.edit.resetInterpolateHasBeenApplied();
            }
        }));
        this.loadEntities();
        this.viewDataService.hydrographManualScales = null;
        this.viewDataService.scattergraphManualScales = null;
        this.subscriptions.push(this.dataEditService.updateSgEditedPoints.subscribe(({ edits, toUnflag }) => {
            if (this.advanceScatterGraph && this.advanceScatterGraph.graph) {
                const unflagSet = new Set(toUnflag.map(v => v.ts));

                if (this.advanceScatterGraph.graph?.flaggedPointsData?.length) {
                    this.advanceScatterGraph.graph.flaggedPointsData = this.advanceScatterGraph.graph.flaggedPointsData.filter(v => !unflagSet.has(v.dateTime as number));
                }

                if (this.advanceScatterGraph.graph?.hydroFlaggedPoints?.length) {
                    this.advanceScatterGraph.graph.hydroFlaggedPoints = this.advanceScatterGraph.graph.hydroFlaggedPoints.filter(v => !unflagSet.has(v.stamp));
                }

                const rawvelUnflags = toUnflag.filter(v => v.entId === RAW_VELOCITY_ENTITY || v.entId === VELOCITY_ENTITY);

                if (rawvelUnflags.length && this.advanceScatterGraph.graph.rawvelEdits && this.advanceScatterGraph.graph.rawvelEdits.size) {
                    rawvelUnflags.forEach(v => {
                        this.advanceScatterGraph.graph.rawvelEdits.delete(v.ts);
                    });
                }
            }

            const editedTimestampsSet = new Set(this.editedPointsForSg.filter(v => v.edited).map(v => v.stamp));
            this.editedPointsForSg = edits.map(v => ({ ...v, edited: editedTimestampsSet.has(v.stamp) }));
        }));
        this.subscriptions.push(this.dataEditService.interpolateResponse.subscribe((res: DataEditPreview) => this.handleInterpolationResponse(res, res.isSelectPoints, undefined, false)));
        this.dataEditService.otherLocationsData$.next(null);
        this.updateSecondaryLocationsDialogData(null);
        this.missingSecondaryLocsData = '';
        this.subscriptions.push(this.domOperationUtilsService.openDEDialog.subscribe((value: boolean) => {
            this.showResonForEdit = value;
            this.domOperationUtilsService.deDialogOpened.next(value);
        }));
        const pageNameHint = this.domOperationUtilsService.selectedHintPageName.subscribe((pageName: string) => {
            this.selectedHintPageName = pageName;
            if(pageName === 'location-dashBoard-edit-hint') {
                this.lcChart?.tooltip?.infoShowHint();
            } else {
                this.lcChart?.tooltip?.infoBoxShow(false);
            }
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        });
        this.subscriptions.push(pageNameHint);

        // On page load
        this.hideCommentSection = new Array<boolean>();

        this.getHydrographParams = <HydrographArgs> {
            summarizeInterval: 0
        };

        this.updateEntitiesDataToHydrograph();
        this.entityGroupingUpdate();

        const timeSubs = this.dateutilService.timeFormat.subscribe((value) => {
            this.dateFormat = this.dateutilService.getFormat();
            this.is12HourFormat = value !== 'hh:mm:ss';
            this.customDateFormat = this.is12HourFormat
                ? `${this.dateFormat}, ${'hh:mm a'}`
                : `${this.dateFormat}, ${'HH:mm:ss'}`;
        });

        this.subscriptions.push(timeSubs);
        // Subscribe to customer and location group changes

        const preSelectedDateTimeFormat = this.viewDataService.dateTimeFormats.getValue();
        if (preSelectedDateTimeFormat) {
            this.tooltipTimeFormat = preSelectedDateTimeFormat.tooltipTimeFormat;
        } else {
            // subscribe to changes in dateFormat
            this.subscriptions.push(
                this.dateutilService.dateFormat.subscribe(() => {
                    // Getting customer dateformat from dateUtil Service
                    this.scatterDateFormat = this.dateutilService.getGraphDateFormat();
                    const timeSubscription = this.dateutilService.timeFormat.subscribe((value) => {
                        this.tooltipTimeFormat = this.scatterTimeFormat = value;
                    });
                    this.subscriptions.push(timeSubscription);
                }),
            );
        }

        // subscribe to changes in dateFormat
        this.subscriptions.push(
            this.dateutilService.dateFormat.subscribe((newDateFormat) => {
                // Getting customer dateformat from dateUtil Service
                this.customerDateFormat = this.dateutilService.getFormat();
            }),
        );

        // subscribe to changes in time format
        this.subscriptions.push(
            this.dateutilService.timeFormat.subscribe((format) => {
                this.timeFormat = this.dateutilService.getTimeFormatWithoutSeconds();
            }),
        );

        this.getHydrographParams = <HydrographArgs>{
            summarizeInterval: 0,
        };

        this.subscriptions.push(
            this.viewDataService.entitiesList.subscribe((entities) => {
                if (entities) {
                    this.entitiesList = entities;
                }
            }),
        );

        // #34257 Exit edit mode if user want to open Alert popup
        this.subscriptions.push(
            this.disableHeader$.subscribe((editEnabled) => !editEnabled && this.cancelDataEditing(true, false))
        );
        this.subscriptions.push(
            this.dateutilService.isCustomerUnitsMetric.subscribe((isMetric) => this.isCustomerMetric = isMetric)
        );

        this.bindDataEditReasons();
        setTimeout(() => this.listenMarkersChange(), 500);

        this.getAllLocations(this.customerId);

    }

    private loadEvents(filterEvents = true) {
        if(!this.startDate || !this.endDate) return of([]);

        const shouldSeeAllEvents = this.customerPermissionOnEvent;

        const startStr = this.dateutilService.formatDateToUTC(this.startDate);
        const endStr = this.dateutilService.formatDateToUTC(this.endDate);

        if(shouldSeeAllEvents || this.eventShouldSeeRain) {
            return this.eventService.getAllEvents(this.customerId, startStr, endStr).pipe(
                tap((events) => {
                    // #28112 - Cust Admin should see just Rains if got no permissions
                    if(!shouldSeeAllEvents && this.eventShouldSeeRain) {
                        events = events.filter(e => e.etype === EventTypes.Rain);
                    }
                    this.allEvents = events.map(v => ({ ...v, duration: this.eventService.getDuration(v) }));
                    if(filterEvents) {
                        this.filterEvents(this.startDate, this.endDate);
                    }
                    this.eventsLoad$.next();
                }));
        } else {
            return of([]);
        }
    }

    private filterEvents(start: Date, end: Date) {
        if(this.allEvents) {
            this.events = this.allEvents.filter(x => {
                if(!x.lids || !x.lids.includes(this.locationId)) return false;

                const xStart = new Date(x.start);
                const xEnd = new Date(x.end);
                const isInRange = (xStart >= start && xStart <= end)
                    || (xStart <= start && xEnd >= start)
                    || (xEnd >= start && xEnd >= end)

                return isInRange;
                }
            );
        }
    }

    private entityGroupingUpdate()
    {
        const entitiesUpdate = this.dataEditService.updateEntityGroupingsSubject$.subscribe((data: EntitiesUpdate[]) => {
            if(data)
            {
                this.setEntityGroupings(data);
            }
        });

        this.subscriptions.push(entitiesUpdate);
    }

    private updateEntitiesDataToHydrograph() {
        const entitiesUpdate = this.dataEditService.entitiesData$.subscribe((data: BasicSeriesData[]) => {
            if (data) {
                this.shipDataToNEWHydrograph(data);
            }
        });

        this.subscriptions.push(entitiesUpdate);
    }

    private listenMarkersChange() {
        const userSettings = this.userService.userSettings.getValue();
        this.userPrefHGMarkers$.next(userSettings && userSettings.markDataPoints);
        const settingsSub = this.userService.userSettings.pipe(filter((v) => !!v)).subscribe((data) => {
            this.userPrefHGMarkers$.next(data.markDataPoints);
        });
        this.subscriptions.push(settingsSub);
    }

    /** Get feature values. It is called from within customer change subscription */
    private getFeatures() {
        this.requiredComment = this.userService.isDataEditCommentsAllowed.getValue().value;
        this.isDataEditCommentsAllowed = this.userService.isDataEditCommentsAllowed.getValue().value;
        this.isReasonSelected = this.isDataEditCommentsAllowed;
        this.enableDataEditing = this.userService.isBasicDataEditingAllowed.getValue();
        this.userPermissionOnEvent = this.userService.isEventEditorOnUserAllowed.getValue();
        this.customerPermissionOnEvent = this.userService.isEventEditorOnCustomerAllowed.getValue().value;
        this.eventShouldSeeRain = this.customerPermissionOnEvent;
    }

    public userPermissionLoaded(loaded: boolean) {
        this.userPermissionLoaded$.next(true);
    }

    public openHgInNewTab() {
        this.separateWindowHydrographService.isNewWindowOpened = true;
        this.toggleScatterGraph(true);

        const currentHostUrl = window.location.host;

        let newTabUrl = window.location.protocol
            + '//' + currentHostUrl + this.router.url
            + `&${isNewTab}=1`
            + `&${parentId}=${this.separateWindowHydrographService.id}`;

        const containsLid = this.router.url.includes(`${locationIdQueryParam}=`);
        const containsLg = this.router.url.includes(`${customerLocationGroupQueryParam}=`);

        if(!containsLid) newTabUrl += `&${locationIdQueryParam}=${this.locationId}`;
        if(!containsLg) newTabUrl += `&${customerLocationGroupQueryParam}=${this.locationGroupId}`;

        const win = window.open(newTabUrl);

        this.separateWindowHydrographService.onNewWindowOpened(win);

        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.graph.displayScatterGraph(this.advanceScatterGraph.data);
        }
    }

    public closeTab() {
        window.close();
    }

    private getAllLocations(customerId: number) {
        this.locationService
        .getLocationData(<LocationListArgs>{
            cid: customerId,
            LocationGroupId: 0,
            IncludeInactiveLocations: true,
            }
        ).subscribe((data) => {
            if (data) {
                this.allLocations = data.l;
            }
        });
    }

    public loadEntities() {
        // subscribe the already selected group
        this.subscriptions.push(
            this.locationGroupService.locationGroupSelected.subscribe((data: any) => {
                if (data && data.locationGroups) {
                    this.locationGroupsSelected = data.locationGroups.find(
                        (group: LocationGroup) => group.locationGroupID === this.locationGroupId,
                    );
                }
            }),
        );

        const customerSubscription = this.customerService
            .getCustomerById(this.customerId)
            .subscribe((res: Customer) => {
                this.workOrderLink = res.workOrderLink;
                this.isMetric = res.unitsType === UnitOfMeasureType.METRIC;

                this.getFeatures();

                this.subscriptions.push(this.loadEvents(false).subscribe(() => {}));
            });
        this.subscriptions.push(customerSubscription);

        const loadEntitiesSubscription =
            this.locationService
            .getLocationData(<LocationListArgs>{
                cid: this.customerId,
                LocationGroupId: this.locationGroupId,
                IncludeInactiveLocations: this.includeInactiveLocations,
                }
            ).subscribe((entityData) => {
                if (!entityData) {
                    loadEntitiesSubscription.unsubscribe();
                    return;
                }

                entityData.l = entityData.l.map((l) => {
                    if (l.s === 'Echo') {
                        l.s = 'ECHO';
                    }
                    return l;
                });
                entityData.d = entityData.d.map((d) => {
                    if (d.s === 'Echo') {
                        d.s = 'ECHO';
                    }
                    return d;
                });
                this.distributeEntitiesAndLocations(entityData);
                this.resetSnappedPointsOnCustomerChange();
                this.getStaticTracerSetting();
                this.scattergraphLoading = true;
                const filters = this.viewDataService.filterValues.getValue();

                if (filters.annotationsSettings) {
                    this.getScatterGraphData(filters);
                    this.annotationSettings = filters.annotationsSettings;

                    if (this.viewDataFilter) {
                        this.annotationSettings.isToolbarEnabled = this.viewDataFilter.annotationSettings.isToolbarEnabled;
                        filters.annotationsSettings.isToolbarEnabled = this.viewDataFilter.annotationSettings.isToolbarEnabled;
                    }
                    const hydroGetParams = {
                        start: filters.startDate,
                        end: filters.endDate,
                        entityIds: filters.entities.map((v) => v.id),
                        locationId: this.locationId,
                        summarizeInterval: filters.summarizeInterval,
                    };
                    // getHydroGraphData before updating entities
                    // this.getHydroGraphData(hydroGetParams);
                }

                if(loadEntitiesSubscription) {
                    loadEntitiesSubscription.unsubscribe();
                }
            });
    }

    private getStaticTracerSetting() {
        this.userService.getStaticTracerSetting().subscribe((setting: boolean) => this.userService.staticTracerSetting.next(setting));
    }

    private resetSnappedPointsOnCustomerChange() {
        if (!this.advanceScatterGraph) {
            return;
        }

        this.advanceScatterGraph.graph.previousSnappedPoints = [];
        // this.advanceScatterGraph.graph.clearSeries(false, false)
    }

    private distributeEntitiesAndLocations(data: LocationEntitiesData) {
        if (this.locationGroupsSelected) {
            this.locationEntityData = <LocationEntitiesData>{
                d: data.d,
                l: data.l.filter((locData: LocationData) =>
                    this.locationGroupsSelected.locations.some((group: any) => group.locationID === locData.lid),
                ),
            };
            const newLocId = this.locationEntityData.l[0] ? this.locationEntityData.l[0].lid : 0;
            if (!this.locationEntityData.l.some(v => v.lid === this.locationId)) {
                this.locationId = newLocId;
            }
        } else {
            this.locationEntityData = data;
            if (this.locationId === 0) {
                this.locationId = this.locationEntityData.l[0].lid;
            }
        }

        this.checkPipeInfo();

        this.selectedLocation = this.locationId
            ? data.l.find((loc: LocationData) => loc.lid === this.locationId)
            : data.l[0];

        if(!this.selectedLocation && data && data.l && data.l.length)
        {
            this.selectedLocation = data.l[0];
        }

        this.locationName = this.selectedLocation ? this.selectedLocation.n : '';

        this.getGainData();
        const filters = this.viewDataService.filterValues.getValue();
        this.viewDataService.filterValues.next({
            ...filters,
            locationName: this.locationName,
            locationIDs: [this.locationId],
        });

        this.checkSecondaryLocationsDataValidity();

    }

    public getGainData() {
        this.dataEditService.getGainTable(this.customerId, this.locationId).subscribe((gainData: GainTableResponse[]) => {
            this.gainData = gainData || [];

            this.checkIfRawVelExist();
        });
    }

    private checkIfRawVelExist() {
        if (!this.gainData || !this.gainData.length) {
            this.isRawVelExistsForLocation = false;
        } else {
            const formattedGainData: string[] = [];
            this.gainData.forEach( a => a?.g?.forEach(p => formattedGainData.push(p.dt)));
            this.isRawVelExistsForLocation = formattedGainData?.some( v => new Date(this.endDate).getTime() >= new Date(v).getTime());
        }
    }

    /**
     * Represents the Confirmation Action
     * @param confirmation -confirmation response
     */
    public listenConfirmation(confirmation: IComponentCustomizedConfirmationResult) {
        this.showConfirmation = false;
        if (confirmation && confirmation.whichButtonPressed === 'cancel') {
            // this.persistDataEditingPoints();
        } else {
            this.cancelDataEditing();
        }
    }

    public scatterEntityBlur() {
        this.getScatterGraphData(this.viewDataService.filterValues.getValue());
    }

    public onScatterEntitiesChange(selectedEntityIds: number[]) {
        this.selectedSGEntityIds = selectedEntityIds;
        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.selectedEntityIds = this.selectedSGEntityIds;
        }
    }

    private preselectDepthVelOnEdit() {
        if (!this.getHydrographParams.entityIds.includes(RAW_VELOCITY_ENTITY) && this.isRawVelExistsForLocation) {
            const availableEnts = this.viewDataFilter.availableEntities$.getValue();
            const selectedEntities = this.viewDataFilter.selectedEntities;
            const rawvel = availableEnts.find(v => v.groupId === EntityGroupId.Velocity).entities.find(v => v.id === RAW_VELOCITY_ENTITY);

            this.shouldEnterEditMode = true;
            this.viewDataFilter.selectEntities([...selectedEntities, rawvel], true, false);
        } else {
            this.shouldEnterEditModeSG = true;
        }

        // #22784 select entities for SG
        this.viewDataFilter.checkEntitiesOnEdit();
        this.viewDataFilter.selectSGEntities(this.viewDataFilter.selectedSGEntities, true, true);

        /* Resetting the highlights */
        this.viewDataService.highlightAdvancedScatterChartPoints(null);
    }

    private restoreSGEntitiesBeforeEdit() {
        if (this.needToSyncSGentitiesAfterEdit) {
            this.viewDataFilter.isDataEditingEnabled = false;
            this.viewDataFilter.syncSGEntities(this.viewDataFilter.selectedEntities, true);
            this.needToSyncSGentitiesAfterEdit = false;
        }

        // #22784 show SG, if there are selectes SG entities then do not overwrite them - they should stay as they are, not revert
        if (this.sgEntitiesBeforeEdit && this.viewDataFilter.selectedSGEntityIds.length < 2) {
            this.viewDataFilter.selectedSGEntityIds = this.sgEntitiesBeforeEdit;
            this.selectedSGEntityIds = this.sgEntitiesBeforeEdit;

            this.getScatterGraphData(this.viewDataService.filterValues.getValue());
        }

        this.sgEntitiesBeforeEdit = null;
    }
    public editData(editId: string = null) {
        if (this.showEditingMenu) {
            return;
        }

        if (!editId) {
            this.separateWindowHydrographService.dispatchAction<string>({ type: SeparateWindowActionTypes.enterEdit, payload: DataEditService.getUUID() });
        } else {
            DataEditService.guid = editId;
        }

        if (this.editingDisabled && !editId) {
            this.snackBar.open(this.missingPipeInfoText, this.dismissButton, {
                panelClass: 'custom-error-snack-bar',
            });
            return;
        }

        this.previewLoader$.next(true);
        this.disableHeader$.next(true);

        /** Added condition to check if user has chosen averaging if yes turn off averaging and then launch data table
         * on successful hydrograph API request with non averaged data
         */
        if (this.getHydrographParams.summarizeInterval) {
            this.setDataAveraging = 0;
            this.dataAveraging(0);
            this.isAveragingTurnedOff = true;

            this.snackBar.open('Averaged data can not be edited. Changing to all data', this.dismissButton, {
                panelClass: 'custom-error-snack-bar',
            });
            return;
        }

        // set entities list

        const sgAvailableEnts = this.viewDataFilter.availableSGEntities;
        const velAvailEnts = sgAvailableEnts.find(v => v.groupId === VELOCITY_DISPLAY_GROUP);
        const depAvailEnts = sgAvailableEnts.find(v => v.groupId === DEPTH_DISPLAY_GROUP);

        const sgEnts = this.viewDataFilter.selectedSGEntityIds;
        const hgEnts = this.viewDataFilter.selectedEntityIds;

        const velDepSelected = hgEnts.includes(DEPTH_ENTITY) && hgEnts.includes(VELOCITY_ENTITY);
        const sgHasAnyVelEntity = velAvailEnts && velAvailEnts.entities.length && velAvailEnts.entities.some(v => sgEnts.includes(v.id));
        const sgHasCorrectVel = sgEnts.includes(RAW_VELOCITY_ENTITY) || (sgEnts.includes(VELOCITY_ENTITY) && !this.isRawVelExistsForLocation);
        const depthIsSelected = depAvailEnts && depAvailEnts.entities.length && depAvailEnts.entities.some(v => v.id === DEPTH_ENTITY);
        const sgHasRawvel = sgEnts.includes(RAW_VELOCITY_ENTITY);

        const sgHasCorrectEntities = sgEnts.includes(DEPTH_ENTITY) && (sgEnts.includes(RAW_VELOCITY_ENTITY) || (sgEnts.includes(VELOCITY_ENTITY) && !this.isRawVelExistsForLocation));
        const sgDisplayAnything = sgEnts.length > 1;

        if ((!sgHasCorrectVel && sgHasAnyVelEntity && depthIsSelected) || (velDepSelected && !sgHasRawvel && this.isRawVelExistsForLocation)) {
            this.preselectDepthVelOnEdit();

            const message = this.translate.instant('LOCATION_DASHBOARD.DE_AUTO_SELECT_DEPTH_VEL_MESSAGE');
            this.snackBar.open(message, this.dismissButton, {
                panelClass: 'custom-error-snack-bar', duration: 8000
            });

            return;
        } else if (!sgHasCorrectEntities && sgDisplayAnything) {
            this.sgEntitiesBeforeEdit = this.viewDataFilter.selectedSGEntityIds;
            this.viewDataFilter.selectedSGEntityIds = [];
            this.hideScattergraph();
        }

        this.enterEditMode();
    }

    private enterEditMode() {
        // For hydro
        this.getHydrographParams.start = this.startDate;
        // this.viewDataFilter.checkEntitiesOnEdit();
        this.dataEditService.clearSgIgnoredPoints();
        // For scatter
        if (this.advanceScatterGraph) {
            this.onFlagPoints();

            const availEnts = this.viewDataFilter.availableSGEntities;
            const depthGroup = availEnts.find(v => v.groupId === DEPTH_DISPLAY_GROUP);
            const velGroup = availEnts.find(v => v.groupId === VELOCITY_DISPLAY_GROUP);

            let entitiesChanged = false;

            if(depthGroup) {
                depthGroup.entities = depthGroup?.entities?.filter(v => {
                    if (v.id !== DEPTH_ENTITY) {
                        entitiesChanged = true;
                    }

                    return v.id === DEPTH_ENTITY;
                });
            }

            if(velGroup) {
                velGroup.entities = velGroup?.entities?.filter(v => {
                    if (v.id !== VELOCITY_ENTITY && v.id !== RAW_VELOCITY_ENTITY) {
                        entitiesChanged = true;
                    }

                    return v.id === VELOCITY_ENTITY || v.id === RAW_VELOCITY_ENTITY;
                });
            }

            this.viewDataFilter.availableSGEntities = [depthGroup, velGroup];
            this.needToSyncSGentitiesAfterEdit = entitiesChanged;

            const editedTimestampsSet = new Set(this.editedPointsForSg.filter(v => v.edited).map(v => v.stamp));
            const series = this.dataEditService.newHydroData$.getValue();
            this.editedPointsForSg = !series ? [] : series.reduce((acc, curr) => {
                if (!this.selectedSGEntityIds.includes(curr.entityId)) {
                    return acc
                }

                return [...acc, ...curr.data.filter(v => v.correctedY !== undefined).map(v => ({ id: curr.entityId, stamp: v.x, value: v.correctedY, flagged: v.flagged, edited: editedTimestampsSet.has(v.x) }))];
            }, []);
            this.advanceScatterGraph.annotationSettings.isToolbarEnabled = true;
            if (!this.annotationSettings.isManualLinear && !this.annotationSettings.isManualSmooth) {
                this.viewDataService.scatterMenuItem.next('activeZoomFlag');
            }
            this.advanceScatterGraph.snappingPosition = 1;
            // #22301 do not emit event, we rebuild HG anyways
            this.advanceScatterGraph.graph.clearSeries(false, true, false);
            this.advanceScatterGraph.isDataSelected = false;
        }

        this.annotationSettings.isCurveEnabled = true;
        this.annotationSettings.isToolbarEnabled = true;

        //For Filter View
        this.viewDataFilter.annotationSettings.isCurveEnabled = true;
        this.viewDataFilter.annotationSettings.isToolbarEnabled = true;

        // For overall page
        this.domOperationUtilsService.hintPageName = EDIT_VIEW_DATA;
        this.dataEditService.showEditMenu = true;
        this.showEditingMenu = true;
        this.filterSettings.isDisplayDatePicker = false;

        this.scatterGraphEnableSnapToCurveOption = true;
        this.previewLoader$.next(false);

        // #36776 Has to update series info only in edit mode
        this.setGraphObject();
    }

    public toggleHydroGraph(expanded: boolean, handToogleHydrograph?: boolean) {
        // True for expand, false for collapse
        if (expanded) {
            this.showScatterGraph = false;
        } else {
            this.showScatterGraph = true;
            this.redrawScatterGraph();
        }

        if (handToogleHydrograph !== undefined) {
            if (handToogleHydrograph) {
                this.handToogleHydrograph = true;
            } else {
                this.handToogleHydrograph = undefined;
            }
        }

        this.redrawHydrographGraph();
    }

    public toggleScatterGraph(expanded: boolean) {
        // True for expand, false for collapse
        if (expanded) {
            this.showHydroGraph = false;
        } else {
            this.showHydroGraph = true;
            this.redrawHydrographGraph();
        }

        this.redrawScatterGraph();
    }

    public redrawScatterGraph() {
        setTimeout(() => {
            if (
                this.viewDataService.advancedCharts &&
                this.viewDataService.advancedCharts.length > 1 &&
                this.viewDataService.advancedCharts[1]
            ) {
                // Force it to run after screen adjustments are made, and force view to refresh
                if (this.viewDataService.advancedCharts && this.viewDataService.advancedCharts.length > 1) {
                    this.viewDataService.advancedCharts[1].reflow();
                }
            }
        }, 0);
    }

    public redrawHydrographGraph() {
        setTimeout(() => {
            // Still used for resizing when parent box changes size
            if (
                this.viewDataService.advancedCharts &&
                this.viewDataService.advancedCharts.length > 0 &&
                this.viewDataService.advancedCharts[0]
            ) {
                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            }
        }, 0);
    }

    public onExportTelemetryData(): void {
        const exportable = <VaultLocationExport>{
            cid: this.customerId,
            startDate:
                [
                    this.filterSelectedValues.startDate.getFullYear(),
                    this.filterSelectedValues.startDate.getMonth() + 1,
                    this.filterSelectedValues.startDate.getDate(),
                ].join('-') + 'T00:00:00Z',
            endDate:
                [
                    this.filterSelectedValues.endDate.getFullYear(),
                    this.filterSelectedValues.endDate.getMonth() + 1,
                    this.filterSelectedValues.endDate.getDate(),
                ].join('-') + 'T23:59:59Z',
            fileType: DataExportType.AdsCsv,
            entityRowColumn: EntityExportType.Column,
            dataAverage: this.getHydrographParams.summarizeInterval,
            locationType: LocationType.Location,
            singleOrMultiple: DataExportFile.Multiple,
        };
        const entityIds = this.filterSelectedValues.entities.map((x) => x.id);
        const locationIds = this.filterSelectedValues.locationIDs;

        this.subscriptions.push(
            this.telemetryService.vaultExportLocation(exportable, entityIds, locationIds).subscribe(
                (res) => {
                    // #23724 - do not display anything on success - we have signalR for this
                    if(!res) {
                        // #23725 'No data' toast if failed. Returns HTTP 200 on success and HTTP 204 on no data
                        this.snackBarNotificationService.raiseNotification(this.noDataMsg, this.dismissButton, {
                            panelClass: 'custom-error-snack-bar',
                        }, false);
                    }
                },
                (err) => {
                    this.snackBar.open(this.csvExportErrorMsg, this.dismissButton, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            ),
        );
    }

    // Prints the dashboard data for the current location dashboard filters
    public printDashboard(event): void {
        // Force call because otherwise resizing doesn't happen correctly/all the time
        this.onBeforePrint(event);
        setTimeout(() => window.print(), 0);
    }

    public locationChangeHandler(event: { location: LocationData, notify: boolean }): void {
        const { location, notify } = event;

        this.resetHGZoom();
        if (notify) {
            this.separateWindowHydrographService.dispatchAction<LocationData>({ type: SeparateWindowActionTypes.locationChange, payload: location });
        }
        this.selectedLocation = location;
        this.locationId = location.lid;

        this.getGainData();
        this.zoomForScatter$.next([]);
        this.dataEditService.clearSgIgnoredPoints();
        this.scatterGraphData = null;
        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.checkSelectedCurveOnLocationChange();
            this.advanceScatterGraph.getCurvesList(location.lid);
            this.advanceScatterGraph.graph.zoomEvent = null;
        }
        // Glitches have let me end up changing location without exiting editing mode
        // Also, this ensures everything is reset (like flaggedPointsOnEdit window AKA ghost points)
        if (this.showEditingMenu) {
            // Do it only if in editing mode
            this.cancelDataEditing();
        }

        this.resetSnappedPointsOnCustomerChange();

        this.checkPipeInfo();
    }

    public onEditPointsForSG(points: UpdatePointForSG[], notifyOtherWindows = true) {
        const editedTimestampsSet = new Set(this.editedPointsForSg.filter(v => v.edited).map(v => v.stamp));

        this.editedPointsForSg.push(...points.filter(v => this.selectedSGEntityIds.includes(v.id)));
        // If edited point was edited again we need to keep last edited value, code below filters points by keeping latest values
        const tempMapForEditedPoints = this.editedPointsForSg.reduce((acc, curr) => acc.set(curr.stamp, curr), new Map<number, UpdatePointForSG>());
        this.editedPointsForSg = Array.from(tempMapForEditedPoints.values()).map(v => ({ ...v, edited: editedTimestampsSet.has(v.stamp) }));

        if (notifyOtherWindows) {
            this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.applyEditsToSg, payload: this.editedPointsForSg });
        }
    }

    private checkPipeInfo(): void {
        if (this.locationId) {
            this.editingDisabled = true;

            const pipeSubscription =
                combineLatest([
                    this.locationService.getLocationDetailsV2(this.customerId, this.locationId, this.reloadCache),
                    this.mapService.getMarkerLocationDetails(this.locationId, this.customerId)
                ]).subscribe(([details, pipeInfo]) => {
                    this.locationDetails = pipeInfo;
                    if (pipeInfo) {
                        this.rainGaugeId = pipeInfo.assignedRainGaugeLocationId;
                        const isPipeInstall = pipeInfo && pipeInfo.installationType && pipeInfo.installationType === 'Pipe';
                        const hasPipeDimensions = Boolean(pipeInfo) && Boolean(pipeInfo.height) && Boolean(pipeInfo.width);
                        this.editingDisabled = isPipeInstall ? !hasPipeDimensions : isPipeInstall;
                        this.telemetryStart = pipeInfo.telemetryStart;
                        this.telemetryEnd = pipeInfo.telemetryEnd;
                        this.installationShapeId = pipeInfo.installationShapeTypeID;

                        // Fix for #21956 - only way we can know location is USGS
                        // TODO: API is so much inconsistent here that it works differently for Raleigh, BEAVERDAM CREEK AT DAM and Cape Girardeu, Mississippi River
                        const isUsgs = (details && !!details[0]?.usgsid && details[0]?.usgsid != 'string') || (!!pipeInfo?.usgsid && pipeInfo?.usgsid != 'string') || pipeInfo?.series === 'USGS';
                        this.isUSGS.next(isUsgs);

                        const series = details && details[0] && details[0].series ? details[0].series.toLowerCase()
                            : pipeInfo && pipeInfo.series ? pipeInfo.series.toLowerCase()
                            : null;

                        if(series) {
                            this.series = details[0].series;
                            const isRainGauge = series === MONITOR_SERIES_TYPES.RAIN_ALERT_III.toLowerCase() || series === MONITOR_SERIES_TYPES.RAIN_ALERT_II.toLowerCase();
                            this.isRainGauge.next(isRainGauge);
                        }

                        this.slope = pipeInfo.designSlope;
                        this.effectiveRoughness = pipeInfo.effectiveRoughness;
                    }

                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                });

            this.subscriptions.push(pipeSubscription);
        }
    }

    public refreshAppDataHandler(scattergraphOnly = false) {
        this.getScatterGraphData(this.filterSelectedValues);

        if (!scattergraphOnly) {
            this.getHydroGraphData(this.getHydrographParams);
        }
    }

    dateRangeModifiedByUser = false;
    public onDateRangeChange() {
        this.dateRangeModifiedByUser = true;
    }

    // Retrieves hydro graph data for the current location dashboard filters.
    public getHydroGraphData(params: HydrographArgs, shouldEnterEditMode = false, siltChanged = false, confirmationPointsDates?: { dateRange?: { start?: Date; end?: Date }}): void {
        if (params.locationId === -1 || params.locationId === 0) {
            return;
        }
        this.hydroGraphLoading$.next(true);
        this.newHydroBackupData = null;
        this.missingRain = false;
        this.noDataForHydrographChart = false;
        this.missingParameters = '';

        if (this.annotationSettings) {
            // This parameter is sent to the Hydrographs API to retrieve Alarms Threshold
            params.highLevelThreshold = this.annotationSettings.isHighLevel;
            params.highHighThreshold = this.annotationSettings.isHighHigh;
            params.highFlowThreshold = this.annotationSettings.isHighFlow;
            params.lowLevelThreshold = this.annotationSettings.isLowDepth;
            params.includeOriginalValues = this.annotationSettings.isShowEdits;
            params.includeConfirmationPoints = this.annotationSettings.isConfirmationPoints;
            params.includeZeroRainValues = this.annotationSettings.includeZeroRainValues;
        }

        this.subscriptions.push(
            this.viewDataService.getHydrograph(this.customerId, params.locationId, params, this.reloadCache).subscribe(
                (hydroResult: OptimizedViewData) => {
                    // late response, user is viewing another location
                    if (params.locationId !== this.selectedLocation.lid) {
                        return;
                    }

                    this.isForeSITELocation = this.selectedLocation.s.toLowerCase() === MONITOR_SERIES_TYPES.FORE_SITE.toLowerCase() || this.selectedLocation.s.toLowerCase() === MONITOR_SERIES_TYPES.FORE_SITE_XD.toLowerCase();

                    this.zoomForScatter$.next([]);
                    this.editedPointsForSg = [];
                    this.handleAPIdata(hydroResult, siltChanged, confirmationPointsDates);

                    // Things for page header bar:
                    this.filterSettings.isExportPrintEnabled = true;
                    this.enableHeaderButtons = true;
                    this.isRainAlertOrEcholoc = this.selectedLocation.s.toLowerCase() === MONITOR_SERIES_TYPES.RAIN_ALERT_III.toLowerCase() || this.selectedLocation.s.toLowerCase() === MONITOR_SERIES_TYPES.ECHO.toLowerCase();
                    // Internal tracking

                    // Apparently this does something for refreshing data to non averaged in edit mode?
                    if (this.isAveragingTurnedOff) {
                        this.editData();
                        this.isAveragingTurnedOff = false;
                    }

                    this.hydroGraphLoading$.next(false);

                    if (!this.showEditingMenu) {
                        this.dataEditService.updatedData$.next(undefined);
                    }
                    // #22784 loading on Edit, notify UI
                    if(shouldEnterEditMode || this.shouldEnterEditMode) {
                        this.enterEditMode();
                        this.shouldEnterEditMode = false;
                    }
                },
                () => {
                    this.dataEditService.newHydroData$.next(null);
                    this.hydroGraphLoading$.next(false);
                    this.noDataForHydrographChart = true;

                    // #22784 loading on Edit, notify UI
                    if(shouldEnterEditMode) {
                        this.enterEditMode();
                    }

                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                },
            ),
        );
    }

    public hideHydrograph() {
        this.hydroGraphLoading$.next(false);
        this.noDataForHydrographChart = true;
        this.missingParameters = '';

        this.prevFiltersData = null;
        this.lcChart?.destroy();
    }

    // to update index for multiple locations.
    public updateLocIndex() {
        this.locIndex = this.locIndex + 1;
        if (this.locIndex === this.locString.length) {
            this.locString.length = 0;
            this.locIndex = 0;
        }
    }

    /**
     * Initializes the Hydrograph.
     * @param entitiesInformation - Data Array for hydrograph entities.
     */
    public initChart(entitiesInformation: EntitySeries[], xAxisDateFormat: string, tooltipTimeFormat: string) {
        // if (!this.disableFilters) {
        //     this.resetSeries();
        // }
        /*if (this.filterWidgetService.getisEmptyDashboardState()) {
            this.entitySeriesDepVelocity = JSON.parse(
                sessionStorage.getItem('dynamicDashboardOverlayDepthVelocity')
            );
            this.entitySeriesFlowRain = JSON.parse(
                sessionStorage.getItem('dynamicDashboardOverlayFlowRain')
            );
            this.entitySeriesTempVoltage = JSON.parse(
                sessionStorage.getItem('dynamicDashboardOverlayTempVoltage')
            );
            this.entitySeriesScalarOther = JSON.parse(
                sessionStorage.getItem('dynamicDashboardOverlayScalarOther')
            );
        }*/

        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    /*
    public plotGraphs(
        entity: string,
        entityData: EntitySeries[],
        tooltipFormat: string,
        xAxisDateFormat: string
    ) {

        this.subscriptions.push(this.statusCodeService.userInfoThemeBS.subscribe(
            (response: boolean) => {
                if (response) {
                    StringUtils.setHighChartTheme();
                } else {
                    StringUtils.setHighchartWhiteTheme();
                }
            }
        ));

        /*this.options = {
            tooltip: {
                hideDelay: 0,
                enabled: true,
                shared: true,
                crosshairs: true,
                useHTML: true,
                formatter: function () {
                    let tooltipFormatter = new Array<string>();
                    if (this.points[0].series.options.isMultipleOverlayEnabled) {
                        let lineBreakIndex = 0;
                        let tableFormat = '';
                        tooltipFormatter.push(Highcharts.dateFormat(xAxisDateFormat + tooltipFormat,
                            new Date(this.points[0].x).getTime()));
                        tableFormat = tableFormat + '<br><table cellpadding="0" cellspacing="0"><tbody><tr>';

                        for (let seriesPoint of this.points) {
                            if (seriesPoint.y != null && !seriesPoint.point.hideToolTip) {
                                lineBreakIndex++;
                                let point = `<td><span style="color: ${seriesPoint.series.color}">\u25CF</span>
                                    ${seriesPoint.series.options.locationName}: ${StringUtils.upperCase(seriesPoint.series.name)}: <b>
                                    ${seriesPoint.y.toFixed(seriesPoint.series.options.precision)}</b>
                                    ${seriesPoint.series.options.unitOfMeasure}</td>`;
                                tableFormat = tableFormat + point;
                                if (lineBreakIndex === 4) {
                                    lineBreakIndex = 0;
                                    tableFormat = tableFormat + '</tr><tr>';
                                }
                            }
                        }
                        tableFormat = tableFormat + '</tr></tbody></table>';
                        tooltipFormatter.push(tableFormat);
                    } else {
                        tooltipFormatter.push(Highcharts.dateFormat(xAxisDateFormat + tooltipFormat, new Date(this.points[0].x).getTime()));
                        for (let seriesPoint of this.points) {
                            if (seriesPoint.y != null && !seriesPoint.point.hideToolTip) {
                                let point = `<br /><span style="color: ${
                                    seriesPoint.series.color
                                    }">\u25CF</span> ${StringUtils.upperCase(seriesPoint.series.name)}: <b>
                          ${seriesPoint.y.toFixed(seriesPoint.series.options.precision !== 0
                                        ? seriesPoint.series.options.precision
                                        : 0)}</b>
                          ${seriesPoint.series.options.unitOfMeasure}` +
                                    (seriesPoint.series['name'] && seriesPoint.series['name'] === RAIN &&
                                        seriesPoint.color === RAIN_HEXCODE_FLAGGED
                                        ? ` <b>(${self.flagText})</b>`
                                        : '');
                                tooltipFormatter.push(point);
                            }
                        }
                        return tooltipFormatter;
                    }
                    return tooltipFormatter;
                }
            },
            plotOptions: {
                line: {
                    events: {
                        legendItemClick: event => {
                        }
                    }
                },
            },
            xAxis: {
                tickInterval: xAxisLabelSettings.interval,
                dateTimeLabelFormats: xAxisLabelSettings.dateFormat,
                labels: {
                    useHTML: true,
                    enabled: true,
                    y: 40,
                    formatter: function () {
                        let currentLabelValue = Highcharts.dateFormat('%e. %b', this.value);
                        if (currentLabelValue === this.axis.defaultLabelFormatter.call(this)) {
                            return (`<span style = "font-weight: bold">${this.axis.defaultLabelFormatter.call(this)}</span>`);
                        } else {
                            return this.axis.defaultLabelFormatter.call(this);
                        }
                    }
                },
            },
        };*
    }*/

    // Desposing objects
    public ngOnDestroy() {
        DataEditService.clearUUID();

        this.fetchGraphSub?.unsubscribe();
        this.fetchGraphSub = null;

        this.lcChart?.destroy();
        this.lcChart = null;

        this.dataEditService.storedEdits = [];
        this.dataEditService.currentEditTimestamp = null;
        this.dataEditService.updatedData$.next(undefined);
        this.viewDataService.hydrographManualScales = null;
        this.viewDataService.scattergraphManualScales = null;
        this.viewDataService.filterValues.next(null);
        this.separateWindowHydrographService.destroy();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public showEvent?(guid: string) {
        const ev = this.events.find((e) => e.guid === guid);
        if(ev) {
            this.editEvent(ev);
        }
    }

    public addEvent() {
        this.editEvent(null);
    }
    public editEvent(ev?: EventModel) {
        const editSubs = this.matDialog.open(AddEventDialogComponent, {
            data: {
                customerId: this.customerId,
                includeInactiveLocations: this.includeInactiveLocations,
                locationGroupId: this.locationGroupId,
                events: this.allEvents,
                event: ev,
                hasPermission: this.userPermissionOnEvent
            },
            disableClose: true
        }).afterClosed().subscribe((result: boolean) => {
            if(result) {
                const loadEventsSub = this.loadEvents(this.isShowEvents).subscribe(() => {
                    // #40597 Notify graph so it will reload events
                    this.eventsLoad$.next();
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                });
                this.subscriptions.push(loadEventsSub);
            }
        });

        this.subscriptions.push(editSubs);
    }

    public resetOriginalData(originalData: BasicSeriesData[]) {
        // #38198 After force update RAW VELOCITY update Original Data as they was "reverted"
        this.newHydroBackupData = cloneDeep(originalData);
    }

    public recalculateEntities(res: RecalculateEntitiesComponentResponse) {
        // #38198 After recalculate
        this.previewLoader$.next(true);
        const { startDate, endDate, customerId, locationId } = res;
        const recalculateEntity = DEPTH_ENTITY;

        const isoStartDate = this.dateutilService.getEndDateAsTimeZone(startDate);
        const start = isoStartDate.split('.')[0];
        const end = this.dateutilService.getEndDateAsTimeZone(endDate);

        this.dataEditService.recalculateEntities(customerId, locationId, recalculateEntity, start, end).subscribe({
            next: () => {
                this.refreshAppDataHandler(null);
                this.previewLoader$.next(false);
            },
            error: () => {
                this.snackBar.open('Failed to recalculate entities', 'Close');
                this.previewLoader$.next(false);
            }
        });
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public notifyGraph({
        filtersData,
        enableAcceptButtonEditor,
        enableAcceptButtonNoReason,
        forceAPICall = false,
        reloadHg = false,
        reloadSg = false,
        fromLocationChange = false
    }: {
        filtersData?: LocationDashboardFilterData;
        reloadHg?: boolean;
        reloadSg?: boolean;
        forceAPICall?: boolean;
        enableAcceptButtonEditor?: boolean;
        enableAcceptButtonNoReason?: boolean;
        fromLocationChange?: boolean;
    }) {
        if (enableAcceptButtonEditor !== undefined) {
            this.enableAcceptEditor = enableAcceptButtonEditor;
        } else if (enableAcceptButtonNoReason) {
            this.enableAcceptNoReason = enableAcceptButtonNoReason;
        }

        // #37053 need to reset invalid scales when changing location
        if (fromLocationChange && this.advanceScatterGraph) {
            this.advanceScatterGraph.resetInvalidCustomRanges();
        }

        if (!this.prevFiltersData || !Object.keys(this.prevFiltersData).length || this.checkIfNeedToReloadGraphs(filtersData, this.prevFiltersData) || fromLocationChange) {
            reloadHg = true;
            reloadSg = true;
        }
        if (!filtersData || (filtersData && this.prevFiltersData && this.checkIfFilterParamsAreEqual(filtersData, this.prevFiltersData)) && (!reloadSg && !reloadHg)) {
            return;
        }

        let netAnnotations = filtersData.annotationsSettings;
        let isSiltEnabled: boolean;
        if (this.annotationSettings) {
            isSiltEnabled = (netAnnotations.isSilt && fromLocationChange) || (netAnnotations.isSilt && !this.annotationSettings.isSilt);
            // Take some entities from annotation settings and some from filters data
            netAnnotations = {
                ...netAnnotations,
                ...this.annotationSettings,
                isSilt: filtersData.annotationsSettings.isSilt,
            }; // Because silt is special...
        }

        if (isSiltEnabled) {
            reloadSg = true;
            this.annotationSettings = { ...this.annotationSettings, ...netAnnotations };
        }

        if (netAnnotations && this.annotationSettings && !netAnnotations.isSilt && this.annotationSettings.isSilt && this.advanceScatterGraph) {
            this.advanceScatterGraph.onAnnotationChange(this.advanceScatterGraph.annotationSettings, { ...netAnnotations });
        }

        // Before changing anything track what has changed for handling API calls
        const prevEntities =
            this.prevFiltersData && this.prevFiltersData.entities && this.prevFiltersData.entities.map((x) => x.id);
        const newEntities = filtersData.entities && filtersData.entities.map((x) => x.id);

        // #23563 hahe to clone, otherwise for example date is compared to the same date
        this.prevFiltersData = JSON.parse(JSON.stringify(filtersData));

        this.filterSettings.isDisplayDatePicker = !(this.showEditingMenu === true);
        this.filterSelectedValues = filtersData;
        this.selectedEntities = filtersData.entities;
        this.isBasicDataEditingAllowed = false;
        this.filterWidgetService.setisEmptyDashboardState(false);
        this.locIndex = 0;
        this.isRefreshDataTable = true;

        // For scatter
        this.scatterDateFormat = this.dateutilService.getGraphDateFormat();
        this.dataEditingArray.length = 0;

        if (this.annotationSettings) {
            Object.keys(netAnnotations).forEach((v) => (this.annotationSettings[v] = netAnnotations[v]));
        } else {
            this.annotationSettings = { ...netAnnotations };
        }

        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.annotationSettings = this.annotationSettings;
        }
        this.locationName = filtersData.locationName;
        this.locationId = filtersData.locationIDs[0];
        if(this.startDate?.getTime() !== filtersData.startDate?.getTime() || this.endDate?.getTime() !== filtersData.endDate?.getTime()) {
            this.resetHGZoom();
        }
        this.startDate = filtersData.startDate;
        this.endDate = filtersData.endDate;

        this.selectedDateRange$.next([this.startDate, this.endDate]);

        this.checkIfRawVelExist();
        if (this.viewDataFilter) {
            this.viewDataFilter.scrollToSelectedLocation();
        }
        this.locString.push(this.locationName);
        this.getHydrographParams = <HydrographArgs>{
            summarizeInterval: 0
        };
        this.getHydrographParams.summarizeInterval = !filtersData.summarizeInterval ? 0 : filtersData.summarizeInterval;
        this.getHydrographParams = <HydrographArgs>{
            start: this.startDate,
            end: this.endDate,
            entityIds: filtersData.entities.map((x) => x.id),
            locationId: filtersData.locationIDs[0],
            summarizeInterval: this.getHydrographParams.summarizeInterval,
        };
        this.viewDataService.filterValues.next(filtersData);

        if (!fromLocationChange && prevEntities && prevEntities.length > newEntities.length && newEntities.every((v) => prevEntities.includes(v))) {
            // Take backup of the data, remove unwanted entities
            if (this.newHydroBackupData) {
                this.newHydroBackupData = this.newHydroBackupData.filter((x) => x.entityId < 0 || newEntities.includes(x.entityId));
                Object.seal(this.newHydroBackupData);
                this.shipDataToNEWHydrograph(this.newHydroBackupData);

                reloadHg = false;
            }
        } else if (prevEntities && (prevEntities.length !== newEntities.length || prevEntities.some(v => !newEntities.includes(v)))) {
            reloadHg = true;
        }

        if (reloadHg || reloadSg) {
            this.enableHeaderButtons = false;
        }

        if (reloadHg) {
            if(this.isShowEvents) {
                this.loadEvents(this.isShowEvents).subscribe(() => {});
            }
            this.getHydroGraphData(this.getHydrographParams, false, isSiltEnabled, this.hydrographFilterPointsDateRange);
        }
        if (reloadSg) {
            this.getScatterGraphData(filtersData);
        }

        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    public onIncludeZeroRainChange(newValue: boolean) {
        this.annotationSettings.includeZeroRainValues = newValue;

        this.getHydroGraphData(this.getHydrographParams);
    }

    // Need to check if filter params are equal to avoid duplicate API requests
    private checkIfFilterParamsAreEqual(current: LocationDashboardFilterData, prev: LocationDashboardFilterData) {
        const keys = Object.keys(current);

        if (keys.length !== Object.keys(prev).length) {
            return false;
        }

        let isDifferent = false;
        for (const key of keys) {
            if (!isEqual(current[key], prev[key])) {
                isDifferent = true;
                break;
            }
        }

        return !isDifferent;
    }

    private checkIfNeedToReloadGraphs(current: LocationDashboardFilterData, prev: LocationDashboardFilterData) {
        if (!prev || !current) {
            return true;
        }

        const keys = ['summarizeInterval', 'locationName'];
        const dateKeys = ['startDate', 'endDate'];

        let isDifferent = false;
        if(current !== undefined && prev !== undefined) {
            for (const key of keys) {
                if (!isEqual(current[key], prev[key])) {
                    isDifferent = true;
                    break;
                }
            }

            for (const dateKey of dateKeys) {
                if (!current[dateKey] || !prev[dateKey] || (new Date(current[dateKey]).getTime() !== new Date(prev[dateKey]).getTime())) {
                    isDifferent = true;
                    break;
                }
            }
        }

        if (prev?.isResetScale === false && current?.isResetScale === true) {
            isDifferent = true;
        }

        return isDifferent;
    }
    /**
     * Retrieves hydrograph data based on user selected time.
     * @param val Represents the value on which data Averaging will be performed.
     */
    public dataAveraging(val: number) {
        const locationDashboardFilters = this.viewDataService.filterValues.getValue();
        this.viewDataService.filterValues.next({ ...locationDashboardFilters, summarizeInterval: val });
        this.getHydrographParams.summarizeInterval = val;
        this.getHydroGraphData(<HydrographArgs>{
            start: this.startDate,
            end: this.endDate,
            entityIds: this.getHydrographParams.entityIds,
            locationId: this.getHydrographParams.locationId,
            summarizeInterval: val,
        });

        const filterData = <LocationDashboardFilterData>{ ...this.filterSelectedValues, summarizeInterval: val };
        this.getScatterGraphData(filterData);

        /* Resetting the highlights */
        this.viewDataService.highlightAdvancedScatterChartPoints(null);
    }

    public notifyEventsChange(isShowEvents: boolean) {
        if(this.isShowEvents !== isShowEvents) {
            if(isShowEvents) {
                this.loadEvents().subscribe(() => {
                    this.setGraphObject();
                });
            } else {
                this.events = null;
                this.eventsLoad$.next();
            }

            this.isShowEvents = isShowEvents;
        }
    }

    public notifyAnnotationSelection(annotationsSelection: AnnotationSettings) {
        if (!this.annotationSettings) return;

        this.scatterGraphStatus = false;
        if (!annotationsSelection.isConfirmationPoints) {
            this.scatterGraphConfirmationData = {};
        }
        const commonSettings = ['isSilt', 'isManholeDepth', 'isPipeHeight', 'isDataQuality'];
        const alarmSettings = ['isHighHigh', 'isHighFlow', 'isHighLevel', 'isLowDepth'];
        const hgSettings = ['isRainOnTop', 'isShowEdits', 'isConfirmationPoints'];
        const sgSettings = ['isBestFit', 'isFroude', 'isIsoQ', 'isSSCurve', 'isPipeOverlay', 'isManualSmooth', 'isManualLinear'];
        // scattergraph settings that don't need API call, they are handled on UI, confirmations handled in onScatterConfirmationDataChange method
        const sgCommonSettings = ['isToolbarEnabled', 'isScatterInvert', 'isConfirmationPoints'];

        const prevSettings = { ...this.annotationSettings };
        const newAnnotations = { ...this.annotationSettings, ...annotationsSelection };
        const isScatterSettingTurnedOff = sgSettings.some((key) => (!!prevSettings[key] !== !!newAnnotations[key]) && !newAnnotations[key]);
        const commonSettingTurnedOff = [...commonSettings, ...alarmSettings].find((key) => (!!prevSettings[key] !== !!newAnnotations[key]) && !newAnnotations[key]);
        // If scattergraph setting was turned off do not recall scattergraph, code below will trigger changes in scattergraph component
        if (sgCommonSettings.some(v => !!prevSettings[v] !== !!newAnnotations[v]) || isScatterSettingTurnedOff || commonSettingTurnedOff) {
            this.annotationSettings = newAnnotations;
        }
        // do not reload hydrograph but just remove annotation series if setting was turned off
        if (commonSettingTurnedOff && this.newHydroBackupData) {
            this.shipDataToNEWHydrograph(this.newHydroBackupData);
        }

        if (prevSettings.isShowEdits && !newAnnotations.isShowEdits) {
            this.newHydroBackupData = this.newHydroBackupData.filter(v => v.entityId > 0 || !(String(v.entityId).match(/\-\d+\.\d+/)));
            Object.seal(this.newHydroBackupData);
            this.shipDataToNEWHydrograph(this.newHydroBackupData);
        }

        if (prevSettings.isConfirmationPoints && !newAnnotations.isConfirmationPoints) {
            this.newHydroBackupData = this.newHydroBackupData.filter(v => v.entityId > 0 || String(v.entityId).match(/\-\d+\.\d+/));
            Object.seal(this.newHydroBackupData);
            this.shipDataToNEWHydrograph(this.newHydroBackupData);
        }

        Object.keys(annotationsSelection).forEach(v => this.annotationSettings[v] = annotationsSelection[v]);
        let reloadHg = false;
        let reloadSg = false;
        if ([...hgSettings, ...commonSettings, ...alarmSettings]
            .some((key) =>
                !!prevSettings[key] !== !!newAnnotations[key]
                && (newAnnotations[key] || key === 'isDataQuality' || key === 'isRainOnTop'))) {
            reloadHg = true;
        }

        if ([...commonSettings, ...sgSettings].some((key) => (!!prevSettings[key] !== !!newAnnotations[key]) && (newAnnotations[key] || key === 'isDataQuality'))) {
            reloadSg = true;
        }

        if (
            this.viewDataFilter && this.prevFiltersData &&
            (new Date(this.prevFiltersData.startDate).getTime() !== new Date(this.viewDataFilter.startDate).getTime() ||
                new Date(this.prevFiltersData.endDate).getTime() !== new Date(this.viewDataFilter.endDate).getTime())
        ) {
            // Fix for #21964 - not reloading configuration dates
            this.filterSelectedValues.startDate = this.viewDataFilter.startDate;
            this.filterSelectedValues.endDate = this.viewDataFilter.endDate;

            reloadHg = true;
        }

        if (this.filterSelectedValues) {
            this.filterSelectedValues.annotationsSettings = this.annotationSettings;
        }

        if (this.locationId && this.filterSelectedValues && (!this.filterSelectedValues.locationIDs || this.filterSelectedValues.locationIDs[0] !== this.locationId)) {
            this.filterSelectedValues.locationIDs = [this.locationId];
        }
        this.notifyGraph({ filtersData: this.filterSelectedValues, reloadHg, reloadSg });
        // this.persistDataEditingPoints();
    }

    public backRedirect(includeLocationID?: boolean) {
        const parameters = <AppQueryParams>{
            c: this.customerId,
            lg: this.locationGroupId,
            lt: Number(this.includeInactiveLocations),
            s: null,
        };

        if (includeLocationID) {
            parameters.lid = this.locationId;
        }

        this.router.navigate(['/pages/menuDashboard/viewData'], {
            queryParams: parameters,
        });
    }

    public onScatterCurveChanged(annotationSettings: AnnotationSettings) {
        const { isBestFit, isSSCurve, isToleranceLines, isManualLinear, isManualSmooth, isCWRcurve, isManningDesign, isLanfearColl } = annotationSettings;
        this.annotationSettings.isBestFit = isBestFit;
        this.annotationSettings.isSSCurve = isSSCurve;
        this.annotationSettings.isManualLinear = isManualLinear;
        this.annotationSettings.isManualSmooth = isManualSmooth;
        this.annotationSettings.isToleranceLines = isToleranceLines;
        this.annotationSettings.isCWRcurve = isCWRcurve;
        this.annotationSettings.isManningDesign = isManningDesign;
        this.annotationSettings.isLanfearColl = isLanfearColl;
        this.filterSelectedValues.annotationsSettings = { ...annotationSettings };

        this.viewDataFilter.annotationSettings = { ...this.annotationSettings };
    }

    public onShowToolbarChange(newValue: boolean) {
        this.showCurveEnabled = newValue;
    }

    public hideScattergraph() {
        this.hideScatterGraph = true;

        this.scatterGraphData = null;
        this.zoomForScatter$.next([]);
        this.toggleScatterGraph(false);
        this.toggleHydroGraph(true);
        this.noDataForScatterChart = true;
    }

    public getScatterGraphData(filters: LocationDashboardFilterData, rawvelEdits: Map<number, DataEditOriginalValue> = null): void {
        // If entities aren't available hide hydrograph
        const location = this.locationEntityData.l.find((x) => x.lid === this.locationId);

        if (!location || !this.annotationSettings || !location.s) {
            return;
        }

        const monitorType = location.s.toLowerCase();
        let entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === monitorType);
        if (!entityGroups) {
            entityGroups = this.locationEntityData.d.find((x) => x.s.toLowerCase() === OTHERTEXT);
        }

        const series = this.dataEditService.newHydroData$.getValue();

        const editedTimestampsSet = new Set(this.editedPointsForSg.filter(v => v.edited).map(v => v.stamp));
        this.editedPointsForSg = !series ? [] : series.reduce((acc, curr) => {
            if (![RAW_VELOCITY_ENTITY, VELOCITY_ENTITY, DEPTH_ENTITY].includes(curr.entityId)) {
                return acc
            }

            return [...acc, ...curr.data.filter(v => (v.correctedY !== undefined || v.flagged)).map(v => ({ id: curr.entityId, stamp: v.x, value: v.correctedY, flagged: v.flagged, edited: editedTimestampsSet.has(v.x) }))];
        }, []);

        if (this.shouldEnterEditModeSG) {
            this.enterEditMode();
            this.shouldEnterEditModeSG = false;
        }
        // Otherwise do API call and load scatter
        if (this.advanceScatterGraph) {
            this.advanceScatterGraph.storeRawvelEdits(rawvelEdits);
            this.advanceScatterGraph.scatterResponse.pipe(first()).subscribe((data) => this.onScatterResponse(data));
            // #23803 update annotation settings, as advanceScatterGraph holds a copy of it itself to make sure request will be done with correct params
            this.advanceScatterGraph.updateAnnotationSettings(this.annotationSettings);
            this.advanceScatterGraph.getScatterGraphData(filters, this.scatterGraphConfirmationData, !rawvelEdits);

            return;
        }

        // Scattergraph needs at least 2 entities to load
        if (!this.selectedSGEntityIds || !this.selectedSGEntityIds.length || this.selectedSGEntityIds.length < 2) {
            return;
        }

        this.hideScatterGraph = false;

        const entityIds = this.viewDataService.sortSelectedEntities(
            this.selectedSGEntityIds,
            this.annotationSettings.isScatterInvert,
        );

        if(!entityIds || !entityIds.length || entityIds.length != 2){
            return;
        }

        const confirmationData: { type?: string; dateRange?: { start: string; end: string } } =
            !this.scatterGraphConfirmationData
            ? null
            : this.scatterGraphConfirmationData.type === 'all'
                ? {type: 'all'}
                : {
                    type: this.scatterGraphConfirmationData.type,
                    dateRange: {
                        start:
                            this.scatterGraphConfirmationData.dateRange
                            && this.scatterGraphConfirmationData.dateRange.start
                            ? this.scatterGraphConfirmationData.dateRange.start.toString() : null,
                        end:
                            this.scatterGraphConfirmationData.dateRange
                            && this.scatterGraphConfirmationData.dateRange.end
                            ? this.scatterGraphConfirmationData.dateRange.end.toString() : null
                    }
                }

        const tempFlags = [];
        this.subscriptions.push(
            this.viewDataService
                .getScatterGraphOptimized(
                    this.customerId,
                    filters.locationIDs[0],
                    filters.startDate,
                    filters.endDate,
                    filters.annotationsSettings.isPipeOverlay ? true : false,
                    this.annotationSettings.isPipeHeight,
                    this.annotationSettings.isManholeDepth,
                    this.annotationSettings.isIsoQ,
                    this.annotationSettings.isBestFit,
                    this.annotationSettings.isSSCurve,
                    this.annotationSettings.isCWRcurve,
                    this.annotationSettings.isManningDesign,
                    this.annotationSettings.isLanfearColl,
                    this.annotationSettings.isFroude,
                    tempFlags,
                    confirmationData,
                    entityIds,
                    filters.summarizeInterval,
                    this.annotationSettings.isSilt,
                    this.reloadCache
                )
                .subscribe(
                    (scatterResult: ScatterData) => {
                        // late response, user is viewing another location
                        if (this.locationId !== Number(filters.locationIDs[0])) {
                            return;
                        }
                        // Force it to run after screen adjustments are made, and force view to refresh. Needed for #16939 when data are delivered instantly.
                        setTimeout(() => {
                            if (!scatterResult) {
                                /** Handling in case we have 204 No content in Response */
                                this.scattergraphLoading = false;
                                this.scatterGraphData = null;
                                this.noDataForScatterChart = true;

                                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                                this.toggleHydroGraph(true);
                                return;
                            }
                            this.enableHeaderButtons = true;
                            this.scatterGraphData = <ScatterData>scatterResult;
                            this.scattergraphLoading = false;
                            this.noDataForScatterChart = false;
                            this.toggleHydroGraph(false);
                        }, 0);
                    },
                    (error) => {
                        this.scattergraphLoading = false;
                        this.scatterGraphData = null;
                        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                    },
                ),
        );
    }

    public onCurveChanged(curve: string[]) {
        this.scatterGraphData.c = curve;
        if (this.scatterGraphData.curves) {
            this.scatterGraphData.curves[0].d = curve;
        }
    }

    public onScatterResponse(response: ScatterData) {
        if (!response) {
            /** Handling in case we have 204 No content in Response */
            this.scattergraphLoading = false;
            this.scatterGraphData = null;
            this.noDataForScatterChart = true;

            // #28108 hide SG as there is no data
            this.hideScattergraph();

            return;
        }

        this.enableHeaderButtons = true;

        this.scatterGraphData = <ScatterData>response;
        this.scattergraphLoading = false;
        this.noDataForScatterChart = false;

        if (!this.handToogleHydrograph) {
            this.toggleHydroGraph(false);
        }
    }

    public onScatterConfirmationDataChange(confirmationData: {
            filter: ConfirmationDateRange, isConfirmationPoints: boolean, isFromDate: boolean, reloadHyg : false
        }) {
        const { filter, isConfirmationPoints, isFromDate, reloadHyg  } = confirmationData;
        const { type, dateRange } = filter;
        const prevScatterGraphConfirmationData = this.scatterGraphConfirmationData;
        this.scatterGraphConfirmationData = { type, dateRange };
        this.hydrographFilterPointsDateRange = filter;

        const confirmationVisibilityChange = !!this.annotationSettings?.isConfirmationPoints !== !!isConfirmationPoints;
        const confirmationTypeChange = prevScatterGraphConfirmationData.type !== this.scatterGraphConfirmationData.type
        const confirmationDateRangeChange =
            this.scatterGraphConfirmationData.type === 'date'
            && this.scatterGraphConfirmationData.dateRange?.start !== prevScatterGraphConfirmationData.dateRange?.start
            && this.scatterGraphConfirmationData.dateRange?.end !== prevScatterGraphConfirmationData.dateRange?.end

        if (confirmationVisibilityChange
            || confirmationTypeChange
            || confirmationDateRangeChange
        ) {
            if (isConfirmationPoints && !isFromDate) {
                this.notifyGraph({ filtersData: this.filterSelectedValues, reloadHg: reloadHyg, reloadSg: true });
            } else if (this.advanceScatterGraph && !isFromDate) {
                this.notifyAnnotationSelection({ ...this.annotationSettings, isConfirmationPoints: false }, );
                this.advanceScatterGraph.onAnnotationChange(this.advanceScatterGraph.annotationSettings, { ...this.annotationSettings, isConfirmationPoints: false });
            }
        }
    }
    /* END SCATTER FUNCTIONS */

    public acceptDataEditing() {
        this.endDte = null;
        this.showResonForEdit = !this.enableAcceptNoReason;
        this.domOperationUtilsService.deDialogOpened.next(this.showResonForEdit);
        const hgSeries = this.dataEditService.newHydroData$.getValue();

        // #22346 Update flags based on there are any not flagged points
        this.allDataFlagged = ! hgSeries.some(e => e.data && e.data.some(p => p.y !== null && p.y !== undefined && !p.flagged));
        this.noDataForHydrographChart = this.allDataFlagged;
        this.dataEditService.clearSgIgnoredPoints();

        if (!this.showResonForEdit) {
            this.submitTelemetryData();
        }
    }

    public clearFilter() {
        this.viewDataService.clearFilter.next(true);
    }

    public applyTranslation() {
        const translateKeys: Array<string> = [
            'COMMON.CORRECTED_TEXT',
            'LOCATION_DASHBOARD.CONFIRMATION_DEPTH',
            'LOCATION_DASHBOARD.CONFIRMATION_FLOW',
            'LOCATION_DASHBOARD.CONFIRMATION_VELOCITY',
            'HOME.MAP.FLOW_PLACEHOLDER',
            'LOCATION_DASHBOARD.VELOCITY_ENTITY',
            'COMMON.OTHER_TEXT',
            'COMMON.DISMISS_TEXT',
            'COMMON.CSV_EXPORT_SUCCESS',
            'COMMON.CSV_EXPORT_ERROR',
            'LOCATION_DASHBOARD.HYDROGRAPH_DETAIL_VIEW.COPY_PASTE_PREVIEW_FAILURE_TXT',
            'COMMON.GRAPH_ALERT_TEST',
            'LOCATION_DASHBOARD.HYDROGRAPH_DETAIL_VIEW.MULTI_POINT_PREVIEW_FAILURE_TEXT',
            'LOCATION_DASHBOARD.DATA_EDITING_CONFIRM_MSG',
            'COMMON.OK',
            'COMMON.CANCEL_BUTTON',
            'COMMON.INFORMATION',
            'LOCATION_DASHBOARD.HYDROGRAPH_DETAIL_VIEW.HYDROGRAPH_EDIT_MODE_LEAVE',
            'COMMON.ATTENTION',
            'LOCATION_DASHBOARD.DASHBOARD_FILTER.FLAG',
            'LOCATION_DASHBOARD.MISSING_PIPE_INFORMATION',
            'VAULT.VAULT_TELEMETRY.EXPORT.NO_DATA_EXPORT_ERR',

        ];

        this.subscriptions.push(
            this.translate.get(translateKeys).subscribe((response) => {
                this.correctedText = response['COMMON.CORRECTED_TEXT'];

                this.dismissButton = response['COMMON.DISMISS_TEXT'];
                this.csvExportSuccessMsg = response['COMMON.CSV_EXPORT_SUCCESS'];
                this.csvExportErrorMsg = response['COMMON.CSV_EXPORT_ERROR'];

                this.graphAlertText = response['COMMON.GRAPH_ALERT_TEST'];
                this.okText = response['COMMON.OK'];
                this.cancelText = response['COMMON.CANCEL_BUTTON'];
                this.dataEditingConfirmText = response['LOCATION_DASHBOARD.DATA_EDITING_CONFIRM_MSG'];
                this.dataEditingConfirmTitle = response['COMMON.INFORMATION'];

                this.confirmationText = {
                    depth: response['LOCATION_DASHBOARD.CONFIRMATION_DEPTH'],
                    quantity: response['LOCATION_DASHBOARD.CONFIRMATION_FLOW'],
                    velocity: response['LOCATION_DASHBOARD.CONFIRMATION_VELOCITY'],
                };

                this.noDataMsg = response['VAULT.VAULT_TELEMETRY.EXPORT.NO_DATA_EXPORT_ERR']

                this.canceDataEdit = response['LOCATION_DASHBOARD.HYDROGRAPH_DETAIL_VIEW.HYDROGRAPH_EDIT_MODE_LEAVE'];
                this.attention = response['COMMON.ATTENTION'];
                this.flagText = response['LOCATION_DASHBOARD.DASHBOARD_FILTER.FLAG'];

                this.missingPipeInfoText = response['LOCATION_DASHBOARD.MISSING_PIPE_INFORMATION'];
            }),
        );
    }

    public cancelDataEdit() {
        if (this.entitiesEdited && this.entitiesEdited.length !== 0) {
            this.subscriptions.push(
                this.matDialog
                    .open(ConfirmationDialogComponent, {
                        disableClose: true,
                        data: {
                            title: this.attention,
                            message: this.canceDataEdit,
                            okText: this.okText,
                            cancelText: this.cancelText,
                        },
                    })
                    .afterClosed()
                    .subscribe((result) => {
                        if (result.whichButtonWasPressed === 'ok') {
                            this.cancelDataEditing();
                        }
                    }),
            );
        } else {
            // No reason for popup if nothing was changed
            this.cancelDataEditing();
        }
    }

    public cancelDataEditing(notifyOtherWindows = true, notifyDisableHeader = true) {
        if (!this.showEditingMenu) {
            return;
        }
        if (notifyOtherWindows) {
            this.separateWindowHydrographService.dispatchAction<null>({ type: SeparateWindowActionTypes.cancelEdit, payload: null });
        }
        if (DataEditService.guid) {
            this.dataEditService.dataEditDelete(this.customerId).subscribe();
            DataEditService.clearUUID();
        }

        this.dataEditService.storedEdits = [];
        this.dataEditService.updatedData$.next(undefined);
        this.dataEditService.currentEditTimestamp = null;
        this.dataEditService.onEditsChanged.next(null);
        this.restoreSGEntitiesBeforeEdit();

        // #22301 do not emit event, we rebuild HG anyways
        this.onDataSelectForSnap({ selected: [], snapped: [] }, false);

        if (this.newHydroBackupData) {
            this.dataEditService.newHydroData$.next([...cloneDeep(this.newHydroBackupData)]);
        }
        // For scatter
        this.scatterGraphEnableSnapToCurveOption = true;
        this.dataEditService.clearSgIgnoredPoints();
        if (
            this.advanceScatterGraph &&
            this.advanceScatterGraph.graph &&
            this.advanceScatterGraph.graph.advanceScatteroptions
        ) {
            this.editedPointsForSg = [];
            // #22301 do not emit event, we rebuild HG anyways
            this.advanceScatterGraph.graph.clearSeries(false, false, false);
            this.advanceScatterGraph.graph.advanceScatteroptions.series =
            this.advanceScatterGraph.graph.advanceScatteroptions.series.filter((v) => v.name === 'scatterPoints');

            // #34603 remove extra scattergraph call when comning from disable header subscription
            if (this.advanceScatterGraph.graph.shouldUpdateCurve && notifyDisableHeader) {
                this.advanceScatterGraph.graph.shouldUpdateCurve = false;
                this.getScatterGraphData(this.viewDataService.filterValues.getValue());
            }
        }

        if (this.shouldRefetchHydrograph) {
            this.shouldRefetchHydrograph = false;
            this.getHydroGraphData(this.getHydrographParams);
        }

        if (!this.viewDataFilter.isToolbarEnabled) {
            if (this.advanceScatterGraph) {
                this.advanceScatterGraph.savedCurveData = null;
            }
            this.annotationSettings.isCurveEnabled = false;
            this.annotationSettings.isBestFit = false;
            this.annotationSettings.isSSCurve = false;
            this.annotationSettings.isManualLinear = false;
            this.annotationSettings.isManualSmooth = false;
        }
        this.annotationSettings = {
            ...this.annotationSettings,
            isToolbarEnabled: this.viewDataFilter.isToolbarEnabled,
        };

        this.onFlagPoints();

        this.dataEditService.showEditMenu = false;
        this.showEditingMenu = false;
        this.viewDataService.isDataEditingOpen.next(false);
        this.statusCodeService.enableGlobalDropDowns.next(false);


        if (this.entitiesEdited && this.entitiesEdited.length !== 0) {
            // Changes have been made
            // For overall page
            this.editedDataArray = [];
            this.entitiesEdited = [];
        }

        this.editedEntitiesTracker = [];

        if(notifyDisableHeader) this.disableHeader$.next(false);

        this.lcChart?.edit?.clearEditingParams();
    }

    public currentlySelectedReasons(reasons: Array<Selectable>, index: number) {
        this.selectedReason = '';
        const filterReasons = reasons.filter((item) => item.isChecked);
        this.isReasonSelected = filterReasons.length > 0 && filterReasons[0].name === OTHER;
        const reasonNameArray = new Array<string>();
        filterReasons.forEach((selectableitem: Selectable) => {
            reasonNameArray.push(selectableitem.name);
            this.selectedReason = selectableitem.name;
        });
        this.hideCommentSection[index] =
            !(reasonNameArray.toLocaleString().toLowerCase().indexOf(OTHERTEXT) > -1) && reasonNameArray.length > 0;
        if (!this.hideCommentSection[index]) {
            this.isReasonSelected = true;
            this.otherReason = '';
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        }
    }

    public clearSelectedReasons() {
        if (this.reasonsSelector && this.reasonsSelector.length > 0) {
            this.reasonsSelector[0].forEach((item) => {
                item.isChecked = false;
            });
            this.selectedReason = '';
            // #// #22834 revert to feature setting
            this.isReasonSelected = this.isDataEditCommentsAllowed;
        }
    }

    public otherReasonState(reasonText) {
        this.isReasonSelected = !(reasonText.length > 0);
    }

    public emitDataEditingDialog() {
        this.showResonForEdit = false;
        this.domOperationUtilsService.deDialogOpened.next(false);
        this.datesDisabled = true;
        this.resetAfterSubmitDiag();
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    public submitTelemetryData() {
        this.previewLoader$.next(true);

        const series = this.dataEditService.newHydroData$.getValue();
        for (const point of series) {
            if(point && point.data) {
                for (let i = 0; i < point.data.length; i++) {
                    point.data[i].snapped = false;
                }
            }
        }

        this.onDataSelectForSnap({ selected: [], snapped: [] }, false);

        this.selectedReason = this.selectedReason
            ? this.selectedReason
            : this.otherReason
            ? this.otherReason
            : 'No comment submitted';
        let submitData = [];
        if (this.editedDataArray.length > 0) {
            submitData = this.editedDataArray.map((editedData) => {
                return {
                    timeStamp: editedData.whichPoint.timeStamp || new Date(editedData.dateTime).toISOString(),
                    eid: editedData.whichPoint.id ? editedData.whichPoint.id : editedData.id,
                    reading: editedData.whichPoint.modifiedValue
                        ? editedData.whichPoint.modifiedValue
                        : editedData.originalModifiedValue,
                    reason: this.selectedReason === OTHER ? this.otherReason : this.selectedReason,
                    ignore: editedData.whichPoint.isFlagged ? editedData.whichPoint.isFlagged : editedData.isFlagged,
                };
            });
        }

        if (submitData.length > 0 && submitData[0].reason === OTHER) {
            submitData[0].reason = this.otherReason;
        }

        this.newHydroBackupData = cloneDeep(this.dataEditService.newHydroData$.value); // Update backup with saved changes; Immutable clone
        this.showEditingMenu = false;
        this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.submitEdits, payload: null });
        this.subscriptions.push(
            this.dataEditService.dataEditSubmit(this.customerId, this.locationId, this.selectedReason).subscribe(
                () => {
                    this.lcChart?.edit?.clearEditingParams();

                    // #42689 SG Rewrite edited data onto original data
                    if (this.scatterGraphData && this.scatterGraphData.d) {
                        this.scatterGraphData.d = this.dataEditService.updateOriginalSGValues(this.scatterGraphData.d, this.editedPointsForSg, this.selectedSGEntityIds);
                        this.scatterGraphData = cloneDeep(this.scatterGraphData);
                    }

                    if (this.endDte || (this.startDte && this.endDte)) {
                        this.approveData();
                    }
                    this.resetAfterSubmit();
                    DataEditService.clearUUID();
                },
                (error) => {
                    this.resetAfterSubmit();
                },
            ),
        );

        this.editedEntitiesTracker = [];
    }

    public errorOnApproveData(isError: boolean) {
        this.isErrorOnApproveData = isError;
    }

    private getAllRawvelEditsOnSubmit(): Map<number, DataEditOriginalValue> {
        return this.dataEditService.storedEdits.reduce((acc, curr) => {
            if (!curr || !curr.parsedResponse) {
                return acc;
            }

            const rawvelEdits = curr.parsedResponse.get(RAW_VELOCITY_ENTITY);

            if (rawvelEdits) {
                acc = new Map([...acc, ...rawvelEdits]);
            }

            return acc;
        }, new Map<number, DataEditOriginalValue>());
    }

    public startDateChanged(startDate : Date) {
        this.startDte = startDate;
    }

    public endDateChanged(endDate: Date) {
        this.endDte = endDate;
    }

    private approveData() {
        let startDate = '';
        let endDate = '';
        if(this.startDte && !isNaN(Date.parse(this.startDte.toString())))
        {
            startDate = this.dateutilService.formatDateWithoutOffset(this.startDte);
        }
        endDate = this.dateutilService.formatDateWithoutOffset(this.endDte);

        this.viewDataService.dataEditApproval(this.customerId, this.locationId, startDate, endDate).subscribe(() => {
                    this.dataEditService.mostRecentApproval(this.customerId, [this.locationId]).subscribe(
                        (result: Approval[]) => {
                            if(result)
                            {
                                this.prevApprovalDates({ prevApprStartDate : result[0].approvedStartDate, prevApprEndDate: result[0].approvedThrough, approvedBy : result[0].approvedBy });
                            }
                        }
                    ); 
            this.refreshAppDataHandler(null); 
            this.onApprovalDateToggle({ checked: false } as MatSlideToggleChange);
        });
    }

    private resetAfterSubmitDiag() {
        this.otherReason = '';
        this.isReasonSelected = false;
        this.hideCommentSection = new Array<boolean>();
        this.reasonsSelector[this.numberOfEntries] = [];
        this.clearSelectedReasons();
    }

    public resetAfterSubmit() {
        this.resetAfterSubmitDiag();
        const updatesMap = this.dataEditService.getAllAppliedEdits();

        this.disableHeader$.next(false);
        this.showResonForEdit = false;
        this.domOperationUtilsService.deDialogOpened.next(false);
        this.dataEditService.showEditMenu = false;
        this.showEditingMenu = false;

        this.restoreSGEntitiesBeforeEdit();

        this.viewDataService.collectiveFlaggedPoints.next(null);
        this.viewDataService.flaggedPoints.next(null);
        this.viewDataService.unFlaggedPoints.next(null);
        this.viewDataService.copiedPoints.next(null);
        this.viewDataService.flagOptionSelected.next(null);
        this.viewDataService.updateDataTableWithCopiedPoints.next(null);
        this.editedDataArray = [];
        this.previewLoader$.next(false);
        this.filterSettings.isDisplayDatePicker = true;
        this.viewDataService.advancedCharts[0] = this.viewDataService.enableDisableChartZoom(
            true,
            this.viewDataService.advancedCharts[0],
        );
        DataEditService.guid = null;
        this.dataEditService.storedEdits = [];
        this.dataEditService.currentEditTimestamp = null;
        this.dataEditService.onEditsChanged.next(null);
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        // refresh hydrograph and scatter graph
        this.selectedReason = '';
        this.enableAcceptEditor = false;

        if (updatesMap) {
            const hgSeries = this.dataEditService.newHydroData$.getValue();
            hgSeries.forEach((v) => {
                if (v.data) {
                    const fromUpdateEntity = updatesMap.get(v.entityId);

                    v.data.forEach((i) => {
                        const fromUpdatePoint = fromUpdateEntity ? fromUpdateEntity.get(i.x) : null;

                        // #40489 If point exist in getAllAppliedEdits then it was mofidied andmark it as edited
                        // #40489 If point has correctedY property then it was modified earlier (comes from API)
                        i.edited = !!fromUpdatePoint || !!i.correctedY;
                        i.interpolated = false;
                        i.interpolationAccepted = false;
                        i.selected = false;
                    });
                }
            });
        }
    }

    public bindDataEditReasons() {
        this.reasonsSelector[0] = [];
        this.subscriptions.push(
            this.viewDataService.DataEditReasons.subscribe(
                (resonsResponse: DataEditingReasons) => {
                    if (resonsResponse) {
                        this.reasons = resonsResponse;
                        this.reasons.forEach((entity: DataEditingReasons) => {
                            this.reasonsSelector[0].push({
                                name: entity.reason,
                                id: entity.id,
                            });
                        });
                    }
                },
                (error) => {
                    // empty block
                },
            ),
        );
    }

    public scatterGraphApplySnapCurveChange($event, notifyOtherWindows = true) {
        if (this.entitiesEdited.findIndex((o) => o === VELOCITY) < 0) {
            this.entitiesEdited.push(VELOCITY);
        }
        if (this.entitiesEdited.findIndex((o) => o === QCONTINUITY) < 0) {
            this.entitiesEdited.push(QCONTINUITY);
        }
        this.enableAcceptNoReason = false;
        this.enableAcceptEditor = false;

        this.numberOfEntries = 1;
        this.numberOfTimes = Array(+this.numberOfEntries).fill(0);
        if (notifyOtherWindows) {
            this.separateWindowHydrographService.dispatchAction<null>({ type: SeparateWindowActionTypes.successSnap, payload: null });
        }
    }

    public longTableRowEditing(event: DataRowItem) {
        if (!event) {
            return;
        }

        this.viewDataService.applyDataTableEditingChanges(event);
        const point = (this.editedDataArray || []).find(
            (item) => item.dateTime === event.datetime && item.id === event.entityId,
        );
        if (!point) {
            const entity = this.selectedEntities.find((e) => e.id === event.entityId);
            this.editedDataArray.push({
                id: event.entityId,
                dateTime: event.datetime,
                entity: entity.name,
                displayGroupId: entity.groupId,
                rawData: event.reading,
                whichPoint: {},
            });
        }
    }

    public onApprovalDateToggle(event: MatSlideToggleChange) {
        if (event.checked) {
            this.endDte = TODAY;
            this.startDte = this.prevApprStartDate ? new Date(this.prevApprStartDate) : null;
            this.datesDisabled = false;
        } else {
            this.startDte = null;
            this.endDte = null;
            this.datesDisabled = true;
        }
    }

    public approvalDateChange(event) {
        if (!event.value) return;

        const current = new Date(event.value).getTime();
        const startDate = new Date(this.viewDataFilter.startDate).getTime();

        if (current > startDate) return;

        const dialogData = {
            title: 'Confirm date',
            message: 'Selected date is outside of shown date range, are you sure to set this date?',
            okText: 'Confirm',
            cancelText: 'Cancel',
        };
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data: dialogData });
        dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (!result || result.whichButtonWasPressed === 'cancel') {
                    this.approvalDateControl.setValue(this.viewDataFilter.endDate);
                    return;
                }
            });
    }

    public mouseOutFromHydro() {
        this.viewDataService.highlightScatterPoint.next(null);
    }

    public onFlagPoints() {
        const series = this.dataEditService.newHydroData$.getValue();
        if (this.advanceScatterGraph && series) {
            const flagged = [];

            series.forEach((series) => {
                if (this.selectedSGEntityIds.includes(series.entityId)) {
                  flagged.push(...series.data.filter((v) => v.flagged));
                }
            });

            this.advanceScatterGraph.onHGflagPoints(flagged.map(v => v.x));
        }
    }

    public onDataSelectForSnap(points: { selected: number[]; snapped: number[] }, doComputeChart = true, notifyOtherWindows = true) {
        const series = this.dataEditService.newHydroData$.getValue();

        if (!series) {
            this.tempSelectedSnappedPoints = points;

            this.dataEditService.newHydroData$
                .pipe(
                    filter((v) => !!v),
                    first(),
                )
                .subscribe(() => {
                    this.onDataSelectForSnap(this.tempSelectedSnappedPoints);
                });

            return;
        }

        const updatedSeries: BasicSeriesData[] = [];

        for (const serie of series) {
            const ud: HGGraphData[] = [];
            // First reset all points to false
            if (
                serie.entityId !== DEPTH_ENTITY && (
                    (this.selectedSGEntityIds.includes(RAW_VELOCITY_ENTITY) && serie.entityId !== RAW_VELOCITY_ENTITY) ||
                    (this.selectedSGEntityIds.includes(VELOCITY_ENTITY) && serie.entityId !== VELOCITY_ENTITY))
                ) {
                continue;
            }
            for (let i = 0; i < serie.data.length; i++) {
                const wasChanged = serie.data[i].selected;
                serie.data[i].selected = false;
                serie.data[i].snapped = false;
                serie.data[i].index = i;

                if(wasChanged) ud.push(serie.data[i]);
            }

            // Next update affected points
            const updateCorrectPoints = (type: keyof typeof points, ud: HGGraphData[]) => {
                for (const xVal of points[type]) {
                    let start = 0;
                    let end = serie.data.length - 1;

                    while (start <= end) {
                        const mid = Math.floor((start + end) / 2);
                        const midPoint = serie.data[mid];

                        if (midPoint.x === xVal && type === 'selected') {
                            midPoint[type] = true;
                            ud.push(midPoint);
                            break;
                        } else if (midPoint.x === xVal && type === 'snapped' && (serie.entityId === VELOCITY_ENTITY || serie.entityId === RAW_VELOCITY_ENTITY)) {
                            midPoint[type] = true;
                            midPoint.correctedY = midPoint.y;
                            ud.push(midPoint);
                            break;
                        } else if (midPoint.x > xVal) {
                            end = mid - 1;
                        } else {
                            start = mid + 1;
                        }
                    }
                }
            };
            // tslint:disable-next-line: forin
            for (const type in points) {
                updateCorrectPoints(type as keyof typeof points, ud);
            }

            if(ud.length) {
                updatedSeries.push({... serie, data: ud});
            }
        }

        if (notifyOtherWindows && (points.selected.length || points.snapped.length)) {
            this.separateWindowHydrographService.dispatchAction({ type: SeparateWindowActionTypes.selectSnapPoints, payload: points });
        }
        this.dataEditService.newHydroData$.next([...series]);
    }

    public tracerOptionChanged(isStatic: boolean, notifyOtherWindow = true) {
        if (this.lcChart) {
            this.lcChart.tracerButtonClicked(isStatic);
        }

        this.usersService.staticTracerSetting.next(isStatic);

        if (notifyOtherWindow) {
            this.separateWindowHydrographService.dispatchAction<boolean>({
                type: SeparateWindowActionTypes.tracerPosition,
                payload: isStatic
            });
        }
    }
}
