export interface LocationAbstarct {
    locationName: string;
    id: number;
    isChecked: boolean;
    customerID: number;
    isActiveLocation: boolean;
    series?: string;
}

export interface LocationGroupSelectable {
    id: number;
    name?: string;
    groupId?: number;
    groupName?: string;
    isChecked?: boolean;
    isComposite?: boolean;
    locations?: { id?: number; name?: string; }[]
}

export class FlowMonitorExport {
    public cid: number;
    public lids: Array<number>;
    public locationGroupId: number;
    public entityIds: Array<number>;
    public startTime: string;
    public endTime: string;
    public includeUptime: boolean;
    public sections: Array<number>;
    public useSummarizeIntervalForDailyTable: boolean;
    public groupBy: number;
    public reportTemplate: number;
    public ScattergraphIsoQ = true;
    public ScattergraphFroude = false;
    public ScattergraphPipeOverlay = true;
    public SummarizeIntervalScattergraph = 2;
    public SummarizeIntervalHydrograph = 2;
    public SummarizeIntervalCommentary = 0;
    public SummarizeIntervalTabular = 0;
    constructor() {
        // includeUptime by default stays false in the API request model
        this.includeUptime = false;
    }
}

export const DTR_SAME_AVERAGING_AS_HYDROGRAPHS = 5;
export const DTR_NONE_AVERAGING = 4;
export const SCATTERGRAPH_SECTION = 3;

export enum FlowMonitorExportSectionTypes {
    siteCommentaries = 'Site Commentaries',
    hydrograph = 'Hydrograph Reports',
    scattergraph = 'Scattergraph Reports',
    tabular = 'Tabular Reports'
};

export const FLOW_MONITORING_EXPORT_SECTIONS = [
    { id: 0,sectionId:0, name: FlowMonitorExportSectionTypes.siteCommentaries, groupId: 0, groupName: 'Sections', isChecked: true },
    // { id: 1, name: 'Site Sheet', groupId: 0, groupName: 'Sections', isChecked: true }, deferred for now will be enabled in future
    { id: 2, sectionId:2, name: FlowMonitorExportSectionTypes.hydrograph, groupId: 0, groupName: 'Sections', isChecked: true },
    { id: 3, sectionId:3, name: FlowMonitorExportSectionTypes.scattergraph, groupId: 0, groupName: 'Sections', isChecked: true },
    { id: DTR_NONE_AVERAGING, name: FlowMonitorExportSectionTypes.tabular, groupId: 0, groupName: 'Sections', isChecked: true }, // Disabled until we fix data table
];

export const DATA_AVERAGING_COLLECTION = [
    { id: 0, title: 'None' },
    { id: 2, title: '15 Minutes' },
    { id: 3, title: 'Hourly' },
    { id: 4, title: 'Daily' },
];

export const GROUPING_SECTIONS = [
    { id: 0, title: 'Location' },
    { id: 1, title: 'Section Header' },
];

export const REPORT_TEMPLATE_COLLECTION = [
    { id: 1, title: 'Standard ADS Report Template' },
    { id: 0, title: 'Empty Word Document' },
];

export const EXPORT_TYPE_COLLECTION = [
    { name: 'Location', value: 'location' },
    { name: 'Location Group', value: 'locationGroup' },
];

export enum RainfallSearchTypeValue {
    location = 'location',
    locGroup = 'locationGroup'
}

export interface FlowMonitoringSelectable {
    id?: number;
    title?: string;
    name?: string;
    value?: string;
}

export enum REPORT_TYPE {
    LOCATION = 'location',
    LOCATIONGROUP = 'locationGroup',
}

export const CUSTOM_NOTIFICATION_TIMEOUT = 5000;
export const FMR_CREATE_TIMEOUT = 10000;
