import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewEncapsulation
} from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { TopnavComponent } from 'app/navigation/topnav/topnav.component';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { UsersService } from 'app/pages/admin/users.service';
import { EntityService } from 'app/shared/services/entity.service';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { AppQueryParams, customerQueryParam } from 'app/shared/models/customer';
import { TrackBy } from 'app/shared/utils/track-by';
import { FlowMonitoringService } from 'app/shared/services/flow-monitoring.service';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { DATA_AVERAGING_COLLECTION, DTR_SAME_AVERAGING_AS_HYDROGRAPHS, EXPORT_TYPE_COLLECTION, FLOW_MONITORING_EXPORT_SECTIONS, FlowMonitorExport, FlowMonitoringSelectable, GROUPING_SECTIONS, LocationAbstarct, LocationGroupSelectable, REPORT_TEMPLATE_COLLECTION, REPORT_TYPE, SCATTERGRAPH_SECTION } from '../../site-commentary-report/site-commentary.model';
import { SelectableGroup } from 'app/shared/models/selectable';
import { EntityResponseItem, QUICK_DATE_RANGES } from 'app/shared/models/view-data';
import { DFINAL_ENTITY, Q_FINAL_ENTITY, RAIN_ENTITY, RAIN_ENTITY_FULL, V_FINAL_ENTITY } from 'app/shared/constant';
import { LocationGroup } from 'app/shared/models/location-group';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { FlowMonitoringConfigSetting, FlowMonitoringReport } from 'app/shared/models/flow-monitoring-config';
import { LocType } from 'app/shared/components/location-card/location-card.constants';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';


@Component({
    selector: 'flow-monitoring-template-details',
    templateUrl: './flow-monitoring-details.component.html',
    styleUrls: ['./flow-monitoring-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [TopnavComponent],
})
export class FlowMonitoringDetailsComponent implements OnInit, OnDestroy {

    public defaultEntitesList: Array<number> = [DFINAL_ENTITY, V_FINAL_ENTITY, RAIN_ENTITY, Q_FINAL_ENTITY];
    public selectedEntitiesIds: Array<number> = this.defaultEntitesList;

    private subscriptions: Subscription[] = [];

    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
    public maxDate = new Date();

    public sections: Array<SelectableGroup> = FLOW_MONITORING_EXPORT_SECTIONS;
    public QUICK_DATE_RANGES = QUICK_DATE_RANGES;

    private routeCustomerId: number;

    public selectedSections: Array<SelectableGroup> = FLOW_MONITORING_EXPORT_SECTIONS;
    public initialLocationsList: Array<LocationAbstarct> = [];
    public entities: Array<SelectableGroup> = [];
    public invalidEntities: boolean;
    public locationGroupsUnavailable: boolean;
    public graphDataAveraging: Array<FlowMonitoringSelectable> = DATA_AVERAGING_COLLECTION.filter(v => v.id !== 0);
    public dataAveragingSelections: Array<FlowMonitoringSelectable> = DATA_AVERAGING_COLLECTION;
    public groupedBySelections: Array<FlowMonitoringSelectable> = GROUPING_SECTIONS;
    public locationsUnavailable: boolean;
    public selectedEntities: Array<SelectableGroup> = [];
    public flowMonitoringTemplate = new FlowMonitorExport();

    public showScattergraphFilters = true;
    public scattergraphIsoQ = true;
    public scattergraphFroude = false;
    public scattergraphPipeOverlay = true;
    public customerId: number;
    public shouldIncludeInactiveLocations: boolean;
    public locationTypeCollection: Array<FlowMonitoringSelectable> = EXPORT_TYPE_COLLECTION;
    public isLocationGroupInvalid: boolean;
    public locationGroupList: Array<LocationGroupSelectable> = [];
    public isLoadingState = false;
    public noLocationGroupsErrorMessage: string;
    public locationSearch = new FormControl();
    public dismissText: string;
    public noEntitiesErrorMessage: string;
    public fmrName: string;


    public trackById = TrackBy.byId;
    public trackByValue = TrackBy.byValue();

    public locations: Array<LocationAbstarct> = [];
    public selectAllLocations = false;
    public minimumLocationError: boolean;
    public searchModeEnabled: boolean;

    public locationType: string;
    public noLocationsErrorMessage: string;

    private saveTemplateSuccessText: string;

    public locType = LocType;

    constructor(
        private flowMonitoringService: FlowMonitoringService,
        private snackBarService: SnackBarNotificationService,
        private entityService: EntityService,
        private usersService: UsersService,
        private locationGroupService: LocationGroupService,
        private translate: TranslateService,
        public cdr: ChangeDetectorRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
    ) {
        this.locationSearch.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                switchMap((locationSearch) => this.filterLocations(locationSearch)),
            )
            .subscribe((res) => { });
    }

    public applyTranslations() {

         this.noLocationsErrorMessage = this.translate.instant('FLOW_MONITORING.NO_LOCATIONS_NOTIFICATION_MESSAGE');
         this.noLocationGroupsErrorMessage = this.translate.instant('FLOW_MONITORING.NO_LOCATION_GROUPS_NOTIFICATION_MESSAGE');
         this.noEntitiesErrorMessage = this.translate.instant('FLOW_MONITORING.NO_ENTITIES_NOTIFICATION_MESSAGE');
         this.dismissText = this.translate.instant('COMMON.DISMISS_TEXT');
         this.saveTemplateSuccessText = this.translate.instant('FLOW_MONITORING.FMR_SAVE_SUCCESS_TEXT');
    }

    public ngOnInit() {
        const routeSubs = this.activatedRoute.queryParamMap
            .subscribe((params: ParamMap) => {
                const customerId = Number(params.get(customerQueryParam));
                this.routeCustomerId = this.customerId = customerId;
                this.isLoadingState = false;
                this.applyTranslations();
                this.getLocations();
                this.getLocationGroups();
                this.initFlowMonitoringPage();
                this.resetFlowMonitoringPage();
            });

        this.subscriptions.push(routeSubs);
        this.locationGroupService.locationGroupDisabledStatus = true;
    }

    public navigateToDashboard(){
        const appQueryParams = <AppQueryParams>{
            [customerQueryParam]: this.customerId,
        };

        this.router.navigate(['/pages/report/flowMonitoringReport/flowMonitoringReport_V2'], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    public saveFlowMonitoringTemplate() {
        this.updateFlowMonitoringObject();

        const fmrTemplate: FlowMonitoringConfigSetting = {
            cid: this.customerId,
            lids: this.flowMonitoringTemplate.lids,
            locationGroupId: this.flowMonitoringTemplate.locationGroupId,
            entityIds: this.flowMonitoringTemplate.entityIds,
            startTime: this.flowMonitoringTemplate.startTime,
            endTime: this.flowMonitoringTemplate.endTime,
            includeUptime: this.flowMonitoringTemplate.includeUptime,
            sections: this.flowMonitoringTemplate.sections,
            groupBy: this.flowMonitoringTemplate.groupBy,
            scattergraphIsoQ: this.flowMonitoringTemplate.ScattergraphIsoQ,
            scattergraphFroude: this.flowMonitoringTemplate.ScattergraphFroude,
            scattergraphPipeOverlay: this.flowMonitoringTemplate.ScattergraphPipeOverlay,
            SummarizeIntervalScattergraph: this.flowMonitoringTemplate.SummarizeIntervalScattergraph,
            SummarizeIntervalHydrograph: this.flowMonitoringTemplate.SummarizeIntervalHydrograph,
            SummarizeIntervalCommentary: this.flowMonitoringTemplate.SummarizeIntervalCommentary,
            SummarizeIntervalTabular: this.flowMonitoringTemplate.SummarizeIntervalTabular,
            timeSpanNumeric: 0,
            reportTemplate: 0,
            includeInactiveLocations: false
        }

        const flowMonitoringObject: FlowMonitoringReport = {
            cid : this.customerId,
            name : this.fmrName,
            template: fmrTemplate
        }

        this.flowMonitoringService.saveFMRTemplate(flowMonitoringObject).subscribe((returnStatus: boolean) => {
            if(returnStatus)
            {
                this.navigateToDashboard();
                this.snackBar.open(this.saveTemplateSuccessText, this.dismissText, { duration: 10000 });
            }
        });

    }

    public handleSelectedSections() {
        this.selectedSections = this.sections.filter(v => v.isChecked);
        this.flowMonitoringTemplate.sections = this.selectedSections.map((section) => section.id);
        this.showScattergraphFilters = this.selectedSections.some((v) => v.id === SCATTERGRAPH_SECTION);
    }

    public handleGraphAveragingChange(event: MatSelectChange) {
        this.flowMonitoringTemplate.SummarizeIntervalHydrograph = event.value;
        this.flowMonitoringTemplate.SummarizeIntervalScattergraph = event.value;
    }

    private updateFlowMonitoringObject() {
        if (this.showScattergraphFilters) {
            this.flowMonitoringTemplate.ScattergraphFroude = this.scattergraphFroude;
            this.flowMonitoringTemplate.ScattergraphIsoQ = this.scattergraphIsoQ;
            this.flowMonitoringTemplate.ScattergraphPipeOverlay = this.scattergraphPipeOverlay;
        }
        this.flowMonitoringTemplate.cid = this.customerId;
        this.flowMonitoringTemplate.includeUptime = true;
        this.flowMonitoringTemplate.entityIds = this.selectedEntities.map((entity) => entity.id);
        this.flowMonitoringTemplate.sections = this.selectedSections.map((section) => section.id);

        this.flowMonitoringTemplate.useSummarizeIntervalForDailyTable = this.selectedSections
            .some((sec: SelectableGroup) => sec.id == DTR_SAME_AVERAGING_AS_HYDROGRAPHS);

        this.flowMonitoringTemplate.startTime = new Date(
            this.startDate.getTime() - this.startDate.getTimezoneOffset() * 60000,
        ).toISOString();
        this.flowMonitoringTemplate.endTime = new Date(
            this.endDate.getTime() - this.endDate.getTimezoneOffset() * 60000,
        ).toISOString();
    }

    public checkSelectedSection(sectionId: number) {
        return !(this.sections.find(v => v.id === sectionId).isChecked);
    }

    public selectedStartDate(date?: Date) {
        if (date) {
            this.startDate = date;
            this.flowMonitoringTemplate.startTime = new Date(
                this.startDate.getTime() - this.startDate.getTimezoneOffset() * 60000,
            ).toISOString();
        }
    }

    public handleSelectedEntities(entities: Array<SelectableGroup>) {
        this.invalidEntities = !(entities && entities.length);
        this.selectedEntities = [];
        this.selectedEntities = entities.filter((entity: SelectableGroup) => entity.isChecked);
        this.selectedEntitiesIds = this.selectedEntities.map((x) => x.id);
        this.flowMonitoringTemplate.entityIds = this.selectedEntities.map((entity) => entity.id);
    }

    public selectedEndDate(date?: Date) {
        if (date) {
            this.endDate = date;
            this.flowMonitoringTemplate.endTime = new Date(
                this.endDate.getTime() - this.endDate.getTimezoneOffset() * 60000,
            ).toISOString();
        }
    }

    public onLocationChange() {
        let locations = this.locations.filter((location) => location.isChecked);
        if (
            this.searchModeEnabled &&
            this.initialLocationsList.some((location) => location.isChecked) &&
            locations.length
        ) {
            const retainedLocations = this.initialLocationsList.filter(
                (location) => location.isChecked && !locations.includes(location),
            );
            locations = [...locations, ...retainedLocations];
        }
        if (!(locations && locations.length)) {
            this.minimumLocationError = true;
            this.resetEntitiesList();
            return;
        }
        this.minimumLocationError = false;
        this.flowMonitoringTemplate.lids = locations.map((location) => location.id);
        this.getSelectedEntities(locations);
    }

    public filterLocations(searchString: string) {
        this.searchModeEnabled = true;
        this.locations = this.initialLocationsList.filter((x) =>
            x.locationName.toLowerCase().includes(searchString.toLowerCase()),
        );
        if (searchString.trim() === '') {
            this.searchModeEnabled = false;
        }
        if (this.locations.filter((x) => x.isChecked).length === this.locations.length && this.locations.length !== 0) {
            this.selectAllLocations = true;
        } else {
            this.selectAllLocations = false;
        }
        return this.locations;
    }

    public getLocations() {
        this.loadLocationsSubscription().subscribe(() => {
            let locations = this.locations.filter((location) => location.isChecked);
            this.minimumLocationError = false;
            this.flowMonitoringTemplate.lids = locations.map((location) => location.id);
            if (locations.length > 0) {
                this.getSelectedEntities(locations);
            }

        });
    }

    public getLocationGroups() {
        this.isLoadingState = true;
        this.locationGroupList = [];
        const locationGroupSubscription = this.locationGroupService.getLocationGroups(this.customerId).subscribe(
            (locationGroupList) => {
                if (locationGroupList && locationGroupList.locationGroups && locationGroupList.locationGroups.length) {
                    this.isLoadingState = false;
                    this.locationGroupsUnavailable = false;

                    const formattedLocationGroups: LocationGroupSelectable[] = [];
                    locationGroupList.locationGroups.forEach((locationGroup: LocationGroup) => {
                        formattedLocationGroups.push({
                            id: locationGroup.locationGroupID,
                            name: locationGroup.name,
                            isChecked: false,
                        });
                    });

                    this.locationGroupList = formattedLocationGroups;
                } else {
                    this.locationGroupsUnavailable = true;
                    this.isLoadingState = false;
                }
            },
            (error) => {
                this.isLoadingState = false;
                this.snackBarService.raiseNotification(this.noLocationGroupsErrorMessage, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );
        this.subscriptions.push(locationGroupSubscription);
    }

    public getSelectedEntities(selectedLocations: Array<LocationAbstarct>) {
        this.isLoadingState = true;
        this.selectedEntities = [];
        const entitiesSubscription = this.entityService
            .getEntitiesByLocation(
                this.customerId,
                selectedLocations.map(({ id }) => id),
            )
            .subscribe(
                (entities: Array<EntityResponseItem>) => {
                    const entitiesSet = new Set(this.selectedEntitiesIds);
                    if (entities && entities.length) {
                        const filteredEntities = new Array<SelectableGroup>();
                        entities.forEach((entity: EntityResponseItem) => {
                            const isChecked = entitiesSet.has(entity.entityId);
                            filteredEntities.push({
                                name: entity.entityName,
                                id: entity.entityId,
                                groupName: entity.displayGroupName,
                                groupId: entity.displayGroup,
                                isChecked: isChecked,
                            });
                        });
                        this.entities =  filteredEntities;
                        this.selectedEntities = this.entities.filter((item) => item.isChecked);
                        this.isLoadingState = false;
                    }

                    const isRain = this.entities.some((e) => e.id === RAIN_ENTITY);
                    const isRainEntityChecked = entitiesSet.has(RAIN_ENTITY_FULL.entityId);
                    if (!isRain) {
                        for (const loc of selectedLocations) {
                            if (loc.series && loc.series.toUpperCase() === MONITOR_SERIES_TYPES.USGS) {
                                this.entities.push({
                                    name: RAIN_ENTITY_FULL.entityName,
                                    id: RAIN_ENTITY_FULL.entityId,
                                    groupName: RAIN_ENTITY_FULL.displayGroupName,
                                    groupId: RAIN_ENTITY_FULL.displayGroup,
                                    isChecked: isRainEntityChecked
                                })
                                break;
                            }
                        }
                    }
                },
                (error) => {
                    this.isLoadingState = false;
                    this.snackBarService.raiseNotification(this.noEntitiesErrorMessage, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                },
            );
        this.subscriptions.push(entitiesSubscription);
    }

    public resetFlowMonitoringPage() {
        this.flowMonitoringTemplate = new FlowMonitorExport();
        this.getLocations();
        this.getLocationGroups();
        this.initFlowMonitoringPage();
    }


    public initFlowMonitoringPage() {
        this.locationType = undefined;
        this.flowMonitoringTemplate.sections = [];

        this.sections
            .forEach((section) => {
                if(section.id !== DTR_SAME_AVERAGING_AS_HYDROGRAPHS)
                    section.isChecked = true;
            });
        this.resetEntitiesList();
        this.flowMonitoringTemplate.groupBy = 0;
    }

    private loadLocationsSubscription() {
        this.isLoadingState = true;
        const locationArgs = <LocationArgs>{
            customerId: this.routeCustomerId,
            IncludeInactiveLocations: this.shouldIncludeInactiveLocations,
        };
        return this.usersService.getLocationsList(locationArgs).pipe(
            tap(
                (locationList: Locations[]) => {
                    if (locationList && locationList.length) {
                        locationList = locationList.filter(x => x.viewable);
                        locationList = locationList.sort((a, b) =>
                            a.locationName && b.locationName
                                ? a.locationName.localeCompare(b.locationName)
                                : a.locationName
                                    ? 1
                                    : -1,
                        );

                        this.locationsUnavailable = false;

                        const locationsNew = [];
                        locationList.forEach((location: Locations) => {
                            locationsNew.push({
                                id: location.locationId,
                                locationName: location.locationName,
                                isChecked: this.locations.find(x => x.id === location.locationId && x.isChecked) ? true : false,
                                customerID: location.customerID,
                                series: location.series,
                                isActiveLocation:
                                    location.status === LocationStatus.Active ||
                                    location.status === LocationStatus.Maintenance,
                            });
                        });

                        this.locations = locationsNew;
                        this.initialLocationsList = this.locations;


                    } else {
                        this.locationsUnavailable = true;
                    }
                    this.locationType = REPORT_TYPE.LOCATION; // setting location as default on page load
                    this.handleLocationTypeChange(REPORT_TYPE.LOCATION);

                    this.isLoadingState = false;
                },
                (error) => {
                    this.isLoadingState = false;
                    this.snackBarService.raiseNotification(this.noLocationsErrorMessage, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                    this.locationsUnavailable = true;
                    this.locationType = REPORT_TYPE.LOCATION; // setting location as default on page load
                    this.handleLocationTypeChange(REPORT_TYPE.LOCATION);
                }
            ));
    }

    public handleLocationGroupInput(locationGroupInput: string) {
        if (!locationGroupInput && !this.flowMonitoringTemplate.locationGroupId) {
            return;
        } else if (!locationGroupInput && this.flowMonitoringTemplate.locationGroupId) {
            this.isLocationGroupInvalid = true;
            this.resetEntitiesList();
            return;
        }
        this.flowMonitoringTemplate.locationGroupId
            ? (this.isLocationGroupInvalid = !this.locationGroupList.some(
                  (locationGroup) => locationGroup.name === locationGroupInput,
              ))
            : (this.isLocationGroupInvalid = true);
        if (this.isLocationGroupInvalid) {
            this.resetEntitiesList();
        }
    }

    public handleSelectedLocationsGroup(selectedLocationGroup) {
        if (!selectedLocationGroup || !selectedLocationGroup.length || !selectedLocationGroup[0]) {
            this.resetEntitiesList();
            return;
        }
        this.isLocationGroupInvalid = false;
        if (this.flowMonitoringTemplate && this.flowMonitoringTemplate.locationGroupId !== selectedLocationGroup[0].id) {
            this.getSelectedEntities(selectedLocationGroup);
            this.flowMonitoringTemplate.locationGroupId = selectedLocationGroup[0].id;
        } else if (
            this.flowMonitoringTemplate &&
            this.flowMonitoringTemplate.locationGroupId === selectedLocationGroup[0].id
        ) {
            this.selectedEntities = this.entities.filter((entity) => entity.isChecked);
        }
    }

    public handleLocationTypeChange(locationTypeSelected: string) {
        this.isLoadingState = true;
        if (locationTypeSelected === REPORT_TYPE.LOCATION) {
            this.resetEntitiesList();
            this.flowMonitoringTemplate.locationGroupId = 0;
            this.isLocationGroupInvalid = false;
        } else if (locationTypeSelected === REPORT_TYPE.LOCATIONGROUP) {
            this.locationGroupList = this.locationGroupList.filter(x => true);

            this.toggleSelectAllLocations(false);
            this.flowMonitoringTemplate.lids = [];
            this.minimumLocationError = false;
        }
        this.invalidEntities = false;
        this.isLoadingState = false;
    }

    public toggleSelectAllLocations(isChecked: boolean) {
        this.locations.forEach((location) => (location.isChecked = isChecked));
        this.selectAllLocations = isChecked;
        if (this.selectAllLocations) {
            this.handleSelectAllValidation();
        } else if (!this.selectAllLocations && this.initialLocationsList.some((location) => location.isChecked)) {
            const retainSelectedLocations = this.initialLocationsList.filter((location) => location.isChecked);
            this.getSelectedEntities(retainSelectedLocations);
            this.minimumLocationError = false;
            this.flowMonitoringTemplate.lids = retainSelectedLocations.map((selectedLocation) => selectedLocation.id);
        } else {
            this.resetEntitiesList();
            this.minimumLocationError = true;
        }
    }

    public handleSelectAllValidation() {
        let selectedLocations = [];
        if (this.searchModeEnabled && this.flowMonitoringTemplate.lids.length) {
            selectedLocations = [
                ...this.locations,
                ...this.initialLocationsList.filter((location) => this.flowMonitoringTemplate.lids.includes(location.id)),
            ];
            this.flowMonitoringTemplate.lids = [...this.flowMonitoringTemplate.lids, ...this.locations.map((x) => x.id)];
        } else {
            this.flowMonitoringTemplate.lids = this.locations.map((x) => x.id);
        }
        this.getSelectedEntities(selectedLocations.length ? selectedLocations : this.locations);
        this.minimumLocationError = false;
    }


    public resetEntitiesList() {
        this.entities = [];
        this.selectedEntities = [];
        this.invalidEntities = false;
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.beforeDestroy();
    }

    private beforeDestroy() {
        this.locationGroupService.locationGroupDisabledStatus = false;
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
        this.subscriptions = [];
    }

}
