import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AssistanceCenterComponent } from 'app/shared/components/assistance-center/assistance-center.component';
import { AppComponent } from './app.component';
import { AppConfigOptions } from 'app/app.config.options';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AdalService } from 'app/shared/services/auth-adal.service';
import { WelcomeComponent } from './public/welcome/welcome.component';
import { SubmissionsComponent } from './pages/submissions/submissions.component';
import { HomeMapDashboardComponent } from './homemapdashboard/home-map-dashboard.component';
import { LocationLayerListComponent } from './homemapdashboard/location-layer-list/location-layer-list.component';
import { locationFeaturePropertyListComponent } from './homemapdashboard/location-feature-property-list/location-feature-property-list.component';
import { LocationStepsGPSComponent } from './homemapdashboard/location-steps-gps/location-steps-gps.component';
import { LocationGisQuickLookComponent } from './homemapdashboard/location-gis-quick-look/location-gis-quick-look.component';
import { BatteryStatusWidgetComponent } from './pages/battery-status-widget/battery-status-widget.component';
import { HydrographService } from './shared/services/hydrograph.service';
import { HydrographNEWService } from './shared/services/hydrographNEW.service';
import { HydrographDataEditingService } from './shared/services/hydrograph-data-editing.service';
import { CustomDashboardService } from './pages/dashboards/custom-dashboard/custom-dashboard.service';
import { LocationGroupEditorComponent } from './homemapdashboard/location-group-editor/location-group-editor.component';
import { CollectionWidgetScheduleComponent } from './pages/collection-widget-schedule/collection-widget-schedule.component';
import { AlarmWidgetComponent } from './pages/alarm-widget/alarm-widget.component';
import { AutoReviewWidgetComponent } from 'app/pages/auto-review-widget/auto-review-widget.component';
import { BlockagePredictionWidgetComponent } from 'app/pages/blockage-prediction-widget/blockage-prediction-widget.component';
import { UserPreferencesComponent } from 'app/pages/user-preferences/user-preferences.component';
import { CustomerEditorComponent } from 'app/pages/customer-editor/customer-editor.component';
import { EditCustomerEditorComponent } from 'app/pages/customer-editor/edit-customer-editor/edit-customer-editor.component';
import { AlarmGraphComponent } from 'app/pages/alarm-graph/alarm-graph.component';
import { UsersService } from 'app/pages/admin/users.service';
import { AdsMonitoringService } from 'app/shared/services/ads-monitoring.service';
import { SliicerMarketingScreenComponent } from './pages/sliicer/sliicer-marketing-screen/sliicer-marketing-screen.component';
import { SliicerService } from './shared/services/sliicer.service';
import { SliicerUtils } from './shared/utils/sliicer-utils';
import { DrawPipeHelper } from './shared/helpers/draw-pipe.helper';
import { TermsDialogComponent } from 'app/pages/terms-dialog';
import { DiagnosticService } from 'app/pages/view-data/view-data-location-details/location-diagnostics/location-diagnostics-service';
import { MainComponent, MainResolverService, AlarmNotificationComponent } from './main';
import { AddEditFlowBalanceComponent } from './pages/view-data/add-edit-flow-balance/add-edit-flow-balance.component';
import { DatePipe } from '@angular/common';
import { AboutUsComponent } from 'app/pages/about-us';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonInterceptor } from 'app/shared/interceptor/common-interceptor';
import { GenerateApiKeyComponent } from './pages/admin/generate-api-key/generate-api-key.component';
import { EditWetDryOverflowComponent } from './pages/customer-editor/edit-wet-dry-overflow/edit-wet-dry-overflow.component';
import { ApiKeyDashboardComponent } from './pages/admin/api-key-dashboard/api-key-dashboard.component';
import { ApiKeyService } from './shared/services/api-key.service';
import { PageHintComponent } from './page-hint/page-hint.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { CompositeLocComponent } from './shared/components/map/composite-loc/composite-loc.component';
import { DataCollectionComponent } from './pages/data-collection/data-collection.component';
import { CompositeLocationService } from './shared/services/composite-location.service';
import { ChangePasswordComponent } from './public/change-password/change-password.component';
import { CustomerDetailLocationsEditorComponent } from './pages/customer-editor/customer-detail-locations-editor/customer-detail-locations-editor.component';
import { CustomerDetailFeatureComponent } from 'app/pages/customer-editor/customer-detail-feature/customer-detail-feature.component';
import { CustomerDetailUserEditorComponent } from './pages/customer-editor/customer-detail-user-editor/customer-detail-user-editor.component';
import { LocationWetDryOverflowComponent } from './shared/components/location-wet-dry-overflow/location-wet-dry-overflow.component';
import { CustomerRainProfileComponent } from './pages/customer-editor/customer-rain-profile/customer-rain-profile.component';
import { CustomerDetailLocationService } from './shared/services/customer-detail-location.service';
import { TritonLocationService } from './shared/services/tritonLocation.service';
import { AdsPrismGISLayersComponent } from './pages/customer-editor/ads-prism-gis-layers/ads-prism-gis-layers.component';
import { AdsPrismGISAddEditComponent } from './pages/customer-editor/ads-prism-gis-add-edit/ads-prism-gis-add-edit.component';
import { AdsPrismFtpSweepComponent } from './pages/customer-editor/ads-prism-ftp-sweep/ads-prism-ftp-sweep.component';
import { AdsPrismFtpAddEditComponent } from './pages/customer-editor/ads-prism-ftp-add-edit/ads-prism-ftp-add-edit.component';
import {
    VaultService,
    VaultUploadDialogComponent,
    VaultUploadOverwriteComponent,
    VaultUploadDialogLocalComponent,
} from './pages/vault';
import { ModemSetupComponent } from './shared/components/map/modem-setup/modem-setup.component';
import { AddModemSetupComponent } from './shared/components/map/modem-setup/add-modem-setup/add-modem-setup.component';
import { UserIdleService } from 'angular-user-idle';

import { NotificationDashboardService } from './shared/services/notification.service';
import { AddLocationComponent } from './shared/components/map/add-location/add-location.component';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { OrderByPipe } from './shared/pipes/order-by-pipe';
import { ReplacePipe } from './shared/pipes/replace.pipe';
import { BPModeFormat } from './shared/pipes/bp-mode.pipe';
import { RoundPipe } from './shared/pipes/round.pipe';
import { AdsDatePipe } from './pages/sliicer/shared/pipes/user-date/user-date.pipe';
import { ErrorCountPipe } from './shared/pipes/error-count.pipe';
import { AdsCustomDatePipe } from './shared/pipes/custom-date.pipe';
import { OnlyCheckedOptionsPipe } from './shared/pipes/only-checked-options.pipe';
import { FilterByUnpickablePipe } from './shared/components/map/add-edit-location/filter-by-unpickable.pipe';
import { MarkerLocationDetailsComponent } from './shared/components/map/marker-location-details/marker-location-details.component';
import { DateService } from './shared/services/date.service';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/auth-guard.service';
import { LoginGuard } from './shared/services/login.guard';
import { CollectService } from './shared/services/collect.service';
import { CrowdCoreService } from './shared/services/crowd-core';
import { CustomerService } from './shared/services/customer.service';
import { LocationService } from './shared/services/location.service';
import { CalculatedEntitiesService } from './shared/services/calculated-entities.service';
import { SharedService } from './shared/services/shared.service';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';
import { SliicerDeactivateGuard } from './shared/services/sliicer-deactivate.guard';
import { MathService } from './shared/services/math.service';
import { UnitsService } from './shared/services/units.service';
import { EntityService } from './shared/services/entity.service';
import { AlarmService } from './shared/services/alarm.service';
import { ScheduleCollectionService } from './shared/services/schedule-collection.service';
import { BatteryStatusService } from './shared/services/battery-status.service';
import { LocationDashboardService } from './shared/services/location-dashboard.service';
import { DateutilService } from './shared/services/dateutil.service';
import { LocationGroupService } from './shared/services/location-group.service';
import { AutoScrubSummaryService } from './shared/services/auto-scrub-summary.service';
import { BlockagePredictionService } from './shared/services/blockage-prediction.service';
import { SearchService } from './shared/services/search.service';
import { AboutUsService } from './shared/services/about-us.service';
import { ViewDataService } from './shared/services/view-data.service';
import { MonitorSettingService } from './shared/services/monitor-setting.service';
import { MessageService } from './shared/services/message.service';
import { EmailService } from './shared/services/email.service';
import { DateFormat } from './shared/services/date-format.service';
import { NgModelChangeDebouncedDirective } from './shared/directive/ng-model-change-debounced.directive';
import { MultiSelectGroupComponent } from './shared/components/multi-select/multi-select-group/multi-select-group.component';
import { MultiSelectAutoCompleteComponent } from './shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';
import { MonitorSettingsComponent } from './shared/components/map/monitor-settings/monitor-settings.component';
import { MarkerCompositeLocDetailsComponent } from './shared/components/map/marker-composite-loc-details/marker-composite-loc-details.component';
import { LocationSelectionBarComponent } from './shared/components/location-selection-bar/location-selection-bar.component';
import { YesNoDialogComponent } from './shared/components/yesno-dialog.component';
import { BlockDataEditorComponent } from './pages/block-data-editor/block-data-editor.component';
import { CollectionWidgetComponent } from './pages/collection-widget/collection-widget.component';
import { ANSRAlarmComponent } from './pages/dashboards/notification-dashboard/ansr-alarm/ansr-alarm.component';
import { StandardAlarmComponent } from './pages/dashboards/notification-dashboard/standard-alarm/standard-alarm.component';
import { SiltEditorComponent } from './pages/silt-editor/silt-editor.component';
import { AdsPrismSingleSelectGroupComponent } from './shared/components/ads-prism-single-select-group/ads-prism-single-select-group.component';
import { AutoCompleteComponent } from './shared/components/auto-complete/auto-complete.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog.component';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { CustomizedConfirmationBoxComponent } from './shared/components/customized-confirmation-box/customized-confirmation-box.component';
import { DatePickerComponent } from './shared/components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './shared/components/date-range-picker/date-range-picker.component';
import { DdfGraphComponent } from './shared/components/ddf-graph/ddf-graph.component';
import { DiagnosticsMenuComponent } from './shared/components/diagnostics-menu/diagnostics-menu.component';
import { DownloadConfirmationsDialogComponent } from './shared/components/download-confirmations-dialog/download-confirmations-dialog.component';
import { EditAdminUserDialogComponent } from './shared/components/edit-admin-user-dialog/edit-admin-user-dialog.component';
import { EntitySelectorComponent } from './shared/components/entity-selector/entity-selector.component';
import { FileViewComponent } from './shared/components/file-view/file-view.component';
import { HydroGraphComponent } from './shared/components/hydro-graph/hydro-graph.component';
import { HydrographEditingMenuComponent } from './shared/components/hydrograph-editing-menu/hydrograph-editing-menu.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ClusterLocationsComponent } from './shared/components/map';
import { AddTritonLocationAvGatedSensorComponent } from './shared/components/map/add-edit-location/avGatedSensor/add-triton-location-avGated-sensor.component';
import { AddTritonLocationLongRangeDepthSensorComponent } from './shared/components/map/add-edit-location/longRangeDepthEchoSensor/add-triton-location-long-range-depth-sensor.component';
import { AddTritonLocationLongRangeDepthTritonSensorComponent } from './shared/components/map/add-edit-location/longRangeDepthTritonSensor/add-triton-location-long-range-depth-triton-sensor.component';
import { AddTritonLocationPeakComboSensorComponent } from './shared/components/map/add-edit-location/peakComboSensor/add-triton-location-peak-combo-sensor.component';
import { AddTritonLocationSmartDepthSensorComponent } from './shared/components/map/add-edit-location/smartDepthSensor/add-triton-location-smart-depth-sensor.component';
import { AddForeSITEFlexRadarSensorComponent } from './shared/components/map/add-edit-location/radarSensor/add-foreSITEFlex-radar-sensor.component';
import { AddTritonLocationSurfaceComboSensorComponent } from './shared/components/map/add-edit-location/surfaceComboSensor/add-triton-location-surface-combo-sensor.component';
import { PagingComponent } from './shared/components/paging/paging.component';
import { RecalculateEntitiesComponent } from './shared/components/recalculate-entities/recalculate-entities.component';
import { TimePickerComponent } from './shared/components/timepicker/timepicker.component';
import { VelocityGainTableComponent } from './shared/components/velocity-gain-table/velocity-gain-table.component';
import { WidgetFiltersComponent } from './shared/components/widget-filters/widget-filters.component';
import { AdsPopupWindowDirective } from './shared/directive/popup-window.directive';
import { DatetimepickerPositionDirective } from './shared/directive/date-picker-position.directive';
import { SortingDirective } from './shared/directive/sorting.directive';
import { ValidateFnDirective } from './shared/directive/validate-fn.directive';
import { CalloutComponent } from './shared/layout/callout/callout.component';
import { ChipComponent } from './shared/layout/chip/chip.component';
import { WidgetComponent } from './shared/layout/widget/widget.component';
import { SanitizePipe } from './shared/pipes/sanitize.pipe';
import { AdminCustomerComponent } from './pages/admin/admin-customer/admin-customer.component';
import { AddEditPlottingConfirmationComponent } from './pages/view-data/add-edit-plotting-confirmation/add-edit-plotting-confirmation.component';
import { AdsCustomDashboardComponent } from './pages/dashboards/custom-dashboard/custom-dashboard.component';
import { AdminUsersComponent } from './pages/admin/admin-users/admin-users.component';
import { AdsAdjustStormEventPeriodsComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/adjust-storm-event-periods/adjust-storm-event-periods.component';

import { AdsCustomDashboardModelComponent } from './pages/dashboards/custom-dashboard/custom-dashboard-model/custom-dashboard-model.component';
import { AdsDataEditingAuditReportComponent } from './pages/report/data-editing-audit-report/data-editing-audit-report.component';
import { AdsDetailsDialogComponent } from './pages/sliicer/sliicer-case-study-details/details-dialog/details-dialog.component';
import { AdsFlowMonitorHydroGraphComponent } from './pages/sliicer/shared/components/flow-monitor-hydrograph/flow-monitor-hydrograph.component';
import { AdsPrismAddScheduleComponent } from './pages/dashboards/schedule-collect-dashboard/ads-prism-add-schedule/ads-prism-add-schedule.component';
import { AdsPrismDataEditingTableComponent } from './pages/data-editing-table/data-editing-table.component';
import { AdsPrismVaultLocationComponent } from './pages/view-data/ads-prism-vault-location/ads-prism-vault-location.component';
import { AdsPrismVaultLocationExportComponent } from './pages/vault/vault-location-export/vault-location-export.component';
import { AdsPrismVaultTreeComponent } from './pages/vault/ads-prism-vault-tree/ads-prism-vault-tree.component';
import { AdsStormEventsComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/storm-events.component';
import { AdvanceScattergraphComponent } from './pages/view-data/graphs/advance-scattergraph/advance-scattergraph.component';
import { AdvanceScattergraphContainerComponent } from './pages/view-data/graphs/advance-scattergraph-container/advance-scattergraph-container.component';
import { AnchorDirective } from './pages/sliicer/shared/directives/anchor/anchor.directive';
import { ApproveDataDialogComponent } from './pages/view-data/graphs/approve-data-dialog/approve-data-dialog.component';
import { AsLeftPipe } from './pages/sliicer/shared/pipes/is-left.pipe';
import { EntityDayValuePipe } from './pages/report/uptime-report/uptime-overview/uptime-day-value.pipe';
import { AsSomePipe } from './pages/sliicer/shared/pipes/option.pipe';
import { AuditComponent } from './pages/audit/audit.component';
import { NavigationService } from './navigation/navigation.service';
import { BasinDefinitionComponent } from './pages/sliicer/sliicer-case-study-details/basin-definition/basin-definition.component';
import { BasinQviStatsComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/basin-qvi-stats/basin-qvi-stats.component';
import { CalculatedEntityComponent } from './pages/view-data/calculated-entity/calculated-entity.component';
import { ChartMouseDragDirective } from './pages/sliicer/shared/directives/chart-mouse-drag/chart-mouse-drag.directive';
import { ClickOutsideDirective } from './shared/directive/click-outside.directive';
import { NavItemComponent } from './navigation/nav-item/nav-item.component';
import { NotificationDashboardComponent } from './pages/dashboards/notification-dashboard/notification-dashboard.component';
import { NotificationTreeComponent } from './shared/components/notification-tree/notification-tree.component';
import { PercentFullComponent } from './pages/view-data/graphs/percent-full/percent-full.component';
import { PercentFullDetailsComponent } from './pages/report/percent-full-report/percent-full-details/percent-full-details.component';
import { PercentFullOverviewComponent } from './pages/report/percent-full-report/percent-full-overview/percent-full-overview.component';
import { PrecompensationSettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/precompensation-settings/precompensation-settings.component';
import { ProgressBarsComponent } from './pages/sliicer/shared/components/progress-bars/progress-bars.component';
import { QIGraphComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/q-i-graph/q-i-graph.component';
import { RainfallBlockDaysComponent } from './pages/sliicer/sliicer-case-study-details/rainfall-monitor/rainfall-hytograph/rainfall-block-days/rainfall-block-days.component';
import { RainfallDdfComponent } from './pages/sliicer/sliicer-case-study-details/rainfall-monitor/rainfall-ddf/rainfall-ddf.component';
import { RainfallGraphComponent } from './pages/sliicer/sliicer-case-study-details/rainfall-monitor/rainfall-hytograph/rainfall-graph/rainfall-graph.component';
import { RainfallHytographComponent } from './pages/sliicer/sliicer-case-study-details/rainfall-monitor/rainfall-hytograph/rainfall-hytograph.component';
import { RainTableComponent } from './pages/sliicer/sliicer-case-study-details/rainfall-monitor/rain-table/rain-table.component';
import { RegimeSettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/regime-settings/regime-settings.component';
import { RollbackEditorComponent } from './pages/view-data/rollback-editor-dialog/rollback-editor-dialog.component';
import { ScattergraphComponent } from './pages/view-data/graphs/scattergraph/scattergraph.component';
import { ScatterGraphEditMenuComponent } from './pages/view-data/graphs/scattergraph-edit-menu/scattergraph-edit-menu.component';
import { ScheduleCollectOverviewComponent } from './pages/dashboards/schedule-collect-dashboard/schedule-collect-dashboard-overview/schedule-collect-dashboard-overview.component';
import { SeasonsSettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/seasons-settings/seasons-settings.component';
import { SeasonsTimelineComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/seasons-settings/seasons-timeline/seasons-timeline.component';
import { SiteCommentaryReportComponent } from './pages/report/site-commentary-report/site-commentary-report.component';
import { SliicerCaseStudyConfigComponent } from './pages/sliicer/sliicer-case-study-details/config/config.component';
import { SliicerCaseStudyDetailsComponent } from './pages/sliicer/sliicer-case-study-details/case-study-details.component';
import { SliicerCaseStudyEditConfigComponent } from './pages/sliicer/sliicer-case-study-details/edit-config/edit-config.component';
import { SliicerRainfallMonitorComponent } from './pages/sliicer/sliicer-case-study-details/rainfall-monitor/rainfall-monitor.component';
import { SliicerCaseStudyWizardComponent } from './pages/sliicer/sliicer-case-study-details/wizard/wizard.component';
import { SliicerDashboardComponent } from './pages/sliicer/sliicer-dashboard/sliicer-dashboard.component';
import { SliicerImportFileDialogComponent } from './pages/sliicer/sliicer-case-study-details/sliicer-import-file-dialog/sliicer-import-file-dialog.component';
import { SliicerImportVaultStudyComponent } from './pages/sliicer/sliicer-import-vault-study/sliicer-import-vault-study.component';
import { SliicerStormChooserComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/storm-chooser/storm-chooser.component';
import { SliicerStormStatsComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/storm-stats/storm-stats.component';
import { SliicerStudyDailogComponent } from './pages/sliicer/sliicer-study-dialog-component/sliicer-study-dialog.component';
import { SliicerStudyDashboardComponent } from './pages/sliicer/sliicer-study-dashboard/sliicer-study-dashboard.component';
import { StormDecompositionHydrographComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-decomposition-hydrograph/storm-decomposition-hydrograph.component';
import { StormSettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/storm-settings/storm-settings.component';
import { StormSettingsDialogComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/storm-settings-dialog/storm-settings-dialog.component';
import { StudySettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/study-settings.component';
import { TelemetryImportComponent } from './pages/vault/vault-telemetry/vault-telemetry-import/vault-telemetry-import.component';
import { TopnavComponent } from './navigation/topnav/topnav.component';
import { UpdatesWidgetComponent } from './pages/sliicer/shared/components/updates-widget/updates-widget.component';
import { UptimeComponent } from './pages/view-data/graphs/uptime/uptime.component';
import { UptimeDetailsComponent } from './pages/report/uptime-report/uptime-details/uptime-details.component';
import { UptimeOverviewComponent } from './pages/report/uptime-report/uptime-overview/uptime-overview.component';
import { UserIdleComponent } from './navigation/user-Idle/user-idle.component';
import { VaultCreateFolderComponent } from './pages/vault/vault-create-folder/vault-create-folder.component';
import { VaultDashboardComponent } from './pages/vault/vault-dashboard/vault-dashboard.component';
import { VaultFilesDeleteActionComponent } from './pages/vault/vault-files-delete-action/vault-files-delete-action.component';
import { VaultFilesMoveActionComponent } from './pages/vault/vault-files-move-action/vault-files-move-action.component';
import { VaultImportFileSummaryComponent } from './pages/vault/vault-telemetry/vault-import-file-summary/vault-import-file-summary.component';
import { VaultItemShareComponent } from './pages/vault/vault-item-share/vault-item-share.component';
import { VaultNavComponent } from './pages/vault/vault-nav/vault-nav.component';
import { VaultNavItemComponent } from './pages/vault/vault-nav-item/vault-nav-item.component';
import { VaultTelemetryExportComponent } from './pages/vault/vault-telemetry/vault-telemetry-export/vault-telemetry-export.component';
import { VaultTelemetryExportModalComponent } from './pages/vault/vault-telemetry/vault-telemetry-export-modal/vault-telemetry-export-modal.component';
import { VaultUploadLocationDialogComponent } from './pages/vault/vault-upload-location-dialog/vault-upload-location-dialog.component';
import { ViewDataFilterComponent } from './pages/view-data/view-data-filter/view-data-filter.component';
import { ViewDataLocationDetailsComponent } from './pages/view-data/view-data-location-details/view-data-location-details.component';
import { WasteWaterSettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/waste-water-settings/waste-water-settings.component';
import { WeekGroupsSettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/week-groups-settings/week-groups-settings.component';
import { CustomerFeatureComponent } from './pages/admin/customer-feature/customer-feature.component';
import { CustomerUserAssociationComponent } from './pages/admin/customer-user-association/customer-user-association.component';
import { CustomTimePipe } from './shared/pipes/time-minutes-hrs.pipe';
import { SecondsToSampleRatePipe } from './shared/pipes/seconds-to-sample-rate.pipe';
import { DailySummaryComponent } from './pages/view-data/graphs/daily-summary/daily-summary.component';
import { DailySummaryDetailsComponent } from './pages/report/daily-summary-report/daily-summary-details/daily-summary-details.component';
import { DailySummaryOverviewComponent } from './pages/report/daily-summary-report/daily-summary-overview/daily-summary-overview.component';
import { DataDeleteComponent } from './pages/view-data/graphs/data-delete/data-delete.component';
import { DataEditingComponent } from './pages/view-data/graphs/data-editing/data-editing.component';
import { DebounceDirective } from './pages/sliicer/shared/directives/debounce/debounce.directive';
import { DesignStormsDialogComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/design-storms-dialog/design-storms-dialog.component';
import { DiurnalGraphComponent } from './pages/sliicer/shared/components/diurnal-graph/diurnal-graph.component';
import { DryDayDiurnalGraphComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/dry-days/dry-day-diurnal-graph/dry-day-diurnal-graph.component';
import { DryDaysComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/dry-days/dry-days.component';
import { DryDaySettingsComponent } from './pages/sliicer/sliicer-case-study-details/study-settings/dry-day-settings/dry-day-settings.component';
import { DryDaysStatsComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/dry-days/dry-day-stats/dry-days-stats.component';
import { EditStormEventsComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/edit-storm-events/edit-storm-events.component';
import { EitherValidationDirective } from './pages/sliicer/shared/directives/either-validation.directive';
import { ErrorReportComponent } from './pages/admin/error-report/error-report.component';
import { ExportStormComponent } from './pages/sliicer/sliicer-case-study-details/study-results/export-storm/export-storm.component';
import { FileExtensionPipe } from './shared/pipes/file-extension.pipe';
import { CacheService } from './shared/services/cache.service';
import { FlowBalanceReportComponent } from './pages/report/flow-balance-report/flow-balance-report.component';
import { FlowloadFileUploadComponent } from './pages/sliicer/sliicer-case-study-details/flowload-file-upload/flowload-file-upload.component';
import { MaxReturnComponent } from './pages/sliicer/sliicer-case-study-details/rainfall-monitor/max-return/max-return.component';
import { FlowMonitorChangesDialogComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/flow-monitor-changes-dialog/flow-monitor-changes-dialog.component';
import { FlowMonitorComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/flow-monitor.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { FormatNumberPipe } from './pages/sliicer/shared/pipes/format-number/format-number.pipe';
import { GraphComponent } from './pages/sliicer/shared/components/graph/graph.component';
import { LocationDashboardComponent } from './pages/dashboards/location-dashboard/location-dashboard.component';
import { InlineEditComponent } from './pages/view-data/view-data-filter/inline-edit/inline-edit.component';
import { IsLeftPipe } from './pages/sliicer/shared/pipes/is-left.pipe';
import { IsNonePipe, IsNullPipe, IsSomePipe } from './pages/sliicer/shared/pipes/option.pipe';
import { LoadSettingComponent } from './pages/vault/load-setting/load-setting.component';
import { LocationDiagnosticsComponent } from './pages/view-data/view-data-location-details/location-diagnostics/location-diagnostics.component';
import { LocationDiagnosticsResultsComponent } from './pages/view-data/view-data-location-details/location-diagnostics/location-diagnostics-results/location-diagnostics-results.component';
import { LocationFlowBalanceReportComponent } from './pages/report/location-flow-balance-report/location-flow-balance-report.component';
import { AddTritonLocationService } from './shared/components/map/add-edit-location/add-triton-location-service';
import { AuditService } from './shared/services/audit.service';
import { Config } from './shared/services/config';
import { ConfirmationService } from './shared/services/confirmation.service';
import { DataEditService } from './shared/services/data-edit.service';
import { DynamicFilterWidgetService } from './shared/services/dynamic-filter-widget.service';
import { FileService } from './shared/services/file.service';
import { FilterWidgetService } from './shared/services/filter-widget-service';
import { FlowMonitoringService } from './shared/services/flow-monitoring.service';
import { LocationFormBuilder } from './shared/components/location-card/services/location-form-builder';
import { GISService } from './shared/services/gis-service';
import { MapService } from './shared/services/map.service';
import { TritonBasicInfoComponent } from './shared/components/location-card/components/triton-basic-info/triton-basic-info.component';
import { MonitorDiagnosticsService } from './shared/services/monitor-diagnostics.service';
import { PreLoaderService } from './shared/services/pre-loader.service';
import { ScatterGraphBuilder } from './pages/view-data/graphs/advance-scattergraph/scattergraph-builder.service';
import { SnackBarNotificationService } from './shared/services/snack-bar-notification.service';
import { TelemetryService } from './shared/services/telemetry.service';
import { TelogService } from './shared/services/telog.service';
import { UiService } from './shared/services/ui.service';
import { VaultTelemetryExportResolverService } from './pages/vault/vault-telemetry/vault-telemetry-export/vault-telemetry-export-resolver.service';
import { DialogActionsComponent } from './shared/components/location-card/components/dialog-actions/dialog-actions.component';
import { DailySummaryReportService } from './pages/report/daily-summary-report';
import { PercentFullReportService } from './pages/report/percent-full-report';
import { UptimeReportService } from './pages/report/uptime-report';
import { DailyRainfallReportService } from './pages/report/daily-rainfall-report/daily-rainfall-report.service';
import { DailySummaryExportComponent } from './pages/report/daily-summary-report/daily-summary-export/daily-summary-export.component';
import { QstartDataService } from './pages/qstart/services/qstart.service';
import { TritonLocationDialogComponent } from './shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { TritonInfoComponent } from './shared/components/location-card/components/triton-info/triton-info.component';
import { EchoInfoComponent } from './shared/components/location-card/components/echo-info/echo-info.component';
import { foreSITEFlexInfoComponent } from './shared/components/location-card/components/foreSITEFlex-info/foreSITEFlex-info.component';
import { RainalertInfoComponent } from './shared/components/location-card/components/rainalert-info/rainalert-info.component';
import { RainAlarmInfoComponent } from './shared/components/location-card/components/rain-alarm-info/rain-alarm-info.component';
import { RainSensorComponent } from './shared/components/location-card/components/rain-sensor/rain-sensor.component';
import { OtherMonitorsInfoComponent } from './shared/components/location-card/components/other-monitors-info/other-monitors-info.component';
import { TritonConfigureAlarmsComponent } from './shared/components/location-card/components/triton-configure-alarms/triton-configure-alarms.component';
import { Mp2BasicInfoComponent } from './shared/components/location-card/components/mp2-basic-info/mp2-basic-info.component';
import { SamplerInfoComponent } from './shared/components/location-card/components/sampler-info/sampler-info.component';
import { TritonDataDeliveryComponent } from './shared/components/location-card/components/triton-data-delivery/triton-data-delivery.component';
import { FlowFormComponent } from './shared/components/location-card/components/flow-form/flow-form.component';
import { InstallationTypeInfoComponent } from './shared/components/location-card/components/installation-type-info/installation-type-info.component';
import { AddUnitsPipe } from './shared/pipes/add-measurement-units.pipe';
import { NumDisplayPipe } from './shared/pipes/num-display.pipe';
import { LocationCardService } from './shared/components/location-card/services/location-card.service';
import { UptimeFiltersComponent } from './pages/report/uptime-report/update-filters/uptime-filters.component';
import { SaveCurveDialogComponent } from './shared/components/save-curve-dialog/save-curve-dialog.component';
import { SiteNamePopoverComponent } from './shared/components/site-name-popover/site-name-popover.component';
import { DailyReportNotificationsComponent } from './pages/dashboards/notification-dashboard/daily-report-notifications/daily-report-notifications.component';
import { CurveOptionsDialogComponent } from './pages/view-data/graphs/curve-options-dialog/curve-options-dialog.component';
import { FmrSaveConfigurationDialogComponent } from './pages/report/site-commentary-report/fmr-save-configuration-dialog/fmr-save-configuration-dialog.component';
import { FmrOpenConfigurationDialogComponent } from './pages/report/site-commentary-report/fmr-open-configuration-dialog/fmr-open-configuration-dialog.component';
import { RainFallFrequencyOverviewComponent } from './pages/report/rain-fall-frequency-report/rain-fall-frequency-overview/rain-fall-frequency-overview.component';
import { ConfigurationTableComponent } from './shared/components/configuration-table/configuration-table.component';
import { ConfigurationSaveDialogComponent } from './shared/components/configuration-save-dialog/configuration-save-dialog.component';
import { EventWidgetComponent } from './pages/event-widget/event-widget.component';
import { CumulativeRainWidgetComponent } from './pages/cumulative-rain-widget/cumulative-rain-widget.component'
import { AddEventDialogComponent } from './pages/add-event-dialog/add-event-dialog.component';
import { EventFiltersComponent } from './shared/components/event-filters/event-filters.component';
import { DurationPickerComponent } from './shared/components/duration-picker/duration-picker.component';
import { MinMaxAvgTotalTableComponent } from './pages/dashboards/location-dashboard/min-max-avg-total-table/min-max-avg-total-table.component';
import { OtherLocationsGraphingDialogComponent } from './shared/components/other-locations-graphing-dialog/other-locations-graphing-dialog.component';
import { LocationNotesDialogComponent } from './pages/view-data/location-notes-dialog/location-notes-dialog.component';
import { QvsIGroupDialogComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/q-vs-i-group-dialog/q-vs-i-group-dialog.component';
import { LocationNotificationConfigurationComponent } from './pages/report/location-notification-configuration/location-notification-configuration.component';
import { ExpandedStormChooserComponent } from './pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/expanded-storm-chooser/expanded-storm-chooser.component';
import { LocationConfigurationReportComponent } from './pages/report/location-configuration-report/location-configuration-report.component';
import { DailyRainfallReportComponent } from './pages/report/daily-rainfall-report/daily-rainfall-report.component';
import { AnnouncementComponent } from './pages/admin/alert/Alert.component';
import { AnnouncementService } from './shared/services/announcement.service';
import { StormsPivotTableComponent } from './pages/sliicer/sliicer-case-study-details/study-results/storms-pivot-table/storms-pivot-table.component';
import { BasinQvsiPivotTableComponent } from './pages/sliicer/sliicer-case-study-details/study-results/basin-qvsi-pivot-table/basin-qvsi-pivot-table.component';
import { DryDayHydrographComponent } from './pages/sliicer/sliicer-case-study-details/study-results/dry-day-hydrograph-pivot/dry-day-hydrograph-pivot.component';
import { DryDayPivotTableComponent } from './pages/sliicer/sliicer-case-study-details/study-results/dry-day-pivot-table/dry-day-pivot-table.component';
import { LightningChartBuilder } from './shared/components/hydrograph/lightning-chart-builder/lightning-chart-builder';
import { LightningChartSeriesFactory } from './shared/components/hydrograph/lightning-chart-builder/lightning-chart-series-factory'
import { LightningChartObject } from './shared/components/hydrograph/lightning-chart-object/lightning-chart-object';
import { HydrographTracerPanelComponent } from './shared/components/hydrograph/hydrograph-tracer-panel/hydrograph-tracer-panel.component';
import { HydrographCustomRangesComponent } from './shared/components/hydrograph/hydrograph-custom-ranges/hydrograph-custom-ranges.component';
import { HydrographLegendComponent } from './shared/components/hydrograph/hydrograph-legend/hydrograph-legend.component';
import { AdminBillingMetricsComponent } from './pages/admin/admin-usage/admin-billing-metrics/admin-billing-metrics.component';
import { AdminCostAllocationComponent } from './pages/admin/admin-usage/admin-cost-allocation/admin-cost-allocation.component';
import { BillingReportsService } from './shared/services/billing-reports.service';
import { BPDailySummaryComponent } from './pages/blockage-predict/bp-daily-summary/bp-daily-summary.component';
import { appImports } from './app.imports';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';

export function highchartsFactory() {
    return require('highcharts/modules/stock');
}

const defaultOptions: AppConfigOptions = {
    appTitle: 'ADS PRISM',
};

const DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


@NgModule({
    declarations: [
        AboutUsComponent,
        AddEditFlowBalanceComponent,
        AddEditPlottingConfirmationComponent,
        AddLocationComponent,
        AddModemSetupComponent,
        AddTritonLocationAvGatedSensorComponent,
        AddTritonLocationLongRangeDepthSensorComponent,
        AddTritonLocationLongRangeDepthTritonSensorComponent,
        AddTritonLocationPeakComboSensorComponent,
        AddTritonLocationSmartDepthSensorComponent,
        AddForeSITEFlexRadarSensorComponent,
        AddTritonLocationSurfaceComboSensorComponent,
        AdminCustomerComponent,
        AdminUsersComponent,
        AdsAdjustStormEventPeriodsComponent,
        AdsCustomDashboardComponent,
        AdsCustomDashboardModelComponent,
        AdsCustomDatePipe,
        AdsDataEditingAuditReportComponent,
        AdsDatePipe,
        AdsDetailsDialogComponent,
        AdsFlowMonitorHydroGraphComponent,
        AdsPopupWindowDirective,
        DatetimepickerPositionDirective,
        AdsPrismAddScheduleComponent,
        AdsPrismDataEditingTableComponent,
        AdsPrismFtpAddEditComponent,
        AdsPrismFtpSweepComponent,
        AdsPrismGISAddEditComponent,
        AdsPrismGISLayersComponent,
        AdsPrismSingleSelectGroupComponent,
        AdsPrismVaultLocationComponent,
        AdsPrismVaultLocationExportComponent,
        AdsPrismVaultTreeComponent,
        AdsStormEventsComponent,
        AdvanceScattergraphComponent,
        AdvanceScattergraphContainerComponent,
        AlarmGraphComponent,
        AlarmNotificationComponent,
        AlarmWidgetComponent,
        AnchorDirective,
        AnnouncementComponent,
        ANSRAlarmComponent,
        ApiKeyDashboardComponent,
        AppComponent,
        ApproveDataDialogComponent,
        AsLeftPipe,
        AssistanceCenterComponent,
        AsSomePipe,
        AuditComponent,
        AutoCompleteComponent,
        AutoReviewWidgetComponent,
        BasinDefinitionComponent,
        BasinQviStatsComponent,
        BatteryStatusWidgetComponent,
        BlockagePredictionWidgetComponent,
        BlockDataEditorComponent,
        CalculatedEntityComponent,
        CalloutComponent,
        ChangePasswordComponent,
        ChartMouseDragDirective,
        ChipComponent,
        ClickOutsideDirective,
        ClusterLocationsComponent,
        CollectionWidgetComponent,
        CollectionWidgetScheduleComponent,
        CompositeLocComponent,
        ConfirmationDialogComponent,
        ConfirmDialogComponent,
        ContactUsComponent,
        CustomerDetailFeatureComponent,
        CustomerDetailLocationsEditorComponent,
        CustomerDetailUserEditorComponent,
        CustomerEditorComponent,
        CustomerFeatureComponent,
        CustomerRainProfileComponent,
        CustomerUserAssociationComponent,
        CustomizedConfirmationBoxComponent,
        CustomTimePipe,
        SecondsToSampleRatePipe,
        DailyReportNotificationsComponent,
        DailySummaryComponent,
        DailySummaryDetailsComponent,
        BPDailySummaryComponent,
        DailySummaryExportComponent,
        DailySummaryOverviewComponent,
        DataCollectionComponent,
        DataDeleteComponent,
        DataEditingComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DdfGraphComponent,
        DebounceDirective,
        DesignStormsDialogComponent,
        DiagnosticsMenuComponent,
        DialogActionsComponent,
        DiurnalGraphComponent,
        DownloadConfirmationsDialogComponent,
        DryDayDiurnalGraphComponent,
        DryDaysComponent,
        DryDaySettingsComponent,
        DryDaysStatsComponent,
        EditAdminUserDialogComponent,
        EditCustomerEditorComponent,
        EditStormEventsComponent,
        EditWetDryOverflowComponent,
        EitherValidationDirective,
        EntityDayValuePipe,
        AddUnitsPipe,
        EntitySelectorComponent,
        ErrorCountPipe,
        ErrorReportComponent,
        ExportStormComponent,
        FileExtensionPipe,
        FileViewComponent,
        FilterByUnpickablePipe,
        FlowBalanceReportComponent,
        FlowloadFileUploadComponent,
        MaxReturnComponent,
        FlowMonitorChangesDialogComponent,
        FlowMonitorComponent,
        FooterComponent,
        FormatNumberPipe,
        GenerateApiKeyComponent,
        GraphComponent,
        LocationDashboardComponent,
        HydroGraphComponent,
        HydrographEditingMenuComponent,
        InlineEditComponent,
        IsLeftPipe,
        IsNonePipe,
        IsNullPipe,
        IsSomePipe,
        NumDisplayPipe,
        LoaderComponent,
        LoadSettingComponent,
        HomeMapDashboardComponent,
        LocationDiagnosticsComponent,
        LocationDiagnosticsResultsComponent,
        locationFeaturePropertyListComponent,
        LocationFlowBalanceReportComponent,
        LocationGroupEditorComponent,
        LocationLayerListComponent,
        LocationSelectionBarComponent,
        LocationStepsGPSComponent,
        LocationGisQuickLookComponent,
        LocationWetDryOverflowComponent,
        MainComponent,
        MarkerCompositeLocDetailsComponent,
        MarkerLocationDetailsComponent,
        ModemSetupComponent,
        MonitorSettingsComponent,
        MultiSelectAutoCompleteComponent,
        MultiSelectGroupComponent,
        NavItemComponent,
        NgModelChangeDebouncedDirective,
        NotificationDashboardComponent,
        NotificationTreeComponent,
        OnlyCheckedOptionsPipe,
        OrderByPipe,
        PageHintComponent,
        PagingComponent,
        PercentFullComponent,
        PercentFullDetailsComponent,
        PercentFullOverviewComponent,
        PrecompensationSettingsComponent,
        ProgressBarsComponent,
        QIGraphComponent,
        RainfallBlockDaysComponent,
        RainfallDdfComponent,
        RainfallGraphComponent,
        RainfallHytographComponent,
        RainTableComponent,
        RecalculateEntitiesComponent,
        RegimeSettingsComponent,
        ReplacePipe,
        BPModeFormat,
        RollbackEditorComponent,
        RoundPipe,
        SanitizePipe,
        ScattergraphComponent,
        ScatterGraphEditMenuComponent,
        ScheduleCollectOverviewComponent,
        SeasonsSettingsComponent,
        SeasonsTimelineComponent,
        SiltEditorComponent,
        SiteCommentaryReportComponent,
        SliicerCaseStudyConfigComponent,
        SliicerCaseStudyDetailsComponent,
        SliicerRainfallMonitorComponent,
        SliicerCaseStudyEditConfigComponent,
        SliicerCaseStudyWizardComponent,
        SliicerDashboardComponent,
        SliicerImportFileDialogComponent,
        SliicerImportVaultStudyComponent,
        SliicerMarketingScreenComponent,
        SliicerStormChooserComponent,
        SliicerStormStatsComponent,
        SliicerStudyDailogComponent,
        SliicerStudyDashboardComponent,
        SortingDirective,
        ValidateFnDirective,
        StandardAlarmComponent,
        StormDecompositionHydrographComponent,
        StormSettingsComponent,
        StormSettingsDialogComponent,
        StudySettingsComponent,
        SubmissionsComponent,
        TelemetryImportComponent,
        TermsDialogComponent,
        TimePickerComponent,
        TritonBasicInfoComponent,
        TritonLocationDialogComponent,
        TopnavComponent,
        UpdatesWidgetComponent,
        UptimeComponent,
        UptimeDetailsComponent,
        UptimeOverviewComponent,
        UserIdleComponent,
        UserPreferencesComponent,
        UptimeFiltersComponent,
        VaultCreateFolderComponent,
        VaultDashboardComponent,
        VaultFilesDeleteActionComponent,
        VaultFilesMoveActionComponent,
        VaultImportFileSummaryComponent,
        VaultItemShareComponent,
        VaultNavComponent,
        VaultNavItemComponent,
        VaultTelemetryExportComponent,
        VaultTelemetryExportModalComponent,
        VaultUploadDialogComponent,
        VaultUploadDialogLocalComponent,
        VaultUploadLocationDialogComponent,
        VaultUploadOverwriteComponent,
        VelocityGainTableComponent,
        ViewDataFilterComponent,
        ViewDataLocationDetailsComponent,
        WasteWaterSettingsComponent,
        WeekGroupsSettingsComponent,
        WelcomeComponent,
        WidgetComponent,
        WidgetFiltersComponent,
        YesNoDialogComponent,
        TritonInfoComponent,
        EchoInfoComponent,
        foreSITEFlexInfoComponent,
        RainAlarmInfoComponent,
        RainalertInfoComponent,
        OtherMonitorsInfoComponent,
        TritonConfigureAlarmsComponent,
        TritonDataDeliveryComponent,
        FlowFormComponent,
        Mp2BasicInfoComponent,
        SamplerInfoComponent,
        RainSensorComponent,
        InstallationTypeInfoComponent,
        SaveCurveDialogComponent,
        SiteNamePopoverComponent,
        CurveOptionsDialogComponent,
        FmrSaveConfigurationDialogComponent,
        FmrOpenConfigurationDialogComponent,
        RainFallFrequencyOverviewComponent,
        ConfigurationTableComponent,
        ConfigurationSaveDialogComponent,
        EventWidgetComponent,
        CumulativeRainWidgetComponent,
        AddEventDialogComponent,
        EventFiltersComponent,
        DurationPickerComponent,
        MinMaxAvgTotalTableComponent,
        OtherLocationsGraphingDialogComponent,
        LocationNotesDialogComponent,
        QvsIGroupDialogComponent,
        LocationNotificationConfigurationComponent,
        ExpandedStormChooserComponent,
        LocationConfigurationReportComponent,
        HydrographTracerPanelComponent,
        DailyRainfallReportComponent,
        StormsPivotTableComponent,
        BasinQvsiPivotTableComponent,
        DryDayHydrographComponent,
        DryDayPivotTableComponent,
        HydrographCustomRangesComponent,
        HydrographLegendComponent,
        AdminBillingMetricsComponent,
        AdminCostAllocationComponent
    ],
    // #40925 Fix import of NgxIntlTelInputModule
    imports: [...appImports, NgxIntlTelInputModule],
    providers: [
        { provide: 'AppConfigOptions', useValue: defaultOptions },
        { provide: DateAdapter, useClass: DateFormat },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
        AboutUsService,
        AdalService,
        AddTritonLocationLongRangeDepthSensorComponent,
        AddTritonLocationService,
        AdsMonitoringService,
        AlarmService,
        AnnouncementService,
        ApiKeyService,
        AuditService,
        AuthGuard,
        LoginGuard,
        AuthService,
        AutoScrubSummaryService,
        BatteryStatusService,
        BillingReportsService,
        BlockagePredictionService,
        CacheService,
        CalculatedEntitiesService,
        CanDeactivateGuard,
        CollectService,
        CompositeLocationService,
        Config,
        ConfirmationService,
        CrowdCoreService,
        CustomDashboardService,
        CustomerDetailLocationService,
        CustomerService,
        DailySummaryReportService,
        DataEditService,
        DatePipe,
        DateService,
        DateutilService,
        DiagnosticService,
        DomOperationUtilsService,
        DynamicFilterWidgetService,
        EmailService,
        EntityService,
        FileService,
        FilterWidgetService,
        FlowMonitoringService,
        GISService,
        HydrographDataEditingService,
        HydrographNEWService,
        HydrographService,
        LocationCardService,
        LocationDashboardService,
        LocationFormBuilder,
        LocationGroupService,
        LocationService,
        MainResolverService,
        LocationCardService,
        LightningChartBuilder,
        LightningChartSeriesFactory,
        LightningChartObject,
        MapService,
        MathService,
        MatSnackBar,
        MessageService,
        MonitorDiagnosticsService,
        MonitorSettingService,
        NavigationService,
        NotificationDashboardService,
        PercentFullReportService,
        PreLoaderService,
        QstartDataService,
        SecondsToSampleRatePipe,
        ScatterGraphBuilder,
        ScheduleCollectionService,
        SearchService,
        SharedService,
        SliicerService,
        SliicerUtils,
        DrawPipeHelper,
        SnackBarNotificationService,
        TelemetryService,
        TelogService,
        UiService,
        TritonLocationService,
        UiUtilsService,
        UnitsService,
        UptimeReportService,
        DailyRainfallReportService,
        UserIdleService,
        UsersService,
        VaultService,
        VaultTelemetryExportResolverService,
        ViewDataService,
        SliicerDeactivateGuard
    ],
    bootstrap: [AppComponent],
    exports: [
        AdsPopupWindowDirective,
        DatetimepickerPositionDirective,
        AdsPrismSingleSelectGroupComponent,
        ANSRAlarmComponent,
        AutoCompleteComponent,
        CollectionWidgetComponent,
        CommonModule,
        CustomizedConfirmationBoxComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DdfGraphComponent,
        DiagnosticsMenuComponent,
        DownloadConfirmationsDialogComponent,
        EntitySelectorComponent,
        FooterComponent,
        HydroGraphComponent,
        HydrographEditingMenuComponent,
        LoaderComponent,
        LocationSelectionBarComponent,
        MarkerLocationDetailsComponent,
        MatAutocompleteModule,
        MultiSelectAutoCompleteComponent,
        MultiSelectGroupComponent,
        NgModelChangeDebouncedDirective,
        PagingComponent,
        SanitizePipe,
        StandardAlarmComponent,
        TimePickerComponent,
        TopnavComponent,
        TranslateModule,
        VaultUploadDialogComponent,
        VaultUploadDialogLocalComponent,
        VelocityGainTableComponent,
        WidgetFiltersComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }


