import { Customer } from './customer';
import { LocationData } from './locations';
import { GetPermissionUserFeature } from './users-permission';

export interface UsersData {
    userID?: string;
    userName?: string;
    firstName?: string;
    lastName?: string;
    secondaryEmail?: string;
    lastLogin?: Date;
    userRole?: string;
    userEmail?: string;
    phoneNumber?: string;
    role?: string;
    roles?: Array<string>;
    customers?: Customer[];
    isSelected?: boolean;
    testUser?: boolean;
    features?: Array<UserFeature>;
    isActive?: boolean;
    isAAD?: boolean;
}

export interface UsersDataDisplay {
    userEmail: string;
    name: string;
    role: string;
    customers: string;
    locations: string;
    features: Array<string>;
    isSelected: boolean;
    isActive: boolean;
}

/**
 * Represents a generic model which will
 * be used to get data from the API.
 */
export interface UsersInfo {
    features?: Array<GetPermissionUserFeature>;
    roles?: Array<string>;
    role?: string;
    secondaryEmail?: string;
    phoneNumber?: string;
    userEmail?: string;
    userID?: string;
    firstName?: string;
    lastName?: string;
    customers?: any;
    userRole?: Array<string>;
    locations?: Array<LocationData>;
    isIdex?: boolean;
}

export interface UserAdminData {
    firstName?: string;
    lastName?: string;
    userEmail?: string;
    secondaryEmail?: string;
    phoneNumber?: string;
    lastLogin?: string;
    isAAD?: boolean;
    defaultCustomer?: number;
}

export interface ILocationFeature {
    id: number;
    name: string;
    viewable: boolean;
}

export class UsersDetailInfo {
    public customerID: number;
    public customerName: string;
    public features?: Array<WidgetPermission>;
    public isSelected? = true;
    public shortName?: string;
    public customer?: DefaultCustomer;
    public defaultCustomer?: boolean;
}
export interface WidgetPermission {
    id: number;
    name: string;
    viewable: boolean;
}

export interface DefaultCustomer {
    customer: UsersDetailInfo;
    defaultCustomer: boolean;
    customerName?: string;
    customerID?: number;
}

export interface UserPermissons {
    firstName: string;
    lastName: string;
    userEmail: string;
    secondaryEmail: string;
    roles: Array<string>;
    features: Array<UserFeature>;
    customers: Array<Customer>;
}

export interface UserFeature {
    id: number;
    name: string;
    viewable: boolean;
}

export interface IAdminDetails {
    user: UsersData;
    customers: Array<Customer>;
}

export interface IFeatures {
    id: number;
    name: string;
    canassigncustomer: boolean;
    viewable?: boolean;
    disabled?: boolean;
}

export interface IRoles {
    id: string;
    name: string;
    normalizedName: string;
    concurrencyStamp: string;
    isSelected?: boolean;
}

/**
 * Represents a generic model which will
 * be used to send data to the post API.
 */

export interface IOriginalObject {
    roles: any;
    selectedCustomers: Array<UsersDetailInfo>;
    availableFeatures: Array<IFeatures>;
    selectedLocations: any;
}

export interface ILocationFeatures {
    customerID: number;
    locationId: number;
    locationName: string;
}

export interface ILocations {
    customerId: number;
    locations: Array<ILocationFeatures>;
}

export class UserPermissionInEdit implements UserPermissons {
    public firstName = '';
    public lastName = '';
    public userEmail = '';
    public secondaryEmail = '';
    public roles = [];
    public features = [];
    public customers = [];
}

export interface Language {
    value: string;
    viewValue: string;
}
