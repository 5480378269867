import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    OnDestroy,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import uniqBy from 'lodash/uniqBy';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { NotificationDashboardService } from 'app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService, USER_ROLES } from 'app/pages/admin/users.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { NotificationTreeComponent } from 'app/shared/components/notification-tree/notification-tree.component';
import { MapService } from 'app/shared/services/map.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationService } from 'app/shared/services/location.service';
import { EntityService } from 'app/shared/services/entity.service';
import { CalculatedEntitiesService } from 'app/shared/services/calculated-entities.service';
import { EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';
import {
    NotificationDetailsLite,
    NotificationLevel,
    NotificationLocationOptions,
    NotificationResponseValidation,
    NotificationUser,
    NotificationValidation,
    NotificationValidationError,
} from 'app/shared/models/notifications';
import { LocationEntitiesData, LocationListArgs } from 'app/shared/models/locations-entities-data';
import { AppQueryParams, customerQueryParam, genericIdQueryParam } from 'app/shared/models/customer';
import { AddLocationModalData } from 'app/shared/models/add-location';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { GISService } from 'app/shared/services/gis-service';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { delay, first } from 'rxjs/operators';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { TrackBy } from 'app/shared/utils/track-by';
import { NO_PAGINATION_REQUEST } from 'app/shared/constant';
interface UIAlarmNotificationValidation extends NotificationValidation {
    fieldWarnings: { field: string; message: string }[];
}

interface AlarmOption {
    name: string;
    id: number;
    type: string;
    checked: boolean;
}

@Component({
    selector: 'ansr-alarm',
    templateUrl: './ansr-alarm.component.html',
    styleUrls: ['./../alarms.component.scss', './../notification-dashboard.component.scss'],
})
export class ANSRAlarmComponent implements OnInit, OnDestroy {
    @ViewChild('nameField') public nameField: ElementRef;
    @ViewChild('enabledToggle') public enabledToggle: MatSlideToggle;
    @ViewChildren('enabledLevelToggle') public enabledLevelToggle: QueryList<MatSlideToggle>;

    // FOR ALL TABS
    private subscriptions = [new Subscription()];
    public PAGE_TEXT: any;
    public notificationObject: NotificationDetailsLite = {
        name: null,
        desc: null,
        enabled: true,
        levels: [],
        lids: [],
        aids: [],
        ansr: {
            ents: [
                {
                    locationId: null,
                    entityId: null,
                    variableName: '',
                },
            ],
            equation: '',
            name: '',
            description: '',
            formulaType: 0,
        },
    };
    public backupNotificationObject: NotificationDetailsLite;
    public selectedIndex = 4; // Start on confirmation page for subscribe only users
    private customerId: number;
    public notificationId: string;
    public loadingItems = { page: true, locations: true, validations: true, users: true };
    public subscribeOnly = true; // Can't edit unless admin or customer admin
    private userID: string;

    // FOR LOCATIONS TAB
    public locationsOptions: NotificationLocationOptions[] = [];
    public dispLocationsOptions: NotificationLocationOptions[] = [];
    public allLocationsChecked = false;
    public locationGroupOptions: string[];
    public selectedLocationGroup: string;
    public locationSearchString: string;
    /** Whenever it is one location selected. UI at alarm tab depends on this. */
    public oneLocationSelected: boolean;

    // FOR ALARM TAB
    public alarmOptions: AlarmOption[] = [];
    public sourceAlarmOptions = [
        'Flow Loss',
        'Full Pipe',
        'High High',
        'High Level',
        'Low Battery',
        'Low Level',
        'Overflow',
        'Rain',
        'Submerged',
        'Tilt',
    ];
    public selectedSourceAlarm = 'High High';
    public entitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};
    public ansrEntitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};
    public entitiesAll: EntitySelectorObject[] = [];
    public ansrEntitiesAll: EntitySelectorObject[] = [];
    public validatingEntity = false;
    public isEntityValid = false;
    public isEntityUIValid = false;

    // For notification tree tab
    public notificationTreeColumns: string[] = ['name', 'delay', 'userCount', 'enable', 'options'];
    public userOptions: NotificationUser[];

    // FOR VALIDATION TAB
    public notificationValidated = false;
    public showErrors = true;
    public showWarnings = true;
    public validationInfo: UIAlarmNotificationValidation = {
        warnings: [],
        fieldWarnings: [],
        errors: [],
        fieldErrors: [],
    };

    // FOR CONFIRMATION TAB
    public confirmationShowUsers: boolean[] = [];
    public subscribeUser: { alarm: boolean; rtn: boolean }[] = [];
    public equation: string;
    public allEntitiesByLocation: Array<any> = [];

    public trackByIndex = TrackBy.byIndex;
    public trackById = TrackBy.byId;
    public trackByEntityId = TrackBy.byEntityId;

    constructor(
        private matDialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private mapService: MapService,
        private usersService: UsersService,
        private snackBar: MatSnackBar,
        private locationGroupService: LocationGroupService,
        private locationService: LocationService,
        private entityService: EntityService,
        private statusCodeService: StatusCodeService,
        private calculatedEntitiesService: CalculatedEntitiesService,
        private notificationService: NotificationDashboardService,
        private gisService: GISService,
        public domOperationUtilsService: DomOperationUtilsService,
        private locationCardService: LocationCardService
    ) {
        this.translate.get('NOTIFICATION_DASHBOARD').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });
        this.userID = this.usersService.userID.getValue().toUpperCase();
        this.subscribeOnly = !this.usersService.userRoles
            .getValue()
            .some((x) => x === USER_ROLES.ADMIN || x === USER_ROLES.CUSTOMER_ADMIN || x === USER_ROLES.CUSTOMER_USER_MANAGER);

        // Set current customer
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                const customerId = Number(params.get(customerQueryParam));
                const notificationId = params.get(genericIdQueryParam);

                if (this.notificationId !== notificationId || this.customerId !== customerId) {
                    // Only need to update if customer or id has changed
                    this.customerId = customerId;
                    this.notificationId = notificationId;

                    this.loadNotificationsWithLocationsAndEntities();
                }
            }),
        );
    }

    public ngOnInit() {
        // On page load
        if (this.subscribeOnly) {
            this.selectedIndex = 4;
        } else if (this.notificationId) {
            // Admin and edit notification
            this.selectedIndex = 0;
        } else {
            // Admin and new notification
            this.selectedIndex = 0;
        }

        setTimeout(() => this.nameField.nativeElement.focus(), 50);
    }

    private loadNotificationsWithLocationsAndEntities() {
        // Clear out locations before getting new ones
        this.loadingItems.page = true;
        this.loadingItems.locations = true;
        this.locationGroupOptions = [];
        this.locationsOptions = [];
        this.dispLocationsOptions = [];

        this.loadAlarmTypes();

        // Need location groups in order to know which group each location belongs in
        const locationGroupSubscription = this.locationGroupService.getLocationGroups(this.customerId).subscribe(
            (locGrpRes) => {
                const locationGroups: Map<number, any> = new Map(
                    flatMap(locGrpRes.locationGroups, (locGrp) =>
                        locGrp.locations.map((loc) => [loc.locationID, locGrp]),
                    ),
                );

                const loadEntitiesWithNotification = combineLatest([
                    this.locationService.getLocationData(<LocationListArgs>{
                        cid: this.customerId,
                        IncludeInactiveLocations: this.statusCodeService.activeInactiveHandler.getValue(),
                    }),
                    this.getNotificationForEditing(),
                ]).subscribe(
                    ([locRes, res]) => {
                        if (locRes) {
                            const selectedLocs = res ? res.lids : [];
                            this.locationsOptions = locRes.l
                                .filter((x) => x.t !== 3) // Filter out Composite locations
                                .map((x) => {
                                    const grp = locationGroups.get(x.lid);
                                    return {
                                        id: x.lid,
                                        name: x.n,
                                        checked: selectedLocs.includes(x.lid),
                                        group: grp ? grp.name : undefined,
                                    };
                                })
                                .sort((l1, l2) =>
                                    l1.name && l2.name ? l1.name.localeCompare(l2.name) : l1.name ? -1 : 1,
                                );

                            this.locationGroupOptions = uniqBy(this.locationsOptions, 'group')
                                .map((x) => x.group)
                                .filter(Boolean)
                                .sort();
                            this.locationGroupOptions.unshift('All');
                            this.selectedLocationGroup = this.locationGroupOptions[0];
                            this.locationSetSearchAndGroup(); // Populates screen and handles filtering / check all functionality
                        }
                        // handle entities
                        this.distributeEntitiesAndLocations(locRes);
                        this.handleNotifcationFromEditing(res);

                        loadEntitiesWithNotification.unsubscribe();
                        this.uiUtilsService.safeChangeDetection(this.cdr);

                        this.formulaChange();

                        this.populatePageOptions();
                        this.loadingItems.locations = false;
                        this.loadingItems.page = false;

                        if (res) {
                            this.isEntityValid = true;
                        }
                    },
                    (err) => {
                        this.loadingItems.locations = false;
                        this.loadingItems.page = false;
                    },
                );
            },
            (error) => {
                this.loadingItems.locations = false;
                this.loadingItems.page = false;
            },
        );
        this.subscriptions.push(locationGroupSubscription);
    }

    private getNotificationForEditing(): Observable<NotificationDetailsLite> {
        return this.notificationService.getSingleNotificationDetails(this.customerId, this.notificationId);
    }

    private handleNotifcationFromEditing(res: NotificationDetailsLite) {
        if (res) {
            this.notificationObject = { ...res };
            res.levels.forEach((lvl, ind) => {
                const userInLevel = lvl.users.find((x) => x.id === this.userID);
                if (userInLevel) {
                    this.subscribeUser[ind] = { alarm: userInLevel.alarm, rtn: userInLevel.rtn };
                } else {
                    this.subscribeUser[ind] = { alarm: false, rtn: false };
                }

                lvl.users = lvl.users.filter(x => x.rtn || x.admin || x.alarm);
            });
            if (this.notificationObject.lids && this.notificationObject.lids.length === 1) {
                this.oneLocationSelected = true;
            } else {
                // have to translate response to one understable by UI
                // UI for multiple locations do not hold locations inside entities, but in lids,
                // also there is one entity type for all of locations instead of multiple of them for each location
                const uniqueEntities = [];
                const entitiesBody = [];
                for (const ent of this.notificationObject.ansr.ents) {
                    if (!uniqueEntities.includes(ent.entityId)) {
                        uniqueEntities.push(ent.entityId);
                        ent.locationId = null;
                        entitiesBody.push(ent);
                    }
                }
                this.notificationObject.ansr.ents = entitiesBody;
            }
        } else {
            // Notification not found aka create new one
            this.notificationId = undefined;
            this.notificationObject = {
                name: '',
                desc: null,
                enabled: true,
                levels: [],
                lids: [],
                aids: [null],
                ansr: {
                    ents: [
                        {
                            locationId: null,
                            entityId: null,
                            variableName: '',
                        },
                    ],
                    equation: '',
                    name: '',
                    description: '',
                    formulaType: 0,
                },
            };
        }

        // Once we have a notification (or blank template if no notification)
        // we can populate all of the options that the page will need for each tab
        this.backupNotificationObject = { ...this.notificationObject };
        this.loadingItems.page = false;
    }

    private populatePageOptions() {
        this.loadUsersForTree();
    }

    public loadAlarmTypes() {
        this.alarmOptions = [];
        const opts = this.notificationService.getAllAlarmTypesAnsr();
        this.alarmOptions = opts.map(v => ({ ...v, checked: false }));
    }

    public loadUsersForTree() {
        this.loadingItems.users = true;
        this.userOptions = [];
        if (!this.subscribeOnly) {
            // #Bug 44159 - Passing '-1' as pageIndex to not consider Pagination 
            const usersSubscription = this.usersService
                .getCustomerUsersV2(this.customerId, false, null, NO_PAGINATION_REQUEST, null, false)
                .subscribe({
                    next: (res) => {
                        if (res && res.payload) {
                            this.userOptions = res.payload
                                .sort((u1, u2) => {
                                    const lnc = u1.lastName.localeCompare(u2.lastName);
                                    if (lnc !== 0) return lnc;

                                    const fnc = u1.firstName.localeCompare(u2.firstName);
                                    if (fnc !== 0) return lnc;

                                    return u1.userID > u2.userID ? 1 : u1.userID < u2.userID ? -1 : 0;
                                })
                                .map((usr) => {
                                    return {
                                        dispName:
                                            usr.firstName || usr.lastName
                                                ? usr.firstName.concat(' ', usr.lastName)
                                                : usr.userName,
                                        id: usr.userID.toUpperCase(),
                                        admin: usr.role === USER_ROLES.ADMIN,
                                        alarm: false,
                                        rtn: false,
                                    };
                                });
                        }
                        this.loadingItems.users = false;
                    },
                    error: (error) => this.loadingItems.users = false,
                });
            this.subscriptions.push(usersSubscription);
        } else {
            this.loadingItems.users = false;
        }
    }

    public toggleNotificationEnabled(event: MatSlideToggleChange) {
        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_NOTIFICATION_CONFIRM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.notificationObject.enabled = false;
                    } else {
                        this.notificationObject.enabled = true;
                        this.enabledToggle.checked = true; // To visually force it back to the previous state
                    }
                });
        } else {
            this.notificationObject.enabled = true;
        }
    }
    public backToAllNotifications() {
        // Make sure you capture any changes in active tab
        if (this.selectedIndex === 0) {
            this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);
        } else if (this.selectedIndex === 1) {
            this.notificationObject.ansr.ents = this.notificationObject.ansr.ents.map((x) => {
                return {
                    locationId: x['locationId'],
                    entityId: x['entityId'],
                    variableName: x['variableName'],
                };
            });
            this.notificationObject.ansr.equation = this.equation;
            this.notificationObject.ansr.name = this.selectedSourceAlarm;
        }

        if (isEqual(this.backupNotificationObject, this.notificationObject)) {
            // No changes have been made, just go back
            this.actuallyGoBack();
        } else {
            // Confirm that you want to discard changes
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.BACK_HEADER,
                        message: this.PAGE_TEXT.BACK_TEXT,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.actuallyGoBack();
                    }
                });
        }
    }

    private actuallyGoBack() {
        const route = '/pages/menuDashboard/notifications';
        const appQueryParams: AppQueryParams = { c: this.customerId };

        this.router.navigate([route], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    // VALIDATION TAB FUNCTIONS
    public validateNotification() {
        this.notificationValidated = false;
        this.loadingItems.validations = true;

        // Check that all of the required items are present don't validate if not
        if (!this.checkAllRequiredInputs()) {
            return;
        }

        const validationServiceCall = this.notificationService.validateNotification(
            this.customerId,
            this.notificationObject,
        );

        // Once everything that is required is present, validate the alarm
        const validationSubscription = validationServiceCall.subscribe(
            (res: NotificationResponseValidation) => {
                this.validationInfo = { warnings: [], fieldWarnings: [], errors: [], fieldErrors: [] };

                const noUserWillBeNotfied = this.notificationObject.levels.every(
                    (x) => !x.enabled || x.users.length === 0,
                );
                if (noUserWillBeNotfied) {
                    this.validationInfo.fieldWarnings.push({
                        field: 'notifications',
                        message: this.PAGE_TEXT.NO_USER_NOTIFICATION_SELECTED,
                    });
                }

                if (res) {
                    if (res.fieldErrors) {
                        for (const error of res.fieldErrors) {
                            this.validationInfo.fieldErrors.push({
                                field: error.fieldName,
                                message: error.fieldMessage,
                            });
                        }
                    }

                    if (res.payload) {
                        const locKeys = Object.keys(res.payload);
                        const payload = res.payload;
                        locKeys.forEach((loc) => {
                            const warningLocItem = {
                                location: payload[loc][0].locationName,
                                lid: payload[loc][0].locationID,
                                items: [],
                            };
                            const errorLocItem = {
                                location: payload[loc][0].locationName,
                                lid: payload[loc][0].locationID,
                                items: [],
                            };

                            payload[loc].forEach((itm) => {
                                switch (itm.error) {
                                    case NotificationValidationError.AlarmInvalid: {
                                        warningLocItem.items.push({
                                            error: itm.error,
                                            alarmType: itm.alarmType,
                                            text: this.PAGE_TEXT.ERROR_TEXT.ALARM_INVALID.concat(
                                                this.notificationService.getSpecificAlarmType(itm.alarmType).name,
                                            ).concat(this.PAGE_TEXT.ERROR_TEXT.ALARM),
                                        });
                                        break;
                                    }
                                    case NotificationValidationError.AlarmNotConfigured: {
                                        errorLocItem.items.push({
                                            error: itm.error,
                                            alarmType: itm.alarmType,
                                            text: this.notificationService
                                                .getSpecificAlarmType(itm.alarmType)
                                                .name.concat(this.PAGE_TEXT.ERROR_TEXT.ALARM_NOT_CONFIGURED),
                                        });
                                        break;
                                    }
                                    case NotificationValidationError.DuplicateNotification: {
                                        errorLocItem.items.push({
                                            error: itm.error,
                                            alarmType: itm.alarmType,
                                            text: this.notificationService
                                                .getSpecificAlarmType(itm.alarmType)
                                                .name.concat(this.PAGE_TEXT.ERROR_TEXT.DUPLICATE_NOTIFICATION),
                                        });
                                        break;
                                    }
                                    case NotificationValidationError.ConfigurationInvalid: {
                                        warningLocItem.items.push({
                                            error: itm.error,
                                            alarmType: itm.alarmType,
                                            text: this.notificationService
                                                .getSpecificAlarmType(itm.alarmType)
                                                .name.concat(this.PAGE_TEXT.ERROR_TEXT.CONFIGURATION_INVALID),
                                        });
                                        break;
                                    }
                                    default:
                                        break;
                                }
                            });

                            if (warningLocItem.items.length > 0) {
                                this.validationInfo.warnings.push(warningLocItem);
                            }
                            if (errorLocItem.items.length > 0) {
                                this.validationInfo.errors.push(errorLocItem);
                            }
                        });
                    }
                }

                // Any locations with errors need full location data for location cards
                this.notificationValidated = true;
                this.loadingItems.validations = false;
            },
            (error) => {
                this.loadingItems.validations = false;
            },
        );
        this.subscriptions.push(validationSubscription);
    }
    public openLocationCard(lid: number) {
        const locCardSub = this.locationService.getLocationDetailsV2(this.customerId, lid).subscribe(
            (locRes) => {
                const locationDetails = {
                    series: locRes[0].series,
                    serialNumber: locRes[0].serialNumber,
                    isActive: locRes[0].isActive,
                    modem: locRes[0].modem,
                    description: locRes[0].description,
                    qFinalEntityId: locRes[0].qFinalEntityID,
                    manholeAddress: locRes[0].manholeAddress,
                    coordinate: { latitude: locRes[0].latitude, longitude: locRes[0].longitude, elevation: 1 },
                    locationID: locRes[0].locationID,
                    locationName: locRes[0].locationName,
                    assignedRainGaugeLocationId: locRes[0].assignedRainGaugeLocationId,
                    installationHeight: locRes[0].height,
                    installationWidth: locRes[0].width,
                    installationType: locRes[0].installationType,
                    installationId: locRes[0].installationID,
                    depthUnit: locRes[0].depthUnit,
                    flowUnit: locRes[0].flowUnit,
                    locationType: locRes[0].components ? 3 : 1,
                    components: locRes[0].components,
                    installationShape: locRes[0].installationShape,
                    ipaddress: locRes[0].connectionString,
                    range: locRes[0].range,
                    capacity: locRes[0].capacity,
                    width: locRes[0].width,
                    installationShapeTypeID: locRes[0].installationShapeTypeID,
                    manholedepth: locRes[0].manholeDepth,
                    length: locRes[0].length,
                    breadth: locRes[0].breadth,
                    coefficient: locRes[0].coefficient,
                    assignedRainGaugeLocationName: locRes[0].assignedRainGaugeLocationName,
                    entries: locRes[0].entries,
                    lastCollectedDate: '',
                };

                const dialogOptions: MatDialogConfig = {
                    disableClose: true,
                    data: {
                        isFromCustomerEditor: false,
                        rainGaugeLocations: [],
                        customerId: this.customerId,
                        editMode: true,
                        locationDetails,
                        isFromNotificationDash: true,
                    },
                };

                if (this.gisService.locationCardPosition) {
                    dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                }

                if (this.mapService.addEditLocationDialog) {
                    this.mapService.addEditLocationDialog.close();
                }
                this.mapService.addEditLocationDialog = this.matDialog.open(
                    TritonLocationDialogComponent,
                    dialogOptions,
                );
                this.mapService.addEditLocationDialog
                    .afterClosed()
                    .pipe(first(), delay(1000))
                    .subscribe((result: boolean) => {
                        this.mapService.addEditLocationDialog = null;
                        if (result) {
                            this.validateNotification();
                        }
                    });
            },
            (error) => {},
        );
        this.subscriptions.push(locCardSub);
    }

    public checkAllRequiredInputs(): boolean {
        if (this.notificationObject.name === null || this.notificationObject.name.trim() === '') {
            this.snackBar.open(this.PAGE_TEXT.MISSING_NAME, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => this.nameField.nativeElement.focus(), 0);
            return false;
        }

        this.notificationObject = {
            ...this.notificationObject,
            name: this.notificationObject.name.trim(),
            desc: this.notificationObject.desc ? this.notificationObject.desc.trim() : '',
        };
        const obj = this.notificationObject;
        if (obj.lids.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LOCATIONS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 0), 500);
            return false;
        } else if (obj.ansr.equation.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_ALARMS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 1), 500);
            return false;
        } else if (obj.levels.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LEVELS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 2), 500);
            return false;
        }

        return true;
    }

    // ALL TAB FUNCTIONS
    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.selectedIndex = tabChangeEvent.index;
        this.domOperationUtilsService.selectedStandarAlarmTab.next(this.selectedIndex);
        this.handleTabChange(false);
    }
    public handleTabChange(changeTab: boolean, direction?: boolean) {
        // Update notification object or validate as necessary
        this.notificationValidated = false;
        this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);

        if (this.selectedIndex === 3) {
            this.validateNotification();
        }

        // Fill first entry only if it was a NEXT button from a step 2
        if (this.selectedIndex === 1 && changeTab && direction) {
            this.checkSelectedLocations();
        }
    }

    /** Whenever Controller waq */
    public callbackChangeTab(tabIndex: number) {
        if (tabIndex < 0 || tabIndex > 4) return;
        this.selectedIndex = tabIndex;
    }

    public uiChangeTab(changeTab: boolean, direction?: boolean) {
        if (this.selectedIndex === 1 && direction && !this.isEntityValid) {
            this.validateEquation();
            return;
        }

        if (changeTab) {
            direction ? (this.selectedIndex += 1) : (this.selectedIndex -= 1);
        }
    }

    public allowNext(currentTabIndex: number) {
        let condition = true;
        switch (currentTabIndex) {
            // Validation tab
            case 3:
            default:
                condition =
                    condition &&
                    this.notificationValidated &&
                    this.validationInfo.errors.length === 0 &&
                    this.validationInfo.fieldErrors.length === 0;
            // Levels Tab
            case 2:
                condition = condition && this.isEntityValid && this.notificationObject.levels.length > 0;
            // Alarm tab
            case 1:
                condition = condition && this.isEntityUIValid;
            // Location tab
            case 0:
                condition =
                    condition &&
                    this.locationsOptions.some((x) => x.checked) &&
                    this.notificationObject.name !== null &&
                    this.notificationObject.name !== '';
        }

        return condition;
    }
    public disableTab(tabIndex: number) {
        switch (tabIndex) {
            case 0: // Location tab
                // Disable if subscribe only
                return this.subscribeOnly;
            case 1: // Alarm tab
                return this.subscribeOnly || (!this.notificationId && this.selectedIndex < tabIndex);
            case 2: // Levels tab
            case 3: // Validation tab
                // Disable if subscribe only OR new add and in earlier tab
                return (
                    this.subscribeOnly || !this.isEntityValid || (!this.notificationId && this.selectedIndex < tabIndex)
                );
            case 4:
            default:
                return this.selectedIndex !== 4;
        }
    }
    public checkName() {
        if (this.notificationObject.name !== null) {
            this.notificationObject.name = this.notificationObject.name.trim();
        }
        if (this.selectedIndex === 3) {
            this.validateNotification();
        }
    }

    public checkSelectedLocations() {
        const loc = this.locationsOptions.filter((x) => x.checked);
        this.oneLocationSelected = loc.length === 1;

        if (
            loc.length === 1 &&
            this.notificationObject.ansr.ents &&
            (this.notificationObject.ansr.ents.length === 0 || !this.notificationObject.ansr.ents[0].locationId)
        ) {
            this.notificationObject.ansr.ents[0] = {
                locationId: loc[0].id,
                entityId: null,
                variableName: '',
            };
        }
    }
    // END ALL TAB FUNCTIONS

    // LOCATIONS TAB FUNCTIONS
    public checkAllLocations() {
        // Update displayed locations only
        this.allLocationsChecked = !this.allLocationsChecked;
        this.dispLocationsOptions.forEach((loc) => (loc.checked = this.allLocationsChecked));

        // Have to "touch" locationOptions so pipe at summary will refresh
        this.locationsOptions = this.locationsOptions.map((loc) => loc);
    }
    public updateLocation(location: NotificationLocationOptions) {
        this.locationsOptions = this.locationsOptions.map((loc) => {
            if (loc.id === location.id) {
                loc.checked = !loc.checked;
            }
            return loc;
        });
        this.locationSetSearchAndGroup(); // Update displayed location and whether 'check all' should be checked
    }
    public locationSetSearchAndGroup() {
        // Set displayed locations by group first
        if (this.selectedLocationGroup === this.locationGroupOptions[0]) {
            // 'All' option
            this.dispLocationsOptions = this.locationsOptions;
        } else {
            this.dispLocationsOptions = this.locationsOptions.filter((loc) => loc.group === this.selectedLocationGroup);
        }

        // Next filter by search string
        if (this.locationSearchString && this.locationSearchString !== '') {
            this.dispLocationsOptions = this.dispLocationsOptions.filter((loc) =>
                loc.name.toLowerCase().includes(this.locationSearchString.toLowerCase()),
            );
        }

        // Finally update whether all displayed locations should be checked
        this.allLocationsChecked =
            this.dispLocationsOptions.length > 0 && this.dispLocationsOptions.every((x) => x.checked);
    }
    // END LOCATIONS TAB FUNCTIONS

    // ALARM TAB FUNCTIONS
    private distributeEntitiesAndLocations(data: LocationEntitiesData) {
        this.entitiesAll = [];
        const uniqueEntities = [];
        const uniqueGroups = [];
        for (const d of data.d) {
            for (const g of this.entityService.seriesEntityGroupToEntitySelectorObject(d.g)) {
                if (g.entities && g.entities.length > 0) {
                    if (!uniqueGroups.includes(g.groupId)) {
                        uniqueGroups.push(g.groupId);
                        this.entitiesAll[g.groupId] = {
                            groupId: g.groupId,
                            groupName: g.groupName,
                            entities: [],
                        };
                    }
                    const group = this.entitiesAll[g.groupId];
                    for (const e of g.entities) {
                        if (!uniqueEntities.includes(e.id)) {
                            uniqueEntities.push(e.id);
                            group.entities.push(e);
                        }
                    }
                }
            }
        }
        this.entitiesAll = this.entitiesAll.filter((x) => x.entities && x.entities.length > 0);

        this.entitiesByLocation = data.l.reduce((acc, val) => {
            const groups = this.entityService.getSelectedDevices(data, val.s, val.t);

            const entityGroups = groups ? [...this.entityService.seriesEntityGroupToEntitySelectorObject([...groups[0].g])] : [];

            return {
                ...acc,
                [val.lid]: entityGroups,
            };
        }, {});

        this.ansrEntitiesByLocation = data.l.reduce((acc, val) => {
            const entityGroups = [...this.entityService.ansrEntityGroupToEntitySelectorObject([...val.ae], [], true)];

            return {
                ...acc,
                [val.lid]: entityGroups,
            };
        }, {});

        this.ansrEntitiesAll = Object.values(this.ansrEntitiesByLocation).reduce(
            (previousValue, currentValue): EntitySelectorObject[] => {
                previousValue.push(...currentValue);
                return previousValue;
            },
            [],
        );

        this.allEntitiesByLocation = Object.values(this.entitiesByLocation)
            .reduce((acc, val) => acc.concat(val.map((x) => x.entities)), [] as Array<any>)
            .reduce((acc, val) => acc.concat(val.map((x) => x)), []);
    }

    public addMoreRow() {
        this.notificationObject.ansr.ents.push({
            locationId: null,
            entityId: null,
            variableName: '',
        });
        this.formulaChange();
    }

    public removeRow(index: number) {
        if (index > -1) {
            this.notificationObject.ansr.ents.splice(index, 1);
        }
        this.formulaChange();
    }

    public selectedGroupEntity(selectedEntities: EntitySelectorEntity[], selectedSeries) {
        const index = this.notificationObject.ansr.ents.indexOf(selectedSeries);
        if (index === -1) {
            return;
        }
        if (selectedEntities && selectedEntities.length) {
            this.notificationObject.ansr.ents[index]['entityId'] = selectedEntities[0].id;
        } else {
            this.notificationObject.ansr.ents[index]['entityId'] = null;
        }
    }

    public validateEquation() {
        if (this.validatingEntity) return;

        const VALIDATION_DURATION_NOTIFICATION = 10000;

        this.validatingEntity = true;
        const completeEquation = this.notificationObject.ansr.equation;
        const equationValidateSubscription = this.calculatedEntitiesService
            .validateEquation(
                completeEquation,
                this.notificationObject.ansr.ents.map((x) => x.variableName),
            )
            .subscribe(
                (equation) => {
                    if (!equation.isError) {
                        this.isEntityValid = true;
                        this.callbackChangeTab(2);
                        this.snackBar.open(equation.message, this.PAGE_TEXT.DISMISS, {
                            duration: VALIDATION_DURATION_NOTIFICATION,
                        });
                    } else {
                        this.snackBar.open(equation.message, this.PAGE_TEXT.DISMISS, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }

                    this.validatingEntity = false;
                },
                (error) => {
                    this.snackBar.open(error.message || error, this.PAGE_TEXT.DISMISS, {
                        panelClass: 'custom-error-snack-bar',
                    });

                    this.validatingEntity = false;
                },
            );

        this.subscriptions.push(equationValidateSubscription);
    }

    public formulaChange() {
        this.isEntityValid = false;
        if (this.oneLocationSelected) {
            this.isEntityUIValid =
                this.notificationObject.ansr.equation &&
                this.notificationObject.aids.length >= 1 &&
                this.notificationObject.aids[0] &&
                this.notificationObject.ansr.ents.reduce<boolean>((previousValue: boolean, currentValue): boolean => {
                    return (
                        previousValue &&
                        (currentValue.locationId && currentValue.variableName && currentValue.entityId ? true : false)
                    );
                }, true);
        } else {
            this.isEntityUIValid =
                this.notificationObject.ansr.equation &&
                this.notificationObject.aids.length >= 1 &&
                this.notificationObject.aids[0] &&
                this.notificationObject.ansr.ents.reduce<boolean>((previousValue: boolean, currentValue): boolean => {
                    return previousValue && (currentValue.variableName && currentValue.entityId ? true : false);
                }, true);
        }
    }
    // END ALARM TAB FUNCTIONS

    // NOTIFICATION TREE TAB FUNCTIONS
    public addNotificationLevel() {
        const names = this.notificationObject.levels.map((lvl) => lvl.name);
        this.openNotificationDialog(names);
    }
    public editNotificationLevel(element: NotificationLevel) {
        const names = this.notificationObject.levels.map((lvl) => lvl.name).filter((name) => name !== element.name);
        +this.openNotificationDialog(names, element);
    }
    public deleteNotificationLevel(element: NotificationLevel) {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.PAGE_TEXT.BACK_HEADER,
                    message:
                        this.PAGE_TEXT.DELETE_FIRST +
                        element.name +
                        this.PAGE_TEXT.DELETE_LEVEL +
                        this.PAGE_TEXT.DELETE_SECOND,
                    okText: this.PAGE_TEXT.CONFIRM,
                    cancelText: this.PAGE_TEXT.CANCEL,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.notificationObject = {
                        ...this.notificationObject,
                        levels: this.notificationObject.levels.filter((x) => x.name !== element.name),
                    };
                }
            });
    }
    public toggleNotificationLevelEnabled(event: MatSlideToggleChange, element: NotificationLevel) {
        const affectedInd = this.notificationObject.levels.findIndex((x) => x.name === element.name);
        const affectedElement = this.notificationObject.levels[affectedInd];
        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_LEVEL_CONRIM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        affectedElement.enabled = false;
                    } else {
                        affectedElement.enabled = true;
                        const affectedToggle = this.enabledLevelToggle.filter((e, i) => i === affectedInd)[0];
                        affectedToggle.checked = true; // To visually force it back to the previous state
                    }
                });
        } else {
            affectedElement.enabled = true;
        }
    }
    public openNotificationDialog(names: string[], element?: NotificationLevel) {
        this.matDialog
            .open(NotificationTreeComponent, {
                disableClose: true,
                data: {
                    users: this.userOptions,
                    selectedUsers: element ? element.users : [],
                    name: element ? element.name : undefined,
                    names: names,
                    delay: element ? element.delay : 0,
                    enabled: element ? element.enabled : true,
                    showRtn: true,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.save && result.data) {
                    const resLevel: NotificationLevel = {
                        name: result.data.name,
                        delay: result.data.delay,
                        enabled: result.data.enabled,
                        users: result.data.selectedUsers,
                    };

                    if (element) {
                        // Update existing level
                        this.notificationObject = {
                            ...this.notificationObject,
                            levels: this.notificationObject.levels.map((lvl) => {
                                return lvl.name === element.name ? resLevel : lvl;
                            }),
                        };
                    } else {
                        // Create a new level
                        this.notificationObject = {
                            ...this.notificationObject,
                            levels: this.notificationObject.levels.concat(resLevel),
                        };
                    }

                    this.notificationObject.levels = sortBy(this.notificationObject.levels, ['delay']);
                }
            });
    }
    getAlarmTypeName() {
        if (this.notificationObject && this.notificationObject.aids && this.notificationObject.aids.length > 0) {
            const found = this.alarmOptions.filter((x) => this.notificationObject.aids.includes(x.id));
            if (found && found.length > 0) {
                return found[0].name;
            }
        }

        return '';
    }
    // END NOTIFICATION TREE TAB FUNCTIONS

    // CONFIRMATION TAB FUNCTIONS
    public convertIDtoUserNames(users: NotificationUser[]) {
        const usersByName = users.map((usr) => {
            const correctUser = this.userOptions.find((x) => x.id === usr.id);
            if (correctUser) {
                return correctUser.dispName;
            }
            return 'Unknown user';
        });
        return usersByName.sort();
    }

    public filterChecked(x) {
        return x.checked === true;
    }

    public filterLocation(z) {
        return this.locationsOptions.find((y) => y.id === z.locationId)
            ? this.locationsOptions.find((y) => y.id === z.locationId).name
            : '';
    }

    public filterEntities(z) {
        return this.allEntitiesByLocation.find((y) => y.id === z.entityId)
            ? this.allEntitiesByLocation.find((y) => y.id === z.entityId).name
            : '';
    }

    public promptToUpdateSubscription(ind: number, alarm: boolean) {
        // If you are currently subscribed and want to unsubscribe, prompt before doing so
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.PAGE_TEXT.DISABLE_HEADER,
                    message: this.PAGE_TEXT.DISABLE_USER_CONFIRM,
                    okText: this.PAGE_TEXT.CONFIRM,
                    cancelText: this.PAGE_TEXT.CANCEL,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    // Only actually update if they confirm
                    this.updateSubscription(ind, alarm);
                }
            });
    }
    public updateSubscription(ind: number, alarm: boolean) {
        const alarmUser = this.subscribeUser[ind];
        if (alarm) {
            alarmUser.alarm = !alarmUser.alarm;
        } else {
            alarmUser.rtn = !alarmUser.rtn;
        }

        const levelUsers = this.notificationObject.levels[ind].users;
        let myUser = levelUsers.find((x) => x.id === this.userID);
        if (myUser) {
            myUser.alarm = alarmUser.alarm;
            myUser.rtn = alarmUser.rtn;
        } else {
            levelUsers.push({ id: this.userID, alarm: alarmUser.alarm, rtn: alarmUser.rtn, admin: false });
        }
        const subscribed = alarm ? alarmUser.alarm : alarmUser.rtn;
        const updateSubscription = this.notificationService
            .updateNotification(this.customerId, this.notificationObject)
            .subscribe(
                () => {
                    let responseText = subscribed
                        ? this.PAGE_TEXT.SUBSCRIBE_TEXT.SUBSCRIBE_SUCCESS
                        : this.PAGE_TEXT.SUBSCRIBE_TEXT.UNSUBSCRIBE_SUCCESS;
                    responseText = responseText.concat(this.notificationObject.levels[ind].name);
                    this.snackBar.open(responseText, this.PAGE_TEXT.DISMISS, { duration: 10000 });
                },
                (error) => {
                    let responseText = subscribed
                        ? this.PAGE_TEXT.SUBSCRIBE_TEXT.SUBSCRIBE_ERROR
                        : this.PAGE_TEXT.SUBSCRIBE_TEXT.UNSUBSCRIBE_ERROR;
                    responseText = responseText.concat(this.notificationObject.levels[ind].name, error);
                    this.snackBar.open(responseText, this.PAGE_TEXT.DISMISS);

                    // Reset icons back to how they were
                    if (alarm) {
                        alarmUser.alarm = !alarmUser.alarm;
                    } else {
                        alarmUser.rtn = !alarmUser.rtn;
                    }
                    myUser = { ...myUser, alarm: alarmUser.alarm, rtn: alarmUser.rtn };
                },
            );
        this.subscriptions.push(updateSubscription);

        if(myUser && (!myUser.alarm && !myUser.rtn)) {
            this.notificationObject.levels[ind].users = this.notificationObject.levels[ind].users.filter(x => x.rtn || x.alarm);
        }
    }
    public confirm() {
        let cnt = 1;
        this.notificationObject.levels.forEach((x) => (x.level = cnt++));

        let postNotifcationObject = this.notificationObject;
        if (!this.oneLocationSelected) {
            // Has to populate entities in locations. Each location should have all the defined entities
            postNotifcationObject = JSON.parse(JSON.stringify(this.notificationObject));
            postNotifcationObject.ansr.ents = [];
            for (const loc of this.notificationObject.lids) {
                for (const ent of this.notificationObject.ansr.ents) {
                    const postEnt = { ...ent };
                    postEnt.locationId = loc;
                    postNotifcationObject.ansr.ents.push(postEnt);
                }
            }
        }
        const saveSubscription = this.notificationService
            .updateNotification(this.customerId, postNotifcationObject)
            .subscribe(
                (res: any) => {
                    if (res && res.isError === false) {
                        this.actuallyGoBack();
                    } else {
                        this.snackBar.open(this.PAGE_TEXT.CONFIRM_ERROR, this.PAGE_TEXT.DISMISS, { duration: 10000 });
                    }
                },
                (error) => {
                    this.snackBar.open(this.PAGE_TEXT.CONFIRM_ERROR, this.PAGE_TEXT.DISMISS);
                },
            );
        this.subscriptions.push(saveSubscription);
    }
    // END CONFIRMATION TAB FUNCTIONS

    ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.forEach((v) => v.unsubscribe());
    }
}
