import {
    Component,
    Input,
    OnChanges,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy,
    SimpleChanges,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { Breadcrumb, MenuItem } from '../menu-item';
import { NavigationService } from '../navigation.service';
import { Title } from '@angular/platform-browser';
import { StringUtils } from 'app/shared/utils/string-utils';
import { AuthService } from 'app/shared/services/auth.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { SharedService } from 'app/shared/services/shared.service';
import { SignalRService } from 'app/shared/services/signalr.service';
import { SearchService } from 'app/shared/services/search.service';
import { Subscription } from 'rxjs';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationGroupEditorComponent } from '../../homemapdashboard/location-group-editor/location-group-editor.component';
import { UserPreferencesComponent } from 'app/pages/user-preferences/user-preferences.component';
import { LocationService } from 'app/shared/services/location.service';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { UsersService, USER_ROLES } from 'app/pages/admin/users.service';
import { AboutUsComponent } from 'app/pages/about-us/about-us.component';
import { BehaviorSubject } from 'rxjs';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { environment } from 'app/environments/environment';
import { SliicerMarketingScreenComponent } from '../../pages/sliicer/sliicer-marketing-screen/sliicer-marketing-screen.component';
import { SliicerService } from 'app/shared/services/sliicer.service';
import {
    TopnavSignalRMessage,
    NotificationState,
    SignalRMessageType,
    SignalRMessage,
    SliicerSignalRMessage,
    SliicerMessageType,
    VAULT_MOVE_SUCCESS_MESSAGE,
    VAULT_MOVE_FAIL_MESSAGE,
    SIGNALR_MESSAGE_PREFIX,
} from 'app/shared/models/signalr-message';
import { TritonLocationService } from 'app/shared/services/tritonLocation.service';
import { UserIdleService, UserIdleConfig } from 'angular-user-idle';
import { UserIdleComponent } from '../user-Idle/user-idle.component';
import { combineLatest, Subject } from 'rxjs';
import { IDLE_TIMEOUT_SECONDS, IDLE_MESSAGE_MINUTES } from 'app/shared/constant';
import { GISService } from 'app/shared/services/gis-service';
import { gisUserSettings } from 'app/shared/models/gis-service-list';
import { TranslateService } from '@ngx-translate/core';
import { UpdatesWidgetService } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.service';
import { UserSettings } from 'app/shared/models/user-settings';
import { map, take } from 'rxjs/operators';
import { ChangePasswordComponent } from 'app/public/change-password/change-password.component';
import {
    activeInactiveLocationQueryParam,
    AppQueryParams,
    Customer,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { LocationGroupArgs, LocationToolTip, ToggleLocation } from 'app/shared/models/navigation';
import { LocationGroup } from 'app/shared/models/location-group';
import { UserSubscription } from 'app/shared/models/notifications';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { GetPermissionsResponseCustomer, THEMES } from 'app/shared/models/users-permission';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ConfigService } from 'app/shared/services/config.service';
import { AssistanceCenterComponent } from 'app/shared/components/assistance-center/assistance-center.component';
import { ContactUsComponent } from 'app/shared/components/contact-us/contact-us.component';
import { MapService } from 'app/shared/services/map.service';
import { VaultService } from 'app/pages/vault';
import { UiService } from 'app/shared/services/ui.service';
import { NgxPopperjsContentComponent } from 'ng-popperjs';
import { Selectable } from 'app/shared/models/selectable';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { TrackBy } from 'app/shared/utils/track-by';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild(NgxPopperjsContentComponent, { static: true }) popper;
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    @Input() public customers: Array<Customer>;
    @Input() public locationGroups: Array<LocationGroup>;
    @Input() public currentRoute: string;
    @Output() public customerChange = new EventEmitter<number>();
    @Output() public locationEmitter = new EventEmitter<LocationGroupArgs>();
    @Output() public locationGroupChange = new EventEmitter<LocationGroupArgs>();
    public customerID: number;
    public locationGroupID: number;
    public isLoadingRoute = false;
    public pageTitle: string;
    public breadcrumbs = new Array<Breadcrumb>();
    public autoBreadcrumbs = true;
    public searchToggled = false;
    public alarmPreferences: { [cid: number]: UserSubscription[] } = {};
    public menuItemsObservable: Observable<Array<MenuItem>>;
    public locations: Locations;
    public locationsIDs: number[];
    public disableLocationGroupButton: boolean;
    public showTest: boolean;
    public notificationsAlarm: (TopnavSignalRMessage & { date: string })[] = [];
    public notificationsOther: TopnavSignalRMessage[] = [];
    public badgeCount = 0;
    public ringBell = false;
    public selectedCustomer: Customer;

    /**
     * Represents the state of the location group selector.
     */
    public isLocationGroupSelectDisabled: BehaviorSubject<boolean>;

    private browserTitle: string;
    private searchTerm = '';
    private menuItems = new Array<MenuItem>();
    private breadcrumbInterval: number;
    private pageTitleInterval: number;
    private subscriptions = new Array<Subscription>();

    /**
     * @toggleLocation - This will be default once app loading
     * (toggleLocation = 1) - show all locations (active and inactive)
     * (toggleLocation = 0) - show only active locations
     */
    private toggleLocation = ToggleLocation.Active;

    /**
     * Represents tooltip message for active and all (i.e. active and inactive) location
     */
    public inActiveLocationTooltip = LocationToolTip.All;

    /**
     * Represents user role
     */
    public userRole: string;

    /**
     * Represents active/inactive location state
     */
    public isInactiveLocations: boolean;

    /**
     * Customer editor permission
     */
    public isCustomerCanEdit: boolean;
    public isScheduleCollectAllowed: boolean;

    /**
     * enable page Hint
     */
    public showHintOption: boolean;

    public isDarkThemeEnabled: boolean;
    public availablePageHint = false;

    public isDataEditingAuditReportAllowed: boolean;
    public isEventEditorOnCustomerAllowed: boolean;
    public isAudit: boolean;
    public currentCustomerID: number;
    public dataEditingAccess: boolean;
    public registerUserAccess: boolean;
    private subscriptionsIdle = new Array<Subscription>();
    public disableHeaders$: BehaviorSubject<boolean>;
    public alarmType: { value: number; name: string }[];
    public dateFormat = 'yyyy/MM/dd';
    private userSettings: UserSettings;

    //auto complete
    public preselectedCusomer: Selectable;
    public somethingSelected = true;
    somethingSelectedName: Customer;


    public SliicerSignalRMessage = SliicerSignalRMessage;

    readonly PASSWORD_EXPIRATION_DAYS = 'passwordExpirationDays';
    public textCommonAll;

    private firstLoad = true;

    public trackByLocationGroup = TrackBy.byLocationGroupId;
    public trackByTitle = TrackBy.byUniqueKey<MenuItem>()('title');
    public trackByLink = TrackBy.byUniqueKey<Breadcrumb>()('link');
    public trackByTimestamp = TrackBy.byUniqueKey<TopnavSignalRMessage>()('timestamp');
    constructor(
        public navigation: NavigationService,
        public search: SearchService,
        private activatedRouter: ActivatedRoute,
        private title: Title,
        private authService: AuthService,
        private router: Router,
        private userIdleService: UserIdleService,
        private customerService: CustomerService,
        private locationGroupService: LocationGroupService,
        private dialog: MatDialog,
        private locationService: LocationService,
        private usersService: UsersService,
        private sharedService: SharedService,
        private statusCodeService: StatusCodeService,
        private domOperationUtilsService: DomOperationUtilsService,
        private changeDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private sliicerService: SliicerService,
        private tritonLocationService: TritonLocationService,
        public signalRService: SignalRService,
        public gisService: GISService,
        public translateService: TranslateService,
        private dateutilService: DateutilService,
        public updatesWidgetService: UpdatesWidgetService,
        private configService: ConfigService,
        private mapService: MapService,
        private vaultService: VaultService,
        public uiService: UiService,
        public sepWinService: SeparateWindowHydrographService
    ) {
        this.disableHeaders$ = this.locationService.disableHeaderButtons$;

        this.subscriptions.push(
            this.router.events.subscribe(() => {
                this.updatePageTitle();
                this.showHideHintOption();
            }),
        );

        const keys = ['COMMON.HIGH_LEVEL_ALARM', 'COMMON.HIGH_HIGH_ALARAM', 'COMMON.OVERFLOW_ALARM', 'COMMON.ALL_TEXT'];
        translateService.get(keys).subscribe((translateValues) => {
            this.alarmType = [
                { value: 6610, name: translateValues['COMMON.HIGH_LEVEL_ALARM'] },
                { value: 6609, name: translateValues['COMMON.HIGH_HIGH_ALARAM'] },
                { value: 6631, name: translateValues['COMMON.OVERFLOW_ALARM'] },
            ];
            this.textCommonAll = translateValues['COMMON.ALL_TEXT'];
        });

        this.dateutilService.dateFormat.subscribe(() => {
            this.dateFormat = this.dateutilService.getDateFormat();
        });
    }

    /**
     * Framework level lifecycle hook.
     */
    public ngOnChanges(changes: SimpleChanges) {
        if (!changes.customers || !changes.customers.firstChange) {
            if(!(changes.customers?.currentValue === undefined)){
                this.selectedCustomer = changes.customers.currentValue.find((customer) => customer.customerID === this.customerID);
            }

            if (!this.selectedCustomer && changes.customers && changes.customers.currentValue && changes.customers.currentValue.length) {
                this.onCustomerChange([changes.customers.currentValue[0]]);
            }
            return;
        }
        this.subscriptions.push(
            this.statusCodeService.userInfo.subscribe((result) => {
                if (Object.keys(result).length !== 0) {
                    this.userRole = result.userRole[0];
                    this.loadUserPreferenceCustomer();
                    this.updatePageTitle();
                    if (changes.customers) {
                        if (changes.customers.firstChange) {
                            this.setupMainDropDowns(true);
                        } else {
                            this.setupMainDropDowns();
                        }
                    }
                }
            }),
        );
    }

    private addAlarm(res: TopnavSignalRMessage) {
        let date: string;
        if (!res.timestamp) {
            date = this.parseTimestampFromMessage(res.message);
        } else {
            date = res.timestamp.toString();
        }
        this.notificationsAlarm.unshift({ ...res, date });
        this.badgeCount++;
    }

    private parseTimestampFromMessage(msg: string): string {
        const captureGroup =
            /(([0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1]))|((0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4})|((0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/[0-9]{4})) ([2-9]|1[0-2]):[0-5][0-9]:[0-5][0-9] (AM|PM)/.exec(
                msg,
            );
        if (captureGroup && captureGroup.length) {
            return captureGroup[0];
        }
    }

    /**
     * Framework level lifecycle hook.
     */
    public ngOnInit() {
        this.translateService.setDefaultLang('en-us');
        this.translateService.use('en-us');
        this.getUserSettings();

        const signalRSub = this.setupSignalR();
        this.subscriptions.push(signalRSub);

        this.subscriptions.push(
            this.activatedRouter.queryParamMap.subscribe((params: ParamMap) => {
                // get updated customer id
                this.currentCustomerID = Number(params.get(customerQueryParam));
                const locationGroupID = Number(params.get(customerLocationGroupQueryParam));
                const includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
                this.updateCustomerDropDown(this.currentCustomerID, locationGroupID, includeInactiveLocations);

                if (this.firstLoad && this.customerID) {
                    this.firstLoad = false;
                    this.startIdle(this.customerID);
                }
            }),
        );

        this.subscriptions.push(
            this.usersService.passwordExpirationDays.subscribe((passwordExpirationDays) => {
                if (passwordExpirationDays && passwordExpirationDays < 7) {
                    const topnavData: TopnavSignalRMessage = {
                        type: SignalRMessageType.Alarm,
                        message: this.PASSWORD_EXPIRATION_DAYS,
                        detail: `Your password is going to expire in ${passwordExpirationDays} days`,
                        alarmType: 11111, // Make sure
                        state: NotificationState.UNREAD,
                    };

                    this.notificationsOther.push(topnavData);

                    this.turnRingBell();
                }
            }),
        );

        this.configService.loadLocationId.subscribe((val) => {
            this.onChangeLocationGroup(val);
        });

        if (this.firstLoad && this.customerID) {
            this.firstLoad = false;
            this.startIdle(this.customerID);
        }
        if (this.customerID) {
            localStorage.setItem('CustomerId', this.customerID.toString());
        }

        // Start watch when time is up.
        this.subscriptionsIdle.push(
            this.userIdleService.onTimeout().subscribe(() => {
                this.dialog.closeAll();
                if (this.authService.isAuthenticated || this.authService.isAuthenticatedNormal) {
                    this.authService.logout();
                } else {
                    this.usersService.refreshToken().subscribe((res) => {
                        if (res['token']) {
                            sessionStorage.setItem('adsToken', res['token']);
                        }
                    });
                    this.userIdleService.stopTimer();
                }
            }),
        );

        // Subscribing to the result value from permission API
        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            this.isDarkThemeEnabled = response;
        });

        // Checks if page hint is available
        this.subscriptions.push(
            this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
                this.availablePageHint = result;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            }),
        );

        // check if data editing is accesible.
        this.subscriptions.push(
            this.usersService.isDataEditingAuditReportAllowed.subscribe((allowed) => {
                this.dataEditingAccess = allowed;
            }),
        );

        this.subscriptions.push(
            this.usersService.isRegisterUserFeatureAllowed.subscribe((allowed) => {
                this.registerUserAccess = allowed;
            }),
        );

        // check permission for customer editor
        this.subscriptions.push(
            this.usersService.isCustomerEditor.subscribe((response) => {
                this.isCustomerCanEdit = response;
            }),
        );
        this.subscriptions.push(
            this.usersService.isScheduleCollectAllowed.subscribe((response) => {
                this.isScheduleCollectAllowed = response;
            }),
        );
        // setup the state of the location group dropdown - default is enabled
        this.isLocationGroupSelectDisabled = this.locationGroupService.isLocationGroupDisabled;
        this.isDataEditingAuditReportAllowed = this.usersService.isDataEditingAuditReportAllowed.getValue();

        // check if event editor is allowed
        this.subscriptions.push(
            this.usersService.isEventEditorOnCustomerAllowed.subscribe((response) => {
                this.isEventEditorOnCustomerAllowed = response.value;
                this.loadUserPreferenceCustomer();
            }),
        );

        this.loadUserPreferenceCustomer();
        this.setupMainDropDowns();

        this.subscriptions.push(
            this.navigation.getIsRouteLoading.subscribe((isRouteLoading) => (this.isLoadingRoute = isRouteLoading)),
        );

        this.subscriptions.push(
            this.navigation.getMenuItems.subscribe(() => {
                if (this.autoBreadcrumbs) {
                    this.updateAutoBreadcrumbs();
                }
                this.updatePageTitle();
            }),
        );

        this.subscriptions.push(
            this.navigation.getBreadCrumbs.subscribe((breadcrumbs) => {
                if (breadcrumbs !== null) {
                    window.clearInterval(this.breadcrumbInterval);
                    this.autoBreadcrumbs = false;
                    this.breadcrumbs = breadcrumbs;
                } else {
                    if (this.isLoadingRoute) {
                        // this.breadcrumbInterval = window.setInterval(() => {
                        //     if (!this.isLoadingRoute) {
                        //         window.clearInterval(this.breadcrumbInterval);
                        //         this.updateAutoBreadcrumbs();
                        //     }
                        // });
                    } else {
                        window.clearInterval(this.breadcrumbInterval);
                        this.updateAutoBreadcrumbs();
                    }
                }
            }),
        );

        this.subscriptions.push(
            this.navigation.getPageTitle.subscribe((pageTitle) => {
                if (pageTitle !== null) {
                    window.clearInterval(this.pageTitleInterval);
                    this.pageTitle = pageTitle;
                    if (this.browserTitle === null) {
                        this.title.setTitle(this.navigation.getAutoBrowserTitle(pageTitle));
                    }
                } else {
                    if (this.isLoadingRoute) {
                        // this.pageTitleInterval = window.setInterval(() => {
                        //     if (!this.isLoadingRoute) {
                        //         window.clearInterval(this.pageTitleInterval);
                        //         this.updatePageTitle();
                        //     }
                        // });
                    } else {
                        window.clearInterval(this.breadcrumbInterval);
                        this.updatePageTitle();
                    }
                }
            }),
        );

        this.subscriptions.push(
            this.navigation.getBrowserTitle.subscribe((browserTitle) => {
                this.browserTitle = browserTitle;
                if (browserTitle !== null) {
                    this.title.setTitle(browserTitle);
                } else {
                    this.title.setTitle(this.navigation.getAutoBrowserTitle(this.pageTitle));
                }
            }),
        );

        this.subscriptions.push(
            this.activatedRouter.queryParamMap.subscribe((params: ParamMap) => {
                const includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

                // sets the tooltip for global location icon
                if (includeInactiveLocations) {
                    this.inActiveLocationTooltip = LocationToolTip.Active;
                    this.toggleLocation = ToggleLocation.All;

                    // when active-inactive handler is enabled set the value of isInactiveLocation to true
                    this.statusCodeService.activeInactiveHandler.next(true);
                } else {
                    this.inActiveLocationTooltip = LocationToolTip.All;
                    this.toggleLocation = ToggleLocation.Active;

                    // when active-inactive handler is enabled set the value of isInactiveLocation to false
                    this.statusCodeService.activeInactiveHandler.next(false);
                }
            }),
        );
        // this.loadGisUserSettings();
    }

    // public loadGisUserSettings() {
    //     this.gisService.getGisUserSettings().subscribe((res: any) => {
    //         if (res != null) {
    //             this.gisUserSettings = res;
    //             this.isInactiveLocations = res.locationsIsActive;
    //             this.customerID = res.customerId;
    //             if (this.locationGroupID != res.selectedLocationGroupId) {
    //                 this.locationGroupID = res.selectedLocationGroupId;
    //                 this.onChangeLocationGroup(this.locationGroupID);
    //             }

    //         }
    //     });
    // }

    public openResetPassword() {
        this.dialog.open(ChangePasswordComponent, {
            disableClose: true,
            data: {
                userName: this.usersService.userName,
                isForgotPassword: false,
            },
        });
    }

    /**
     * Set up the SignalR subscription, handling all messages
     */
    private setupSignalR() {
        return this.signalRService.messageReceived.subscribe(async (res: SignalRMessage) => {
            // Make sure we have data and that we have a customer id.
            if (!res || !res.cid) {
                return;
            }

            // If we are of type User, make sure that we only display
            // for the correct user.
            if (res.type === SignalRMessageType.User && res.message !== VAULT_MOVE_SUCCESS_MESSAGE && res.message !== VAULT_MOVE_FAIL_MESSAGE) {
                if (!this.checkUser(res)) {
                    return;
                }
            }

            // Set up the data for the top nav alarm widget
            const topnavData: TopnavSignalRMessage = {
                ...res,
                state: NotificationState.UNREAD,
            };

            // Do we want to add location name and make a check on the customer id?
            // Only SLiiCER messages say no.
            let moreDataAndChecks = res.message !== SliicerSignalRMessage.SLIICER_MESSAGE;

            // Do we want to add to the ALARM tab or the OTHERS tab?
            const toAlarms = res.type === SignalRMessageType.Alarm;

            if (res.type === SignalRMessageType.Alarm) {
                // No addtional checks needed.
            } else if (res.type === SignalRMessageType.User && res.message !== VAULT_MOVE_SUCCESS_MESSAGE && res.message !== VAULT_MOVE_FAIL_MESSAGE) {
                if (res.message === SliicerSignalRMessage.SLIICER_MESSAGE) {
                    const sliicerMsg = res as SliicerSignalRMessage;

                    // The only SLiiCER Signal-R message we are handling right now are data export complete.
                    if (sliicerMsg.messageType !== SliicerMessageType.ExportComplete) {
                        return;
                    }

                    /*
                         * BUG 19489: do not de-duplicate messages because we can export multiple times
                        // Check for uniqueness of export complete messages.
                        if (this.notificationsOther.some((n: TopnavSignalRMessage) => {
                            return n.message === sliicerMsg.message &&
                                n.cid === sliicerMsg.cid &&
                                n.caseStudyId === sliicerMsg.caseStudyId;
                        })) {
                            return;
                        }
                        */
                } else if (res.message.includes('FlowMonitorReport')) {
                    // Check for uniqueness of flow monitor report messages.
                    if (this.notificationsOther.some((notification) => notification.message === res.message)) {
                        return;
                    }
                    topnavData.fileName = this.getFileName(res);
                    this.vaultService.reportGenerated.next(res);
                } else if(res.filePath) {
                    // Export Vault, LD, LD details, HD
                    moreDataAndChecks = false;
                } else {
                    // We do not handle any other User messages ATM.
                    return;
                }
            } else {
                // No additional checks needed.
            }

            // Add any more information that we need to the top nav data.
            if (moreDataAndChecks) {
                if (!res.lid || res.cid !== (await this.customerID)) {
                    return;
                }
                // Add the location name to the Topnav data.
                const locationData = await this.getLocationDetails(res.cid, res.lid);
                topnavData.locationName = locationData.locationName;
            }
            const customerData = await this.getCustomerDetails(res.cid);
            topnavData.customerName = customerData.customerName;

            if (topnavData.success === undefined || topnavData.success === null) {
                // topnavData.success = !topnavData.message || (topnavData.message && !topnavData.message.endsWith('failed'));
                topnavData.success = !topnavData.message || (topnavData.message && !topnavData.message.startsWith(SIGNALR_MESSAGE_PREFIX.Failure));
            }

            // Add the data to the UI
            if (toAlarms) {
                this.addAlarm(topnavData);
            } else {
                this.addOtherNotification(topnavData);
            }

            // If we get here, then we definitely want to ring the bell.
            this.turnRingBell();
        });
    }

    private turnRingBell() {
        this.ringBell = true;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);

        // And we want to turn the bell off after a while.
        setTimeout(() => {
            this.ringBell = false;
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        }, 1000);
    }

    private addOtherNotification(notification: TopnavSignalRMessage) {
        this.notificationsOther.unshift(notification);
        this.badgeCount++;
    }

    /**
     * Method to check if SignalR message sent is intend to be received by the logged in user
     * @param messageData
     */
    public checkUser(messageData: SignalRMessage): boolean {
        if (messageData.userId) {
            const userId = sessionStorage.getItem('userId');
            if (userId === messageData.userId) {
                return true;
            }
        } else if (messageData.userName) {
            if (this.user.email === messageData.userName) {
                return true;
            }
        }
        return false;
    }

    public onChange(e: MatSlideToggleChange) {
        if (e.checked === true) {
            this.isDarkThemeEnabled = true;
            //   this.statusCodeService.toggleTheme();
            this.statusCodeService.userInfoThemeBS.next(true);
            this.domOperationUtilsService.setCustomTheme(THEMES.themeColorDark);
        } else {
            this.isDarkThemeEnabled = false;
            //  this.statusCodeService.toggleTheme();
            this.statusCodeService.userInfoThemeBS.next(false);
            this.domOperationUtilsService.setCustomTheme(THEMES.themeColorLight);
        }
        this.statusCodeService.isThemeChanged.next(true);
        // reload current page
        const currentPage = this.router.url.toString();
        this.router.navigateByUrl(currentPage);
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public openNotifications() {
        this.tabGroup.selectedIndex = 0;
        this.badgeCount = 0;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public readNotification(item, notifications) {
        const index = notifications.indexOf(item);
        if (index > -1) {
            notifications.splice(index, 1);
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        }
    }

    private startIdle(customerId?: number) {
        // #38792 customerId equal to undefined or null is invalid.
        if(customerId === undefined || customerId === null) return;

        const customerSub = this.customerService.getCustomerById(customerId).subscribe((customer) => {
            const idleMinutes = customer && customer.idleTimeOut ? customer.idleTimeOut : IDLE_MESSAGE_MINUTES;
            const timeout = IDLE_TIMEOUT_SECONDS;

            const userIdleConfig: UserIdleConfig = {
                idle: idleMinutes * 60,
                timeout,
            };
            this.subscriptionsIdle.forEach((sub) => {
                sub.unsubscribe();
            });
            this.userIdleService.stopWatching();
            this.userIdleService.setConfigValues(userIdleConfig);
            this.userIdleService.startWatching();
            // Start watching when user idle is starting.
            const timer = new Subject();
            const timeStartSub = this.userIdleService.onTimerStart().subscribe((count) => {
                timer.next(count);
                if (count === 1) {
                    // idle timer starts, open dialog
                    if (this.authService.isAuthenticated || this.authService.isAuthenticatedNormal) {
                        this.subscriptions.push(
                            this.dialog
                                .open(UserIdleComponent, {
                                    disableClose: true,
                                    panelClass: 'no-padding-nor-overflow-dialog',
                                    data: { timer },
                                })
                                .afterClosed()
                                .subscribe(() => {
                                    this.usersService.refreshToken().subscribe((res) => {
                                        if (res['token']) {
                                            sessionStorage.setItem('adsToken', res['token']);
                                        }
                                    });
                                    this.userIdleService.resetTimer();
                                }),
                        );
                    }
                }
            });

            this.subscriptionsIdle.push(timeStartSub);

            const timeoutSub = this.userIdleService.onTimeout().subscribe(() => {
                this.dialog.closeAll();
                this.authService.idleLogout = true;
                this.authService.logout(true);
                this.userIdleService.stopTimer();
            });

            this.subscriptionsIdle.push(timeoutSub);
        });

        this.subscriptions.push(customerSub);
    }

    public getCustomerNotifications(customerId: number): Observable<Array<UserSubscription>> {
        return this.usersService.getAlarmUserPreferenceNotification(customerId).pipe(
            map((response: UserSubscription[]) => {
                if (!response) {
                    return new Array<UserSubscription>();
                }
                return response;
            }),
        );
    }

    public async checkNotificationPermissions(cid: number, alarmType: number) {
        if (!this.alarmPreferences[cid]) {
            await new Promise<string>((resolve) => {
                this.subscriptions.push(
                    this.usersService.getAlarmUserPreferenceNotification(cid).subscribe((res) => {
                        this.alarmPreferences[cid] = res;
                        resolve(null);
                    }),
                );
            });
        }
        return this.alarmPreferences[cid] && this.alarmPreferences[cid].some((i) => i.SubscriptionType === alarmType);
    }

    public showHideHintOption() {
        this.showHintOption = false;
        this.enableHintOption();
    }

    public getLocationDetails(cid: number, lid: number) {
        return this.tritonLocationService.getLocationDetails(cid, lid).toPromise();
    }

    public getCustomerDetails(cid: number) {
        return this.customerService.getCustomerById(cid).toPromise();
    }

    public enableHintOption() {
        const url = this.router.url;
        switch (true) {
            case url.indexOf('viewData') > -1:
                // to do for Location Dash Board Page
                this.showHintOption = true;
                if (url.indexOf('advancedHydrograph') > -1) {
                    this.showHintOption = false;
                } else {
                    this.showHintOption = true;
                }
                break;
            case url.indexOf('dashboard') > -1:
            case url.indexOf('schedule-collect') > -1:
            case url.indexOf('viewLocationDetails') > -1:
            case url.indexOf('vault') > -1:
            case url.indexOf('report') > -1:
            case url.indexOf('admin') > -1:
            case url.indexOf('menuDashboard/notifications') > -1:
            case url.indexOf('pages/customers') > -1:
            case url.indexOf('pages/customerEditor/details') > -1:
                this.showHintOption = true;
                break;
            default:
                this.showHintOption = false;
        }
    }

    /**
     * Framework level lifecycle hook.
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
        this.subscriptionsIdle.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    public contactUs() {
        this.dialog.open(ContactUsComponent, {
            disableClose: true,
            data: {},
        });
    }

    public AssistanceCenter(selectedTab: string, selectedTabName: string) {
        this.dialog.open(AssistanceCenterComponent, {
            disableClose: true,
            data: {
                selectedTab: selectedTab,
                selectedTabName: selectedTabName,
            },
        });
    }

    public openOpenMarketScreen() {
        this.dialog.open(SliicerMarketingScreenComponent, {
            disableClose: true,
            data: {},
        });
    }

    // to check if user is on welcome page or not
    public get isCurrentPageHome() {
        return window.location.pathname.toString().toLowerCase().search('welcome') >= 0 ? true : false;
    }

    public get user() {
        return this.authService.user;
    }

    public login() {
        this.authService.login();
    }

    public logOut() {
        this.authService.logout();
    }

    public get getSearchTerm(): string {
        return this.searchTerm;
    }

    public set setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
        this.search.updateSearchTerm(searchTerm);
    }

    /**
     * Handles the location group selection change event.
     * @param locationGroupID The surrogate identifier for a location group.
     */
    public async onChangeLocationGroup(locationGroupID: number) {
        // set current location group selection
        this.locationGroupID = locationGroupID;
        this.uiUtilsService.locationGroupID = locationGroupID;

        // emit changes to location group selection
        this.locationGroupChange.emit(<LocationGroupArgs>{
            customerId: this.customerID,
            locationGroupId: locationGroupID,
            activeAllFlag: this.toggleLocation,
        });

        // to clear persist location on hydrograph and hydrograph details page
        this.statusCodeService.currentLocationId.next(null);

        // track location group change from globle customer dropdown
        this.statusCodeService.isLocationGroupChanged.next(true);

        // loading user setting
        /*if(this.locationGroupID != this.gisUserSettings.selectedLocationGroupId){
            this.loadGisUserSettings();
        }*/

        const userSettings = await this.gisService.gisUserSettingsGet();
        const affectedCustomer = userSettings.customerSettings
            ? userSettings.customerSettings.find((x) => x.cid === this.customerID)
            : null;
        if (affectedCustomer) {
            affectedCustomer.lastViewedLocationGroup = locationGroupID;
        } else if (userSettings.customerSettings) {
            userSettings.customerSettings.push({
                cid: this.customerID,
                lastViewedExtents: [],
                lastViewedLocationGroup: locationGroupID,
                layers: [],
            });
        }

        if (userSettings.customerSettings) {
            this.updateUserSettings(userSettings);
        }
    }

    /**
     * Handles the global location change event to display active or all locations
     */
    public async activeInactiveLocationHandler() {
        this.isInactiveLocations = !this.isInactiveLocations;

        const userSettings = await this.gisService.gisUserSettingsGet();
        this.updateUserSettings({ ...userSettings, locationsIsActive: this.isInactiveLocations });
        // sets the tooltip for global location icon
        if (this.isInactiveLocations) {
            this.inActiveLocationTooltip = LocationToolTip.Active;

            // when active-inactive handler is enabled set the value of isInactiveLocation to true
            this.statusCodeService.activeInactiveHandler.next(true);
        } else {
            this.inActiveLocationTooltip = LocationToolTip.All;

            // when active-inactive handler is enabled set the value of isInactiveLocation to false
            this.statusCodeService.activeInactiveHandler.next(false);
        }

        // sets the value of toggleLocation field based on location change event
        if (this.toggleLocation === ToggleLocation.All) {
            this.toggleLocation = ToggleLocation.Active;
        } else if (this.toggleLocation === ToggleLocation.Active) {
            this.toggleLocation = ToggleLocation.All;
        }

        // emit changes to global location selection
        this.locationEmitter.emit(<LocationGroupArgs>{
            customerId: this.customerID,
            locationGroupId: this.locationGroupID,
            activeAllFlag: this.toggleLocation,
            params:
                this.activatedRouter && this.activatedRouter.snapshot
                    ? this.activatedRouter.snapshot.queryParams
                    : null,
        });
    }

    private updateUserSettings(userSettings: gisUserSettings) {
        userSettings.lastViewedCustomerId = this.customerID;
        userSettings.locationsIsActive = this.isInactiveLocations;
        this.gisService.updateGisUserSettings(userSettings).subscribe();
    }

    /**
     * Handles the customer selection change event.
     * @param customerId The surrogate identifier for a customer.
     */
    public async onCustomerChange(selectedCustomers: Customer[]) {
        if (!selectedCustomers || !selectedCustomers.length) return;
        const customerId = selectedCustomers[0].customerID;

        // set current customer selection
        this.customerID = customerId;

        // Update the saved customer ID
        const userSettings = await this.gisService.gisUserSettingsGet();

        this.updateUserSettings({ ...userSettings, lastViewedCustomerId: customerId });

        this.customerChange.emit(customerId);

        // to clear persist location on hydrograph and hydrograph details page
        this.statusCodeService.currentLocationId.next(null);

        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        // to clear persist timespan on hydrograph and hydrograph details page
        this.statusCodeService.timeInterval.next(null);
        if (userSettings != null) {
            const affectedCustomer = userSettings.customerSettings.find((x) => x.cid === customerId);
            let locationGroup = 0;
            if (affectedCustomer) {
                locationGroup = affectedCustomer.lastViewedLocationGroup;
            }
            this.locationGroupID = locationGroup;
            this.isInactiveLocations = userSettings.locationsIsActive;
            this.onChangeLocationGroup(locationGroup);
        } else {
            this.isInactiveLocations = false;
            this.onChangeLocationGroup(0);
        }
        // track customer change from globle customer dropdown
        this.statusCodeService.isCustomerChanged.next(true);
        this.loadUserPreferenceCustomer();

        this.startIdle(customerId);
    }
    // emit customer change
    //const userSettings: gisUserSettings = { ... this.gisService.userSettings, locationsIsActive: this.isInactiveLocations }
    //this.updateUserSettings(userSettings);
    /*if(this.gisUserSettings){
            if( this.customerID != this.gisUserSettings.customerId){
                this.loadGisUserSettings();
            }
        }else{
            this.loadGisUserSettings();
        }*/

    /*
       // this.customerChange.emit(customerId);
        // to clear persist location on hydrograph and hydrograph details page
        this.statusCodeService.currentLocationId.next(null);

        // to clear persist timespan on hydrograph and hydrograph details page
        this.statusCodeService.timeInterval.next(null);
        //  set location group to 0
        this.onChangeLocationGroup(0);
        // track customer change from globle customer dropdown
        this.statusCodeService.isCustomerChanged.next(true);
        this.loadUserPreferenceCustomer();

        this.startIdle(customerId);*/

    /**
     *  Method which opens location group editor pop up
     */
    public updateLocation(customerId, groupId?, showInactive?) {
        this.customerID = customerId;
        this.openLocationGroup(groupId, showInactive);
    }

    public openLocationGroup(groupId?, showInactive?) {
        this.statusCodeService.toggleTheme();
        // set to default state of false for location group close button
        this.sharedService.closeLocationGroup(false);

        // Disable the location group button
        this.disableLocationGroupButton = true;

        const inactive = showInactive || Boolean(this.toggleLocation)

        // intialize location parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerID,
            IncludeInactiveLocations: inactive,
            locationGroupId: 0,
        };

        const locationsObservable: Observable<Locations[]> = this.usersService.getLocationsList(locationArgs);

        const locationGroupsObservable: Observable<LocationGroup[]> = this.locationGroupService
            .getLocationGroups(this.customerID)
            .pipe(
                map((locationGroups: { [key: string]: Array<LocationGroup> }) => {
                    if (locationGroups) {
                        return locationGroups.locationGroups;
                    } else {
                        EMPTY;
                    }
                }),
            );

        const locationsAndLocationGroupsSubscription = forkJoin(
            locationsObservable,
            locationGroupsObservable,
        ).subscribe((result: [Array<Locations>, Array<LocationGroup>]) => {
            // Filter the location group based on location name
            const filterPipe = new OrderByPipe();
            this.locationGroups = filterPipe.transform(result[1], 'name', false);
            let locationList;
            if (this.isInactiveLocations || !!showInactive) {
                locationList = result[0].filter((x) => x.viewable);
            } else {
                locationList = result[0].filter(
                    (x) =>
                        x.viewable && (x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance),
                );
            }

            // Open location group editor dialog
            const locationGroupDialogSubscription = this.dialog
                .open(LocationGroupEditorComponent, {
                    disableClose: true,
                    data: {
                        locations: locationList,
                        locationGroups: this.locationGroups,
                        selectedGroupId: groupId,
                    },
                })
                .afterClosed()
                .subscribe((response) => {
                    // Enable the location group button
                    this.disableLocationGroupButton = false;
                    this.loadLocationGroups();
                });
            this.subscriptions.push(locationGroupDialogSubscription);
        });
        this.subscriptions.push(locationsAndLocationGroupsSubscription);
    }
    /**
     * open About Us editor pop up
     */
    public openAboutUs() {
        this.dialog.open(AboutUsComponent, {
            disableClose: true,
        });
    }

    /**
     * open User Preferences editor pop up
     */
    public openUserPreferences() {
        this.dialog.open(UserPreferencesComponent, {
            disableClose: true,
            data: {
                user: this.user.name,
            },
        });
    }
    /**
     * open Customer Editor editor
     */
    public openCustomerEditor() {
        // get current query params for customer
        const currentCustomerID = Number(this.activatedRouter.snapshot.queryParamMap.get(customerQueryParam));

        // get current query params for location group
        const locationGroupId = Number(
            this.activatedRouter.snapshot.queryParamMap.get(customerLocationGroupQueryParam),
        );

        // get current query params for showing all locations
        const isShowAllLocations = Number(
            this.activatedRouter.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
        );

        // get global query params
        const appQueryParams = <AppQueryParams>{
            c: currentCustomerID,
            lg: locationGroupId || undefined,
            lt: isShowAllLocations || undefined,
        };

        // navigate
        this.router.navigate(['/pages/customers'], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRouter,
        });
    }

    public loadLocationGroups() {
        const subscription = this.locationGroupService.getLocationGroups(this.customerID).subscribe(
            (result) => {
                if (result && result.locationGroups) {
                    this.locationGroups = result.locationGroups;
                    const filterPipe = new OrderByPipe();
                    this.locationGroups = filterPipe.transform(this.locationGroups, 'name', false);

                    if (
                        !this.locationGroups.some((v) => v.locationGroupID === this.locationGroupID) &&
                        this.locationGroupID !== 0
                    ) {
                        this.onChangeLocationGroup(0);
                    }
                } else {
                    this.locationGroups = [];
                    if (this.locationGroupID !== 0) {
                        this.onChangeLocationGroup(0);
                    }
                }
            },
            (error) => this.handleError(error),
        );
        this.subscriptions.push(subscription);
    }

    public handleError(error: Error) {
        this.locationGroups = [];
    }

    public toggleSearch(input: HTMLInputElement) {
        this.searchToggled = !this.searchToggled;
        if (this.searchToggled) {
            input.focus();
        }
    }

    public searchBlur() {
        if (StringUtils.isEmpty(this.searchTerm)) {
            this.searchToggled = false;
        }
    }

    /**
     * Responsible for setting up the customer and location group drop down menus.
     */
    private setupMainDropDowns(intialLoad?: boolean): void {
        // ensure customers is filled
        if (this.customers != null) {
            const temp = this.customerID;

            // find selected customer
            const chosenCustomer = this.customers.find(
                (customer: GetPermissionsResponseCustomer) => customer.isCurrentlySelected,
            );
            // set current customer to be preselected customer from previous change or the current customers first customer
            if (chosenCustomer) {
                if (chosenCustomer.customer !== undefined && chosenCustomer.customer.customerID) {
                    this.customerID = chosenCustomer.customer.customerID;
                    this.selectedCustomer = chosenCustomer.customer.customerID;
                } else {
                    this.customerID = chosenCustomer.customerID;
                    this.selectedCustomer = chosenCustomer.customer.customerID;
                }
            } else {
                this.customerID = this.customers[0].customerID;
                this.selectedCustomer = this.customers[0].customer.customerID;
            }

            //  if a new customer is selected after the application is loaded
            if (this.customerID !== temp && !intialLoad) {
                this.customerChange.emit(this.customerID);
            }
        }

        // ensure location groups are filled
        if (this.locationGroups != null) {
            const filterPipe = new OrderByPipe();
            this.locationGroups = filterPipe.transform(this.locationGroups, 'name', false);
            // find selected group
            const chosenLocationGroup = this.locationGroups.find(
                (locationGroup: LocationGroup) => locationGroup.isCurrentlySelected,
            );

            // set current location group to previously selected group or use the first location group found
            this.locationGroupID = chosenLocationGroup != undefined ? chosenLocationGroup.locationGroupID : 0;
        }
    }

    private updateAutoBreadcrumbs() {
        this.subscriptions.push(
            this.navigation.getCurrentRoute.pipe(take(1)).subscribe((currentRoute) => {
                this.autoBreadcrumbs = true;
                this.breadcrumbs = this.navigation.getAutoBreadcrumbs(currentRoute);
            }),
        );
    }

    private updatePageTitle() {
        this.subscriptions.push(
            this.navigation.getCurrentRoute.pipe(take(1)).subscribe((currentRoute) => {
                this.pageTitle = this.navigation.getAutoPageTitle(currentRoute);
                if (this.browserTitle === null) {
                    this.title.setTitle(this.navigation.getAutoBrowserTitle(this.pageTitle));
                }
            }),
        );
    }

    private getUserSettings() {
        this.usersService.getUserSettings().subscribe((data) => {
            this.userSettings = data;
            this.usersService.userSettings.next(data);
        });
    }

    /**
     * set customer as per selected user preference
     */
    private loadUserPreferenceCustomer() {
        this.subscriptions.push(
            this.customerService.userCustomersWithLocations.subscribe(
                (customers) => {
                    if (!customers) {
                        return;
                    }
                    this.setMenuItems();

                    // menu item setup based on user role
                    this.subscriptions.push(
                        combineLatest([this.menuItemsObservable, this.usersService.isAdvancedReportsAllowed]).subscribe(
                            ([menuItems, isAdvancedReportsAllowed]) => {
                                this.menuItems = menuItems;
                                const isAdmin = this.userRole === USER_ROLES.ADMIN;
                                const isCustAdmin = this.userRole === USER_ROLES.CUSTOMER_ADMIN;
                                const isCustomerUserManager = this.userRole === USER_ROLES.CUSTOMER_USER_MANAGER;
                                if (this.userRole) {
                                    this.menuItems.find((x) => x.title === 'NAVIGATION.ADMIN').isAdmin =
                                        isAdmin || (isCustAdmin && this.registerUserAccess);

                                    this.menuItems.find((x) => x.title === 'NAVIGATION.ADMIN').children[0].isAdmin =
                                        isAdmin;

                                    const blockagePredict = this.menuItems.find((x) => x.title === 'NAVIGATION.ADMIN').children[1];

                                    blockagePredict.isAdmin = isAdmin;
                                    blockagePredict.children.forEach(v => v.isAdmin = isAdmin);

                                    const reports = this.menuItems.find((x) => x.title === 'NAVIGATION.ADMIN').children[2];
                                    reports.isAdmin = isAdmin;
                                    reports.children.forEach(v => v.isAdmin = isAdmin);

                                    // condition for Admin/Users
                                    this.menuItems.find((x) => x.title === 'NAVIGATION.ADMIN').children[3].isAdmin =
                                        isAdmin || (isCustAdmin && this.registerUserAccess);

                                    this.menuItems.find((x) => x.title === 'NAVIGATION.CUSTOMER_USER_MANAGER').isAdmin =
                                        isCustomerUserManager;
                                } else {
                                    this.menuItems.find((x) => x.title === 'NAVIGATION.ADMIN').isAdmin =
                                        this.userRole === USER_ROLES.ADMIN ||
                                        this.userRole === USER_ROLES.CUSTOMER_ADMIN;

                                    this.setupRoute();
                                }
                                const reportMenuItems = this.menuItems.find((x) => x.title === 'NAVIGATION.REPORTS');
                                if (reportMenuItems) {
                                    // Hide these in production no matter what (for now)
                                    reportMenuItems.children.find((x) => x.title === 'NAVIGATION.FLOW_MONITORING_REPORT').show =
                                        isAdvancedReportsAllowed.value;

                                        reportMenuItems.children.find((x) => x.title === 'NAVIGATION.FLOW_MONITORING_REPORT').children[0].isAdmin = true;
                                        reportMenuItems.children.find((x) => x.title === 'NAVIGATION.FLOW_MONITORING_REPORT').children[1].isAdmin = this.userRole === USER_ROLES.ADMIN;

                                        reportMenuItems.children.find((x) => x.title === 'NAVIGATION.FLOW_MONITORING_REPORT').children[0].show = true;
                                        reportMenuItems.children.find((x) => x.title === 'NAVIGATION.FLOW_MONITORING_REPORT').children[1].show = this.userRole === USER_ROLES.ADMIN;

                                    reportMenuItems.children.find(
                                        (x) => x.title === 'NAVIGATION.DATA_EDITING_AUDIT_REPORT',
                                    ).show = this.isDataEditingAuditReportAllowed;

                                    reportMenuItems.children.find(
                                        (x) => x.title === 'NAVIGATION.RAIN_FALL_FREQUENCY',
                                    ).show = isAdvancedReportsAllowed.value && this.isEventEditorOnCustomerAllowed;

                                    reportMenuItems.children.find((x) => x.title === 'NAVIGATION.AUDIT_LOG').show =
                                        isAdvancedReportsAllowed.value;

                                    reportMenuItems.isAdmin = true;
                                    (reportMenuItems.children || []).forEach((child) => (child.isAdmin = true));
                                }

                                this.menuItems.find((x) => x.title === 'NAVIGATION.HOME').isAdmin = true;
                                const dashboardMenuItems = this.menuItems.find(
                                    (x) => x.title === 'NAVIGATION.DASHBOARDS',
                                );
                                if (dashboardMenuItems) {
                                    dashboardMenuItems.isAdmin = true;
                                    if (dashboardMenuItems.children) {
                                        dashboardMenuItems.children.forEach((child) => (child.isAdmin = true));
                                        dashboardMenuItems.children.find(
                                            (x) => x.title === 'NAVIGATION.SCHEDULE_COLLECT',
                                        ).isAdmin = this.isScheduleCollectAllowed;
                                    }
                                }
                                this.menuItems.find((x) => x.title === 'NAVIGATION.VAULT').isAdmin = true;
                                if (environment.enableSliicer) {
                                    this.menuItems.find((x) => x.title === 'NAVIGATION.ANALYTICS').isAdmin = true;
                                    this.menuItems.find((x) => x.title === 'NAVIGATION.ANALYTICS').children[0].isAdmin =
                                        true;
                                }
                            },
                        ),
                    );

                    if (!this.customerID) {
                        this.customerID = this.statusCodeService.currentCustomerId.getValue();
                    }
                },
                () => {
                    this.setMenuItems();
                    // menu item setup when api fails
                    this.subscriptions.push(
                        this.menuItemsObservable.subscribe((menuItems) => {
                            this.menuItems = menuItems;
                            this.menuItems.find((x) => x.title === 'REPORTS').isAdmin = true;
                        }),
                    );
                },
            ),
        );
    }

    private setupRoute() {
        // get current query params for customer
        let currentCustomerID = Number(this.activatedRouter.snapshot.queryParamMap.get(customerQueryParam));
        // 9937 handle for 0 customer
        if (currentCustomerID === 0) {
            currentCustomerID = this.statusCodeService.currentCustomerId.getValue();
        }
        // get current query params for location group
        const locationGroupId = Number(
            this.activatedRouter.snapshot.queryParamMap.get(customerLocationGroupQueryParam),
        );

        // get current query params for showing all locations
        const isShowAllLocations = Number(
            this.activatedRouter.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
        );

        // get global query params
        const appQueryParams = <AppQueryParams>{
            c: currentCustomerID,
            lg: locationGroupId || undefined,
            lt: isShowAllLocations || undefined,
        };
        // get the path if there is a link or return empty array for current path
        const path = ['/dashboard'];

        // navigate
        this.router.navigate(path, {
            queryParams: appQueryParams,
            relativeTo: this.activatedRouter,
        });
    }

    /**
     * fill menu items array
     */
    private setMenuItems() {
        this.menuItemsObservable = combineLatest(
            this.navigation.getMenuItems,
            this.navigation.tempMenuItems,
            (menuItems, tempMenuItems) => {
                if (tempMenuItems !== null && tempMenuItems.length > 0) {
                    return tempMenuItems;
                }
                return menuItems;
            },
        );
    }

    public showPageHint() {
        this.domOperationUtilsService.updateHintPageHeight('#hintOverlay', 'show');
    }

    public updateCustomerDropDown(customerId, locGroupId, includeInactiveLocations) {
        if (this.customerID !== customerId && customerId > 0) {
            //auto complete
            this.somethingSelected = true;
            this.somethingSelectedName = customerId;

            this.customerID = customerId;
            this.loadLocationGroups();
            this.locationGroupID = locGroupId || 0;
            this.isInactiveLocations = includeInactiveLocations;
        } else if (this.locationGroupID !== locGroupId && this.customerID > 0) {
            this.loadLocationGroups();
            this.locationGroupID = locGroupId;
        } else if (this.isInactiveLocations !== includeInactiveLocations && this.customerID > 0) {
            this.isInactiveLocations = includeInactiveLocations;
        }

        if (this.customers && this.customers.length) {
            this.selectedCustomer = this.customers.find((customer) => customer.customerID === this.customerID);

            //this.selectedCustomer = this.customers.find(v => v.customerID === this.customerID);
        }

        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    // method to handle notification message click
    public notificationMessageClicked(message) {
        this.notificationsOther.find((s) => s.lid === message.lid).state = NotificationState.READ;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    // method to get filename from url passed
    public getFileName(res: SignalRMessage): string {
        const result = res.url ? res.url.split('/') : [];
        if (result && result.length) {
            const fileName = result[result.length - 1];
            // replacing file name as hyperlink with href link as javscript:vid(0) to avoid page route
            return res.message.replace(fileName, fileName.link('javscript:void(0)'));
        }
        return '';
    }

    public openURL(url: string, target?: string) {
        window.open(url, target);
    }
}
