import { HydrographArgs } from './hydrograph';
import { HGGraphData } from './hydrographNEW';
import { EntityData } from './scatter-data';


export enum SeparateWindowActionTypes {
    ping = 'Ping',
    pong = 'Pong',
    routeChange = 'RouteChange',
    locationChange = 'LocationChange',
    dataHover = 'dataHover',
    enterEdit = 'enterEdit',
    cancelEdit = 'cancelEdit',
    dateClose = 'dateClose',
    hgEntitiesChange = 'hgEntitiesChange',
    sgEntitiesChange = 'sgEntitiesChange',
    dataAverageChange = 'dataAverageChange',
    annotationChange = 'annotationChange',
    selectSnapPoints = 'selectSnapPoints',
    ignoreSGpoints = 'ignoreSGpoints',
    successSnap = 'successSnap',
    applyEditsToSg = 'applyEditsToSg',
    hgPreviewResponse = 'hgPreviewResponse',
    zoomPoints = 'zoomPoints',
    submitEdits = 'submitEdits',
    close = 'close',
    revertChanges = 'revertChanges',
    siltChange = 'siltChange',
    blockEditor = 'blockEditor',
    loadConfig = 'loadConfig',
    updateConfigs = 'updateConfigs',
    updateStoredEdits = 'updateStoredEdits',
    undoEdit = 'undoEdit',
    redoEdit = 'redoEdit',
    confirmationEntity = 'confirmationEntity',
    tracerPosition = 'tracerPosition',
}

export interface Action<T = any> {
    type: SeparateWindowActionTypes;
    payload: T;
    destinationId?: string;
}

export interface ViewData {
    title: string;
    subTitle: string;
    start: Date;
    end: Date;
    displayGroups: DisplayGroup[];
}

export interface EntityResponseItem {
    entityId?: number;
    entityName?: string;
    entityDisplayName?: string;
    entityShortDisplayName?: string;
    storageUnitCategory?: number;
    displayGroup?: number;
    displayGroupPrecendece?: number;
    isPreDefined?: boolean;
    displayGroupName?: string;
    flowDataType?: number;
    totallingMethod?: number;
    summaryMethod?: number;
    isRain?: boolean;
    rate?: number;
    readings?: [];
}

export interface DisplayGroup {
    id: number;
    axis: Axis;
    entityInformation: EntityInformation[];
    entityData: Array<ViewDataEntityData>;
    unit: string;
    precision?: number;
    annotationInformation: AnnotationInformation[];
    annotations: Array<ViewDataEntityData>;
    style?: string;
    sortOrder?: number;
}

export interface ViewDataEntityData {
    ts: Date;
    E: Object;
    Q: number;
}

export interface EntityInformation {
    id: number;
    name: string;
    color: number;
    storageUnitCategory?: number;
    colorHex?: string;
}

export interface EntityInformationData {
    id?: number;
    entityName: string;
    entityData: Array<DepthPoint>;
    color: string;
    unitOfMeasure: string;
    precision?: number;
    axis?: Axis;
    storageUnitCategory?: number;
    style?: string;
}

export interface EntitySeries {
    legendIndex?: number;
    visible?: boolean;
    name: string;
    id?: number;
    displayGroupID?: number;
    type: string;
    data?: Array<DepthPoint>;
    showInLegend: boolean;
    color: string;
    dashStyle?: string;
    dataGrouping: { enabled: false };
    zIndex: number;
    lineWidth: number;
    unitOfMeasure: string;
    precision: number;
    axis?: Axis;
    yAxis: number;
    marker?: Marker;
    step: boolean;
    style?: string;
    turboThreshold?: number;
    connectNulls?: boolean;
    plotBands?: Array<Object>;
    correctedData?: EntitySeries;
    locationName?: string;
    startDate?: string;
    isMultipleOverlayEnabled?: boolean;
    editedData?: EntitySeries;
    pointStart?: number;
    plotOptions?: Object;
}

export interface AnnotationInformation {
    id: number;
    name: string;
    color: number;
    storageUnitCategory: number;
    colorHex?: string;
}

/**
 * Represents the scaling details for hydrograph
 */
interface Axis {
    label?: string;
    minimum: number;
    maximum: number;
}

/**
 * Represents the marker detials for Hydrograph
 */
interface Marker {
    symbol?: string;
    radius?: number;
    enabled?: boolean;
    color?: string;
}

/**
 * Represents the Annotation Series for Hydrograph
 */

export interface AnnotationSeries {
    name: string;
    type: string;
    data: Array<DepthPoint>;
    showInLegend: boolean;
    color: string;
    dashStyle?: string;
    dataGrouping?: { enabled: false };
    unitOfMeasure: string;
    precision: number;
    marker?: Marker;
    lineWidth: number;
    location: string;
    zIndex?: number;
    step?: string;
}

/**
 * Represents the Entity Details for Hydrograph
 */
export interface EntityDetails {
    label: string;
    minimum?: number;
    maximum?: number;
    unit?: string;
    storageCategory?: number;
}

/**
 * Represents the y-axis for Hydrograph
 */
export interface YAxis {
    allowDecimals: boolean;
    title: Object;
    top: string;
    height: string;
    min: number;
    max: number;
    offset: number;
    opposite?: boolean;
    reversed?: boolean;
    maxPadding?: number;
    startOnTick?: boolean;
    endOnTick?: boolean;
    tickInterval?: number;
    showFirstLabel?: boolean;
    showLastLabel?: boolean;
    visible?: boolean;
    tickAmount: number;
    tickPositions?: Array<number>;
    gridLineWidth?: number;
}

export interface DepthPoint {
    x: number;
    y: number;
    segmentColor?: string;
    color?: string;
}

export interface AnnotationSettings {
    isCurveEnabled: boolean;
    isToolbarEnabled?: boolean;
    isPipeHeight: boolean;
    isManholeDepth: boolean;
    isSilt: boolean;
    isDataQuality: boolean;
    isRainOnTop: boolean;
    isShowEdits: boolean;
    isScatterInvert?: boolean;
    isHighLevel: boolean;
    isHighHigh: boolean;
    isHighFlow: boolean;
    isLowDepth: boolean;
    isIsoQ?: boolean;
    isPipeOverlay?: boolean;
    isBestFit?: boolean;
    isToleranceLines?: boolean;
    isSSCurve?: boolean;
    isCWRcurve?: boolean;
    isManningDesign: boolean;
    isLanfearColl: boolean;
    isManualLinear?: boolean;
    isManualSmooth?: boolean;
    isConfirmationPoints?: boolean;
    isFroude?: boolean;
}

export interface DateTimeSetting {
    tooltipTimeFormat: string;
    xAxisDateFormat: string;
}

export interface DataEditingData {
    dateTime?: Date;
    dateTimeUTC?: Date;
    entity?: string;
    rawData?: number;
    suggestedData?: string;
    modifiedValue?: string;
    originalModifiedValue?: string;
    id?: number;
    displayGroupId?: number;
    displayUnit?: string;
    isSelected?: boolean;
    dataEditingArray?: Array<DataEditingData>;
    whichPoint?: DataEditingData;
    whichActionToPerform?: boolean;
    enablePreview?: boolean;
    timeStamp?: Date;
    eid?: string;
    reading?: number;
    reason?: string;
    isFlagged?: boolean;
}

export interface EditedData {
    dateTime?: Date;
    entity?: string;
    rawData?: number;
    suggestedData?: string;
    originalModifiedValue?: string;
    id?: number;
    displayGroupId?: number;
    displayUnit?: string;
    isSelected?: boolean;
    dataEditingArray?: Array<DataEditingData>;
    whichPoint?: DataEditingData;
    whichActionToPerform?: boolean;
    pointExists?: boolean;
    isFlagged?: boolean;
}

export interface FlaggedPoint {
    entityId: number;
    timestamp: number;
    reading: number;
}

export interface DataApprovalRange {
    startDate: Date;
    endDate: Date;
}

export interface DataEditingRow {
    timeStampSelected: number;
    rawDataSelected: number;
    entity?: string;
    selectedTimeStamps?: DataEditingSelectedTimeStamps;
}

export interface DataEditingSelectedTimeStamps {
    minValue: number;
    maxValue: number;
}

export interface LaunchDataEditingTable {
    hydrographParams: HydrographArgs;
    customerId: number;
    locationId: number;
    isDisplayAutoCorrectedData: boolean;
}

export interface ScatterSeriesTooltip {
    useHTML: boolean;
    headerFormat: string;
    shared?: boolean;
    pointFormatter?: () => string;
    positioner?: (labelWidth: number, labelHeight: number, point) => ({ x: number; y: number });
}

export interface BestFitCurve {
    name: string;
    data: EntityData[];
    type: string;
    dashStyle?: string;
    color: string;
    width?: number;
    findNearestPointBy?: string;
    zIndex?: number;
    tooltip?: ScatterSeriesTooltip;
    label?: { enabled?: boolean };
    smoothCurve?: boolean;
}

export interface EntityInfo {
    id: number;
    name: string;
    color: number;
    displayGroupID: number;
    precision?: number;
    unit?: string;
    axisLabel: string;
    storageUnitCategory?: number;
    colorHex?: string;
}

export interface compactAnnotations {
    [key: number]: string;
}

export interface compactData {
    [key: number]: string;
}

export interface OptimizedViewData {
    annotationIds: Array<number>;
    compactAnnots: compactAnnotations[];
    compactData: compactData[];
    displayGroups: OptimizedDisplayGroup[];
    end: Date;
    entityIds: Array<number>;
    start: Date;
    subTitle: string;
    title: string;
    enablePreview?: boolean;
}

export interface optimizedAnnotations {
    color: string;
    id: number;
    name: string;
    unit: number;
    factor: number;
    offset: number;
}

export interface optimizedEntities {
    color: string;
    id: number;
    name: string;
    unit: number;
}

export interface ChartPointMap {
    [key: string]: HGGraphData[]
}

export interface OptimizedDisplayGroup {
    annotations: Array<optimizedAnnotations>;
    entities: Array<optimizedEntities>;
    id: number;
    label: string;
    precision?: number;
    sort?: number;
    style?: string;
    unit?: string;
}

export interface SSCurve {
    name?: string;
    data?: EntityData[];
    type: string;
    dashStyle?: string;
    color?: string;
    width?: number;
    zIndex?: number;
    tooltip?: ScatterSeriesTooltip;
}

export interface DataEditingReasons {
    id: number;
    reason: string;
}

export interface SyncObject {
    editedTimestamp: number;
    editedValue: number;
    editedEntity: number;
}

export interface dateRangeValues {
    startDate: Date;
    endDate: Date;
}

export enum DISPLAY_GROUP_LABELS {
    Depth = 'Depth',
    Velocity = 'Velocity',
    Flow = 'Flow',
    Qcontinuity = 'QContinuity',
    Rain = 'Rain',
    Temp = 'Temperature',
}

export enum DataEditingActionType {
    CopyPaste,
    DragDrop,
    Flagged,
    MultiPoint,
    None,
    UnFlagged,
    Zoom,
}

export enum AdsChartType {
    HYDRO_GRAPH = 0,
    SCATTER_GRAPH,
}

export enum QUICK_DATE_RANGES {
    TODAY = 'today',
    LAST_WEEK = 'lastWeek',
    LAST_MONTH = 'lastMonth',
    PREVIOUS_MONTH = 'previousMonth',
    LAST_THREE_MONTHS = 'lastThreeMonths',
    LAST_SIX_MONTHS = 'lastSixMonths',
    LAST_YEAR = 'lastYear',
}

export enum MultiEditArithmeticType {
    Add = 0,
    Substract,
    Multiply,
    Divide,
    Equal,
}

export enum HydrographTickInterval {
    daily = 1,
    Weekly = 7,
    Monthly = 30,
    ThreeMonths = 90,
    SixMonths = 180,
    Year = 365,
}

export enum GenerationOptions {
    CURRENT,
    LAST_30,
    LAST_60,
    LAST_90,
}

export interface ScatterRevertData {
    x: number;
    y: number;
    ts: number;
    dataQuality?: string;
    flagged?: number;
    rest?: string[];
}
