export const environment = {
    production: false,
    apiUrl: 'https://demo-api.adsprism.net/',
    serviceUrl: 'https://demo-api.adsprism.net/',
    geoserverUrl: 'https://demo-api.adsprism.net/',
    domainURL: 'https://demo.adsprism.net/',
    legacySliicerImportUrl: 'https://ads-sliicer-legacy-import-dev.adsprod3.p.azurewebsites.net/',
    powerBIServiceUrl: 'https://adspowerbi-dev.adsprod3.p.azurewebsites.net/',
    exportStormUrl: 'https://ads-sliicer-data-export-api-dev.azurewebsites.net/',
    adalConfig: {
        tenant: 'adswastewater.onmicrosoft.com',
        clientId: '6b3c3863-2d40-4328-8bd8-9f65a21980ab',
        redirectUri: window.location.origin + '/',
        postLogoutRedirectUri: window.location.origin + '/',
    },
    mapApiKey: 'AIzaSyBlTbBKMgykRlUEwoqM1oH8XslO9lrCVhk',
    showOnlyCrowdcore: false,
    appInsights: {
        instrumentationKey: 'e963a06b-5423-44ea-8f04-8533c6292d57',
    },
    disableTestUser: false,
    showImportExportButtons: false,
    disableTestItems: false,
    enableSliicer: true,
    enableDataEditing: true,
    exportStormUserId: 'ADS',
    exportStormPassword: 'PintaNinaSantaMaria',
    workOrderLink: 'https://adsenv.quickbase.com/db/bpnhhkbd6',
    cookiesScriptUrl: 'https://cdn-cookieyes.com/client_data/cfd74dfd640c097c71f1f1a7/script.js',
    flexmonsterLicense: 'Z7LT-XIAJ4P-2X3W2E-0G4210-5F355D-63380C-4H701J-1R5Y0L-3A6Q61-6M2T02-4X',
    lcLicenseNumber: '',
    clarityKey: 'jnggspx6bk'
};
