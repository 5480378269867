import { AutoScrubSummary } from 'app/shared/models/auto-scrub-summary';
import {
    Component,
    Inject,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    ElementRef,
} from '@angular/core';
import { AlarmService } from 'app/shared/services/alarm.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import * as Highcharts from 'highcharts';
import { Global } from 'app/shared/services/global';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { ScatterDataEntityIds } from '../view-data/graphs/advance-scattergraph/scatter-graph-constants';
import { QUANTITY_ENTITY, VELOCITY_ENTITY, RAIN_ENTITY, DEPTH_ENTITY } from 'app/shared/constant';
import { EntityData, ScatterData } from 'app/shared/models/scatter-data';
import { HydrographAlarmThresholdsArgs } from 'app/shared/models/hydrograph';
import {
    AnnotationSeries,
    DepthPoint,
    DISPLAY_GROUP_LABELS,
    EntitySeries,
    OptimizedViewData,
} from 'app/shared/models/view-data';
import { customerQueryParam } from 'app/shared/models/customer';
import { GraphData } from 'app/shared/models/graph-data';
import { AutoScrubSummaryService } from 'app/shared/services/auto-scrub-summary.service';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { environment } from 'app/environments/environment';

import * as $ from 'jquery';

@Component({
    selector: 'app-alarm-graph',
    templateUrl: './alarm-graph.component.html',
    styleUrls: ['./alarm-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AlarmGraphComponent implements OnInit, OnDestroy {
    public defaultTheme: Highcharts.Options;
    public status: number;

    // tslint:disable-next-line:no-any
    public options: any;

    public chart: any;

    public noHydroDataFound: boolean;
    public hydroLocationName: string;
    public colors = {
        colorScattWormCursorPoint: 'rgba(200, 0, 0, 1)',
        colorScattWormCursorBefore: 'rgba(255, 255, 102, 1)',
        colorScattWormCursorAfter: 'rgba(255, 0, 255, 1)',
        colorHydPlotBandCursorBefore: 'rgba(255, 255, 102, .5)',
        colorHydPlotBandCursorAfter: 'rgba(255, 0, 255, .5)',
        colorScattWormLeaveBehindBefore: 'rgba(51, 102, 255, 1)',
        colorScattWormLeaveBehindAfter: 'rgba(255, 0, 102, 1)',
        colorHydPlotBandLeaveBehindBefore: 'rgba(51, 102, 255, .5)',
        colorHydPlotBandLeaveBehindAfter: 'rgba(255, 0, 102, .5)',
    };
    private subscriptions = new Array<Subscription>();
    private customerID: number;

    /**
     * Graph Loading State
     */
    public graphLoadingState: boolean;

    /**
     * date format on x axis label
     */
    public xAxisDateFormat: string;
    /**
     * Use to show time stamp on tooltip
     */
    public tooltipTimeFormat: string;

    /**
     * Used for showing one graph(i.e. hydrograph) or multi graph(i.e. hydrograph and scattergraph)
     */
    public showMultiGraph: boolean;

    /**
     * Default selected Tab index
     * 0 will represents 1st Tab
     */
    public selectedTabIndex = 0;

    /**
     * Represents x-axis label for scatter graph
     */
    public xAxisLabel: string;

    /**
     * Represents y-axis label for scatter graph
     */
    public yAxisLabel: string;

    /**
     * Represents scattergraph options to initialize the chart
     */
    // tslint:disable-next-line:no-any
    public scatterOptions: any;

    /**
     * Represents scatter chart instance
     */
    // tslint:disable-next-line:no-any
    public scatterChart: any;

    /**
     * Represents the state of scatter graph data avaliablity
     */
    public noScatterDataFound: boolean;

    /**
     * Represents scatter graph data
     */
    private scatterData: ScatterData;

    /**
     * Represents dateFormat for graph data
     */
    private dateFormat: string;

    /**
     * Represents all locations data
     */
    private autoScrubSummaryDetails = new Array<AutoScrubSummary>();

    /**
     * Represents next location availability
     */
    public noNextData: boolean;

    /**
     * Represents previous location availability
     */
    public noPrevData: boolean;

    /**
     * Represents default entities
     */
    public defaultEntities = [RAIN_ENTITY, QUANTITY_ENTITY, DEPTH_ENTITY, VELOCITY_ENTITY, 5122, 5124];

    /**
     * Stores the information for Plot bands
     */
    public plotBands = [];
    /**
     * boolean flag to display Auto Corrected Data
     */
    public isDisplayAutoCorrectedData: boolean;

    public isLowDepth = false;
    public isHighLevel = false;
    public isHighHigh = false;
    public showHydrographOptions: boolean;
    public alarmId: number;
    public annotationsValues = new Array<AnnotationSeries>();
    public maximumAnnotationValue = 0;
    public minimumAnnotationValue = 0;

    public scattergraphChartIndex = 1;
    public graphData: OptimizedViewData;
    public reScaleEntitiesAbstract: EntitySeries[] = [];
    public alarmChartRef: any;
    public workOrderLink: string;
    constructor(
        private alarmService: AlarmService,
        private dateutilService: DateutilService,
        @Inject(MAT_DIALOG_DATA) public data: GraphData,
        public dialogRef: MatDialogRef<AlarmGraphComponent>,
        private autoScrubSummaryService: AutoScrubSummaryService,
        private activatedRoute: ActivatedRoute,
        private customerService: CustomerService,
        private viewDataService: ViewDataService,
        private cdr: ChangeDetectorRef,
        private element: ElementRef,
        private uiUtilsService: UiUtilsService,
        private statusCodeService: StatusCodeService,
    ) {}

    public ngOnInit() {
        Highcharts.setOptions({
            time: {
                useUTC: true,
            },
        });

        const customerIDSuscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerID = Number(params.get(customerQueryParam));
        });

        this.subscriptions.push(customerIDSuscription);

        this.showMultiGraph = this.data.showMultiGraph;

        this.workOrderLink = this.data.workOrderLink || environment.workOrderLink;

        if (this.data.isAutoReviewWidget) {
            // Getting AutoScrubSummary data from service
            this.autoScrubSummaryDetails = this.autoScrubSummaryService.autoScrubSummaryDetailsSorted;
        }

        // Highcharts.setOptions({ global: { useUTC: false } });

        this.xAxisDateFormat = this.dateutilService.getGraphDateFormat();

        if (this.data.timeFormat === 'h:mm:ss tt') {
            this.tooltipTimeFormat = ' %l:%M %p';
        } else {
            this.tooltipTimeFormat = ' %H:%M';
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    public showHydrographParmeter() {
        this.showHydrographOptions = !this.showHydrographOptions;
    }

    /**
     * event handler of pipeHeight checkbox
     * @param event - instance of checkbox
     */
    public getLowDepth(event: MatCheckboxChange) {
        this.isLowDepth = event.checked;
        this.loadGraphData();
    }

    public openCustomerLink() {
        window.open(this.workOrderLink, '_blank');
    }

    /**
     * event handler of pipeHeight checkbox
     * @param event - instance of checkbox
     */
    public getHighLevel(event: MatCheckboxChange) {
        this.isHighLevel = event.checked;
        this.loadGraphData();
    }

    /**
     * event handler of Silt checkbox
     * @param event - instance of checkbox
     */
    public getHighHigh(event: MatCheckboxChange) {
        this.isHighHigh = event.checked;
        this.loadGraphData();
    }
    /**
     * Below function will load the hydrograph and scattergraph data
     */
    public loadGraphData() {
        this.graphLoadingState = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);

        if (this.data.isAutoReviewWidget) {
            this.checkPrevData();
            this.checkNextData();
        }

        let startDate, endDate;

        if (this.data.isAlarmsWidget) {
            startDate = this.dateutilService.getNewDate(-3, this.data.eventDate);
            endDate = this.dateutilService.getNewDate(4, this.data.eventDate);
        } else if (this.data.isAutoReviewWidget) {
            startDate = this.dateutilService.getNewDate(-6, this.data.eventDate);
            endDate = this.dateutilService.getNewDate(1, this.data.eventDate);
        } else {
            startDate = this.dateutilService.getNewDate(-13, this.data.eventDate);
            endDate = this.dateutilService.getNewDate(1, this.data.eventDate);
        }

        if (this.data && this.data.hydrographAlarmThresholdType) {
            if (this.data.hydrographAlarmThresholdType === 'High High') {
                this.isHighHigh = true;
                this.alarmId = 2;
            }
            if (this.data.hydrographAlarmThresholdType === 'High Level') {
                this.isHighLevel = true;
                this.alarmId = 1;
            }
            if (this.data.hydrographAlarmThresholdType === 'Low Flow') {
                this.isLowDepth = true;
                this.alarmId = 3;
            }
        }
        const params = <HydrographAlarmThresholdsArgs>{
            start: startDate,
            end: endDate,
            entityIds: this.defaultEntities,
            highLevelThreshold: this.isHighLevel,
            highHighThreshold: this.isHighHigh,
            lowLevelThreshold: this.isLowDepth,
        };

        /* Pass viewCorrected Data Flag in order to return Auto-Corrected Data*/
        if (this.isDisplayAutoCorrectedData) {
            params['viewCorrectedData'] = true;
        }

        const hydrographSubscription = this.alarmService
            .getHydrograph(this.customerID, this.data.locationId, params)
            .subscribe(
                (res: OptimizedViewData) => {
                    if (res) {
                        // getting data as ViewData
                        this.graphData = <OptimizedViewData>res;
                        this.hydroLocationName = res.title;
                        this.noHydroDataFound = false;
                        this.annotationsValues.length = 0;
                        this.maximumAnnotationValue = 0;
                        let yAxisNumber = 0;

                        const entitiesData = new Array<EntitySeries>();

                        for (const displayGroup of this.graphData.displayGroups) {
                            /* 10416 - UI:  Enable alarm thresholds */

                            // Setting plotlines for each annotation
                            if (displayGroup.annotations.length > 0) {
                                // array to store annotations data
                                const annotationValue = [];
                                displayGroup.annotations.forEach((annotation) => {
                                    if (annotation.id === this.alarmId) {
                                        const annotaionsData = [];

                                        const selectedAnnotations = this.graphData.annotationIds.indexOf(this.alarmId);
                                        let index = 0;
                                        let compactAnnotationData;
                                        for (const prop in this.graphData.compactAnnots) {
                                            compactAnnotationData = String(this.graphData.compactAnnots[prop])
                                                .slice(1, String(this.graphData.compactAnnots[prop]).length - 1)
                                                .split(';')
                                                .filter(
                                                    (element) =>
                                                        element.split(':')[0] === selectedAnnotations.toString(),
                                                );
                                            if (compactAnnotationData) {
                                                annotaionsData.push({
                                                    x:
                                                        index === 0
                                                            ? Date.UTC(
                                                                  startDate.getFullYear(),
                                                                  startDate.getMonth(),
                                                                  startDate.getDate(),
                                                              )
                                                            : Date.UTC(
                                                                  endDate.getFullYear(),
                                                                  endDate.getMonth(),
                                                                  endDate.getDate(),
                                                              ),
                                                    y: parseFloat(String(compactAnnotationData).split(':')[1]),
                                                });

                                                annotationValue.push(
                                                    parseFloat(String(compactAnnotationData).split(':')[1]),
                                                );
                                                index++;
                                            }
                                        }

                                        // new series to set start and end point of annotation to display on tooltip
                                        const series: AnnotationSeries = {
                                            name: annotation.name,
                                            data: annotaionsData,
                                            type: 'line',
                                            showInLegend: false,
                                            color: '#FF3D00',
                                            dashStyle: 'shortdash',
                                            marker: { symbol: 'triangle-down', radius: 5 },
                                            lineWidth: 1,
                                            unitOfMeasure: 'in',
                                            precision: 2,
                                            location: '',
                                        };

                                        // Adding annotation value in array
                                        this.annotationsValues.push(series);

                                        //  calculate maximum annotations value
                                        const maxAnnotationValue = Math.max(...annotationValue);
                                        if (
                                            typeof maxAnnotationValue === 'number' &&
                                            maxAnnotationValue > this.maximumAnnotationValue
                                        ) {
                                            this.maximumAnnotationValue = maxAnnotationValue;
                                        }

                                        const minAnnotationValue = Math.min(...annotationValue);
                                        if (
                                            typeof minAnnotationValue === 'number' &&
                                            minAnnotationValue > this.minimumAnnotationValue
                                        ) {
                                            this.minimumAnnotationValue = minAnnotationValue;
                                        }
                                    }
                                });
                            }

                            for (const entityInformation of displayGroup.entities) {
                                if (entitiesData.findIndex((k) => k.name === entityInformation.name) === -1) {
                                    const entities = [];
                                    // storing unit of entity

                                    /* The Corrected Series represents the series of Auto-Corrected Points*/

                                    const correctedSeries = <EntitySeries>{
                                        id: entityInformation.id + 10000,
                                        name: 'Corrected' + ' ' + entityInformation.name,
                                        showInLegend: false,
                                        displayGroupID: displayGroup.id,
                                        unitOfMeasure: displayGroup.unit,
                                        style: displayGroup.style,
                                        data: [],
                                        color: '#DAA520',
                                        precision: displayGroup.precision,
                                        axis: {
                                            minimum: 0, //displayGroup.axis.minimum || 0,
                                            maximum: 10, //displayGroup.axis.maximum || 0
                                        },
                                        type: 'line',
                                        dashStyle: 'longdash',
                                        dataGrouping: { enabled: false },
                                        zIndex: 5,
                                        lineWidth: 1.5,
                                        yAxis: 0,
                                        step: false,
                                        connectNulls: false,
                                        plotBands: new Array<Object>(),
                                        turboThreshold: 100000,
                                    };

                                    const entityInformationData = <EntitySeries>{
                                        id: entityInformation.id,
                                        name: entityInformation.name || '',
                                        displayGroupID: displayGroup.id,
                                        type: entityInformation.id === 2123 ? 'column' : 'line',
                                        data: new Array<Object>(),
                                        showInLegend: true,
                                        color: entityInformation.color || '',
                                        dataGrouping: { enabled: false },
                                        zIndex: 5,
                                        lineWidth: 1.5,
                                        unitOfMeasure: displayGroup.unit || '',
                                        precision: displayGroup.precision || null,
                                        yAxis: yAxisNumber,
                                        axis: {
                                            minimum: 0,
                                            maximum: 10,
                                            // tslint:disable-next-line: max-line-length
                                            label: `${
                                                displayGroup.label === DISPLAY_GROUP_LABELS.Flow
                                                    ? DISPLAY_GROUP_LABELS.Qcontinuity
                                                    : displayGroup.label
                                            } (${displayGroup.unit})`,
                                        },
                                        turboThreshold: 100000,
                                        step: false,
                                        correctedData: correctedSeries,
                                    };

                                    yAxisNumber++;

                                    const plotband = {
                                        color: '',
                                        from: null,
                                        to: null,
                                        id: 'plotBandAnnomalies',
                                    };

                                    const entityDataObject = this.computeEntityData(entityInformation.id, displayGroup);
                                    for (const prop in entityDataObject) {
                                        if (prop === 'Entites') {
                                            entityInformationData.data = entityDataObject[prop];
                                        } else {
                                            correctedSeries.data = entityDataObject[prop];
                                        }
                                    }
                                    entityInformationData.correctedData.plotBands = [...this.plotBands];
                                    this.plotBands.length = 0;
                                    entitiesData.push(entityInformationData);
                                }
                            }
                        }

                        // setting data on chart
                        this.initChart(
                            entitiesData,
                            this.xAxisDateFormat,
                            this.tooltipTimeFormat,
                            'highChartIdAuto',
                            startDate,
                            endDate,
                        );

                        // hiding loader
                        this.graphLoadingState = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    } else {
                        this.noHydroDataFound = true;
                        this.hydroLocationName = this.data.locationName;
                        this.graphLoadingState = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                },
                (error) => {
                    this.graphLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );
        this.subscriptions.push(hydrographSubscription);

        if (this.data.showMultiGraph) {
            const tempFlags = [];

            const subscription = this.viewDataService
                .getScatterGraphOptimized(
                    this.customerID,
                    this.data.locationId,
                    startDate,
                    endDate,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    tempFlags,
                    {},
                    [ScatterDataEntityIds.Velocity, ScatterDataEntityIds.Unidepth],
                )
                .subscribe(
                    (scatterResult: ScatterData) => {
                        if (scatterResult) {
                            this.scatterData = <ScatterData>scatterResult;
                            this.displayScatterGraph();
                        } else {
                            this.noScatterDataFound = true;
                        }
                    },
                    (error) => {
                        this.noScatterDataFound = true;
                    },
                );
            this.subscriptions.push(subscription);
        }
    }

    /**
     * For geting graph of pervious location
     */
    public previousLocationGraph() {
        // Getting data of pervious location
        this.data.locationIndex--;
        const prevLocation = this.autoScrubSummaryDetails[this.data.locationIndex];

        // Reset the Plotbands and AutoCorrected Points
        this.plotBands.length = 0;

        // Updating graph with new location
        this.updateLocationData(prevLocation);
    }

    /**
     * For geting graph of next location
     */
    public nextLocationGraph() {
        // Getting data of next location
        this.data.locationIndex++;
        const nextLocation = this.autoScrubSummaryDetails[this.data.locationIndex];

        // Reset the Plotbands and AutoCorrected Points
        this.plotBands.length = 0;

        // Updating graph with new location
        this.updateLocationData(nextLocation);
    }

    /**
     * For updating graph with new location
     */
    public updateLocationData(autoScrubSummary: AutoScrubSummary) {
        if (autoScrubSummary && autoScrubSummary.aDate) {
            // Setting new location data on Graph
            this.data.locationId = autoScrubSummary.locationId;
            this.data.locationName = autoScrubSummary.locationName;
            this.data.eventDate = autoScrubSummary.aDate.toString();
            this.loadGraphData();
        }
    }

    /**
     * For checking next location avaliablity
     */
    public checkNextData() {
        if (this.data.locationIndex === this.autoScrubSummaryDetails.length - 1) {
            this.noNextData = true;
        } else {
            this.noNextData = false;
        }
    }

    /**
     * For checking previous location avaliablity
     */
    public checkPrevData() {
        if (this.data.locationIndex === 0) {
            this.noPrevData = true;
        } else {
            this.noPrevData = false;
        }
    }

    /**
     * Method returns the placement of the entity series
     * on the Hydrograph: 1 - top, 2 - bottom
     * @param item - Entity series on Hydrograph
     */
    private itemPlacementLevel(item: EntitySeries) {
        //  if item belongs to standard depth entities group or
        //  standard velocity entities
        //  it displays on top
        if (item.displayGroupID === 2 || item.displayGroupID === 5) {
            return 1;
        }

        //  if item belongs to standard rain entities
        //  or standard flow entities
        //  it belongs on the bottom
        if (item.displayGroupID === 3 || item.displayGroupID === 6) {
            return 2;
        }

        return 0;
    }

    /**
     * Method returns the number of entities series for the top level
     * or bottom level fo the graph
     * used to calculate the position on left or right on x axis
     * @param displayGroupID - display group ID
     * @param entities - all entities series on the graph
     */
    private numberOfEntitiesInTier(displayGroupID: number, entities: Array<EntitySeries>) {
        if (!entities || entities.length === 0) {
            return;
        }

        //  if the display group ID belongs to standard depth entities or
        //  standard velocity entities, return the number of those entity series on the graph
        if (displayGroupID === 2 || displayGroupID === 5) {
            return entities.filter((x) => x.displayGroupID === 2 || x.displayGroupID === 5).length;
        }

        //  if display group ID belongs to standard rain entities or standard flow entities
        //  return the number of those entity series on the graph
        if (displayGroupID === 3 || displayGroupID === 6) {
            return entities.filter((x) => x.displayGroupID === 3 || x.displayGroupID === 6).length;
        }
        return 0;
    }

    /**
     * Method returns the maximum y axis scale value
     * @param displayGroupID - display group ID
     * @param entities - entity series on the graph
     */
    private returnYaxisMax(displayGroupID: number, entities: Array<EntitySeries>) {
        if (!entities || entities.length === 0) {
            return;
        }
        const maxValuesArray = new Array<number>();
        const sameDisplayGroupEntites = entities.filter((x) => x.displayGroupID === displayGroupID).map((y) => y.data);
        //  if the annotations are present include them in calculations of depth/velocity scale

        if (this.isDisplayAutoCorrectedData) {
            maxValuesArray.push(
                Math.max(
                    ...entities
                        .filter((x) => x.displayGroupID === displayGroupID)[0]
                        .correctedData.data.map((x) => x['y']),
                ),
            );
        }

        sameDisplayGroupEntites.forEach((item) => {
            maxValuesArray.push(
                ...item.map((x) => {
                    if (x) {
                        return x['y'];
                    } else {
                        return 0;
                    }
                }),
            );
        });

        let maxValue = Math.max(...maxValuesArray);

        //  if the annotations are present include them in calculations of depth/velocity scales
        if (this.maximumAnnotationValue > 0 && displayGroupID === 2) {
            maxValue = Math.max(...[this.maximumAnnotationValue, maxValue]);
        }
        if (maxValue % 1 === 0) {
            return maxValue;
        }
        return Math.ceil(maxValue * 100) / 100;
    }

    /**
     * Method returns the minimum y axis scale value
     * @param displayGroupID - display group ID
     * @param entities - entity series on the graph
     */
    private returnYaxisMin(displayGroupID: number, entities: Array<EntitySeries>) {
        if (!entities || entities.length === 0) {
            return;
        }

        const currentEntity = entities.filter((x) => x.displayGroupID === displayGroupID);
        const minValuesArray = new Array<number>();
        minValuesArray.push(0);
        const sameDisplayGroupEntites = currentEntity.map((y) => y.data);

        sameDisplayGroupEntites.forEach((item) => {
            if (item.length > 0) {
                minValuesArray.push(
                    Math.min(
                        ...item.map((x) => {
                            if (x) {
                                return x['y'];
                            } else {
                                return 0;
                            }
                        }),
                    ),
                );
            }
        });

        if (this.isDisplayAutoCorrectedData) {
            currentEntity[0].correctedData &&
            currentEntity[0].correctedData.data &&
            currentEntity[0].correctedData.data.length > 0
                ? minValuesArray.push(Math.min(...currentEntity[0].correctedData.data.map((x) => x['y'])))
                : minValuesArray.push(0);
        }

        let minValue = Math.min(...minValuesArray);

        //  if the annotations are present include them in calculations of depth/velocity scales
        if (this.minimumAnnotationValue > 0 && displayGroupID === 2) {
            minValue = Math.min(...[this.minimumAnnotationValue, minValue]);
        }

        if (minValue % 1 === 0) {
            return minValue;
        }
        return Math.ceil(minValue * 100) / 100;
    }
    /**
     * Initializes the Hydrograph.
     * @param entitiesData - series data
     * @param xAxisDateFormat - date format for x axis
     * @param tooltipTimeFormat - date/time format for tooltip
     * @param hydroId - DOM element to render the chart to
     */
    public initChart(
        entitiesData: Array<EntitySeries>,
        xAxisDateFormat: string,
        tooltipTimeFormat: string,
        hydroId: string,
        startDate: Date,
        endDate: Date,
    ) {
        const yAxises = [];

        if (entitiesData.length === 0) {
            return;
        }

        //  add non-existing default entities to the chart
        this.defaultEntities.forEach((entityID) => {
            if (!entitiesData.some((x) => x && x.id === entityID)) {
                let entityName;
                let groupID;
                if (entityID === 2123) {
                    entityName = 'Rain';
                    groupID = 3;
                } else if (entityID === QUANTITY_ENTITY) {
                    entityName = 'Quantity';
                    groupID = 6;
                } else if (entityID === DEPTH_ENTITY) {
                    entityName = 'Unidepth';
                    groupID = 2;
                } else if (entityID === VELOCITY_ENTITY) {
                    entityName = 'Velocity';
                    groupID = 5;
                } else if (entityID === 5122) {
                    entityName = 'PDEPTH_1';
                    groupID = 2;
                } else if (entityID === 5124) {
                    entityName = 'PDEPTH_3';
                    groupID = 2;
                }

                const entityInformationData = <EntitySeries>{
                    name: entityName,
                    id: entityID,
                    displayGroupID: groupID,
                    type: '',
                    data: new Array<Object>(),
                    showInLegend: true,
                    color: '',
                    dataGrouping: { enabled: false },
                    zIndex: 5,
                    lineWidth: 0,
                    unitOfMeasure: null,
                    precision: null,
                    yAxis: 0,
                    step: false,
                    marker: {
                        enabled: false,
                    },
                };

                entitiesData.push(entityInformationData);
            }
        });

        let numberOfDepthScales = 0;

        entitiesData.forEach((item, itemIndex) => {
            let verticalOffset = '0%';
            let right = false;

            const numberOfItemsInTier = this.numberOfEntitiesInTier(item.displayGroupID, entitiesData);

            //  if there is more than one entities on the graph and it's velocity or rain
            //  display the y axis on the right
            if (numberOfItemsInTier > 1 && (item.displayGroupID === 5 || item.displayGroupID === 3)) {
                right = true;
            }

            //  offset the axis vertiaclly depending if  it goes on top or bottom of the graph
            const itemPlacement = this.itemPlacementLevel(item);
            if (itemPlacement === 2 || entitiesData.length === 1) {
                verticalOffset = '60%';
                // } else if (itemPlacement === 3) {
                //     verticalOffset = '120%';
                // } else if (itemPlacement === 4) {
                //     verticalOffset = '180%';
            }

            //  y axis label text for all depth entities should be "Depth"
            const title = item.displayGroupID === 2 ? 'Depth' : item.name;

            //  there should be only one y axis showing for depth entities
            let isVisibleAxis = true;
            if (item.displayGroupID === 2) {
                isVisibleAxis = numberOfDepthScales < 1;
                numberOfDepthScales++;
            }

            yAxises.push({
                allowDecimals: true,
                opposite: right,
                title: {
                    text: item.unitOfMeasure ? title + ' (' + item.unitOfMeasure + ')' : title,
                    enabled: isVisibleAxis,
                },
                top: verticalOffset,
                height: '40%',
                min: item.data.length > 0 ? this.returnYaxisMin(item.displayGroupID, entitiesData) : 0,
                max: item.data.length > 0 ? this.returnYaxisMax(item.displayGroupID, entitiesData) : 10,
                offset: 40,
                visible: isVisibleAxis,
                plotLines: [
                    {
                        value: 0,
                        color: 'black',
                        dashStyle: 'solid',
                        width: 1,
                    },
                ],
            });
        });

        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme(this.chart);
            } else {
                StringUtils.setHighchartWhiteTheme(this.chart);
            }
        });

        /* Code to plot the AutoCorrected Series and Plotbands in case Auto Corrected setting is enabled*/
        if (this.isDisplayAutoCorrectedData) {
            entitiesData.forEach((entityInformation) => {
                if (entityInformation.correctedData) {
                    entitiesData = entitiesData.concat(entityInformation.correctedData);
                }
            });
        }

        this.options = {
            credits: {
                enabled: false,
            },
            title: {
                text: null,
            },
            legend: {
                enabled: true,
                labelFormatter: function () {
                    return StringUtils.upperCase(this.name);
                },
            },
            exporting: {
                enabled: false,
            },

            chart: {
                renderTo: hydroId,
                defaultSeriesType: 'line',
                zoomType: 'x',
                height: 450,
                events: {
                    click: function (event) {
                        const clickedX = event.xAxis[0].value;
                        const clickedXRnd = Math.round(clickedX);
                    },
                    redraw: function (e) {
                        this.redrawCount++;
                    },
                    select: function (event) {
                        this.onChartSelection(event);
                    },
                },
            },

            plotOptions: {
                column: { groupPadding: 0, pointPadding: 0, pointWidth: 1, borderWidth: 0, shadow: false },
                line: { lineWidth: 0.5 },
                series: {
                    events: {
                        legendItemClick: (event) => {
                            // rescale hydrograph when user toggles visibility of series from legend
                            this.reScaleAutoReviewHydrographEntities(
                                entitiesData,
                                event.target.name,
                                event.target.userOptions.displayGroupID,
                                event.target.data && event.target.data.length > 0 && event.target.visible,
                            );
                        },
                    },
                    allowPointSelect: true,
                    marker: {
                        states: {
                            select: {
                                fillColor: 'red',
                                radius: 6,
                                lineWidth: 0,
                            },
                            hover: {
                                enabled: true,
                                lineWidth: 2,
                            },
                        },
                    },
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },

                    point: {
                        events: {
                            mouseOver: (event) => {
                                this.viewDataService.corelateGraphs(
                                    event.target.series.chart.container,
                                    event.target.x,
                                );
                            },
                            mouseOut: (event) => {
                                this.viewDataService.setChartParameter(event.target.x, this.scattergraphChartIndex);
                            },
                        },
                    },
                },
            },
            xAxis: {
                min: Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
                max: Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat(xAxisDateFormat, this.value);
                    },
                    rotation: 45,
                    enabled: true,
                },
                tickInterval: 24 * 3600 * 1000,
                ordinal: false,
                gridLineWidth: 1,
                plotBands: [
                    {
                        // mark the weekend
                        color: this.colors.colorHydPlotBandCursorBefore,
                        from: 0,
                        to: 0,
                        id: 'plotBandCursorWormBefore',
                    },
                    {
                        // mark the weekend
                        color: this.colors.colorHydPlotBandCursorAfter,
                        from: 0,
                        to: 0,
                        id: 'plotBandCursorWormAfter',
                    },
                ],
            },
            yAxis: yAxises,
            scrollbar: {
                enabled: false,
                liveRedraw: false,
            },
            navigator: { enabled: false, margin: 0 },
            series: entitiesData,
            tooltip: {
                hideDelay: 0,
                enabled: true,
                crosshairs: true,
                headerFormat: '',
                shared: true,

                formatter: function () {
                    // condition to set tooltip format as per highchart configuration

                    if (this.points) {
                        const tooltipFormatter = new Array<string>();
                        tooltipFormatter.push(Highcharts.dateFormat(xAxisDateFormat + ' ' + tooltipTimeFormat, this.x));
                        this.points.forEach((point) => {
                            if (point.color !== '#00000000' && !point.point.hideToolTip) {
                                tooltipFormatter.push(`<br><span style="color: ${
                                    point.color
                                }">\u25CF</span>${StringUtils.upperCase(point.series.name)}:
                            <b>${point.y.toFixed(point.series.options.precision)}</b>
                             ${point.series.options.unitOfMeasure}`);
                            }
                        });
                        return tooltipFormatter;
                    }
                },
            },
            rangeSelector: {
                enabled: true,
            },
            allowPointSelect: true,
        };

        //  for depth/velocity entities add annotations
        if (this.annotationsValues && this.annotationsValues.length > 0) {
            this.options.series = this.options.series.concat(this.annotationsValues);
        }
        this.alarmChartRef = new Highcharts.Chart(this.options);
        if (this.isDisplayAutoCorrectedData) {
            entitiesData.forEach((entityInformation) => {
                if (entityInformation.plotBands) {
                    entityInformation.plotBands.forEach((band) => {
                        this.options.xAxis[0].addPlotBand({
                            color: 'rgba(240,236,239,0.5)',
                            from: band['from'],
                            to: band['to'],
                        });
                    });
                }
            });
        }
    }

    /**
     * Saves the chart instance into chart variable after chart is loaded.
     * @param chart - chart instance
     */
    public saveChart(chart) {
        this.chart = chart;
    }

    /**
     * gets called on chart selection event of Hydrograph.
     * @param event - event
     */
    public onChartSelection(event) {
        if (event.originalEvent.shiftKey === true || event.originalEvent.ctrlKey === true) {
            event.preventDefault();
            // RemoveSelectionPlotBand();;
            const selectState = !event.originalEvent.ctrlKey;
            this.chart.series[0].chart.xAxis[0].addPlotBand({
                // mark the selection
                color: 'rgba(102, 153, 153, .2)',
                from: event.xAxis[0].min,
                to: event.xAxis[0].max,
                id: 'plotBandSelection',
            });
            let startSelectionIndex, endSelectionIndex;
            const chartScatter = $('#scattergraph_container').highcharts();
            for (let i = Global.zoomStartIndex; i < this.chart.series[0].data.length; i++) {
                if (this.chart.series[0].data === undefined) {
                    continue;
                }
                if (this.chart.series[0].data[i].x > event.xAxis[0].min) {
                    startSelectionIndex = i;
                    break;
                }
            }
            for (let i = startSelectionIndex; i < this.chart.series[0].data.length; i++) {
                if (this.chart.series[0].data[i].x === undefined) {
                    continue;
                }
                if (this.chart.series[0].data[i].x > event.xAxis[0].max) {
                    endSelectionIndex = i - 1;
                    break;
                }
            }

            for (let i = startSelectionIndex; i <= endSelectionIndex; i++) {
                const myPoint = (<any>chartScatter).series[0].data[i - Global.zoomStartIndex];
                myPoint.select(true, true);
                for (let j = 1; j <= 6; j++) {
                    const myData = (<any>chartScatter).series[j].data;
                    for (let k = 0; k < myData.length; k++) {
                        if (myPoint.x === event.context.x) {
                            if (myData[k].y === event.context.y) {
                                myData[i].select(selectState, true);
                                break;
                            }
                        }
                    }
                }
            }
            return false;
        }
    }

    public emitGraph() {
        this.dialogRef.close({ success: false });
    }

    public displayScatterGraph() {
        if (!this.scatterData) {
            this.noScatterDataFound = true;

            return;
        }

        const depthVelocityData = new Array<EntityData>();

        this.noScatterDataFound = false;
        this.xAxisLabel = this.scatterData.xAxis.label;
        this.yAxisLabel = this.scatterData.yAxis.label;

        for (const data of this.scatterData.d) {
            // substr is done to remove brackets [ ]
            const strElements = data.toString().substr(1, data.toString().length - 2).split(':');
            const millisecondsElement = strElements[2];
            const milliseconds = Number(millisecondsElement) * 1000;

            const xValue = Number(strElements[0]);
            const yValue = Number(strElements[1]);

            const xAxisEntity = this.scatterData.xAxis.label.split(' ')[0];
            const yAxisEntity = this.scatterData.yAxis.label.split(' ')[0];
            const depthPoint: EntityData = {
                dateElement: xValue + ':' + yValue + ':' + milliseconds,
                x: xValue,
                y: yValue,
                xUnit: this.scatterData.xAxis.unit,
                yUnit: this.scatterData.yAxis.unit,
                xAxisEntity: xAxisEntity,
                yAxisEntity: yAxisEntity,
                dateTime: milliseconds,
            };
            depthVelocityData.push(depthPoint);
        }

        // initialize scatter chart options
        this.initScatterChart(depthVelocityData, this.xAxisDateFormat, this.tooltipTimeFormat);
    }

    /**
     * Initializes the Scattergraph.
     * @param depthVelocityData - Data Array for depth and velocity series in scattergraph.
     * @param scatterDateFormat - Represents the date format in scattergraph which will be come from customer API .
     * @param tooltipTimeFormat - Represents the tooltip format in scattergraph.
     */
    public initScatterChart(
        depthVelocityData: Array<EntityData>,
        scatterDateFormat: string,
        tooltipTimeFormat: string,
    ) {
        if (!this.data) {
            return;
        }
        this.scatterOptions = new Highcharts.Chart(<any>{
            credits: false,
            exporting: {
                enabled: false,
            },
            tooltip: {
                hideDelay: 0,
                enabled: true,
                shared: true,
                crosshairs: false,
                headerFormat: '',
                formatter: function () {
                    if (this.points) {
                        const tooltipFormatter = new Array<string>();
                        tooltipFormatter.push(
                            Highcharts.dateFormat(scatterDateFormat + tooltipTimeFormat, this.points[0].point.dateTime),
                        );
                        for (const seriesPoint of this.points) {
                            const point =
                                '<br/>' +
                                StringUtils.upperCase(seriesPoint.point.yAxisEntity) +
                                ':' +
                                '<b>' +
                                Math.round(this.y * 100) / 100 +
                                ' ' +
                                '</b>' +
                                seriesPoint.point.yUnit +
                                '<br/>' +
                                StringUtils.upperCase(seriesPoint.point.xAxisEntity) +
                                ':' +
                                '<b>' +
                                Math.round(this.x * 100) / 100 +
                                ' ' +
                                '</b>' +
                                seriesPoint.point.xUnit;
                            tooltipFormatter.push(point);
                        }
                        return tooltipFormatter;
                    } else {
                        const dateFormat = Highcharts.dateFormat(
                            scatterDateFormat + tooltipTimeFormat,
                            this.point.dateTime,
                        );
                        const scatterTooltipFormatter = `${dateFormat}<br/>${StringUtils.upperCase(
                            this.point.yAxisEntity,
                        )}: <b>
                        ${Math.round(this.y * 100) / 100} </b>${this.point.yUnit}<br/>
                        ${StringUtils.upperCase(this.point.xAxisEntity)}: <b>${Math.round(this.x * 100) / 100}</b>
                        ${this.point.xUnit}`;
                        return scatterTooltipFormatter;
                    }
                },
            },
            legend: {
                enabled: false,
            },

            chart: {
                renderTo: 'scatterChartIdAuto',
                type: 'scatter',
                zoomType: 'xy',
            },

            plotOptions: {
                column: { groupPadding: 0, pointPadding: 0, pointWidth: 1, borderWidth: 0, shadow: false },
                line: { lineWidth: 0.5 },
                scatter: {
                    marker: {
                        radius: 5,
                        enabled: true,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                        },
                    },
                },
                series: {
                    turboThreshold: 10000,
                    allowPointSelect: true,
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: this.xAxisLabel,
                },
                ordinal: false,
            },
            yAxis: {
                title: {
                    text: this.yAxisLabel,
                },
            },
            scrollbar: {
                enabled: false,
                liveRedraw: false,
            },
            navigator: { enabled: false, margin: 0 },
            series: [
                {
                    marker: { radius: 4, symbol: 'diamond' },
                    color: 'rgba(119, 152, 191, .5)',
                    data: depthVelocityData,
                },
            ],
            rangeSelector: {
                enabled: false,
            },
        });
    }

    /**
     * saves the chart instance to a chart variable.
     * @param chart - instance of scatter chart.
     */
    public saveScatterChart(chart) {
        this.scatterChart = chart;
    }

    /**
     * Below function would be invoked while clicking on the graph tab and sets the selected tab
     * @param index represents selcted tab index
     */
    public selectedIndexChange(index: number) {
        this.selectedTabIndex = index;
    }
    /**
     * code to add plot bands to the Graph
     * @param correctedPoint - corrected Point for the end date
     * @param plotband - plot band having to and From Information
     * @param entityAutoCorrectedData - Series for which plot bands are plotted
     */
    private addPlotBands(correctedPoint, plotband) {
        if (correctedPoint && correctedPoint.y) {
            plotband['to'] = correctedPoint.x;
            const plotBand = { ...plotband };
            this.plotBands.push(plotBand);
        }
    }
    // tslint:disable-next-line:cyclomatic-complexity
    private computeEntityData(entityId, displayGroup) {
        const selectedEntity = this.graphData.entityIds.indexOf(entityId);
        const autoCorrectedEntity = this.graphData.entityIds.indexOf(entityId + 10000);
        const entities = [];
        const autoCorrectedData = [];
        let depthPoint: DepthPoint;
        let correctedPoint: DepthPoint;
        let compactEntityData: string[];
        let compactAutoCorrectedData: string[];
        let index = 0;

        const plotband = {
            color: '',
            fxgrom: null,
            to: null,
            id: 'plotBandAnnomalies',
        };
        // tslint:disable-next-line:forin
        for (const prop in this.graphData.compactData) {
            /* Hydrograph Optimization - Get the Response String having the same entity as display group selected */
            compactEntityData = String(this.graphData.compactData[prop])
                .slice(1, String(this.graphData.compactData[prop]).length - 1)
                .split(';')
                .filter((element) => element.split(':')[0] === selectedEntity.toString());

            /* Hydrograph Optimization - Get the Response String having the same entity as display group selected */
            if (this.isDisplayAutoCorrectedData) {
                compactAutoCorrectedData = String(this.graphData.compactData[prop])
                    .slice(1, String(this.graphData.compactData[prop]).length - 1)
                    .split(';')
                    .filter((element) => element.split(':')[0] === autoCorrectedEntity.toString());
            }

            if (compactEntityData && compactEntityData.length > 0) {
                let compactValue: string;
                let QValue: any;

                /* Means that the entity is having Q- Value not Good */
                if (
                    compactEntityData &&
                    String(compactEntityData).split(':')[1] &&
                    String(compactEntityData).split(':')[1].indexOf(',') > 0
                ) {
                    compactValue = String(compactEntityData)
                        .split(':')[1]
                        .split(',')[0]
                        .slice(1, String(compactEntityData).split(',')[0].length);
                    QValue = String(String(compactEntityData).split(':')[1].split(',')[2]).slice(0, -1);
                } else {
                    compactValue = String(compactEntityData).split(':')[1];
                    QValue = 15;
                }

                depthPoint = {
                    x: new Date(Number(prop)).getTime() * 1000,
                    y: this.correctedData(String(compactEntityData).split(':')[1])
                        ? parseFloat(this.correctedData(String(compactEntityData).split(':')[1]))
                        : 0,
                };

                if (this.isDisplayAutoCorrectedData) {
                    if (compactAutoCorrectedData) {
                        let compactValue: string;
                        let QValue: any;

                        /* Means that the entity is having Q- Value not Good */
                        if (
                            compactAutoCorrectedData &&
                            String(compactAutoCorrectedData).split(':')[1] &&
                            String(compactAutoCorrectedData).split(':')[1].indexOf(',') > 0
                        ) {
                            compactValue =
                                // tslint:disable-next-line:max-line-length
                                String(compactAutoCorrectedData)
                                    .split(':')[1]
                                    .split(',')[0]
                                    .slice(1, String(compactAutoCorrectedData).split(',')[0].length);
                            QValue = String(String(compactAutoCorrectedData).split(':')[1].split(',')[2]).slice(0, -1);
                        } else {
                            compactValue = String(compactAutoCorrectedData).split(':')[1];
                            QValue = 15;
                        }

                        correctedPoint = {
                            x: new Date(Number(prop)).getTime() * 1000,
                            y: this.correctedData(String(compactAutoCorrectedData).split(':')[1])
                                ? parseFloat(this.correctedData(String(compactAutoCorrectedData).split(':')[1]))
                                : 0,
                        };
                    }

                    depthPoint['index'] = index;

                    /* If the entity data is equal to the Auto Corrected data - means no correction is required */

                    if (depthPoint['y'] === correctedPoint['y'] || correctedPoint['y'] === 0) {
                        correctedPoint['y'] =
                            autoCorrectedData.length > 0 &&
                            autoCorrectedData[index - 1] &&
                            autoCorrectedData[index - 1]['marker']['enabled']
                                ? depthPoint['y']
                                : null;
                        correctedPoint['marker'] = {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        };
                        correctedPoint['hideToolTip'] = true;
                        correctedPoint['locationName'] = '';
                        correctedPoint['index'] = index;

                        this.addPlotBands(correctedPoint, plotband);
                    } else {
                        /* If the entity data is not equal to the Auto Corrected data - means correction is required */

                        depthPoint['marker'] = {
                            symbol: 'circle',
                            radius: 4,
                            enabled: true,
                        };

                        correctedPoint['marker'] = {
                            symbol: 'circle',
                            radius: 4,
                            enabled: true,
                        };
                        correctedPoint['index'] = index;
                        correctedPoint['locationName'] = '';

                        /*check if previous point was Depth Point then replace its null value from the series to the actual value*/
                        if (
                            autoCorrectedData.length > 0 &&
                            autoCorrectedData[index - 1] &&
                            !autoCorrectedData[correctedPoint['index'] - 1]['marker']['enabled']
                        ) {
                            plotband['from'] = autoCorrectedData[correctedPoint['index'] - 1]['x'];
                            autoCorrectedData[correctedPoint['index'] - 1]['y'] =
                                entities[correctedPoint['index'] - 1]['y'];
                        }
                    }
                }
            }
            if (depthPoint) {
                entities.push(depthPoint);
            }

            if (displayGroup.id !== 8) {
                StringUtils.addDataGaps(entities, index);
            }

            if (correctedPoint) {
                autoCorrectedData.push(correctedPoint);
            }

            index++;
        }

        return { Entites: entities, AutoCorrectedData: autoCorrectedData };
    }

    private correctedData(data) {
        if (data && data.indexOf(',') > 0) {
            return data.split(',')[0].slice(1, data.split(',')[0].length);
        } else {
            return data;
        }
    }

    /** Method to rescale hydrograph with existing visible series when user toggles visibility of series from graph legend*/
    public reScaleAutoReviewHydrographEntities(entityData: EntitySeries[], selectedSeries, displayGroupID, reScale) {
        // variables to store entityData required for yAxis scale computation and entity to hidden/show
        const cloneEntityData = Array.from(entityData);
        let entitySelectedOrHidden: EntitySeries;
        /* if rescale enabled remove existing hidden series from CloneEntitydata and also new series clicked to be hidden
         * and calculate min and max for existing visible series available from cloneEntityData
         * also push series to be hidden to rescaleEntityAbstract to track, add back if user chooses to make it visible
         */
        if (reScale) {
            if (this.reScaleEntitiesAbstract.length > 0) {
                this.reScaleEntitiesAbstract.forEach((reScaleData) => {
                    cloneEntityData.splice(
                        cloneEntityData.findIndex((x) => x.name === reScaleData['name']),
                        1,
                    );
                });
            }
            entitySelectedOrHidden = cloneEntityData.splice(
                cloneEntityData.findIndex((x) => x.name === selectedSeries),
                1,
            )[0];
            if (entitySelectedOrHidden) {
                this.reScaleEntitiesAbstract.push(entitySelectedOrHidden);
            }
        } else {
            /** Condition invoked when user clicks to add back hidden series to graph from legend. So, identify the series to be shown
             * and splice out from rescaleEntitites abstract and retain existing hidden series by splicing cloneEntityData
             * with items in rescaleEntityAbstract
             */
            entitySelectedOrHidden = this.reScaleEntitiesAbstract.find((x) => x['name'] === selectedSeries);
            this.reScaleEntitiesAbstract.splice(
                this.reScaleEntitiesAbstract.findIndex((x) => x['name'] === selectedSeries),
                1,
            );
            if (this.reScaleEntitiesAbstract.length > 0) {
                this.reScaleEntitiesAbstract.forEach((data) =>
                    cloneEntityData.splice(
                        cloneEntityData.findIndex((x) => x.name === data['name']),
                        1,
                    ),
                );
            }
        }
        // compute and update the min and max of respected scale on entity series shown or hidden
        const yAxisMax = this.returnYaxisMax(displayGroupID, cloneEntityData);
        const yAxisMin = this.returnYaxisMin(displayGroupID, cloneEntityData);
        this.alarmChartRef.yAxis
            .filter(
                (element) =>
                    element['userOptions']['title']['text'] === entitySelectedOrHidden['axis']['label'] &&
                    element.series &&
                    element.series.length > 0,
            )
            .forEach((yAxisData) => {
                yAxisData.update({
                    min: yAxisMin,
                    max: yAxisMax === -Infinity ? 10 : yAxisMax,
                });
            });
    }
}
