<div id="advanceHydroGraphId" class="contentLayout">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
        (listenConfirmation)="listenConfirmation($event)"
    ></app-customized-confirmation-box>
    <div class="fullPageloader">
        <app-loader [isLoading]="previewLoader$ | async"></app-loader>
    </div>
    <div class="header" *ngIf="filterSettings">
        <app-view-data-filter
            [editingDisabled]="editingDisabled"
            (cancelClick)="cancelDataEdit()"
            (acceptClick)="acceptDataEditing()"
            (notifyViewData)="notifyGraph($event)"
            (notifyAnnotationSelection)="notifyAnnotationSelection($event)"
            (notifyEventsChange)="notifyEventsChange($event)"
            (exportTelemetryData)="onExportTelemetryData()"
            (printDashboard)="printDashboard($event)"
            (editsReverted)="editsReverted($event)"
            (prevApprovalDates)="prevApprovalDates($event)"
            [isUSGS]="isUSGS"
            (locationChanged)="locationChangeHandler($event)"
            (refreshGraphData)="refreshAppDataHandler($event)"
            (dataEditingClick)="editData()"
            (showToolbar)="onShowToolbarChange($event)"
            (scatterConfirmationData)="onScatterConfirmationDataChange($event)"
            [defaultFilterSettings]="filterSettings"
            [defaultCustomerId]="customerId"
            [locationGroupId]="locationGroupId"
            [includeInactiveLocations]="includeInactiveLocations"
            [filterValuesForOverlay]="filterValues"
            [enableButtons]="enableHeaderButtons"
            [noDataForScatterChart]="noDataForScatterChart"
            [noDataForHydrographChart]="noDataForHydrographChart"
            [enableAccept]="enableAcceptEditor || enableAcceptNoReason"
            [entityData]="locationEntityData"
            [allLocations]="allLocations"
            [defaultSelectedLocation]="selectedLocation"
            [summarizeInterval]="setDataAveraging"
            [workOrderLink]="workOrderLink"
            [showScatterGraph]="showScatterGraph"
            [isDataEditingEnabled]="showEditingMenu"
            [minDate]="telemetryStart"
            [maxDate]="telemetryEnd"
            [rainGaugeId]="rainGaugeId"
            (hideScattergraph)="hideScattergraph()"
            (hideHydrograph)="hideHydrograph()"
            (scatterEntityBlur)="scatterEntityBlur()"
            (scatterEntitiesChange)="onScatterEntitiesChange($event)"
            (userPermissionLoaded)="userPermissionLoaded($event)"
            (editedPointsForSG)="onEditPointsForSG($event)"
            (resetEditingParams)="resetEditingParams()"
            (loadOtherLocationsData)="loadOtherLocationsData($event)"
            (updateOtherLocationsData)="updateOtherLocationsData($event)"
            (refreshVelocityGainData)="getGainData()"
            [isRawVelExistsForLocation]="isRawVelExistsForLocation"
            [isElevationExistsForLocation]="isElevationExistsForLocation"
            [series]="series"
            [installationShapeId]="installationShapeId"
            (delaySyncEntities)="needToSyncSGentitiesAfterEdit = true"
            [selectedConfirmationEntity]="confirmationEntity"
            (confirmationEntityChange)="confirmationEntityChange($event)"
            [isMetric]="isMetric"
            (resetOriginalData)="resetOriginalData($event)"
            (recalculateEntities)="recalculateEntities($event)"
        >
        </app-view-data-filter>
    </div>
    <div #printSection>
        <div class="advanceGraph border-radius-5 mat-box-shadow hidden-overflow">
            <div
                class="hydroGraphDetailsContainer"
                [ngClass]="{
                    'force-graph-to-full-width': separateWindowHydrographService.isNewTab,
                    'hide-graph': separateWindowHydrographService.isNewWindowOpened,
                    'dont-print': !(dataEditService.newHydroData$ | async) || (hydroGraphLoading$ | async),
                    'full-width': !showScatterGraph,
                    'hydroGraphDetailsContainer-hidden': !showHydroGraph,
                    hydroGraphDetailsContainer: showHydroGraph
                }"
            >
                <div class="advanceHydroGraphHeader bottom-border">
                    <div class="auto-margin headerText pad-l-15 uppercaseTransform hydrographHint">
                        {{ 'COMMON.HYDROGRAPH_TEXT' | translate }}
                    </div>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin dont-print" *ngIf="customerPermissionOnEvent && userPermissionOnEvent"><button mat-button class="add-event" (click)="addEvent()">{{ 'COMMON.ADD_EVENT' | translate }}</button></div>
                    <div class="auto-margin dont-print" [ngClass]="{'hg-btns-on-hint': selectedHintPageName === 'location-dashBoard-edit-hint'}">
                        <mat-icon
                            class="backIcon hydrograph-filter hg-new-tab-hint"
                            matTooltip="Open in new tab"
                            (click)="openHgInNewTab()"
                            *ngIf="!isForeSITELocation && !isRainAlertOrEcholoc && !separateWindowHydrographService.isNewTab && !separateWindowHydrographService.isNewWindowOpened"
                            >open_in_new
                        </mat-icon>
                        <mat-icon
                            class="backIcon hydrograph-filter hg-new-tab-close-hint"
                            matTooltip="Close"
                            (click)="closeTab()"
                            *ngIf="separateWindowHydrographService.isNewTab"
                            >open_in_new_off
                        </mat-icon>
                        <mat-icon
                            class="backIcon hydrograph-filter hg-filter-hint"
                            matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                            (click)="showHydrographManualScales = !showHydrographManualScales"
                            >filter_list
                        </mat-icon>
                        <mat-icon
                            class="backIcon hydrograph-expand hg-full-hint"
                            *ngIf="showScatterGraph && !separateWindowHydrographService.isNewTab && !separateWindowHydrographService.isNewWindowOpened"
                            matTooltip="{{ 'COMMON.FULLSCREEN_TOOLTIP' | translate }}"
                            (click)="toggleHydroGraph(true, true)"
                        >
                            fullscreen</mat-icon
                        >
                        <mat-icon
                            class="backIcon"
                            *ngIf="!showScatterGraph && !separateWindowHydrographService.isNewTab && !separateWindowHydrographService.isNewWindowOpened"
                            matTooltip="{{ 'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate }}"
                            (click)="toggleHydroGraph(false, false)"
                            >fullscreen_exit</mat-icon
                        >
                    </div>
                </div>
                <app-loader [isLoading]="hydroGraphLoading$ | async"></app-loader>
                <div class="dont-print no-data-found-section" *ngIf="(!(!noDataForHydrographChart || (hydroGraphLoading$ | async)) || (showEditingMenu && allDataFlagged))">
                    <div class="notabledataFound custom-table print-nodata-space">
                        <div class="no-data-found-img"></div>
                        <span *ngIf="!missingRain">{{ 'COMMON.NO_DATA_IN_GRAPH_MSG' | translate }}</span>
                        <span *ngIf="missingRain">{{ 'COMMON.NO_RAIN_DATA_IN_GRAPH_MSG' | translate }}</span>
                    </div>
                </div>

                <div>
                    <mat-card>
                        <!-- HYDROGRAPH -->
                        <mat-card-content class="margin-t-5" (mouseleave)="mouseOutFromHydro()">
                            <app-hydrograph-custom-ranges
                                [showCustomRanges]="showHydrographManualScales"
                                [isCustomerMetric]="isCustomerMetric"
                                [customRanges]="customRanges"
                                (customRangesChange)="customRangesChange($event)"
                                >
                            </app-hydrograph-custom-ranges>
                            <!-- Editing menu component -->
                            <app-hydrograph-editing-menu
                                *ngIf="showEditingMenu && lcChart?.edit?.editingParams && !isPrinting"
                                [customerId]="customerId"
                                [entityList]="lcChart?.editableSeriesdata"
                                [currentParams]="lcChart?.edit?.editingParams"
                                [selectedDates]="lcChart?.edit?.areaSelectedDateRange.asObservable()"
                                [selectedValueRange]="lcChart?.edit?.editingValuesSelected"
                                [doubleClick]="lcChart?.edit?.doubleClick"
                                [destinationDate]="lcChart?.destinationDate.asObservable()"
                                (performAction)="lcChart?.edit?.setAction($event)"
                                (updateParams)="lcChart?.edit?.setEditingParams($event)"
                                (entityChanged)="lcChart?.onEntityChanged()"
                                (resetInterpolateHasBeenApplied) = "lcChart?.edit?.resetInterpolateHasBeenApplied()"
                                (updateRange)="lcChart?.edit?.onRangeChanged($event)"
                                (applyCopyPaste)="lcChart?.edit?.applyCopyPaste($event)"
                                (interpolateAccept)="lcChart?.edit?.applyInterpolate($event)"
                                (destinationDateChange)="lcChart?.edit?.setDestinationDate($event)"
                                (updateMultiplePointsRules)="lcChart?.edit?.applyMultiPointEdit($event)"
                                [isRainGauge]="isRainGauge"
                                [arePointsSelected]="lcChart?.edit?.arePointsSelected"
                                [selectedMoreThenHour]="lcChart?.edit?.selectedMoreThenHour"
                                [interpolateHasBeenApplied] = "lcChart?.edit?.interpolateHasBeenApplied"
                                [isRawVelExistsForLocation]="isRawVelExistsForLocation"
                                >
                            </app-hydrograph-editing-menu>
                            <app-hydrograph-tracer-panel
                                *ngIf="(dataEditService.newHydroData$ | async) && ((entityGroupingsHydroNEW && !noDataForHydrographChart) || (showEditingMenu && !allDataFlagged)) && !isPrinting"
                                (zoomButtonClicked)="tracerZoomButtonClicked($event)"
                                (tracerButtonClicked)="tracerOptionChanged($event)"
                                [selectedDateRange]="selectedAreaRange$ | async"
                                [maximumDateRange]="selectedDateRange$ | async"
                                [chartInfoText]="chartInfoText$.asObservable()"
                            ></app-hydrograph-tracer-panel>
                            <div [id]="chartId" class="lc-chart"></div>
                            <app-hydrograph-legend
                                *ngIf="!noDataForHydrographChart"
                                [isDataQuality]="annotationSettings?.isDataQuality"
                                [legendSeriesDefinition]="lcChart?.legendSeriesDefinition"
                                [legendEventDefinition]="lcChart?.legendEventDefinition"
                                (serieVisibility)="lcChart?.updateSeriesVisibility($event)"
                                (eventVisibility)="lcChart?.updateEventVisibility($event)"
                            ></app-hydrograph-legend>

                            <div *ngIf="!isPrinting"
                                class="missingParameters"
                                [ngClass]="{ 'dont-print': !missingRain && !missingLevel && !noDataForHydrographChart }"
                                [hidden]="!missingRain && !missingLevel && !noDataForHydrographChart && !missingSecondaryLocsData"
                            >
                               <span *ngIf="missingSecondaryLocsData !== ''"
                                    >{{ 'COMMON.NO_DATA_SECONDARY_LOCS' | translate }} {{ missingSecondaryLocsData }}</span
                                >
                                <span *ngIf="missingParameters !== ''"
                                    >No data available for the following parameters: {{ missingParameters }}</span
                                >
                                <span *ngIf="(dataEditService.newHydroData$ | async) && missingRain && (rainGaugeId === null || rainGaugeId === undefined)">{{
                                    'COMMON.NO_RAIN_DATA_AVAILABLE_FOR_THIS_PARAMETER' | translate: unit
                                }}</span>
                                <span *ngIf="(dataEditService.newHydroData$ | async) && missingRain  && (rainGaugeId !== null && rainGaugeId !== undefined)">{{
                                    'COMMON.NO_RAIN_DATA_IN_GRAPH_MSG' | translate
                                }}</span>
                                <span *ngIf="(dataEditService.newHydroData$ | async) && missingLevel">{{
                                    'COMMON.NO_LEVEL_DATA_IN_GRAPH_MSG' | translate: unit
                                }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div
                class="scattergraphGraphDetailsContainer"
                [ngClass]="{
                    'force-graph-to-full-width': separateWindowHydrographService.isNewWindowOpened,
                    'hide-graph': separateWindowHydrographService.isNewTab,
                    'dont-print': hideScatterGraph || scattergraphLoading,
                    'full-width': !showHydroGraph,
                    'scattergraphGraphDetailsContainer-hidden': !showScatterGraph,
                    scattergraphGraphDetailsContainer: showScatterGraph
                }"
            >
                <div class="advanceHydroGraphHeader bottom-border">
                    <div class="auto-margin headerText uppercaseTransform pad-l-15 scatterGraphHint">
                        {{ 'COMMON.SCATTERGRAPH_TEXT' | translate }}
                    </div>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin dont-print">
                        <mat-icon
                            class="backIcon hydrograph-filter hg-filter-hint"
                            matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                            (click)="showScattergraphManualScales = !showScattergraphManualScales"
                            >filter_list
                        </mat-icon>
                        <mat-icon
                            class="backIcon scattergraph-expand sg-full-hint"
                            *ngIf="showHydroGraph && !separateWindowHydrographService.isNewWindowOpened && !separateWindowHydrographService.isNewTab"
                            (click)="toggleScatterGraph(true)"
                            matTooltip="{{ 'COMMON.FULLSCREEN_TOOLTIP' | translate }}"
                        >
                            fullscreen</mat-icon
                        >
                        <!-- adsPopupWindow [popupElement] ="advanceScatterGraph"
                            [poupHeight] ="800" [poupWidth] ="800" [isComponentElement] = "true" -->
                        <mat-icon
                            class="backIcon"
                            *ngIf="!showHydroGraph && !separateWindowHydrographService.isNewWindowOpened && !separateWindowHydrographService.isNewTab"
                            matTooltip="{{ 'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate }}"
                            (click)="toggleScatterGraph(false)"
                            >fullscreen_exit
                        </mat-icon>
                    </div>
                </div>
                <div class="missingParameters" *ngIf="hideScatterGraph">
                    Select DEPTH and VELOCITY entities to view scattergraph
                </div>
                <div *ngIf="!hideScatterGraph">
                    <app-advance-scattergraph-container
                        #advanceScatterGraph
                        [isLoading]="scattergraphLoading"
                        [scatterGraphConfirmationData]="scatterGraphConfirmationData"
                        [locationId]="locationId"
                        [locationDetails]="locationDetails"
                        [annotationSettings]="annotationSettings"
                        [data]="scatterGraphData"
                        [scatterDateFormat]="scatterDateFormat"
                        [showCustomRangesForScattergraph]="showScattergraphManualScales"
                        [tooltipTimeFormat]="scatterTimeFormat"
                        [filterValues]="filterValues"
                        [isDataEditingModeEnabled]="showEditingMenu"
                        [editedPoints]="editedPointsForSg"
                        [effectiveRoughness]="effectiveRoughness"
                        [slope]="slope"
                        [isSnapToCurveButton]="scatterGraphEnableSnapToCurveOption"
                        [zoomFromHydro]="zoomForScatter$ | async"
                        [showCurveEnabled]="showCurveEnabled"
                        (enablePreviewChange)="scatterGraphApplySnapCurveChange($event)"
                        (updateAnnotations)="onScatterCurveChanged($event)"
                        (dataSelectForSnap)="onDataSelectForSnap($event)"
                        [entityData]="locationEntityData"
                        [selectedEntityIds]="selectedSGEntityIds"
                        (scatterResponse)="onScatterResponse($event)"
                        (curveChanged)="onCurveChanged($event)"
                        [isPrinting]="isPrinting"
                        [validPipeTable]="!editingDisabled"
                        [selectedConfirmationEntity]="confirmationEntity"
                    >
                    </app-advance-scattergraph-container>
                </div>
            </div>
        </div>

        <div *ngIf="!isPrinting">
            <app-min-max-avg-total-table
                [customerId]="customerId"
                [locationId]="locationId"
                [start]="hydrographStartDate"
                [end]="hydrographEndDate"
                [entityIds]="getHydrographParams.entityIds"
                [summarizeInterval]="getHydrographParams.summarizeInterval"
            ></app-min-max-avg-total-table>
        </div>
        <!--Long Table for location dashboard -->
        <div>
            <ads-prism-data-editing-table
                #dataEditingTable
                [customerId]="customerId"
                [locationId]="locationId"
                [selectedEntities]="selectedEntities"
                [startDate]="startDate"
                [endDate]="endDate"
                [data]="dataEditService.newHydroData$ | async"
                [selectedDateRange]="debouncedZoomForLongTable | async"
                [summarizeInterval]="getHydrographParams ? getHydrographParams.summarizeInterval : null"
                [isRefreshDataTable]="isRefreshDataTable"
                [customerDateFormat]="customerDateFormat"
                [timeFormat]="timeFormat"
                [editable]="showEditingMenu"
                (rowEditing)="longTableRowEditing($event)"
            >
            </ads-prism-data-editing-table>
        </div>
    </div>

    <div class="customDialogLayer" *ngIf="showResonForEdit">
        <div class="customDialog">
            <div class="customDialogPosition">
                <div id="resonForEditId">
                    <div class="popupHeaderWithButton" mat-dialog-title>
                        <div class="auto-margin pad-l-15">
                            {{ 'LOCATION_DASHBOARD.DATA_EDITING.EDIT_SESSION_COMMENTS' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                    </div>
                    <div mat-dialog-content class="eachTabContent">
                        <div class="impact-space">
                            {{ 'LOCATION_DASHBOARD.DATA_EDITING.APPLY_INFO_BY_DATE_TEXT' | translate }}
                        </div>
                        <div class="impact-space de-dialog-entities-hint">
                            {{ 'LOCATION_DASHBOARD.DATA_EDITING.IMPACTED_ENTITIES_TEXT' | translate }}
                            <span class="eachEntityEdited" *ngFor="let entity of entitiesEdited">
                                {{ entity }} <span class="listComma">, </span>
                            </span>
                        </div>
                        <div *ngFor="let num of numberOfTimes; let i = index" [attr.data-index]="i">
                            <div class="eachEditComment">
                                <div class="full-width de-dialog-reason-hint">
                                    <app-multi-select-auto-complete
                                        placeholder="{{
                                            'LOCATION_DASHBOARD.DATA_EDITING.REASON_COLUMN_TITLE' | translate
                                        }}"
                                        name="reason"
                                        [items]="reasonsSelector[i]"
                                        [minDisplayLimit]="1"
                                        [maxDisplayLimit]="reasonsSelector[i].length ? reasonsSelector[i].length : 12"
                                        (currentlySelectedItems)="currentlySelectedReasons($event, i)"
                                    >
                                    </app-multi-select-auto-complete>
                                </div>
                                <div [hidden]="hideCommentSection[i] && selectedHintPageName !== 'de-dialog-hint'">
                                    <mat-form-field class="full-width de-dialog-comment-hint">
                                        <textarea
                                            [required]="requiredComment"
                                            [(ngModel)]="otherReason"
                                            matInput
                                            placeholder="{{
                                                'LOCATION_DASHBOARD.DATA_EDITING.REASON_OTHER' | translate
                                            }}"
                                            (ngModelChangeDebounced)="otherReasonState($event)"
                                            matTextareaAutosize
                                            maxlength="60"
                                            matAutosizeMinRows="1"
                                            matAutosizeMaxRows="5"
                                        ></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="csvHeader" *ngIf="false">
                            <button mat-button class="exportCSVButton" (click)="addNewTimeSpan()"
                                [disabled]="isCopyPasteOperationEnabled">{{ 'LOCATION_DASHBOARD.DATA_EDITING.ADD_NEW_TIMESPAN_BUTTON'
                        | translate }}</button>
                        </div> -->
                    </div>
                    <div class="data-approval de-dialog-approve-hint">
                        <div>
                            <mat-slide-toggle (change)="onApprovalDateToggle($event)">
                                <b>Approve Data</b>
                            </mat-slide-toggle>
                        </div>
                        <p *ngIf="!startDte && !endDte">Please select a date the data is approved through</p>
                        <app-approve-data [endDate]="endDte" [prevApprStartDate]="prevApprStartDate" [prevApprEndDate]="prevApprEndDate"  [disabled]="datesDisabled" (startDateChange)="startDateChanged($event)" (IsErrorOnApproveData)="ErrorOnApproveData($event)" ></app-approve-data>
                    </div>
                    <div mat-dialog-actions>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin">
                            <button (click)="emitDataEditingDialog()" class="de-dialog-cancel-hint" mat-button>
                                {{ 'COMMON.CANCEL_BUTTON' | translate }}
                            </button>
                        </div>
                        <div class="auto-margin">
                            <button mat-button (click)="submitTelemetryData()" class="de-dialog-accept-hint" [disabled]="isReasonSelected || isErrorOnApproveData">
                                {{ 'COMMON.SUBMIT_BTN' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
