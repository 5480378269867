import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { UUID } from 'angular2-uuid';
import {
    DEPTH_ENTITY,
    QCONTINUITY,
    RAIN,
    RAIN_ENTITY,
    UNIDEPTH,
    VELOCITY,
    VELOCITY_ENTITY,
    SERIES_ONE,
    RAIN_HEXCODE_FLAGGED,
    DEPTH,
    DataEditType,
    RED_COLOR_HEX,
    RAIN_HEXCODE_UNFLAGGED,
    DEFAULT_MARKER_COLOR,
    C_FLOW,
    C_DEPTH,
    C_VELOCITY,
    EntityGroupId,
    EDIT_COLOR,
} from '../constant';
import { DataTableResponse, DataTableArgs, DataRowItem } from 'app/pages/data-editing-table/data-editing-table.model';
import { DataEditService } from './data-edit.service';
import { StringUtils } from '../utils/string-utils';
import { StatusCodeService } from './status-code.service';
import { GraphTypes as GraphType } from '../enums/graph-types';
import {
    AdsChartType,
    AnnotationSettings,
    DataEditingActionType,
    DataEditingData,
    DataEditingReasons,
    DataEditingRow,
    DateTimeSetting,
    FlaggedPoint,
    LaunchDataEditingTable,
    OptimizedViewData,
    SyncObject,
} from '../models/view-data';
import { DataEditingParams, HydrographArgs, ManualScale } from '../models/hydrograph';
import { EntityData, HistoricalCurve, ScatterData } from '../models/scatter-data';
import { LocationDashboardFilterData } from '../models/location-dashboard-filter-data';
import { DataEditingCurveTypes, DataEditPreview, DataEditPreviewParams, SnapDataResponse } from '../models/data-edit';
import { Entities, EntitySelectorObject } from '../models/entities';
import { map, tap } from 'rxjs/operators';
import _ from 'lodash';
import { Series } from 'highcharts';
import { SeriesProperty } from 'app/pages/dashboards/custom-dashboard/custom-dashboard-model/custom-dashboard.model';
import { LightningChartObject } from '../components/hydrograph/lightning-chart-object/lightning-chart-object';
import { DisplayGroupScales } from '../models/user-settings';
import { ConfirmationEntitiesEnum } from '../models/view-data-filter';
const MS_PER_DAY = 1000 * 60 * 60 * 24;
export class ScattergraphQuery {
    public start: string;
    public end: string;
    public entityIds: Array<number>;
    public pipeHeight: boolean;
    public manholeDepth: boolean;
    public isoQ: boolean;
    public pipeOverlay: boolean;
    public bestfit: boolean;
    public colebrookWhite: boolean;
    public manningDesign: boolean;
    public lanfearColl: boolean;
    public editingMode: boolean;
    public froude: boolean;
    public stevensSchutzbach: boolean;
    public temporaryFlags: Array<string>;
    public confirmations: boolean;
    public confirmationStart?: string;
    public confirmationEnd?: string;
    public includeInactiveLocations?: boolean;
    public summarizeInterval?: number; // adding optional summarizeinterval for scattergraph
    public silt?: boolean
}

const ACTIVE_ZOOM = 'activeZoomFlag';
const _SELECT_DATA = 'activeDataFlag';
const _CLEAR_SELECT = 'activeClearFlag';
const _IGNORE_DATA = 'activeFlagPoint';
const _UNIGNORE_DATA = 'activeUnflagPoint';
const NAVIGATOR = 'navigator';

export interface ConfirmationFilter {
    type?: 'all' | 'date';
    text?: string;
    dateRange?: { start?: Date; end?: Date }
}

@Injectable()
export class ViewDataService {
    // Represents co-relation between hydrograph and scattergraph
    public charts = new Array<any>();
    public advancedCharts = new Array<any>();
    public annotationsSettings = new BehaviorSubject<AnnotationSettings>(null);
    public dateTimeFormats = new BehaviorSubject<DateTimeSetting>(null);
    public scatterMenuItem = new BehaviorSubject(ACTIVE_ZOOM);
    public highlightScatterPoint = new BehaviorSubject(null);

    public dataEditingData = new BehaviorSubject<DataEditingData[]>(null);
    public selectedTimeStamps = new BehaviorSubject<Object>(null);
    public resetZoomClicked = new BehaviorSubject<boolean>(false);
    public selectedScatterPoints = new BehaviorSubject<number[]>(null);
    public closeDataEditingMode = new BehaviorSubject<boolean>(null);
    public previewDataEditing = new BehaviorSubject<boolean>(false);
    public acceptDataEditing = new BehaviorSubject<boolean>(false);
    public acceptAllAutoCorrectedVal = new BehaviorSubject<boolean>(false);
    public clearFilter = new BehaviorSubject<boolean>(false);
    public enablePreview = new BehaviorSubject<boolean>(true);
    public enableAccept = new BehaviorSubject<boolean>(true);
    public previewLoader = new BehaviorSubject<boolean>(false);

    public notifyAdvGraphWithEditedValues = new BehaviorSubject<DataEditingData>(null);
    public notifyAdvGraphWithAllEditedValues = new BehaviorSubject<DataEditingData>(null);
    public notifyDataEditingTable = new BehaviorSubject<DataEditingRow>(null);
    public selectPointOnHydrograph = new BehaviorSubject<DataEditingData>(null);
    public unSelectPointOnHydrograph = new BehaviorSubject<DataEditingData>(null);
    public isDataEditingOpen = new BehaviorSubject<boolean>(false);
    public reloadLocationDashboard = new BehaviorSubject<boolean>(false);
    public launchDataEditingTable = new BehaviorSubject<LaunchDataEditingTable>(null);
    public filterValues = new BehaviorSubject<LocationDashboardFilterData>(null);
    public selectedSnappedPoints = new BehaviorSubject<EntityData[]>(null);
    public dataEditingSubmitOnHydrograph = new BehaviorSubject<HydrographArgs>(null);
    public notifySnapPointsToDataEditingTable = new BehaviorSubject<ScatterData>(null);
    public updatedPoints = new BehaviorSubject<Array<Object>>(null);
    public flaggedMarkerStyle: Object;

    public editedMarkerStyle: Object;
    public defaultMarkerStyle: Object;
    public viewDataParameters = new BehaviorSubject<HydrographArgs>(null);
    public scatterGraphQuery: ScattergraphQuery;
    public flaggedPoints = new BehaviorSubject<Array<Object>>(null);
    public unFlaggedPoints = new BehaviorSubject<Array<Object>>(null);
    public collectiveFlaggedPoints = new BehaviorSubject<Array<Object>>(null);
    public flagOptionSelected = new BehaviorSubject<boolean>(false);
    public copiedPoints = new BehaviorSubject<Array<Object>>(null);
    public updateDataTableWithCopiedPoints = new BehaviorSubject<object>(null);
    public entitiesList = new BehaviorSubject<Array<Entities>>(null);
    public multiPointEditPoints = new BehaviorSubject<Array<Object>>(null);
    public updatedMultiPointEditValues = new BehaviorSubject<object>(null);
    public hydroGraphFilterData: HydrographArgs;
    public scatterGraphToolData = new BehaviorSubject<object>(null);
    public loadScatterGraphToolData = new BehaviorSubject(null);
    public scatterManualCurveSelectedPoints = new BehaviorSubject<EntityData[]>([]);

    public lockSGCurve = new BehaviorSubject(false);
    private ScatterElement;
    public scatterToleranceRangeValue = 0;
    public scattergraphAvailableEntities: EntitySelectorObject[] = [];

    public hydrographManualScales: DisplayGroupScales[];
    public scattergraphManualScales: ManualScale[];

    public isCurveEditable = false; // Whenver currently choosen curve is manual one

    private cachedFilters: {
        annotations?: AnnotationSettings;
        startDate?: Date;
        endDate?: Date;
        confirmationFilter?: ConfirmationFilter;
        selectedConfirmationEntity?: ConfirmationEntitiesEnum;
    }

    private cacheDataEditReasons?: {
        reasons: DataEditingReasons;
    }

    /** Cache for saved curves */
    savedCurvesCache?: {
        response: string[],
        cid: number,
        lid: number;
    }

    constructor(
        public http: HttpClient,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
    ) {
        this.editedMarkerStyle = {
            symbol: `diamond`,
            radius: 4,
            enabled: true,
            fillColor: EDIT_COLOR,
        };

        this.defaultMarkerStyle = {
            symbol: `diamond`,
            radius: 4,
            enabled: true,
            fillColor: DEFAULT_MARKER_COLOR,
        };

        this.flaggedMarkerStyle = {
            symbol: 'diamond',
            radius: 4,
            enabled: true,
            fillColor: RED_COLOR_HEX,
        };
    }

    public cachedFiltersCreate() {
        this.cachedFilters = {};
    }

    public cachedFiltersGet() {
        if(!this.cachedFilters) this.cachedFiltersCreate();
        return this.cachedFilters;
    }

    public cachedFiltersClear() {
        this.cachedFilters = null;
    }

    public getOtherLocationHydrograph(customerId: number, item: SeriesProperty, summarizeInterval = 0) {
        const params: HydrographArgs = {
            start: item.startDate,
            end: item.endDate,
            entityIds: [item.eid],
            locationId: item.lid,
            summarizeInterval: summarizeInterval
        }

        return this.getHydrograph(customerId, item.lid, params);
    }

    public getHydrograph(customerId: number, locationId: number, params: HydrographArgs, reloadCache = true) {
        // #25192 remove duplicate entity ids
        params.entityIds = params.entityIds.filter((v, i) => i === params.entityIds.indexOf(v));

        const startDate = (params.start ? this.dateutilService.getLocalDateFromUTCDate(params.start) : params.start);
        const endDate = (params.end ? this.dateutilService.getLocalDateFromUTCDate(params.end) : params.end);

        const start = startDate ? startDate.toISOString().slice(0,-5) : null;
        const end = endDate ? endDate.toISOString().slice(0,-5) : null;

        params = ({ ...params, start, end });
        params.includeInactiveLocations = true;
        if(params.entityIds) {
            params.entityIds.sort();
        }

        // Remove all empty fields from object so _.isEqual will be accurate
        Object.keys(params).forEach(x => (params[x] === undefined || params[x] === null) && delete params[x]);

        const url = `api/Hydrograph/V2/Average?customerId=${customerId}&locationId=${locationId}`;
        return this.http.post<OptimizedViewData>(Config.serviceUrl + url, params)
    }

    // get the scattergraph details
    public getScatterpgraph(
        customerId: number,
        locationId: number,
        start: Date,
        end: Date,
        entityIds?: Array<number>,
        isPipeOverLay = false,
    ) {
        const isoStartDate = this.dateutilService.getStartDateAsTimeZone(start);
        const rawStartDate = isoStartDate.split('.');
        const startDate = rawStartDate[0];

        const isoEndDate = this.dateutilService.getEndDateAsTimeZone(new Date(new Date(end)));
        const rawEndDate = isoEndDate.split('.');
        const endDate = rawEndDate[0];

        let url = `api/Scattergraph?customerId=${customerId}&locationId=${locationId}
      &Start=${startDate}&End=${endDate}&includeInactiveLocations= ${true}&PipeOverlay=${isPipeOverLay}`;

        if (entityIds && entityIds.length > 0) {
            entityIds.forEach((element) => {
                url = url.concat(`&EntityIds=${element}`);
            });
        }

        return this.http.get<ScatterData>(`${Config.serviceUrl}${url}`).pipe(
            map((res: ScatterData) => {
                return res;
            }),
        );
    }

    public getDataEditingTable(
        customerId: number,
        locationId: number,
        args: DataTableArgs,
    ): Observable<DataTableResponse> {
        const start = args.start.toISOString();
        const end = args.end.toISOString();
        const url = `${Config.urls.DataTable.fetch}?cid=${customerId}&lid=${locationId}`;
        return this.http.post<DataTableResponse>(`${Config.serviceUrl}${url}`, { ...args, start, end });
    }

    // get the scattergraph details as per V2 api
    public getScatterGraphOptimized(
        customerId: number,
        locationId: number,
        start: Date,
        end: Date,
        isPipeOverLay = false,
        isPipeHeight: boolean,
        isManholeDepth: boolean,
        isIsoQ: boolean,
        isBestFit: boolean,
        isSSCurve: boolean,
        isCWRcurve: boolean,
        isManningDesigncurve: boolean,
        isLanfearCollCurve: boolean,
        isFroude: boolean,
        tempFlags: Array<string>,
        confirmationData: { type?: string; dateRange?: { start: string; end: string } },
        entityIds?: Array<number>,
        summarizeInterval?: number,
        silt?: boolean,
        reloadCache = true
    ) {

        const startDate = (start ? this.dateutilService.getLocalDateFromUTCDate(start) : start);
        const endDate = (end ? this.dateutilService.getLocalDateFromUTCDate(end) : end);

        const startStr = startDate ? startDate.toISOString().slice(0,-5) : null;
        const endStr = endDate ? endDate.toISOString().slice(0,-5) : null;

        this.scatterGraphQuery = new ScattergraphQuery();
        this.scatterGraphQuery.start = startStr;
        this.scatterGraphQuery.entityIds = new Array<number>();

        this.scatterGraphQuery.end = endStr;
        this.scatterGraphQuery.pipeOverlay = isPipeOverLay;

        this.scatterGraphQuery.confirmations = !!confirmationData.type;

        if (confirmationData.type && confirmationData.type === 'date') {
            const isoStartConfirmation = this.dateutilService.getEndDateAsTimeZone(
                new Date(confirmationData.dateRange.start),
            );
            const rawStartConfirmation = isoStartConfirmation.split('.');
            this.scatterGraphQuery.confirmationStart = rawStartConfirmation[0];
            this.scatterGraphQuery.confirmationEnd = this.dateutilService.getEndDateAsTimeZone(
                new Date(confirmationData.dateRange.end),
            );
        }
        // additional parameters added in V2 api for optimized scattergrpah
        this.scatterGraphQuery.pipeHeight = isPipeHeight;
        this.scatterGraphQuery.manholeDepth = isManholeDepth;
        if (isIsoQ === undefined) {
            this.scatterGraphQuery.isoQ = false;
        } else {
            this.scatterGraphQuery.isoQ = isIsoQ;
        }
        this.scatterGraphQuery.bestfit = isBestFit;
        this.scatterGraphQuery.colebrookWhite = isCWRcurve;
        this.scatterGraphQuery.manningDesign = isManningDesigncurve;
        this.scatterGraphQuery.lanfearColl = isLanfearCollCurve;
        this.scatterGraphQuery.editingMode = isBestFit || isSSCurve || isCWRcurve || isManningDesigncurve || isLanfearCollCurve;
        this.scatterGraphQuery.froude = isFroude;
        this.scatterGraphQuery.stevensSchutzbach = isSSCurve;
        this.scatterGraphQuery.temporaryFlags = tempFlags;
        this.scatterGraphQuery.silt = silt;
        this.scatterGraphQuery.summarizeInterval = !summarizeInterval ? 0 : summarizeInterval;

        const url = `api/Scattergraph/V2?customerId=${customerId}&locationId=${locationId}`;

        const includeInactiveLocations = this.statusCodeService.activeInactiveHandler.getValue();
        this.scatterGraphQuery.includeInactiveLocations = includeInactiveLocations;
        if (entityIds && entityIds.length > 0) {
            this.scatterGraphQuery.entityIds.push(...entityIds);
        }

        // api updated to V2 and request params added in post api.
        return this.http.post<ScatterData>(`${Config.serviceUrl}${url}`, this.scatterGraphQuery);
    }

    /**
     * Method Which Resets Chart Series Data
     * @param dateTime => Represent hydrograph or scattergraph indexing time
     * @param chartIndex => Chart Index
     */
    public setChartParameter(dateTime: number, chartIndex: number) {
        if (this.charts.length > 0) {
            this.charts.forEach((chart) => {
                chart.tooltip.hide();
            });

            if (this.charts[chartIndex]) {
                this.charts[chartIndex].series
                    .filter((s) => s.data && s.data.length > 0)
                    .forEach((seriesElement) => {
                        seriesElement.data
                            .filter((element) => element.dateTime === dateTime || element.x === dateTime)
                            .forEach((item) => item.setState(''));
                    });
            }
        }
    }

    /**
     * Method Which Resets Chart Series Data
     * @param dateTime => Represents hydrograph or scattergraph indexing time
     * @param chartIndex => Chart Index
     */
    public setChartParameterAdvanced(dateTime: number, chartIndex: number) {
        if (this.advancedCharts.length > 0) {
            this.advancedCharts.forEach((chart) => {
                if(chart instanceof LightningChartObject) {
                    chart.tooltip.tooltipHide();
                } else {
                    if (chart && chart.tooltip) {
                        chart.tooltip.hide();
                    }
                }
            });
            if (this.advancedCharts[chartIndex] && this.advancedCharts[chartIndex].series) {
                this.advancedCharts[chartIndex].series
                    .filter((element) => element.data && element.data.length > 0)
                    .forEach((seriesElement) => {
                        seriesElement.data
                            .filter((child) => child.dateTime === dateTime || child.x === dateTime)
                            .forEach((dataElement) => dataElement.setState(''));
                    });
            }
        }
    }

    /**
     * Method which provides co-relatation between hydrograph and scattergraph
     * @param container => Represents chart container
     * @param dateTime => Represents hydrograph or scattergraph indexing time
     */
    public corelateGraphs(container: HTMLElement, dateTime: number): void {
        for (let i = 0; i < this.charts.length; i++) {
            if (
                this.charts[i] &&
                this.charts[i].container &&
                container &&
                container.id !== this.charts[i].container.id &&
                this.charts[i].tooltip.shared
            ) {
                const seriesData = new Array<number>();
                for (let j = 0; j < this.charts[i].series.length; j++) {
                    if (this.charts[i].series[j].visible && this.charts[i].series[j].data.length > 0) {
                        this.charts[i].series[j].data
                            .filter((element) => element.dateTime === dateTime || element.x === dateTime)
                            .forEach((element, indexer) => {
                                seriesData.push(this.charts[i].series[j].data[indexer]);
                            });
                    }
                }
                if (seriesData.length > 0) {
                    this.charts[i].tooltip.refresh(seriesData);
                }
            }
        }
    }

    /**
     * Method which provides co-relatation between hydrograph and scattergraph
     * @param container => Represents chart container
     * @param dateTime => Represents hydrograph or scattergraph indexing time
     */
    public correlateAdvancedGraphs(container: HTMLElement, dateTime: number): void {
        if(!container) return;

        let seriesDataArray = new Array<Series>();
        let seriesData: number;

        // location dashboard charts, index 0 is hydrograph, index 1 is scattergraph
        this.advancedCharts.forEach((chart, index) => {
            if (!chart
                || (chart.container && container.id === chart.container.id)
                || (chart instanceof LightningChartObject && (chart as LightningChartObject).dashboard?.engine?.container?.id === container.id)
                ) {
                return;
            }

            if(chart && chart instanceof LightningChartObject) {
                chart.tooltip.tooltipSynchronize(dateTime, false);
            } else {
                // has to be SG as HG is LightningChartObject
                const isScattergraph = true;// index === 1;

                if (isScattergraph) {
                    this.highlightScatterPoint.next({ container, dateTime });
                }

                const dataSeries = chart.series.filter(series => series.visible && series.data && series.data.length > 0);

                dataSeries.forEach((series) => {
                    const point = series.data.find(v => (v.dateTime && v.dateTime === dateTime) || (v.x && v.x === dateTime));

                    if (!point) return;

                    if (chart.tooltip.shared) {
                        seriesDataArray.push(point);
                    } else {
                        seriesData = point;
                        chart.tooltip.refresh(seriesData);
                    }

                    if (point.graphic) {
                        point.graphic.toFront();
                    }

                });

                if (chart.tooltip.shared && seriesDataArray.length > 0) {
                    seriesDataArray = seriesDataArray.filter((v) => v && v.series && !v.series.name.toLowerCase().includes(NAVIGATOR));
                    chart.tooltip.refresh(seriesDataArray);

                    // for unknown reason highcharts will not display tooltip for some points, so we have to display tooltips on our own
                    if (chart.tooltip.isHidden && chart.tooltip.label) {
                        this.forceShowTooltip(chart, seriesDataArray);
                    }
                }
            }
        });
    }

    // piece of tooltip logic from highchart's source code, used when highchart refuses to show tooltip for some data points
    private forceShowTooltip(chart, seriesDataPoints) {
        const formatter = chart.tooltip.options.formatter;
        const point = seriesDataPoints[0];
        const [x, y] = chart.tooltip.getAnchor(seriesDataPoints)

        const formattedPoints = seriesDataPoints.map((point) => ({
            color: point.color,
            key: point.category,
            point,
            series: point.series,
            x: point.x,
            y: point.y
        }))

        const textConfig = {
            x: point.category,
            y: point.y,
            points: formattedPoints
        };

        const text = formatter.call(textConfig, chart.tooltip);
        const label = chart.tooltip.getLabel();

        label.attr({
            text: text && text.join ?
                text.join('') :
                text
        });

        chart.tooltip.updatePosition({
            plotX: x,
            plotY: y,
            negative: point.negative,
            ttBelow: point.ttBelow,
            h: 0
        });

        chart.tooltip.label.attr({ opacity: 1 }).show();
    }

    /**
     * Method which highlights the zoomed points from Hydrographs on scattergraph
     * @param scatterHighlightPoints => Represents timestamp of maximum and minimum zoomed value from the Axis
     */
    public highlightScatterChartPoints(points: any) {
        if (!this.advancedCharts[1].series || !this.advancedCharts[1].series.length) {
            return;
        }
        this.advancedCharts[1].series[0].data.forEach((element) => {
            for (let i = 0; i < points.length; i++) {
                if (points[i].x === element.dateTime) {
                    if (points[i].series.name === UNIDEPTH) {
                        element.update({ y: points[i].y });
                    } else if (points[i].series.name === VELOCITY) {
                        element.update({ x: points[i].y });
                    }
                    element.color = '#FF0000';
                    this.advancedCharts[1].redraw();
                    element.select();
                }
            }
        });
    }

    /**
     * Method which highlights the zoomed points from Hydrographs on scattergraph
     * @param scatterHighlightPoints => Represents timestamp of maximum and minimum zoomed value from the Axis
     */
    public highlightAdvancedScatterChartPoints(scatterHighlightPoints: Object) {
        if (scatterHighlightPoints && this.advancedCharts[1]) {
            this.advancedCharts[1].series[0].data.forEach((element) => {
                if (element.color !== RED_COLOR_HEX) {
                    element.color =
                        element.dateTime >= scatterHighlightPoints['minValue'] &&
                        element.dateTime <= scatterHighlightPoints['maxValue']
                            ? 'rgba(255, 217, 15)'
                            : 'rgba(119, 152, 191, .5)';
                } else {
                    element.select();
                }
            });
            this.advancedCharts[1].series[0].redraw();
        } else {
            if (this.advancedCharts[1] && this.advancedCharts[1].series) {
                this.advancedCharts[1].series[0].data.forEach((element) => {
                    if (element.color !== RED_COLOR_HEX) {
                        element.color = 'rgba(119, 152, 191, .5)';
                    } else {
                        element.select();
                    }
                });
                this.advancedCharts[1].series[0].redraw();
            }
        }
    }

    private selectedElements = [];

    /**
     * Method which provides co-relatation between hydrograph and scattergraph
     * @param dateTime => Represents hydrograph or scattergraph indexing time
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public selectAdvancedGraphs(
        dateTime: number
    ): void {
        for(const element of this.selectedElements) {
            if(element.options && element.options.color) {
                element.color = element.options.color;
            }
            if(element.select) {
                element.select(false);
            }
        }
        if(this.ScatterElement) {
            this.ScatterElement.color = undefined;
            if(this.ScatterElement.select) this.ScatterElement.select(false);
            this.ScatterElement = null;
        }
        this.selectedElements = [];

        for (let i = 0; i < this.advancedCharts.length; i++) {
            const chart = this.advancedCharts[i];
            const series = chart?.series;
            const seriesDataArray = new Array<number>();


            if(chart instanceof LightningChartObject) {
                chart.tooltip.tooltipSynchronize(dateTime);
            } else if (chart && series) {
                for (let j = 0; j < series.length; j++) {
                    const serie = this.advancedCharts[i].series[j];
                    const data = serie.data;

                    let found = false;
                    if (data && data.length > 0) {
                        if(serie && serie.name && serie.name.toLowerCase().startsWith('navigator')) {
                            continue;
                        }
                        for(let indexer = 0; indexer < data.length; indexer++) {
                            const element = data[indexer];
                            if(i === 0 && indexer === series.length - 1) {
                                element.select(false);
                            }
                            if (element && (element.dateTime === dateTime || element.x === dateTime)) {
                                if (this.advancedCharts[i].tooltip.shared) {
                                    seriesDataArray.push(element);
                                    this.selectedElements.push(element);
                                } else {
                                    this.advancedCharts[i].tooltip.refresh(element);
                                    if(this.ScatterElement) {
                                        this.ScatterElement.color = undefined;
                                        if(this.ScatterElement.select) this.ScatterElement.select(false);
                                    }
                                    this.ScatterElement = element;
                                }

                                // To highlight scatter point if its overlapping with other points for more visibility
                                if (element.graphic) {
                                    element.graphic.toFront();
                                }

                                element.color = 'rgba(255, 217, 15)';
                                element.select(true);

                                found = true;
                            } else if(i === 0) {
                                //
                                // Highchart - we need to unselect one more point, otherwise last serie point won't be displayed in yellow
                                // Point is selected but state 'hover' is responsible for yellow color. This state will apply if we will 'touch' another point.
                                // TODO: For last point of HG it won't highlight it - will need some effort to achieve
                                // ---
                                // Scattegraph - we cannot unselect one more point, otherwise markers will leave on chart
                                if(found) {
                                    element.select(false);
                                    break;
                                }
                            }
                        }
                    }
                }

                if (this.advancedCharts[i].tooltip.shared && seriesDataArray && seriesDataArray.length > 0) {
                    this.advancedCharts[i].tooltip.refresh(seriesDataArray);
                }
            }
        }


    }

    public unSelectPointOnAdvGraph() {
        if (this.advancedCharts.length > 0 && this.advancedCharts[0].tooltip) {
            for (let i = 0; i < this.advancedCharts.length; i++) {
                this.advancedCharts[i].tooltip.hide();
                for (let j = 0; j < this.advancedCharts[i].series.length; j++) {
                    this.advancedCharts[i].series[j].data.forEach((element) => {
                        element.select(false);
                        element.color = 'rgba(119, 152, 191, .5)';
                    });
                }
            }
        }
    }
    /**
     * Method which provides date difference in days
     * @param date1 => To Date of the Hydrograph
     * @param date2 => From Date of the Hydrograph
     */
    public dateDiffInDays(date1: Date, date2: Date) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        return Math.floor((utc2 - utc1) / MS_PER_DAY);
    }

    public get DataEditReasons() {
        if(this.cacheDataEditReasons) {
            return of(this.cacheDataEditReasons.reasons);
        } else {
            return this.http.get<DataEditingReasons>(Config.urls.dataEditReasons).pipe(
            tap((reasonsResponse: DataEditingReasons) => {
                this.cacheDataEditReasons = {
                    reasons: reasonsResponse
                }
            }),
            map((reasonsResponse: DataEditingReasons) => {
                return reasonsResponse;
            }));
        }
    }

    public DataEditRemove(customerId: number, locationId: number, params: Array<DataEditingData>) {
        const url = `api/Telemetry/remove?cid=${customerId}&lid=${locationId}`;
        return this.http.post(Config.serviceUrl + url, params);
    }

    /**
     * This method is use to flag/unflag and snip the points for selection
     * @param scatterHighlightPoints => Bounds of scatter charts selection event
     * @param resetFlags => Flag to reset the points and unflag them
     * @param isSnapToCurve => Flag for snap to curve
     */
    public snippingAdvancedScatterChartPoints(
        scatterHighlightPoints: Object,
        resetFlags?: boolean,
        isSnapToCurve?: boolean,
    ) {
        if (this.advancedCharts[1]) {
            const { data } = this.advancedCharts[1].series[0];
            const points = data.filter(
                (element) =>
                    element &&
                    element.x >= scatterHighlightPoints['xMinValue'] &&
                    element.x <= scatterHighlightPoints['xMaxValue'] &&
                    element.y >= scatterHighlightPoints['yMinValue'] &&
                    element.y <= scatterHighlightPoints['yMaxValue'],
            );

            return points;
        }
    }

    /**
     * This method is used to move the points to the best Fit or SS Curve
     * @param snappedPoints => Bounds of scatter charts selection event
     * @param isBestFit => Flag for bestFit/SS curve to move the data editing points
     */
    public moveAdvancedScatterChartPoints(
        snappedPoints: Array<any>,
        isBestFit: boolean,
        positions: number,
        snappingPosition: number,
    ) {
        const editedPoints = [];
        if (this.advancedCharts[1] && snappedPoints && snappedPoints.length > 0) {
            const tolranceLinesDataAbove = this.advancedCharts[1].series.filter(
                (x: { name: string; id: string }) => x.name === 'ToleranceLines_0',
            )[0]
                ? this.advancedCharts[1].series.filter((x: { name: string }) => x.name === 'ToleranceLines_0')[0].data
                : null;

            const tolranceLinesDataBelow = this.advancedCharts[1].series.filter(
                (x: { name: string; id: string }) => x.name === 'ToleranceLines_1',
            )[0]
                ? this.advancedCharts[1].series.filter((x: { name: string }) => x.name === 'ToleranceLines_1')[0].data
                : null;

            // tslint:disable-next-line: cyclomatic-complexity
            snappedPoints.forEach((element) => {
                const nearestPointAbove = this.computeNearestCoordinate(tolranceLinesDataAbove, element);
                const nearestPointBelow = this.computeNearestCoordinate(tolranceLinesDataBelow, element);

                switch (snappingPosition) {
                    case 1:
                        /* Snapping Above and Below the Curve */
                        if (positions === 3 && nearestPointAbove && element.x < nearestPointAbove['x']) {
                            editedPoints.push(element);
                        }
                        if (positions === 3 && nearestPointBelow && element.x > nearestPointBelow['x']) {
                            editedPoints.push(element);
                        }
                        break;
                    case 2:
                        /* Snapping between Above and Below the Curve */
                        if (
                            positions === 3 &&
                            nearestPointAbove &&
                            element.x > nearestPointAbove['x'] &&
                            element.x < nearestPointBelow['x']
                        ) {
                            editedPoints.push(element);
                        }
                        break;
                    case 3:
                        if (positions === 1 && nearestPointAbove && element.x < nearestPointAbove['x']) {
                            editedPoints.push(element);
                        }
                        break;
                    case 4:
                        if (positions === 1 && nearestPointBelow && element.x > nearestPointBelow['x']) {
                            editedPoints.push(element);
                        }
                        break;
                    default:
                        break;
                }
            });
        }

        return editedPoints;
    }

    /**
     * This method is used to re-render the bestFit/SS curve based on Flagged Points
     * @param customerId => Customer Id
     * @param filterValues => Filter Values for start date , end date , location etc.
     * @param flaggedPoints => Points that are flagged for best fit Recalculation
     */
    public editScatterGraph(
        customerId: number,
        filterValues: LocationDashboardFilterData,
        isInverted: boolean,
        flaggedPoints?: Array<number>,
        apiEditingCurve?: number | undefined,
        start: Date | string = null,
        end: Date | string = null,
        isRawVelEntitySelected = false,
    ) {

        const isoStartDate = this.dateutilService.getStartDateAsTimeZone(start ? start : filterValues.startDate);
        const rawStartDate = isoStartDate.split('.');
        const startDate = rawStartDate[0];

        const isoEndDate = this.dateutilService.getEndDateAsTimeZone(
            new Date(new Date(end ? end : filterValues.endDate).setHours(23, 59, 59)),
        );
        const rawEndDate = isoEndDate.split('.');
        const endDate = rawEndDate[0];

        const params = {} as DataEditPreviewParams;
        const url =
            Config.getUrl(Config.urls.DataEdit.preview) + `?cid=${customerId}&lid=${filterValues.locationIDs[0]}`;

        params.editingCurve = apiEditingCurve;

        params.dataEditType = DataEditType.ScattergraphFlag;
        params.depthOnY = true;
        params.start = startDate as string;
        params.end = endDate as string
        params.sessionGuid = DataEditService.getUUID();
        params.temporaryFlags = flaggedPoints.map((x) => new Date(x).toISOString().split('.')[0]);
        params.isRawVelEntitySelected = isRawVelEntitySelected;

        return this.http.post<ScatterData>(url, params).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    /**
     * This method is used to re-render the bestFit/SS curve based on Flagged Points
     * @param series => BestFit / SS Curve Series
     * @param element => Snapped Point
     */
    public computeNearestCoordinate(series, element) {
        let nearestValueBelow: number;
        let nearestValueAbove: number;

        if (series && element) {
            const yValueFloor = series.filter((point) => point.y <= element.y);
            if (yValueFloor && yValueFloor.length > 0) {
                nearestValueBelow = yValueFloor[yValueFloor.length - 1];
            }
            const yValueCiel = series.filter((point) => point.y >= element.y);
            if (yValueCiel && yValueCiel.length > 0) {
                nearestValueAbove = yValueCiel[0];
            }

            if (nearestValueAbove && nearestValueBelow) {
                return element.y - nearestValueBelow['y'] > nearestValueAbove['y'] - element.y
                    ? nearestValueAbove
                    : nearestValueBelow;
            } else if (nearestValueAbove) {
                return nearestValueAbove;
            } else if (nearestValueBelow) {
                return nearestValueBelow;
            } else {
                return null;
            }
        }
    }


    /**
     * Method to follow an svg path and compute distance to a point
     * at each location along the path in order to find the closest point
     * along the path to the point in question. Useful for snapping to the closest
     * point on a curve
    */
   // 20107 commented out pending resolution of this user story
//    private getPointOnPath(
//         chart: Highcharts.Chart,
//         path: SVGPathElement,
//         isInverted: boolean,
//         point: Point,
//         toleranceValue: number
//     ) {
//         // Convert point into pixel point value
//         const pixelPointX = chart.xAxis[0].toPixels(point.x, true);
//         const pixelPointY = chart.yAxis[0].toPixels(point.y, true);

//         const precision = 1;
//         const totalPathLength = path.getTotalLength();

//         let windowLoc = 0;
//         let pointDistance: number;
//         let minPoint: DOMPoint;
//         let pointAtLoc: DOMPoint;
//         while (windowLoc < totalPathLength) {
//             pointAtLoc = path.getPointAtLength(windowLoc);

//             const xDist = pointAtLoc.x - pixelPointX;
//             const yDist = pointAtLoc.y - pixelPointY;
//             const distanceAtLoc = Math.sqrt(xDist * xDist + yDist * yDist);

//             if (!pointDistance || distanceAtLoc < pointDistance) {
//                 pointDistance = distanceAtLoc;
//                 minPoint = pointAtLoc;
//             }
//             if (pointDistance < 0.005) { break; }

//             windowLoc += precision;
//         }

//         // Convert min point on curve back to values
//         const closestPointX = chart.xAxis[0].toValue(minPoint.x, true);
//         const closestPointY = chart.yAxis[0].toValue(minPoint.y, true);

//         const xMin = closestPointX * (1 - toleranceValue);
//         const xMax = closestPointX * (1 + toleranceValue);
//         const yMin = closestPointY * (1 - toleranceValue);
//         const yMax = closestPointY * (1 + toleranceValue);

//         const newX = Math.round( (Math.random() * (xMax - xMin) + xMin) * 100) / 100;
//         const newY = Math.round( (Math.random() * (yMax - yMin) + yMin) * 100) / 100;
//         const dateTimeFormatted = new Date(point.dateTime).toISOString();
//         return {x: newX, y: newY, dateTime: dateTimeFormatted};
//     }


    /**
     * This method is used to re-render the bestFit/SS curve based on Flagged Points
     * @param customerId => Customer Id
     * @param filterValues => Filter Values for start date , end date , location etc.
     * @param snappedPoints => Points that are snapped Data Editing
     */
    public snapScatterGraph(
        customerId: number,
        filterValues: LocationDashboardFilterData,
        snappedPoints: Array<any>,
        bestfit: boolean,
        stevensSchutzbach: boolean,
        manualLinear: boolean,
        manualSmooth: boolean,
        colebrook: boolean,
        manningDesign: boolean,
        lanfearColl: boolean,
        curve: any,
        isInverted: boolean,
        toleranceValue: number,
        uniqueGUID = false,
        isRawVelEntitySelected = false,
    ): Observable<SnapDataResponse> {
        const params = {} as DataEditPreviewParams;

        const isoStartDate = this.dateutilService.getStartDateAsTimeZone(filterValues.startDate);
        const rawStartDate = isoStartDate.split('.');
        const startDate = rawStartDate[0];

        const isoEndDate = this.dateutilService.getEndDateAsTimeZone(
            new Date(new Date(filterValues.endDate).setHours(23, 59, 59)),
        );
        const rawEndDate = isoEndDate.split('.');
        const endDate = rawEndDate[0];
        const url =
            Config.getUrl(Config.urls.DataEdit.preview) + `?cid=${customerId}&lid=${filterValues.locationIDs[0]}`;

        params.dataEditType = DataEditType.ScattergraphSnap;
        params.depthOnY = !isInverted;
        params.scattergraphTolerance = toleranceValue;
        params.start = startDate;
        params.end = endDate;
        params.isRawVelEntitySelected = isRawVelEntitySelected;

        if (uniqueGUID === true) {
            params.sessionGuid = UUID.UUID();
        } else {
            params.sessionGuid = DataEditService.getUUID();
        }

        if (bestfit) { params.editingCurve = DataEditingCurveTypes.BestFit; }
        else if (stevensSchutzbach) { params.editingCurve = DataEditingCurveTypes.StevensSchutzbach; }
        else if (manualLinear) { params.editingCurve = DataEditingCurveTypes.ManualLinear; }
        else if (colebrook) { params.editingCurve = DataEditingCurveTypes.Colebrook; }
        else if (manningDesign) { params.editingCurve = DataEditingCurveTypes.ManningDesign; }
        else if (lanfearColl) { params.editingCurve = DataEditingCurveTypes.LanfearColl; }
        else if (manualSmooth) { params.editingCurve = DataEditingCurveTypes.ManualSmooth; }

        // 20107 code, commented out pending resolution of user story
        // If you need to do manual snap on front end
        // const curvePath = document.getElementById("advance-scattergraph-chart").getElementsByClassName('highcharts-series-1')[0].firstChild as SVGPathElement;
        // params.snapPoints = snappedPoints.map(point => this.getPointOnPath(chart, curvePath, isInverted, point, toleranceValue));

        params.curve = curve.map((a) => {
            return { x: a['x'], y: a['y'] };
        });

        params.snapPoints = snappedPoints.map((x) => {
            return {
                x: x['x'],
                y: x['y'],
                dateTime: new Date(x['dateTime']).toISOString(),
            };
        });
        const snapPointsTS: number[] = snappedPoints.map(v => (v.dateTime / 1000));
        return this.http.post<DataEditPreview>(url, params).pipe(
            map((res: DataEditPreview) => {
                if (res && res.d) {
                    const responseTimestamps = Object.keys(res.d);

                    responseTimestamps.forEach((ts: string) => {
                        if (!snapPointsTS.includes(Number(ts))) {
                            delete res.d[ts];
                        }
                    });
                }

                return res;
            }),
            map((res: DataEditPreview) => {
                const stamps = Object.keys((res && res.d) || {});

                const snapData: { stamp: number; entities: { id: number; xValue: number }[] }[] = stamps.map((stamp) => {
                    const stampData = res.d[stamp].substr(1, res.d[stamp].length - 2);
                    const entities = stampData.split(';').reduce((acc: { stamp: number; entities: { id: number; xValue: number } }[], curr: string) => {
                        const [id, xValue] = curr.split(':');

                        return [...acc, { id: Number(id), xValue: Number(xValue) }];
                    }, []);

                    return { stamp: Number(stamp) * 1000, entities };
                });

                return { sgd: res.sgd, snapData, curve: res.c, sessionTS: res.sessionTS };
            }),
        );
    }
    /**
     * This method is used to re-render the bestFit/SS curve based on Flagged Points
     * @param customerId => Customer Id
     * @param filterValues => Filter Values for start date , end date , location etc.
     * @param snappedPoints => Points that are snapped Data Editing
     */
    public syncScatterChartWithHydrographs(syncData: SyncObject) {
        this.advancedCharts[1].tooltip.hide();
        this.advancedCharts[1].series[0].data.forEach((element) => {
            if (element.dateTime === syncData.editedTimestamp) {
                if (syncData.editedEntity === 4122) {
                    element.update({
                        y: syncData.editedValue,
                        marker: this.editedMarkerStyle,
                    });
                } else if (syncData.editedEntity === 4202) {
                    element.update({
                        x: syncData.editedValue,
                        marker: this.editedMarkerStyle,
                    });
                }
            }
        });

        this.syncHydroGraphsWithScatterChart(syncData);
    }

    /**
     * This method is used to sync hydrographs with scatter chart data
     * @param syncData => Points that require to be synced
     */
    public syncHydroGraphsWithScatterChart(syncData: SyncObject) {
        for (let i = 0; i < this.advancedCharts.length; i++) {
            if (i === 1) {
                continue;
            }
            this.advancedCharts[i].tooltip.hide();
            const p = this.advancedCharts[i].series.findIndex(
                (x) => x['options']['id'].toString() === syncData.editedEntity,
            );
            if (p > -1) {
                const e = this.advancedCharts[i].series[p].data.findIndex((z) => {
                    return z.x / 1000 === syncData.editedTimestamp;
                });
                if (e > -1) {
                    this.advancedCharts[i].series[p].data[e].update(
                        {
                            y: syncData.editedValue,
                            marker: this.editedMarkerStyle,
                        },
                        false,
                    );
                }
                if (
                    syncData.editedValue >
                    this.advancedCharts[0].series
                        .find((x) => x['options']['id'].toString() === syncData.editedEntity)
                        .yAxis.getExtremes().max
                ) {
                    this.advancedCharts[0].series
                        .find((x) => x['options']['id'].toString() === syncData.editedEntity)
                        .yAxis.setExtremes(null, syncData.editedValue);
                }
                return;
            }
        }
    }

    /**
     * This method is used to sync hydrographs with scatter chart data
     * @param syncData => Points that require to be synced
     */
    public flagHydrographSelectedEntity(
        selectedEntity: number,
        hydroGraphHighlightPoints: Object,
        flaggedOperation: boolean,
    ) {
        const flaggedPoints = [];
        for (let i = 0; i < this.advancedCharts.length; i++) {
            const series = this.advancedCharts[i].series.find((x) => x['options']['id'] === selectedEntity);
            if (series && series.data) {
                series.data
                    .filter(
                        (element) =>
                            element.x >= hydroGraphHighlightPoints['xMinValue'] &&
                            element.x <= hydroGraphHighlightPoints['xMaxValue'] &&
                            element.y >= hydroGraphHighlightPoints['yMinValue'] &&
                            element.y <= hydroGraphHighlightPoints['yMaxValue'],
                    )
                    .forEach((element) => {
                        if (flaggedOperation) {
                            if (selectedEntity === RAIN_ENTITY) {
                                element.color = RAIN_HEXCODE_FLAGGED;
                                element.borderWidth = 1;
                                element.borderColor = RAIN_HEXCODE_FLAGGED;
                            } else {
                                element.marker = this.flaggedMarkerStyle;
                                element.color = RED_COLOR_HEX;
                                element.select();
                            }
                        } else {
                            if (selectedEntity === RAIN_ENTITY) {
                                element.color = RAIN_HEXCODE_UNFLAGGED;
                                element.borderWidth = 0;
                                element.borderColor = RAIN_HEXCODE_UNFLAGGED;
                            } else {
                                element.marker = { enabled: false };
                            }
                        }

                        flaggedPoints.push({
                            dateTime: element.x,
                            reading: element.y,
                            timeStamp: new Date(element.x).toISOString(),
                            ignore: flaggedOperation,
                        });
                    });

                series.redraw();
            }
        }
        this.flaggedPoints.next([
            {
                id: selectedEntity,
                startDate: new Date(hydroGraphHighlightPoints['xMinValue']),
                endDate: new Date(hydroGraphHighlightPoints['xMaxValue']),
                points: flaggedPoints,
            },
        ]);

        if (flaggedPoints && flaggedPoints.length) {
            this.addData({
                id: selectedEntity,
                startDate: new Date(hydroGraphHighlightPoints['xMinValue']),
                endDate: new Date(hydroGraphHighlightPoints['xMaxValue']),
                points: flaggedPoints,
            });
        }

        if (selectedEntity === DEPTH_ENTITY || selectedEntity === VELOCITY_ENTITY) {
            this.syncFlaggedPointsWithScatterChart(flaggedPoints);
        }

        return flaggedPoints;
    }

    /**
     * This method is used to add Flagged Points in hydrographs to ScatterChart
     * @param flaggedPoints => Points that are flagged from Hydrograph
     */
    public syncFlaggedPointsWithScatterChart(flaggedPoints: Array<any>, column = 'dateTime') {
        if (!flaggedPoints) {
            return;
        }

        if (
            this.advancedCharts &&
            this.advancedCharts.length > 1 &&
            this.advancedCharts[1].series &&
            this.advancedCharts[1].series.length > 0
        ) {
            flaggedPoints.forEach((x) => {
                const element = this.advancedCharts[1].series[0].data.find((s) => s.dateTime === x[column]);
                if (element) {
                    element.update({ marker: x.ignore ? this.editedMarkerStyle : this.defaultMarkerStyle }, false);
                }
            });
            this.advancedCharts[1].series[0].redraw();
        }
    }

    /**
     * This method is used to add Flagged Points in hydrographs to ScatterChart
     * @param flaggedPoints => Points that are flagged from Hydrograph
     */
    public syncFlaggedPointsWithHydograph(flaggedPoints: Array<any>, column = 'dateTime') {
        if (!flaggedPoints) {
            return;
        }

        if (
            this.advancedCharts &&
            this.advancedCharts.length > 1 &&
            this.advancedCharts[0].series &&
            this.advancedCharts[0].series.length > 0
        ) {
            flaggedPoints.forEach((x) => {
                this.advancedCharts[0].series.forEach((series) => {
                    if (series.userOptions.name === UNIDEPTH || series.userOptions.name === VELOCITY) {
                        const element = series.data.find((s) => s && s.x === x[column]);
                        if (element) {
                            if (x.ignore) {
                                element.update({ marker: this.flaggedMarkerStyle }, false);
                            } else {
                                element.update({ marker: { enabled: false } }, false);
                            }
                        }
                        series.redraw();
                    }
                });
            });
        }
    }

    public addData(dataObj) {
        let currentValue = this.collectiveFlaggedPoints.value || [];
        if (currentValue && currentValue['points'] && currentValue['points'].length) {
            const availablePoints = currentValue['points'].map((point) => new Date(point.timeStamp).getTime());
            // set start date which ever is lower
            currentValue['startDate'] =
                new Date(currentValue['startDate']) < new Date(dataObj.startDate)
                    ? currentValue['startDate']
                    : dataObj.startDate;

            // set end date which ever is higher
            currentValue['endDate'] =
                new Date(currentValue['endDate']) > new Date(dataObj.endDate)
                    ? currentValue['endDate']
                    : dataObj.endDate;

            // append new flagged points
            const excludedExistingPoints = dataObj.points.filter(
                (point) => availablePoints.indexOf(point.dataTime) === -1,
            );
            if (excludedExistingPoints && excludedExistingPoints.length) {
                currentValue['points'].push(...excludedExistingPoints);
            }
        } else {
            currentValue = dataObj;
        }

        this.collectiveFlaggedPoints.next(currentValue);
    }

    public removeRoomArr(id: number) {
        const roomArr: any[] = this.collectiveFlaggedPoints.getValue();
        roomArr.filter((item) => item['id'] === id).forEach((item, index) => roomArr.splice(index, 1));
        this.collectiveFlaggedPoints.next(roomArr);
    }

    public getcopiedPointsFromSelection(
        selectedEntity: number,
        hydroGraphHighlightPoints: Object,
        copyPasteOperation: boolean,
    ) {
        const copiedPoints = [];
        const seriesPoints = this.advancedCharts[0].series.find((x) => x['options']['id'] === selectedEntity);
        if (seriesPoints) {
            seriesPoints.data.forEach((element) => {
                if (
                    hydroGraphHighlightPoints &&
                    element.x >= hydroGraphHighlightPoints['xMinValue'] &&
                    element.x <= hydroGraphHighlightPoints['xMaxValue'] &&
                    copyPasteOperation
                ) {
                    copiedPoints.push({
                        id: selectedEntity,
                        dateTime: element.x,
                        dateTimeUtc: new Date(element.x).toISOString(),
                        y: element.y,
                        x: new Date(element.x),
                    });
                }
            });
        }
        let existingCopiedPoints = this.copiedPoints.value;
        if (!existingCopiedPoints) {
            existingCopiedPoints = copiedPoints;
        } else {
            existingCopiedPoints.push(...copiedPoints);
        }
        this.copiedPoints.next(existingCopiedPoints);
        return copiedPoints;
    }

    public pasteOperation(targetElement, sourceEntityId: number, targetPointsForDataTable) {
        const targetSeries = this.advancedCharts[0].series.find((x) => x['options']['id'] === sourceEntityId);
        const targetSeriesIndex = this.advancedCharts[0].series.findIndex((x) => x['options']['id'] === sourceEntityId);
        if (!targetSeries) {
            return;
        }
        targetElement.forEach((copiedElement, index) => {
            if (copiedElement.timeStamp && copiedElement.reading) {
                const targetPoint = targetSeries.data.find(
                    (element) => element && element.x === new Date(copiedElement.timeStamp).getTime(),
                );
                if (targetPoint) {
                    targetPoint.update(
                        {
                            y: copiedElement.reading,
                            marker: this.editedMarkerStyle,
                        },
                        false,
                    );
                    targetPointsForDataTable[index]['pointExists'] = true;
                    targetPointsForDataTable[index]['entityName'] = targetSeries['options']['name'];
                    targetPointsForDataTable[index]['displayGroup'] = targetSeries['options']['displayGroupID'];
                } else {
                    targetSeries.addPoint(
                        {
                            x: copiedElement.timeStamp,
                            y: copiedElement.reading,
                            marker: this.editedMarkerStyle,
                        },
                        false,
                        false,
                    );
                    targetPointsForDataTable[index]['pointExists'] = false;
                    targetPointsForDataTable[index]['entityName'] = targetSeries['options']['name'];
                    targetPointsForDataTable[index]['displayGroup'] = targetSeries['options']['displayGroupID'];
                }
            }
        });

        this.advancedCharts[0].series[targetSeriesIndex].redraw();
        return targetPointsForDataTable;
    }

    public syncScatterChartWithCopiedPoints(syncData, editedEntity): void {
        this.advancedCharts[1].tooltip.hide();
        if (this.advancedCharts[1].series && this.advancedCharts[1].series.length) {
            const targetSeries = this.advancedCharts[1].series[0];
            if (!targetSeries) {
                return;
            }
            syncData.forEach((element) => {
                const pointToBeUpdated = targetSeries.data.find((x) => x.dateTime === element.dateTime);
                if (pointToBeUpdated) {
                    if (editedEntity === DEPTH_ENTITY) {
                        pointToBeUpdated.update(
                            {
                                y: element.reading,
                                marker: this.editedMarkerStyle,
                            },
                            false,
                        );
                    } else if (editedEntity === VELOCITY_ENTITY) {
                        pointToBeUpdated.update(
                            {
                                x: element.reading,
                                marker: this.editedMarkerStyle,
                            },
                            false,
                        );
                    }
                }
            });
            this.advancedCharts[1].series[0].redraw();
        }
    }

    public selectedPointsForMultiPointEdit(
        selectedEntity: number,
        hydroGraphHighlightPoints: Object,
        multiPointEditOperation: boolean,
    ) {
        const selectedPoints = [];
        const item = this.advancedCharts[0].series.find((x) => x['options']['id'] === selectedEntity);
        if (item && item.data) {
            item.data
                .filter(
                    (element) =>
                        hydroGraphHighlightPoints &&
                        element.x >= hydroGraphHighlightPoints['xMinValue'] &&
                        element.x <= hydroGraphHighlightPoints['xMaxValue'] &&
                        multiPointEditOperation,
                )
                .forEach((element) => {
                    selectedPoints.push({
                        id: selectedEntity,
                        dateTime: element.x,
                        x: new Date(element.x),
                        y: element.y,
                        dateTimeUTC: new Date(element.x).toISOString(),
                    });
                });
        }

        let existingEditedPoints = this.multiPointEditPoints.value;
        if (!existingEditedPoints) {
            existingEditedPoints = selectedPoints;
        } else {
            existingEditedPoints.push(...selectedPoints);
        }
        this.multiPointEditPoints.next(existingEditedPoints);
        return selectedPoints;
    }

    public multiPointEditOperation(entityId: number, selectedPoints) {
        const targetSeries = this.advancedCharts[0].series.find((x) => x['options']['id'] === entityId);
        const targetSeriesIndex = this.advancedCharts[0].series.findIndex((x) => x['options']['id'] === entityId);
        if (!targetSeries) {
            return;
        }
        selectedPoints.forEach((selectedElement) => {
            const targetPoint = targetSeries.data.find((element) => element.x === selectedElement.dateTime);
            if (targetPoint) {
                targetPoint.update(
                    {
                        y: selectedElement.reading,
                        marker: this.editedMarkerStyle,
                    },
                    false,
                );
            }
        });
        this.advancedCharts[0].series[targetSeriesIndex].redraw();
    }

    // get data editing rows for given customer and location with selected filter options
    public fetchDataEditingPoints(customerId: number, params: DataEditingParams) {
        params.start = this.dateutilService.getStartDateAsTimeZone(params.start);
        params.end = this.dateutilService.getEndDateAsTimeZone(new Date(new Date(params.end).setHours(23, 59, 59)));
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams
            .append('cid', String(customerId))
            .append('lid', String(params.locationId))
            .append('start', params.start)
            .append('end', params.end)
            .append('pageSize', String(params.pageSize || 10))
            .append('startPage', String(params.startPage || 1))
            .append('searchValue', params.searchValue || '')
            .append('sort', params.sort || '');

        params.eids.forEach((id) => {
            httpParams = httpParams.append('eids', String(id));
        });

        return this.http.get<any>(`${Config.serviceUrl}${Config.urls.dataEditing}`, { params: httpParams }).pipe(
            map((response) => {
                return response;
            }),
        );
    }

    public createNewCurveOptions(cid: number, lid: number, name: string, points: string[], depthOnY: boolean, smoothCurve: boolean, editable: boolean) {
        const url = `${Config.serviceUrl}${Config.urls.DataEdit.saveCurve}?cid=${cid}&lid=${lid}`;
        return this.http.post(url, { name, d: points, dy: depthOnY, smoothCurve, editable: editable});
    }

    public deleteSavedCurve(cid: number, lid: number, name: string) {
        const url = `${Config.serviceUrl}${Config.urls.DataEdit.saveCurve}?cid=${cid}&lid=${lid}&name=${name}`;
        return this.http.delete(url);
    }

    private clearSavedCurvesCache() {
        this.savedCurvesCache = null;
    }

    public getSavedCurves(cid: number, lid: number) {
        const url = `${Config.serviceUrl}${Config.urls.DataEdit.getSavedCurves}?cid=${cid}&lid=${lid}`;
        return this.http.get<string[]>(url);
    }

    public optimizeScatterData(scatterData) {
        if (typeof scatterData[0] === 'string' || scatterData[0] instanceof String) {
            scatterData.forEach(function (dataElement, index: number) {
                const strElements = dataElement.toString().split(':');

                const xElement = strElements[0];
                const xValue = Number(xElement.substr(1, xElement.length));
                const yElement = strElements[1];
                const yValue = Number(yElement.substr(0, yElement.length - 1));
                dataElement = {
                    x: xValue,
                    y: yValue,
                };
                if (index === 0) {
                    dataElement['xUnits'] = 0;
                    dataElement['yUnits'] = 0;
                }
                scatterData[index] = dataElement;
            });
        }
        return scatterData;
    }

    /**
     * Retain selected points on charts (Hydrograph or Scattergraph)
     * @param selectedEntityId - Select entity on which operation are performed
     * @param points - Select points which needs to be retains on chart
     * @param actionType - If data editing operations are performed otherwise set as NONE.
     * @param color - Point marker color.
     */
    public persistPointsOnCharts(
        selectedEntityId: number,
        points: any,
        actionType: DataEditingActionType,
        color: string = RED_COLOR_HEX,
        chartType: AdsChartType = AdsChartType.HYDRO_GRAPH,
    ) {
        if (points) {
            const marker =
                actionType === DataEditingActionType.Flagged || actionType === DataEditingActionType.UnFlagged
                    ? this.flaggedMarkerStyle
                    : this.editedMarkerStyle;

            let name;
            if (selectedEntityId === DEPTH_ENTITY) {
                name = UNIDEPTH;
            } else if (selectedEntityId === VELOCITY_ENTITY) {
                name = VELOCITY;
            } else if (selectedEntityId === RAIN_ENTITY) {
                name = RAIN;
            } else {
                name = QCONTINUITY;
            }

            const entityPoints = this.advancedCharts[chartType].series.find((x) => x['name'] === name);
            const entitySeriesIndex = this.advancedCharts[chartType].series.findIndex((x) => x['name'] === name);
            if (entityPoints && entityPoints.data) {
                if (points && points.length) {
                    const dates = points.map((element) => element.dateTime);
                    const items = entityPoints.data.filter((x) => dates.includes(x.x));
                    if (items && items.length) {
                        items.forEach((item) => {
                            if (selectedEntityId === RAIN_ENTITY) {
                                item.update(
                                    {
                                        color: RAIN_HEXCODE_FLAGGED,
                                    },
                                    false,
                                );
                            } else {
                                item.update(
                                    {
                                        marker: marker,
                                        color: color,
                                    },
                                    false,
                                );
                            }
                        });
                        if (this.advancedCharts[chartType].series && this.advancedCharts[chartType].series.length) {
                            this.advancedCharts[chartType].series[entitySeriesIndex].redraw();
                        }
                    }
                    if (this.advancedCharts[chartType].series && this.advancedCharts[chartType].series.length) {
                        this.advancedCharts[chartType].series[entitySeriesIndex].redraw();
                    }
                }
            }
        }
    }

    public storeHydroGraphFilterData(filterData: HydrographArgs) {
        this.hydroGraphFilterData = filterData;
        if (filterData.start && filterData.end) {
            if (typeof filterData.start === 'string') {
                // timezone consideration not required ,  that's why removind z from date
                // sometimes date comes as string and sometimes in date format.
                this.hydroGraphFilterData.start = new Date(filterData.start).toISOString().replace('Z', '');
            }

            if (typeof filterData.end === 'string') {
                this.hydroGraphFilterData.end = new Date(filterData.end).toISOString().replace('Z', '');
            }
        }
    }

    public getHydoGraphFilterData(): any {
        return this.hydroGraphFilterData;
    }

    public enableDisableChartZoom(enable: boolean, chart: any) {
        if (chart) {
            chart.zoomType = enable ? 'xy' : '';
            if (chart.userOptions) {
                chart.userOptions.chart.zoomType = enable ? 'xy' : '';
            }
        }
        return chart;
    }

    public applyDataTableEditingChanges(dataRowItem: DataRowItem) {
        const targetSeries = this.advancedCharts[0].series.find((x) => x['options']['id'] === dataRowItem.entityId);
        const targetSeriesIndex = this.advancedCharts[0].series.findIndex(
            (x) => x['options']['id'] === dataRowItem.entityId,
        );
        if (!targetSeries) {
            return;
        }

        const targetPoint = targetSeries.data.find((element) => element.x === dataRowItem.timestamp);
        if (targetPoint) {
            targetPoint.update(
                {
                    y: dataRowItem.reading,
                    marker: this.editedMarkerStyle,
                },
                false,
            );
        }
        this.advancedCharts[0].series[targetSeriesIndex].redraw();
    }

    public showDataEditingFlaggedPoints(flaggedItems: Array<FlaggedPoint>) {
        if (!flaggedItems || !flaggedItems.length) {
            return;
        }

        const entityIds = StringUtils.applyUniqueAndSort<Array<number>>(
            flaggedItems.map((m) => m.entityId),
            'entityId',
            'entityId',
            true,
        );
        const flaggedPoints = [];
        const timestamps = [];
        entityIds.forEach((e) => {
            const items = flaggedItems.filter((i) => i.entityId === e);
            const series = this.advancedCharts[0].series.find((x) => x['options']['id'] === e);
            if (series) {
                items.forEach((item) => {
                    const point = series.data.find((d) => d.x === item.timestamp);
                    if (point) {
                        if (e === RAIN_ENTITY) {
                            point.update(
                                {
                                    color: RAIN_HEXCODE_FLAGGED,
                                    borderWidth: 1,
                                    borderColor: RAIN_HEXCODE_FLAGGED,
                                    y: item.reading,
                                },
                                false,
                            );
                        } else {
                            point.update(
                                {
                                    y: item.reading,
                                    marker: this.flaggedMarkerStyle,
                                },
                                false,
                            );
                        }

                        // stored flagged point to sync with scatter graph
                        if (e === DEPTH_ENTITY || e === VELOCITY_ENTITY) {
                            timestamps.push(point.x);
                            flaggedPoints.push({
                                dateTime: point.x,
                                reading: point.y,
                                timeStamp: new Date(point.x).toISOString(),
                                ignore: true,
                            });
                        }
                    }
                });
                series.redraw();

                // retain flagged items on scatter graph
                if (flaggedPoints && flaggedPoints.length) {
                    const min = Math.min(...timestamps);
                    const max = Math.max(...timestamps);
                    this.flaggedPoints.next([
                        {
                            id: e,
                            startDate: new Date(min),
                            endDate: new Date(max),
                            points: flaggedPoints,
                        },
                    ]);

                    if (e === DEPTH_ENTITY || e === VELOCITY_ENTITY) {
                        this.syncFlaggedPointsWithScatterChart(flaggedPoints);
                    }
                }
            }
        });
    }

    public dataEditApproval(customerId: number, locationId: number, approvedStartDate: string, approvedThrough: string) {
        const approvalString = approvedStartDate != null && approvedStartDate != undefined && approvedThrough != null && approvedThrough != undefined ? `approvedStartDate=${approvedStartDate}&approvedThrough=${approvedThrough}` : `approvedThrough=${approvedThrough}` ;
        const url = `${Config.serviceUrl}${Config.urls.DataEdit.approval}?cid=${customerId}&lid=${locationId}&` + approvalString;

        return this.http.put(url, {});
    }

    public getSavedCurveData(
        cid: number,
        lid: number,
        start: Date,
        end: Date,
        curveName: string,
        depthOnY: boolean,
        entityIds: number[],
    ) {
        const url = `${Config.serviceUrl}${Config.urls.scattergraph.historicalcurve}?customerId=${cid}&locationId=${lid}`;
        return this.http.post<HistoricalCurve[]>(url, {
            savedCurves: [curveName],
            depthOnY,
            start,
            end,
            entityIds,
            distances: true,
        });
    }

    public sortSelectedEntities(entityids: number[], isInverted: boolean) {
        const list = this.scattergraphAvailableEntities;
        const depthGroup = list.find((v) => v.groupId === EntityGroupId.Depth);
        const velGroup = list.find((v) => v.groupId === EntityGroupId.Velocity);

        const depthFound = depthGroup ? depthGroup.entities.find((v) => entityids.includes(v.id)) : null;
        const velFound = velGroup ? velGroup.entities.find((v) => entityids.includes(v.id)) : null;

        const velId = velFound ? velFound.id : null;
        const depthId = depthFound ? depthFound.id : null;

        if (velId && depthId) return [velId, depthId];
        if (velId) return [velId];
        if (depthId) return [depthId];

        return [];
    }

    public getScatterGraphHistoricalCurve(
        customerId: number,
        locationId: number,
        start: Date | string,
        end: Date | string,
        entityIds: Array<number>,
        curves: GraphType[],
        depthOnY = true,
        distancesStart: string,
        distancesEnd: string
    ) {
        const url = `${Config.serviceUrl}${Config.urls.scattergraph.historicalcurve}?customerId=${customerId}&locationId=${locationId}`;
        return this.http.post<HistoricalCurve[]>(url, {
            start,
            end,
            entityIds,
            curves,
            depthOnY,
            distances: true,
            distancesStart,
            distancesEnd
        });
    }
}
