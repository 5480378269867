import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnDestroy,
    OnChanges,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    SimpleChanges,
} from '@angular/core';
import { AlarmGraphComponent } from 'app/pages/alarm-graph/alarm-graph.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSortable } from '@angular/material/sort';
import { UsersService } from 'app/pages/admin/users.service';
import { Observable, pipe, Subject, Subscription } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from 'app/shared/services/message.service';
import { SignalRMessageType } from 'app/shared/models/signalr-message';
import { SignalRService, ALARM_ACKNOWLEDGED, ALARM_CLEARED } from 'app/shared/services/signalr.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { first, last, takeLast } from 'rxjs/operators';
import { AlarmStatusChange } from 'app/shared/models/alarm-status-change';
import { ActiveAlarm } from 'app/shared/models/active-alarm';
import { FilterSettings, WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { AlarmService } from 'app/shared/services/alarm.service';
import { LocationService } from 'app/shared/services/location.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { AcknowledgeAlarms, GetAlarmsArgs } from 'app/shared/models/alarms';
import { GraphData } from 'app/shared/models/graph-data';
import { ConfirmationButton, ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

enum AlarmWidgetListColumns {
    state = 'state',
    type = 'type',
    timestamp = 'timestamp',
    location = 'location',
    graph = 'graph',
    acknowledgeBy = 'acknowledgeBy',
    acknowledgeTime = 'acknowledgeTime',
    clearBy = 'clearBy',
    clearTime = 'clearTime',
    rtnDate = 'rtnDate',
}

@Component({
    selector: 'app-alarm-widget',
    templateUrl: './alarm-widget.component.html',
    styleUrls: ['./alarm-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmWidgetComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public showAlarmSearch: boolean;
    @Input() public showColumn: boolean;
    @Input() public toggleState: boolean;
    @Input() public customerID: number;
    @Input() public locationGroupID: number;
    @Input() public customerLocationsLoaded: boolean;
    @Input() public updateAlarmTable: boolean;
    @Input() public includeInactiveLocations: boolean;
    @Input() public dateFormat: string;
    @Input() public timeFormat: string;
    @Output() public showMap: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public showAlarmColumn: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public showMapLocationForAlarm: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
    @Output() public alarmStatusChange: EventEmitter<AlarmStatusChange> = new EventEmitter<AlarmStatusChange>();
    @Output() public displayedAlarms: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    public AlarmWidgetListColumns = AlarmWidgetListColumns;

    public activeAlarms: Array<ActiveAlarm> = [];
    public invalidDateRange: boolean;
    public users = new Array<Object>();
    public listOfStatus: Array<object>;
    public status: number;
    public alarmWidgetLoadingState: boolean;
    public setFirstPage = true;
    public locationgGroupID: number;
    private subscriptions = new Array<Subscription>();
    public alarmWidgetDisplayedColumns = [];
    public alarmWidgetFilterColumns = [];
    public alarmWidgetDataSource: MatTableDataSource<ActiveAlarm>;
    @ViewChild(MatPaginator) public alarmWidgetPaginator: MatPaginator;
    @ViewChild(MatSort) public alarmWidgetSort: MatSort;
    @ViewChild('filter') public alarmWidgetFilter: ElementRef;
    public alarmWidgetDataChange: BehaviorSubject<ActiveAlarm[]> = new BehaviorSubject<ActiveAlarm[]>([]);
    public rawDataActive: boolean;
    public get alarmWidgetData(): ActiveAlarm[] {
        return this.alarmWidgetDataChange.value;
    }
    public totalPaginationLength = 0;
    public lastAlarmHistoryArgs: GetAlarmsArgs;
    public statusTooltip: string;
    /**
     * Variable which validates API state of the first initially loaded alarm widget locations on map
     */
    public alarmAPIState = true;

    /**
     * Declares the alarm widget filter options
     */
    public alarmFilterSettings: FilterSettings;
    /**
     * This flag is used to disable status bell based on permission
     */
    public isDisableAlarm: boolean;
    public filterParams: GetAlarmsArgs;

    /** #43328 number of alarms */
    private acknowledgedAlarmsCount: number;
    private unacknowledgedAlarmsCount: number;

    // #44626 Alarms state to enable disable respective buttons
    public isAnyToAcknowledge = false;
    public isAnyToClear = false;

    constructor(
        private alarmService: AlarmService,
        private dialog: MatDialog,
        private locationService: LocationService,
        private cdr: ChangeDetectorRef,
        private usersService: UsersService,
        private dateutilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private messageService: MessageService,
        public signalRService: SignalRService,
        public translate: TranslateService,
        private snackBar: MatSnackBar
    ) { }

    public ngOnInit() {
        this.rawDataActive = this.usersService.isRawDataEditingAllowed.getValue();
        // check permission for alarm maintenance feature
        this.subscriptions.push(
            this.usersService.isAlarmMaintenanceAllowed.subscribe(
                (response: boolean) => (this.isDisableAlarm = response),
            ),
        );

        this.setAlarmWidgetFilterSettings();
        this.setColumns(this.toggleState);
        const signalRSub = this.signalRService.messageReceived.subscribe(async (res) => {
            if (res && res.cid && res.type === SignalRMessageType.Info && res.message && res.cid === this.customerID) {
                if (res.message.startsWith(ALARM_ACKNOWLEDGED) || res.message.startsWith(ALARM_CLEARED)) {
                    this.generateAlarmHistory(this.lastAlarmHistoryArgs);
                }
            }
        });

        this.signalRService.alarmReceived.subscribe(() => {
            this.generateAlarmHistory(this.lastAlarmHistoryArgs);
        });

        this.signalRService.returnToNormal.subscribe(() => {
            this.generateAlarmHistory(this.lastAlarmHistoryArgs);
        });

        this.subscriptions.push(signalRSub);
    }

    public ngOnChanges(changes: SimpleChanges) {
        const args = <GetAlarmsArgs>{
            customerId: this.customerID,
            LocationGroupID: this.locationGroupID,
            Active: true,
            Acknowledged: true,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        if (
            (changes.customerLocationsLoaded && changes.customerLocationsLoaded.currentValue) ||
            (changes.updateAlarmTable && changes.updateAlarmTable.currentValue && !this.showAlarmSearch) ||
            changes.locationGroupID
        ) {
            this.alarmAPIState = true;
            this.filterParams = {...args};
            this.generateAlarmHistory(args);
        }

        if (changes.toggleState) {
            if (changes.toggleState.previousValue === false && changes.toggleState.currentValue === true) {
                this.setColumns(true);
            }
            if (changes.toggleState.previousValue === true && changes.toggleState.currentValue === false) {
                this.setColumns(false);
            }
        }
    }

    private setColumns(removeExtra: boolean) {
        if (removeExtra) {
            this.alarmWidgetDisplayedColumns = this.alarmWidgetFilterColumns = [
                AlarmWidgetListColumns.state,
                AlarmWidgetListColumns.type,
                AlarmWidgetListColumns.timestamp,
                AlarmWidgetListColumns.location,
                AlarmWidgetListColumns.graph
            ];
        } else {
            this.alarmWidgetDisplayedColumns = this.alarmWidgetFilterColumns = [
                AlarmWidgetListColumns.state,
                AlarmWidgetListColumns.type,
                AlarmWidgetListColumns.timestamp,
                AlarmWidgetListColumns.location,
                AlarmWidgetListColumns.graph,
                AlarmWidgetListColumns.acknowledgeBy,
                AlarmWidgetListColumns.acknowledgeTime,
                AlarmWidgetListColumns.clearBy,
                AlarmWidgetListColumns.clearTime,
                AlarmWidgetListColumns.rtnDate
            ];
        }
        this.generateAlarmWidgetTable();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private alarmsComparer(a: ActiveAlarm, b: ActiveAlarm) {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    }


    public generateAlarmWidgetTable() {
        this.alarmWidgetDataSource = null;
        this.displayedAlarms.emit(null);
        this.alarmWidgetDataChange = new BehaviorSubject<ActiveAlarm[]>([]);
        if (this.activeAlarms) {
            const acknowledgedAlarms = this.activeAlarms.filter((v) => !!v.acknowledgeBy).sort(this.alarmsComparer);
            const unacknowledgedAlarms = this.activeAlarms.filter((v) => !v.acknowledgeBy).sort(this.alarmsComparer);

            this.isAnyToAcknowledge = unacknowledgedAlarms.length > 0;
            this.isAnyToClear = acknowledgedAlarms.length > 0;

            /** #43328 number of alarms */
            this.acknowledgedAlarmsCount = acknowledgedAlarms.length;
            this.unacknowledgedAlarmsCount = unacknowledgedAlarms.length;

            this.activeAlarms = [...unacknowledgedAlarms, ...acknowledgedAlarms];

            for (const activeAlarm of this.activeAlarms) {
                const alarmWidgetCopiedData = this.alarmWidgetData.slice();
                const activeAlarmData = {
                    alarmID: activeAlarm.alarmID,
                    customerID: activeAlarm.customerID,
                    state: activeAlarm.state,
                    type: activeAlarm.type,
                    timestamp: activeAlarm.timestamp,
                    location: activeAlarm.location,
                    locationID: activeAlarm.locationID,
                    acknowledgeBy: activeAlarm.acknowledgeBy,
                    acknowledgeTime: activeAlarm.acknowledgeTime,
                    clearBy: activeAlarm.clearBy,
                    clearTime: activeAlarm.clearTime,
                    alarmDefinitionID: activeAlarm.alarmDefinitionID,
                    processedNotification: activeAlarm.processedNotification,
                    lid: activeAlarm.lid,
                    returnToNormal: activeAlarm.returnToNormal,
                };

                alarmWidgetCopiedData.push(activeAlarmData);
                this.alarmWidgetDataChange.next(alarmWidgetCopiedData);
            }

            this.alarmWidgetPaginator.pageIndex = 0;
            this.displayedAlarms.emit(this.alarmWidgetData.map((x) => x.locationID));
            this.alarmWidgetDataSource = new MatTableDataSource(this.alarmWidgetData);
            this.alarmWidgetDataSource.paginator = this.alarmWidgetPaginator;

            this.alarmWidgetDataSource.sort = this.alarmWidgetSort;
            this.totalPaginationLength = this.alarmWidgetData.length;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.totalPaginationLength = 1;
        }
    }
    /**
     * Below function would sort the alarm data
     * @param sort represents materail sort event
     */
    public sortData() {
        this.alarmWidgetDataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case AlarmWidgetListColumns.type:
                    return item.type;
                case AlarmWidgetListColumns.location:
                    return item.location;
                case AlarmWidgetListColumns.acknowledgeBy:
                    return item.acknowledgeBy;
                case AlarmWidgetListColumns.clearBy:
                    return item.clearBy;
                case AlarmWidgetListColumns.acknowledgeTime:
                    return this.returnDate(item.acknowledgeTime);
                case AlarmWidgetListColumns.timestamp:
                    return this.returnDate(item.timestamp);
                case AlarmWidgetListColumns.clearTime:
                    return this.returnDate(item.clearTime);
                case AlarmWidgetListColumns.rtnDate:
                    return this.returnDate(item.returnToNormal);
                default:
                    return item[property];
            }
        };
    }
    /**
     * Convert to date time with respect to date format
     * @param inputDate
     */
    private returnDate(inputDate: Date) {
        if (inputDate) {
            const dateFormat = this.dateutilService.dateFormat.getValue().toUpperCase();
            if (dateFormat === 'MM/DD/YYYY' || dateFormat === 'YYYY/MM/DD') {
                return new Date(inputDate);
            } else if (dateFormat === 'DD/MM/YYYY') {
                return new Date(String(inputDate).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'));
            }
        } else {
            return '';
        }
    }

    public notifyWidget(filtersData: WidgetFilterData) {
        this.filterParams = {
            customerId: this.customerID,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        if (filtersData.alarmType.length && filtersData.alarmType[0] !== 0) {
            this.filterParams.AlarmDefinitionIds = filtersData.alarmType;
        }

        let startDate = null;
        let endDate = null;
        if (filtersData.startDate) {
            startDate = [
                filtersData.startDate.getMonth() + 1,
                filtersData.startDate.getDate(),
                filtersData.startDate.getFullYear(),
            ].join('-');
            this.filterParams.StartTime = startDate;
        }
        if (filtersData.endDate) {
            endDate = [
                filtersData.endDate.getMonth() + 1,
                filtersData.endDate.getDate(),
                filtersData.endDate.getFullYear(),
            ].join('-');
            this.filterParams.EndTime = endDate;
        }

        filtersData.alarmStatus.forEach((alarmStatus) => {
            if (alarmStatus === 0 || !alarmStatus) {
                this.filterParams.Active = true;
                this.filterParams.Acknowledged = true;
            } else if (alarmStatus === 1) {
                this.filterParams.Active = true;
            } else if (alarmStatus === 2) {
                this.filterParams.Acknowledged = true;
            } else if (alarmStatus === 3) {
                this.filterParams.Cleared = true;
            }
        });

        if (filtersData.locationIDs) {
            this.filterParams.LocationIds = filtersData.locationIDs;
        } else {
            this.filterParams.LocationGroupID = this.uiUtilsService.locationGroupID;
        }

        this.generateAlarmHistory(this.filterParams);
    }

    public generateAlarmHistory(params: GetAlarmsArgs) {
        this.lastAlarmHistoryArgs = params;
        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.alarmWidgetLoadingState = true;

        this.isAnyToAcknowledge = false;
        this.isAnyToClear = false;

        this.activeAlarms = null;
        this.alarmWidgetDataChange.next(null);

        const subscription = this.alarmService.getAlarms(params).subscribe(
            (result) => {
                this.activeAlarms = result || [];

                this.sortActiveAlarms();

                /**
                 * Below Logic will emit the alarm widget locations to parent component(i.e.landing page)
                 * to display the locations on map. This code will also avoid the multiple API call to fetch the alarms.
                 */
                if (this.alarmAPIState) {
                    this.showMapLocationForAlarm.emit(this.activeAlarms.map((element) => element.locationID));
                    this.alarmAPIState = false;
                }

                this.generateAlarmWidgetTable();
                this.alarmWidgetLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.alarmWidgetLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(subscription);
    }

    public changeAlarmStatus(activeAlarm: ActiveAlarm) {
        if (activeAlarm.state === 0 || activeAlarm.state === 1) {
            const subscription = this.alarmService
                .updateAlarmStatus(activeAlarm.state, activeAlarm.alarmID)
                .subscribe((result) => {
                    if (result.responseMessage === 'Alarm acknowledged') {
                        activeAlarm.acknowledgeTime = result.alarm.acknowledgeTime;
                        activeAlarm.state = 1;
                        activeAlarm.acknowledgeBy = result.alarm.acknowledgeBy;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.messageService
                            .postMessage(SignalRMessageType.Info, this.customerID, ALARM_ACKNOWLEDGED)
                            .subscribe();
                    } else if (result.responseMessage === 'Alarm cleared') {
                        activeAlarm.clearTime = result.alarm.clearTime;
                        activeAlarm.state = 2;
                        activeAlarm.clearBy = result.alarm.clearBy;
                        this.messageService
                            .postMessage(SignalRMessageType.Info, this.customerID, ALARM_CLEARED)
                            .subscribe();
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }

                    setTimeout(() => {
                        this.alarmStatusChange.emit({ isAlarmStatusChanged: true, toggleState: this.toggleState });
                        this.generateAlarmHistory(this.lastAlarmHistoryArgs);
                    }, 1000);
                });
            this.subscriptions.push(subscription);
        }
    }

    public getMarkerLocationDetails(locationId: number) {
        this.locationService.locationId = locationId;
    }

    public openHydrograph(locationId: number, locationName: string, timestamp: string, type: string) {
        // open graph dialog modal
        const dialogRef = this.dialog.open(AlarmGraphComponent, {
            disableClose: true,
            data: <GraphData>{
                locationId: locationId,
                locationName: locationName,
                eventDate: timestamp,
                timeFormat: this.timeFormat,
                isAlarmsWidget: true,
                hydrographAlarmThresholdType: type,
            },
        });

        dialogRef
            .afterOpened()
            .pipe(first())
            .subscribe(() => {
                dialogRef.componentInstance.loadGraphData();
            });
    }

    public trackById(index, item) {
        return index;
    }

    private sortActiveAlarms() {
        if (this.activeAlarms) {
            this.activeAlarms.sort((a, b) => {
                if (a.state < b.state) {
                    return -1;
                } else if (a.state > b.state) {
                    return 1;
                } else {
                    if (a.timestamp < b.timestamp) {
                        return 1;
                    } else if (a.timestamp > b.timestamp) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    /**
     * Function sets the alarm widget filter settings options
     */
    private setAlarmWidgetFilterSettings() {
        this.alarmFilterSettings = {
            displayLocations: true,
            displayAlarmStatus: true,
            singleLocationSelect: false,
            displayDateRanges: true,
            dateRangeOptional: true,
            displayAlarmType: true,
            skipInitialCall: true,
            setAlarmEmptyDate: true,
            removeFullWidthFromLocationInput: true,
            isEntitiesRequired: true,
        };
    }

    /**
     * //checks if the name length is more than specific length
     */
    public validateLocationNameLength(str: string, strLength: number): boolean {
        if ((str + '').length > strLength) {
            return true;
        } else {
            return false;
        }
    }

    public exportDataToCSV() {
        let status;

        const data = this.alarmWidgetDataSource.data.reduce((acc, curr) => {
            if (curr.state === 0) {
                status = 'Unacknowledged';
            } else if (curr.state === 1) {
                status = 'Acknowledged';
            } else if (curr.state === 2) {
                status = 'Cleared';
            } else {
                status = '-';
            }

            const formatted = [
                status,
                curr.type,
                curr.timestamp,
                curr.location,
                curr.acknowledgeBy,
                curr.acknowledgeTime,
                curr.clearBy,
                curr.clearTime,
                curr.returnToNormal,
            ].map((v) => (v ? v : '-'));
            acc.push(formatted);

            return acc;
        }, []);

        const csvHeaders = [
            'Status',
            'Type',
            'Date',
            'Location',
            'Acknowledged By',
            'Acknowledged DateTime',
            'Cleared By',
            'Cleared DateTime',
            'RTN DateTime',
        ];

        const options = {
            showLabels: true,
            headers: csvHeaders,
            title: `Alarms`,
            showTitle: true,
        };

        const result = new AngularCsv(data, 'Alarms', options);
    }


    /** #39909 Ability to Acknowledge All and/or Clear All Alarms */
    public alarmsShowConfirmation(
        titleTranslation: string,
        messageTranslation: string,
        errorTranslation: string,
        alarmsCount: number,
        apiCall: Observable<void>
    ) {
        const sub = this.dialog
            .open<ConfirmationDialogComponent, IComponentDialog, IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                disableClose: true,
                data: <IComponentDialog>{
                    title: this.translate.instant(`HOME.ALARMS_TILE.${titleTranslation}`),
                    message: this.translate.instant(`HOME.ALARMS_TILE.${messageTranslation}`).replace('%', alarmsCount),
                    okText: this.translate.instant('COMMON.CONFIRM_BTN'),
                    cancelText: this.translate.instant('COMMON.CANCEL')
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.whichButtonWasPressed === ConfirmationButton.ok) {
                    apiCall.subscribe({
                        next: () => {
                            this.alarmStatusChange.emit({ isAlarmStatusChanged: true, toggleState: this.toggleState });
                            this.generateAlarmHistory(this.filterParams);
                        },
                        error: () => {
                            this.snackBar.open(
                                this.translate.instant(`HOME.ALARMS_TILE.${errorTranslation}`),
                                this.translate.instant('COMMON.DISMISS_TEXT'),
                                { duration: 10000 }
                            );
                            this.alarmStatusChange.emit({ isAlarmStatusChanged: true, toggleState: this.toggleState });
                            this.generateAlarmHistory(this.filterParams);
                        }
                    })
                }
            });
        this.subscriptions.push(sub);
    }

    private generateParams(): AcknowledgeAlarms {
        const params: AcknowledgeAlarms = {
            locationGroupId: this.locationGroupID
        }

        if(this.filterParams?.StartTime) params.startTime = this.filterParams.StartTime;
        if(this.filterParams?.EndTime) params.endTime = this.filterParams.EndTime;
        if(this.filterParams?.LocationIds) params.locationIds = this.filterParams.LocationIds;
        if(this.filterParams?.AlarmDefinitionIds) params.alarmDefinitionIds = this.filterParams.AlarmDefinitionIds;

        return params;
    }

    /** #39909 Ability to Acknowledge All and/or Clear All Alarms */
    public alarmsClearAll() {
        this.alarmsShowConfirmation(
            'CONFIRM_CLEAR_TITLE',
            'CONFIRM_CLEAR_DETAILS',
            'CLEAR_ALL_FAIL',
            this.acknowledgedAlarmsCount,
            this.alarmService.clearAll(this.customerID, this.generateParams())
        )

        return true;
    }

    /** #39909 Ability to Acknowledge All and/or Clear All Alarms */
    public alarmsAcknowledgeAll() {
        this.alarmsShowConfirmation(
            'CONFIRM_ACKOWLEDGE_TITLE',
            'CONFIRM_ACKOWLEDGE_DETAILS',
            'ACKOWLEDGE_ALL_FAIL',
            this.unacknowledgedAlarmsCount,
            this.alarmService.acknowledgeAll(this.customerID, this.generateParams())
        )

        return true;
    }
}
