import { ActionTypes, BasicSeriesData, CopyPasteDestinationAction, DataType, HGEditParams, HGGraphData, ManipulationActions } from "app/shared/models/hydrographNEW";
import { LightningChartObject } from "./lightning-chart-object";
import { HydrographDataEditingService } from "app/shared/services/hydrograph-data-editing.service";
import { Point, RectangleFigure, SeriesXY } from "@arction/lcjs";
import { Subject } from "rxjs";
import { UpdatePoints } from "app/shared/models/data-edit";
import { LC_DOUBLE_CLICK_TOLERANCE } from "../lightning-chart-builder/lightning-chart-ui-constants";
import { LightningChartDataPoint } from "../lightning-chart-builder/lightning-chart-data-model";


export class LightningChartEdit {
    editService: HydrographDataEditingService;

    lcChart: LightningChartObject;
    selection: RectangleFigure[] = [];

    public areaSelectedDateRange: Subject<number[]> = new Subject<[]>();
    public flagPoints: Subject<number[]> = new Subject<[]>();
    public dataEdited: Subject<UpdatePoints[]> = new Subject(); // Emit changes to data for parent to track
    public doubleClick = new Subject<{ x: number; y: number }>(); // Emit double clicked point

    public editingParams: HGEditParams = { entity: null, action: null };
    public editingValuesSelected: Array<number>;
    public arePointsSelected = false;
    public editingDatesSelected: number[];
    public valueRange: number[];
    public editedPointsList: UpdatePoints[] = [];
    public selectedMoreThenHour = false;
    public interpolateHasBeenApplied = false;
    selectionStartTimestamp: number = null;

    private previousEntity: BasicSeriesData = null;
    curDragRow: {
        row: LightningChartDataPoint;
        serieIndex: number;
        dataIndex: number;
    } = null;


    constructor(lcChart: LightningChartObject) {
        this.lcChart = lcChart;
    }

    public applyAllowZoom() {
        const isZoom = [null, undefined].includes(this.editingParams.action) || this.editingParams.action === ActionTypes.Zoom;

        for(const chart of this.lcChart.charts) {
            chart
                .setMouseInteractionRectangleFit(isZoom)
                .setMouseInteractionRectangleZoom(isZoom);
        }
    }
    
    public resetInterpolateHasBeenApplied() {
        this.interpolateHasBeenApplied = false;
    }
    
    public setEditingParams(editingParams: HGEditParams) {
        // TODO: Something does change this.editingParams ouside of this method call
        const { action, entity } = editingParams;
        this.editingParams = editingParams;

        this.applyAllowZoom();

        if (this.previousEntity && entity && entity.entityId !== this.previousEntity.entityId) {
            const series = this.editService.updateSelectionToEntity(this.previousEntity, entity, this.lcChart.seriesData);

            this.lcChart.updateSeries(series.sd);
        }

        this.lcChart.detectChanges();
        this.lcChart.dashboard.engine.layout();

        this.previousEntity = this.editingParams?.entity;
    }

    public onEntityChanged() {
        this.lcChart.seriesData.forEach((series) => {
            if (series.data) {
                series.data.forEach((v) => (v.interpolated = false));
            }
        });

        const series = this.lcChart.seriesData.find((x) => x.entityId === this.editingParams.entity.entityId && x.lid === this.editingParams.entity.lid);

        // #41231 set higher Z index for editing entity
        if (series) {
            for (let i = 0; i < this.lcChart.chartSeries.length; i++) {
                const group = this.lcChart.chartSeries[i];

                group.forEach((serie) => {
                    if (serie.getName() === series.entityName) {
                        serie.setDrawOrder({ seriesDrawOrderIndex: 2 });
                    } else {
                        serie.setDrawOrder({ seriesDrawOrderIndex: 1 });
                    }
                });
            }
        }
        const dataType = series.dataType ? series.dataType : null;
        if (
            [ActionTypes.CopyPaste, ActionTypes.MultiPointEdit, ActionTypes.Interpolate].includes(
                this.editingParams.action,
            )
        ) {
            switch (dataType) {
                case DataType.Depth:
                case DataType.Velocity:
                    break;
                case DataType.Quantity:
                    this.editingParams.action = ActionTypes.CopyPaste;
                    break;
                case DataType.Rain:
                    this.setEditingParams({ action: ActionTypes.Zoom, entity: this.editingParams.entity });
                    this.editService.clearAll(this.lcChart.seriesData);
                default:
            }
        }
    }

    public selectAreaOnChartTick(rowIndex: number, event: MouseEvent, button: number, startLocation: Point) {
        if(this.selectionStartTimestamp === null) this.selectionStartTimestamp = event.timeStamp;

        switch(this.editingParams.action) {
            case ActionTypes.Zoom:
            case ActionTypes.ClearSelection:
                return ;
            case ActionTypes.DragDrop: {
                const chart = this.lcChart.charts[rowIndex];
                const {serieIndex} = this.lcChart.chartForSelectedEntity(this.editingParams.entity.entityId);

                const axis = this.lcChart.getXYaxisForEntity(this.editingParams.entity.entityName);

                if (!axis) return;
                const curLocationAxis = chart.translateCoordinate(event, axis);

                if(!this.curDragRow) {
                    let diffX = Number.MAX_SAFE_INTEGER;
                    let diffY = Number.MAX_SAFE_INTEGER;
                    const { entity } = this.editingParams;
                    const serieDef = this.lcChart.seriesDef[rowIndex].findIndex((e) => e.entityId === entity.entityId);

                    for (let iX = 0; iX < this.lcChart.seriesDef[rowIndex][serieDef].data.length; iX++) {
                        const row = this.lcChart.seriesDef[rowIndex][serieDef].data[iX];
                        const dx = Math.abs(curLocationAxis.x - row.x);
                        const dy = Math.abs(curLocationAxis.y - row.y);
                        const isdx = dx < diffX;
                        const isdy = dy < diffY;
                        if((isdx && isdy) || (dx+dy < diffX + diffY)) {
                            diffX = dx;
                            diffY = dy;
                            this.curDragRow = {
                                row: row,
                                serieIndex: serieDef,
                                dataIndex: iX
                            };
                        }
                    }

                    this.lcChart.dragSeries[rowIndex][serieIndex].clear();

                    const sdata = this.lcChart.seriesDef[rowIndex][this.curDragRow.serieIndex].data;
                    const data = [
                        sdata[this.curDragRow.dataIndex - 1],
                        sdata[this.curDragRow.dataIndex],
                        sdata[this.curDragRow.dataIndex + 1]
                    ];
                    this.lcChart.dragSeries[rowIndex][serieIndex].add(data);
                } else {
                    this.lcChart.dragSeries[rowIndex][serieIndex].clear();
                    const sdata = this.lcChart.seriesDef[rowIndex][this.curDragRow.serieIndex].data;
                    const data = [
                        sdata[this.curDragRow.dataIndex - 1],
                        {x: sdata[this.curDragRow.dataIndex].x, y: curLocationAxis.y},
                        sdata[this.curDragRow.dataIndex + 1]
                    ];
                    this.lcChart.dragSeries[rowIndex][serieIndex].add(data);
                }
                break;
            }
            case ActionTypes.Flag:
            case ActionTypes.MultiPointEdit:
            case ActionTypes.Unflag:
            case ActionTypes.CopyPaste:
            case ActionTypes.Interpolate:
            default:
                const chart = this.lcChart.charts[rowIndex];
                const rectangle = this.selection[rowIndex];

                const startLocationAxis = chart.translateCoordinate({ clientX: startLocation.x, clientY: startLocation.y }, chart.coordsAxis);
                const curLocationAxis = chart.translateCoordinate(event, chart.coordsAxis);

                rectangle.setDimensions({
                    x1: startLocationAxis.x,
                    y1: startLocationAxis.y,
                    x2: curLocationAxis.x,
                    y2: curLocationAxis.y,
                });
                if(!rectangle.getVisible()) rectangle.setVisible(true);
            break;
        }
    }

    public selectAreaOnChartEnd(rowIndex: number, event: MouseEvent, button: number, startLocation: Point) {
        const timestampDiff = this.selectionStartTimestamp === null ? 0 : event.timeStamp - this.selectionStartTimestamp;
        this.selectionStartTimestamp = null;
        if(timestampDiff < LC_DOUBLE_CLICK_TOLERANCE) return;

        switch(this.editingParams.action) {
            case ActionTypes.Zoom:
            case ActionTypes.ClearSelection:
                return;
            case ActionTypes.Flag:
            case ActionTypes.Unflag:
            {
                const {startLocationAxis, curLocationAxis} = this.resolveSelection(rowIndex, event, startLocation);
                const xRange = [Math.min(startLocationAxis.x, curLocationAxis.x), Math.max(startLocationAxis.x, curLocationAxis.x)];
                const yRange = [Math.min(startLocationAxis.y, curLocationAxis.y), Math.max(startLocationAxis.y, curLocationAxis.y)];
                this.updateFlaggedPoints(xRange, yRange)
                break;
            }
            case ActionTypes.DragDrop: {
                const chart = this.lcChart.charts[rowIndex];
                const {serieIndex} = this.lcChart.chartForSelectedEntity(this.editingParams.entity.entityId);

                const axis = this.lcChart.getXYaxisForEntity(this.editingParams.entity.entityName);
                if (!axis) return;

                const curLocationAxis = chart.translateCoordinate(event, axis);

                this.lcChart.seriesDef[rowIndex][this.curDragRow.serieIndex].data[this.curDragRow.dataIndex].y = curLocationAxis.y;

                this.applyDragAndDrop(
                    this.lcChart.seriesDef[rowIndex][this.curDragRow.serieIndex].data[this.curDragRow.dataIndex].x,
                    this.lcChart.seriesDef[rowIndex][this.curDragRow.serieIndex].data[this.curDragRow.dataIndex].y
                );

                this.curDragRow = null;
                this.lcChart.dragSeries[rowIndex][serieIndex].clear();
                break;
            }
            case ActionTypes.CopyPaste:
            case ActionTypes.MultiPointEdit:
            case ActionTypes.Interpolate:
            default: {
                const resolvedSelection = this.resolveSelection(rowIndex, event, startLocation);
                if(!resolvedSelection) {
                    break;
                }

                const {startLocationAxis, curLocationAxis} = resolvedSelection;
                const xRange = [Math.min(startLocationAxis.x, curLocationAxis.x), Math.max(startLocationAxis.x, curLocationAxis.x)];

                const serieDefinition = this.lcChart.seriesDefition[this.editingParams.entity.entityId];
                const precision = serieDefinition.precision;
                const yMinRange = Math.min(startLocationAxis.y, curLocationAxis.y);
                const yMaxRange =  Math.max(startLocationAxis.y, curLocationAxis.y);
                const yRange = [
                    (!yMinRange && yMinRange !== 0) ? null : Number(yMinRange.toFixed(precision)),
                    (!yMaxRange && yMaxRange !== 0) ? null : Number(yMaxRange.toFixed(precision))
                ];

                this.resetInterpolateHasBeenApplied();
                this.updateSelection(xRange, yRange);
                this.editingValuesSelected = yRange;
                this.areaSelectedDateRange.next(xRange);
                break;
            }
        }

        const rectangle = this.selection[rowIndex];
        rectangle?.setVisible(false);
        this.lcChart.detectChanges();
        this.lcChart.dashboard.engine.layout();
    }

    public mouseDoubleClick(event) {
        if(!this.lcChart.showEditingMenu) return;
        if(!this.editingParams || !this.editingParams.entity) return;

        const {chartIndex} = this.lcChart.chartForSelectedEntity(this.editingParams.entity.entityId);
        const chart = this.lcChart.charts[chartIndex];

        const axis = this.lcChart.getXYaxisForEntity(this.editingParams.entity.entityName);
        if (!axis) return;

        const chartPoint = chart.translateCoordinate(event, axis);
        const x = this.findClosestX(chartPoint.x);
        const position = {x: x, y: chartPoint.y};
        this.doubleClick.next(position);
    }

    public resolveSelection(rowIndex, event, startLocation) {
        if(!this.editingParams.entity) return;

        const {chartIndex} = this.lcChart.chartForSelectedEntity(this.editingParams.entity.entityId);
        const chart = this.lcChart.charts[chartIndex];

        const axis = this.lcChart.getXYaxisForEntity(this.editingParams.entity.entityName);
        if (!axis) return;

        const startLocationAxis = chart.translateCoordinate({ clientX: startLocation.x, clientY: startLocation.y }, axis);
        const curLocationAxis = chart.translateCoordinate(event, axis);

        return {
            startLocationAxis: startLocationAxis,
            curLocationAxis: curLocationAxis
        }
    }

    public updateFlaggedPoints(dates: number[], valueRange: number[]) {
        const retArr = this.editService.updateFlags(
            this.editingParams.action === ActionTypes.Flag,
            this.editingParams.entity,
            dates,
            valueRange,
            this.lcChart.seriesData,
            this.editedPointsList,
        );

        this.emitPointsEdited();

        this.lcChart.updateSeries(retArr[0] as BasicSeriesData[]);
    }

    public notifyPointsSelected(areSelected: boolean, selectedPoints?: HGGraphData[], currentlySelectedPoints?: HGGraphData[]) {
        this.arePointsSelected = areSelected;

        if (!this.arePointsSelected) {
            this.selectedMoreThenHour = false;
        } else {
            let moreThenHour = false;

            // #28614 Check time between all the points - we allow to interpolate not more then 1 hour of GAP
            for (let i = 1; i < selectedPoints.length; i++) {
                const range = selectedPoints[selectedPoints.length - 1].x - selectedPoints[0].x;
                moreThenHour = range > 60 * 60 * 1000;

                if (moreThenHour) break;
            }

            if(currentlySelectedPoints.length<3){
                moreThenHour = true;
            }

            this.selectedMoreThenHour = moreThenHour;
        }
    }

    public updateSelection(dates: number[], valueRange: number[]) {
        const { entity } = this.editingParams;
        const selectionArr =
            this.editService.updateSelection(
                entity,
                dates,
                valueRange,
                this.lcChart.seriesData,
                this.editedPointsList
            );
        const isSelection = selectionArr.s.some(p => p.selected);
        this.notifyPointsSelected(isSelection,selectionArr.s, selectionArr.currentlySelectedPoints);

        this.editingDatesSelected = dates;
        this.valueRange = valueRange;
        this.lcChart.updateSeries(selectionArr.sd);
    }


    public setAction(event: { action: ActionTypes }) {
        if (event.action === ActionTypes.ClearSelection) {
            this.clearSelection();
        }
    }

    public clearSelection() {
        this.editService.clearSelection(this.editingParams.entity.entityId, this.lcChart.seriesData);
        this.notifyPointsSelected(false);
        this.lcChart.updateSeries(this.lcChart.seriesData);
    }

    public applyMultiPointEdit(event: { action: ManipulationActions; value: number }) {
        this.editService.multiPointEdit(
            this.editingDatesSelected,
            this.editingValuesSelected,
            this.editingParams.entity,
            event.action,
            event.value,
            this.lcChart.seriesData,
            this.editedPointsList
        );

        this.emitPointsEdited();
    }

    private findClosestX(x: number, entityId: number = null): number {
        let ox = x;
        let diff = Number.MAX_SAFE_INTEGER;

        if (entityId) {
            const entity = this.lcChart.seriesData.find(v => v.entityId === entityId);

            if (entity && entity.data) {
                entity.data.forEach((d) => {
                    const newDiff = Math.abs(x - d.x);
                    // Last point has y null and most likely refers to chart max x, exclude it
                    if (newDiff < diff && d.y !== null && d.y !== undefined) {
                        diff = newDiff;
                        ox = d.x;
                    }
                });
            }
        } else {
            this.lcChart.seriesData.forEach((s) => {
                s.data && s.data.forEach((d) => {
                    const newDiff = Math.abs(x - d.x);
                    // Last point has y null and most likely refers to chart max x, exclude it
                    if (newDiff < diff && d.y !== null && d.y !== undefined) {
                        diff = newDiff;
                        ox = d.x;
                    }
                });
            });
        }

        return ox;
    }
    public setDestinationDate(event: { date: Date; entityId: number }) {
        const { date, entityId } = event;
        const x = this.findClosestX(date.getTime(), entityId);
        this.lcChart.destinationDate.next(new Date(x));
    }

    public onRangeChanged(event: number[]) {
        this.editingDatesSelected = event;
        this.updateSelection(this.editingDatesSelected,this.valueRange);
    }

    public applyCopyPaste(event: CopyPasteDestinationAction) {
        this.editService.copyPaste(
            this.editingDatesSelected,
            this.editingParams.entity,
            event.entity.entityId,
            event.date,
            this.lcChart.seriesData,
            this.editedPointsList,
            event.action,
            event.value,
        );

        this.emitPointsEdited();
    }

    public onInterpolatePoints(dates?: number[]) {
        this.lcChart.seriesData.forEach((v) => {
            const modifiedPoints = [];
            v.data && v.data.forEach((i) => {
                if (i.interpolated) modifiedPoints.push(i);
                i.interpolated = false;
            });
        });

        this.lcChart.updateSeries(this.lcChart.seriesData);

        const isPointSelected = this.editService.interpolatePoints(this.editingParams.entity, this.lcChart.seriesData, dates);

        if(this.lcChart.lightningChartReceiver.setPointsForInterpolation) {
            this.lcChart.lightningChartReceiver.setPointsForInterpolation(isPointSelected);
        }
    }

    public applyInterpolate(isSelectedPoints: boolean) {
        const range = this.findSelectedStartEnd();
        const iss = range && range.start ? new Date(range.start).toISOString() : null;
        const ise = range && range.end ? new Date(range.end).toISOString() : null;
        const editedEntityId = this.editingParams.entity.entityId;
        const dates = isSelectedPoints ? null : [range.start, range.end];

        this.onInterpolatePoints(dates);

        const requestParams: {
            iss: string;
            ise: string;
            updatePoints?: {
                timeStamp: Date;
                eid: number;
                reading: number;
                originalValue: {
                    dateTime: number;
                    x: number;
                    y: number;
                    correctedY: number;
                    flagged: boolean;
                    eid: number;
                };
            }[],
            ieids?: number[]
        } = { iss, ise };

        if (isSelectedPoints) {
            const points = this.lcChart.seriesData
                .find((v) => v.entityId === this.editingParams.entity.entityId && v.lid === this.editingParams.entity.lid)
                .data.filter((v) => v.interpolated)
                .map((point) => {
                    return {
                        timeStamp: new Date(point.x),
                        eid: this.editingParams.entity.entityId,
                        reading: point.correctedY ? point.correctedY : point.y,
                        originalValue: { dateTime: point.x, x: point.x, y: point.y, correctedY: point.correctedY, flagged: point.flagged, eid: this.editingParams.entity.entityId }
                    };
                });
            if(!points || !points.length) return;

            requestParams.updatePoints = JSON.parse(JSON.stringify(points));
            requestParams.iss = points[0].timeStamp.toISOString();
            requestParams.ise = points[points.length - 1].timeStamp.toISOString();

        } else {
            requestParams.ieids = [editedEntityId];
        }
        this.interpolateHasBeenApplied = true;
        this.lcChart.lightningChartReceiver.onSubmitInterpolate({ requestParams, editedEntityId, isSelectedPoints });
    }

    public applyDragAndDrop(x: number, y: number) {
        const { entity } = this.editingParams;
        this.editService.applyDragAndDrop(
            entity,
            x,
            this.lcChart.seriesData,
            y,
            this.editedPointsList,
        );
        this.emitPointsEdited();
        this.lcChart.updateSeries(this.lcChart.seriesData);
    }

    private findSelectedStartEnd(): null | { start: number; end: number } {
        const { entity } = this.editingParams;
        const currentSeries = this.lcChart.seriesData.find((e) => entity && entity.entityId === e.entityId && e.lid === entity.lid);
        if (currentSeries) {
            let start = -1;
            let end = -1;
            for (let i = 0; i < currentSeries.data.length; i++) {
                const d = currentSeries.data[i];
                if (d.selected) {
                    if (start === -1) {
                        start = i;
                    } else {
                        end = i;
                    }
                }
            }

            if (start >= 0 && end >= 0) {
                const newStart = currentSeries.data[start].x;
                const newEnd = currentSeries.data[end].x;

                return { start: newStart, end: newEnd };
            }
        }

        return null;
    }

    public emitPointsEdited() {
        this.lcChart.lightningChartReceiver.dataEdit(this.editedPointsList);
        this.editedPointsList = [];
    }


    public emitFlagPoints() {
        this.lcChart.lightningChartReceiver.onFlagPoints();
    }

    public clearEditingParams() {
        this.editingParams = { entity: null, action: null };
        this.applyAllowZoom();
    }
}
