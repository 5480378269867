import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseMapListItem, GisServiceList, GisToken, ServiceTypes } from 'app/shared/models/gis-service-list';
import { gisUserSettings, userSettingLayerDetails } from 'app/shared/models/gis-service-list';
import { GISLayerMapping } from 'app/shared/models/gis-service-list';
import { Config } from './config';
import { Subject, Observable, BehaviorSubject, of } from 'rxjs';
import { OLMAPPROJECTION, ADS_LAYER_BBOX } from 'app/shared/constant';
import { catchError, concatMap, first, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { createStyleFunction } from 'ol-esri-style';
import { Fill, RegularShape, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import EsriJSON from 'ol/format/EsriJSON';
import { TileArcGISRest } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import LineString from 'ol/geom/LineString';
import * as OlMap from 'ol/Map';
import { Feature } from 'ol';
import { LocationCardService } from '../components/location-card/services/location-card.service';

const esrijsonFormat = new EsriJSON();

export enum BasemapNames {
    topographic = 'Topographic',
    imagery = 'Imagery (Hybrid)',
    esri = 'ESRI',
    esriImage = 'ESRI Image',
    osm = 'OSM',
    esriBasemap = 'ESRI BaseMap'
}

export interface LayerInfo {
    type,
    minScale,
    maxScale,
    drawingInfo,
    error
}

export interface LayerSettings {
    name: string,
    checked: boolean,
    child: LayerSettings[],
    selection: boolean,
    opacity: number,
    legendInfo: LegendInfo[],
}

export enum LegendType {
    LINE = 1,
    AREA = 2,
    CIRCLE = 3
}

enum LegendSymbolCode {
    circle = 'esriSMS',
    area = 'esriSFS',
    line = 'esriSLS'
}

export interface LegendInfo {
    label: string;
    url: string | SafeResourceUrl;
    color: string;
    borderColor: string;
    type: LegendType;
    height: string;
}

@Injectable()
export class GISService {
    public layerToFeaturesRelation = new Map<string, Map<string, Feature>>();
    public coordinatePickerFromMap = new Subject();

    public gisUserSettingsSubject$: BehaviorSubject<gisUserSettings> = new BehaviorSubject<gisUserSettings>(null);
    private readonly http: HttpClient;

    public locationCardPosition: { top: string; left: string };
    constructor(
        backend: HttpBackend,
        private apiHttp: HttpClient,
        private authService: AuthService,
        private _sanitizer:DomSanitizer,
        private locationCardService: LocationCardService
    ) {

        this.authService.gotTheToken$.subscribe((isToken) => {
            if (isToken) {
                this.apiHttp
                    .get<gisUserSettings>(Config.urls.GISServices + '/usersettings')
                    .pipe(
                        catchError((err) => of({} as gisUserSettings)),
                    )
                    .subscribe((setting) => {
                        this.gisUserSettingsSubject$.next(setting);
                        if (setting) {
                            this.locationCardPosition = this.locationCardService.getLocationCardPosition(true, setting);
                        } else {
                            this.locationCardPosition = { top: '0px', left: '0px' };
                        }
                    });
            }
        });

        this.http = new HttpClient(backend);
    }

    public gisUserSettingsGet(): Promise<gisUserSettings> {
        return this.gisUserSettingsSubject$.pipe(first()).toPromise();
    }

    // GIS Services List
    public getGISServicesList(customerId: number): Observable<GisServiceList[]> {
        return this.apiHttp.get<GisServiceList[]>(Config.urls.GISServices + '/services/' + customerId);
    }

    // TODO: Bug #19937 - getCapabilities. Code generated by old team for WMS or WFS service types. They are not used, if will be needed then rewrite this.
    // public getLayerList(url: string) {
    // 	const newUrl = url + '?request=GetCapabilities';
    // 	const auth = {
    // 		'Content-type': 'application/json',
    // 		"Authorization": 'Basic SURFWDprM1IqdjJMeHEjRkQ='
    // 	}

    // 	// const authen = auth.split('&');
    // 	// const param1 =authen[0].split('=');
    // 	// const param2 =authen[1].split('=');
    // 	// debugger;
    // 	//  let headerparama: HttpHeaders = new HttpHeaders();
    // 	//   headerparama = headerparama.append(param1[0], param1[1]);
    // 	//   headerparama = headerparama.append(param2[0], param2[1]);
    // 	//   const autherization = btoa(param1[1]+ ':'+param2[1]);
    // 	//   //btoa('IDEX:k3R*v2Lxq#FD')
    // 	return this.apiHttp.get(newUrl, {
    // 		responseType: 'text',
    // 		headers: new HttpHeaders(auth)
    // 	});
    // }

    public getServiceToken(url: string, urlAuth: string, username: string, pass: string) {
        // #21978 - using "two factor" tokens
        if (urlAuth && urlAuth !== '') return this.getTwoFactorToken(url, urlAuth, username, pass);

        return this.getBasicToken(url, username, pass);
    }

    // Implementation for #21978 - using "two factor" tokens
    public getTwoFactorToken(url: string, urlAuth: string, username: string, pass: string) {
        const data =
            'username=' + username + '&password=' + pass + '&client=requestip&ip=&referer=&expiration=60&f=json';
        return this.http
            .post(urlAuth, data, {
                headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            })
            .pipe(
                concatMap((res: { token: string }) => {
                    const secondTokenURL = `${urlAuth}?request=getToken&serverUrl=${decodeURI(url)}&token=${
                        res.token
                    }&referer=www.arcgis.com&f=json`;
                    return this.http.get(secondTokenURL);
                }),
            );
    }

    /** Default and standard, generateToken request */
    public getBasicToken(url: string, username: string, pass: string) {
        const data =
            'username=' + username + '&password=' + pass + '&client=requestip&ip=&referer=&expiration=60&f=json';
        const urls = url.split('/');
        const newurl = urls[0] + '//' + urls[2] + '/' + urls[3] + '/tokens/generateToken';
        return this.http.post(newurl, data, {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
    }

    public postArcGISLayerList(url: string, token: string) {
        const data = 'token=' + token;
        return this.http.post(url + '?f=pjson', data, {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
    }
    public getArcGISLayerList(url: string) {
        return this.http.get(url + '?f=pjson', {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
    }
    public getFeatureLayerData(url: string) {
        return this.http.get<LayerInfo>(url, {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        });
    }

    public getAttribueList(gisService: GisServiceList, LayerName: string) {
        const url =
            gisService.url +
            '?&INFO_FORMAT=application/json&' +
            'REQUEST=GetFeatureInfo&EXCEPTIONS=application/vnd.ogc.se_xml&SERVICE=WMS&VERSION=1.1.1&BBOX=' +
            ADS_LAYER_BBOX +
            '&' +
            'LAYERS=' +
            LayerName +
            '&QUERY_LAYERS=' +
            LayerName +
            '&FEATURE_COUNT=1&X=1&Y=1&CRS=' +
            OLMAPPROJECTION +
            '&STYLES=&WIDTH=1&HEIGHT=1';
        return this.apiHttp.get(url);
    }

    public getWMSFetureInfo(url: string, layerName: string, bbox: string, authparam: string) {
        const urls =
            url +
            '?&INFO_FORMAT=application/json&' +
            'REQUEST=GetFeatureInfo&EXCEPTIONS=application/vnd.ogc.se_xml&SERVICE=WMS&VERSION=1.1.1&BBOX=' +
            bbox +
            '&' +
            'LAYERS=' +
            layerName +
            '&QUERY_LAYERS=' +
            layerName +
            '&FEATURE_COUNT=100000&X=1&Y=1&CRS=' +
            OLMAPPROJECTION +
            '&STYLES=&WIDTH=1&HEIGHT=1';
        return this.apiHttp.get(urls, {
            headers: new HttpHeaders({ Authorization: 'Basic SURFWDprM1IqdjJMeHEjRkQ=' }),
        });
    }

    // GIS Services Update
    public updateGISService(gisService: GisServiceList) {
        const body = {
            serviceId: gisService.serviceId,
            serviceType: gisService.serviceType,
            URL: gisService.url,
            authUrl: gisService.authURL,
            AuthDetails: '',
            CustomerId: gisService.customerId,
            AuthMethod: gisService.authMethod,
            UserName: gisService.userName,
            Password: gisService.password,
            authParams: gisService.authParams,
        };
        return this.apiHttp.post(Config.urls.GISServices + '/services/' + gisService.customerId, body);
    }

    // GIS Services Save
    public addGISService(gisService: GisServiceList) {
        const body = {
            serviceType: gisService.serviceType,
            url: gisService.url,
            authUrl: gisService.authURL,
            authDetails: '',
            customerId: gisService.customerId,
            authMethod: gisService.authMethod,
            userName: gisService.userName,
            password: gisService.password,
            authParams: gisService.authParams,
        };
        return this.apiHttp.post(Config.urls.GISServices + '/services/' + gisService.customerId, body);
    }

    // GIS Services Delete
    public deleteGISservice(customerId: number, serviceId: number) {
        return this.apiHttp.delete(Config.urls.GISServices + '/services/' + customerId + '/' + serviceId);
    }

    /// tile loading
    public tileloader(src) {
        return this.apiHttp.get(src, {
            responseType: 'blob',
            headers: new HttpHeaders({
                Authorization: 'Basic SURFWDprM1IqdjJMeHEjRkQ=',
                Accept: 'image/png',
            }),
        });
    }
    /*public tileloader(tile, src) {
	  var xhr = new XMLHttpRequest();
	  xhr.responseType = 'blob';
	  xhr.addEventListener('loadend', function (evt) {
		var data = this.response;
		if (data !== undefined) {
		  tile.getImage().src = URL.createObjectURL(data);
		} else {
		  tile.setState(TileState.ERROR);
		}
	  });
	  xhr.addEventListener('error', function () {
		tile.setState(TileState.ERROR);
	  });
	  xhr.open('GET', src);
	  xhr.send();
	};*/

    // Loading WFS service
    public loadWFSLayerFeatures(url: string, projection: string, layername: string, param: string, extent: string) {
        const wfsurl =
            url +
            '?service=WFS&version=1.0.0&request=GetFeature&typename=' +
            layername +
            '&outputFormat=application/json&srsname=' +
            projection +
            param +
            extent;
        return this.apiHttp.get(wfsurl);
    }

    public loadWFSLayerFeaturesAuth(
        url: string,
        projection: string,
        layername: string,
        param: string,
        extent: string,
        auth: string,
    ) {
        const wfsurl =
            url +
            '?service=WFS&version=1.0.0&request=GetFeature&typename=' +
            layername +
            '&outputFormat=application/json&srsname=' +
            projection +
            param +
            extent;
        return this.apiHttp.get(wfsurl, {
            headers: new HttpHeaders({ Authorization: 'Basic SURFWDprM1IqdjJMeHEjRkQ=' }),
        });
    }

    //BaseMap
    public getGISBasemap() {
        return this.apiHttp.get<BaseMapListItem[]>(Config.urls.GISServices + '/basemaps');
    }

    public getGisToken() {
        return this.apiHttp.get<GisToken>(Config.urls.gisToken);
    }

    //User Setting

    // public getGisUserSettings() {
    // 	return this.apiHttp.get<gisUserSettings>(Config.urls.GISServices + '/usersettings').pipe(
    // 		tap(v => this.userSettings = v)
    // 	);
    // }
    public updateGisUserSettings(gisUserSettings: gisUserSettings) {
        gisUserSettings.customerSettings = gisUserSettings
            ? gisUserSettings.customerSettings.filter((x) => x.cid !== 0)
            : null;
        if (gisUserSettings && gisUserSettings._etag) {
            delete gisUserSettings._etag;
        } // Can't be on update call

        this.gisUserSettingsSubject$.next(gisUserSettings);

        return this.apiHttp.post(Config.urls.GISServices + '/usersettings', gisUserSettings);
    }
    public updateLayerGisUserSettings(customerId: number, userSettingLayerDetails: userSettingLayerDetails) {
        return this.apiHttp.post(
            Config.urls.GISServices + '/updatelayerusersetting?customerId=' + customerId,
            userSettingLayerDetails,
        );
    }

    cacheCustomerSettings: {
        customerid: number;
        res: Customer;
    }

    public getCustomerSettings(customerid: number, reloadCache = true) {
        if(!reloadCache
            && this.cacheCustomerSettings
            && this.cacheCustomerSettings.customerid === customerid
        ) {
            return of(this.cacheCustomerSettings.res)
        } else {
            return this.apiHttp.get<Customer>(Config.getUrl(Config.urls.activateCustomer) + '/' + customerid)
                .pipe(
                    tap((res) => {
                        if(res) {
                            this.cacheCustomerSettings = {
                                customerid: customerid,
                                res: res
                            }
                        }
                    })
                )
        }
    }

    //Layer Mapping
    public saveGisLayerMapping(gisLayerMapping: GISLayerMapping[]) {
        return this.apiHttp.post(Config.urls.GISServices + '/layermapping', gisLayerMapping);
    }
    public getGisLayerMapping(customerId: number): Observable<GISLayerMapping[]> {
        return this.apiHttp.get<GISLayerMapping[]>(Config.urls.GISServices + '/layermappings/' + customerId);
    }

    //////////// ARCGIS
    public arcGISToken: string;

    public async generateArcGisToken(service) {
        // if there are multiple services, we need to generate a token for each service
        // so i am resetting the token to undefined
        this.arcGISToken = undefined;
        this.arcGISToken = await this.generateToken(service) ?? undefined;
    }

    public clearArcGisToken() {
        this.arcGISToken = undefined;
    }

    private async generateToken(service) {
        if (service.userName === '' && service.password === '') {
            return null;
        }
        const tokenRes = await this
            .getServiceToken(service.url, service.authURL, service.userName, service.password)
            .toPromise();
        try {
            (<any>tokenRes).token;
        } catch (e) {
            return null;
        }
        return (<any>tokenRes).token;
    }

    public getSavedLayerSettings(customerID: number, gisUserSetting: gisUserSettings, layerName: string): userSettingLayerDetails {
        const affectedCustomer = gisUserSetting.customerSettings.find((x) => x.cid === customerID);
        if (!affectedCustomer) {
            return undefined;
        }
        const affectedLayer = affectedCustomer.layers.find((x) => x.name === layerName);
        return affectedLayer;
    }


    public async layerLoad(
        services,
        gisUserSetting,
        customerID: number,
        shouldProcess: () => boolean,
        attributes: {
            checkedLayersList: Set<number>,
            layerNameToEsriLayerId: {[index: string]: number},
            olMap: OlMap.default,
            prismLayerList: LayerSettings[],
            esriTileSource: TileArcGISRest,
            esriTileLayer: TileLayer
        },
        options: {
            defaultOpacity: number
        }
    ) {
        if (services !== null) {
            // #42845 making sure we process map server and then feature server so that the layers dont cover each other.
            const mapServices = services.filter(service => service.serviceType === "maps");
            const featServices = services.filter(service => service.serviceType === "feat");
            services = [...mapServices, ...featServices];
            for (const service of services) {

                if(!shouldProcess()) return;

                const serviceType = ServiceTypes.FEATURE as ServiceTypes;
                if (service.serviceType == ServiceTypes.WMS || service.serviceType == ServiceTypes.WFS) {
                    // TODO: Bug #19937 - getCapabilities. Code generated by old team for WMS or WFS service types. They are not used, if will be needed then rewrite this.
                    // // TODO geoserverPrefix and Authorization are hardcoded for CWW
                    // // create a user setting for this
                    // const geoserverPrefix = 'CWW_IDEX'
                    // const capResponse: any = await fetch(service.url + '?request=GetCapabilities', {
                    //     method: 'GET',
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //         'Authorization': 'Basic SURFWDprM1IqdjJMeHEjRkQ='
                    //     }
                    // }).catch(() => {
                    // });
                    // if (!capResponse) break; // TODO: Consider change to CONTINUE so it won't stop loading them for every service
                    // const capResponsetext = await capResponse.text()
                    // let xmaloutput = converter.xml2json(capResponsetext, { compact: true, spaces: 2 });
                    // const Capabilities = JSON.parse(xmaloutput).WMS_Capabilities
                    // if (!Capabilities) break; // TODO: Consider change to CONTINUE so it won't stop loading them for every service
                    // const newlayerlist = Capabilities.Capability.Layer.Layer;
                    // if (service.serviceType == ServiceTypes.WMS) {
                    //     let layerNumId = 0;
                    //     newlayerlist.forEach(layer => {
                    //         layer.esriLayerId = geoserverPrefix + ':' + layer.Name._text
                    //         let layerVisible = true;
                    //         const savedLayerSettings = this.getSavedLayerSettings(layer.Name._text);
                    //         let layerSettingsToUse = {
                    //             name: layer.Name._text,
                    //             checked: true,
                    //             child: [],
                    //             selection: true,
                    //             opacity: this.defaultOpacity,
                    //             legendInfo: []
                    //         };
                    //         if (savedLayerSettings) {
                    //             if (savedLayerSettings.isVisible !== undefined) {
                    //                 layerSettingsToUse.checked = savedLayerSettings.isVisible
                    //                 layerVisible = savedLayerSettings.isVisible;
                    //             }
                    //             if (savedLayerSettings.isSelectable !== undefined) {
                    //                 layerSettingsToUse.selection = savedLayerSettings.isSelectable
                    //             }
                    //         }
                    //         if (layerSettingsToUse.checked) {
                    //             this.checkedLayersList.add(layer.esriLayerId)
                    //         }
                    //         this.layerNameToEsriLayerId[layer.Name._text] = layer.esriLayerId
                    //         layer.id = layerNumId
                    //         layerNumId++;
                    //         this.prismLayerList.push(layerSettingsToUse);
                    //     });
                    //     var filteredLayersList = Array.from(this.checkedLayersList).filter(function (el) {
                    //         return el != null;
                    //     });
                    //     let esriTileSourceParams = {
                    //         LAYERS: filteredLayersList.toString(),
                    //         'TILED': true,
                    //         "CRS": OLMAPPROJECTION
                    //     }
                    //     this.esriTileSource = new TileWMS({
                    //         url: service.url,
                    //         params: esriTileSourceParams
                    //     })
                    //     this.esriTileSource.setTileLoadFunction((tile, src) => {
                    //         this.tileloading(tile, src);
                    //     });
                    //     this.esriTileLayer = new TileLayer({
                    //         source: this.esriTileSource,
                    //         name: 'esriTileLayer',
                    //         visible: filteredLayersList.length > 0
                    //     })
                    //     this.olMap.addLayer(this.esriTileLayer);
                    // } else {
                    //     // newlayerlist = JSON.parse(xmaloutput)['wfs:WFS_Capabilities'].FeatureTypeList.FeatureType;
                    //     // newlayerlist.forEach(layer => {
                    //     //     const savedLayerSettings = this.getSavedLayerSettings(layer.Name._text);
                    //     //     if (savedLayerSettings) {
                    //     //         this.prismLayerList.push({
                    //     //             name: savedLayerSettings.name,
                    //     //             checked: savedLayerSettings.isVisible,
                    //     //             child: [],
                    //     //             selection: savedLayerSettings.isSelectable,
                    //     //             legend: savedLayerSettings.name, // TODO AMP: this needs real legend info
                    //     //             legendurl: '',
                    //     //             opacity: this.defaultOpacity
                    //     //         });
                    //     //     } else {
                    //     //         this.prismLayerList.push({
                    //     //             name: layer.Name._text,
                    //     //             checked: true, child: [],
                    //     //             selection: true,
                    //     //             legend: true,
                    //     //             legendurl: '',
                    //     //             opacity: this.defaultOpacity
                    //     //         });
                    //     //     }
                    //     // });
                    // }
                    // this.setMapList();
                    //this.prismLayersGroup.setLayers(this.prismLayerGroupList);
                } else if (serviceType == ServiceTypes.MAPS || serviceType == ServiceTypes.FEATURE) {
                    await this.generateArcGisToken(service);
                    await this.arcLayerLoading(
                        service,
                        gisUserSetting,
                        customerID,
                        shouldProcess,
                        {
                            checkedLayersList: attributes.checkedLayersList,
                            layerNameToEsriLayerId: attributes.layerNameToEsriLayerId,
                            olMap: attributes.olMap,
                            prismLayerList: attributes.prismLayerList,
                            esriTileSource: attributes.esriTileSource,
                            esriTileLayer: attributes.esriTileLayer
                        },
                        {
                            defaultOpacity: options.defaultOpacity
                        }
                    );

                }
            }
        }
    }

    private createLegendInfo(label: string, url: string | SafeResourceUrl, symbol, color?: string, height?: string, borderColor?: string): LegendInfo {
        let legendType: LegendType;

        if (symbol && symbol.type === LegendSymbolCode.circle) {
            legendType = LegendType.CIRCLE;
        } else if (symbol && symbol.type === LegendSymbolCode.line) {
            legendType = LegendType.LINE;
        } else {
            legendType = LegendType.AREA;
        }

        return {
            label: label,
            url: url,
            color: color ? color : null,
            borderColor: borderColor ? borderColor : null,
            height: height ? height: null,
            type: legendType
        }
    }

    public async arcLayerLoading(
        service,
        gisUserSetting,
        customerID: number,
        shouldProcess: () => boolean,
        attributes: {
            checkedLayersList: Set<number>,
            layerNameToEsriLayerId: {[index: string]: number},
            olMap: OlMap.default,
            prismLayerList: LayerSettings[],
            esriTileSource: TileArcGISRest,
            esriTileLayer: TileLayer
        },
        options: {
            defaultOpacity: number
        }
    ) {
        service.url = service.url.trim();
        this.layerToFeaturesRelation.clear();
        const response = this.arcGISToken
            ? await this.postArcGISLayerList(service.url, this.arcGISToken).toPromise()
            : await this.getArcGISLayerList(service.url).toPromise();
        const arcLayers = response as {layers};

        const layerAttributes = {};
        if (service.serviceType === ServiceTypes.MAPS) {
            let legendUrl = service.url + '/legend?f=json';
            if (this.arcGISToken) {
                legendUrl += '&token=' + this.arcGISToken;
            }

            const legendInfo = await fetch(legendUrl);
            const legendJson = await legendInfo.json();
            if (legendJson && legendJson.layers) {
                for (const item of legendJson.layers) {
                    layerAttributes[item.layerId] = item;
                }
            }
        }

        // To make vector layers almost invisible. If they are completely invisible feature selection tools does not work properly
        const nofill = new Fill({
            color: 'rgba(255,255,255,0.05)',
        });
        const nostroke = new Stroke({
            color: 'rgba(255,255,255,0.05)',
        });
        const noimage = new RegularShape({
            fill: nofill,
            stroke: nostroke,
            points: 4,
            radius: 10,
            angle: Math.PI / 4,
        }) as any;

        if (arcLayers.layers) {
            // make vector layers to allow feature selection tool
            for (const lyr of arcLayers.layers) {

                if(!shouldProcess()) return;

                // #23256
                const sourceUrl = service.url + '/' + lyr.id;
                let queryUrl = null;
                if (this.arcGISToken) {
                    queryUrl = '?token=' + this.arcGISToken;
                }
                if(queryUrl === null) {
                    queryUrl = '?';
                } else {
                    queryUrl += '&';
                }

                queryUrl += 'f=json';
                const layerInfo = await this.getFeatureLayerData(sourceUrl + queryUrl).toPromise();
                const layerInfoJson = await (layerInfo);



                if (service.serviceType === ServiceTypes.FEATURE) {
                    layerAttributes[lyr.id] = {
                        layerType: layerInfoJson.type,
                        minScale: layerInfoJson.minScale ? layerInfoJson.minScale : Number.MAX_SAFE_INTEGER,
                        maxScale: layerInfoJson.maxScale,
                        legend: layerInfoJson.drawingInfo,
                    };
                }
                let layerVisible = true;
                const savedLayerSettings = this.getSavedLayerSettings(customerID, gisUserSetting, lyr.name);
                const layerSettingsToUse = {
                    name: lyr.name,
                    checked: true,
                    child: [],
                    selection: true,
                    opacity: layerAttributes[lyr.id] && layerAttributes[lyr.id].legend && layerAttributes[lyr.id].legend.transparency
                        ? (layerAttributes[lyr.id].legend.transparency / 100) : options.defaultOpacity,
                    legendInfo: [],
                };

                if (savedLayerSettings) {
                    if (savedLayerSettings.isVisible !== undefined) {
                        layerSettingsToUse.checked = savedLayerSettings.isVisible;
                        layerVisible = savedLayerSettings.isVisible;
                    }
                    if (savedLayerSettings.isSelectable !== undefined) {
                        layerSettingsToUse.selection = savedLayerSettings.isSelectable;
                    }
                }

                if (layerSettingsToUse.checked) {
                    attributes.checkedLayersList.add(lyr.id);
                }

                attributes.layerNameToEsriLayerId[lyr.name] = lyr.id;
                if (layerAttributes[lyr.id] && layerAttributes[lyr.id].layerType === 'Feature Layer') {
                    const vectorSource = new VectorSource({
                        format: new GeoJSON(),
                        loader: async (extent, resolution, projection) => {
                            let url =
                                sourceUrl +
                                '/query/?f=json&' +
                                'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
                                encodeURIComponent(
                                    '{"xmin":' +
                                        extent[0] +
                                        ',"ymin":' +
                                        extent[1] +
                                        ',"xmax":' +
                                        extent[2] +
                                        ',"ymax":' +
                                        extent[3] +
                                        ',"spatialReference":{"wkid":102100}}',
                                ) +
                                '&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*' +
                                '&outSR=102100';
                            if (this.arcGISToken) {
                                url += '&token=' + this.arcGISToken;
                            }
                            const layerData = await this.getFeatureLayerData(url).toPromise();
                            const features = esrijsonFormat.readFeatures(<any>layerData, {
                                featureProjection: projection,
                            });
                            this.setLayerToFeaturesRelation(lyr, features);
                            if (features.length > 0) {
                                vectorSource.addFeatures(features);
                            }
                        },
                        strategy: bboxStrategy,
                    });

                    // conversion units from Scale (esri) to Resolution (openLayers)
                    const inchesPerMetre = 39.3701;
                    const DOTS_PER_INCH = 72;

                    let minRes = layerAttributes[lyr.id].maxScale / (inchesPerMetre * DOTS_PER_INCH);
                    let maxRes = layerAttributes[lyr.id].minScale / (inchesPerMetre * DOTS_PER_INCH);
                    if (minRes === maxRes) {
                        if (minRes === 0) {
                            // Both are 0, show at all resolutions
                            maxRes = undefined;
                        } else {
                            // Equal but not at 0, show only at/below that resolution
                            minRes = 0;
                        }
                    }

                    const options = {
                        name: lyr.name,
                        visible: layerVisible,
                        source: vectorSource,
                        minResolution: minRes,
                        maxResolution: maxRes,
                        opacity: layerAttributes[lyr.id] && layerAttributes[lyr.id].legend && layerAttributes[lyr.id].legend.transparency
                             ? (layerAttributes[lyr.id].legend.transparency / 100) : 1,
                        zIndex: 1
                    };
                    const newVectorLayer = new VectorLayer(options);

                    if(layerAttributes[lyr.id].legend?.renderer?.symbol?.marker && layerAttributes[lyr.id].legend.renderer.symbol.marker.style === 'arrow') {
                        const styleFunction = this.arrowStyleFunction(attributes, layerAttributes[lyr.id].legend);
                        newVectorLayer.setStyle(styleFunction);
                    } else if (service.serviceType === ServiceTypes.FEATURE && layerInfoJson && !layerInfoJson.error) {
                        const styleFunction = await createStyleFunction(layerInfoJson);
                        newVectorLayer.setStyle(styleFunction);
                    } else if (service.serviceType === ServiceTypes.MAPS) {
                        const style = new Style({
                            fill: nofill,
                            stroke: nostroke,
                            image: noimage,
                        });
                        newVectorLayer.setStyle(style);
                    }

                    let legendUrl: SafeResourceUrl = null;
                    let legendColor: string = null;
                    let legendOutlineColor: string = null;
                    if (Array.isArray(layerAttributes[lyr.id].legend)) {
                        layerAttributes[lyr.id].legend.forEach((legendEntry) => {
                            legendUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
                                'data:image/png;base64, ' + legendEntry.imageData,
                            );
                            layerSettingsToUse.legendInfo.push(
                                this.createLegendInfo(legendEntry.label, legendUrl, legendEntry.symbol, null, null)
                            );
                        });

                    } else if (layerAttributes[lyr.id].legend.renderer.symbol) {
                        const legendEntry = layerAttributes[lyr.id].legend;
                        const symbol = layerAttributes[lyr.id].legend.renderer.symbol;
                        if (symbol && symbol.imageData) {
                            legendUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
                                'data:image/png;base64, ' + symbol.imageData,
                            );
                        } else if (symbol && symbol.color) {
                            legendColor = 'rgba(' + symbol.color.toString() + ')';
                            if(symbol.outline) {
                                legendOutlineColor = 'rgba(' + symbol.outline.color.toString() + ')';
                            }
                        }

                        let height = '100%';
                        if (symbol && symbol.height) {
                            height = symbol.height + 'px';
                        }
                        layerSettingsToUse.legendInfo.push(
                            this.createLegendInfo(legendEntry.label, legendUrl, symbol, legendColor, height, legendOutlineColor)
                        );

                    } else if (layerAttributes[lyr.id].legend.renderer.uniqueValueInfos) {
                        const symbols = layerAttributes[lyr.id].legend.renderer.uniqueValueInfos;
                        symbols.forEach((syms) => {
                            const sym = syms.symbol;
                            let color = null;
                            let outlineColor = null;
                            let legendUrl = null;
                            if (sym.imageData) {
                                legendUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
                                    'data:image/png;base64, ' + sym.imageData,
                                );
                            } else if (sym.color) {
                                color = 'rgba(' + sym.color.toString() + ')';
                                if(sym.outline) {
                                    outlineColor = 'rgba(' + sym.outline.color.toString() + ')';
                                }
                            }
                            layerSettingsToUse.legendInfo.push(
                                this.createLegendInfo(syms.label, legendUrl, sym, color, null, outlineColor)
                            );
                        });
                    }



                    this.setExtentOnLayer(newVectorLayer, sourceUrl);
                    attributes.olMap.addLayer(newVectorLayer);
                    attributes.prismLayerList.push(layerSettingsToUse);
                }
            }

            if (service.serviceType === ServiceTypes.MAPS) {
                const filteredLayersList = Array.from(attributes.checkedLayersList).filter(function (el) {
                    return el != null;
                });

                const esriTileSourceParams = {
                    projection: OLMAPPROJECTION,
                    LAYERS: 'show:' + filteredLayersList.toString(),
                    token: this.arcGISToken,
                };

                attributes.esriTileSource = new TileArcGISRest({
                    url: service.url,
                    params: esriTileSourceParams,
                    crossOrigin: 'anonymous',
                });

                const esriTLOptions = {
                    source: attributes.esriTileSource,
                    name: 'esriTileLayer',
                    visible: filteredLayersList.length > 0,
                };
                attributes.esriTileLayer = new TileLayer(esriTLOptions);

                attributes.olMap.addLayer(attributes.esriTileLayer);
            }
        }
    }

    private arrowStyleFunction(attributes, legend) {
        const symbol = legend.renderer.symbol;
        const color = 'rgba(' + symbol.color.toString() + ')';
        return function (feature) {
            let MAP_MAX_SCALE_PLUS_ONE = 20;
            const mapScale = attributes.olMap.getView().getZoom();

            if (mapScale + 1 > MAP_MAX_SCALE_PLUS_ONE) MAP_MAX_SCALE_PLUS_ONE = mapScale + 1;

            let scale = (MAP_MAX_SCALE_PLUS_ONE - mapScale);
            if (scale > 3) scale = scale * ((MAP_MAX_SCALE_PLUS_ONE - mapScale) / 3);

            const strokeArrowWidth = 3;
            const strokeWidth = 2;

            const arrowWings = 2 * scale;
            const arrowMultiplier = 1 + 2 * (scale / MAP_MAX_SCALE_PLUS_ONE);
            const arrowDivider = 2 - (scale / MAP_MAX_SCALE_PLUS_ONE);

            const geometry = feature.getGeometry();
            const styles = [
                new Style({
                    stroke: new Stroke({
                        color: color,
                        width: strokeWidth
                    })
                })
            ];

            const coords = geometry.getCoordinates();

            if(coords.length < 2) return styles;

            const start = coords[coords.length - 2];
            const end = coords[coords.length - 1];

            // We don't want to do math root to compute distance because of performance, use something simiular
            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const rotation = Math.atan2(dy, dx);

            let d = Math.abs(dx) + Math.abs(dy);
            if(d > 15) d = d / 10; else d = 2;

            const sx = end[0] - dx / d;
            const sy = end[1] - dy / d;

            const p = [sx, sy];

            const lineStr1 = new LineString([p, [p[0] - arrowWings * arrowMultiplier, p[1] + arrowWings / arrowDivider]]);
            lineStr1.rotate(rotation, p);
            const lineStr2 = new LineString([p, [p[0] - arrowWings * arrowMultiplier, p[1] - arrowWings / arrowDivider]]);
            lineStr2.rotate(rotation, p);

            const stroke = new Stroke({
                color: color,
                width: strokeArrowWidth
            });

            styles.push(new Style({
                geometry: lineStr1,
                stroke: stroke
            }));
            styles.push(new Style({
                geometry: lineStr2,
                stroke: stroke
            }));

            return styles;
        };
    }

    private setLayerToFeaturesRelation(layer: { name: string }, features: Feature[]) {
        const featuresMap = features.reduce((acc, curr) => {
            const name = this.getFeatureNameProp(curr);
            if (!name) return acc;

            acc.set(name, curr);

            return acc;
        }, new Map<string, Feature>());

        this.layerToFeaturesRelation.set(layer.name, featuresMap);
    }

    public getFeatureNameProp(feat: Feature) {
        const featProperties = feat.getProperties();
        const featureKeys = Object.keys(featProperties);
        const nameKeysHierarchy = ['name', 'assetid', 'facilityid', 'gis_id', 'unitid'];

        let nameKey: string;
        for(let i = 0; i<nameKeysHierarchy.length; i++) {
            const current = featureKeys.find(v => v.toLowerCase() === nameKeysHierarchy[i]);

            if (current) {
                nameKey = current;
                break;
            }
        }
        if (!nameKey) {
            nameKey = featureKeys[0] === 'geometry' ? featureKeys[1] : featureKeys[0];
        }

        return featProperties[nameKey];
    }

    async setExtentOnLayer(layer, sourceUrl) {
        let url = sourceUrl + '/query?where=1=1&returnCountOnly=true&returnExtentOnly=true&f=json&outSR=102100';
        if (this.arcGISToken) {
            url += '&token=' + this.arcGISToken;
        }

        const extentData = await this.getFeatureLayerData(url).toPromise();
        const extentJson = <any>extentData;
        if (extentJson.extent) {
            const extent = [
                extentJson.extent.xmin,
                extentJson.extent.ymin,
                extentJson.extent.xmax,
                extentJson.extent.ymax,
            ];
            layer.esriExtent = extent;
        }
    }
}
