import { DataEditPreview, Approval, DataEditPreviewParams, DataEditReport, RevertDataEdit, EntityInfoParams,
    UpdatePoints, DataEditOriginalValue, StoredEdit, DataEditSessions, SnapDataResponse, EntitiesUpdate,
    DataEditStoreActionTypes, UpdatePointForSG, DataRevertRequest, ScatterLastGainEditModel } from '../models/data-edit';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from './config';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { DataEditingAuditReportArgs } from 'app/pages/report/data-editing-audit-report/data-editing-audit-report.model';
import { BehaviorSubject, combineLatest, Observable, Subject, of } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
import { BasicSeriesData, HGGraphData, DataType } from '../models/hydrographNEW';
import { GainPoints, GainTableResponse, MonitorDetails } from '../models/gain-data';
import {  map, tap } from 'rxjs/operators';
import { Action, SeparateWindowActionTypes, EntityInfo, AnnotationSettings } from '../models/view-data';
import { DataEditType, DEPTH_ENTITY, QUANTITY_ENTITY, RAW_VELOCITY_ENTITY, VELOCITY_ENTITY } from '../constant';
import { EntityData } from '../models/scatter-data';
import { DateutilService } from './dateutil.service';
import { MathUtils } from '../utils/math-utils';
import { NonNullableFormBuilder } from '@angular/forms';

// used as timestamp for scattergraph ignore curve values
const UNIQUE_SESSION_TIMESTAMP = 'UNIQUE_TIMESTAMP';

@Injectable()
export class DataEditService {
    public static guid: string;
    public showEditMenu = false;
    public newHydroData$ = new BehaviorSubject<BasicSeriesData[]>(undefined);

    /** Only data that was being updated stream here */
    public updatedData$ = new BehaviorSubject<BasicSeriesData[]>(undefined);
    public otherLocationsData$ = new BehaviorSubject<BasicSeriesData[]>(null);
    public hydrographDataSelector: Observable<BasicSeriesData[]>;
    public sgCurveData$ = new BehaviorSubject<EntityData[]>(undefined);
    /** #37506 SG points ignored for curve */
    public sgIgnoredPointsData$ = new BehaviorSubject<{ inverted: boolean; points: EntityData[]}>({ inverted: false, points: [] });
    public sgUpdateCurveAndDistances$ = new BehaviorSubject<{ res: DataEditPreview, storeEdit: boolean}>(undefined);
    public sgApplyGainEdits$ = new BehaviorSubject<ScatterLastGainEditModel>(null);

    public storedEdits: StoredEdit[] = [];
    public currentEditTimestamp: string = null;
    public onEditsChanged = new Subject();
    public editRequestLoading = new BehaviorSubject(false);

    public siltLevelEdited = new Subject();

    public undoScattergraphEdits = new Subject<StoredEdit>();
    public redoScattergraphEdits = new Subject<StoredEdit>();
    public updateSgEditedPoints = new Subject<{ edits: UpdatePointForSG[]; toUnflag: { ts: number; entId: number }[] }>();
    public interpolateResponse = new Subject<DataEditPreview>();
    public updateEntityGroupingsSubject$ = new BehaviorSubject<EntitiesUpdate[]>(undefined);
    public entitiesData$ = new BehaviorSubject<BasicSeriesData[]>(undefined);

    public notifyOtherWindow = new Subject<Action>();

    /** Parse API line regex. Can parse scientific notation #21135.  */
    public apiRegEx =
        /(?<entityIndex>\d+):(?:(?<value>[-?\d.E[\+\-]+)|{(?<valueString>[^}]+)})(?::(?:{(?<optionsString>[^}]+)}|(?<flagged>[^;\]]+)))?/g;

    public lastCopyCache: HGGraphData[] = null;
    private lastInterpolateType: 0 | 1 = null;

    constructor(public http: HttpClient, private dateutilService: DateutilService,) {
        const newHgData = this.newHydroData$.pipe(map((v) => (!!v ? v : [])));
        const otherData = this.otherLocationsData$.pipe(map((v) => (!!v ? v : [])));
        this.hydrographDataSelector = combineLatest([newHgData, otherData]).pipe(
            map(([hgData, otherData]: [BasicSeriesData[], BasicSeriesData[]]) => ([...hgData, ...otherData]))
        );
    }

    //#region "Data Edit Hackathon Changes Start."

    public gainTablePreview(customerId: number, locationId: number,  params: DataEditPreviewParams, isRawVelSelected: boolean, forceUpdateDateRanges: string[][]) {
        params.sessionGuid = DataEditService.getUUID();
        const url = Config.getUrl(Config.urls.DataEdit.preview) + `?cid=${customerId}&lid=${locationId}`;

        let rawVelCall : Observable<EntityInfo[]> = of(null);
        if(isRawVelSelected && !this.newHydroData$.getValue()?.find(v => v.entityId === RAW_VELOCITY_ENTITY)) {

            const entityParams: EntityInfoParams = {
                eids : [RAW_VELOCITY_ENTITY]
            };
            rawVelCall = this.getEntityInfo(entityParams);
        }

        const previewCall = this.http.post<DataEditPreview>(url, params);

        return combineLatest([previewCall, rawVelCall]).pipe(
            map(([ previewRes, rawVelDetails ]: [DataEditPreview, EntityInfo[]]) => {
                this.handleGainPreviewResponse(previewRes, rawVelDetails, forceUpdateDateRanges);
                return previewRes;
            })
        )
    }

    public apiCurveForUICurve(annotationSettings: AnnotationSettings): number | undefined {
        if(!annotationSettings) return undefined;

        if (annotationSettings.isBestFit) {
            return 0;
        } else if (annotationSettings.isSSCurve) {
            return 1;
        } else if (annotationSettings.isCWRcurve) {
            return 4;
        } else if (annotationSettings.isManningDesign) {
            return 5;
        } else if (annotationSettings.isLanfearColl) {
            return 6;
        } else return undefined;
    }

    public dataEditPreview(customerId: number, locationId: number, params: DataEditPreviewParams, shouldStoreEdit = false) {
        this.editRequestLoading.next(true);
        if (this.currentEditTimestamp === null && this.storedEdits.length > 0) {
            DataEditService.clearUUID();
            this.storedEdits = [];
        }
        params.sessionGuid = DataEditService.getUUID();

        const originalPoints = this.getOriginalValuesOnEdit(params.updatePoints);
        const originalDistances = params['originalDistances'];
        delete params['originalDistances'];

        const url = Config.getUrl(Config.urls.DataEdit.preview) + `?cid=${customerId}&lid=${locationId}`;

        return this.http.post<DataEditPreview>(url, params).pipe(
            tap((res: DataEditPreview) => {
                if (params.dataEditType === DataEditType.DataInterpolate) {
                    this.lastInterpolateType = params.isSelectedPoints ? 1 : 0;
                }
                if (shouldStoreEdit && originalPoints.length) {
                    this.cacheEdit(originalPoints, res, false, false);
                    const lastEdit = this.storedEdits[this.storedEdits.length - 1];
                    lastEdit.originalDistances = originalDistances;
                }
            }),
            map((data: DataEditPreview) => {
                let lastCopyTimestamps = undefined;

                if (shouldStoreEdit && originalPoints.length) {
                    const lastEdit = this.storedEdits[this.storedEdits.length - 1];
                    lastCopyTimestamps = lastEdit.lastCopyTimestamps;
                }

                this.editRequestLoading.next(false);
                this.handleAPIpreviewResponse(data, originalPoints.length ? shouldStoreEdit : false, lastCopyTimestamps);

                this.siltLevelEdited.next(null);
                return data;
            })
        );
    }

    public clearSgIgnoredPoints() {
        this.sgIgnoredPointsData$.next({ inverted: false, points: [] });
    }

    public cacheEdit(originalValues: DataEditOriginalValue[], response: DataEditPreview, uniqueTimestamp = false, notifyOtherWindows = true) {
        if (!originalValues.length) {
            return;
        }

        if (uniqueTimestamp) {
            response.sessionTS = UNIQUE_SESSION_TIMESTAMP + (Math.random() + 1).toString(36).substring(7);
        }

        const assocArr = [];
        originalValues.forEach((p) => {
            if(p && p.eid !== undefined) {
                if(!assocArr[p.eid]) {
                    assocArr[p.eid] = [];
                }

                assocArr[p.eid].push(p);
            }
        });

        const series = this.newHydroData$.value;
        series.forEach((serie) => {
            const ep = assocArr[serie.entityId];
            ep && serie.data && serie.data.forEach((p, i) => {
                const sp = ep.find((d) => d.x === p.x);
                if(sp) {
                    sp.index = i;
                }
            })
        });

        const actionEditsRes = this.getActionAndEditsFromOriginalValues(originalValues);
        let action = actionEditsRes.action || DataEditStoreActionTypes.HGedit;
        const editsMap = actionEditsRes.editsMap;

        if (this.lastInterpolateType === null && editsMap === null && !this.lastCopyCache) {
            return;
        } else if (this.lastInterpolateType === 1) {
            response.isSelectPoints = Boolean(this.lastInterpolateType);
            action = DataEditStoreActionTypes.HGedit;
            this.lastInterpolateType = null;
        } else if (this.lastInterpolateType === 0 && editsMap === null) {
            response.isSelectPoints = Boolean(this.lastInterpolateType);
            this.lastInterpolateType = null;
        }

        // if user makes new change, all undone changes should be removed
        if (this.storedEdits.length > 0 && this.storedEdits[this.storedEdits.length - 1].ts !== this.currentEditTimestamp) {
            const lastIndex = this.storedEdits.findIndex(v => v.ts === this.currentEditTimestamp);
            this.storedEdits = this.storedEdits.slice(0, lastIndex + 1);
        }
        const lastIndex = this.storedEdits.push({ ts: response.sessionTS, response, originalValues: editsMap, action }) - 1;

        if (this.lastCopyCache && this.lastCopyCache.length) {
            this.storedEdits[lastIndex].lastCopyTimestamps = this.lastCopyCache.reduce((acc, curr) => acc.set(curr.x, curr), new Map<number, HGGraphData>());
            this.lastCopyCache = null;
        }

        this.currentEditTimestamp = response.sessionTS;

        if (notifyOtherWindows) {
            this.notifyOtherWindow.next({ type: SeparateWindowActionTypes.updateStoredEdits, payload: { edits: this.storedEdits, currentTS: this.currentEditTimestamp } });
        }
        this.onEditsChanged.next(null);

        this.lastInterpolateType = null;
    }

    public getAllUndoneEdits(currentTs: string) {
        if (!this.storedEdits.length || !this.currentEditTimestamp) {
            return null;
        }

        const startingIndex = this.storedEdits.findIndex(v => v.ts === currentTs);
        return this.storedEdits.slice(startingIndex);
    }

    public getAllAppliedEdits() {
        if (!this.storedEdits.length || !this.currentEditTimestamp) {
            return null;
        }

        const currentEditIndex = this.storedEdits.findIndex(v => v.ts === this.currentEditTimestamp);

        return this.storedEdits.slice(0, currentEditIndex + 1).map(v => v.parsedResponse).reduce((acc, curr) => {
            if(curr) {
                Array.from(curr.keys()).forEach(v => {
                    if (!acc.get(v)) {
                        acc.set(v, curr.get(v));
                    }

                    acc.set(v, new Map([...acc.get(v), ...curr.get(v)]));
                });
            }

            return acc;
        }, new Map<number, Map<number, DataEditOriginalValue>>());
    }

    public getActionAndEditsFromOriginalValues(originalValues: DataEditOriginalValue[]): { action: DataEditStoreActionTypes, editsMap: Map<number, Map<number, DataEditOriginalValue>> } {
        const firstItem = originalValues[0];    // we use fist value because all values are same in action

        // we need to double check because interpolate edit might have undefined for original
        if (!firstItem) {
            return { action: DataEditStoreActionTypes.HGedit, editsMap: null };
        }

        const action: DataEditStoreActionTypes = originalValues[0].action;
        const editsMap = originalValues.reduce((acc, curr) => {
            if (!curr) return acc;

            let currentEntity = acc.get(curr.eid);
            if (!currentEntity) {
                acc.set(curr.eid, new Map());
                currentEntity = acc.get(curr.eid);
            }

            currentEntity.set(curr.dateTime, { ...curr });

            return acc;
        }, new Map<number, Map<number, DataEditOriginalValue>>());

        return { action, editsMap };
    }

    private getOriginalValuesOnEdit(points: UpdatePoints[]): DataEditOriginalValue[] {
        if (!points) return [];

        return points.map(v => {
            if(v.originalValue) return v.originalValue;

            if(v.originalValue === undefined) {
                return {...v, x: v.dateTime, y: null}
            }

            return undefined;
        });
    }

    public redoChanges(cid: number, notifyOtherWindows = true) {
        this.editRequestLoading.next(true);

        if (notifyOtherWindows) {
            this.notifyOtherWindow.next({ type: SeparateWindowActionTypes.redoEdit, payload: cid });
        }

        this.currentEditTimestamp = this.findEditTimestamp(true);
        const currentEdit = this.storedEdits.find(v => v.ts === this.currentEditTimestamp);

        this.redoScattergraphEdits.next(currentEdit);

        if (currentEdit.action === DataEditStoreActionTypes.Snap) {
            this.updateHydrographOnScattergraphSnap(currentEdit.response, false, false);
        } else if (currentEdit.action !== DataEditStoreActionTypes.Ignore && currentEdit.action !== DataEditStoreActionTypes.Unignore) {
            this.handleAPIpreviewResponse(currentEdit.response, false, currentEdit.lastCopyTimestamps);
        }
        this.onEditsChanged.next(null);
        this.updateEditingSession(cid);

    }

    public undoChanges(cid: number, notifyOtherWindows = true) {
        this.editRequestLoading.next(true);

        if (notifyOtherWindows) {
            this.notifyOtherWindow.next({ type: SeparateWindowActionTypes.undoEdit, payload: cid });
        }

        const currentEdit = this.storedEdits.find(v => v.ts === this.currentEditTimestamp);

        this.undoHydrographChanges(currentEdit);
        this.undoScattergraphEdits.next(currentEdit);

        this.currentEditTimestamp = this.findEditTimestamp(false);
        this.onEditsChanged.next(null);
        this.updateEditingSession(cid);
    }

    private undoHydrographChanges(storedEdit: StoredEdit) {
        // #34611 need to have a deep copy of data, because we mutate it below and it cause Long table to not recognize the changes
        const hydrographData = JSON.parse(JSON.stringify(this.newHydroData$.getValue()));

        const updatedSeries = [];

        if (!hydrographData || !hydrographData.length) return;

        if (storedEdit.action === DataEditStoreActionTypes.Snap) {
            hydrographData.forEach((series: BasicSeriesData) => {
                const editedSeries = storedEdit.originalValues.get(series.entityId);

                if (!editedSeries || !series.data) return;

                const us: BasicSeriesData = {...series};
                us.data = [];
                series.data = series.data.map((point: HGGraphData) => {
                    const editedPoint = editedSeries.get(point.x);
                    if (!editedPoint) return point;

                    const p = {...point};
                    p.y = editedPoint.y;
                    p.correctedY = editedPoint.correctedY;
                    p.snapped = false;
                    p.interpolated = false;
                    p.interpolationAccepted = false;
                    p.index = editedPoint.index;

                    us.data.push(p);

                    return p;
                });

                updatedSeries.push(us);
            });
        } else if (storedEdit.action !== DataEditStoreActionTypes.Ignore && storedEdit.action !== DataEditStoreActionTypes.Unignore) {
            // it may be null on interpolation, so we need to remove response points

            if (storedEdit.originalValues === null) {
                const { response } = storedEdit;
                const timestamps: Map<number, boolean> = Object.keys((response as DataEditPreview).d)
                    .reduce((acc, curr) => acc.set(Number(curr) * 1000, true), new Map<number, boolean>());

                hydrographData.forEach((series: BasicSeriesData) => {
                    const us = {...series};
                    us.data = [];
                    if (!series.data || series.data.length === 0) return;

                    series.data.forEach((point: HGGraphData, i) => {
                        if(timestamps.get(point.x)) {
                            us.data.push({...point, y: null, index: i});
                        }
                    });

                    if(us.data.length) {
                        updatedSeries.push(us);
                    }
                    series.data = series.data.filter((v: HGGraphData) => !timestamps.get(v.x));

                });

            } else {
                hydrographData.forEach((series: BasicSeriesData) => {
                    const editedSeries = storedEdit.originalValues.get(series.entityId);

                    if (!editedSeries || !series.data) return;

                    const us: BasicSeriesData = {...series};
                    us.data = [];

                    series.data = series.data.map((point: HGGraphData) => {
                        const editedPoint = editedSeries.get(point.x);
                        if (!editedPoint) return point;

                        const p = {...point};
                        p.correctedY = editedPoint.correctedY;
                        p.y = editedPoint.y;
                        if(p.correctedY === undefined || p.correctedY === null) p.edited = false;
                        p.flagged = editedPoint.flagged;
                        p.index = editedPoint.index;
                        p.interpolated = false;
                        p.interpolationAccepted = false;
                        us.data.push(p);

                        return p;
                    });

                    series.data = series.data.filter((p: HGGraphData) => p.y !== null);

                    updatedSeries.push(us);
                });


                if (storedEdit.action === DataEditStoreActionTypes.HGedit && storedEdit.lastCopyTimestamps) {
                    updatedSeries.forEach((series: BasicSeriesData) => {
                        const editedSeries = storedEdit.originalValues.get(series.entityId);

                        if (!editedSeries || !series.data) return;

                        series.data.forEach(point => {
                            if (storedEdit.lastCopyTimestamps.get(point.x)) {
                                point.correctedY = null;
                                point.y = null;
                            }
                        });
                    });

                    hydrographData.forEach((series: BasicSeriesData) => {
                        const editedSeries = storedEdit.originalValues.get(series.entityId);

                        if (!editedSeries || !series.data) return;

                        series.data = series.data.filter((v: HGGraphData) => !storedEdit.lastCopyTimestamps.get(v.x));
                    });
                }
            }
        }

        this.updateEditedPointsForSg(hydrographData, []);
        this.updatedData$.next(updatedSeries);
        this.newHydroData$.next([...hydrographData]);
    }

    private updateEditedPointsForSg(series: BasicSeriesData[], toUnflag: { ts: number; entId: number }[]) {
        series = series.filter(v => v.entityId === VELOCITY_ENTITY || v.entityId === DEPTH_ENTITY || v.entityId === RAW_VELOCITY_ENTITY);

        const editedPoints = series.reduce((acc, curr) => {
            return [...acc, ...curr.data.filter(v => v.flagged || v.correctedY !== undefined).map(v => ({ id: curr.entityId, stamp: v.x, flagged: v.flagged, value: v.correctedY }))];
        }, []);

        this.updateSgEditedPoints.next({ edits: editedPoints, toUnflag });
    }

    // finds either next or previous edit timestamp
    private findEditTimestamp(isNext: boolean): string | null {
        if (!this.currentEditTimestamp && isNext) return this.storedEdits[0].ts;

        const currentIndex = this.storedEdits.findIndex(v => v.ts === this.currentEditTimestamp);

        if (currentIndex === 0 && !isNext) {
            return null;
        }

        return this.storedEdits[currentIndex + (isNext ? +1 : -1)].ts;
    }

    private updateEditingSession(cid: number) {
        if (!this.currentEditTimestamp || this.currentEditTimestamp.includes(UNIQUE_SESSION_TIMESTAMP)) {
            this.editRequestLoading.next(false);
            return;
        }

        const sessionGuid = DataEditService.getUUID();
        const url = Config.getUrl(Config.urls.DataEdit.session) + `?cid=${cid}&sessionGuid=${sessionGuid}&item=${this.currentEditTimestamp}`;

        this.http.put(url, {}).subscribe(() => this.editRequestLoading.next(false), () => this.editRequestLoading.next(false));
    }

    public getEditingSessionsList(cid: number) {
        const sessionGuid = DataEditService.getUUID();
        const url = Config.getUrl(Config.urls.DataEdit.session) + `?cid=${cid}&sessionGuid=${sessionGuid}`;

        return this.http.get(url).pipe(
            map((res: { stack: DataEditSessions[] }) => (res.stack.filter(v => !!v.result)))
        );
    }

    public dataEditSubmit(customerId: number, locationId: number, reason: string) {
        DataEditService.getUUID();
        const url = `${Config.urls.DataEdit.submit}?cid=${customerId}&lid=${locationId}&guid=${
            DataEditService.guid
        }&reason=${encodeURI(reason)}`;
        return this.http.post(Config.serviceUrl + url, {});
    }

    public recalculateEntities(
        customerId: number,
        locationId: number,
        entityId: number,
        startDate: string,
        endDate: string,
    ) {
        const url = `${Config.urls.DataEdit.recalculate}?cid=${customerId}&lid=${locationId}&eid=${entityId}&start=${startDate}&end=${endDate}`;
        return this.http.post(Config.serviceUrl + url, {});
    }

    public recalculateRawVelocity(
        customerId: number,
        locationId: number,
        startDate: string,
        endDate: string,
    ): Observable<DataEditPreview> {
        const url = `${Config.urls.DataEdit.forceUpdateRawVelocity}?cid=${customerId}&lid=${locationId}`;
        return this.http.post<DataEditPreview>(Config.serviceUrl + url, {
            graphStart: startDate,
            graphEnd: endDate,
            rawVelocityStart: startDate,
            rawVelocityEnd: endDate
        });
    }

    public dataEditDelete(customerId: number) {
        const url = Config.getUrl(Config.urls.DataEdit.delete) + `?cid=${customerId}&guid=${DataEditService.guid}`;
        return this.http.post(url, {});
    }

    public static clearUUID() {
        DataEditService.guid = undefined;
    }

    public static getUUID() {
        if (!DataEditService.guid) {
            DataEditService.guid = UUID.UUID();
        }
        return DataEditService.guid;
    }

    public dataEditingAuditReport(
        customerId: number,
        reportArgs: DataEditingAuditReportArgs,
    ): Observable<DataEditReport> {
        const reportUrl = Config.getUrl(Config.urls.DataEdit.auditReport) + `?cid=${customerId}`;
        return this.http.post(reportUrl, reportArgs) as Observable<DataEditReport>;
    }
    //#endregion "Data Edit Hackathon Changes End."

    public dataEditRevert(customerId: number, locationId: number, dataRevertRequest: DataRevertRequest) {
        const revertUrl = Config.getUrl(Config.urls.DataEdit.revert) + `?cid=${customerId}&lid=${locationId}`;
        return this.http.post(revertUrl, dataRevertRequest) as Observable<DataEditPreview>;
    }

    public updateGainTable(customerId: number, locationId: number, gains: GainPoints[]) {
        const url = Config.getUrl(Config.urls.Telemetry.gainUpdate) + `?cid=${customerId}&lid=${locationId}`;
        return this.http.post(url, gains);
    }

    // telemtry
    public getAllEntityData(customerId: number, locationId: number, entityId: number) {
        const url =
            Config.getUrl(Config.urls.Telemetry.allEntityData) + `?cid=${customerId}&lid=${locationId}&eid=${entityId}`;
        const result = this.http.get(url);
        return result;
    }

    public getEntityInfo(entityParams: EntityInfoParams): Observable<EntityInfo[]>
    {
        const entityurl = Config.serviceUrl + Config.urls.entityInfo;
        let httpParams: HttpParams = new HttpParams();

        Object.keys(entityParams).forEach(function (key) {
            httpParams = httpParams.append(key, entityParams[key]);
        });

        return this.http.get<EntityInfo[]>(entityurl, { params: httpParams });
    }

    public getGainTable(customerId: number, locationId: number): Observable<GainTableResponse[]> {
        const url = Config.getUrl(Config.urls.Telemetry.gainTableData) + `?cid=${customerId}&lid=${locationId}`;
        return this.http.get<GainTableResponse[]>(url);
    }

    public getMonitorDetails(customerId: number, locationId: number) : Observable<MonitorDetails[]> {
        const url = Config.getUrl(Config.urls.Telemetry.monitorData) + `?cid=${customerId}&lid=${locationId}`;
        return this.http.get<MonitorDetails[]>(url);
    }

    public mostRecentApproval(customerId: number, locationIds: number[]) {
        const mostRecentApprovalUrl = `${Config.getUrl(Config.urls.DataEdit.mostRecentApproval)}?cid=${customerId}`;
        return this.http.post(mostRecentApprovalUrl, locationIds) as Observable<Approval[]>;
    }

    public parseAPIPreviewData(apiResponse: DataEditPreview) {
        let tracker;
        const entityData = new Map<number, Map<number, DataEditOriginalValue>>();
        if (Object.keys(apiResponse).length > 0) {
            Object.keys(apiResponse.d).forEach((timeStamp) => {
                while ((tracker = this.apiRegEx.exec(apiResponse.d[timeStamp])) !== null) {
                    // Here 'entityIndex' becomes the entity ID
                    const entityId = Number(tracker.groups.entityIndex);
                    if (!entityData.get(entityId)) {
                        entityData.set(Number(entityId), new Map());
                    }

                    const editable =
                        (tracker.groups.flagged && +tracker.groups.flagged <= 1) ||
                        (tracker.groups.optionsString && +tracker.gorups.optionsString.split(',')[0] <= 1);

                    const point: DataEditOriginalValue = {
                        x: +timeStamp * 1000,
                        y: +tracker.groups.value,
                        flagged: Boolean(+tracker.groups.flagged),
                        editable: editable,
                        dateTime: +timeStamp * 1000,
                        eid: entityId
                    };

                    entityData.get(entityId).set(point.x, point);
                }
            });
        }
        return entityData;
    }

    private handleGainPreviewResponse(apiResponse: DataEditPreview, entityDetails: EntityInfo[], forceUpdateDateRanges: string[][]) {
        if (!apiResponse) {
            return;
        }

        const hydrographData: BasicSeriesData[] = JSON.parse(JSON.stringify((this.newHydroData$.getValue())));

        if (!hydrographData) {
            return apiResponse;
        }

        // First translate into usable data
        const entityData: Map<number, Map<number, DataEditOriginalValue>> = this.parseAPIPreviewData(apiResponse);
        this.sgApplyGainEdits$.next({ results: this.parseAPIPreviewData(apiResponse), forceUpdateDateRanges});

        hydrographData.forEach((series: BasicSeriesData) => {
            const editedEntity = entityData.get(series.entityId);

            if (!editedEntity) return;

            series.data.forEach((point: HGGraphData) => {
                const editedPoint = editedEntity.get(point.x);

                if (!editedPoint) return;

                if (forceUpdateDateRanges && this.dateutilService.checkIfInsideDateRange(point.x, forceUpdateDateRanges)) {
                    point.correctedY = undefined;
                    point.flagged = false;
                    point.y = editedPoint.y;
                } else {
                    point.y = editedPoint.y;
                    point.correctedY = editedPoint.y;
                }


                editedEntity.delete(point.x);   // remove applied values
            });
        });

        const rawVelEntityDetails = entityDetails ? entityDetails[0]: null;

        let rawVelSeriesData: BasicSeriesData;

        if(rawVelEntityDetails !== null)
        {
            rawVelSeriesData = {
                entityId: rawVelEntityDetails.id,
                displayGroupId: rawVelEntityDetails.displayGroupID,
                entityName: rawVelEntityDetails.name,
                axisName: rawVelEntityDetails.axisLabel,
                unitOfMeasure: rawVelEntityDetails.unit,
                dataType: DataType.Velocity,
                color: rawVelEntityDetails.colorHex,
                precision: rawVelEntityDetails.precision,
                data: [],
                annotations: [],
            }

            this.updateEntityGroupingsSubject$.next([rawVelSeriesData]);
            hydrographData.push(rawVelSeriesData);
        }

        // remaining values are new points for the series
        entityData.forEach((entityValues: Map<number, DataEditOriginalValue>, entityId: number) => {
            const hgSeries = hydrographData.find(v => v.entityId === entityId);
            if(!hgSeries) return;

            const points = Array.from(entityValues.values());

            hgSeries.data.push(...points as HGGraphData[]);

            hgSeries.data.sort((a, b) => a.x - b.x);
        });

        this.entitiesData$.next(hydrographData);
    }

    public handleAPIpreviewResponse(
        apiResponse: DataEditPreview,
        shouldStoreEdit = false,
        lastCopyTimestamps: Map<number, HGGraphData> = null,
        revertRequest = false // #32416 Whenever it is revertRequest, not dataPreview request
    ) {
        if (!apiResponse) {
            return;
        }

        const hydrographData = JSON.parse(JSON.stringify((this.newHydroData$.getValue())));
        const originalValuesMap = new Map<number, Map<number, DataEditOriginalValue>>();

        if (!hydrographData) {
            return apiResponse;
        }

        if (lastCopyTimestamps) {

            const possibleEntities = [DEPTH_ENTITY, VELOCITY_ENTITY, QUANTITY_ENTITY];
            const firstRow = apiResponse.d[Object.keys(apiResponse.d)[0]];
            const affectedEntityId = possibleEntities.find(v => firstRow.includes(v));

            const affectedEntity = hydrographData.find(v => v.entityId === affectedEntityId);
            if (affectedEntity && affectedEntity.data) {
                const toPasteData = Array.from(lastCopyTimestamps.values());

                const insertIndex = affectedEntity.data.length;

                affectedEntity.data.splice(insertIndex, 0, ...toPasteData);
            }
        }

        /* Tracker.groups.flagged is the data flag from API. Values can be:
            Good (0), Bad (1, consider that a flagged point), Manual (2), Anonaly (3), or Missing (4)
        */

        // First translate into usable data
        const entityData: Map<number, Map<number, DataEditOriginalValue>> = this.parseAPIPreviewData(apiResponse);

        this.updateOriginalValuesOnUnflag(entityData, apiResponse.sessionTS);
        const updatedData = [];
        const toUnflag = [];
        // Then replace data on graph as needed
        Array.from(entityData.keys()).forEach((entId: number) => {
            originalValuesMap.set(Number(entId), new Map());

            const affectedEntity = hydrographData.find((y) => y.entityId === entId);
            if (affectedEntity && affectedEntity.data && affectedEntity.data !== null) {
                const ud = [];
                const affectedEntityData = new Map(entityData.get(entId));

                affectedEntity.data = affectedEntity.data.map((pointData, index) => {
                    const editedPoint = affectedEntityData.get(pointData.x);

                    // #37738 if point's Y value is equal to edited Y value, and edited point is unflagged, then just ignore this point
                    // Need to round to 3 decimals, because Quantity points are rounded to 3 decimals
                    if (!revertRequest && editedPoint && MathUtils.compareWithMinimalDecimal(pointData.y, editedPoint.y) && MathUtils.compareWithMinimalDecimal(pointData.y, pointData.correctedY) && !editedPoint.flagged) {
                        pointData.flagged = false;

                        toUnflag.push({...pointData, entId});
                        affectedEntityData.delete(pointData.x);

                        return pointData;
                    }

                    const returnObj = {
                        ...pointData, // Don't need to update x, quality, correctedY
                        editable: !revertRequest,
                        index: index
                    };

                    if (editedPoint) {
                        originalValuesMap.get(Number(entId)).set(pointData.x, {
                            dateTime: pointData.x,
                            x: pointData.x,
                            y: revertRequest ? editedPoint.y : pointData.y,
                            correctedY: revertRequest? undefined : pointData.correctedY,
                            eid: Number(entId),
                            flagged: revertRequest ? editedPoint.flagged : pointData.flagged,
                            index: index
                        });
                        returnObj.y = revertRequest ? editedPoint.y : pointData.y;
                        returnObj.flagged = editedPoint.flagged;
                        returnObj.reverted = revertRequest;
                        // If points is flagged then cannot have correctedY value (UI prioritize correctedY first)
                        if (revertRequest) {
                            returnObj.correctedY = undefined;
                        } else if (editedPoint.correctedY && !MathUtils.compareWithMinimalDecimal(editedPoint.correctedY, returnObj.y)) {
                            returnObj.correctedY = editedPoint.correctedY;
                        } else if (!MathUtils.compareWithMinimalDecimal(editedPoint.y, pointData.y)) {
                            returnObj.correctedY = editedPoint.y;
                        }
                        returnObj.edited = (returnObj.correctedY !== undefined && returnObj.correctedY !== null) && (returnObj.y !== returnObj.correctedY || pointData.edited);

                        ud.push(returnObj);
                        affectedEntityData.delete(pointData.x);
                    }

                    return returnObj;
                });

                if(affectedEntityData.size > 0) {
                    let lastIndex = ud.length ? ud[ud.length - 1].index : affectedEntity.data[affectedEntity.data.length - 1].index;

                    for(const pointData of affectedEntityData.values()) {
                        lastIndex++;
                        ud.push({...pointData,
                            editable: !revertRequest, index: lastIndex,
                            y: pointData.y,
                            correctedY: revertRequest ? undefined : pointData.correctedY ? pointData.correctedY : pointData.y
                        });

                        const insertIndex = affectedEntity.data.findIndex(pp => pp.x > pointData.x);

                        if(insertIndex === -1) {
                            // #38374 if not found then just push to the end
                            affectedEntity.data.push({...pointData, index: insertIndex});
                        } else {
                            affectedEntity.data.splice(insertIndex, 0, {...pointData, index: insertIndex});
                        }


                        originalValuesMap.get(Number(entId)).set(pointData.x, {
                            dateTime: pointData.x,
                            x: pointData.x,
                            y: null,
                            correctedY: revertRequest ? undefined : null,
                            index: lastIndex
                        });
                    }
                }

                if(ud.length) {
                    const e = {...affectedEntity};
                    e.data = ud;
                    updatedData.push(e);
                }

            }
        });

        if (shouldStoreEdit) {
            const affectedEntities = Array.from(originalValuesMap.keys());
            const lastEdit = this.storedEdits[this.storedEdits.length - 1];
            affectedEntities.forEach((entityId: number) => {
                if (!lastEdit.originalValues.get(entityId)) {
                    lastEdit.originalValues.set(entityId, originalValuesMap.get(entityId));
                } else {
                    const lastEditData = lastEdit.originalValues.get(entityId);
                    for(const v of lastEditData.values()) {
                        if(v.y === null) {
                            const entityMap = originalValuesMap.get(entityId);
                            const entityValue = entityMap.get(v.x);

                            if(entityValue) {
                                v.index = entityValue.index;
                            }
                        }
                    }
                }
            });
            lastEdit.parsedResponse = entityData;
        }

        if(!revertRequest) this.updateEditedPointsForSg(hydrographData, toUnflag.map(v => ({ ts: v.x, entId: v.entId})));
        this.sgUpdateCurveAndDistances$.next({ res: apiResponse, storeEdit: shouldStoreEdit});

        if (this.showEditMenu || revertRequest) {
            this.newHydroData$.next([...hydrographData]);
            this.updatedData$.next(updatedData);
        }

        return hydrographData;
    }

    public updateHydrographOnScattergraphSnap(res: SnapDataResponse, storeEdit = true, notifyOtherWindows = true, toCachePoints: { snap: EntityData[], prevSnap: EntityData[] } = null) {
        const originalValues: DataEditOriginalValue[] = [];
        const hydrographData = cloneDeep(this.newHydroData$.value);

        const { snapData } = res;
        const mappedSnapData = new Map<number, {id: number, xValue: number}[]>();

        const affectedEntities = snapData.reduce((acc, curr) => {
            mappedSnapData.set(curr.stamp, curr.entities);
            curr.entities.forEach(v => acc.add(v.id));

            return acc;
        }, new Set());

        const entityData: Map<number, Map<number, DataEditOriginalValue>> = new Map();

        Array.from(mappedSnapData.keys()).forEach((stamp: number) => {
            const values = mappedSnapData.get(stamp);

            values.forEach((v: { id: number, xValue: number }) => {
                const { id, xValue } = v;
                if (!entityData.get(id)) entityData.set(id, new Map());

                entityData.get(id).set(stamp, {
                    eid: id,
                    y: xValue,
                    dateTime: stamp,
                    x: stamp,
                    flagged: null,
                });
            });
        });

        const updatedData = [];
        hydrographData.forEach(ser => {
          if (!affectedEntities.has(ser.entityId) || !ser.data) {
            return;
          }

          const ud = [];
          ser.data.forEach(v => {
              const updatedPoint = mappedSnapData.get(v.x);

              if (!updatedPoint || !updatedPoint.find(i => i.id === ser.entityId)) {
                return;
              }


              if (storeEdit) {
                  originalValues.push({
                    dateTime: v.x, x: v.x, y: v.y,
                    correctedY: v.correctedY, eid: ser.entityId,
                    flagged: v.flagged,
                    action: DataEditStoreActionTypes.Snap
                  });
              }
              v.y = Number(updatedPoint.find(i => i.id === ser.entityId).xValue);
              v.correctedY = v.y;
              v.snapped = true;

              ud.push(v);
          });
          if(ud.length) updatedData.push({...ser, data: ud});
        });


        if (storeEdit) {
            this.cacheEdit(originalValues, res, false, notifyOtherWindows);
            const lastEdit = this.storedEdits[this.storedEdits.length -1];
            lastEdit.parsedResponse = entityData;

            if (toCachePoints) {
                lastEdit.snappedPoints = [...toCachePoints.snap];
                lastEdit.previousSnappedPoints = [...toCachePoints.prevSnap];
            }
            if (notifyOtherWindows) {
                this.notifyOtherWindow.next({ type: SeparateWindowActionTypes.updateStoredEdits, payload: { edits: this.storedEdits, currentTS: this.currentEditTimestamp } });
            }
        }

        this.newHydroData$.next([...hydrographData]);
        this.updateEditedPointsForSg(hydrographData, []);
        this.updatedData$.next(updatedData);
    }

    // #38501 when unflagging depth/vel points that were previously flagged
    // we need to update stored edit's original values to include sub entities (Quantity, or Velocity for Rawvel)
    private updateOriginalValuesOnUnflag(parsedResponse: Map<number, Map<number, DataEditOriginalValue>>, editTs: string) {
        const storedEdit = this.storedEdits.find(v => v.ts === editTs && v.action === DataEditStoreActionTypes.Unflag);

        if (!storedEdit) return;

        const hydrographData: BasicSeriesData[] = JSON.parse(JSON.stringify(this.newHydroData$.getValue()));

        hydrographData.forEach((entity: BasicSeriesData) => {
            const fromResponseEntity = parsedResponse.get(entity.entityId);
            let originalValuesEntity = storedEdit.originalValues.get(entity.entityId);

            if (!fromResponseEntity || !fromResponseEntity.size || !entity.data || !entity.data.length) {
                return;
            }

            if (!originalValuesEntity) {
                originalValuesEntity = new Map();
            }

            entity.data.forEach((point: HGGraphData) => {
                const fromResponsePoint = fromResponseEntity.get(point.x);

                if (!fromResponsePoint) return;

                // check if point was originally flagged and add it to original values only in this case
                if (point.flagged) {
                    originalValuesEntity.set(point.x, {
                        ...point,
                        action: DataEditStoreActionTypes.Unflag,
                        flagged: true
                    });
                }
            });
        });
    }
}
