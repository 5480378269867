import { Component, OnInit, ViewEncapsulation, ViewChild, OnChanges } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    BasinDefinition,
    SliicerCaseStudy,
    Basin,
    Coordinate,
    MonitorDistributedMethod,
    RainfallWeight,
    RainfallMonitor,
} from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { SelectItem } from 'app/shared/models/selected-Item';
import { MathUtils } from 'app/shared/utils/math-utils';
import { filter, debounceTime } from 'rxjs/operators';
import { UpdateInfo } from '../../shared/components/updates-widget/updates-widget.models';
import * as _ from 'underscore';
import { UpdatesWidgetService } from '../../shared/components/updates-widget/updates-widget.service';
import { Subscription } from 'rxjs';
import { Debounce } from '../../shared/directives/debounce/debounce.directive';
import { DataHelperService } from '../../shared/services/dataHelper/data-helper.service';
import { BasinNetwork, BasinRainfall, RainfallDistributionPreview } from 'app/shared/models/sliicer/basins';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
import { TrackBy } from 'app/shared/utils/track-by';
import { environment } from 'app/environments/environment';
import { FlexmonsterPivot } from 'ngx-flexmonster';
import { TOOLBAR_EXPORT_TAB_ID, TOOLBAR_FULLSCREEN_TAB_ID } from '../study-results/flexmonster.constant';
import Flexmonster, { Toolbar } from 'flexmonster';

const METRIC_UNITS = ['ha', 'm', 'mm-km'];
const OTHER_UNITS = ['ac', 'ft', ' in-mi'];
const UPDATES_MODEL = 'studyBasinDefinition';
const CSV_FILE_TYPE = 'text/csv';

const FOOTPRINT_KEY_NAME = 'footprint';
const LATITUDE_KEY_NAME = 'latitude';
const LONGITUDE_KEY_NAME = 'longitude';

const BASIN_NAME_STRING = 'basinNames';
const PLUS_CODE = 43;
const e_CODE = 101;
const E_CODE = 69;


@Component({
    selector: 'app-basin-definition',
    styleUrls: ['./basin-definition.component.scss'],
    templateUrl: './basin-definition.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class BasinDefinitionComponent implements OnInit {
    @ViewChild(MatSort, { read: false }) public sort: MatSort;
    @ViewChild('geometrytable', { read: MatTable }) public geometryTable: MatTable<Basin[]>;

    public Math = Math;
    public MINUS_CODE = 189;
    private customerId: number;
    private caseStudyId: string;
    public numericWith3DecimalPlaces: string;

    public isLoadingState = false;
    public allowEditing: boolean;
    public updatedText: string;
    public updateFailureText: string;
    public importValidFileText: string;
    public basinText: string;
    public invalidText: string;
    public invalidBasinText: Array<string>;
    public isMetric: boolean;
    public decimal: string;
    public geometryPrecision: number;
    public isStudyLocked: boolean;
    public debounceData: Debounce = { value: 200, initial: true };

    //
    // properties tab variables
    //
    public geometryDataSource: MatTableDataSource<Basin>;
    public areaUnit: string;
    public lengthUnit: string;
    public footprintUnit: string;

    //
    // rainfall distribution variables
    //
    public rainfallDistributionColumns: Array<string>;
    public geometryColumns = [
        'Basinname',
        'Area(ac)',
        'SewerLength(ft)',
        'Footprint(in-ml)',
        'Centroid Latitude',
        'Centroid Longitude',
    ];
    public MONITOR_DISTRIBUTION_METHODS_ENUM = MonitorDistributedMethod;
    public monitorDistributionMethodValue: MonitorDistributedMethod;
    public originalDistributionMethodValue: MonitorDistributedMethod;
    public rainfallDataSource: MatTableDataSource<BasinRainfall>;
    public rainfallData: BasinRainfall[] = [];
    public methodValues: Array<SelectItem> = [];

    //
    // network tab variables
    //
    public basinDataSource: MatTableDataSource<BasinNetwork>;
    public filteredUpstreamBasins: Array<string> = [];
    public basinColumns = ['changedName', 'outletFlow', 'upStream'];
    public selectedUpstreamBasins: Array<string>;
    public basinNetworkTableData: BasinNetwork[] = [];
    public basinGeometryTableData: Basin[] = [];
    public selectedBasin = '';
    public selectedBasinRow: BasinNetwork;
    public disableSave = false;

    public showNoCoordsOnRGsError = false;
    public noCoordsOnRGsErrorText: string = null;
    public rainfallMonitors: Array<RainfallMonitor>;

	public license = environment.flexmonsterLicense;
	public report: Flexmonster.Report;
	@ViewChild('pivot') private pivotTable: FlexmonsterPivot;

    private originalbasinDataSource: BasinNetwork[];
    private originalGeometryDataSource: Basin[];
    private originalRainfallData: BasinRainfall[];
    private subscriptions = new Array<Subscription>();

    private initialExportsTab: Flexmonster.ToolbarTab[];

    exportErrMsg: string;
    dismissText: string;

    public trackByIndex = TrackBy.byIndex;
    constructor(
        private translate: TranslateService,
        private sliicerService: SliicerService,
        private dateUtilService: DateutilService,
        public updatesWidgetService: UpdatesWidgetService,
        private dataHelperService: DataHelperService,
        private snackBarNotificationService: SnackBarNotificationService,
    ) {
        this.dismissText = this.translate.instant('COMMON.DISMISS_TEXT');
        this.exportErrMsg = this.translate.instant('VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG');
        this.importValidFileText = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.SLICER_IMPORT_BASIN_FILE_TYPE');
    }

    public ngOnInit() {
        const unitsSubs = this.dateUtilService.isCustomerUnitsMetric.subscribe((isMetric: boolean) => {
            this.isMetric = isMetric;
            this.areaUnit = this.isMetric ? METRIC_UNITS[0] : OTHER_UNITS[0];
            this.lengthUnit = this.isMetric ? METRIC_UNITS[1] : OTHER_UNITS[1];
            this.footprintUnit = this.isMetric ? METRIC_UNITS[2] : OTHER_UNITS[2];
            this.geometryPrecision = this.isMetric ? 1 : 2;
        });

        this.subscriptions.push(unitsSubs);

        this.decimal = '1.3-3';
        this.numericWith3DecimalPlaces = REGEX_CONFIG.numericWith3DecimalPlaces;

        this.applyTranslations();

        this.disableSave = true;

        // FIXME: Vladimir M. The 'applyChanges' action is getting triggered here on changes to settings
        //        We MUST ONLY trigger the saveBasinData() from this screen.
        this.subscriptions.push(
            this.updatesWidgetService.updatesAction.subscribe((action) => {
                if (this.updatesWidgetService.updatesModel === UPDATES_MODEL) {
                    switch (action) {
                        default:
                            break;
                        case 'undoChanges':
                            this.resetChanges();
                            break;
                        case 'applyChanges':
                            this.saveBasinData();
                            break;
                    }
                }
            }),
        );

        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
            }),
        );

        // set Locked study notifiaction
        this.subscriptions.push(
            this.sliicerService.caseStudyDetails
                .pipe(filter((data) => !!data))
                .subscribe((caseStudy: SliicerCaseStudy) => {
                    this.customerId = caseStudy.customerId;
                    this.caseStudyId = caseStudy.id;
                    if (caseStudy.basinDefinition) {
                        this.bindDataTables(caseStudy);
                        this.basinDataSource.sort = this.sort;
                    }

                    if (caseStudy.config && caseStudy.config.rainfallMonitors) {
                        const noCoordsLocations = caseStudy.config.rainfallMonitors
                            .filter(v => Boolean(v.latitude) === false || Boolean(v.longitude) === false)
                            .map(v => v.name);


                        this.showNoCoordsOnRGsError = caseStudy.config.rainfallMonitors.some(v => Boolean(v.latitude) === false || Boolean(v.longitude) === false);
                        const errorText = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.GEOMETRY.NO_COORDS_ON_RGS_ERROR');
                        this.noCoordsOnRGsErrorText = errorText.replace('$', noCoordsLocations.join(', '))
                    }
                }),
        );

        this.report = {
            dataSource: {
                data: this.rainfallData
            },
            options: {
                configuratorButton: false,
                editing: this.monitorDistributionMethodValue === MonitorDistributedMethod.UserDefined ? true : false,
                grid: {
                    showHeaders: false,
                    showFilter: false,
                    type: "flat",
                    showGrandTotals: "off",
                    dragging: false
                }
            },
        };

        this.pivotTable?.flexmonster?.setReport(this.report);

    }

    private customizeBasinName() {
        this.pivotTable?.flexmonster?.customizeCell(function(cell, data) {
            if (data.label === BASIN_NAME_STRING) {
                cell.text = 'Basin Names';
            }
            if (data.columnIndex === 0) {
                cell.addClass("highlightBasinNames");
            }
        });
    }

    public fmDataChanged(e) {
        // we do not want to let them edit basin names,
        // but there is no way to disable one column's editatable property through flexmonster.
        if (e.data[0].field === BASIN_NAME_STRING) {
            this.pivotTable?.flexmonster?.setReport(this.report);
        }
        else {
            if (Number.isNaN(e.data[0].value)) {
                this.rainfallData[e.data[0].id][e.data[0].field] = this.rainfallData[e.data[0].id][e.data[0].field];
            }
            else if (e.data[0].value < 0) {
                this.rainfallData[e.data[0].id][e.data[0].field] = this.rainfallData[e.data[0].id][e.data[0].field];
            }
            else {
                this.rainfallData[e.data[0].id][e.data[0].field] = e.data[0].value;
            }

            this.report.dataSource.data = this.rainfallData;
            this.pivotTable?.flexmonster?.setReport(this.report);
            this.rainfallChange();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public customizeToolbar(toolbar: Toolbar) {
        this.customizeBasinName();
		toolbar.showShareReportTab = true;

		let tabs = this.getTabs(toolbar);

		toolbar.getTabs = function () {
			return tabs;
		}
	}

    private getTabs(toolbar: Toolbar) {
		const tabs = toolbar.getTabs();

		let exportTab = tabs.find(v => v.id === TOOLBAR_EXPORT_TAB_ID);
        exportTab.menu = exportTab.menu?.filter(x => x.title === 'To CSV');

		if (!this.initialExportsTab) {
			this.initialExportsTab = exportTab.menu;
		}

		const fullScreenTab = tabs.find(v => v.id === TOOLBAR_FULLSCREEN_TAB_ID);

		return [
			fullScreenTab,
            exportTab
		];
	}

    public importBasinProperties() {
        const fileInput = document.getElementById('fileInput');
        $(".file-display").val(null);
        fileInput.click();
    }

    public importBasinRainfallDistribution(){
        const fileInput = document.getElementById('bFileInput');
        $(".file-display").val(null);
        fileInput.click();
    }

    public sortData(event) {
        this.basinDataSource.sortingDataAccessor = (item, property) => {
            if (typeof item[property] === 'string') {
                return item[property].toLocaleLowerCase();
              }

              return item[property];
        }
    }

    public uploadBfile(fileList: FileList){
        const file = fileList[0];
        const tarrR = [];
        const breakError = {};
        const fileReader: FileReader = new FileReader();
        let fileresult: any;
        const that = this;
        fileReader.onload = function(event){
            fileresult = event.target.result;
            let allTextLines = [];
            allTextLines = fileresult.split(/\n/);

            const arrl = allTextLines.length;
            const rows = [];

            let preHeaders = allTextLines[0].split(',');
            for (let i = 0; i < preHeaders.length; i++){
                preHeaders[i] = preHeaders[i].replace('\r', '');
            }

            for(let i = 1; i < arrl; i++){
                const currentRow = allTextLines[i];
                rows.push(currentRow.split(',').map(v => v.replace(/\"/g,"").trim()));
            }

            for (let j = 0; j < arrl; j++) {
                tarrR.push(rows[j]);
            }

            that.rainfallData = [];
            tarrR.forEach(row => {
                const obj = preHeaders.reduce((acc, header, index) => {
                    if (header === "Basin Names") {
                        header = BASIN_NAME_STRING;
                    }
                    if (row) {
                        Number.isNaN(Number(row[index])) ? acc[header] = row[index] : acc[header] = Number(row[index]);
                        return acc;
                    }
                }, {});
                if (obj) {
                    that.rainfallData.push(obj);
                }
            });

            that.report.dataSource.data = that.rainfallData;
            that.pivotTable?.flexmonster?.setReport(that.report);

            that.rainfallChange();
        }
        fileReader.readAsText(file);
    }

    public uploadFile(fileList: FileList) {
        const file = fileList[0];
        if(file && file.type !== CSV_FILE_TYPE)
        {
            this.sliicerService.showToast(this.importValidFileText, true);
            return;
        }
        const tarrR = [];
        const breakError = {};
        const fileReader: FileReader = new FileReader();
        let fileresult: any;
        const that = this;

        const formatImportedValue = (value: string, key: string) => {
            if (!value) return '';

            return Number(value.trim().replace(/(?<=(.*\..*))\./g, '')).toFixed(that.getAllowedDecimals(key));
        }
        fileReader.onload = function(event){
            fileresult = event.target.result;
            const allTextLines = fileresult.split(/\n/);
            const rows = [];

            // need special conversion here because we can get rows like 'GDP-FM01,105,"13,428.00",248.47' where we have comma within one value which is in brackets
            allTextLines.forEach((row: string) => {
                let isComma = true; // false when we are inside brackets which means that value will have comma separator inside it
                let skipNext = false;

                let currentItem = '';
                const currentRow = [];

                for(let i = 0; i < row.length; i++) {
                    const item = row[i];

                    if (skipNext) {
                        skipNext = false;
                        continue;
                    }

                    // normal case, just add current letter
                    if (item !== '"' && item !== ',') {
                        currentItem += item;
                    }

                    // switch to next value
                    if (item === ',' && isComma) {
                        currentRow.push(currentItem);
                        currentItem = '';
                        continue;
                    }

                    // since isComma is true here, then it is a starting bracket, just set is comma to false and skip
                    if (item === '"' && isComma) {
                        isComma = false;
                        continue;
                    }

                    // since isComma is false, it means we are inside a brackets value and this comma is a part of it, just skip it
                    if (item === ',' && !isComma) {
                        continue;
                    }

                    // since isComma is false then it is the ending bracket, we add the value to row and skip next, cause next is comma
                    if (item === '"' && !isComma) {
                        currentRow.push(currentItem);
                        currentItem = '';
                        skipNext = true;
                        isComma = true;
                        continue;
                    }

                    // end of iteration

                    if (i === row.length - 1 && currentItem) {
                        currentRow.push(currentItem);
                    }
                }

                rows.push(currentRow);
            });

            const arrl = allTextLines.length;

            for (let j = 0; j < arrl; j++) {
                tarrR.push(rows[j]);
            }

            tarrR.forEach((importRow) => {
                try {
                that.geometryDataSource.data.forEach((dataRow: Basin) => {
                    const [name, area, length, footprint, latitude, longitude] = importRow;
                    if (name && dataRow.name === name) {

                        dataRow.area = formatImportedValue(area, 'area');
                        dataRow.length = formatImportedValue(length, 'length');
                        dataRow.footprint = formatImportedValue(footprint, 'footprint');
                        dataRow.centroid.latitude = latitude ? latitude.trim() : '';
                        dataRow.centroid.longitude = longitude ? longitude.trim() : '';
                        throw breakError;
                    }
                });
            }
            catch (err) {
              }
            });
        }
        fileReader.readAsText(file);
    }

    private applyTranslations() {
        const distributionMethods = [
            { key: MonitorDistributedMethod.UserDefined, value: 'SLIICER_TABLE.USER_DEFINED_DISTRIBUTION' },
            { key: MonitorDistributedMethod.Closest, value: 'SLIICER_TABLE.CLOSEST_MONITOR' },
            { key: MonitorDistributedMethod.InverseDistance, value: 'SLIICER_TABLE.INVERSE_DISTANCE' },
            { key: MonitorDistributedMethod.InverseDistanceSquared, value: 'SLIICER_TABLE.INVERSE_DISTANCE_SQUARED' },
        ];

        const translationKeys: Array<string> = [
            'SLIICER.BASINS.SAVE_SUCCESS',
            'SLIICER.BASINS.SAVE_FAIL',
            'BASIN_NETWORK.BASIN',
            'BASIN_NETWORK.INVALID_BASIN',
        ];
        distributionMethods.forEach((m) => {
            translationKeys.push(m.value);
        });

        this.translate.get(translationKeys).subscribe((values) => {
            (this.updatedText = values['SLIICER.BASINS.SAVE_SUCCESS']),
                (this.updateFailureText = values['SLIICER.BASINS.SAVE_FAIL']),
                (this.basinText = values['BASIN_NETWORK.BASIN']);
            this.invalidText = values['BASIN_NETWORK.INVALID_BASIN'];

            let index = 0;
            distributionMethods.forEach((m) => {
                const value: SelectItem = {
                    id: index++,
                    name: m.key,
                    text: values[m.value],
                };
                this.methodValues.push(value);
            });
        });
    }

    /**
     * Make sure that the selected rainfall monitor distribution method is valid. If we do
     * not have basin centroids or rainfall monitor lat-long values then the only valid value
     * is UserDefined.
     */
    public checkMonitorDistributionMethod() {
        if (this.monitorDistributionMethodValue === MonitorDistributedMethod.UserDefined) {
            this.prepareRainfallDistributionDataSource();

            this.rainfallChange();
            
            this.report.options.editing = true;
            this.pivotTable?.flexmonster?.setReport(this.report);
            return;
        }

        this.report.options.editing = false;
        this.pivotTable?.flexmonster?.setReport(this.report);

        this.sliicerService.rainfallDistributionMethodPreview(this.customerId, this.caseStudyId, this.monitorDistributionMethodValue)
            .subscribe((previewData: RainfallDistributionPreview) => {
                this.rainfallDistributionColumns = [BASIN_NAME_STRING];
                if (this.rainfallMonitors) {
                    this.rainfallMonitors.forEach((element) => {
                        this.rainfallDistributionColumns.push(element.name);
                    });
                }

                this.rainfallData = [];

                let basinNames = Object.keys(previewData);
                basinNames = basinNames.sort();
                basinNames.forEach((basin: string) => {
                    const rainMonitors = previewData[basin];

                    const basinData = { basinNames: basin };
                    rainMonitors.forEach(v => basinData[v.rainGauge] = v.weight);

                    this.rainfallData.push(basinData as BasinRainfall);
                });

                this.rainfallDataSource = new MatTableDataSource(this.rainfallData);
                this.rainfallChange();
            });
    }

    /**
     * This method will handle the tab change and will check save button disable state
     */
    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        switch (tabChangeEvent.index) {
            default:
                break;
            case 0:
                this.disableSave = this.checkBasinDataSource();
                break;
            case 1:
                this.disableSave = this.checkGeometryDataSource();
                break;
            case 2:
                this.disableSave = this.checkRainfallData();
                this.setDistributionMethodsDisableValue();
                break;
        }
    }

    public numberFormatter(value: number): string {
        return Number(value).toFixed(this.geometryPrecision);
    }

    public onRainfallInputChange(element: BasinRainfall, column: string) {
        if (!element[column]) return;

        const allowedDecimals = this.isMetric ? 1 : 2;
        const decimals = this.countDecimals(element[column]);

        if (decimals > allowedDecimals) {
            element[column] = Number(element[column].toFixed(allowedDecimals));
        }
    }

    public onLonLatInput(event: KeyboardEvent) {
        if (!event || !event.key) {
            return true;
        }

        const keyCode = event.key.charCodeAt(0);

        if (keyCode === PLUS_CODE || keyCode === e_CODE || keyCode === E_CODE) {
            return false;
        }

        return true;
    }

    public onPropertyInputChange(element: Basin, key: string) {
        let value = element[key];

        if (!value) {
            return;
        }

        const decimals = this.countDecimals(element[key]);
        const allowedDecimals = this.getAllowedDecimals(key);

        if (decimals > allowedDecimals) {
            value = Number(value.toFixed(allowedDecimals));
        }

        element[key] = value;
    }

    private getAllowedDecimals(key: string) {
        // #34914 latitude and longiture inputs should have 8 allowed decimal places
        if (key === LATITUDE_KEY_NAME || key === LONGITUDE_KEY_NAME) {
            return 8;
        }
        // #34283 for footprint and metric we allow 1 decimal place, for other cases 2 decimals
        return (key === FOOTPRINT_KEY_NAME && this.isMetric) ? 1 : 2;
    }

    private countDecimals(value: number) {
        if (Math.floor(value) === value) {
            return 0;
        }

        return value.toString().split(".")[1].length || 0;
    }

    public getDistributionMethodError(method: SelectItem) {
        if (!method.disabled) return null;

        const name = method.text;
        const errorText = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.DISTRIBUTION_METHOD_ERROR');

        // $ is used to place the param
        return errorText.replace('$', name);
    }

    /**
     * This method will set the disable value on each distribution method
     */
    private setDistributionMethodsDisableValue(): void {
        const basins = this.basinsFromDataSource();
        this.methodValues.forEach((m) => (m.disabled = this.getDistributionMethodDisableValue(m.name, basins)));
    }

    /**
     * This method will get and return the disable value for a specifed distribution method
     * that is applied for a specific basins array
     * @param methodName
     * @param basins
     */
    private getDistributionMethodDisableValue(methodName: string, basins: Basin[]): boolean {
        if (methodName === MonitorDistributedMethod.UserDefined) {
            return false;
        }

        return basins.some((geom: Basin) => !geom.centroid || !geom.centroid.latitude || !geom.centroid.longitude);
    }

    /**
     * This occurs when the user changes which upstream basins belong to
     * the currently selected basin.
     */
    public selectUpstreamBasins(values) {
        const selectedBasinNames = [];
        this.selectedUpstreamBasins = values;
        this.selectedUpstreamBasins
            .map((element) => {
                return { name: element };
            })
            .forEach((item) => selectedBasinNames.push(item));

        const basins = this.basinsFromDataSource();
        basins.forEach((basin) => {
            if (basin.name === this.selectedBasin) {
                basin.upstream = selectedBasinNames;
            }
        });

        this.prepareUpstreamBasinsDataSource();
        this.basinDataChange();
        this.clearAllErrors();
    }

    private clearAllErrors() {
        this.invalidBasinText = [];
    }

    public clearAll() {
        if (this.selectedUpstreamBasins.length) {
            this.selectUpstreamBasins([]);
        }
    }

    /**
     * Highlight the selected basin row and show network for it
     */
    public selectBasin(row) {
        this.selectedBasinRow = row;
        if (row) {
            this.selectedBasin = row.basin;
        }
        this.applyUpstreams();
        this.clearAllErrors();
    }

    /**
     * Method to update table sorting after a basin name change
     */
    public onNameChange() {
        this.basinDataSource.sort = this.sort;
    }

    /**
     * This method will handle model change on basin network data
     */
    public basinDataChange() {
        this.disableSave = this.checkBasinDataSource();
        this.setUpdatesInfo();
    }

    /**
     * This method will handle model change on geometry data
     */
    public geometryChange() {
        this.disableSave = this.checkGeometryDataSource();
        this.setUpdatesInfo();
    }

    /**
     * This method will handle model change on rainfall data
     */
    public rainfallChange() {
        this.disableSave = this.checkRainfallData();
        this.setUpdatesInfo();
    }

    private parseClipboardIntoBasins(pastedText: string, basins: Basin[]) {
        const rows = pastedText.split(/\r?\n/g);
        rows.forEach((row: string, rowIndex: number) => {
            // split into columns either as TSV or CSV
            let rowElements: string[] = [];
            if (row.match(/\t/)) {
                rowElements = row.split(/\t/);
            } else {
                rowElements = row.split(/,/);
            }
            // pasted data may be longer than expected
            if (basins.length >= rowIndex) {
                return;
            }
            // TODO: WPS - is this sanity check necessary?
            if (!basins[rowIndex]) {
                return;
            }

            // if the basin exists, zero out items for the row
            basins[rowIndex].area = "";
            basins[rowIndex].centroid.latitude = 0;
            basins[rowIndex].centroid.longitude = 0;
            basins[rowIndex].footprint = "";
            basins[rowIndex].length = "";

            // parse columns
            rowElements.forEach((element, columnIndex) => {
                const floatValue = parseFloat(element);
                if (isNaN(floatValue)) {
                    return;
                }
                switch (columnIndex) {
                    case 0:
                        basins[rowIndex].area = this.numberFormatter(floatValue);
                        break;
                    case 1:
                        basins[rowIndex].length = this.numberFormatter(floatValue);
                        break;
                    case 2:
                        basins[rowIndex].footprint = this.numberFormatter(floatValue);
                        break;
                    case 3:
                        if (basins[rowIndex].centroid === null) {
                            basins[rowIndex].centroid = { latitude: 0, longitude: 0 };
                        }
                        basins[rowIndex].centroid.latitude = floatValue;
                        break;
                    case 4:
                        if (basins[rowIndex].centroid === null) {
                            basins[rowIndex].centroid = { latitude: 0, longitude: 0 };
                        }
                        basins[rowIndex].centroid.longitude = floatValue;
                        break;
                    default:
                        break;
                }
            });
        });
    }

    private clearUpstreams() {
        this.selectedUpstreamBasins = [];
        this.filteredUpstreamBasins = [];
    }
    /**
     * Show upstream basins for the selected basin
     */
    private applyUpstreams() {
        this.clearUpstreams();
        const basins = this.basinsFromDataSource();
        if (basins && basins.length > 0) {
            this.filteredUpstreamBasins = basins.filter((b) => b.name !== this.selectedBasin).map((b) => b.name);
            const basin = basins.find((el) => el.name === this.selectedBasin);
            if (basin.upstream) {
                this.selectedUpstreamBasins = basin.upstream.map((b) => b.name);
            }
        }
    }

    private prepareUpstreamBasinsDataSource() {
        const basins = this.basinsFromDataSource();
        if (basins && basins.length > 0) {
            basins.forEach((element, index) => {
                const l = element.upstream ? element.upstream.length : 0;
                if (this.basinNetworkTableData && this.basinNetworkTableData.length > 0) {
                    this.basinNetworkTableData[index].upStream = element.upstream ? element.upstream.length : 0;
                }
            });
        }
        this.basinDataSource = new MatTableDataSource(this.basinNetworkTableData);
    }

    private buildRainfallData(buildColumns = false) {
        const caseStudy = this.sliicerService.caseStudyDetails.getValue();
        const basins = caseStudy.basinDefinition.basins || [];
        basins.sort((a, b) => a.name.localeCompare(b.name));
        let rainfallData = [];
        let setWeightFlag = false;

        if (buildColumns) {
            this.rainfallDistributionColumns = [BASIN_NAME_STRING];
            if (this.rainfallMonitors) {
                this.rainfallMonitors.forEach((element) => {
                    this.rainfallDistributionColumns.push(element.name);
                });
            }
        }

        basins.forEach((element) => {
            const rainData = {} as BasinRainfall;
            if (element.weights) {
                this.rainfallMonitors.forEach((monitor: RainfallMonitor) => {
                    const monitorWeight = element.weights.find(v => v.rainfallMonitorName === monitor.name);

                    rainData.basinNames = element.name;
                    rainData[monitor.name] = monitorWeight ? Number(monitorWeight.weight) : 100;
                });
            } else {
                setWeightFlag = true;
            }
            rainfallData.push(rainData);
        });

        if (setWeightFlag && buildColumns) {
            rainfallData = [];
            basins.forEach((basin) => {
                const monitorValues = {} as BasinRainfall;
                this.rainfallDistributionColumns.forEach((element) => {
                    monitorValues[element] = null;
                    monitorValues.basinNames = basin.name;
                });
                rainfallData.push(monitorValues);
            });
        }

        return rainfallData;
    }

    private prepareRainfallDistributionDataSource() {
        this.rainfallData = this.buildRainfallData(true);
        this.rainfallDataSource = new MatTableDataSource(this.rainfallData);
    }

    // used to prevent empty inputs, we put 0 if input is empty
    public onWeightInputBlur(rowIndex: number, colIndex: number) {
        this.rainfallDataSource.data.forEach(row => Object.keys(row).forEach(key => {
            if (row[key] === null || row[key] === undefined) {
                row[key] = 0;
            }
        }));

        this.rainfallChange();
    }

    /**
     * Method to fetch monitor names
     */
    private prepareNetworkDataSource(basins: Basin[]) {
        this.basinNetworkTableData = [];

        basins.forEach((element) => {
            const basin = {
                basin: element.name,
                outletFlow: element.outputFlowMonitor,
                upStream: element.upstream ? element.upstream.length : 0,
                changedName: element.changedName ? element.changedName : element.name,
            };
            this.basinNetworkTableData.push(basin);
        });

        this.basinDataSource = new MatTableDataSource(this.basinNetworkTableData);

        if (this.basinNetworkTableData && this.basinNetworkTableData.length) {
            this.selectedBasinRow = this.basinNetworkTableData[0];
            this.selectedBasin = this.basinNetworkTableData[0].basin;
            this.prepareUpstreamBasinsDataSource();
            this.applyUpstreams();
        } else {
            this.clearUpstreams();
        }
    }

    private preparePropertiesDataSource(basins: Basin[]) {
        this.basinGeometryTableData = [];
        basins.forEach((element) => {
            element.area = element.area ? Number(element.area).toFixed(this.getAllowedDecimals('area')) : '';
            element.footprint = element.footprint ? Number(element.footprint).toFixed(this.getAllowedDecimals('footprint')) : '';
            element.length = element.length ? Number(element.length).toFixed(this.getAllowedDecimals('length')) : '';

            const centroid: Coordinate = {
                latitude: element.centroid && element.centroid.latitude ? element.centroid.latitude : undefined,
                longitude: element.centroid && element.centroid.longitude ? element.centroid.longitude : undefined,
            };
            // #33007 Pass all original basin parameters, we need them at save
            this.basinGeometryTableData.push({
                ...element,
                centroid: centroid
            });
        });
        this.geometryDataSource = new MatTableDataSource(this.basinGeometryTableData);
    }

    private basinsFromDataSource(): Basin[] {
        return this.geometryDataSource ? this.geometryDataSource.data : [];
    }

    private getBasinDataSource(): BasinNetwork[] {
        return this.basinDataSource ? this.basinDataSource.data : [];
    }

    private getRainfallDataSource(): BasinRainfall[] {
        if (this.monitorDistributionMethodValue === MonitorDistributedMethod.UserDefined) {
            return this.report.dataSource.data ? this.report.dataSource.data as BasinRainfall[] : [];
        }

        return this.originalRainfallData;
    }

    /**
     * This method will reset changes
     */
    private resetChanges(): void {
        this.resetToOriginalData();
        this.setUpdatesInfo();
    }

    /**
     * This method will set original data.
     */
    private setOriginalData(): void {
        this.originalbasinDataSource = JSON.parse(JSON.stringify(this.basinNetworkTableData));
        this.originalGeometryDataSource = JSON.parse(JSON.stringify(this.basinGeometryTableData));
        this.originalRainfallData = JSON.parse(JSON.stringify(this.buildRainfallData()));
    }

    /**
     * This method will reset all changes to the original data
     */
    private resetToOriginalData() {
        this.resetNetworkData();
        this.resetGeometryData();
        this.resetRainfallData();
    }

    /**
     * This method will reset basin network data.
     */
    private resetNetworkData(): void {
        this.basinNetworkTableData = JSON.parse(JSON.stringify(this.originalbasinDataSource));
        this.basinDataSource = new MatTableDataSource(this.basinNetworkTableData);
    }

    /**
     * This method will reset basin geometry data.
     */
    private resetGeometryData(): void {
        this.basinGeometryTableData = JSON.parse(JSON.stringify(this.originalGeometryDataSource));
        this.geometryDataSource = new MatTableDataSource(this.basinGeometryTableData);
        this.applyUpstreams();
    }

    /**
     * This method will reset basin rainfall data.
     */
    private resetRainfallData(): void {
        this.monitorDistributionMethodValue = this.originalDistributionMethodValue;

        // #37840 Need to process those differently, since anything other then UserDefined is just
        if (this.monitorDistributionMethodValue === MonitorDistributedMethod.UserDefined) {
            this.rainfallData = JSON.parse(JSON.stringify(this.originalRainfallData));
            this.report.dataSource.data = this.rainfallData;
            this.pivotTable.flexmonster.setReport(this.report);
        } else {
            this.checkMonitorDistributionMethod();
        }
    }

    /**
     * Prepare data tables
     */
    public bindDataTables(caseStudy: SliicerCaseStudy) {
        this.rainfallMonitors = caseStudy.config.rainfallMonitors || [];
        // TODO: WPS - This actually sorts the data in the case study and thus will be sorted for other
        //       components. Is this what we want? I think it's okay but probably should be done somewhere
        //       else.
        this.rainfallMonitors.sort((a, b) => a.name.localeCompare(b.name));
        this.originalDistributionMethodValue = caseStudy.basinDefinition.monitorDistributionMethod;
        this.monitorDistributionMethodValue = this.originalDistributionMethodValue;
        const basins = caseStudy.basinDefinition.basins || [];
        // TODO: WPS - Ditto.
        basins.sort((a, b) => a.name.localeCompare(b.name));

        if (this.monitorDistributionMethodValue === MonitorDistributedMethod.UserDefined) {
            this.prepareRainfallDistributionDataSource();
        } else {
            this.checkMonitorDistributionMethod();
        }
        this.preparePropertiesDataSource(basins);
        this.prepareNetworkDataSource(basins);
        this.setOriginalData();
    }

    /**
     * Make sure that the rainfall distribution values are applied
     */
    private prepareRainfallDistributionForSaveIntoBasins(basins: Basin[]) {
        let keyValues: Array<string>;
        const weightsData = [];
        const rainData = this.monitorDistributionMethodValue === MonitorDistributedMethod.UserDefined ? this.rainfallData : this.originalRainfallData;

        rainData.forEach((element) => {
            const monitorWeights: Array<RainfallWeight> = [];
            if (element) {
                keyValues = Object.keys(element);
                keyValues.forEach((key) => {
                    if (key !== BASIN_NAME_STRING) {
                        monitorWeights.push({
                            rainfallMonitorName: key,
                            weight: element[key],
                        });
                    }
                });
                const rainMonitor = {
                    name: element.basinNames,
                    weights: monitorWeights,
                };
                weightsData.push(rainMonitor);
            }
            
        });

        basins.forEach((element, index) => {
            if (element.name === weightsData[index]?.name) {
                element.weights = weightsData[index]?.weights;
            }
        });
    }

    public checkBasinDataSource() {
        return _.isEqual(this.getBasinDataSource(), this.originalbasinDataSource);
    }

    public checkGeometryDataSource() {
        return _.isEqual(
            this.dataHelperService.deepStripEmptyKeys(this.basinsFromDataSource()),
            this.dataHelperService.deepStripEmptyKeys(this.originalGeometryDataSource),
        );
    }

    public checkRainfallData() {
        return (
            _.isEqual(this.report.dataSource.data, this.originalRainfallData) &&
            this.monitorDistributionMethodValue === this.originalDistributionMethodValue
        );
    }

    /**
     * User may have changed basin names.
     * We key off of `outputFlowFlowMonitor` here because it is unchanging. The premice is that
     * any given basin has a single point of exit.
     */
    private updateBasinNames(basins: Basin[]) {
        const srcBasins = this.getBasinDataSource();
        srcBasins.forEach((b) => {
            const found = basins.find((existing) => existing.outputFlowMonitor == b.outletFlow);
            if (found) {
                found.name = b.changedName;
            }
        });
    }

    /**
     * Save basin data
     */
    private saveBasinData() {
        this.updatesWidgetService.updatesLoader = true;
        const basins = this.basinsFromDataSource();
        this.prepareRainfallDistributionForSaveIntoBasins(basins);
        this.updateBasinNames(basins);

        const basinDefinition: BasinDefinition = {
            basins: basins,
            monitorDistributionMethod: this.monitorDistributionMethodValue,
        };
        this.isLoadingState = true;
        this.sliicerService.putBasinDefinition(this.customerId, this.caseStudyId, basinDefinition).subscribe(
            (result: Array<string>) => {
                this.invalidBasinText = [];
                if (result && result.length) {
                    // the result is a list of circular basins
                    result.forEach((r) => {
                        this.invalidBasinText.push(`${this.basinText} ${r} ${this.invalidText}`);
                    });
                    this.resetGeometryData();
                    this.prepareUpstreamBasinsDataSource();
                    this.basinDataChange();
                } else {
                    this.sliicerService.showToast(this.updatedText);
                    this.setOriginalData();
                    this.setUpdatesInfo();
                }

                this.originalRainfallData = this.getRainfallDataSource();
                
                this.setUpdatesInfo();
                this.sliicerService.bustQviCache();
                this.isLoadingState = false;
                this.updatesWidgetService.updatesLoader = false;

            },
            (error) => {
                this.updatesWidgetService.updatesLoader = false;
                this.sliicerService.showToast(this.updateFailureText, true);
                this.isLoadingState = false;
            },
        );
    }

    /**
     * This will check all changes and structure udpates info data if any change is detected
     */
    private setUpdatesInfo() {
        const updatesInfo: UpdateInfo[] = [];
        if (!this.checkBasinDataSource()) {
            BasinDefinitionComponent.AddUpdateInfo(
                updatesInfo,
                this.getBasinUpdateInfo(this.getBasinDataSource(), this.originalbasinDataSource),
            );
        }
        if (!this.checkGeometryDataSource()) {
            BasinDefinitionComponent.AddUpdateInfo(
                updatesInfo,
                this.getGeometryUpdateInfo(this.basinsFromDataSource(), this.originalGeometryDataSource),
            );
        }
        if (!this.checkRainfallData()) {
            BasinDefinitionComponent.AddUpdateInfo(
                updatesInfo,
                this.getRainfallUpdateInfo(this.getRainfallDataSource(), this.originalRainfallData),
            );
        }
        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
    }

    /**
     * This will structure and return the basin data update info
     * @param basinData
     * @param originalBasinData
     */
    private getBasinUpdateInfo(basinData: BasinNetwork[], originalBasinData: BasinNetwork[]): UpdateInfo {
        return { title: 'BASIN_NETWORK', values: [] };
    }

    /**
     * This will structure and return the geometry data update info
     * @param geometryData
     * @param originalGeometryData
     */
    private getGeometryUpdateInfo(geometryData: Basin[], originalGeometryData: Basin[]): UpdateInfo {
        return { title: 'BASIN_PROPERTIES', values: [] };
    }

    /**
     * This will structure and return the rainfall data update info
     * @param rainfallData
     * @param originalRainfallData
     */
    private getRainfallUpdateInfo(rainfallData: BasinRainfall[], originalRainfallData: BasinRainfall[]): UpdateInfo {
        return { title: 'RAINFALL_DISTRIBUTION', values: [] };
    }
    /********************************************************************************/
    /* PRIVATE STATIC METHODS                                                       */
    /********************************************************************************/

    /**
     * This will handle the update to the updatesInfo array
     * since the updateInfo can be Object or Array depending on what is updated
     * @param updatesInfo
     * @param updateInfo
     * @constructor
     */
    private static AddUpdateInfo(updatesInfo: UpdateInfo[], updateInfo: UpdateInfo | UpdateInfo[]) {
        if (updateInfo instanceof Array) {
            for (let i = 0; i < updateInfo.length; i++) {
                updatesInfo.push(updateInfo[i]);
            }
        } else {
            updatesInfo.push(updateInfo);
        }
    }

    // tslint:disable-next-line: max-line-length
    public static computeBasinRainfallWeights(
        distributionMethod: MonitorDistributedMethod,
        basins: Basin[],
        rainGauges: RainfallMonitor[],
    ) {
        if (distributionMethod === MonitorDistributedMethod.UserDefined) {
            return;
        } else if (distributionMethod === MonitorDistributedMethod.Closest) {
            basins.forEach((basin) => {
                // first, get the distances of all the rainfall monitors to the basin centroid
                const distances = rainGauges.map((rg) => {
                    const distance = MathUtils.getDistanceFromLatLonInKm(
                        basin.centroid.latitude,
                        basin.centroid.longitude,
                        rg.latitude,
                        rg.longitude,
                    );
                    return {
                        rainfallMonitorName: rg.name,
                        distance: distance,
                    };
                });

                // find the closest monitor
                distances.sort((a, b) => a.distance - b.distance);
                const closest = distances[0].rainfallMonitorName;

                // closest monitor has 100, others have 0
                basin.weights = rainGauges.map((rg) => {
                    const weight = closest === rg.name ? 100 : 0;
                    return {
                        rainfallMonitorName: rg.name,
                        weight: weight,
                    };
                });
            });
        } else {
            basins.forEach((basin) => {
                // first, get the distances of all the rainfall monitors to the basin centroid
                basin.weights = rainGauges.map((rg) => {
                    const distance = MathUtils.getDistanceFromLatLonInKm(
                        basin.centroid.latitude,
                        basin.centroid.longitude,
                        rg.latitude,
                        rg.longitude,
                    );
                    const weight =
                        distributionMethod === MonitorDistributedMethod.InverseDistance
                            ? (1 / distance) * 100
                            : (1 / (distance * distance)) * 100;
                    return {
                        rainfallMonitorName: rg.name,
                        weight: Math.floor(weight),
                    };
                });
            });
        }
    }


    public exportBasinProperties() {
        const successMsg = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_BASIN_PROPS_MESSAGE');
        this.sliicerService
            .vaultExportBasinProperties(this.customerId, this.caseStudyId)
            .subscribe(
                (ignoreResult) => {
                    this.snackBarNotificationService.raiseNotification(
                        successMsg,
                        this.dismissText,
                        {
                            duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
                        },
                        true,
                    );
                },
                (ignoreError) => {
                    this.snackBarNotificationService.raiseNotification(this.exportErrMsg, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                },
            );
    }

}
