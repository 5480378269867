import { filter } from 'rxjs/operators';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { UsersService } from 'app/pages/admin/users.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { DEPTH_ENTITY, VELOCITY_ENTITY, RAIN_ENTITY, QUANTITY_ENTITY, RAW_VELOCITY_ENTITY, ELEVATION_ENTITY, LEVEL_ENTITY, Q_A2_ENTITY, Q_A1_ENTITY } from 'app/shared/constant';
import {
    ActionTypes,
    BasicSeriesData,
    CopyPasteDestinationAction,
    CopyPasteEntity,
    DataType,
    EditButton,
    HGEditParams,
    ManipulationActions,
} from 'app/shared/models/hydrographNEW';
import { GetPermissionsResponseCustomerPermissions } from 'app/shared/models/users-permission';
import { combineLatest } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { Customer } from 'app/shared/models/customer';

@Component({
    selector: 'app-hydrograph-editing-menu',
    templateUrl: './hydrograph-editing-menu.component.html',
    styleUrls: ['./hydrograph-editing-menu.component.scss'],
})
export class HydrographEditingMenuComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public customerId: number;
    @Input() public entityList: BasicSeriesData[];
    @Input() public selectedDates: Observable<string[]>;
    @Input() public selectedValueRange: number[];
    @Input() public currentParams: HGEditParams;
    @Input() public doubleClick: Observable<{ x: number; y: number }>;
    @Input() public destinationDate: Observable<Date>;
    @Input() public isRainGauge: BehaviorSubject<boolean>;
    @Input() public arePointsSelected: boolean;
    @Input() public selectedMoreThenHour: boolean;
    @Input() public interpolateHasBeenApplied: boolean;
    @Input() public isRawVelExistsForLocation: boolean;

    @Output() public updateParams = new EventEmitter();
    @Output() public resetInterpolateHasBeenApplied = new EventEmitter<void>();
    @Output() public performAction = new EventEmitter();
    @Output() public updateRange = new EventEmitter<number[]>();
    @Output() public applyCopyPaste = new EventEmitter<CopyPasteDestinationAction>();
    @Output() public updateMultiplePointsRules = new EventEmitter();
    @Output() public flag = new EventEmitter();
    @Output() public unflag = new EventEmitter();
    @Output() public interpolateAccept = new EventEmitter();
    @Output() public destinationDateChange: EventEmitter<{ date: Date; entityId: number }> = new EventEmitter();
    @Output() public entityChanged = new EventEmitter(); // to exactly know when entity changed without updateParams

    // Variables to link to each output above
    public copyPasteDestination: CopyPasteDestinationAction;
    public pasteEntitiesList: CopyPasteEntity[] = [];
    public multiplePointsRules: { action: ManipulationActions; value: number };

    public TOOLTIPS;
    public COPY_PASTE;
    public EDIT_MULTIPLE;
    public COMMON;
    public buttonList: EditButton[] = [];
    public copyEntitiesList: BasicSeriesData[];
    public selectedDateRange: Moment[];
    public displaySelectedDateRangeDates: Date[];
    public selectedDateRangeDates: Date[];
    // Since data is all stored UTC time not local time, this lets us display correctly...
    private timeOffsetMS: number;
    public ACTION_TYPES = ActionTypes;
    public MANIPULATION_ACTIONS = ManipulationActions;
    public manipulationList = Object.values(this.MANIPULATION_ACTIONS);
    public copyPasteManipulationList = [
        null,
        ...Object.values(this.MANIPULATION_ACTIONS).filter((x) => x !== ManipulationActions.Equal),
    ];
    public numericDecimal1PlacePattern = REGEX_CONFIG.numericDecimal1PlacePattern;
    public numericDecimal2PlacePattern = REGEX_CONFIG.numericDecimal2PlacePattern;
    public numeric3PlaceDecimalRegEx = REGEX_CONFIG.numericDecimal3PlacePattern;
    public negativeWithOneDecimalPattern = REGEX_CONFIG.negativeWithOneDecimalPattern;
    public negativeWithTwoDecimalPattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public negativeWithThreeDecimalPattern = REGEX_CONFIG.negativeWithThreeDecimalPattern;
    public maxEndDate = new Date();

    private currentCustomer: Customer;
    private userHasPermissionOnEdit = false;
    private customerHasBasicEditPermission = false;
    private customerHasAdvancedEditPermission = false;
    public isPointsSelectedForInterpolation = false;
    public isInterpolateSelectedPoints = true;

    public subscriptions: Array<Subscription> = [];
    public dateFormat: string;
    public momentFormat: string;
    public dateInvalid = [false, false];
    public dateRangeInvalid = false;
    public destinationDateDisplay: string;
    public destinationDateInvalid = false;
    public selectedHintPageName: string;
    public flagActionEnabled = true;

    public disableUndo = true;
    public disableRedo = true;

    constructor(
        private datePipe: DatePipe,
        private translate: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private uiUtilService: UiUtilsService,
        private userService: UsersService,
        private customerService: CustomerService,
        private dateUtilService: DateutilService,
        private domOperationUtilsService: DomOperationUtilsService,
        public dataEditService: DataEditService
    ) {}
    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
        this.subscriptions = [];
    }

    public ngOnInit() {
        const pageNameHint = this.domOperationUtilsService.selectedHintPageName.subscribe((pageName: string) => {
            this.selectedHintPageName = pageName;
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        });
        this.subscriptions.push(pageNameHint);
        // On page load
        this.populatePermissions();
        this.setAllToDefault();
        this.clearDates();
        this.checkForEdits();
        this.subscriptions.push(this.selectedDates.subscribe((dates) => {
            this.setDates(dates)
        }));

        // Get all of the static text
        this.translate.get('HYDROGRAPH_NEW.EDITING_MENU').subscribe((res: string) => {
            this.TOOLTIPS = res;
        });
        this.translate.get('HYDROGRAPH_NEW.COPY_PASTE').subscribe((res: string) => {
            this.COPY_PASTE = res;
        });
        this.translate.get('HYDROGRAPH_NEW.EDIT_MULTIPLE').subscribe((res: string) => {
            this.EDIT_MULTIPLE = res;
        });
        this.translate.get('COMMON').subscribe((res: string) => {
            this.COMMON = res;
        });

        const self = this;
        this.buttonList = [
            {
                tooltip: this.TOOLTIPS.ZOOM,
                icon: 'zoom_in',
                enabled: true,
                action: ActionTypes.Zoom,
                permission: 'basic',
                class: 'de-hg-zoom-hint'
            },
            {
                tooltip: this.TOOLTIPS.EDIT_POINT,
                icon: 'drag',
                enabled: false,
                action: ActionTypes.DragDrop,
                permission: 'basic',
                class: 'de-hg-drag-hint'
            },
            {
                tooltip: this.TOOLTIPS.FLAG_POINT,
                icon: 'flag',
                enabled: false,
                action: ActionTypes.Flag,
                permission: 'basic',
                class: 'de-hg-flag-hint',
                inactive: () => (this.isRainGauge.getValue() || (this.currentParams && this.currentParams.entity && this.currentParams.entity.entityId !== RAIN_ENTITY))
            },
            {
                tooltip: this.TOOLTIPS.UNFLAG_POINT,
                icon: 'outlined_flag',
                enabled: false,
                action: ActionTypes.Unflag,
                permission: 'basic',
                inactive: () => (this.isRainGauge.getValue() || (this.currentParams && this.currentParams.entity && this.currentParams.entity.entityId !== RAIN_ENTITY))
            },
            {
                tooltip: this.TOOLTIPS.CLEAR_SELECTON,
                class: 'custom-eraser-white de-hg-clear-hint',
                enabled: false,
                action: ActionTypes.ClearSelection,
                permission: 'basic',
                inactive: () => self.isSelection(),
            },
            {
                tooltip: this.TOOLTIPS.EDIT_MULTIPLE_POINTS_ENTITIES,
                icon: 'iso',
                enabled: false,
                action: ActionTypes.MultiPointEdit,
                permission: 'basic',
                class: 'de-hg-multiple-hint'
            },
            {
                tooltip: this.TOOLTIPS.COPY_PASTE_ENTITIES,
                icon: 'file_copy',
                enabled: false,
                action: ActionTypes.CopyPaste,
                permission: 'basic',
                class: 'de-hg-copy-hint'
            },
            {
                tooltip: this.TOOLTIPS.INTERPOLATE,
                iconUrl: '/assets/images/interpolate.png',
                enabled: false,
                action: ActionTypes.Interpolate,
                permission: 'advanced',
                class: 'de-hg-interpolate-hint'
            },
        ];

        this.subscriptions.push(
            combineLatest([this.dateUtilService.dateFormat, this.dateUtilService.timeFormat]).subscribe(
                ([newDateFormat, newTimeFormat]) => {
                    this.dateFormat =
                        this.dateUtilService.getStringFormat(newDateFormat) +
                        ' ' +
                        this.dateUtilService.getTimeFormat();
                    this.momentFormat =
                        this.dateUtilService.getStringFormat(newDateFormat).toUpperCase() +
                        ' ' +
                        this.dateUtilService.getTimeFormat();
                },
            ),
        );

        this.subscriptions.push(
            this.doubleClick.subscribe((point) => {
                if (!point) return;

                if (this.currentParams.action === ActionTypes.CopyPaste) {
                    // Seems like new Date().getTimezoneOffset() will give offset without including 1 hour change on some timezones
                    // calling .date.getTimezoneOffset() from the date from datepicker is safe
                    this.timeOffsetMS = new Date(point.x).getTimezoneOffset() * 60000;
                    const newDate = moment(point.x + this.timeOffsetMS).toDate();
                    this.copyPasteDestination.date = newDate;
                    this.setDestinationDisplayDate(newDate);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                }
            }),
        );

        this.subscriptions.push(
            this.destinationDate.subscribe((date) => {
                this.setDestinationDisplayDate(date);
                this.copyPasteDestination.date = date;
                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            }),
        );

        this.dataEditService.onEditsChanged.next(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.entityList || changes.currentParams) {
            const list = changes.entityList ? changes.entityList.currentValue : this.entityList;
            const params = changes.currentParams ? changes.currentParams.currentValue : this.currentParams;
            this.checkSelectedEntity(list, params);
        }
    }

    private checkSelectedEntity(list: BasicSeriesData[], params: HGEditParams) {
        if (!list || !list.length || !params || !params.entity) return;

        const { action, entity } = params;
        this.currentParams = { action, entity: list.find(v => v.entityId === entity.entityId && v.lid === entity.lid) };
    }

    private checkForEdits() {
        const subs = this.dataEditService.onEditsChanged.subscribe(() => {
            const { currentEditTimestamp: currentTs, storedEdits: edits } = this.dataEditService;

            this.disableUndo = edits.length === 0 || currentTs === null;
            this.disableRedo = (edits.length === 0 || edits[edits.length -1].ts === currentTs);
        });

        this.subscriptions.push(subs);
    }

    private populatePermissions() {
        combineLatest([
            this.userService.isBasicDataEditingAllowed,
            this.userService.isAdvancedDataEditingAllowed,
        ]).subscribe(([basicEditingAllowed, advancedEdititngAllowed]) => {
            this.userHasPermissionOnEdit = basicEditingAllowed;
            this.customerHasBasicEditPermission = basicEditingAllowed;
            this.customerHasAdvancedEditPermission = advancedEdititngAllowed.value;
        });

        this.customerService.userCustomers.subscribe((customer) => {
            if (customer) {
                const tempCustomer = customer.find((v) => v.customer.customerID === this.customerId);
                this.currentCustomer = tempCustomer ? tempCustomer.customer : null;
            }
        });
    }

    private checkPermission(permission: 'basic' | 'advanced') {
        if (!this.userHasPermissionOnEdit || !this.customerHasBasicEditPermission) {
            return false;
        }
        if (permission === 'basic') {
            return true;
        }

        return this.customerHasAdvancedEditPermission;
    }

    public onEntitySelected(newEntity: BasicSeriesData) {
        this.entityChanged.emit(null);
        const chosenEntity = this.entityList.find((x) => x.entityId === newEntity.entityId && x.lid === newEntity.lid);

        // Update buttons based on what was chosen
        this.updateShownButtons(chosenEntity);

        // #26450 If current action will be disabled, then change it to default ZOOM one
        const curActionBtn = this.buttonList.find(b => b.action === this.currentParams.action);
        if(curActionBtn && (
                (!curActionBtn.enabled)
                || (curActionBtn.inactive && !curActionBtn.inactive())
            )) {
            this.currentParams.action = this.ACTION_TYPES.Zoom;
        }

        // Update other variables accordingly
        this.copyPasteDestination = { ...this.copyPasteDestination, date: null };
        this.setDestinationDisplayDate(null);
        this.setCopyPasteDestination(newEntity);
        this.clearDates();
        this.isPointsSelectedForInterpolation = false;
        this.currentParams.entity = newEntity;
        this.updateParams.emit(this.currentParams);
    }
    public setCopyPasteDestination(entity: BasicSeriesData) {
        if (!entity) {
            return;
        }
        // Array below is entity IDs that are allowed to receive copy paste:
        const allowedEntityDestinations = [DEPTH_ENTITY, VELOCITY_ENTITY, QUANTITY_ENTITY, RAIN_ENTITY, RAW_VELOCITY_ENTITY, Q_A1_ENTITY, Q_A2_ENTITY];
        const destinationEntities = this.entityList.filter(
            (x) => x.dataType === entity.dataType && allowedEntityDestinations.includes(x.entityId) && x.lid === undefined  // undefined lid means that series is from current location
        );

        if (!destinationEntities.length) {
            return;
        }

        // #36877 Raw velocity entity should be selected by default over Velocity
        destinationEntities.sort((a, b) => a.entityId === RAW_VELOCITY_ENTITY ? -1 : 0);
        this.pasteEntitiesList = destinationEntities.map(v => ({ entityId: v.entityId, name: v.entityName }));
        this.copyPasteDestination.entity = {
            entityId: destinationEntities[0].entityId,
            name: destinationEntities[0].entityName,
        };
    }
    public onPasteEntitySelect(entityName: string) {
        this.copyPasteDestination.entity = this.pasteEntitiesList.find(v => v.name === entityName);
    }
    readonly EDIT_BUTTONS: ActionTypes[] = [
        ActionTypes.Zoom,
        ActionTypes.DragDrop,
        ActionTypes.Flag,
        ActionTypes.Unflag,
        ActionTypes.MultiPointEdit,
        ActionTypes.CopyPaste,
        ActionTypes.Interpolate,
        ActionTypes.ClearSelection
    ];
    public updateShownButtons(entity: BasicSeriesData) {
        let shownButtons = [];
        const dataType = entity ? entity.dataType : null;
        switch (dataType) {
            case DataType.Depth:
            case DataType.Feet:
            case DataType.Velocity: {
                if ((
                    entity.entityId === DEPTH_ENTITY
                    || entity.entityId === VELOCITY_ENTITY
                    || entity.entityId === ELEVATION_ENTITY
                    || entity.entityId === RAW_VELOCITY_ENTITY
                ) && entity.lid === undefined) {
                    // Unidepth or velocity are the only entities all these should be available for
                    shownButtons = this.EDIT_BUTTONS;
                } else if(entity.entityId === LEVEL_ENTITY ) {
                    shownButtons = [ActionTypes.Zoom, ActionTypes.ClearSelection];
                }
                else {
                    shownButtons = [ActionTypes.Zoom, ActionTypes.CopyPaste, ActionTypes.ClearSelection];
                }
                break;
            }
            case DataType.Rain: {
                if (entity.lid === undefined) {
                    // AMP 8/2020: Lee requested to remove copy paste for now since not supported by backend
                    shownButtons = [ActionTypes.Zoom, ActionTypes.Flag, ActionTypes.Unflag, ActionTypes.ClearSelection];
                } else {
                    shownButtons = [ActionTypes.Zoom];
                }
                break;
            }
            case DataType.Quantity: {
                // #40871 Allow Q_A1 and Q_A2 to be edited
                if(entity.entityId === Q_A1_ENTITY || entity.entityId === Q_A2_ENTITY) {
                    shownButtons = this.EDIT_BUTTONS;
                } else {
                    shownButtons = [ActionTypes.Zoom, ActionTypes.CopyPaste, ActionTypes.ClearSelection];
                }
                break;
            }
            default: {
                shownButtons = [ActionTypes.Zoom];
            }
        }
        this.buttonList.forEach((x) => {
            shownButtons.includes(x.action) ? this.enableButtonIfHasPermission(x) : (x.enabled = false);
        });
    }

    private enableButtonIfHasPermission(button: EditButton) {
        if (this.checkPermission(button.permission)) {
            button.enabled = true;
        }
    }

    public isSelection(): boolean {
        return this.arePointsSelected;
    }

    public onButtonClick(button: EditButton) {
        if (button.action === ActionTypes.ClearSelection) {
            this.performAction.emit({ action: ActionTypes.ClearSelection });
        } else {
            this.currentParams.action = button.action;
            this.updateParams.emit(this.currentParams);

            this.setCopyPasteDestination(this.currentParams.entity);

            if (button.action !== ActionTypes.CopyPaste) {
                this.copyPasteDestination = { ...this.copyPasteDestination, date: null };
                // this.selectedDateRange = null;
            }
            if (button.action !== ActionTypes.MultiPointEdit) {
                this.multiplePointsRules = { action: null, value: null };
                // this.selectedDateRange = null;
            }

            if (button.action !== ActionTypes.Interpolate) {
                this.isPointsSelectedForInterpolation = false;
            }
        }
    }

    public onCancelInterpolation() {
        this.resetInterpolateHasBeenApplied.emit();
        this.currentParams.action = ActionTypes.Zoom;
        this.isPointsSelectedForInterpolation = false;
        this.updateParams.emit(this.currentParams);
    }
    public cancelEdits() {
        this.setAllToDefault();
        this.clearDates();
        this.currentParams.action = ActionTypes.Zoom;
        this.updateParams.emit(this.currentParams);
    }
    public applyEdits() {
        if (this.currentParams.action === ActionTypes.CopyPaste) {
            // Since data is all stored UTC time not local time...
            this.applyCopyPaste.emit(this.copyPasteDestination);
            // #22304 change to Zoom tool
            this.currentParams.action = ActionTypes.Zoom;
            this.updateParams.emit(this.currentParams);
        } else if (this.currentParams.action === ActionTypes.MultiPointEdit) {
            this.updateMultiplePointsRules.emit(this.multiplePointsRules);
        } else if (this.currentParams.action === ActionTypes.Interpolate) {
        } else if (this.currentParams.action === ActionTypes.Flag) {
            this.flag.emit(true);
        } else if (this.currentParams.action === ActionTypes.Unflag) {
            this.unflag.emit(true);
        }
        this.setAllToDefault();
    }
    public setDates(dates) {
        const { entity } = this.currentParams;
        this.timeOffsetMS = new Date(dates[0]).getTimezoneOffset() * 60000;
        const firstAvailableDate = this.entityList
            .find((x) => entity && x.entityId === entity.entityId && x.lid === entity.lid)
            .data.find((y) => y.x >= dates[0]);
        this.selectedDateRange = [
            moment(firstAvailableDate.x + this.timeOffsetMS),
            moment(dates[1] + this.timeOffsetMS),
        ];
        this.selectedDateRangeDates = [this.selectedDateRange[0].toDate(), this.selectedDateRange[1].toDate()];
        this.displaySelectedDateRangeDates = this.selectedDateRangeDates;
        this.copyPasteDestination.date = new Date(firstAvailableDate.x + this.timeOffsetMS);
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);

        this.setDestinationDisplayDate(this.displaySelectedDateRangeDates[0]);
    }
    public clearDates() {
        this.selectedDateRange = null;
        this.displaySelectedDateRangeDates = [null, null];
        this.selectedDateRangeDates = [null, null];
        this.setDestinationDisplayDate(null);
    }
    public onManualSourceDateSelected(index: number, event) {
        this.dateRangeInvalid = false;
        this.dateInvalid[index] = false;

        const changeDate = moment(event.target.value, this.momentFormat);

        if (changeDate.isValid()) {
            if (
                index === 0
                    ? this.checkDates(changeDate.toDate(), this.selectedDateRangeDates[1])
                    : this.checkDates(this.selectedDateRangeDates[0], changeDate.toDate())
            ) {
                this.selectedDateRangeDates[index] = changeDate.toDate();
                this.onSourceDateSelected(index);
            } else {
                this.dateRangeInvalid = true;
            }
        } else {
            this.dateInvalid[index] = true;
        }
    }
    private checkDates(start: Date, end: Date): boolean {
        return start < end;
    }
    public onSourceDateSelected(index: number, event?) {
        this.dateInvalid[index] = false;
        this.dateRangeInvalid = false;
        if (!this.checkDates(this.displaySelectedDateRangeDates[0], this.displaySelectedDateRangeDates[1])) {
            this.dateRangeInvalid = true;
            return;
        }
        this.selectedDateRangeDates[index] = this.displaySelectedDateRangeDates[index];
        this.selectedDateRange[index] = moment(this.selectedDateRangeDates[index]);

        this.setDestinationDisplayDate(this.displaySelectedDateRangeDates[0]);
        this.copyPasteDestination.date = new Date(this.displaySelectedDateRangeDates[0]);
        // Seems like new Date().getTimezoneOffset() will give offset without including 1 hour change on some timezones
        // calling .date.getTimezoneOffset() from the date from datepicker is safe
        this.timeOffsetMS = this.copyPasteDestination.date.getTimezoneOffset() * 60000;
        const from = this.selectedDateRangeDates[0].getTime() - this.timeOffsetMS;
        const to = this.selectedDateRangeDates[1].getTime() - this.timeOffsetMS;

        this.updateRange.emit([from, to]);
    }

    public onManualDestinationDateModelChange(dateStr) {
        this.destinationDateDisplay = dateStr;
    }
    public onManualDestinationDateSelected(event) {
        this.destinationDateInvalid = false;

        const changeDate = moment(event.target.value, this.momentFormat);
        if (changeDate.isValid()) {
            this.onDestinationDateSelected({ value: changeDate.toDate() });
        } else {
            this.destinationDateInvalid = true;
        }
    }
    public onDestinationDateSelected(event) {
        this.copyPasteDestination.date = event.value;
        this.setDestinationDisplayDate(event.value);

        const params = { date: event.value, entityId: this.copyPasteDestination.entity.entityId };
        this.destinationDateChange.emit(params);
    }
    public onActionSelected(newAction: ManipulationActions) {
        this.multiplePointsRules.action = newAction;
    }
    public onCopyPasteActionSelected(newAction: ManipulationActions) {
        if (!newAction) {
            this.copyPasteDestination.value = null;
        }

        this.copyPasteDestination.action = newAction;
    }

    public setAllToDefault() {
        const { entity } = this.currentParams;
        // Reset any potential changes irregardless of what has been done
        this.copyPasteDestination = {
            entity: this.copyPasteDestination ? this.copyPasteDestination.entity : null,
            date: this.displaySelectedDateRangeDates ? new Date(this.displaySelectedDateRangeDates[0]) : null,
        };
        this.multiplePointsRules = { action: null, value: null };

        if (this.currentParams.action === ActionTypes.CopyPaste && this.displaySelectedDateRangeDates) {
            this.setDestinationDisplayDate(this.displaySelectedDateRangeDates[0]);
        }

        if (this.currentParams.entity !== null) {
            this.updateShownButtons(this.entityList.find((x) => entity && x.entityId === entity.entityId && x.lid === entity.lid));
        } else {
            this.currentParams.action = ActionTypes.Zoom;
            this.copyPasteDestination.entity = { entityId: null, name: null }; // Only reset these if no entity present
            this.updateShownButtons(null);
        }
    }

    public getDestinationDateTimeEnd() {
        if (!this.destinationDateDisplay) return null;

        const sourceStart = this.displaySelectedDateRangeDates[0];
        const sourceEnd = this.displaySelectedDateRangeDates[1];

        if (!sourceStart || !sourceEnd) return;

        const sourceLength = sourceEnd.getTime() - sourceStart.getTime();

        const destinationEndMoment = moment(this.destinationDateDisplay, this.momentFormat);

        if (destinationEndMoment.isValid()) {
            const destinationEnd = destinationEndMoment.toDate().getTime() + sourceLength;
            const destinationDateEnd = new Date(destinationEnd);
            return this.datePipe.transform(destinationDateEnd, this.dateFormat);
        } else {
            return null;
        }
    }

    public setPointsForInterpolation(isSelected: boolean) {
        this.isPointsSelectedForInterpolation = isSelected;
    }

    private setDestinationDisplayDate(date) {
        const changeDate = typeof date === 'string' ? moment(date, this.momentFormat) : moment(date);

        if (changeDate.isValid()) {
            this.destinationDateInvalid = false;
            this.destinationDateDisplay = this.datePipe.transform(changeDate.toDate(), this.dateFormat);
        } else if (!isNaN(date)) {
            this.destinationDateInvalid = false;
            this.destinationDateDisplay = date;
        } else {
            this.destinationDateInvalid = true;
            this.destinationDateDisplay = null;
        }
    }
}
