import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    OnChanges,
    AfterContentInit,
    SimpleChanges,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UsersService } from 'app/pages/admin/users.service';
import { TranslateService } from '@ngx-translate/core';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { first } from 'rxjs';

@Component({
    selector: 'app-approve-data',
    templateUrl: './approve-data.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ApproveDataComponent implements OnInit, OnChanges {
    constructor(
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private userService: UsersService,
        private translate: TranslateService,
        private dateutilService: DateutilService
    ) { }

    public customerDateFormat: string;
    public startDate: Date;
    public userName: string;
    public today = new Date();
    public isError: boolean;

    @Input() public endDate: Date;

    @Input() public disabled: boolean;

    @Input() public prevApprStartDate: Date;

    @Input() public prevApprEndDate: Date;

    @Output() public startDateChange = new EventEmitter<Date>();
    @Output() public endDateChange = new EventEmitter<Date>();

    @Output() public IsErrorOnApproveData = new EventEmitter<boolean>();

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit() {
        const dateFormat = this.dateutilService.getFormat();
        const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customerDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;
        this.userName = this.userService.userName;
    }

    public eventStartChanged(event: MatDatepickerInputEvent<Date>) {

       this.startDate = event.value;

       this.startDateChange.emit(this.startDate);

       this.approvalDateChange();
    }

    public eventEndChanged(event: MatDatepickerInputEvent<Date>) {
        
        this.endDate = event.value;
        this.endDateChange.emit(this.endDate);
        this.approvalDateChange();
    }

    public approvalDateChange() {
        let message = '';
        this.isError = false;

        if(this.startDate && !this.endDate)
        {
            message = 'Please provide an End Date';
        }

        var startDate = this.startDate?.getTime();
        var endDate = this.endDate?.getTime();

        if(startDate > endDate)
        {
            this.isError = true;
        }

        this.IsErrorOnApproveData.emit(this.isError);
        
    }


}
