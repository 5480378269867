import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UsersService } from 'app/pages/admin/users.service';
import { customerQueryParam, SubscriptionLevel, WidgetsIds } from 'app/shared/models/customer';
import { UserSettingsWidget } from 'app/shared/models/user-settings';
import { CustomerService } from 'app/shared/services/customer.service';
import { combineLatest, Subscription } from 'rxjs';

interface UIWidget {
    id: WidgetsIds;
    name: string;
    checked?: boolean;
    disabled?: boolean;
}

@Component({
    selector: 'app-turn-off-widgets-dialog',
    templateUrl: './turn-off-widgets-dialog.component.html',
    styleUrls: ['./turn-off-widgets-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TurnOffWidgetsDialogComponent implements OnInit, OnDestroy {

    private customerID: number;
    private subscriptionLevel: number = SubscriptionLevel.PrismLimited;
    private isEventEditorOnCustomerAllowed: boolean;
    private isAutoReviewAllowed: boolean;
    private isCumulativeRainAllowed: boolean;

    private subscriptions = new Array<Subscription>();
    public SubscriptionLevel = SubscriptionLevel;
    private widgetSettingsAssoc: {[key: number]: boolean} = {};
    public isModified = false;

    public WIDGETS: UIWidget[] = [
        {id: WidgetsIds.Alarms, name: 'HOME.ALARMS_TILE.TITLE', disabled: true},
        {id: WidgetsIds.Battery, name: 'HOME.BATTERY_TILE.TITLE'},
        {id: WidgetsIds.DataCommunication, name: 'HOME.COLLECTION_TILE.TITLE'},
        {id: WidgetsIds.BlockagePredict, name: 'HOME.BLOCKAGE_PREDICTION_TILE.TITLE'},
        {id: WidgetsIds.AutoReview, name: 'HOME.AUTO_REVIEW_TILE.TITLE'},
        {id: WidgetsIds.Events, name: 'HOME.EVENT_TILE.TITLE'},
        {id: WidgetsIds.CumulativeRainfall, name: 'HOME.CUMULATIVE_RAIN_TILE.TITLE'},
    ];

    public filteredWidgets: UIWidget[] = [];
    constructor(
        private dialog: MatLegacyDialog,
        private customerService: CustomerService,
        private usersService: UsersService,
        private activatedRoute: ActivatedRoute
    ) { }
    public ngOnInit() {
        const queryParamsSubs = this.activatedRoute.queryParamMap
            .subscribe((params: ParamMap) => {
                this.customerID = Number(params.get(customerQueryParam));
                const getProjectDetailsSubscription = combineLatest(
                    this.customerService.getProjectDetails(this.customerID),
                    this.usersService.isEventEditorOnCustomerAllowed,
                    this.usersService.isAutoReviewAllowed,
                    this.usersService.isCumulativeRainAllowed,
                ).subscribe(
                    ([projectDetails, isEventEditorOnCustomerAllowed, isAutoReviewAllowed, isCumulativeRainAllowed]) => {
                        this.subscriptionLevel = projectDetails.subscriptionLevel;
                        this.isEventEditorOnCustomerAllowed = isEventEditorOnCustomerAllowed.value;
                        this.isAutoReviewAllowed = isAutoReviewAllowed.value;
                        this.isCumulativeRainAllowed = isCumulativeRainAllowed.value;

                        this.filterWidgets();
                        this.applyChecked();

                        this.subscriptions.push(
                            this.usersService.userSettingsWidgetConfig().subscribe(config => {
                                this.widgetSettingsAssoc = config;
                                this.applyChecked();

                            })
                        );
                    }
                );
                this.subscriptions.push(getProjectDetailsSubscription);
            });
        this.subscriptions.push(queryParamsSubs);
    }

    public filterWidgets() {
        this.filteredWidgets = this.WIDGETS.filter(
            w => {
                if(w.id === WidgetsIds.BlockagePredict && this.subscriptionLevel === SubscriptionLevel.PrismPro) return true;
                if(w.id === WidgetsIds.AutoReview && this.isAutoReviewAllowed) return true;
                if(w.id === WidgetsIds.CumulativeRainfall && this.isCumulativeRainAllowed) return true;
                if(w.id === WidgetsIds.Events && this.isEventEditorOnCustomerAllowed) return true;

                return ![WidgetsIds.BlockagePredict, WidgetsIds.AutoReview, WidgetsIds.CumulativeRainfall, WidgetsIds.Events].includes(w.id);
            }
        );
    }

    public applyChecked() {
        if(!this.widgetSettingsAssoc) return;

        this.filteredWidgets.forEach(w => w.checked = this.widgetSettingsAssoc[w.id]);
    }

    public checkForChanges() {
        if(!this.widgetSettingsAssoc) return false;

        for(const w of this.filteredWidgets) {
            if(w.checked !== this.widgetSettingsAssoc[w.id]) return true;
        }

        return false;
    }

    public checkAtLeastOne() {
        return this.filteredWidgets.some(w => w.checked);
    }

    public itemChange(event) {
        this.isModified = this.checkForChanges();
    }

    public cancel() {
        this.dialog.closeAll();
    }

    public save() {
        const settings = this.usersService.userSettings.getValue();
        const widgetAssoc: {[key: number]: UIWidget} = {};
        for(const w of this.filteredWidgets) {
            widgetAssoc[w.id] = w;
        }

        for(const w of settings.widgetSettings) {
            w.e = widgetAssoc[w.p]?.checked ?? w.e;
        }

        this.usersService.updateUserSettings(settings).subscribe(() => {
            this.usersService.userSettings.next(settings);
            this.dialog.closeAll();
        })
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }
}
