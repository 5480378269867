import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { UsersService } from 'app/pages/admin/users.service';
import {
    ACTION,
    FAILURE_ATTEMPTS,
    LAST_COLLECTED_DATE,
    LAST_FAILED_COLLECTION,
    LAST_FAILED_REASON,
    LAST_MANUAL_COLLECTION,
    LAST_SCHEDULED_COLLECTION,
    LOCATION_NAME,
    MONITOR_SERIES,
    NEXT_SCHEDULED_COLLECTION,
    SUCCESS_ATTEMPTS,
    TOTAL_ATTEMPTS,
} from 'app/shared/constant';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { CollectionWidgetScheduleComponent } from '../collection-widget-schedule/collection-widget-schedule.component';
import { DataCollectionComponent } from '../data-collection/data-collection.component';
import { SignalRService } from 'app/shared/services/signalr.service';
import { SignalRMessage } from 'app/shared/models/signalr-message';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { CollectService } from 'app/shared/services/collect.service';
import { SharedService } from 'app/shared/services/shared.service';
import { LocationService } from 'app/shared/services/location.service';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { AuthService } from 'app/shared/services/auth.service';
import { ScheduleCollection } from 'app/shared/models/schedule-collection';
import { Selectable } from 'app/shared/models/selectable';
import { CollectionDetails, CollectionHistory, GetCollectHistoryArgs } from 'app/shared/models/collection-history';
import { FilterSettings, WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { customerLocationGroupQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { AdsPrismAddScheduleComponent } from '../dashboards/schedule-collect-dashboard';
import { debounceTime, take } from 'rxjs/operators';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';

const statusCode = 3;
@Component({
    selector: 'app-collection-widget',
    templateUrl: './collection-widget.component.html',
    styleUrls: ['./collection-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CollectionWidgetComponent implements OnChanges, OnDestroy, OnInit {
    public schedules = new Array<ScheduleCollection>();
    // @Input() public collectionHistory = new Array<CollectionHistory>();
    @Input() public showCollectionSearch: boolean;
    @Input() public toggleState: boolean;
    @Input() public availablePageHint: boolean;
    @Input() public customerID: number;
    @Input() public locationGroupID: number;
    @Input() public customerLocationsLoaded: boolean;
    @Input() public includeInactiveLocations: boolean;
    @Input() public dateFormat: string;
    @Output() public showMap = new EventEmitter<boolean>();
    @Output() private emitWidgetData = new EventEmitter();
    @Output() public showMapLocationForCollectionWidget: EventEmitter<Array<number>> = new EventEmitter<
        Array<number>
    >();
    @Output() public displayedData: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

    public isCollecting: boolean;
    public disableSchedule: boolean;
    public locations: Array<Selectable>;
    private subscriptions = new Array<Subscription>();
    public collectionWidgetHistory = new Array<CollectionHistory>();
    public collectionWidgetDetails: CollectionDetails[];
    public collectionWidgetDisplayedColumns = ['locationname', 'lastcollecteddate', 'action'];
    public collectionWidgetDataSource: MatTableDataSource<CollectionHistory>;
    public longTablecollectionDetailsDataSource: MatTableDataSource<CollectionHistory>;
    public collectionDetailsDataSource: MatTableDataSource<CollectionDetails>;
    @ViewChild(MatPaginator) public collectionWidgetPaginator: MatPaginator;
    @ViewChild('table') public collectionWidgetSort: MatSort;
    @ViewChild('longtable') public collectionWidgetLongTableSort: MatSort;
    @ViewChild('filter') public collectionWidgetFilter: ElementRef;
    public collectionWidgetDataChange: BehaviorSubject<CollectionHistory[]> = new BehaviorSubject<CollectionHistory[]>(
        [],
    );
    public collectionDetailsDataChange: BehaviorSubject<CollectionDetails[]> = new BehaviorSubject<CollectionDetails[]>(
        [],
    );
    public startPage: number;
    public pageSize: number;
    public locationHeader: string;
    public monitorHeader: string;
    public failedAttemptHeader: string;
    public successAttemptHeader: string;
    public lastCollectedHeader: string;
    public lastScheduledHeader: string;
    public lastManualHeader: string;
    public lastFailedHeader: string;
    public lastFailedReasonHeader: string;
    public totalAttemptHeader: string;
    public nextScheduledHeader: string;
    public allSchedules: Array<ScheduleCollection>;
    prevParms: GetCollectHistoryArgs;

    public get collectionWidgetData(): CollectionHistory[] {
        return this.collectionWidgetDataChange.value;
    }
    public get collectionDetailsData(): CollectionDetails[] {
        return this.collectionDetailsDataChange.value;
    }
    public totalPaginationLength: number;
    public collectionDetailsDisplayedColumns = [
        LOCATION_NAME,
        MONITOR_SERIES,
        FAILURE_ATTEMPTS,
        TOTAL_ATTEMPTS,
        SUCCESS_ATTEMPTS,
        LAST_COLLECTED_DATE,
        LAST_SCHEDULED_COLLECTION,
        LAST_MANUAL_COLLECTION,
        LAST_FAILED_COLLECTION,
        LAST_FAILED_REASON,
        NEXT_SCHEDULED_COLLECTION,
        ACTION,
    ];
    /**
     * Variable which validates API state of the first initially loaded collection widget locations on map
     */
    public collectionAPIState = true;

    /**
     * Represents the loading state for locations
     */
    public locationsLoadingState: boolean;

    /**
     * Represents snackbar message
     */
    public snackbarMessage: string;

    /**
     * Represents snackbar dismiss button text
     */
    public dismissBtn: string;

    public isReport: boolean;

    public showErrorMessage: boolean;
    public collectInitSuccessMsg: string;
    public invalidCollectionMessage: string;
    public locationScheduleMessage: string;
    public actionLabel: string;
    public isScheduleCollectAllowed: boolean;
    private scheduleCollectDashboardURL = '/pages/menuDashboard/schedule-collect/overview';
    public translateKeys: Array<string> = [
        'HOME.COLLECTION_WIDGET.SNACKBAR_TEXT',
        'COMMON.DISMISS_TEXT',
        'HOME.MAP.MARKER_LOCATION_DETAIL.COLLECT_INIT_SUCCESS_MSG',
        'HOME.MAP.MARKER_LOCATION_DETAIL.INVALID_IP_COLLECTIONS',
        'COMMON.DISMISS_TEXT',
        'HOME.COLLECTION_WIDGET.SNACKBAR_TEXT',
        'COMMON.LOCATION_NAME_HEADER',
        'COMMON.MONITOR_SERIES_HEADER',
        'COMMON.FAILURE_ATTEMPTS_HEADER',
        'COMMON.TOTAL_ATTEMPTS_HEADER',
        'COMMON.SUCCESS_ATTEMPTS_HEADER',
        'COMMON.LAST_COLLECTED_DATE_HEADER',
        'COMMON.LAST_SCHEDULED_COLLECTION_HEADER',
        'COMMON.LAST_MANUAL_COLLECTION_HEADER',
        'COMMON.LAST_FAILED_COLLECTION_HEADER',
        'COMMON.NEXT_SCHEDULED_COLLECTION_HEADER',
    ];
    public reportFilterSettings: FilterSettings = {
        displayLocations: true,
        singleLocationSelect: true,
        singleMonitorSelect: true,
        displayDateRanges: true,
        skipInitialCall: true,
        onlyDisplayMP1Locations: true,
    };

    constructor(
        private locationDashboardService: LocationDashboardService,
        public scheduleCollectionService: ScheduleCollectionService,
        private dialog: MatDialog,
        private dateutilService: DateutilService,
        private cdr: ChangeDetectorRef,
        private locationService: LocationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sharedService: SharedService,
        private collectService: CollectService,
        private usersService: UsersService,
        private snackBar: MatSnackBar,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private snackBarNotificationService: SnackBarNotificationService,
        private signalRService: SignalRService,
        private authService: AuthService,
        private domOperationUtilsService: DomOperationUtilsService,
    ) {
        const translateSubs = translate.get(this.translateKeys).subscribe((translateValues) => {
            this.snackbarMessage = translateValues['HOME.COLLECTION_WIDGET.SNACKBAR_TEXT'];
            this.dismissBtn = translateValues['COMMON.DISMISS_TEXT'];
            this.collectInitSuccessMsg = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.COLLECT_INIT_SUCCESS_MSG'];
            this.invalidCollectionMessage = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.INVALID_IP_COLLECTIONS'];
            this.locationScheduleMessage = translateValues['HOME.COLLECTION_WIDGET.SNACKBAR_TEXT'];
            this.actionLabel = translateValues['COMMON.DISMISS_TEXT'];

            this.locationHeader = translateValues['COMMON.LOCATION_NAME_HEADER'];
            this.monitorHeader = translateValues['COMMON.MONITOR_SERIES_HEADER'];
            this.failedAttemptHeader = translateValues['COMMON.FAILURE_ATTEMPTS_HEADER'];
            this.totalAttemptHeader = translateValues['COMMON.TOTAL_ATTEMPTS_HEADER'];
            this.successAttemptHeader = translateValues['COMMON.SUCCESS_ATTEMPTS_HEADER'];
            this.lastCollectedHeader = translateValues['COMMON.LAST_COLLECTED_DATE_HEADER'];
            this.lastScheduledHeader = translateValues['COMMON.LAST_SCHEDULED_COLLECTION_HEADER'];
            this.lastManualHeader = translateValues['COMMON.LAST_MANUAL_COLLECTION_HEADER'];
            this.lastFailedHeader = translateValues['COMMON.LAST_FAILED_COLLECTION_HEADER'];
            this.nextScheduledHeader = translateValues['COMMON.NEXT_SCHEDULED_COLLECTION_HEADER'];
        });

        this.lastFailedReasonHeader = this.translate.instant('COMMON.LAST_FAILED_REASON_COLLECTION_HEADER');
        this.subscriptions.push(translateSubs)
    }
    public ngOnInit() {

        const hintSubs = this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
            this.availablePageHint = result;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        this.subscriptions.push(hintSubs);
        this.activatedRoute.params.subscribe((params) => {
            if (params['report'] === 'report') {
                this.activatedRoute.queryParams.subscribe((param) => {
                    this.isReport = true;
                    this.customerID = param[customerQueryParam];
                    this.locationGroupID = param[customerLocationGroupQueryParam];
                    this.loadLocations();
                    this.statusCodeService.toggleTheme();
                    this.toggleState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                });
            }
        });
        this.checkSheduleCollectPermission();

        this.collectService.collectionEdited.subscribe((response) => {
            if (response === null /* initial case */ || response.customerId === this.customerID) {
                this.generateReportWithGenericArgs();
            }
        });

        this.signalRService.collectComplete.subscribe((data: SignalRMessage) => {
            if (data && data.cid === this.customerID && (!data.userId || data.userId === this.authService.userID)) {
                this.generateReportWithGenericArgs();
            }
        });
        this.signalRService.collectFailed.subscribe((data: SignalRMessage) => {
            if (data && data.cid === this.customerID && (!data.userId || data.userId === this.authService.userID)) {
                this.collectionWidgetData.forEach((row) => {
                    if (row.locationid === data.lid) {
                        row.collectinprogress = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                });
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ((changes.customerLocationsLoaded && changes.customerLocationsLoaded.currentValue) || changes.dateFormat) {
            this.loadLocations();

            this.collectionAPIState = true;
            // this.generateReportWithGenericArgs();

            // disable schedule button initially
            this.disableSchedule = true;
        }
        this.statusCodeService.isVisibleCollection.pipe(take(1)).subscribe((response) => {
            if (response) {
                this.generateReportWithGenericArgs();
            }
        });

        this.checkSheduleCollectPermission();
        if (changes.toggleState && changes.toggleState.previousValue && !changes.toggleState.currentValue) {
            this.locationsLoadingState = true;
            this.generatCommunicationArgs();
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    private generatCommunicationArgs(isCollectAllSchedule?: boolean) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        const endDate = startDate;
        const args = <GetCollectHistoryArgs>{
            customerID: this.customerID,
            LocationGroupID: this.locationGroupID || 0,
            StartTime: startDate,
            EndTime: endDate,
            Status: statusCode,
            IncludeInactiveLocations: this.includeInactiveLocations,
            includeTcpConnections: true,
        };
        if (isCollectAllSchedule) {
            this.getCollectionHistory(args);
        } else {
            this.getCollectionDetails(args);
        }
    }

    private generateReportWithGenericArgs() {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        const endDate = startDate;
        const args = <GetCollectHistoryArgs>{
            customerID: this.customerID,
            LocationGroupID: this.locationGroupID || 0,
            StartTime: startDate,
            EndTime: endDate,
            Status: 3,
            IncludeInactiveLocations: this.includeInactiveLocations,
            includeTcpConnections: true,
        };

        this.getCollectionHistory(args);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Load the locations from locations API
     */
    public loadLocations() {
        // set location loading state value to display loader
        this.locationsLoadingState = true;

        // create the instance of locations variable
        this.locations = new Array<Selectable>();

        // intialize location parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerID,
            locationGroupId: this.locationGroupID || 0,
            validCommunications: true,
        };

        // Fetch the locations for selected customer
        const subscription = this.locationDashboardService.getLocationsList(locationArgs).subscribe(
            (res: Array<Locations>) => {
                if (res && res.length > 0) {
                    // filter active locations
                    res = res.filter(
                        (location: Locations) =>
                            location.status === LocationStatus.Active || location.status === LocationStatus.Maintenance,
                    );

                    if (res.length > 0) {
                        // activate schedule button
                        this.disableSchedule = false;

                        // detect change detector reference
                        this.uiUtilsService.safeChangeDetection(this.cdr);

                        // sort the locations based on name
                        const filterPipe = new OrderByPipe();
                        this.locations = filterPipe.transform(res, 'locationName', false);

                        this.checkSheduleCollectPermission();
                        this.generatCommunicationArgs(this.toggleState);
                    } else {
                        // disable schedule button if there are no active locations
                        this.disableSchedule = true;

                        // detect change detector reference
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                } else {
                    // activate schedule button
                    this.disableSchedule = false;
                    // detect change detector reference
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
                // set location loading state value to hide loader
                this.locationsLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            () => {
                // set location loading state value to display loader
                this.locationsLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(subscription);
    }

    private checkSheduleCollectPermission() {
        // to check schedule collection permission
        this.subscriptions.push(
            this.usersService.isScheduleCollectAllowed.subscribe((response) => {
                this.disableSchedule = this.isScheduleCollectAllowed = response;
                // detect change detector reference
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }),
        );
    }

    public collectLocation(locationID: number) {
        let handleBackCollect = true;
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // get updated customer id
            const currentCustomerID = Number(params.get(customerQueryParam));

            this.subscriptions.push(
                this.locationService.getLocationDetails(currentCustomerID, locationID).subscribe((result) => {
                    let lastCollectDate;
                    const dateFormat = this.dateutilService.dateFormat.getValue().toUpperCase();
                    if (result) {
                        if (dateFormat === 'MM/DD/YYYY' || dateFormat === 'YYYY/MM/DD') {
                            lastCollectDate = new Date(result.lastCollectedDate);
                        } else if (dateFormat === 'DD/MM/YYYY') {
                            lastCollectDate = new Date(
                                String(result.lastCollectedDate).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'),
                            );
                        }
                    } else {
                        lastCollectDate = '';
                    }
                    if (handleBackCollect) {
                        handleBackCollect = false;
                        this.dialog
                            .open(DataCollectionComponent, {
                                disableClose: true,
                                data: lastCollectDate,
                            })
                            .afterClosed()
                            .subscribe((response) => {
                                // if response true that denoted that collect button press from data-collection will be called
                                if (response && response.success && response.startDate && response.endDate) {
                                    const startdate = new Date(
                                        response.startDate.getTime() - response.startDate.getTimezoneOffset() * 60000,
                                    ).toISOString();
                                    // moment.utc(response.startDate).format('YYYY-MM-DD HH:mm:ss');
                                    const endDate = new Date(
                                        response.endDate.getTime() - response.endDate.getTimezoneOffset() * 60000,
                                    ).toISOString();
                                    // moment.utc(response.endDate).format('YYYY-MM-DD HH:mm:ss');
                                    this.isCollecting = true;
                                    const collectSubscription = this.collectService
                                        .collectLocation(
                                            this.customerID,
                                            locationID,
                                            startdate.split('.')[0],
                                            endDate.split('.')[0],
                                        )
                                        .subscribe(
                                            () => {
                                                this.isCollecting = false;
                                                this.snackBarNotificationService.raiseNotification(
                                                    this.collectInitSuccessMsg,
                                                    this.actionLabel,
                                                    { duration: 10000 },
                                                );
                                                this.collectionDetailsData.forEach((data) => {
                                                    if (data.locationId === locationID && response.success) {
                                                        data.collectInProgress = true;
                                                        this.uiUtilsService.safeChangeDetection(this.cdr);
                                                    }
                                                });
                                                this.collectionWidgetData.forEach((data) => {
                                                    if (data.locationid === locationID && response.success) {
                                                        data.collectinprogress = true;
                                                        this.uiUtilsService.safeChangeDetection(this.cdr);
                                                    }
                                                });
                                            },
                                            (err: HttpErrorResponse) => {
                                                this.isCollecting = false;
                                                if (err.status === 400) {
                                                    this.snackBarNotificationService.raiseNotification(
                                                        this.invalidCollectionMessage,
                                                        this.actionLabel,
                                                        {
                                                            panelClass: 'custom-error-snack-bar',
                                                        },
                                                        false,
                                                    );
                                                    return;
                                                }
                                                this.subscriptions.push(
                                                    this.statusCodeService.statusCode409.subscribe(
                                                        (statusResponse: boolean) => {
                                                            if (statusResponse) {
                                                                /* let simpleSnackBarRef = this.snackBar.open('Location(s) already scheduled for collect', 'Dismiss');
                                                        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 3000); */
                                                                this.snackBarNotificationService.raiseNotification(
                                                                    this.locationScheduleMessage,
                                                                    this.actionLabel,
                                                                    {
                                                                        panelClass: 'custom-error-snack-bar',
                                                                    },
                                                                    false,
                                                                );
                                                            }
                                                        },
                                                    ),
                                                );
                                            },
                                        );
                                    this.subscriptions.push(collectSubscription);
                                }
                            });
                    }
                    // this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }),
            );
        });
    }
    public scheduleCollection() {
        // default value
        this.sharedService.setCloseFlagValue(false);
        this.disableSchedule = false;
        const locationArgs = <LocationArgs>{
            customerId: this.customerID,
        };

        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        // Fetch all the locations for selected customer for schedule menu
        const subscriptionLocations = this.usersService.getLocationsList(locationArgs).subscribe(
            (locations: Array<Locations>) => {
                if (locations && locations.length > 0) {
                    // filter the locations have monitoring point(MP) is not equal to 2
                    // because scheduling the MP1 monitor collects both monitoring points.
                    locations = locations.filter((location: Locations) => location.monitoringPoint !== 2);

                    if (activeInactiveHandler) {
                        locations = locations.filter((x) => x.viewable);
                    } else {
                        locations = locations.filter(
                            (x) =>
                                x.viewable &&
                                (x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance),
                        );
                    }
                    const subscripton = this.scheduleCollectionService.getScheduleCollection(this.customerID).subscribe(
                        (result) => {
                            this.schedules = result;
                            this.dialog
                                .open(CollectionWidgetScheduleComponent, {
                                    disableClose: true,
                                    data: {
                                        locations: locations.filter(
                                            (x) =>
                                                x.viewable &&
                                                (x.status === LocationStatus.Active ||
                                                    x.status === LocationStatus.Maintenance),
                                        ),
                                        schedules: this.schedules,
                                    },
                                })
                                .afterClosed()
                                .subscribe((res) => {
                                    const subscribe = this.sharedService.currentValue.subscribe(
                                        (response: boolean) => {
                                            if (response) {
                                                this.loadScheduleCollection();
                                            }
                                        },
                                        (error) => {
                                            // handle error if required
                                        },
                                    );
                                    this.subscriptions.push(subscribe);
                                    // activate schedule button
                                    this.disableSchedule = true;

                                    // detect change detector reference
                                    this.uiUtilsService.safeChangeDetection(this.cdr);
                                    this.checkSheduleCollectPermission();
                                });
                        },
                        (error) => {
                            // handle error if required
                        },
                    );
                    this.subscriptions.push(subscripton);
                }
            },
            (error) => {
                // handle error if required
            },
        );
        this.subscriptions.push(subscriptionLocations);
    }

    public loadScheduleCollection() {
        const subscription = this.scheduleCollectionService.getScheduleCollection(this.customerID).subscribe(
            (result) => {
                this.schedules = result;
            },
            (error) => this.handleError(error),
        );
        this.subscriptions.push(subscription);
    }

    private handleError(error) {
        this.schedules = [];
    }

    private getCollectionHistory(params: GetCollectHistoryArgs) {
        this.collectionWidgetHistory = null;
        const subscriptionGetCollectionHistory = this.scheduleCollectionService
            .getCollectionHistory(params)
            .pipe(debounceTime(1000))
            .subscribe(
                (result) => {
                    this.collectionWidgetHistory = result || [];
                    this.scheduleCollectionService.collectionHistory = this.collectionWidgetHistory;

                    /**
                     * Below Logic will emit the collection widget locations to parent component(i.e.landing page)
                     * to display the locations on map.
                     */
                    if (this.collectionAPIState) {
                        this.showMapLocationForCollectionWidget.emit(
                            this.collectionWidgetHistory.map((element) => element.locationid),
                        );
                    }

                    this.collectionAPIState = false;
                    this.locationsLoadingState = false;
                    this.generateCollectionWidgetTable();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.locationsLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );

        this.subscriptions.push(subscriptionGetCollectionHistory);
    }
    private getCollectionDetails(params?) {
        this.locationsLoadingState = true;
        this.collectionDetailsDataSource = null;
        const subscriptionGetCollectionDetails = this.scheduleCollectionService
            .getCollectionDetails(this.startPage, this.pageSize, params)
            .subscribe(
                (result: any) => {
                    this.locationsLoadingState = false;
                    this.collectionWidgetDetails = [];
                    if (result) {
                        this.collectionWidgetDetails = result.dataCommunicationDetails;
                        this.totalPaginationLength = result.count;
                    }
                    this.generateCollectionDetailsTable();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.locationsLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );

        this.subscriptions.push(subscriptionGetCollectionDetails);
    }

    public notifyWidget(filtersData: WidgetFilterData) {
        const params = <GetCollectHistoryArgs>{
            customerID: this.customerID,
            StartTime: filtersData.startDate,
            EndTime: filtersData.endDate,
            IncludeInactiveLocations: this.includeInactiveLocations,
            LocationGroupID: this.locationGroupID || 0,
            Status: 3,
            includeTcpConnections: true,
        };
        if (filtersData.locationIDs && filtersData.locationIDs.length) {
            params.LocationIDs = filtersData.locationIDs;
        }
        if (filtersData.monitorIds && filtersData.monitorIds.length) {
            params.monitorIds = filtersData.monitorIds;
        }
        if (!this.toggleState) {
            this.getCollectionDetails(params);
        } else {
            this.getCollectionHistory(params);
        }
    }

    public loadMapLocations() {
        if (this.toggleState) {
            this.showMap.emit(true);
        }
    }
    public generateCollectionDetailsTable() {
        this.collectionDetailsDataChange = new BehaviorSubject<CollectionDetails[]>([]);
        if (this.collectionWidgetDetails.length >= 0) {
            this.locationsLoadingState = true;
            for (const collectionDetailsItem of this.collectionWidgetDetails) {
                const {
                    locationId,
                    locationName,
                    lastCollectedDate,
                    lastFailedCollection,
                    lastFailedReason,
                    lastManualCollection,
                    lastScheduledCollection,
                    monitorSeries,
                    nextScheduledCollection,
                    successPercent,
                    totalAttempts,
                    failureAttempts,
                    collectInProgress,
                } = collectionDetailsItem;
                const data = {
                    locationId,
                    locationName,
                    lastCollectedDate,
                    lastFailedCollection,
                    lastFailedReason,
                    lastManualCollection,
                    lastScheduledCollection,
                    monitorSeries,
                    nextScheduledCollection,
                    successPercent,
                    totalAttempts,
                    failureAttempts,
                    collectInProgress,
                };
                const collectionDetailsCopiedData = this.collectionDetailsData.slice();
                collectionDetailsCopiedData.push(data);
                this.collectionDetailsDataChange.next(collectionDetailsCopiedData);
            }
            this.collectionDetailsDataSource = new MatTableDataSource(this.collectionDetailsData);
            this.collectionDetailsDataSource.sort = this.collectionWidgetLongTableSort;

            this.collectionDetailsDataSource.sortingDataAccessor = (item, property) => {
                switch (property) {
                    case LAST_COLLECTED_DATE: return item.lastCollectedDate ? new Date(item.lastCollectedDate) : null;
                    case LAST_MANUAL_COLLECTION: return item.lastManualCollection ? new Date(item.lastManualCollection) : null;
                    case LAST_FAILED_COLLECTION: return item.lastFailedCollection ? new Date(item.lastFailedCollection) : null;
                    default: return item[property];
                }
            };


            this.locationsLoadingState = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.totalPaginationLength = 1;
        }
        this.showErrorMessage = true;
    }
    public generateCollectionWidgetTable() {
        this.collectionWidgetDataSource = null;
        this.collectionWidgetDataChange = new BehaviorSubject<CollectionHistory[]>([]);
        if (this.collectionWidgetHistory && this.collectionWidgetHistory.length > 0) {
            this.locationsLoadingState = true;
            for (const collectionHistoryItem of this.collectionWidgetHistory) {
                const { locationid, locationname, lastcollecteddate, failedcollects30days, collectinprogress } =
                    collectionHistoryItem;
                const data = {
                    locationid,
                    locationname,
                    lastcollecteddate,
                    failedcollects30days,
                    collectinprogress,
                };
                const collectionWidgetCopiedData = this.collectionWidgetData.slice();
                collectionWidgetCopiedData.push(data);
                this.collectionWidgetDataChange.next(collectionWidgetCopiedData);
            }

            this.collectionWidgetPaginator.pageIndex = 0;

            this.collectionWidgetDataSource = new MatTableDataSource(this.collectionWidgetData);
            this.collectionWidgetDataSource.sortingDataAccessor = (item, property) => {
                switch (property) {
                    case 'lastcollecteddate': return item.lastcollecteddate ? new Date(item.lastcollecteddate) : null;
                    default: return item[property];
                }
            };
            this.displayedData.emit(this.collectionWidgetData.map((x) => x.locationid));
            this.totalPaginationLength = this.collectionWidgetData.length;
            this.collectionWidgetDataSource.paginator = this.collectionWidgetPaginator;
            this.collectionWidgetDataSource.sort = this.collectionWidgetSort;
            this.locationsLoadingState = false;
            this.emitWidgetData.emit(this.collectionWidgetDataSource.data);
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.totalPaginationLength = 1;
        }
        this.showErrorMessage = true;
    }

    public onPageChange(event): void {
        if (event) {
            this.startPage = event.pageIndex + 1;
            this.pageSize = event.pageSize;
        }
        this.generatCommunicationArgs();
    }
    public collect() {
        //  let status = new BehaviorSubject<boolean>(false);

        // track response code for 409
        let flag = false;
        for (const item of this.collectionWidgetHistory) {
            this.locationsLoadingState = true;
            this.collectService.collectAllLocation(this.customerID, item.locationid).subscribe(
                (response) => {
                    this.locationsLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.locationsLoadingState = false;
                    // detect change detector reference
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.subscriptions.push(
                        this.statusCodeService.statusCode409.subscribe((response: boolean) => {
                            if (response && !flag) {
                                flag = true;
                                this.snackBar.open(this.snackbarMessage, this.dismissBtn, {
                                    panelClass: 'custom-error-snack-bar',
                                });
                            }
                        }),
                    );
                },
            );
        }
        // snack bar display only once
        flag = false;
    }

    public collectAllSchedule() {
        this.locationsLoadingState = true;
        this.collectService.collectAllSchedule(this.customerID).subscribe(
            (response) => {
                this.generatCommunicationArgs(true);
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.locationsLoadingState = false;
                // detect change detector reference
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.subscriptions.push(
                    this.statusCodeService.statusCode409.subscribe((response: boolean) => {
                        if (response) {
                            this.snackBar.open(this.snackbarMessage, this.dismissBtn, {
                                panelClass: 'custom-error-snack-bar',
                            });
                        }
                    }),
                );
            },
        );
    }

    public getMarkerLocationDetails(locationId: number) {
        this.locationService.locationId = locationId;
    }

    /**
     * //checks if the name length is more than specific length
     */
    public validateLocationNameLength(str: string, strLength: number): boolean {
        if ((str + '').length > strLength) {
            return true;
        } else {
            return false;
        }
    }
    public downloadCSV() {
        const csvData = [];
        const dateFormat = `${String(this.dateutilService.getFormat()).toUpperCase()}`;
        const startDate = this.dateutilService.getPreviousDate(new Date(), 1);
        const csvHeaders = [
            this.locationHeader,
            this.monitorHeader,
            this.failedAttemptHeader,
            this.totalAttemptHeader,
            this.successAttemptHeader,
            this.lastCollectedHeader,
            this.lastScheduledHeader,
            this.lastManualHeader,
            this.lastFailedHeader,
            this.lastFailedReasonHeader,
            this.nextScheduledHeader,
        ];
        if (this.collectionWidgetDetails && this.collectionWidgetDetails.length > 0) {
            this.collectionWidgetDetails.map((arrayItem) => {
                if (arrayItem) {
                    csvData.push([
                        arrayItem.locationName,
                        arrayItem.monitorSeries,
                        arrayItem.failureAttempts,
                        arrayItem.totalAttempts,
                        arrayItem.successPercent,
                        arrayItem.lastCollectedDate,
                        arrayItem.lastScheduledCollection,
                        arrayItem.lastManualCollection,
                        arrayItem.lastFailedCollection,
                        arrayItem.lastFailedReason ? arrayItem.lastFailedReason : '-',
                        arrayItem.nextScheduledCollection,
                    ]);
                }
            });
        }
        const options = {
            showLabels: true,
            headers: csvHeaders,
            title: `Data Communication ${moment(startDate).format(dateFormat)}`,
            showTitle: true,
        };
        const result = new AngularCsv(csvData, 'Data Communication Report', options);
    }

    public navigateToScheduleCollectDashboard(locationId?: number) {
        const queryParamsItems = { c: this.customerID };
        if (locationId) {
            const scheduleSubscription = this.scheduleCollectionService
                .getScheduleByLocationId(this.customerID, locationId)
                .subscribe(
                    (response) => {
                        if (response && response.scheduleId) {
                            const editSubscription = this.dialog
                                .open(AdsPrismAddScheduleComponent, {
                                    disableClose: true,
                                    data: {
                                        schedules: this.allSchedules,
                                        customerId: this.customerID,
                                        locationId: locationId,
                                        selectedScheduleId: response.scheduleId,
                                        isEditMode: response.scheduleId ? true : false,
                                    },
                                    autoFocus: false
                                })
                                .afterClosed()
                                .subscribe((res) => {
                                    if (res.success) {
                                    }
                                });
                            this.subscriptions.push(editSubscription);
                        } else {
                            const editSubscription = this.dialog
                                .open(AdsPrismAddScheduleComponent, {
                                    disableClose: true,
                                    data: {
                                        schedules: this.allSchedules,
                                        customerId: this.customerID,
                                        locationId: locationId,
                                        isEditMode: false,
                                    },
                                    autoFocus: false
                                })
                                .afterClosed()
                                .subscribe((res) => {
                                    if (res.success) {
                                    }
                                });
                            this.subscriptions.push(editSubscription);
                        }
                    },
                    () => {
                        const editSubscription = this.dialog
                            .open(AdsPrismAddScheduleComponent, {
                                disableClose: true,
                                data: {
                                    schedules: this.allSchedules,
                                    customerId: this.customerID,
                                    locationId: locationId,
                                    isEditMode: false,
                                },
                                autoFocus: false
                            })
                            .afterClosed()
                            .subscribe((res) => {
                                if (res.success) {
                                }
                            });
                        this.subscriptions.push(editSubscription);
                    },
                );
            this.subscriptions.push(scheduleSubscription);
        } else {
            this.router.navigate([this.scheduleCollectDashboardURL], {
                queryParams: queryParamsItems,
            });
        }
    }
}
