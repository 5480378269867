import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../shared/services/config';
import { BPReportData } from 'app/shared/models/blockage-prediction';

@Injectable({
    providedIn: 'root'
})

export class BlockagePredictService {
    constructor(private http: HttpClient) {}

    getBPdailyData(date: string) {
        let url = Config.urls.blockagePredictionDaily;
        url += `?date=${date}`

        return this.http.get<BPReportData>(url);
    }
}

