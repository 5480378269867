import { DailyReportNotificationsComponent } from './pages/dashboards/notification-dashboard/daily-report-notifications/daily-report-notifications.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from 'app/public/welcome/welcome.component';
import { HomeMapDashboardComponent } from 'app/homemapdashboard/home-map-dashboard.component';
import { MainComponent, MainResolverService } from 'app/main';
import { CustomerEditorComponent } from 'app/pages/customer-editor/customer-editor.component';
import { EditCustomerEditorComponent } from './pages/customer-editor/edit-customer-editor/edit-customer-editor.component';
import { ChangePasswordComponent } from './public/change-password/change-password.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { AdminUsersComponent } from './pages/admin/admin-users';
import { CustomerUserAssociationComponent } from './pages/admin/customer-user-association/customer-user-association.component';
import { AdminCustomerComponent } from './pages/admin/admin-customer/admin-customer.component';
import { ErrorReportComponent } from './pages/admin/error-report/error-report.component';
import { CollectionWidgetComponent } from './pages/collection-widget/collection-widget.component';
import { AdsCustomDashboardComponent } from './pages/dashboards/custom-dashboard/custom-dashboard.component';
import { UpdatesWidgetGuard } from './pages/sliicer/shared/components/updates-widget/updates-widget.guard';
import { SliicerCaseStudyDetailsComponent } from './pages/sliicer/sliicer-case-study-details/case-study-details.component';
import { SliicerDashboardComponent } from './pages/sliicer/sliicer-dashboard/sliicer-dashboard.component';
import {
    VaultDashboardComponent,
    VaultTelemetryExportModalComponent,
    VaultTelemetryExportResolverService,
} from './pages/vault';
import { LocationDashboardComponent } from './pages/dashboards/location-dashboard/location-dashboard.component';
import { ViewDataLocationDetailsComponent } from './pages/view-data/view-data-location-details/view-data-location-details.component';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';
import { ANSRAlarmComponent } from './pages/dashboards/notification-dashboard/ansr-alarm/ansr-alarm.component';
import { NotificationDashboardComponent } from './pages/dashboards/notification-dashboard/notification-dashboard.component';
import { StandardAlarmComponent } from './pages/dashboards/notification-dashboard/standard-alarm/standard-alarm.component';
import { ScheduleCollectOverviewComponent } from './pages/dashboards/schedule-collect-dashboard';
import { SiteCommentaryReportComponent } from './pages/report/site-commentary-report/site-commentary-report.component';
import { LocationFlowBalanceReportComponent } from './pages/report/location-flow-balance-report/location-flow-balance-report.component';
import { FlowBalanceReportComponent } from './pages/report/flow-balance-report/flow-balance-report.component';
import { AuditComponent } from './pages/audit/audit.component';
import { PercentFullDetailsComponent, PercentFullOverviewComponent } from './pages/report/percent-full-report';
import { RainFallFrequencyOverviewComponent } from './pages/report/rain-fall-frequency-report';
import { UptimeDetailsComponent, UptimeOverviewComponent } from './pages/report/uptime-report';
import { DailySummaryDetailsComponent } from './pages/report/daily-summary-report';
import { AdsDataEditingAuditReportComponent } from './pages/report/data-editing-audit-report/data-editing-audit-report.component';
import { DailySummaryOverviewComponent } from './pages/report/daily-summary-report/daily-summary-overview/daily-summary-overview.component';
import { SliicerDeactivateGuard } from './shared/services/sliicer-deactivate.guard';
import { AnnouncementComponent } from './pages/admin/alert/Alert.component';
import { LocationConfigurationReportComponent } from './pages/report/location-configuration-report/location-configuration-report.component';
import { DailyRainfallReportComponent } from './pages/report/daily-rainfall-report/daily-rainfall-report.component';
import { LocationNotificationConfigurationComponent } from './pages/report/location-notification-configuration/location-notification-configuration.component';
import { LoginGuard } from './shared/services/login.guard';
import { BPDailySummaryComponent } from './pages/blockage-predict/bp-daily-summary/bp-daily-summary.component';
import { HumanReviewComponent } from './pages/blockage-predict/human-review-container/human-review-container.component';
import { FlowMonitoringReportComponent } from './pages/report/flow-monitoring-report/flow-monitoring-report.component';
import { FlowMonitoringDetailsComponent } from './pages/report/flow-monitoring-report/flow-monitoring-details/flow-monitoring-details.component';


const appRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        component: MainComponent,
        resolve: { 'app-data': MainResolverService },
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: 'dashboard',
                component: HomeMapDashboardComponent,
            },
            {
                path: 'pages/customerEditor',
                component: CustomerEditorComponent,
            },
            {
                path: 'pages/customerEditor/details',
                component: EditCustomerEditorComponent,
            },
            {
                path: 'pages',
                children: [
                    {
                        path: 'admin',
                        component: AdminUsersComponent,
                    },
                    {
                        path: 'customerUserManager',
                        component: AdminUsersComponent,
                    },
                    {
                        path: 'adminalert',
                        component: AnnouncementComponent,
                    },
                    {
                        path: 'admin/details',
                        component: CustomerUserAssociationComponent,
                    },
                    {
                        path: 'customers',
                        component: AdminCustomerComponent,
                    },
                    {
                        path: 'error',
                        component: ErrorReportComponent,
                    },
                    {
                        path: 'menuDashboard',
                        children: [
                            { path: '', redirectTo: 'viewData', pathMatch: 'full' },
                            {
                                path: 'viewData',
                                children: [
                                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                                    {
                                        path: 'overview',
                                        component: LocationDashboardComponent,
                                    },
                                ],
                            },
                            {
                                path: 'locationFlowBalance',
                                component: LocationFlowBalanceReportComponent,
                            },
                            {
                                path: 'notifications',
                                children: [
                                    { path: '', component: NotificationDashboardComponent },
                                    { path: 'ansr', component: ANSRAlarmComponent },
                                    { path: 'standard', component: StandardAlarmComponent },
                                    { path: 'daily', component: DailyReportNotificationsComponent },
                                ],
                            },
                            {
                                path: 'schedule-collect',
                                children: [
                                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                                    {
                                        path: 'overview',
                                        component: ScheduleCollectOverviewComponent,
                                    },
                                ],
                            },
                            {
                                path: 'custom-dashboard',
                                children: [
                                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                                    {
                                        path: 'overview',
                                        component: AdsCustomDashboardComponent,
                                    },
                                    // {
                                    //   path: ':id/details',
                                    //   component: ScheduleCollectDetailsComponent
                                    // }
                                ],
                            },
                        ],
                    },
                    {
                        path: 'vault',
                        children: [
                            {
                                path: '',
                                redirectTo: 'list',
                                pathMatch: 'full',
                            },
                            {
                                path: 'list',
                                component: VaultDashboardComponent,
                                children: [
                                    {
                                        path: 'telemetry-export',
                                        component: VaultTelemetryExportModalComponent,
                                        resolve: {
                                            'vault-telemetry-export-resolver': VaultTelemetryExportResolverService,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'viewLocationDetails',
                        component: ViewDataLocationDetailsComponent,
                    },
                    {
                        path: 'viewData/advancedHydrograph',
                        component: LocationDashboardComponent,
                    },
                    {
                        path: 'customDashboard',
                        component: AdsCustomDashboardComponent,
                    },
                    {
                        path: 'viewDataDynamic',
                        redirectTo: 'menuDashboard/viewData',
                    },
                    {
                        path: 'datacomm/:report',
                        component: CollectionWidgetComponent,
                    },
                    {
                        path: 'sliicer',
                        children: [
                            {
                                path: '',
                                component: SliicerDashboardComponent,
                            },
                            {
                                path: 'details',
                                component: SliicerCaseStudyDetailsComponent,
                                canDeactivate: [SliicerDeactivateGuard]
                            },
                        ],
                    },
                    {
                        path: 'report',
                        children: [
                            { path: '', redirectTo: 'daily-summary', pathMatch: 'full' },
                            {
                                path: 'daily-summary',
                                children: [
                                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                                    {
                                        path: 'overview',
                                        component: DailySummaryDetailsComponent, // Updated to hide overview
                                        data: { key: 'dailySummaryOverview' },
                                    },
                                    {
                                        path: ':id/details',
                                        component: DailySummaryDetailsComponent,
                                    },
                                ],
                            },
                            {
                                path: 'data-editing-audit',
                                component: AdsDataEditingAuditReportComponent,
                            },
                            {
                                path: 'uptime',
                                children: [
                                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                                    {
                                        path: 'overview',
                                        component: UptimeOverviewComponent,
                                        data: { key: 'uptimeOverview' },
                                    },
                                    {
                                        path: ':id/details',
                                        component: UptimeDetailsComponent,
                                    },
                                ],
                            },
                            {
                                path: 'percent-full',
                                children: [
                                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                                    {
                                        path: 'overview',
                                        component: PercentFullOverviewComponent,
                                    },
                                    {
                                        path: ':id/details',
                                        component: PercentFullDetailsComponent,
                                    },
                                ],
                            },
                            {
                                path: 'rainFallReport',
                                component: RainFallFrequencyOverviewComponent,
                            },
                            {
                                path: 'flowBalance',
                                component: FlowBalanceReportComponent,
                            },
                            {
                                path: 'locationConfiguration',
                                component: LocationConfigurationReportComponent,
                            },
                            {
                                path: 'flowMonitoringReport',
                                children: [
                                    { path: 'flowMonitoringReport_V1', component: SiteCommentaryReportComponent },
                                    { path: 'flowMonitoringReport_V2', component: FlowMonitoringReportComponent },
                                    { path: '', redirectTo: 'flowMonitoringReport_V1', pathMatch: 'full' },
                                    {
                                        path: 'details',
                                        component: FlowMonitoringDetailsComponent,
                                    },
                                ]
                            },
                            {
                                path: 'daily-rain-report',
                                component: DailyRainfallReportComponent,
                            },
                            {
                                path: 'audit',
                                component: AuditComponent,
                            },
                            {
                                path: 'notificationConfiguration',
                                component: LocationNotificationConfigurationComponent,
                            },
                        ],
                    },
                    {
                        path: 'blockage-predict',
                        children: [
                            { path: 'daily-summary', component: BPDailySummaryComponent },
                            { path: 'human-review', component: HumanReviewComponent },
                            { path: '', redirectTo: 'daily-summary', pathMatch: 'full' },
                        ]
                    }
                ],
            },
        ],
    },
    { path: 'welcome', component: WelcomeComponent, canActivate: [LoginGuard] },
    { path: 'reset', component: ChangePasswordComponent },

    { path: '**', redirectTo: '' },
];

export const routing: ModuleWithProviders<NgModule> = RouterModule.forRoot(appRoutes, {
    onSameUrlNavigation: 'reload',
});
