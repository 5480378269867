import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-approve-data-dialog',
    templateUrl: './approve-data-dialog.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveDataDialogComponent implements OnInit {

       public startDate: Date;
       public endDate: Date;
       public prevApprStartDate: Date;
       public prevApprEndDate: Date;
       public approvedUserName: string;
       public locationName: string;
       public isErrorOnApproval: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ApproveDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {

    }

    ngOnInit() {
        this.endDate =  this.data.endDate;
        this.startDate = this.data.startDate;
        this.prevApprStartDate = this.data.prevApprStartDate;
        this.prevApprEndDate = this.data.prevApprEndDate;
        this.approvedUserName = this.data.approvedBy;
        this.locationName = this.data.locationName;
    }

    public startDateChanged(startDate : Date) {
        this.startDate = startDate;
    }

    public endDateChanged(endDate : Date) {
        this.endDate = endDate;
    }

    public errorOnApproveData(isError: boolean) {
        this.isErrorOnApproval = isError;
    }

    public submit() {
        this.dialogRef.close({startDate : this.startDate, endDate : this.endDate});
    }
}
