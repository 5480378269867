import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './config';
import { FlowMonitorExport } from 'app/pages/report/site-commentary-report/site-commentary.model';
import { FlowMonitoringConfigRequest, FlowMonitoringConfigResponse, FlowMonitoringReport } from 'app/shared/models/flow-monitoring-config'
import { Observable } from 'rxjs';

export const ERROR_NAME_ALREADY_EXIST = 'Name already exists';

@Injectable()
export class FlowMonitoringService {
    constructor(private http: HttpClient) {}

    readonly FLOW_MONITORING_CONFIG_SUBJECT = 'FMR';

    /**Method to make API call to export flowmonitoring settings
     * Accepts @param exportReuqestObject
     */
    public exportFlowMonitoringReport(exportReuqestObject: FlowMonitorExport) {
        return this.http.post<FlowMonitorExport>(Config.urls.flowMonitoring, exportReuqestObject);
    }

    public getFMRTemplates(customerId: number) {
        const reqUrl = `${Config.urls.flowMonitoringTemplates}?customerId=${customerId}`;
        return this.http.get<FlowMonitoringReport[]>(reqUrl);
    }

    public saveFMRTemplate(req: FlowMonitoringReport) {
        const reqUrl = `${Config.urls.saveMonitoringTemplate}`;
        return this.http.post<boolean>(reqUrl, req);
    }

    private configurationsBaseURL(customerId) {
        return `${Config.urls.FlowMonitoringConfig}/${customerId}/`;
    }

    private configurationPartitionSufix(customerId) {
        return `_${customerId}`;
    }

    private configurationsURL(customerId) {
        return `${this.configurationsBaseURL(customerId)}${this.FLOW_MONITORING_CONFIG_SUBJECT}/${this.configurationPartitionSufix(customerId)}`;
    }

    public configurationsGet(customerId: number) {
        return this.http.get<FlowMonitoringConfigResponse[]>(`${this.configurationsURL(customerId)}`);
    }

    public configurationsDelete(customerId: number, confId: number) {
        return this.http.delete(`${this.configurationsURL(customerId)}/${confId}`);
    }

    public configurationsPost(customerId: number, req: FlowMonitoringConfigRequest): Observable<FlowMonitoringConfigResponse> {
        const body = {
            configType: `${this.FLOW_MONITORING_CONFIG_SUBJECT}`,
            partitionSuffixFilter: this.configurationPartitionSufix(customerId),
            ...req
        }
        return this.http.post<FlowMonitoringConfigResponse>(`${this.configurationsBaseURL(customerId)}`, body);
    }

    public configurationsRecentGet(customerId: number) {
        return this.http.get<FlowMonitoringConfigResponse[]>(`${this.configurationsURL(customerId)}/recent`);
    }

    public configurationsRecentPost(customerId: number, configId: string) {
        return this.http.post(`${this.configurationsURL(customerId)}/recent/${configId}`, {});
    }
}
