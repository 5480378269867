import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    OnDestroy,
    ElementRef,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DailySummaryReportService } from 'app/pages/report/daily-summary-report';
import { UptimeReportService } from 'app/pages/report/uptime-report';
import { Coordinate } from 'app/shared/models/coordinate';
import {
    activeInactiveLocationQueryParam,
    AppQueryParams,
    Customer,
    customerLocationGroupQueryParam,
    customerQueryParam,
    locationIdQueryParam,
    referringLocationIdQueryParam,
    reloadCacheQueryParam,
} from 'app/shared/models/customer';
import { DailySummaryDetailsReportArgs, IDailySummaryLocationDetail } from 'app/shared/models/daily-summary';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { LayerNameOpts, MapType, MapTypeStyle } from 'app/shared/models/map-type';
import { ScheduleCollection } from 'app/shared/models/schedule-collection';
import { UptimeDetailsReportArgs } from 'app/shared/models/uptime';
import { CollectService } from 'app/shared/services/collect.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { LocationService } from 'app/shared/services/location.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { Subscription, combineLatest, of, forkJoin } from 'rxjs';
import { Observable } from 'rxjs';

import OLMap from 'ol/Map';


import { CustomerDetailLocationService } from '../../../shared/services/customer-detail-location.service';
import { StatusCodeService } from '../../../shared/services/status-code.service';
import { UiUtilsService } from '../../../shared/utils/ui-utils.service';
import { UsersService } from '../../admin/users.service';
import { CollectionWidgetScheduleComponent } from '../../collection-widget-schedule/collection-widget-schedule.component';
import { DataCollectionComponent } from '../../data-collection/data-collection.component';
import { AddEditPlottingConfirmationComponent } from '../add-edit-plotting-confirmation/add-edit-plotting-confirmation.component';
import { CalculatedBaseEntity, CalculatedEntityDetails } from '../calculated-entity/calculated-entity.model';

const LOCATION_DETAIL_HINT = 'LOCATION_DETAIL_HINT';

import {
    LocationDetailsModel,
    MonitorSeriesUI,
    CustomerUnits,
    INSTALLATION_TYPE,
    LocationDetails,
    FlowBalanceDetails,
    LocationUIPipeTable,
    ConfirmationReportUIRow,
    ConfirmationUnitsAndAverages,
    DEFAULT_AVERAGE_TO_PEAK,
} from 'app/shared/models/location-details';
import { DomOperationUtilsService } from '../../../shared/utils/dom-operation-utils.service';

import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import { DeviceTypeCode } from 'app/shared/enums/device-type-code';
import { DownloadConfirmationsDialogComponent } from 'app/shared/components/download-confirmations-dialog/download-confirmations-dialog.component';
import { distinctUntilChanged, skip, first, tap, catchError, take } from 'rxjs/operators';

import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import { BasemapNames, GISService } from 'app/shared/services/gis-service';
import { all as allStrategy } from 'ol/loadingstrategy';
import { darkThemeColors, lightThemeColors, monitorLocationStyle } from 'app/shared/constant/mapStyle.constant';
import { gisUserSettings, BaseMapListItem, userSettingLayerDetails } from 'app/shared/models/gis-service-list';
import {
    OLMAPPROJECTION,
    UnitOfMeasureType,
    GPD_TO_MPD_DIVIDER,
    RAIN_DEFAULT_INSTALLATION_TYPE,
    DEPTH_ENTITY,
    ELEVATION_ENTITY,
    RAIN_ENTITY,
    QUANTITY_ENTITY,
    VELOCITY_ENTITY,
    Series
} from 'app/shared/constant';
import Geolocation from 'ol/Geolocation';
import { Group as LayerGroup, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Cluster, XYZ, Vector as VectorSource, TileWMS, TileArcGISRest } from 'ol/source';
import { Config } from 'app/shared/services/config';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import TileState from 'ol/TileState';
import { fromLonLat } from 'ol/proj';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { MapService } from 'app/shared/services/map.service';
import { CalculatedEntitiesService } from 'app/shared/services/calculated-entities.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { ConfirmationService, CONFIRMATION_ROWS_IN_SUMMARY } from 'app/shared/services/confirmation.service';
import { MonitorDiagnosticsService } from 'app/shared/services/monitor-diagnostics.service';
import { Selectable } from 'app/shared/models/selectable';
import { PercentFullData, PercentFullReportArgs } from 'app/shared/models/percent-full';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { LocationEntitiesData } from 'app/shared/models/locations-entities-data';
import { ContactUsComponent } from 'app/shared/components/contact-us/contact-us.component';
import { AdsPrismAddScheduleComponent, SchedulingElement } from 'app/pages/dashboards/schedule-collect-dashboard';
import { PercentFullReportService } from 'app/pages/report/percent-full-report';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { formatNumber } from '@angular/common';
import { UnitsService } from 'app/shared/services/units.service';
import { ApiUnitsRow, UnitCategoryType } from 'app/shared/models/units';
import { DrawPipeHelper } from 'app/shared/helpers/draw-pipe.helper';
import { ConfirmationPoint } from 'app/shared/models/confirmation-points';
import moment from 'moment';
import { MonitorSeries, MonitorSeriesNames } from 'app/shared/components/location-card/location-card.constants';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { VelocityGainTableComponent, VelocityGainTableComponentResponse } from 'app/shared/components/velocity-gain-table/velocity-gain-table.component';
import { VelocityGainDialogData } from 'app/shared/models/gain-data';
import { apply } from 'ol-mapbox-style';

const CALCULATED_GAIN = 'CALCULATED_GAIN';

@Component({
    selector: 'app-view-data-location-details',
    templateUrl: './view-data-location-details.component.html',
    styleUrls: ['./view-data-location-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDataLocationDetailsComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) public calcualtedEntityPaginator: MatPaginator;
    @ViewChild(MatSort) public calcualtedEntitySort: MatSort;
    @ViewChild('pipeCanvas') public pipeCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild(MatPaginator) public confirmationPaginator: MatPaginator;

    /*
     *Map Parameter
     */
    public olMap: Map;
    public olView: View;
    public projection = OLMAPPROJECTION;
    public AdsMonitorLayerSource: VectorSource;
    public AdsMonitorLayerSourceCL: Cluster;
    public AdsMonitorLayer: VectorLayer;
    public scaleLinecontrol: ScaleLine;
    public activatebasemap = 1;
    public baseMapImageUrl = '../../assets/images/basemap/';
    public baseMaplist: BaseMapListItem[];

    public baseLayers: LayerGroup;
    public gisUserSetting: gisUserSettings;
    public layerViewParam = '';
    public geolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
            enableHighAccuracy: true,
        },
        tracking: true,
        projection: this.projection,
    });

    public accuracyFeature: Feature;
    public positionFeature: Feature;
    public layersParameterJson: any;
    public uptimeEntityIds: number[] = [DEPTH_ENTITY, RAIN_ENTITY, ELEVATION_ENTITY, QUANTITY_ENTITY, VELOCITY_ENTITY] ;
    public prismLayers;
    public prismLayersGroup;
    public manHoleLayer;
    public manholeLayerSource;
    public pipeLayer;
    public pipeLayerSource;
    public basinLayer;
    public basinLayerSource;
    public prismLayerList: any[] = [];
    public prismLayerGroupList: any[] = [];
    public mapLayerLists: any[] = [];
    /*
     *
     */

    public mapStyle: MapTypeStyle[];
    private subscriptions = new Array<Subscription>();
    public mapTypes: MapType[] = [];
    public mapTypeStringId: string;
    public markers: Locations[] = [];
    public drawLocationList: Locations[] = [];
    public customerId: number;
    public locationGroupId: number;
    public locationId: number;
    public referringLocationId: number;
    public locationDetails: LocationDetails;
    public monitorSeries: string;
    public isLoading: boolean;
    public isElevationInstallType: boolean;
    public isForesite: boolean;
    public latitude: number;
    public longitude: number;
    public mapTypeModel: MapType = new MapType(null);
    public locationSeries: string;
    public isConfirmationsEnabled = false;
    public isCollecting: boolean;
    public message = 'Collect has been initiated successfully.';
    public actionLabel = 'Dismiss';
    public locationScheduleMessage: string;
    public titleText: string;
    public deleteCalculatedEntityText: string;
    public cancelText: string;
    public okDeleteText: string;
    public dismissText: string;
    public elevationUnit: string;
    public noDataMsg: string;
    public okText: string;
    public deleteSuccess: string;
    public deleteFailure: string;
    public editFailure: string;
    public totalPaginationLength: number;
    public startPage: number;
    public pageSize: number;
    public confirmationsStartPage: number;
    public confirmationsPageSize: number;
    public pageIndex: number;
    public isFlowBalanceVisible: boolean;
    public isANSRFeatureAvailable: boolean;
    public communicationType: number;
    public disableSchedule: boolean;
    public locations = new Array<Locations>();
    public allLocations = new Array<Locations>();
    public assignedRainGauge = new Array<Locations>();
    public installationDetails: string;
    public percentFullLoading: boolean;
    public oneDayStartDate: Date;
    public oneDayEndDate: Date;
    public percentFullData: Observable<PercentFullData>;
    public dateFormatGraph: string;
    public dailySummaryLoading: boolean;
    public dailySummaryData: Observable<IDailySummaryLocationDetail>;
    public uptimeLoading: boolean;
    public uptimeData: Observable<any>;
    public isLocAtiveStr: string;
    public isLocInAtiveStr: string;
    public locAtiveStatusStr: string;
    public dateFormat: string;
    public activeAllFlag: number;
    public customerDateFormat: string;
    public metricUnitsFromJson: string;
    public flowMetricUnitsFromJson: string;
    public flowUSUnitsFromJson: string;
    public usUnitsFromJson: string;
    public pipeDiameterPlaceholderFromJson: string;
    public manholeDepthPlaceholderFromJson: string;
    public displayFilters: boolean;
    public isFlowBalanceData: boolean;
    public isFlowBalanceDataAvailable = true;
    public generateReportFailureMessage = '';

    public confirmationDateFormat: string;
    public confirmationData: ConfirmationUnitsAndAverages;
    public confirmationDataLength = 0;
    public deleteConfirmationPointTitle: string;
    public deleteConfirmationPointContent: string;
    private deleteConfirmationPointSuccessText: string;
    private deleteConfirmationPointErrorText: string;

    public tabIndex = 0;
    public allSchedules: Array<ScheduleCollection>;
    public viewDataLocationDetailsColumns = ['name', 'description', 'action'];
    public viewDataLocationDetailsDataSource: MatTableDataSource<CalculatedEntitiesModel>;
    public flowBalanceColumns = ['name', 'flow'];
    public flowBalanceDataSource: MatTableDataSource<FlowBalanceDetails>;
    public flowBalanceData = new Array<FlowBalanceDetails>();
    public readonly CALCULATED_GAIN = 'CALCULATED_GAIN';
    public plottingConfirmationsColumns = [
        'expandLabel',
        'time',
        'type',
        'depth',
        'depth_diff',
        'peak_velocity',
        'ap_ratio',
        'calculated_avg_velocity',
        'velocity_diff',
        CALCULATED_GAIN,
        'silt',
        'measured_flow',
        'calculated_flow',
        'ignore',
        'comment',
        'action',
    ];
    public plottingConfirmationsExtendedColumns = [
        'blankExpandLabel',
        'time',
        'type',
        'depth',
        'depth_diff',
        'peak_velocity',
        'ap_ratio',
        'calculated_avg_velocity',
        'velocity_diff',
        CALCULATED_GAIN,
        'silt',
        'measured_flow',
        'calculated_flow',
        'blankIgnore',
        'comment',
        'blankAction',
    ];
    public CONFIRMATION_ROWS_IN_SUMMARY = CONFIRMATION_ROWS_IN_SUMMARY;
    public viewLocationDetailsDataSource = new MatTableDataSource(DATA_ELEMENT_DATA);
    public viewLocationDetailsColumns = ['name', 'description', 'action'];
    public viewLocationNotesDataSource = new MatTableDataSource(NOTES_ELEMENT_DATA);
    public viewLocationNotesColumns = ['category', 'description', 'startdate', 'enddate', 'action'];
    public locationDetailsObj: LocationDetailsModel;
    public monitorSeriesUI = new Array<MonitorSeriesUI>();

    
    public activeBaseMapId: string = null;
    public baseMapOptions: BaseMapListItem[];

    public manholeDepthPlaceHolder: string;
    public pipeWidthPlaceHolder: string;
    public pipeHeightPlaceHolder: string;
    public flumeRangePlaceHolder: string;
    public widthPlaceHolder: string;
    public heightPlaceHolder: string;
    public lengthPlaceHolder: string;
    public breadthPlaceHolder: string;
    public depthPlaceHolder: string;
    public capacityPlaceHolder: string;
    public quantityPlaceHolder: string;
    public depthUnit: string;
    public velocityUnit: string;
    public flowUnit: string;
    public flowSmallerUnit: string;
    public isRainGauge = false;
    public rainGauge: string;
    public channelStandard: string;
    public elevation: string;
    public isBasicDataEditingAllowed: boolean;
    public isScheduleCollectAllowed: boolean;
    public isAdvancedReportsAllowed: boolean;
    public isManageConfirmationPointsAllowed: boolean;
    public isRainAlertIII: boolean;
    /**
     * To describe pipe height placeholder based on translate
     */
    public pipeHeightPlaceholderFromJson: string;
    public pipeWidthPlaceholderFromJson: string;
    public fumeRangePlaceholderFromJson: string;
    public widthPlaceholderFromJson: string;
    public heightPlaceholderFromJson: string;
    public lengthPlaceholderFromJson: string;
    public breathPlaceholderFromJson: string;
    public depthPlaceholderFromJson: string;
    public capacityPlaceholderFromJson: string;
    public quantityPlaceholderFromJson: string;
    public minutesText: string;
    public translateKeys: Array<string> = [
        'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.RAIN_GAUGE',
        'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.CHANNEL_STANDARD_FORESITE',
        'HOME.COLLECTION_WIDGET.SNACKBAR_TEXT',
        'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ACTIVE',
        'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.INACTIVE',
        'HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_DIAMETER',
        'HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH',
        'HOME.MAP.MARKER_LOCATION_DETAIL.MGD_TRACER_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_TRACER_UNITS',
        'CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_DIALOG_TITLE',
        'CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_DIALOG_CONTENT',
        'CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_MESSAGE',
        'LOCATION_DASHBOARD.DELETE_DATA.CANCEL',
        'LOCATION_DASHBOARD.DELETE_DATA.DELETE_TEXT',
        'COMMON.DISMISS_TEXT',
        'COMMON.OK',
        'CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_SUCCESS',
        'CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_FAILURE',
        'CALCULATED_ENTITY_DIALOG.CALCULATED_EDIT_ERROR',
        'CONFIRMATION_POINT_DIALOG.DELETE_TITLE',
        'CONFIRMATION_POINT_DIALOG.DELETE_CONTENT',
        'CONFIRMATION_POINT_DIALOG.POINT_DELETE_MESSAGE',
        'CONFIRMATION_POINT_DIALOG.POINT_DELETE_ERROR_MESSAGE',
        'HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_WIDTH',
        'HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER',
        'HOME.MAP.MARKER_LOCATION_DETAIL.WIDTH',
        'HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT',
        'HOME.MAP.MARKER_LOCATION_DETAIL.LENGTH',
        'HOME.MAP.MARKER_LOCATION_DETAIL.BREADTH',
        'HOME.MAP.MARKER_LOCATION_DETAIL.DEPTH',
        'HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER',
        'HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY',
        'HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_HEIGHT',
        'HOME.MAP.COMPOSITE_LOCATION.SELECT_ATLEAST_LOCATION_MSG',
        'HOME.WIDGET_SCHEDULE.MINUTES_TEXT',
        'VAULT.VAULT_TELEMETRY.EXPORT.NO_DATA_EXPORT_ERR',
        'COMMON.DEPTH',
        'COMMON.AREA',
        'COMMON.PERIMETER',
        'COMMON.CHORD',
        'COMMON.BEFORE', 'COMMON.AFTER', 'COMMON.CLOSEST', 'COMMON.AVG',
        'HOME.MAP.MARKER_LOCATION_DETAIL.METER_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.FEET_UNITS',
        'CONFIRMATION_POINT_DIALOG.UNABLE_TO_UPDATE',
        'CONFIRMATION_POINT_DIALOG.SUCCESSFUL_UPDATE'
    ];
    public translations = {
        before: '',
        after: '',
        closest: '',
        avg: '',
        successfullUpdate: '',
        unableToUpdate: ''

    }
    public calculatedEntityData: any;
    public showCreateSection: boolean;
    public showEditFlowBalance: boolean;
    public showLocationDiagnostics = false;
    public deviceTypes: DeviceTypeCode[] = [];
    public selectableLocations = new Array<Selectable>();

    /** Whenever locations was loaded */
    public locationsLoaded = false;

    public reloadCache = true;

    private locationGroups: number[][];

    private monitorFeatureNamesSet = new Set();

    private esriTileSource: TileArcGISRest;
    private esriTileLayer: TileLayer;
    private layerNameToEsriLayerId: {[index: string]: number};
    private checkedLayersList: Set<number>;
    private isDestroyed = false;
    private isMetric = false;
    public confirmationTableData: ConfirmationReportUIRow[];
    public MONITOR_SERIES_TYPES = MONITOR_SERIES_TYPES;
    public INSTALLATION_TYPE = INSTALLATION_TYPE;

    public pipeDetails?: LocationUIPipeTable[] = null;
    public ansrEntities: CalculatedEntityDetails[] = [];

    public elevationMetricUnits: string;
    public elevationStandardUnits: string;


    private linearUnitMetric?: ApiUnitsRow;
    private linearFeetUnitMetric?: ApiUnitsRow;
    private areaUnitMetric?: ApiUnitsRow;

    private depthText = '';
    private areaText = '';
    private perimeterText = '';
    private chordText = '';
    public MonitorSeriesNames = MonitorSeriesNames;
    public isAvgcgNumeric = false;

    public depthPrecision = '1.2-2';
    public flowPrecision = '1.3-3';

    public attributionText: string;
    constructor(
        private locationDashboardService: LocationDashboardService,
        private usersService: UsersService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private activatedRoute: ActivatedRoute,
        private mapService: MapService,
        private locationService: LocationService,
        private unitsService: UnitsService,
        private dateutilService: DateutilService,
        public dialog: MatDialog,
        private collectService: CollectService,
        private snackBar: MatSnackBar,
        private statusCodeService: StatusCodeService,
        private translate: TranslateService,
        private scheduleCollectionService: ScheduleCollectionService,
        private percentFullReportService: PercentFullReportService,
        private dailySummaryReportService: DailySummaryReportService,
        private upatimeReportService: UptimeReportService,
        private customerLocationService: CustomerDetailLocationService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private activatedRouter: ActivatedRoute,
        private calculatedEntitiesService: CalculatedEntitiesService,
        private domOperationUtilsService: DomOperationUtilsService,
        private snackBarNotificationService: SnackBarNotificationService,
        private customerService: CustomerService,
        public gisService: GISService,
        private monitorDiagnosticsService: MonitorDiagnosticsService,
        private locationGroupService: LocationGroupService,
        private drawPipeHelper: DrawPipeHelper,
        public locationCardService: LocationCardService
    ) {
        this.subscriptions.push(
            translate.get(this.translateKeys).subscribe((translateValues) => {
                this.locationScheduleMessage = translateValues['HOME.COLLECTION_WIDGET.SNACKBAR_TEXT'];
                this.isLocAtiveStr = translateValues['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ACTIVE'];
                this.isLocInAtiveStr = translateValues['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.INACTIVE'];
                this.metricUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS'];
                this.flowMetricUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_TRACER_UNITS'];
                this.flowUSUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.MGD_TRACER_UNITS'];
                this.usUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS'];
                this.pipeDiameterPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_DIAMETER'];
                this.manholeDepthPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH'];
                this.titleText = translateValues['CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_DIALOG_TITLE'];
                this.titleText = translateValues['CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_DIALOG_CONTENT'];
                this.deleteCalculatedEntityText = translateValues['CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_MESSAGE'];
                this.cancelText = translateValues['LOCATION_DASHBOARD.DELETE_DATA.CANCEL'];
                this.okDeleteText = translateValues['LOCATION_DASHBOARD.DELETE_DATA.DELETE_TEXT'];
                this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
                this.okText = translateValues['COMMON.OK'];
                this.deleteSuccess = translateValues['CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_SUCCESS'];
                this.deleteFailure = translateValues['CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_FAILURE'];
                this.editFailure = translateValues['CALCULATED_ENTITY_DIALOG.CALCULATED_EDIT_ERROR'];
                this.deleteConfirmationPointTitle = translateValues['CONFIRMATION_POINT_DIALOG.DELETE_TITLE'];
                this.deleteConfirmationPointContent = translateValues['CONFIRMATION_POINT_DIALOG.DELETE_CONTENT'];
                this.deleteCalculatedEntityText = translateValues['CALCULATED_ENTITY_DIALOG.CALCULATED_DELETE_MESSAGE'];
                this.deleteConfirmationPointSuccessText =
                    translateValues['CONFIRMATION_POINT_DIALOG.POINT_DELETE_MESSAGE'];
                this.deleteConfirmationPointErrorText =
                    translateValues['CONFIRMATION_POINT_DIALOG.POINT_DELETE_ERROR_MESSAGE'];
                this.pipeWidthPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_WIDTH'];
                this.pipeHeightPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_HEIGHT'];
                this.fumeRangePlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER'];
                this.widthPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.WIDTH'];
                this.heightPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT'];
                this.lengthPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.LENGTH'];
                this.breathPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.BREADTH'];
                this.depthPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.DEPTH'];
                this.capacityPlaceholderFromJson =
                    translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER'];
                this.quantityPlaceholderFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY'];
                this.generateReportFailureMessage =
                    translateValues['HOME.MAP.COMPOSITE_LOCATION.SELECT_ATLEAST_LOCATION_MSG'];
                this.rainGauge = translateValues['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.RAIN_GAUGE'];
                this.channelStandard = translateValues['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.CHANNEL_STANDARD_FORESITE'];
                this.elevation = translateValues['LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ELEVATION']
                this.minutesText = translateValues['HOME.WIDGET_SCHEDULE.MINUTES_TEXT'];
                this.noDataMsg = translateValues['VAULT.VAULT_TELEMETRY.EXPORT.NO_DATA_EXPORT_ERR'];
                this.depthText = translateValues['COMMON.DEPTH'];
                this.areaText = translateValues['COMMON.AREA'];
                this.perimeterText = translateValues['COMMON.PERIMETER'];
                this.chordText = translateValues['COMMON.CHORD'];
                this.translations.before = translateValues['COMMON.BEFORE'];
                this.translations.after = translateValues['COMMON.AFTER'];
                this.translations.closest = translateValues['COMMON.CLOSEST'];
                this.translations.avg = translateValues['COMMON.AVG'];
                this.translations.unableToUpdate = translateValues['CONFIRMATION_POINT_DIALOG.UNABLE_TO_UPDATE'];
                this.translations.successfullUpdate = translateValues['CONFIRMATION_POINT_DIALOG.SUCCESSFUL_UPDATE'];
                this.elevationMetricUnits = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.METER_UNITS'];
                this.elevationStandardUnits = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.FEET_UNITS'];
            }),
        );
    }

    // tslint:disable-next-line:no-empty
    public ngOnInit() {
        const activeInactiveSubs = this.statusCodeService.activeInactiveHandler
            .pipe(skip(1), distinctUntilChanged())
            .subscribe(() => {
                this.getAllLocations();
            });

        this.subscriptions.push(activeInactiveSubs);
        // reset to default
        this.referringLocationId = 0;
        this.mapService.isMapLocationEdit = false;
        this.tabIndex = this.usersService.isFlowBalanceReport ? 4 : 0;
        this.domOperationUtilsService.showLocationDetailScreenNo.next(this.tabIndex);

        this.subscriptions.push(
            this.domOperationUtilsService.showLocationDetailScreenNo.subscribe((result: number) => {
                if (this.domOperationUtilsService.hintPageName === LOCATION_DETAIL_HINT) {
                    this.domOperationUtilsService.currentScreenNo = this.tabIndex = result;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            }),
        );

        // get current query params for customer
        const currentCustomerID = Number(this.activatedRouter.snapshot.queryParamMap.get(customerQueryParam));
        this.customerId = currentCustomerID;
        this.customerService.getCustomerById(this.customerId).subscribe((customer: Customer) => {
            this.isMetric = customer.unitsType === UnitOfMeasureType.METRIC;
            this.depthPrecision = this.isMetric ? '1.1-1' : '1.2-2';
            this.flowPrecision = this.isMetric ? '1.1-1' : '1.3-3';

            this.elevationUnit = this.isMetric ? this.elevationMetricUnits : this.elevationStandardUnits;
            // check units of measure
            const isMetricUnit = this.isMetric;
            const depthUnit = isMetricUnit ? this.metricUnitsFromJson : this.usUnitsFromJson;
            const flowUnit = isMetricUnit ? this.flowMetricUnitsFromJson : this.flowUSUnitsFromJson;
            this.pipeDiameterPlaceholderFromJson = this.pipeDiameterPlaceholderFromJson + '(' + depthUnit + ')';
            this.manholeDepthPlaceholderFromJson = this.manholeDepthPlaceholderFromJson + '(' + depthUnit + ')';

            this.manholeDepthPlaceHolder = this.manholeDepthPlaceholderFromJson + ' (' + depthUnit + ')';
            this.pipeWidthPlaceHolder = this.pipeWidthPlaceholderFromJson + ' (' + depthUnit + ')';
            this.pipeHeightPlaceHolder = this.pipeHeightPlaceholderFromJson + ' (' + depthUnit + ')';
            this.flumeRangePlaceHolder = this.fumeRangePlaceholderFromJson + ' (' + depthUnit + ')';
            this.widthPlaceHolder = this.widthPlaceholderFromJson + ' (' + depthUnit + ')';
            this.heightPlaceHolder = this.heightPlaceholderFromJson + ' (' + depthUnit + ')';
            this.lengthPlaceHolder = this.lengthPlaceholderFromJson + ' (' + depthUnit + ')';
            this.breadthPlaceHolder = this.breathPlaceholderFromJson + ' (' + depthUnit + ')';
            this.depthPlaceHolder = this.depthPlaceholderFromJson + ' (' + depthUnit + ')';
            this.capacityPlaceHolder = this.capacityPlaceholderFromJson + ' (' + flowUnit + ')';
            this.quantityPlaceHolder = this.quantityPlaceholderFromJson;

            this.uiUtilsService.safeChangeDetection(this.cdr);
        })

        this.subscriptions.push(
            combineLatest([
                this.usersService.isAdvancedReportsAllowed,
                this.usersService.isANSREngine,
                this.usersService.isManageConfirmationPointsAllowed]).subscribe(
                ([isARAllowed, isANSREnabled, isManageConfirmationPointsAllowed]) => {
                    this.isAdvancedReportsAllowed = isARAllowed.value;
                    this.isFlowBalanceVisible = isARAllowed.value;
                    this.isANSRFeatureAvailable = isANSREnabled.value;
                    this.isManageConfirmationPointsAllowed = isManageConfirmationPointsAllowed;
                },
            ),
        );
        this.subscriptions.push(
            this.locationGroupService.getLocationGroups(this.customerId).subscribe((res) => {
                if(res.locationGroups) {
                    this.locationGroups = [];
                    for(const g of res.locationGroups) {
                        for(const l of g.locations) {
                            if(!this.locationGroups[l.locationID]) {
                                this.locationGroups[l.locationID] = [];
                            }
                            this.locationGroups[l.locationID].push(g.locationGroupID);
                        }
                    }
                }
            })
        )
        // Subscribe to get permission for Basic Data Editing
        this.subscriptions.push(
            this.usersService.isBasicDataEditingAllowed.subscribe((response) => {
                if (response) {
                    this.isBasicDataEditingAllowed = response;
                }
            }),
        );
        // Subscribe to get permission for Schedule Collect
        this.subscriptions.push(
            this.usersService.isScheduleCollectAllowed.subscribe((response) => {
                if (response) {
                    this.isScheduleCollectAllowed = response;
                }
            }),
        );

        this.usersService.isFlowBalanceReport = false;

        const dateFormatSubs = this.dateutilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat =
                this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
        });

        this.subscriptions.push(dateFormatSubs);
        this.getMapTypes();
        this.mapTypeStringId = 'roadmap';
        //  subscribe to customer and location group changes
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                const previousCustomerId = this.customerId;
                const previousLocGroup = this.locationGroupId;
                this.customerId = Number(params.get(customerQueryParam));
                this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
                this.locationId = Number(params.get(locationIdQueryParam));
                this.activeAllFlag = Number(params.get(activeInactiveLocationQueryParam));
                this.referringLocationId = Number(params.get(referringLocationIdQueryParam));
                this.reloadCache = !(params.get(reloadCacheQueryParam) === '0');
                if (previousCustomerId && previousCustomerId !== this.customerId) {
                    this.backRedirect();
                }
                if ((previousLocGroup || previousLocGroup === 0) && previousLocGroup !== this.locationGroupId) {
                    this.getAllLocations();
                    this.reloadLocations();
                }
                this.drawPipe();
            }),
        );
        // get the monitor-series.
        this.subscriptions.push(
            this.locationService.getMonitorSeries().subscribe(
                (monitors: Array<MonitorSeriesUI>) => this.getlatesMonitorDetails(monitors),
                (error) => {
                    // Error Block to handle Errors
                },
            ),
        );
        this.getcurrentLocationDetails(true);

        //  set dates for the bottom graphs for 1 day span
        this.oneDayStartDate = new Date();
        this.oneDayEndDate = new Date();
        this.oneDayStartDate.setDate(this.oneDayEndDate.getDate() - 1);
        this.oneDayEndDate.setDate(this.oneDayEndDate.getDate() - 1);
        this.oneDayEndDate.setHours(23, 59, 59);


        this.loadGisUserSettings();
        // get percent full data
        this.percentFullData = this.getPercentFullGraphData().pipe(
            tap((data: PercentFullData) => {
                if (!data) {
                    return;
                }
            }),
        );

        if (this.confirmationPaginator) {
            this.confirmationPaginator.pageSize = 25;
            this.confirmationPaginator.pageIndex = 0;
        }


        this.getCalculatedEntityDetails();

        // subscribe to changes in dateFormat
        this.subscriptions.push(
            this.dateutilService.dateFormat.subscribe(() => {
                // Getting customer dateformat from dateUtil Service
                this.dateFormatGraph = this.dateutilService.getGraphDateFormat();
                this.confirmationDateFormat =
                    this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormat();
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }),
        );
        this.getFlowBalanceData();
        this.getConfirmationPoints();
        this.getCustomerUnits();

        this.loadScheduleData();

        this.uiUtilsService.safeChangeDetection(this.cdr);
        /////////// olmap adding
        this.addOlMap();
        this.setMapLocationsCenter();
    }

    public loadScheduleData() {
        const loadSubscription = this.scheduleCollectionService.getLiteScheduledCollections(this.customerId).subscribe(
            (schedules: any[]) => {
                if (schedules) {
                    this.allSchedules = schedules.map(
                        (schedule) =>
                            <ScheduleCollection>{
                                scheduleId: schedule.scheduleId,
                                name: schedule.name,
                                collectTime: schedule.collectTime,
                            },
                    );
                    schedules.forEach((schedule, index) => {
                        if (schedule.alarmingFrequency) {
                            if (schedule.alarmingFrequency.includes('15mins')) {
                                schedule.alarmingFrequency = '15 ' + this.minutesText;
                            }
                            if (schedule.alarmingFrequency.includes('Hrs')) {
                                schedule.alarmingFrequency =
                                    parseInt(schedule.alarmingFrequency.split('Hrs')[0], 10) + ' Hours';
                            }
                        }
                    });
                }
            }
        );
        this.subscriptions.push(loadSubscription);
    }

    private checkForActiveInactiveHandler(isActive: boolean): boolean {
        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        if (activeInactiveHandler) {
            return true; // display all locations is turned on
        }

        return isActive;
    }

    private checkForLocationGroup(locationgroupIds: number[]): boolean {
        if (this.locationGroupId === 0) {
            return true; // display all location groups is selected
        }

        return locationgroupIds && locationgroupIds.includes(this.locationGroupId);
    }

    reloadLocations() {
        this.monitorFeatureNamesSet = new Set();
        this.AdsMonitorLayerSource.refresh();
    }

    async addOlMap() {
        // this.gisUserSetting = await this.gisService.getGisUserSettings().toPromise();

        this.olView = new View({
            projection: this.projection,
            center: [0, 0],
            zoom: 4,
            minZoom: 0,
            maxZoom: 19,
        });
        this.olMap = new Map({
            target: 'locationmap',
            controls: defaultControls({
                attribution: false,
                zoom: false,
            }).extend([]),
            //  layers:this.olLayers,
            view: this.olView,
        });

        this.gisService.getCustomerSettings(this.customerId).subscribe((res: any) => {
            let measurementUnit;
            if (res) {
                measurementUnit = res['unitsType'] == 2 ? 'metric' : 'us';
            } else {
                measurementUnit = 'metric';
            }
            this.scaleLinecontrol = new ScaleLine({
                target: 'scaleline',
                units: measurementUnit,
                bar: true,
                steps: 4,
                text: true,
                minWidth: 100,
            });
            this.olMap.addControl(this.scaleLinecontrol);
        });

        this.loadBasemap();
        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        const geoWFSurl = Config.urls.geoserverUrl + `ows/${this.customerId}?IncludeInactiveLocations=${activeInactiveHandler}`
        this.AdsMonitorLayerSource = new VectorSource({
            format: new GeoJSON(),
            url: (extent) => {
                return geoWFSurl;
            },
        });
        this.AdsMonitorLayerSource.setLoader((p0, p1, projection) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'json';
            xhr.addEventListener('loadend', (response) => {
                const responsetaget = response['currentTarget'];
                const data = responsetaget['response'];

                const uniqueData = [];
                for(const f of data.features) {
                    const featureId = f.properties.LocationID;
                    const name = f.properties.Name;
                    const isActive = f.properties.IsActiveLocation;
                    const locationGroupIds = this.locationGroups ? this.locationGroups[featureId] : [];
                    f.id = featureId;

                    if(
                        !this.monitorFeatureNamesSet.has(name)
                        && this.checkForActiveInactiveHandler(isActive)
                        && this.checkForLocationGroup(locationGroupIds)
                    ) {
                        uniqueData.push(f);
                        this.monitorFeatureNamesSet.add(name);
                    }
                }
                data.features = uniqueData;

                const features = this.AdsMonitorLayerSource.getFormat().readFeatures(data, {
                    featureProjection: projection,
                });

                this.AdsMonitorLayerSource.addFeatures(features as Feature[]);
            });
            xhr.addEventListener('error', () => {});
            xhr.open('GET', geoWFSurl);
            const token = sessionStorage.getItem('adsToken');
            if (token) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }
            xhr.send();
        });

        this.AdsMonitorLayerSourceCL = new Cluster({
            distance: 20,
            source: this.AdsMonitorLayerSource,
        });

        this.AdsMonitorLayer = new VectorLayer({
            name: 'Monitor',
            source: this.AdsMonitorLayerSourceCL,
            style: (feature: Feature) => monitorLocationStyle(this.gisService.gisUserSettingsSubject$, feature.get('features'), this.pickMapMonitorColor.bind(this)),
        });

        this.AdsMonitorLayer.setZIndex(2);

        this.olMap.addLayer(this.AdsMonitorLayer);

        const services: any = await this.gisService.getGISServicesList(this.customerId).toPromise();

        this.checkedLayersList = new Set();
        this.layerNameToEsriLayerId = {};

        await this.gisService.layerLoad(
            services,
            this.gisUserSetting,
            this.customerId,
            () => !this.isDestroyed,
            {
                checkedLayersList: this.checkedLayersList,
                layerNameToEsriLayerId: this.layerNameToEsriLayerId,
                olMap: this.olMap,
                prismLayerList: this.prismLayerList,
                esriTileSource: this.esriTileSource,
                esriTileLayer: this.esriTileLayer
            },
            {
                defaultOpacity: 1
            }
        );
    }

    public loadBasemap() {
        const mapToken = this.gisService.getGisToken();
        const baseMaps = this.gisService.getGISBasemap();

        combineLatest([mapToken, baseMaps]).pipe(first())
        .subscribe(([token, basemaps]) => {
            if (!this.activeBaseMapId) {
                // Ensure active base map is set; otherwise default to first map.
                this.activeBaseMapId = basemaps[0].baseMapId;
            }

            this.baseMaplist = basemaps;
            this.baseMapOptions = basemaps;
            const baseMaplists:TileLayer[] = [];

            this.baseMapOptions.forEach((basemap) => {
                if (basemap.url.includes('{token}')) {
                    basemap.url = basemap.url.replace('{token}', token.access_token);
                }
            })

            // include default layers
            this.baseMapOptions.filter(v => !v.url.includes('token')).forEach(basemap => {
                baseMaplists.push(
                    new TileLayer({
                        name: basemap.name,
                        // id: basemap.id,
                        visible: basemap.baseMapId === this.activeBaseMapId ? true : false,
                        source: new XYZ({
                            url: basemap.url,
                            crossOrigin: 'Anonymous',
                        }),
                    }),
                );
            });

            const activeMap = this.baseMapOptions.find(x => x.baseMapId === this.activeBaseMapId);

            if (activeMap && activeMap.url.includes('token')) {
                this.applyDynamicBasemap(activeMap.url, activeMap.name);
            }

            this.baseLayers = new LayerGroup({
                name: LayerNameOpts.basemap,
                layers: baseMaplists,
            });
            this.olMap.addLayer(this.baseLayers);

            this.setMapAttributionText();
        });
    }

    private applyDynamicBasemap(url: string, name: string) {
        // since apply will add a layer dynamically, and does not provide any success event handler we need to listen to Add layer event to set its name
        apply(this.olMap, url)
            .then((data: OLMap) => {
                const newlyAdded = data.getLayers().getArray().find(v => v.get('name') === undefined)

                if (newlyAdded) {
                    newlyAdded.set('name', name);
                }

                const mapBox = this.olMap.get('mapbox-style');
                const sources = mapBox.sources;
                const sourceKeys = Object.keys(sources);

                let attribution: string = '';

                for (const key of sourceKeys) {
                    const source = sources[key];
                    const srcAttribution = source.attribution;

                    if (srcAttribution) {
                        attribution += srcAttribution;
                    }
                }

                if (attribution) {
                    this.attributionText = attribution;
                }

            })
            .catch((err) => {
                this.gisService.getGisToken().subscribe((res) => this.applyDynamicBasemap(this.replaceToken(url, res.access_token), name))
            });
    }

    private replaceToken(url: string, newToken: string) {
        const regex = /token=([^&]*)/;
        return url.replace(regex, `token=${newToken}`);
    }

    private setMapAttributionText() {
        if (!this.activeBaseMapId || !this.baseMapOptions || !this.baseMapOptions.length) {
            return;
        }

        const basemap = this.baseMapOptions.find(v => v.baseMapId === this.activeBaseMapId);

        if (!basemap) {
            return;
        }

        switch(basemap.name) {
            case BasemapNames.topographic: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.TOPOGRAPHIC');
                break;
            }
            case BasemapNames.imagery: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.IMAGERY');

                break;
            }
            case BasemapNames.esri: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.ESRI');

                break;
            }
            case BasemapNames.esriImage: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.ESRI_IMAGE');
                break;
            }
            case BasemapNames.osm: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.OSM');

                break;
            }
            case BasemapNames.esriBasemap: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.ESRI_BASEMAP');

                break;
            }
            default: break;
        }
    }

    maplayersLoad() {
        this.AdsMonitorLayerSource = new VectorSource({
            format: new GeoJSON(),
            url: () => {
                const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();
                return (
                   Config.urls.geoserverUrl + `ows/${this.customerId}?IncludeInactiveLocations=${activeInactiveHandler}`
                );
            },
            strategy: allStrategy,
        });

        this.AdsMonitorLayerSourceCL = new Cluster({
            distance: 20,
            source: this.AdsMonitorLayerSource,
        });

        this.AdsMonitorLayer = new VectorLayer({
            name: 'Monitor',
            source: this.AdsMonitorLayerSourceCL,
            style: (feature: Feature) => monitorLocationStyle(this.gisService.gisUserSettingsSubject$, feature.get('features'), this.pickMapMonitorColor.bind(this)),
        });

        let layerdata: any;
        if (this.layersParameterJson != undefined && this.layersParameterJson.data.length > 0) {
            layerdata = this.layersParameterJson.data.filter((f) => f.name == 'Monitor');
        }
        if (layerdata != undefined && layerdata.length > 0) {
            this.mapLayerLists.push({
                name: 'Monitor',
                checked: layerdata[0].checked,
                child: [],
                selection: layerdata[0].selection,
                legend: layerdata[0].legend,
                legendurl: '',
            });
        } else {
            this.mapLayerLists.push({
                name: 'Monitor',
                checked: true,
                child: [],
                selection: true,
                legend: true,
                legendurl: '',
            });
        }

        /*
         *
         */
        this.positionFeature = new Feature();
        //this.positionFeature.setStyle(positionFeatureStyle);
        this.accuracyFeature = new Feature();
        //this.accuracyFeature.setStyle(accuracyFeatureStyle);
        const loctionLayer = new VectorLayer({
            name: 'loctionLayer',
            source: new VectorSource({
                features: [this.accuracyFeature, this.positionFeature],
            }),
        });

        /*
         * Loading map layers group
         */
        /*this.overlayLayers = new LayerGroup({
				name:"Ovelaymap",
				layers: [ this.AdsMonitorLayer, loctionLayer]
			});*/
        /*this.overlayOVLayers = new LayerGroup({
			name:"Ovelaymap",
			layers: [this.AdsMonitorLayerOV]
		});*/
        this.olMap.addLayer(this.AdsMonitorLayer);
        //this.olMap.addLayer( this.MeasureLayer);
        //this.olMap.addLayer( this.SelectionLayer);
        this.olMap.addLayer(loctionLayer);
        //this.olOvervwLayers.push(this.overlayOVLayers);
        //this.overviewMap();
        //this.loadMonitorLocations();
        this.prismLayerLoad();
        //this.layerList.loaddata(this.mapLayerLists);
    }
    setMapLocationsCenter() {
        const lat = this.locationDetails.coordinate.latitude;
        const long = this.locationDetails.coordinate.longitude;

        if (!this.olMap) return;

        this.olMap.getView().setCenter(fromLonLat([long, lat], this.projection));
        this.olMap.getView().setZoom(18);
    }

    prismLayerLoad() {
        this.gisService.getGisLayerMapping(this.customerId).subscribe((res: any) => {
            this.olMap.removeLayer(this.prismLayersGroup);
            this.prismLayerList = [];
            this.prismLayerGroupList = [];
            res.forEach((layer) => {
                this.prismLayerDefine(layer);
            });

            let networkfound = false;
            let netcnt = 0;
            this.mapLayerLists.forEach((ent) => {
                if (ent.name == 'Network') {
                    if (this.prismLayerList.length > 0) {
                        ent.child = this.prismLayerList;
                        networkfound = true;
                    } else {
                        this.mapLayerLists.splice(netcnt, 1);
                    }
                }
                netcnt++;
            });
            if (this.prismLayerList.length > 0 && !networkfound) {
                this.mapLayerLists.push({
                    name: 'Network',
                    checked: true,
                    child: this.prismLayerList,
                    selection: false,
                    legend: false,
                });
            }
            //this.layerList.loaddata(this.mapLayerLists);
            this.prismLayersGroup = new LayerGroup({
                name: 'Networkmap',
                layers: this.prismLayerGroupList,
            });
            this.olMap.addLayer(this.prismLayersGroup);
        });
        //this.maplayersLoad();
    }
    tileloading(tile, src) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.addEventListener('loadend', (response) => {
            const responsetaget = response['currentTarget'];
            const data = responsetaget['response'];
            if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
            } else {
                tile.setState(TileState.ERROR);
            }
        });
        xhr.addEventListener('error', () => {
            tile.setState(TileState.ERROR);
        });
        xhr.open('GET', src);
        xhr.setRequestHeader('Authorization', 'Basic SURFWDprM1IqdjJMeHEjRkQ=');
        xhr.send();
    }
    public async loadGisUserSettings() {
        const userSettings = await this.gisService.gisUserSettingsGet();
        if (
            userSettings != null &&
            userSettings.layersParameterJson != undefined &&
            userSettings.layersParameterJson != ''
        ) {
            this.layersParameterJson = JSON.parse(userSettings.layersParameterJson);
        }

        this.gisUserSetting = userSettings;
    }

    prismLayerDefine(layer) {
        const layerslisting: any[] = [];
        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        let layersetting;
        this.layersParameterJson.data.forEach((lyrsetting) => {
            if (layer.structureType == lyrsetting.name) {
                layersetting = lyrsetting;
            } else if (layer.structureType == 'Pipe' && lyrsetting.name == 'Pipeline') {
                layersetting = lyrsetting;
            }
        });
        if (layer.serviceType == 'wms') {
            const legendCMUrl = '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=';
            if (layer.structureType == 'Manhole') {
                this.manholeLayerSource = new TileWMS({
                    url: layer.serviceURL,
                    params: {
                        LAYERS: layer.layer,
                        TILED: true,
                        CRS: OLMAPPROJECTION,
                    },
                });
                this.manHoleLayer = new TileLayer({
                    name: 'Manhole',
                    visible: layersetting.checked,
                    source: this.manholeLayerSource,
                });
                this.manholeLayerSource.setTileLoadFunction((tile, src) => {
                    this.tileloading(tile, src);
                });
                this.prismLayerGroupList.push(this.manHoleLayer);
                this.prismLayerList.push({
                    name: 'Manhole',
                    checked: layersetting.checked,
                    child: [],
                    selection: layersetting.selection,
                    legend: layersetting.legend,
                    legendurl: layer.serviceURL + legendCMUrl + layer.layer,
                });
            } else if (layer.structureType == 'Pipe') {
                this.pipeLayerSource = new TileWMS({
                    url: layer.serviceURL,
                    params: {
                        LAYERS: layer.layer,
                        TILED: true,
                        CRS: OLMAPPROJECTION,
                    },
                });
                this.pipeLayer = new TileLayer({
                    name: 'Pipeline',
                    visible: layersetting.checked,
                    source: this.pipeLayerSource,
                });
                this.pipeLayerSource.setTileLoadFunction((tile, src) => {
                    this.tileloading(tile, src);
                });
                //this.olMap.addLayer(this.pipeLayer);
                this.prismLayerGroupList.push(this.pipeLayer);
                this.prismLayerList.push({
                    name: 'Pipeline',
                    checked: layersetting.checked,
                    child: [],
                    selection: layersetting.selection,
                    legend: layersetting.legend,
                    legendurl: layer.serviceURL + legendCMUrl + layer.layer,
                });
            } else if (layer.structureType == 'Basin') {
                this.basinLayerSource = new TileWMS({
                    url: layer.serviceURL,
                    params: {
                        LAYERS: layer.layer,
                        TILED: true,
                        CRS: OLMAPPROJECTION,
                    },
                });
                this.basinLayer = new TileLayer({
                    name: 'Basin',
                    visible: layersetting.checked,
                    source: this.basinLayerSource,
                });
                this.basinLayerSource.setTileLoadFunction((tile, src) => {
                    this.tileloading(tile, src);
                });
                //this.olMap.addLayer(this.basinLayer);
                this.prismLayerGroupList.push(this.basinLayer);
                this.prismLayerList.push({
                    name: 'Basin',
                    checked: layersetting.checked,
                    child: [],
                    selection: layersetting.selection,
                    legend: layersetting.legend,
                    legendurl: layer.serviceURL + legendCMUrl + layer.layer,
                });
            }
        } else {
            if (layer.structureType == 'Manhole') {
                this.manholeLayerSource = new VectorSource({
                    format: new GeoJSON(),
                    url: (extent) => {
                        return (
                            layer.serviceURLl +
                            `ows/${this.customerId}?IncludeInactiveLocations=${activeInactiveHandler}`
                        );
                    },
                    strategy: allStrategy,
                });
                this.manHoleLayer = new VectorLayer({
                    name: 'Manhole',
                    visible: layersetting.checked,
                    source: this.manholeLayerSource,
                    //style: monitorLocationStyle,
                });
                this.olMap.addLayer(this.manHoleLayer);
                this.prismLayerList.push({
                    name: 'Manhole',
                    checked: layersetting.checked,
                    child: [],
                    selection: layersetting.selection,
                    legend: layersetting.legend,
                    legendurl: '',
                });
            } else if (layer.structureType == 'Pipe') {
                this.pipeLayerSource = new VectorSource({
                    format: new GeoJSON(),
                    url: (extent) => {
                        return (
                            layer.serviceURLl +
                            `ows/${this.customerId}?IncludeInactiveLocations=${activeInactiveHandler}`
                        );
                    },
                    strategy: allStrategy,
                });
                this.pipeLayer = new VectorLayer({
                    name: 'Pipeline',
                    visible: layersetting.checked,
                    source: this.pipeLayerSource,
                });
                this.olMap.addLayer(this.pipeLayer);
                this.prismLayerList.push({
                    name: 'Pipeline',
                    checked: layersetting.checked,
                    child: [],
                    selection: layersetting.selection,
                    legend: layersetting.legend,
                    legendurl: '',
                });
            } else if (layer.structureType == 'Basin') {
                this.basinLayerSource = new VectorSource({
                    format: new GeoJSON(),
                    url: (extent) => {
                        return (
                            layer.serviceURLl +
                            `ows/${this.customerId}?IncludeInactiveLocations=${activeInactiveHandler}`
                        );
                    },
                    strategy: allStrategy,
                });
                this.basinLayer = new VectorLayer({
                    name: 'Basin',
                    visible: layersetting.checked,
                    source: this.basinLayerSource,
                });
                this.olMap.addLayer(this.basinLayer);
                this.prismLayerList.push({
                    name: 'Basin',
                    checked: layersetting.checked,
                    child: [],
                    selection: layersetting.selection,
                    legend: layersetting.legend,
                    legendurl: '',
                });
                //this.basinLayerSource
                //this.basinLayer
            }
        }
    }

    public ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        this.isDestroyed = true;
    }

    public onSelectedTabChange(event: MatTabChangeEvent) {
        if (this.domOperationUtilsService.hintPageName === LOCATION_DETAIL_HINT) {
            this.domOperationUtilsService.showLocationDashboardDetailHintOverlay();
        } else {
            this.domOperationUtilsService.currentScreenNo = event.index;
        }
    }
    public showHideFilter() {
        this.displayFilters = !this.displayFilters;
    }
    /**
     * Used to get flow balance data from the api
     *
     */
    public getFlowBalanceData() {
        this.isFlowBalanceData = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.subscriptions.push(
            this.locationService.getFlowBalance(this.customerId, this.locationId).subscribe(
                (result) => {
                    if (result) {
                        this.flowBalanceData = result.filter((x) => x.flowType !== 0);
                        this.isFlowBalanceDataAvailable = this.flowBalanceData.length > 0;
                        this.flowBalanceDataSource = new MatTableDataSource(this.flowBalanceData);
                        this.isFlowBalanceData = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                },
                (error) => {
                    // empty block
                    this.isFlowBalanceData = false;
                },
            ),
        );
    }
    public openCalcuatedEntityModal(calculatedEntityObj?: CalculatedBaseEntity) {
        this.calculatedEntityData = calculatedEntityObj || {};
        this.showCreateSection = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    /**
     * Used to open flow balance pop up
     *
     */
    public openFlowBalance() {
        this.getFlowBalanceData();
        this.showEditFlowBalance = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private openConfirmationDialog(data?: ConfirmationReportUIRow) {
        this.dialog
        .open(AddEditPlottingConfirmationComponent, {
            disableClose: true,
            data: data,
        })
        .afterClosed()
        .subscribe((result) => {
            this.getConfirmationPoints(this.confirmationsStartPage, this.confirmationsPageSize);
        });
    }

    public changeConfirmationPointStatus(event, element) {
        const v = element.data[0];

        v.ignore = event.checked;

        const reqBody = {
            id: v.id,
            confirmationDate:
                v.time && moment.isMoment(v.time)
                ? v.time.format('YYYY-MM-DDTHH:mm:ss')
                : moment(v.time).format('YYYY-MM-DDTHH:mm:ss'),
            depth: v.depth,
            velocity: v.peak_velocity,
            comment: v.comment,
            flow: v.measured_flow  / (this.isMetric ? 1 : GPD_TO_MPD_DIVIDER),
            calculatedFlow: v.calculated_flow,
            depthAccuracy: v.depthacc,
            velocityAccuracy: v.velacc,
            silt: v.silt,
            averageToPeak: v.ap_ratio,
            ignore: v.ignore
        };

        this.confirmationService.update(reqBody).subscribe(
            (res) => {
                this.snackBarNotificationService.raiseNotification(this.translations.successfullUpdate, 'Dismiss', {
                    duration: 10000,
                });
                this.getConfirmationPoints(this.confirmationsStartPage, this.confirmationsPageSize);
            },
            () => {
                this.snackBarNotificationService.raiseNotification(this.translations.unableToUpdate, 'Dismiss', {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                this.getConfirmationPoints(this.confirmationsStartPage, this.confirmationsPageSize);
            },
        );

    }

    public openPlottingConfirmation(event = null, data: ConfirmationReportUIRow = null) {
        if(event) event.stopPropagation();

        if(data) {
            data.units = this.confirmationData;
            this.openConfirmationDialog(data);
        } else {
            this.confirmationService.getConfirmationATP(this.customerId, this.locationId).subscribe((res) => {
                let atp = DEFAULT_AVERAGE_TO_PEAK;

                if(res) {
                    atp = res;
                }

                const data = {
                    atp: atp,
                    units: this.confirmationData
                }

                this.openConfirmationDialog(data);
            });
        }

    }

    public confirmationToggleRow(row) {
        row.expand = !row.expand;
    }
    public confirmationOnPaginateChange(event) {
        this.getConfirmationPoints(event.pageIndex, event.pageSize);
    }

    public getConfirmationPoints(startPage = 0, pageSize = 25) {
        this.isLoading = true;

        this.confirmationsStartPage = startPage;
        this.confirmationsPageSize = pageSize;

        const params = {
            locationIds: [this.locationId],
            paging: {
                pageSize: pageSize,
                startPage: startPage + 1
            }
        };

        this.subscriptions.push(
            this.confirmationService.getFormattedConfirmationReport(this.customerId, params, {
                before: this.translations.before,
                after: this.translations.after,
                closest: this.translations.closest,
                avg: this.translations.avg
            }).subscribe(
                (res) => {
                    res.data.forEach((cp) => {
                        cp.data.forEach((row) => {
                            row.measured_flow = row.measured_flow ? row.measured_flow * (this.isMetric ? 1 : GPD_TO_MPD_DIVIDER) : null;
                            row.peak_velocity = row.peak_velocity ? row.peak_velocity : null;
                        });
                    });

                    this.confirmationData = res.unitsAndAverages;
                    this.confirmationTableData = res.data;
                    this.confirmationDataLength = res.count;
                    this.isAvgcgNumeric = !isNaN(this.confirmationData?.avgcg) && isFinite(this.confirmationData?.avgcg);

                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                () => {
                    this.snackBar.open(this.editFailure, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            ),
        );
    }

    private deleteConfirmationPointFailed() {
        this.snackBar.open(this.deleteConfirmationPointErrorText, 'Dismiss', {
            panelClass: 'custom-error-snack-bar',
        });
    }

    public deleteConfirmationPoint(event, confirmationPoint: {data: ConfirmationPoint[]}) {
        if(event) event.stopPropagation();

        if(!confirmationPoint || !confirmationPoint.data || !confirmationPoint.data.length || !confirmationPoint.data[0].id) {
            this.deleteConfirmationPointFailed();
            return;
        }

        const confirmationId = confirmationPoint.data[0].id;

        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.deleteConfirmationPointTitle,
                    message: this.deleteConfirmationPointContent,
                    cancelText: this.cancelText,
                    okText: this.okText,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.whichButtonWasPressed === 'ok') {
                    this.isLoading = true;
                    this.subscriptions.push(
                        this.confirmationService.delete(confirmationId).subscribe(
                            (result) => {
                                this.snackBar.open(this.deleteConfirmationPointSuccessText, 'Dismiss', {
                                    duration: 10000,
                                });
                                this.getConfirmationPoints();
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                            },
                            (error) => {
                                this.deleteConfirmationPointFailed();
                                this.isLoading = false;
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                            },
                        ),
                    );
                }
            });
    }

    public openVelocityGainTable() {
        const velocityGainData = {
            customerId: this.customerId,
            locationDetails: { locationID: this.locationId, locationName: this.locationDetails.locationName},
            user: this.usersService.userName,
            showEditMenu: false,
        };

        this.dialog
            .open<VelocityGainTableComponent, VelocityGainDialogData, VelocityGainTableComponentResponse>(
            VelocityGainTableComponent, { data: velocityGainData }
        ).afterClosed().subscribe((result) => {
            if (result && result.success) {
                this.getConfirmationPoints();
            }
        });
    }

    /**
     * Method gets map types
     */
    public getMapTypes() {
        this.subscriptions.push(
            this.locationDashboardService
                .getMapTypes()
                .pipe(take(1))
                .subscribe((result: any) => {
                    this.mapTypes = <MapType[]>result;
                    this.mapTypeModel = <MapType>this.mapTypes.find((maptype) => maptype.id === 4);
                    this.mapStyle = this.mapTypeModel.styles;

                    if (this.mapTypes.length > 0) {
                        this.subscriptions.push(
                            this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
                                if (response) {
                                    this.mapTypeModel = <MapType>this.mapTypes.find((maptype) => maptype.id === 4);
                                    this.mapStyle = this.mapTypeModel.styles;
                                } else {
                                    this.mapTypeModel = <MapType>this.mapTypes.find((maptype) => maptype.id === 2);
                                    this.mapStyle = this.mapTypeModel.styles;
                                }
                            }),
                        );
                    }

                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }),
        );
    }
    public exportLocationXml() {
        this.isLoading = true;
        this.subscriptions.push(
            this.locationService.locationsExport(this.customerId, this.locationId).subscribe(
                (res) => {
                    // #23724 - do not display anything on success - we have signalR for this
                    if(!res) {
                        // #23725 'No data' toast if failed. Returns HTTP 200 on success and HTTP 204 on no data
                        this.snackBarNotificationService.raiseNotification(this.noDataMsg, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        }, false);
                    }

                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    if (error.error && error.error.responseMessage) {
                        this.snackBarNotification(error.error.responseMessage, 3000, 'custom-error-snack-bar', false);
                    }
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            ),
        );
    }
    public snackBarNotification(message: string, duration, notificationClass, dismiss = true) {
        this.snackBarNotificationService.raiseNotification(message, this.dismissText, {
            panelClass: notificationClass,
        }, dismiss);
    }
    public contactUs() {
        this.dialog.open(ContactUsComponent, {
            disableClose: true,
        });
    }

    public drawMarkers() {
        if (this.drawLocationList && this.drawLocationList.length > 0) {
            this.drawLocationList[0]['geographicLoc'] = {
                latitude: this.drawLocationList[0].latitude,
                longitude: this.drawLocationList[0].longitude,
                elevation: 0,
            };
            this.markers = this.drawLocationList;
        }
    }

    public onPageChange(event) {
        this.getCalculatedEntityDetails(event);
    }

    private getlatesMonitorDetails(monitors: Array<MonitorSeriesUI>) {
        this.monitorSeriesUI = monitors;
    }

    public getCalculatedEntityDetails(event?) {
        if (event) {
            this.startPage = event.pageIndex + 1;
            this.pageSize = event.pageSize;
            this.calcualtedEntityPaginator.pageIndex = event.pageIndex;
            this.calcualtedEntityPaginator.pageSize = this.pageSize;
        } else {
            this.pageSize = 10;
            this.startPage = 0;
        }

        this.subscriptions.push(
            this.calculatedEntitiesService
                .getCalculatedEntitiesDetails(this.customerId, this.locationId, this.startPage, this.pageSize)
                .subscribe(
                    (result) => {
                        if (result !== undefined) {
                            const dataSource = result.filter((x) => x.calculatedEntityId > 0);
                            this.ansrEntities = dataSource;
                            this.viewDataLocationDetailsDataSource = new MatTableDataSource(dataSource || []);
                            this.viewDataLocationDetailsDataSource.paginator = this.calcualtedEntityPaginator;
                            this.viewDataLocationDetailsDataSource.sort = this.calcualtedEntitySort;
                            this.totalPaginationLength = dataSource.length;
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        } else {
                            this.totalPaginationLength = 0;
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        }
                    },
                    (error) => {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                ),
        );
    }

    public deleteCalculatedEntity(currentCalculatedEntity) {
        this.subscriptions.push(
            this.dialog
                .open(ConfirmationDialogComponent, {
                    data: {
                        title: this.titleText,
                        message: this.deleteCalculatedEntityText + ' ' + currentCalculatedEntity.name + '?',
                        cancelText: this.cancelText,
                        okText: this.okDeleteText,
                    },
                })
                .afterClosed()
                .subscribe((res) => {
                    if (res && res.whichButtonWasPressed.toLowerCase() === this.okText.toLowerCase()) {
                        this.isLoading = true;
                        this.subscriptions.push(
                            this.calculatedEntitiesService
                                .deleteCalculatedEntity(
                                    currentCalculatedEntity.calculatedEntityId,
                                    this.locationId,
                                    this.customerId,
                                )
                                .subscribe(
                                    (result) => {
                                        this.snackBar.open(this.deleteSuccess, this.dismissText, {
                                            duration: 10000,
                                        });
                                        this.uiUtilsService.safeChangeDetection(this.cdr);

                                        this.getCalculatedEntityDetails();
                                    },
                                    (error) => {
                                        this.snackBar.open(this.deleteFailure, this.dismissText, {
                                            panelClass: 'custom-error-snack-bar',
                                        });
                                        this.uiUtilsService.safeChangeDetection(this.cdr);

                                        this.getCalculatedEntityDetails();
                                    },
                                ),
                        );
                    }
                }),
        );
    }

    public editCalculatedEntity(editCalculatedEntity) {
        this.isLoading = true;
        this.subscriptions.push(
            this.calculatedEntitiesService
                .getCalculatedEntitiesById(editCalculatedEntity.calculatedEntityId, this.customerId, this.locationId)
                .subscribe(
                    (result) => {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.openCalcuatedEntityModal(result);
                    },
                    (error) => {
                        this.isLoading = false;
                        this.snackBar.open(this.editFailure, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                ),
        );
    }

    public getcurrentLocationDetails(isLocationChanged: boolean, updateAllLocations = true) {
        this.isLoading = true;

        this.locationDetails = <LocationDetails>{};
        this.locationSeries = 'result.series';
        this.locationDetails.coordinate = <Coordinate>{};

        // get all customer summary
        this.subscriptions.push(
            this.mapService.getMarkerLocationDetails(this.locationId, this.customerId).subscribe(
                (result: any) => {
                    if (
                        !(
                            result === undefined ||
                            result === null ||
                            result.locationID === undefined ||
                            result.locationID === 0
                        )
                    ) {
                        this.isLoading = false;

                        // set data for location detail
                        this.locationDetails.series = result.series;
                        const series = MonitorSeries.find(a=> a.value.toLowerCase() === String(result.series).toLowerCase());
                        if(series){
                            this.monitorSeries = series.text ;
                        }
                        this.locationSeries = result.series;
                        this.isConfirmationsEnabled = result.series === MONITOR_SERIES_TYPES.RAIN_ALERT_III.toUpperCase()
                            || result.series === MONITOR_SERIES_TYPES.RAIN_ALERT_II.toUpperCase();
                        this.isRainAlertIII =
                            String(result.series).toUpperCase() === MONITOR_SERIES_TYPES.RAIN_ALERT_III;
                        this.locationDetails.serialNumber = result.serialNumber;
                        this.locationDetails.status = result.status;
                        this.locationDetails.description = result.description;
                        this.locationDetails.manholeAddress = result.manholeAddress;
                        this.locationDetails.coordinate = <Coordinate>{};
                        this.locationDetails.coordinate.latitude = result.latitude;
                        this.locationDetails.coordinate.longitude = result.longitude;
                        this.locationDetails.locationID = result.locationID;
                        this.locationDetails.locationName = result.locationName;
                        this.locationDetails.assignedRainGaugeLocationId = result.assignedRainGaugeLocationId;
                        this.locationDetails.installationHeight = result.height;
                        this.locationDetails.height = result.range;
                        this.locationDetails.installationWidth = result.width;
                        this.locationDetails.installationType = result.installationType;
                        this.locationDetails.installationId = result.installationID;
                        this.locationDetails.depthUnit = result.depthUnit;
                        this.locationDetails.flowUnit = result.flowUnit;
                        this.locationDetails.locationType = result.components ? 3 : 1;
                        this.locationDetails.components = result.components;
                        this.locationDetails.ipaddress = result.connectionString;
                        this.locationDetails.manholedepth = result.manholeDepth;
                        this.locationDetails.width = result.width;
                        this.locationDetails.installationShape = result.installationShape;
                        this.locationDetails.installationShapeTypeID = result.installationShapeTypeID;
                        this.locationDetails.range = result.range;
                        this.locationDetails.capacity = result.capacity;
                        this.locationDetails.entries = result.entries;
                        this.locationDetails.length = result.length;
                        this.locationDetails.breadth = result.breadth;
                        this.locationDetails.coefficient = result.coefficient;
                        this.locationDetails.telemetryStart = result.telemetryStart;
                        this.locationDetails.telemetryEnd = result.telemetryEnd;
                        this.locationDetails.datum = result.datum;
                        this.locationDetails.referencePoint = result.referencePoint;
                        this.locationDetails.physicalOffset = result.physicalOffset;
                        this.communicationType = result.communicationType;

                        this.assignRGname();

                        this.locationDetailsObj = <LocationDetailsModel>{
                            visibleLocations: this.locations.map((loc: Locations) => {
                                if (loc) {
                                    return loc.locationId;
                                }
                            }),
                            locationDetails: this.locationDetails,
                        };

                        this.installationDetails =
                            this.locationDetails.installationType + ': ' + this.locationDetails.installationShape;
                        if (this.locationDetails.installationType === this.rainGauge) {
                            this.installationDetails = RAIN_DEFAULT_INSTALLATION_TYPE;
                        }else if (
                            this.locationDetails.installationType &&
                            this.locationDetails &&
                            this.locationDetailsObj.locationDetails &&
                            this.locationDetailsObj.locationDetails.installationShapeTypeID > 0
                        ) {
                            this.installationDetails = '';
                        }
                        // Logic coming from the triton-basic-info file around line 503
                        // that if the series is foreSITE, check for elevation or channel
                        if (this.locationDetails.series?.toUpperCase()  === MonitorSeriesNames.ForeSITE_UL || this.locationDetails.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex) {
                            this.installationDetails = this.locationDetails?.installationShapeTypeID === Series.Elevation ? this.elevation : this.channelStandard;
                        }

                        this.isRainGauge =
                            this.locationDetails.series === MONITOR_SERIES_TYPES.RAIN_ALERT_III ||
                            (this.locationDetails.series === MONITOR_SERIES_TYPES.RAIN_ALERT_II &&
                                this.locationDetails.installationType === this.rainGauge);

                        const isMonitorSubs = this.usersService.isMonitorEditor.subscribe((response) => {
                            if (response) {
                                const dignosticsDetailsSub = this.monitorDiagnosticsService
                                    .getDiagnosticsDetails(
                                        this.locationDetails.series as MONITOR_SERIES_TYPES,
                                        this.locationId,
                                        this.customerId,
                                    )
                                    .subscribe(({ showDiagnostics, deviceTypes }) => {
                                        this.showLocationDiagnostics = showDiagnostics;
                                        this.deviceTypes = deviceTypes;
                                    });
                                this.subscriptions.push(dignosticsDetailsSub);
                            }
                        });

                        if(isLocationChanged){
                        // get daily summary data
                        this.dailySummaryData = this.getDailySummaryGraphData().pipe(
                            tap((data: IDailySummaryLocationDetail) => {
                                if (!data) {
                                    return;
                                }
                            }),
                        );

                        // get uptime data
                        this.uptimeData = this.getUptimeData().pipe(
                            tap((data: Array<{}>) => {
                                if (!data) {
                                    return;
                                }
                            }),
                        );
                    }


                        this.subscriptions.push(isMonitorSubs);
                        this.locationDetails.installationShapeTypeID = result.installationShapeTypeID;
                        // get selected monitor identifier
                        const monitorId = this.getMonitorSeriesId(this.locationDetails.series);
                        this.setupInstallationTypeDropDown(monitorId);

                        // draw markers on map
                        this.drawLocationList = [result];

                        this.setMapLocationsCenter();
                        this.drawMarkers();
                        if (updateAllLocations) {
                            this.getAllLocations();
                        }
                        this.locAtiveStatusStr =
                            this.locationDetails.status === LocationStatus.Active ||
                            this.locationDetails.status === LocationStatus.Maintenance
                                ? this.isLocAtiveStr
                                : this.isLocInAtiveStr;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    } else if (result == null) {
                        this.isLoading = false;
                    }
                },
                () => {
                    this.isLoading = false;
                },
            ),
        );

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private pickMapMonitorColor(feature: Feature): string {
        const properties = feature.getProperties();
        const isDarkTheme = this.statusCodeService.userInfoThemeBS.getValue();

        if (!properties.IsActiveLocation) {
            return isDarkTheme ? darkThemeColors.grey : lightThemeColors.grey;
        }

        if (properties.AlarmId) {
            if (properties.AcknowledgedBy) {
                return isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
            } else {
                return isDarkTheme ? darkThemeColors.red : lightThemeColors.red;
            }
        }

        return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
    }

    public getMarkerLocationDetails(location: Locations) {}

    public getMarkerLocations(location: Locations) {}

    private setupInstallationTypeDropDown(monitorId: number) {
        // set the appropriate installation types
        const instalationTypeUI = this.locationService.getInstallationType(monitorId);
        instalationTypeUI.forEach((installationList) => {
            installationList.forEach((installationItem) => {
                const colonIndex = installationItem.value.indexOf(':');
                const newInstallationId = Number(installationItem.value.substr(colonIndex + 2));
                if (newInstallationId === Number(this.locationDetails.installationShapeTypeID)) {
                    this.installationDetails = installationItem.text;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
        });
    }

    /**
     * Retrieves the monitor identifier object based on the monitor name.
     * @param monitorSeriesName The name of the monitor series.
     */
    private getMonitorSeriesId(monitorSeriesName: string): number {
        // find associated monitor
        const selectedMonitor = this.monitorSeriesUI.find(
            (monitor: MonitorSeriesUI) => monitor.modelName === monitorSeriesName,
        );

        return selectedMonitor ? selectedMonitor.monitorSeriesID : undefined;
    }

    public collectLocation(locationID: number) {
        let handleBackCollect = true;
        this.isLoading = true;
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                // get updated customer id
                const currentCustomerID = Number(params.get(customerQueryParam));
                this.subscriptions.push(
                    this.locationService.getLocationDetails(currentCustomerID, locationID).subscribe((result) => {
                        let lastCollectDate;
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        const dateFormat = this.dateutilService.dateFormat.getValue().toUpperCase();
                        if (result) {
                            if (dateFormat === 'MM/DD/YYYY' || dateFormat === 'YYYY/MM/DD') {
                                lastCollectDate = new Date(result.lastCollectedDate);
                            } else if (dateFormat === 'DD/MM/YYYY') {
                                lastCollectDate = new Date(
                                    String(result.lastCollectedDate).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'),
                                );
                            }
                            this.communicationType = result.communicationType;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        } else {
                            lastCollectDate = '';
                        }
                        if (handleBackCollect) {
                            handleBackCollect = false;
                            this.dialog
                                .open(DataCollectionComponent, {
                                    disableClose: true,
                                    data: lastCollectDate,
                                })
                                .afterClosed()
                                .subscribe((response) => {
                                    // if response true that denoted that collect button press from data-collection will be called
                                    if (response && response.success && response.startDate && response.endDate) {
                                        const startdate = new Date(
                                            response.startDate.getTime() -
                                                response.startDate.getTimezoneOffset() * 60000,
                                        ).toISOString();
                                        // moment.utc(response.startDate).format('YYYY-MM-DD HH:mm:ss');
                                        const endDate = new Date(
                                            response.endDate.getTime() - response.endDate.getTimezoneOffset() * 60000,
                                        ).toISOString();
                                        // moment.utc(response.endDate).format('YYYY-MM-DD HH:mm:ss');
                                        this.isCollecting = true;
                                        this.subscriptions.push(
                                            this.collectService
                                                .collectLocation(
                                                    this.customerId,
                                                    locationID,
                                                    startdate.split('.')[0],
                                                    endDate.split('.')[0],
                                                )
                                                .subscribe(
                                                    (data) => {
                                                        this.isCollecting = false;
                                                        this.snackBar.open(this.message, this.actionLabel, {
                                                            duration: 10000,
                                                        });
                                                    },
                                                    (err) => {
                                                        this.isCollecting = false;
                                                        this.subscriptions.push(
                                                            this.statusCodeService.statusCode409.subscribe(
                                                                (response2: boolean) => {
                                                                    if (response2) {
                                                                        /* let simpleSnackBarRef = this.snackBar.open('Location(s)
                                                            already scheduled for collect', 'Dismiss');
                                                            setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 3000); */
                                                                        this.snackBar.open(
                                                                            this.locationScheduleMessage,
                                                                            this.actionLabel,
                                                                            {
                                                                                panelClass: 'custom-error-snack-bar',
                                                                            },
                                                                        );
                                                                    }
                                                                },
                                                            ),
                                                        );
                                                    },
                                                ),
                                        );
                                    }
                                });
                        }
                    }),
                );
            }),
        );
    }

    /**
     * Method which opens the schedule collection editor popup
     */
    public scheduleCollection() {
        this.disableSchedule = false;
        this.isLoading = true;
        // intialize location parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            locationGroupId: this.locationGroupId,
            validCommunications: true,
        };

        const locationsObservable: Observable<Locations> = this.locationDashboardService.getLocationsList(locationArgs);

        const schedulesObservable: Observable<ScheduleCollection[]> =
            this.scheduleCollectionService.getScheduleCollection(this.customerId);

        this.subscriptions.push(
            forkJoin(locationsObservable, schedulesObservable).subscribe(
                (result: [Locations, ScheduleCollection[]]) => {
                    // this.zone.run(() => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);

                    this.dialog
                        .open(CollectionWidgetScheduleComponent, {
                            disableClose: true,
                            data: {
                                locations: result[0],
                                schedules: result[1],
                            },
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this.disableSchedule = true;
                        });
                    // });
                },
            ),
        );
        // });
    }

    public editLocation() {
        // Additional Check added to open Triton+ popup if series matches
        if (this.locationDetails.locationType === 1 && this.locationDetails) {
            const dialogOptions: MatDialogConfig = {
                disableClose: true,
                data: {
                    rainGaugeLocations: this.assignedRainGauge,
                    customerId: this.customerId,
                    editMode: true,
                    locationDetails: this.locationDetails,
                },
            };

            if (this.gisService.locationCardPosition) {
                dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
            }

            if (this.mapService.addEditLocationDialog) {
                this.mapService.addEditLocationDialog.close();
            }

            this.mapService.addEditLocationDialog = this.dialog.open(TritonLocationDialogComponent, dialogOptions);
            this.mapService.addEditLocationDialog
                .afterClosed()
                .pipe(first())
                .subscribe((result: boolean) => {

                    if(result) {
                        this.drawPipe();
                    }
                    this.mapService.addEditLocationDialog = null;
                    this.getAllLocations(true);
                    this.getcurrentLocationDetails(false);
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                });
        } else if (this.locationDetails.locationType === 3 && this.locationDetails) {
            const dialogOptions: MatDialogConfig = {
                disableClose: true,
                data: [this.locationDetails, this.locations, this.customerId],
            };

            if (this.gisService.locationCardPosition) {
                dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
            }
            this.mapService.openEditCompositeDialog(dialogOptions).subscribe((result) => {
                this.getcurrentLocationDetails(false);
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });
        } else if (this.locationDetails) {
            this.mapService
                .openEditMarkerDialog({
                    disableClose: true,
                    data: {
                        openEditDialogFromCustomerEdit: true,
                        CustomerId: this.customerId,
                        locationList: this.locations,
                        assignedRainGauge: this.assignedRainGauge,
                        location: this.locationDetails.locationID,
                    },
                })
                .subscribe((result) => {
                    this.getcurrentLocationDetails(false);
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                });
        }
    }

    public onChangeLocation(name: string) {
        const location = this.locations.find((loc) => loc.locationName === name);
        if (location && location.locationId !== this.locationId) {
            this.locationId = location.locationId;
            const queryParams = {
                lid: location.locationId,
            };
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams,
                replaceUrl: true,
                queryParamsHandling: 'merge',
            });
            this.getcurrentLocationDetails(true);
            this.getConfirmationPoints();
            this.getCalculatedEntityDetails();
            this.getFlowBalanceData();

            // get percent full data
            this.percentFullData = this.getPercentFullGraphData().pipe(
                tap((data: PercentFullData) => {
                    if (!data) {
                        return;
                    }
                }),
            );
        }
    }

    public redirectToFlowbalanceReport() {
        if (!this.flowBalanceData || this.flowBalanceData.length < 1) {
            this.snackBar.open(this.generateReportFailureMessage, this.dismissText, {
                panelClass: 'custom-error-snack-bar',
            });
            return;
        }
        this.statusCodeService.redirectedFrom.next('location');
        this.router.navigate(['/pages/menuDashboard/locationFlowBalance'], {
            queryParams: <AppQueryParams>{
                c: this.customerId,
                lid: this.locationDetails.locationID,
                lg: this.locationGroupId
            },
            relativeTo: this.activatedRoute,
        });
        this.usersService.isFlowBalanceReport = true;
    }

    public assignRGname(): void {
        this.locationDetails.assignedRainGaugeLocationName =
            this.locationDetails.assignedRainGaugeLocationId &&
            this.assignedRainGauge &&
            this.assignedRainGauge.length > 0
                ? this.assignedRainGauge.find((l) => l.locationId === this.locationDetails.assignedRainGaugeLocationId)
                      ?.locationName
                : '';
    }

    public getAllLocations(fromLocationUpdate = false) {
        // initialize location parameter
        const includeInactiveLocations = this.statusCodeService.activeInactiveHandler.getValue();
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            locationGroupId: this.locationGroupId,
            IncludeInactiveLocations: includeInactiveLocations,
        };

        // #22011 - has to get all RGs
        const rgParameters = {
            CID: this.customerId,
            IncludeInactiveLocations: true,
            IType: 'RainGauge',
            PageSize: 1000,
            StartPage: 1,
        };

        this.subscriptions.push(
            combineLatest([
                this.locationDashboardService.getLocations(locationArgs),
                this.customerService.getAllRainGauges(rgParameters),
            ]).subscribe(
                ([result, rgs]) => {
                    if (!this.locations) {
                        this.locations = [];
                    }
                    this.locationsLoaded = true;
                    this.locations.length = 0;

                    this.locations = result;

                    this.assignedRainGauge = rgs as Locations[];
                    this.assignRGname();

                    this.getSelectableLocations(locationArgs, fromLocationUpdate);
                    if (this.locations && this.locations.length > 0) {
                        this.setupRainGaugeLocations();
                    }
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    // empty block
                },
            ),
        );
    }

    // if we are calling after setting location to inactive, we need to select next location on the list
    private getSelectableLocations(locationArgs: LocationArgs, checkIfCurrentLocationExists: boolean) {
        const params = {
            cid: locationArgs.customerId,
            LocationGroupId: locationArgs.locationGroupId,
            IncludeInactiveLocations: locationArgs.IncludeInactiveLocations,
        };

        this.locationService.getLocationData(params).subscribe((locations: LocationEntitiesData) => {
            if (!locations) {
                this.selectableLocations = [];
                return;
            }

            const newSelectableLocations = locations.l.map((v) => ({ id: v.lid, name: v.n }));

            // if current location is no more in the list, select next location from the list
            if (checkIfCurrentLocationExists && newSelectableLocations.length && !newSelectableLocations.find(v => v.id === this.locationId)) {
                const oldLocationIndex = this.selectableLocations.findIndex(v => v.id === this.locationId);
                const newLocationName = newSelectableLocations[oldLocationIndex] ? newSelectableLocations[oldLocationIndex].name : newSelectableLocations[0].name;

                this.selectableLocations = newSelectableLocations;
                this.onChangeLocation(newLocationName);
            } else {
                this.selectableLocations = newSelectableLocations;
            }

            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    private setupRainGaugeLocations() {
        // check if rain guage locations are already filled and if so exit immediately
        if (this.assignedRainGauge && this.assignedRainGauge.length > 0) {
            return;
        }

        // check if locations exist
        if (this.locations) {
            // make the filtered array for assignedRainGauge.
            this.assignedRainGauge = this.locations.filter((location) => {
                if (location && location.series && location.series.toLowerCase().indexOf('rainalert') > -1) {
                    return location;
                }
            });
        }
    }

    /**
     * Retrieves Percent Full data for the current location dashboard filters.
     * @param filters Represents the location dashboard filters.
     */
    private getPercentFullGraphData(): Observable<PercentFullData> {
        this.percentFullLoading = true;

        const parameters = <PercentFullReportArgs>{
            CustomerId: this.customerId,
            LocationIds: [this.locationId],
            Start: [
                this.oneDayStartDate.getMonth() + 1,
                this.oneDayStartDate.getDate(),
                this.oneDayStartDate.getFullYear(),
            ].join('-'),
            End: [
                this.oneDayEndDate.getMonth() + 1,
                this.oneDayEndDate.getDate(),
                this.oneDayEndDate.getFullYear(),
            ].join('-'),
        };

        return this.percentFullReportService
            .getPercentFullReport(parameters)
            .pipe(tap(() => (this.percentFullLoading = false)))
            .pipe(
                catchError((error: Error, caught: Observable<PercentFullData>) => {
                    this.percentFullLoading = false;

                    return of(null);
                }),
            );
    }

    private getPercentOneDayData(): Observable<PercentFullData> {
        const today = new Date();
        const parameters = <PercentFullReportArgs>{
            CustomerId: this.customerId,
            LocationIds: [this.locationId],
            Start: [
                today.getMonth() + 1,
                today.getDate(),
                today.getFullYear(),
            ].join('-'),
            End: [
                today.getMonth() + 1,
                today.getDate(),
                today.getFullYear(),
            ].join('-'),
        };

        return this.percentFullReportService.getPercentFullReport(parameters);
    }

    private checkforForesiteLocation(){
        this.isForesite = false;
        this.isElevationInstallType = false;
        if(this.locationDetails?.series?.toUpperCase() === MONITOR_SERIES_TYPES.FORE_SITE.toUpperCase() || this.locationDetails?.series?.toUpperCase() === MONITOR_SERIES_TYPES.FORE_SITE_FLEX.toUpperCase()) {
            this.isForesite = true;
        if(this.locationDetails.installationType === INSTALLATION_TYPE.ELEVATION){
            this.isElevationInstallType = true;
        }
    }
    }
    /**
     * Retrieves Daily Summayr data for the current location dashboard filters.
     * @param filters Represents the location dashboard filters.
     */
    private getDailySummaryGraphData(): Observable<IDailySummaryLocationDetail> {
        this.dailySummaryLoading = true;
        this.checkforForesiteLocation();
        let entityIds: number[] = [] ;
        if(this.isForesite){
            if(this.isElevationInstallType)
                entityIds = [RAIN_ENTITY, ELEVATION_ENTITY];
            else
                entityIds = [RAIN_ENTITY, DEPTH_ENTITY]
        };

        const parameters = <DailySummaryDetailsReportArgs>{
            CustomerId: this.customerId,
            LocationIds: [this.locationId],
            Start: [
                this.oneDayStartDate.getMonth() + 1,
                this.oneDayStartDate.getDate(),
                this.oneDayStartDate.getFullYear(),
            ].join('-'),
            End: [
                this.oneDayEndDate.getMonth() + 1,
                this.oneDayEndDate.getDate(),
                this.oneDayEndDate.getFullYear(),
            ].join('-')
        };

        return this.dailySummaryReportService
            .getLocationGraphData(parameters, entityIds)
            .pipe(tap(() => (this.dailySummaryLoading = !this.dailySummaryLoading)))
            .pipe(
                catchError((error: Error, caught: Observable<IDailySummaryLocationDetail>) => {
                    this.dailySummaryLoading = !this.dailySummaryLoading;

                    return of(null);
                }),
            );
    }

    /**
     * Retrieves Daily Summayr data for the current location dashboard filters.
     * @param filters Represents the location dashboard filters.
     */
    private getUptimeData(): Observable<Array<Object>> {
        this.uptimeLoading = true;
        this.checkforForesiteLocation();
        let entityIds: number[] = [] ;
        if(this.isForesite){
            if(this.isElevationInstallType)
                entityIds = [RAIN_ENTITY, ELEVATION_ENTITY];
            else
            entityIds = [RAIN_ENTITY, DEPTH_ENTITY]
        };
        const parameters = <UptimeDetailsReportArgs>{
            CustomerId: this.customerId,
            LocationIds: [this.locationId],
            Start: [
                this.oneDayStartDate.getMonth() + 1,
                this.oneDayStartDate.getDate(),
                this.oneDayStartDate.getFullYear(),
            ].join('-'),
            End: [
                this.oneDayEndDate.getMonth() + 1,
                this.oneDayEndDate.getDate(),
                this.oneDayEndDate.getFullYear(),
            ].join('-')
        };

        return this.upatimeReportService
            .getUptimeReportForLocation(parameters, entityIds)
            .pipe(tap(() => (this.uptimeLoading = !this.uptimeLoading)))
            .pipe(
                catchError((error: Error, caught: Observable<Array<Object>>) => {
                    this.uptimeLoading = !this.uptimeLoading;

                    return of(null);
                }),
            );
    }

    public onChange(e: MatSlideToggleChange) {
        this.isLoading = true;

        this.subscriptions.push(
            this.customerLocationService.editLocation(this.customerId, this.locationId, e.checked).subscribe(
                (response) => {
                    if (response) {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.getcurrentLocationDetails(false);
                    } else {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.getcurrentLocationDetails(false);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.getcurrentLocationDetails(false);
                },
            ),
        );
    }

    public backRedirect() {
        const parameters = <AppQueryParams>{
            c: this.customerId,
            lg: this.locationGroupId,
            // if referring locationId is set, use that instead of the location id
            lid: this.referringLocationId ? this.referringLocationId : this.locationId,
            lt: this.activeAllFlag,
            rc: 1
        };

        // Without this extra navigation, it will not load Location Dashboard (Angular default behavior), to read: routeReuseStrategy
        this.router.navigateByUrl('/', { skipLocationChange: true }).then((data) => {
            this.router.navigate(['/pages/menuDashboard/viewData'], {
                queryParams: parameters,
            });
        });
    }

    /**
     * Responsiblel for setting setting the installation type for the location.
     */
    public updateInstallationType() {
        this.locationDetails.installationShapeTypeID = this.getInstallationType();

        if (this.locationDetails.installationType === INSTALLATION_TYPE.CHANNEL) {
            this.locationDetails.entries = this.locationDetailsObj.locationDetails.entries;
        }
    }

    private getInstallationType(): number {
        const colonIndex = this.installationDetails.indexOf(':');
        return Number(this.installationDetails.substr(colonIndex + 2));
    }

    public addNote() {
        // this.dialog.open(NoteDetailsComponent, {
        //     disableClose: true,
        //     data: ''
        // }).afterClosed().subscribe(result => {
        //     // to do
        // });
    }

    public getCustomerUnits() {
        this.subscriptions.push(
            this.customerService.getCustomerUnits(this.customerId).subscribe(
                (response: CustomerUnits) => {
                    if (response) {
                        this.depthUnit = response.depthUnit;
                        this.velocityUnit = response.velocityUnit;
                        this.flowUnit = response.flowUnit;
                        this.flowSmallerUnit = response.flowSmallerUnit;
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            ),
        );
    }

    public renderCreateSection(event: boolean, isFlowBalance?: boolean) {
        if (isFlowBalance) {
            this.showEditFlowBalance = event;
            this.getFlowBalanceData();
        } else {
            this.showCreateSection = event;
            this.getCalculatedEntityDetails();
        }
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public exportConfirmationsToCSV() {
        const includeInactiveLocations = this.statusCodeService.activeInactiveHandler.getValue();
        this.dialog.open(DownloadConfirmationsDialogComponent, {
            data: {
                locationId: this.locationId,
                locationName: this.locationDetails.locationName,
                locationGroupId: this.locationGroupId,
                includeInactiveLocations,
                customerId: this.customerId,
                isMetric: this.isMetric,
                flowSmallerUnit: this.flowSmallerUnit
            },
        });
    }

    public navigateToScheduleCollectDashboard(locationId?: number) {
        if (locationId) {
            this.subscriptions.push(
                this.scheduleCollectionService.getScheduleByLocationId(this.customerId, locationId).subscribe(
                    (response) => {
                        if (response && response.scheduleId) {
                            this.subscriptions.push(
                                this.dialog
                                    .open(AdsPrismAddScheduleComponent, {
                                        disableClose: true,
                                        data: {
                                            schedules: this.allSchedules,
                                            customerId: this.customerId,
                                            locationId: locationId,
                                            selectedScheduleId: response.scheduleId,
                                            isEditMode: response.scheduleId ? true : false,
                                        },
                                        autoFocus: false
                                    })
                                    .afterClosed()
                                    .subscribe((res) => {
                                        if (res.success) {
                                            this.loadScheduleData();
                                        }
                                    }),
                            );
                        } else {
                            this.subscriptions.push(
                                this.dialog
                                    .open(AdsPrismAddScheduleComponent, {
                                        disableClose: true,
                                        data: {
                                            schedules: this.allSchedules,
                                            customerId: this.customerId,
                                            locationId: locationId,
                                            isEditMode: false,
                                        },
                                        autoFocus: false
                                    })
                                    .afterClosed()
                                    .subscribe((res) => {
                                        if (res.success) {
                                            this.loadScheduleData();
                                        }
                                    }),
                            );
                        }
                    },
                    () => {
                        this.subscriptions.push(
                            this.dialog
                                .open(AdsPrismAddScheduleComponent, {
                                    disableClose: true,
                                    data: {
                                        schedules: this.allSchedules,
                                        customerId: this.customerId,
                                        locationId: locationId,
                                        isEditMode: false,
                                    },
                                    autoFocus: false
                                })
                                .afterClosed()
                                .subscribe((res) => {
                                    if (res.success) {
                                        this.loadScheduleData();
                                    }
                                }),
                        );
                    },
                ),
            );
        } else {
        }
    }

    /*
    * Method for going to
    map zoom
	*/
    public zoomtoInital() {
        this.setMapLocationsCenter();
    }
    public zoomIn() {
        this.olMap.getView().setZoom(this.olMap.getView().getZoom() + 1);
    }
    public zoomOut() {
        this.olMap.getView().setZoom(this.olMap.getView().getZoom() - 1);
    }
    /*
     * Method for getting location
     */

    public getGPSLocation() {
        const geoPosition = this.geolocation.getPosition();
        if (geoPosition) {
            this.positionFeature.setGeometry(geoPosition ? new Point(geoPosition) : null);
            this.olMap.getView().setCenter(geoPosition);
            this.olMap.getView().setZoom(18);
        } else {
            //  this.dialog.open(LocationStepsGPSComponent, {disableClose: true});
        }
    }

    public drawPipe() {
        if (!this.customerId || !this.locationId) return;

        combineLatest([
            this.locationService.locationCosmoDetails(this.customerId, this.locationId),
            this.unitsService.getUnitsCategories(this.customerId, [UnitCategoryType.Linear, UnitCategoryType.LinearFeet, UnitCategoryType.Area]),
            this.statusCodeService.userInfoThemeBS
        ]).subscribe(([res, units, isDarkTheme]) => {

            this.linearUnitMetric = units.find(x => x.unitCategory === UnitCategoryType.Linear);
            this.linearFeetUnitMetric = units.find(x => x.unitCategory === UnitCategoryType.LinearFeet);
            this.areaUnitMetric = units.find(x => x.unitCategory === UnitCategoryType.Area);

            this.pipeDetails = this.drawPipeHelper.drawPipe(this.pipeCanvas, res, units, isDarkTheme);
        });
    }

    public pipeExportTable() {
        const title =
            this.locationDetails.locationName + '  '
            + this.installationDetails + '  '
            + formatNumber(this.locationDetails.installationHeight,'en-US','1.2-2') + ' x '
            + formatNumber(this.locationDetails.width,'en-US','1.2-2');

        const options = {
            headers: [
                `${this.depthText} (${this.linearUnitMetric?.unitSuffix})`,
                `${this.areaText} (${this.areaUnitMetric?.unitSuffix})`,
                `${this.perimeterText} (${this.linearUnitMetric?.unitSuffix})`,
                `${this.chordText} (${this.linearUnitMetric?.unitSuffix})`
            ],
            showLabels: true
        };


        const formattedData = this.pipeDetails.map((r) => {
            const retObj = {};
            retObj[this.depthText] = r.d;
            retObj[this.areaText] = r.a;
            retObj[this.perimeterText] = r.p;
            retObj[this.chordText] = r.c;

            return retObj;
        });

        new AngularCsv(formattedData, title, options);
    }
}
export interface CalculatedEntitiesModel {
    calculatedEntityId: number;
    name: string;
    description: string;
}

export interface PlottingConfirmationsElement {
    timestamp: string;
    depth: string;
    velocity: string;
    comment: string;
    createdBy: string;
}
export interface DataElement {
    name: string;
    description: string;
}
export interface NoteElement {
    category: string;
    description: string;
    startdate: string;
    enddate: string;
}

const DATA_ELEMENT_DATA: DataElement[] = [
    {
        name: 'Entity_raw',
        description: '',
    },
    {
        name: 'Calculated Entity 1',
        description: 'Lorem Ipsum simply dummy text of the printing',
    },
    {
        name: 'Calculated Entity 2',
        description: 'Lorem Ipsum simply dummy text',
    },
    {
        name: 'Calculated Entity 3',
        description: 'Lorem Ipsum simply dummy text of the printing',
    },
];
const NOTES_ELEMENT_DATA: NoteElement[] = [
    {
        category: 'General',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 16:00',
        enddate: '2016/07/15 16:00',
    },
    {
        category: 'Weather Condition',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 16:30',
        enddate: '2016/07/15 16:30',
    },
    {
        category: 'Hydraulic Event',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 17:00',
        enddate: '2016/07/15 17:00',
    },
    {
        category: 'Site Condition',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 17:30',
        enddate: '2016/07/15 17:30',
    },
    {
        category: 'Work Order',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 18:00',
        enddate: '2016/07/15 18:00',
    },
    {
        category: 'Public Event',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 18:30',
        enddate: '2016/07/15 18:30',
    },
    {
        category: 'Hydraulic Event',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 19:00',
        enddate: '2016/07/15 19:00',
    },
    {
        category: 'Site Condition',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 19:30',
        enddate: '2016/07/15 19:30',
    },
    {
        category: 'Work Order',
        description: 'Lorem Ipsum is simply dummy text of the printing',
        startdate: '2016/07/15 20:00',
        enddate: '2016/07/15 20:00',
    },
];
