<div id="turnOffWidgetsDialog">
    <div class="popupHeader" mat-dialog-title>
        <span class="design-storm-header">{{ 'HOME.CONFIGURE_WIDGET_DISPLAY' | translate | uppercase}}</span>
    </div>
    <div mat-dialog-content>
        <div class="widgetCheck" *ngFor="let item of filteredWidgets">
            <mat-checkbox
                [disabled]="item.disabled"
                [(ngModel)]="item.checked"
                (ngModelChange)="itemChange($event)"
            >{{item.name | translate | uppercase}}</mat-checkbox>
        </div>
    </div>

    <div mat-dialog-actions class="actions-container">
        <div class="actions-row">
            <button mat-button type="button" (click)="cancel()" color="primary">{{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}</button>
            <button mat-button type="button" (click)="save()" form="stormEditForm" color="primary" [disabled]="!isModified">
                {{ 'SLIICER.COMMON.SAVE_BUTTON_TITLE' | translate }}
            </button>
        </div>
    </div>
</div>
