import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { FlowMonitoringService } from 'app/shared/services/flow-monitoring.service';
import { MatSort, MatSortable } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';

import { DateutilService } from 'app/shared/services/dateutil.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';

import { AppQueryParams, customerQueryParam } from 'app/shared/models/customer';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap, skip, first } from 'rxjs/operators';
import { UsersService } from 'app/pages/admin/users.service';
import { FlowMonitoringReport } from 'app/shared/models/flow-monitoring-config';


enum ColumnNames {
    FmrDates = 'FmrDates'
}

const GENERIC_DATE_FORMAT = 'dd/MM/yyyy';
const DATES_INPUT_LENGTH = 10;


@Component({
    selector: 'ads-flow-monitoring-report',
    templateUrl: './flow-monitoring-report.component.html',
    styleUrls: ['./flow-monitoring-report.component.scss'],
})
export class FlowMonitoringReportComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator) public fmrTemplatePaginator: MatPaginator;
    @ViewChild(MatSort) public fmrTemplateSort: MatSort;

    private customerId: number = null;

    public fmrTemplateDataChange = new BehaviorSubject([]);
    public initialTemplateData: BehaviorSubject<FlowMonitoringReport[]> = new BehaviorSubject<FlowMonitoringReport[]>([]);
    private subscriptions = new Array<Subscription>();

    public fmrTemplateColumns = [
        'name',
        'Author',
        ColumnNames.FmrDates,
        'createdDate',
        'modifiedDate',
        'editTemplate',
        'cloneTemplate',
        'deleteTemplate',
        'generateTemplate',
        'status'
    ];

    // data source for the Material table
    public fmrTemplateDataSource: MatTableDataSource<FlowMonitoringReport>;

    public showTemplateDashboard: boolean;
    public isLoadingState: boolean;
    public totalPaginationLength = 0;
    public templateSearch = new FormControl();

    public listMyTemplatesOnly = false;
    public customerDateFormat: string;

    // Translations

    public cancelText: string;
    public okText: string;
    public okTextClone: string;
    public titleText: string;
    public titleTextClone: string;


    public get fmrTemplateData() {
        return this.fmrTemplateDataChange.value;
    }

    public showClaimStudy = false;

    constructor(
        private utilService: UiUtilsService,
        private flowMonitoringService: FlowMonitoringService,
        private translate: TranslateService,
        public cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dateutilService: DateutilService,
        private usersService: UsersService
    ) {
        const caseStudySub: Subscription = this.templateSearch.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged(), skip(1))
            .subscribe((res: string) => {
                this.filterFMRTemplates(res);
            });
        const dateFormatSub: Subscription = this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat = this.dateutilService.getFormat();
        });

        this.subscriptions.push(caseStudySub, dateFormatSub);
    }

    /**
     * Oninit lifecycle
     */
    public ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                this.customerId = Number(params.get(customerQueryParam));
                this.getFMRTemplates();
            }
        );
        this.applyTranslations(this.translate);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    private applyTranslations(translateService: TranslateService) {
        this.cancelText = translateService.instant('SLIICER.COMMON.CANCEL_BUTTON_TITLE');
        this.okText = translateService.instant('SLIICER.COMMON.DELETE_BUTTON_TITLE');
        this.okTextClone = translateService.instant('SLIICER.COMMON.CLONE_BUTTON_TITLE');
        this.titleText = translateService.instant('SLIICER_TABLE.SLIICER_DIALOG_TITLE');
        this.titleTextClone = translateService.instant('SLIICER_TABLE.SLIICER_DIALOG_TITLE_CLONE');
    }
    /**
       Method to create new fmr template for user
    **/
    public addNewfmrTemplate() {

        const appQueryParams = <AppQueryParams>{
            [customerQueryParam]: this.customerId,
        };
        this.router.navigate(['/pages/report/flowMonitoringReport/details'], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    /**
       Method to generate fmr Template Mat table
    **/
    public generateFMRTemplateTable(fmrTemplates: FlowMonitoringReport[]) {
        if (!fmrTemplates || fmrTemplates.length === 0) {
            this.fmrTemplateDataSource = null;
            this.totalPaginationLength = 0;
            this.utilService.safeChangeDetection(this.cdr);
            return;
        }

        const fmrData = [];
        for (const fmrTemplate of fmrTemplates) {
            const flowMonitoringReportTemplate = {
                name: fmrTemplate.name,
                createdBy: fmrTemplate.author.name,
                FmrDates: this.dateutilService.getFormattedDates(fmrTemplate.template.startTime, fmrTemplate.template.endTime, this.customerDateFormat),
                createdDate: fmrTemplate.createdDate,
                modifiedDate: fmrTemplate.lastUpdate,
                status: fmrTemplate.reportGenerationStatus
            };

            fmrData.push(flowMonitoringReportTemplate);
        }
        this.fmrTemplateDataChange.next(fmrData);

        this.utilService.safeChangeDetection(this.cdr);
        this.resetPageIndex();
        this.fmrTemplateDataSource = new MatTableDataSource(this.fmrTemplateData);
        this.fmrTemplateDataSource.paginator = this.fmrTemplatePaginator;
        this.totalPaginationLength = this.fmrTemplateData.length;

        this.sortApply();

        if (this.fmrTemplateSort) {
            this.fmrTemplateSort.sort(<MatSortable>{
                id: ColumnNames.FmrDates,
                start: 'desc',
            });
            this.fmrTemplateSort.direction = 'desc';
            this.fmrTemplateDataSource.sort = this.fmrTemplateSort;
        }
    }

    public editFMRTemplate($event) {
        //To be added in future
    }

    public cloneFMRTemplate($event) {
        //To be added in future
    }

    public deleteFMRTemplate($event) {
        //To be added in future
    }

    public downloadFMRTemplate($event) {
        //To be added in future
    }

    public generateFMRTemplate($event) {
        //To be added in future
    }

    private sortApply() {
        this.fmrTemplateDataSource.sortingDataAccessor = (data, sortHeaderId: string): string => {
            const columnIndex = this.fmrTemplateColumns.indexOf(sortHeaderId);
            const columnName = columnIndex || columnIndex === 0 ? this.fmrTemplateColumns[columnIndex] : undefined;

            const val = columnName ? data[columnName] : undefined;

            if (columnName === ColumnNames.FmrDates) {
                if (val.length > DATES_INPUT_LENGTH) {
                    if (this.customerDateFormat === GENERIC_DATE_FORMAT) {
                        const day = val.substr(0, 3);
                        const month = val.substr(3, 3);
                        return new Date(month + day + val.substr(6, 4)).toISOString();
                    }
                    return new Date(val.substr(0, 10)).toISOString();
                }
            }
            if (typeof val === 'string') {
                return val.toLocaleLowerCase();
            } else if (val === null || val === undefined) {
                return '';
            }

            return data[sortHeaderId];
        };
    }


    public getFMRTemplates(keepSearchValue: boolean = false) {
        const userId = this.usersService.userID.getValue();
        const flowMonitoringExportSubscription = this.usersService.userSettings.pipe(
            filter(v => !!v),
            first(),
            tap((settings) => this.listMyTemplatesOnly = (settings.fmrTemplateIDFilter && settings.fmrTemplateIDFilter.includes(userId))),
            switchMap(() => this.flowMonitoringService.getFMRTemplates(this.customerId))
        ).subscribe((fmrTemplates: FlowMonitoringReport[]) => {

            if (keepSearchValue === false) {
                this.templateSearch.setValue('');
            }

            if (!fmrTemplates || !fmrTemplates.length) {
                this.showTemplateDashboard = false;
                this.initialTemplateData.next([]);
                this.utilService.safeChangeDetection(this.cdr);

                return;
            }

            this.initialTemplateData.next(fmrTemplates);
            this.showTemplateDashboard = true;
            if (this.listMyTemplatesOnly) {
                const myTemplates = this.filterTemplatesByAuthor(fmrTemplates);
                this.generateFMRTemplateTable(myTemplates);
            } else {
                this.generateFMRTemplateTable(fmrTemplates);
            }
            flowMonitoringExportSubscription.unsubscribe();
        },
            (error) => {
                flowMonitoringExportSubscription.unsubscribe();
            },
        );
    }

    public onShowMyTemplatesChecked() {
        const templates = this.initialTemplateData.getValue();
        this.templateSearch.setValue('', { emitEvent: false });

        if (!this.listMyTemplatesOnly) {
            this.generateFMRTemplateTable(templates);
        }
        else {
            const myStudies = this.filterTemplatesByAuthor(templates);
            this.generateFMRTemplateTable(myStudies);
        }

        const userId = this.usersService.userID.getValue();
        const oldSettings = this.usersService.userSettings.getValue();
        let { fmrTemplateIDFilter = [] } = oldSettings;

        if (this.listMyTemplatesOnly) {
            fmrTemplateIDFilter = [...fmrTemplateIDFilter, userId];
        } else {
            fmrTemplateIDFilter = fmrTemplateIDFilter.filter(v => v !== userId);
        }

        oldSettings.fmrTemplateIDFilter = fmrTemplateIDFilter.filter((v, i) => i === fmrTemplateIDFilter.indexOf(v));

        this.usersService.updateUserSettings({ ...oldSettings }).subscribe(() => {
            this.usersService.userSettings.next({ ...oldSettings });
        });
    }

    private filterTemplatesByAuthor(templates: FlowMonitoringReport[]): FlowMonitoringReport[] {
        const currentUserId = this.usersService.userID.getValue();
        const myTemplates = templates.filter(v => v?.author !== undefined && v?.author.id === currentUserId);

        return myTemplates;
    }

    /**
       Method to show user searched templates if available else show blank screen
    **/
    public filterFMRTemplates(searchString: string) {
        const currentUserId = this.usersService.userID.getValue();
        this.initialTemplateData.subscribe((result) => {
            if (this.listMyTemplatesOnly) {
                result = result.filter(v => v?.author.id === currentUserId);
            }

            if (!searchString || searchString.trim() === '') {
                this.generateFMRTemplateTable(result);
            } else {
                searchString = searchString.trim();
                const searchStringLower = searchString ? searchString.toLowerCase() : null;

                const fmrTemplateData = result.filter((fmrTemplate) => {
                    return (
                        (fmrTemplate.name && fmrTemplate.name.toLowerCase().includes(searchStringLower))
                        || (
                            fmrTemplate.author && fmrTemplate.author.name
                            && fmrTemplate.author.name.toLowerCase().includes(searchStringLower)
                        )
                    );
                });
                this.resetPageIndex();
                this.generateFMRTemplateTable(fmrTemplateData);
            }
        });
    }

    clearSearch(event) {
        if (event) {
            event.stopPropagation();
        }

        this.templateSearch.setValue('');
        this.filterFMRTemplates('');
    }

    private resetPageIndex() {
        if (this.fmrTemplatePaginator && this.fmrTemplatePaginator.pageIndex) {
            this.fmrTemplatePaginator.pageIndex = 0;
        }
    }
}
