import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ANNOTATION_MARKER_COLOR, BPRAIN_ENTITY, DEPTH_ENTITY, RAIN_ENTITY, RAW_VELOCITY_ENTITY, VELOCITY_ENTITY } from 'app/shared/constant';
import { ADMIN_DATE_FORMAT, BLOCKAGE_MARK_THRESHOLD, BLOCKAGE_SCORE_PRECISION, BlockageFlag, BP_DATE_FORMAT, BP_REPORT_COLORS, BP_SCATTERGRAPH_DEFAULT_COLOR, BP_SCATTERGRAPH_LAST_DAY_COLOR, BPReviewBarData, DAYS_TO_PLOT_DEFAULT, HRLegend, HrLegendShapes, HrLocationData, HumanReviewDialogData, HumanReviewOverview, RAIN_ENTITY_IDS } from 'app/shared/models/blockage-prediction';
import { HydrographArgs } from 'app/shared/models/hydrograph';
import { BasicSeriesData } from 'app/shared/models/hydrographNEW';
import { ChartPointMap, compactData, OptimizedViewData } from 'app/shared/models/view-data';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { HydrographNEWService } from 'app/shared/services/hydrographNEW.service';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { BlockagePredictService } from '../blockage-predict.service';
import { environment } from 'app/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TrackBy } from 'app/shared/utils/track-by';
import moment from 'moment';

const ONE_DAY = 1000 * 60 * 60 * 24;
@Component({
    selector: 'app-human-review-report',
    templateUrl: './human-review-report.component.html',
    styleUrls: ['./human-review-report.component.scss']
})

export class HumanReviewReportComponent implements OnInit {
    @Input() overviewReport: HumanReviewOverview;
    @Input() reportDate: Date;
    @Input() daysToPlot: number = DAYS_TO_PLOT_DEFAULT;
    @Input() invertScatter: boolean;

    @Input() dialogData?: HumanReviewDialogData;
    @Output() closeDialog = new EventEmitter()

    bpDateFormat: string = ADMIN_DATE_FORMAT;
    isLoading: boolean = false;
    locationReport: HrLocationData;
    seriesData: BasicSeriesData[];
    selectedReportIndex: number = 0;

    graphMinDateTs: number;
    graphMaxDateTs: number;

    reportScore: string;
    reportScoreColor: string;

    displayScattergraph: boolean;

    hydrographLegends: HRLegend[];
    bargraphLegends: HRLegend[];
    scattergraphLegens: HRLegend[];

    legendShapes = HrLegendShapes;

    trackByIndex = TrackBy.byIndex;
    constructor(
        private viewDataService: ViewDataService,
        private dataEditService: DataEditService,
        public hydrographNewService: HydrographNEWService,
        private datePipe: DatePipe,
        private blockagePredictService: BlockagePredictService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.getLocationReport();
    }

    getLocationReport() {
        this.resetReport();

        // null check
        if (!this.dialogData && (!this.overviewReport || !this.overviewReport.sites || !this.overviewReport.sites.length || !this.overviewReport.sites[this.selectedReportIndex])) {
            return;
        }

        this.isLoading = true;

        let customerId: number;
        let locationId: number;

        if (this.dialogData) {          // dialog view
            customerId = this.dialogData.cid;
            locationId = this.dialogData.lid;
        } else {                        // regular report view
            const { cid, lid } = this.overviewReport.sites[this.selectedReportIndex];

            customerId = cid;
            locationId = lid;
        }

        const date = this.datePipe.transform(this.reportDate, this.bpDateFormat);

        this.blockagePredictService.getHumanReviewByLocation(customerId, locationId, this.daysToPlot, date).subscribe({
            next: data => {
                this.locationReport = data;

                if (this.locationReport && this.locationReport.days && this.locationReport.days.length) {
                    this.locationReport.days.sort((a, b) => {
                        return moment(a.date, BP_DATE_FORMAT).valueOf() - moment(b.date, BP_DATE_FORMAT).valueOf();
                    });

                    const lastDay = this.locationReport.days[this.locationReport.days.length -1];

                    this.reportScore = lastDay.bpscore.toFixed(BLOCKAGE_SCORE_PRECISION) + '%';
                    this.reportScoreColor = this.getScoreColor(lastDay);
                }

                const mlLegend = this.translateService.instant('BLOCKAGE_PREDICT.ML_LEGEND');
                const flagLegend = this.translateService.instant('BLOCKAGE_PREDICT.BP_FLAG_LEGEND');

                this.bargraphLegends = [
                    { name: mlLegend, color: BP_REPORT_COLORS.SCORE_HIGH, shape: HrLegendShapes.Square },
                    { name: flagLegend, color: BP_REPORT_COLORS.SCORE_LOW, shape: HrLegendShapes.Square },
                ];
                this.getHydrographData(data.cid, data.lid);
                this.isLoading = false;
            },
            error: () => this.isLoading = false
        })
    }

    pageChange(newPage: number) {
        this.selectedReportIndex = newPage - 1;     // page index starts from 1

        this.getLocationReport();
    }
    
    overrideLastDay(event: { date: string; flag: BlockageFlag }) {
        const { date, flag } = event;

        this.blockagePredictService.overrideDayFlags(this.locationReport.cid, this.locationReport.lid, date, flag).subscribe(() => {
            const dayIndex = this.locationReport.days.findIndex(v => v.date === date);

            if (dayIndex !== -1) {
                this.locationReport.days[dayIndex].bpflag = flag;
            }

            // if last day was overwritten, update its score color
            if (dayIndex === this.locationReport.days.length - 1) {
                this.reportScoreColor = this.getScoreColor(this.locationReport.days[dayIndex]);
            }
        });
    }

    public getHydrographData(customerId: number, locationId: number) {
        const start = new Date(this.reportDate.getTime() - (ONE_DAY * this.daysToPlot));
        start.setHours(0, 0, 0, 0);
        
        const end = new Date(this.reportDate);
        end.setHours(23, 59, 59);

        this.graphMinDateTs = start.getTime();
        this.graphMaxDateTs = end.getTime();

        // since we do not have BP rain for Dev environment we display Rain
        const rainEntity = environment.production ? BPRAIN_ENTITY : RAIN_ENTITY;
        const args: HydrographArgs = {
            entityIds: [DEPTH_ENTITY, VELOCITY_ENTITY, rainEntity, RAW_VELOCITY_ENTITY],
            locationId: locationId,
            start,
            end
        }
        
        this.viewDataService.getHydrograph(customerId, locationId, args).subscribe((apiData) => {
            let tracker;
            const annotationData = this.dataEditService.handleApiAnnotationsData(apiData, tracker);
            const entitiesData = this.handleApiEntitiesData(apiData, tracker, annotationData);

            this.seriesData = entitiesData;
            this.hydrographLegends = this.seriesData.map(v => ({ 
                name: v.entityName, color: v.color, shape: RAIN_ENTITY_IDS.includes(v.entityId) ? HrLegendShapes.Square : HrLegendShapes.Line
            }));

            const velEntity = this.seriesData.find(v => v.entityId === RAW_VELOCITY_ENTITY || v.entityId === VELOCITY_ENTITY);
            const depthEntity = this.seriesData.find(v => v.entityId === DEPTH_ENTITY);

            const entityExistWithData = (entity: BasicSeriesData): boolean => (entity && entity.data && entity.data.length > 0);
            this.displayScattergraph = entityExistWithData(velEntity) && entityExistWithData(depthEntity);

            if (this.displayScattergraph) {
                const regularDataLegend = this.invertScatter ? this.translateService.instant('BLOCKAGE_PREDICT.DEPTH_VELOCITY_LEGEND') : this.translateService.instant('BLOCKAGE_PREDICT.VELOCITY_DEPTH_LEGEND')
                const lastDayLegend = this.translateService.instant('BLOCKAGE_PREDICT.LAST_DAY_LEGEND')

                this.scattergraphLegens = [
                    { name: regularDataLegend, color: BP_SCATTERGRAPH_DEFAULT_COLOR, shape: HrLegendShapes.Dot },
                    { name: lastDayLegend, color: BP_SCATTERGRAPH_LAST_DAY_COLOR, shape: HrLegendShapes.Dot },
                ];
            }
        })
    }

    private handleApiEntitiesData(apiData: OptimizedViewData, tracker, annotationData) {
        const { entityData } = this.dataEditService.parseCompactData(apiData.compactData, tracker, apiData.entityIds);
        // Create new data structure that hydrograph needs
        const entitiesData: BasicSeriesData[] = [];
        apiData.displayGroups.forEach((group) => {
            group.entities.forEach((entity) => {
                // Check for database entity to ensure entity is valid for device type
                let entityName = entity.name;
                const color = this.hydrographNewService.entityColorParse(entity.id, entity.color);

                let groupedData: BasicSeriesData = {
                    entityName: entityName,
                    axisName: group.label,
                    unitOfMeasure: group.unit,
                    entityId: entity.id,
                    color: entity.id < 0 ? ANNOTATION_MARKER_COLOR : color,
                    precision: group.precision,
                    data: entityData[entity.id],
                    annotations: [],
                    dataType: 2,
                    displayGroupId: 2
                };

                if (entity.id > 0 && group.annotations && group.annotations.length > 0) {
                    group.annotations.forEach((annot) => {
                        if (annot.factor && annotationData) {
                            annotationData[annot.id].forEach(element => {
                                element.y = element.y * annot.factor;
                            });
                        }

                        groupedData.annotations.push({
                            name: annot.name,
                            id: annot.id,
                            color: annot.color,
                            data: annotationData ? annotationData[annot.id] : null,
                            unitOfMeasure: group.unit,
                            precision: group.precision,

                        });
                    });
                }

                entitiesData.push(groupedData);
            });
        });

        return entitiesData;
    }

    private getScoreColor(item: BPReviewBarData): string {
        if (item.bpflag === BlockageFlag.Blockage) return BP_REPORT_COLORS.FLAG_POSITIVE;
        if (item.bpflag === BlockageFlag.NoBlockage) return BP_REPORT_COLORS.FLAG_NEGATIVE;
        if (item.bpflag !== item.bptype && item.bpflag === BlockageFlag.Unflagged) return BP_REPORT_COLORS.SCORE_LOW;

        return item.bpscore >= BLOCKAGE_MARK_THRESHOLD ? BP_REPORT_COLORS.SCORE_HIGH : BP_REPORT_COLORS.SCORE_LOW;
    }

    private resetReport() {
        this.locationReport = null;
        this.reportScore = null;
        this.reportScoreColor = null;
        this.displayScattergraph = false;

        this.hydrographLegends = null;
        this.bargraphLegends = null;
        this.scattergraphLegens = null;
    }
}