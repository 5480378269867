import { Injectable } from '@angular/core';
import { SolidLine, EmptyFill, SeriesXY, UIElementBuilders, UIOrigins, emptyFill, UIDraggingModes, ImageFill, UIBackgrounds, Axis, DateTimeTickStrategy, RectangleSeries, PointShape, TickStyle, FormattingRange, UITextBox, UIBackground, UIElement, MouseStyles, LineSeries } from '@arction/lcjs';
import { AutoCursorModes, AxisTickStrategies, ChartXY, ColorHEX, FontSettings, LegendBoxBuilders, SolidFill, Themes, UILUTCheckBox, emptyLine, emptyTick, lightningChart, synchronizeAxisIntervals } from '@arction/lcjs';
import { DEPTH, QUANTITY_ENTITY, RAIN_ENTITY, RAIN_HEXCODE_UNFLAGGED, VELOCITY } from 'app/shared/constant';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { HydrographNEWService } from 'app/shared/services/hydrographNEW.service';
import { LightningChartObject, LightningChartSerieDefinition } from '../lightning-chart-object/lightning-chart-object';
import { LightningChartTooltip } from '../lightning-chart-object/lightning-chart-tooltip';
import { BasicSeriesData, HGGraphData } from 'app/shared/models/hydrographNEW';
import { HydrographDataEditingService } from 'app/shared/services/hydrograph-data-editing.service';
import { LightningChartEdit } from '../lightning-chart-object/lightning-chart-edit';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { environment } from 'app/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LightningChartSeriesFactory } from './lightning-chart-series-factory';
import { CHART_COLOR_DRAGG_POINT, CHART_COLOR_ZOOMBANDCHART_SERIES, CHART_SERIES_TOP_GAP, JUMP_SCROLL_FONT_DISABLED, JUMP_SCROLL_FONT_ENABLED, JUMP_SCROLL_FONT_ENABLED_DARK, JUMP_SCROLL_FONT_SIZE, LC_MAIN_CHART_MAJOR_TICK_COLOR, LC_MAIN_CHART_MINOR_TICK_COLOR, LC_YAXIS_THICKNESS_LEFT, LC_YAXIS_THICKNESS_RIGHT, LC_ZOOM_BAND_MAJOR_TICK_COLOR, LC_ZOOM_BAND_MINOR_TICK_COLOR, LC_ZOOM_BAND_OVERLAY, LC_ZOOM_BAND_SERIES_BACKGROUND, MAX_X_JOIN_LINE_RANGE, TOOLTIP_BOX_DM_STYLES, TOOLTIP_BOX_STYLES, TOOLTIP_INFO_DM_STYLES, TOOLTIP_INFO_STYLES, Y_AXIS_FONT_SIZE, ZOOMBAND_ONE_CHART_SIZE, ZOOMBAND_THREE_CHARTS_SIZE, ZOOMBAND_TWO_CHARTS_SIZE } from './lightning-chart-ui-constants';
import { HydrographDataBuilder } from '../hydrograph-data/hydrograph-data-builder';
import { ChartSerieType, GraphAnnotationSerieConfiguration, GraphChartConfiguration, GraphEventSerieConfiguration } from '../hydrograph-data/hydrograph-data-model';
import { LCValues, LightningChartConfig, LightningChartDataPoint, LightningChartSerie, LightningChartBuildDataConfig, LegendSeriesDefinition, LegendEventDefinition } from './lightning-chart-data-model';
import { UsersService } from 'app/pages/admin/users.service';
import { DATA_AVERAGING_DAILY } from 'app/shared/models/data-edit';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { EntityData } from 'app/shared/models/scatter-data';

/**
 *
 * Create Lightning Charts Graph.
 *
 * Angular Singleton
 * IMPORTANT: IT SHOULD NOT CONTAIN ANY ATTRIBUTES
 *
 */
@Injectable({
  providedIn: 'root'
})
export class LightningChartBuilder {

    constructor(
        private seriesFactory: LightningChartSeriesFactory,
        private dateutilService: DateutilService,
        private hydroService: HydrographNEWService,
        private editService: HydrographDataEditingService,
        private viewDataService: ViewDataService,
        private separateWindowService: SeparateWindowHydrographService,
        private translate: TranslateService,
        private hydrographDataBuilder: HydrographDataBuilder,
        private usersService: UsersService,
        private dataEditService: DataEditService
    ) {
    }

    private createTooltipDateFormat(): () => string {
        return () => this.dateutilService.formatTooltipForCharts();
    }

    public rebuildChart(
        lcChart: LightningChartObject,
        config: LightningChartBuildDataConfig
    ): LightningChartObject {
        const currentInterval = config.defaultZoom ? {start: config.defaultZoom[0], end: config.defaultZoom[1]} : null;
        const edit = lcChart?.edit;

        // #36798 Maintain visiblity of entity on chart
        if (lcChart && lcChart.seriesDef) {
            if (!config.seriesVisibility) config.seriesVisibility = {};

            for(let cIndex = 0; cIndex < lcChart.chartSeries.length; cIndex++) {
                const chartSeries = lcChart.chartSeries[cIndex];
                if(!chartSeries) continue;

                for(let sIndex = 0; sIndex < chartSeries.length; sIndex++) {
                    if (lcChart.seriesDef[cIndex]) {
                        const series = chartSeries[sIndex];
                        const seriesDef = lcChart.seriesDef[cIndex][sIndex];

                        if(series && seriesDef) {
                            config.seriesVisibility[seriesDef.entityId] = series.getVisible();
                        }
                    }
                }
            }
        }

        lcChart?.destroy();

        if(currentInterval) {
            config.defaultZoom = [currentInterval.start, currentInterval.end];
        }

        const newLcChart = this.buildChart(config);


        if(newLcChart && edit) {
            edit.editService = this.editService;
            edit.lcChart = newLcChart;
            newLcChart.edit = edit;
            newLcChart.edit.applyAllowZoom();

            for(const selectionRectangle of newLcChart.selectionSeries) {
                selectionRectangle.dispose();
            }
            for(const selectionSerie of newLcChart.edit.selection) {
                selectionSerie.dispose();
            }

            for(let i = 0; i < newLcChart.charts.length; i++) {
                const chart = newLcChart.charts[i];
                this.seriesFactory.createSelectionSeries(newLcChart, chart, i);
            }
        }

        return newLcChart;
    }

    public buildSeriesLegendConfig(lcChart: LightningChartObject) {
        const legendDef: LegendSeriesDefinition[] = [];
        lcChart.seriesDef.forEach(chartSeries => {
            chartSeries.forEach((serie) => {
                if(lcChart.seriesDefition[serie.entityId]) {
                    legendDef.push({
                        entityId: serie.entityId,
                        name: serie.entityName,
                        color: serie.color,
                        visible: lcChart.seriesDefition[serie.entityId].visible
                    })
                }
            })
        });

        lcChart.legendSeriesDefinition = legendDef;
    }

    public buildEventLegendConfig(lcChart: LightningChartObject) {
        if(lcChart?.eventSeries) {
            const legendEventDef: LegendEventDefinition[] = [];
            for(const event of lcChart?.eventSeries) {
                legendEventDef.push({
                    etype: event.etype,
                    name: event.name,
                    color: event.color,
                    visible: true
                });
            }
            lcChart.legendEventDefinition = legendEventDef;
        }
    }

    public buildChart(
        config: LightningChartBuildDataConfig,
    ): LightningChartObject {

        const graphObject = this.hydrographDataBuilder.prepareData(config);

        if(config.displayGroupScales) config.customRanges = this.hydrographDataBuilder.createCustomRanges(config.isMetric, config.seriesData, config.displayGroupScales);

        return this.createDashboard(config, graphObject);
    }

    public createDashboard(
        config: LightningChartConfig,
        graphObject: GraphChartConfiguration
    ): LightningChartObject {
        if(!graphObject || !Object.keys(graphObject).length) return null;

        // TODO: PERFORMANCE: On LD it's always called twice on Location change, first time without data
        let chartCount = Math.ceil(graphObject.yAxis.length / 2);
        if(chartCount === 0) return;

        const lcChart = new LightningChartObject();

        lcChart.builder = this;
        lcChart.userSettings = config.userSettings;
        lcChart.annotations = config.annotationSettings;
        lcChart.dateutilService = this.dateutilService;
        lcChart.viewDataService = this.viewDataService;
        lcChart.separateWindowService = this.separateWindowService;
        lcChart.lightningChartReceiver = config.lightningChartReceiver;
        lcChart.showEditingMenu = config.showEditMenu ? config.showEditMenu : () => false;
        lcChart.detectChanges = () => config.lightningChartReceiver.detectChanges();
        lcChart.customRanges = config.customRanges;
        lcChart.isMetrics = config.isMetric;
        lcChart.dataAveraging = config.dataAveraging;
        lcChart.isDarkTheme = config.darkTheme;

        // #40344 DST (Daylight Saving Time) - has to choose correct offset
        const dateStartOffset = new Date(config.startDate).getTimezoneOffset() * 60000;
        const dateEndOffset = new Date(config.endDate).getTimezoneOffset() * 60000;
        lcChart.startTime = config.startDate.getTime() - dateStartOffset;
        lcChart.endTime = config.endDate.getTime() - dateEndOffset;

        // #37303 If license is empty, then create LC without license
        let licenseNumber = environment.lcLicenseNumber === '' ? undefined : environment.lcLicenseNumber;
        if (environment.apiUrl === 'https://localhost:5001/') {
            licenseNumber = '0002-n0bg9FQmAA5budvGEAfOXphMsGcsKwBH4M1APgoET6HRzMi/Fltx2JgSRnTvVUorLtjQAx7Jt5x2W2Auz9oShtbR-MEYCIQD8EMKbNU88+E+zXFatvB/mGjTS+wlKlfQ6tgwgF1RHdQIhALGVuH5g4BsM17/FcNZ0Ek5aijixAkTdoUzVcea0Ml8X';

            lcChart.lightningChartHandle ??= lightningChart({
                license: licenseNumber, resourcesBaseUrl: 'assets/lc',
                licenseInformation: {company: 'LightningChart Ltd.', appTitle:'LightningChart JS Trial'}})

        }
        else {
            lcChart.lightningChartHandle ??= (licenseNumber ?  lightningChart({license: licenseNumber, resourcesBaseUrl: 'assets/lc'}) : lightningChart({resourcesBaseUrl: 'assets/lc'}));
        }

        lcChart.graphGrouping = graphObject.graphGrouping ? graphObject.graphGrouping.filter(v => v.length) : undefined;

        if(lcChart.graphGrouping) chartCount = lcChart.graphGrouping.length;

        lcChart.dashboard ??=
            lcChart.lightningChartHandle.Dashboard({
                theme: config.darkTheme ? Themes.darkGold : Themes.light,
                // By our design chart take twice a space then zoomband chart
                numberOfRows: chartCount * 2 + 1,
                numberOfColumns: 1,
                container: config.chartId === undefined ? undefined : `${config.chartId}`
        });
        lcChart.disposeCharts();
        lcChart.installOnResize();

        // #41397 Adjust zoombandChart row height
        lcChart.dashboard.setRowHeight(chartCount * 2, chartCount === 1 ? ZOOMBAND_ONE_CHART_SIZE : chartCount === 2 ? ZOOMBAND_TWO_CHARTS_SIZE : ZOOMBAND_THREE_CHARTS_SIZE);

        this.generateTooltip(lcChart, config.darkTheme);
        this.generateJumpScrolls(lcChart, config.darkTheme);

        const editAllowed = !!config.allowEditMode;
        this.generateEdit(lcChart, editAllowed);

        if(lcChart.graphGrouping) {
            for(let i = 0; i < chartCount; i++) {
                const yAxesCount = lcChart.graphGrouping[i].length;
                const newChart = this.createChart(lcChart, config, i, chartCount, yAxesCount);
                lcChart.charts.push(newChart);
                if(config.defaultZoom) {
                    newChart.getDefaultAxisX().setInterval({start: config.defaultZoom[0], end: config.defaultZoom[1], stopAxisAfter: false})
                }
            }
        } else {
            for(let i = 0; i < chartCount; i++) {
                const yAxesCount = (graphObject.yAxis[2 * i] ? 1 : 0) + (graphObject.yAxis[2 * i + 1] ? 1 : 0);
                const newChart = this.createChart(lcChart, config, i, chartCount, yAxesCount);
                lcChart.charts.push(newChart);
                if(config.defaultZoom) {
                    newChart.getDefaultAxisX().setInterval({start: config.defaultZoom[0], end: config.defaultZoom[1], stopAxisAfter: false})
                }
            }
        }

        this.createZoomBandChart(lcChart, config.darkTheme, chartCount);

        const syncedAxes = lcChart.charts.map(chart => chart.getDefaultAxisX())
        synchronizeAxisIntervals(...syncedAxes);

        // have to set up axis number for informations that was computed during data creation, they do not come from API
        for(const serie of graphObject.series) {
            if(serie.id) {
                lcChart.seriesDefition[serie.id] = {
                    yAxis: serie.yAxis,
                    hasLegend: serie.hasLegend,
                    hasTooltip: serie.hasTooltip,
                    precision: serie.precision,
                    unitOfMeasure: serie.unitOfMeasure,
                    shape: serie.shape,
                    // #36798 Maintain visiblity of entity on chart
                    visible: config.seriesVisibility ? config.seriesVisibility[serie.id] ?? true : true
                };
            }
        }

        if(graphObject.graphGrouping) {
            for(let c = 0; c < graphObject.graphGrouping.length; c++) {
                for(let y = 0; y < graphObject.graphGrouping[c].length; y++) {

                    const yAxisDef = graphObject.graphGrouping[c][y];
                    const yAxis = lcChart.axisYFor(c, y);
                    if(yAxisDef && yAxis) yAxis.setTitle(yAxisDef.title);
                }
            }
        } else {
            let yAxisCounter = 0;
            for(const yAxisDef of graphObject.yAxis) {
                const chartIndex = Math.floor(yAxisCounter / 2);
                const yAxisIndex = yAxisCounter % 2;

                const yAxis = lcChart.axisYFor(chartIndex, yAxisIndex);
                if(yAxisDef && yAxis) yAxis.setTitle(yAxisDef.title);

                yAxisCounter++;
            }
        }

        this.createEventSeries(lcChart, graphObject.eventSeries);
        this.assignAnnotationSeries(lcChart, graphObject.annotationSeries);

        lcChart.subscribeToData(config.seriesData$);

        return lcChart;
    }

    private assignAnnotationSeries(lcChart: LightningChartObject, series: GraphAnnotationSerieConfiguration[]) {
        lcChart.annotationSeries = series;
    }

    private createEventSeries(lcChart: LightningChartObject, series: GraphEventSerieConfiguration[]) {
        if(!series || !series.length) return;

        lcChart.eventSeries = series;
        lcChart.eventChartSeries = [];
        for(const eventDef of series) {
            this.addPlotBands(lcChart, eventDef);
        }
    }

    public createAnnotationSeries(lcChart: LightningChartObject, series: Array<GraphAnnotationSerieConfiguration>) {
        if(!series) return;

        for(const serieDef of series) {
            let miny = Number.MAX_VALUE;
            let maxy = Number.MIN_VALUE;

            for(const hgDataPoint of serieDef.data) {
                const y = hgDataPoint.y;
                if(miny > y) miny = y;
                if(maxy < y) maxy = y;
            }

            if(miny > 0) miny = 0;

            maxy = CHART_SERIES_TOP_GAP * maxy;

            const {chartIndex, yAxisIndex} = lcChart.seriePlacement(serieDef);

            if(!lcChart.ySeriesExtremes[chartIndex][yAxisIndex]) {
                lcChart.ySeriesExtremes[chartIndex][yAxisIndex] = {miny: miny, maxy: maxy};
            } else {
                if(lcChart.ySeriesExtremes[chartIndex][yAxisIndex].miny > miny) lcChart.ySeriesExtremes[chartIndex][yAxisIndex].miny = miny;
                if(lcChart.ySeriesExtremes[chartIndex][yAxisIndex].maxy < maxy) lcChart.ySeriesExtremes[chartIndex][yAxisIndex].maxy = maxy;
            }
            // #38129 if custom ranges are set for depth or velocity, HG should not listen to the max of annotation settings.
            if (lcChart.customRanges) {
                let depthAndVelocityCustomRanges = lcChart.customRanges.filter(x => x.name === DEPTH || x.name === VELOCITY);
                let validRanges = depthAndVelocityCustomRanges.filter(x => x.max !== undefined);
                // if valid ranges has no length then Math.max will return -infinity.
                if (validRanges.length > 0) {
                    let maxCustomRange = Math.max(...validRanges.map(x => x.max))
                    if (maxCustomRange && maxCustomRange !== 0) {
                        lcChart.ySeriesExtremes[chartIndex][yAxisIndex].maxy = maxCustomRange;
                    }
                }
            }

            const serieData = this.seriesFactory.addAnnotationSerie(lcChart, serieDef, lcChart.customRangesForDisplayGroupId(serieDef.displayGroupId));

            if(!lcChart.chartSeries[chartIndex]) {
                lcChart.chartSeries[chartIndex] = [];
                lcChart.seriesDef[chartIndex] = [];
            }

            lcChart.chartSeries[chartIndex].push(serieData);
        }
    }

    public createSeries(lcChart: LightningChartObject, series: Array<BasicSeriesData>) {
        if(!series || !series.length) return;

        const updatedMap = this.dataEditService.getAllAppliedEdits();

        let lastX = null;
        let minXinterval = Number.MAX_SAFE_INTEGER;
        let i = 0;

        for(const serieDef of series) {
            const serie: LightningChartSerie = {
                entityName: serieDef.entityName,
                displayGroupId: serieDef.displayGroupId,
                entityId: serieDef.entityId,
                yAxis: lcChart.seriesDefition[serieDef.entityId]?.yAxis,
                data: [],
                color: serieDef.color,
                shape: lcChart.seriesDefition[serieDef.entityId]?.shape,
                seriesType: this.hydrographDataBuilder.seriesTypeForDef(serieDef),
                showInZoomBand: i === 0
            };
            serie.hasLegend ??= lcChart.seriesDefition[serie.entityId]?.hasLegend;

            let miny = Number.MAX_VALUE;
            let maxy = Number.MIN_VALUE;

            let minx = Number.MAX_SAFE_INTEGER;
            let maxx = Number.MIN_SAFE_INTEGER;

            if(!serieDef.data) continue;

            let sendToFront = false;

            const fromUpdateEnt = updatedMap && updatedMap.get(serieDef.entityId);

            for(const hgDataPoint of serieDef.data) {
                const x = hgDataPoint.x;
                let y = hgDataPoint.y;
                if(y === undefined || y === null || isNaN(y)) continue;

                const fromUpdatePoint = fromUpdateEnt ? fromUpdateEnt.get(x) : null;
                const isUpdated = fromUpdatePoint && fromUpdatePoint.y !== hgDataPoint.y && hgDataPoint.correctedY !== undefined && hgDataPoint.correctedY !== null;
                const isFlagged = hgDataPoint.flagged || (fromUpdatePoint && fromUpdatePoint.flagged);
                const isIgnored = hgDataPoint.ignored;

                // #37940 Point is edited when it is edited or #37925 snapped. Should appear as pink.
                const isEdited = !hgDataPoint.flagged && (hgDataPoint.edited || hgDataPoint.snapped || isUpdated);// || hgDataPoint.y !== hgDataPoint.correctedY;

                if (isEdited && hgDataPoint.correctedY !== undefined && hgDataPoint.correctedY !== null) {
                    y = hgDataPoint.correctedY
                };

                if(miny > y) miny = y;
                if(maxy < y) maxy = y;

                if(minx > x) minx = x;
                if(maxx < x) maxx = x;

                if(lastX) {
                    if(x - lastX < minXinterval && x > lastX) {
                        minXinterval = x - lastX;
                    }
                }

                // #37228 If a gap between data is above constant period then do not join lines
                if(lastX && x && x - lastX > MAX_X_JOIN_LINE_RANGE && lcChart.dataAveraging !== DATA_AVERAGING_DAILY) {
                    const pointStartNullRange: LightningChartDataPoint = {
                        x: lastX + 1,
                        y: undefined,
                        value: 0
                    };
                    serie.data.push(pointStartNullRange);

                    const pointEndNullRange: LightningChartDataPoint = {
                        x: x - 1,
                        y: undefined,
                        value: 0
                    };
                    serie.data.push(pointEndNullRange);
                }

                lastX = x;

                const isConfirmationSerie = serie.entityId < 0;

                // #36776 do not display flagged points if not in edit mode
                if(isFlagged) {
                    if(!isConfirmationSerie && !lcChart.showEditingMenu()) {
                        const point: LightningChartDataPoint = {
                            x: hgDataPoint.x,
                            y: undefined,
                            value: 0
                        };
                        serie.data.push(point);
                        continue;
                    }
                }

                let value = null;
                if(isConfirmationSerie) {
                    value = isFlagged ? LCValues.Flagged : LCValues.Data;
                } else if(lcChart.annotations?.isDataQuality) {
                    value = this.hydroService.getDataQualityIndex(hgDataPoint.quality);
                } else {
                    value = !lcChart.showEditingMenu()
                        ? (isIgnored ? LCValues.Ignored : LCValues.Data)
                        : hgDataPoint.selected ? LCValues.Selected
                        : isFlagged ? LCValues.Flagged
                        : isEdited ? LCValues.Edited
                        : isIgnored ? LCValues.Ignored
                        : LCValues.Data;
                }

                if(lastX) {
                    if(x - lastX < minXinterval && x > lastX) {
                        minXinterval = x - lastX;
                    }
                }
                lastX = x;

                sendToFront = sendToFront || (value === LCValues.Selected);

                const point: LightningChartDataPoint = {
                    x: hgDataPoint.x,
                    y: y,
                    value: value
                };

                serie.data.push(point);
            }

            lcChart.dataIntervalSize = minXinterval;

            if(miny > 0) miny = 0;

            maxy = CHART_SERIES_TOP_GAP * maxy;

            if(!lcChart.xExtremes) lcChart.xExtremes = {minx: minx, maxx: maxx};
            if(lcChart.xExtremes.minx > minx) lcChart.xExtremes.minx = minx;
            if(lcChart.xExtremes.maxx < maxx) lcChart.xExtremes.maxx = maxx;


            const placement = lcChart.seriePlacement(serie);
            if(!placement) continue;

            const {chartIndex, yAxisIndex} = placement;
            if(!lcChart.ySeriesExtremes[chartIndex]) lcChart.ySeriesExtremes[chartIndex] = [];

            if(!lcChart.ySeriesExtremes[chartIndex][yAxisIndex]) {
                lcChart.ySeriesExtremes[chartIndex][yAxisIndex] = {miny: miny, maxy: maxy};
            } else {
                if(lcChart.ySeriesExtremes[chartIndex][yAxisIndex].miny > miny) lcChart.ySeriesExtremes[chartIndex][yAxisIndex].miny = miny;
                if(lcChart.ySeriesExtremes[chartIndex][yAxisIndex].maxy < maxy) lcChart.ySeriesExtremes[chartIndex][yAxisIndex].maxy = maxy;
            }
            const chartSerie = this.addSerie(lcChart, serie);

            if (chartSerie) {
                setTimeout(() => {
                    const editingEntityId = lcChart?.edit?.editingParams?.entity?.entityId;
                    const defaultDrawOrder = serieDef.entityId === editingEntityId ? 2 : 1;
                    // #36798 Maintain visiblity of entity on chart
                    chartSerie.setVisible(lcChart.seriesDefition[serieDef.entityId]?.visible ?? true);

                    // #36694 Serie with selected points should be at front
                    chartSerie.setDrawOrder({ seriesDrawOrderIndex: sendToFront ? 10 : defaultDrawOrder });
                }, 0);
            }

            i++;
        }


        for(const chart of lcChart.charts) {
            this.applyTickStrategy(chart.getDefaultAxisX(), lcChart)
        }
    }

    public setDefaultIntervals(lcChart: LightningChartObject) {
        // #37895 TODO: REMOVE SETTIMEOUT - Workaround for not displaying full range
        setTimeout(() => {
            for(const chart of lcChart.charts) {
                chart.getDefaultAxisX().setInterval({start: lcChart.xExtremes.minx, end: lcChart.xExtremes.maxx, stopAxisAfter: false});
            }
        }, 10);
    }

    private createZoomBandChart(lcChart: LightningChartObject, darkTheme: boolean, chartCount: number) {
        lcChart.zoomBandChart = lcChart.dashboard.createZoomBandChart({
            columnIndex: 0,
            columnSpan: 1,
            rowIndex: chartCount * 2,
            rowSpan: 1,
            animationsEnabled: false,
            defaultAxis: {
                type: 'linear-highPrecision'
            }
        })
        lcChart.zoomBandChart.setTitle('');

        if(darkTheme) {
            lcChart.zoomBandChart.setSeriesBackgroundFillStyle(new SolidFill({color: ColorHEX(LC_ZOOM_BAND_SERIES_BACKGROUND)}));
            lcChart.zoomBandChart.setDefocusOverlayFillStyle(new SolidFill({color: ColorHEX(LC_ZOOM_BAND_OVERLAY)}));
        }
    }

    private applyXAxisTimezone(tickStrategy: DateTimeTickStrategy, lcChart?: LightningChartObject): DateTimeTickStrategy {
        const FORMAT_YMD: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
        const FORMAT_YMDHM: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC' };
        const FORMAT_HM: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' };

        const DAYS_IN_YEAR = 365 * 24 * 60 * 60 * 1000;
        let isYear = lcChart && lcChart.xExtremes && lcChart.xExtremes.maxx - lcChart.xExtremes.minx >= DAYS_IN_YEAR;

        if (!isYear) {
            delete FORMAT_YMD.year;
            delete FORMAT_YMDHM.year;
        }

        const formatTickDate = (value: number): string => {
            const date = new Date(value);

            // #39385 if it is not the first day of the month, it will use the last day, so we add one more day to move it to the next month
            if (date.getDate() !== 1) {
                date.setDate(date.getDate() + 1);
            }

            return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(date);
        };

        const formatMinorTickDate = (value: number): string => {
            const date = new Date(value);

            // #39385 if it is not the first day of the month, it will use the last day, so we add one more day to move it to the next month
            if (date.getDate() !== 1) {
                date.setDate(date.getDate() + 1);
            }

            return new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date) + " '" + new Intl.DateTimeFormat('en-US', { year: '2-digit' }).format(date);
        };

        return tickStrategy
            .setUTC(true)
            .setFormattingYear(
                formatTickDate,
                formatMinorTickDate,
            )
            .setFormattingMonth(undefined, FORMAT_YMD, FORMAT_YMD)
            .setFormattingWeek(undefined, FORMAT_YMD, FORMAT_YMD)
            .setFormattingDay(undefined, FORMAT_YMDHM, FORMAT_YMDHM)
            .setFormattingHour(undefined, FORMAT_HM, FORMAT_HM)
            .setFormattingMinute(undefined, FORMAT_HM, FORMAT_HM)
            .setFormattingSecond(undefined, FORMAT_HM, FORMAT_HM)
            .setFormattingMilliSecond(FORMAT_HM, FORMAT_HM);
    }

    private applyTickStrategy(axis: Axis, lcChart?: LightningChartObject): Axis {
        return axis.setTickStrategy(AxisTickStrategies.DateTime, ticks =>
                this.applyXAxisTimezone(ticks, lcChart)
                .setGreatTickStyle(emptyTick)
                .setMajorTickStyle((tickStyle: TickStyle) => tickStyle
                    .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: new SolidFill({ color: ColorHEX(LC_MAIN_CHART_MAJOR_TICK_COLOR) })})))
                .setMinorTickStyle((tickStyle: TickStyle) => tickStyle
                    .setGridStrokeStyle(new SolidLine({ thickness: 1, fillStyle: new SolidFill({ color: ColorHEX(LC_MAIN_CHART_MINOR_TICK_COLOR) })})))
            );
    }

    public createDragSerie(lcChart: LightningChartObject, serieDef: LightningChartSerie, chartIndex: number, serieIndex: number) {
        if(!lcChart.dragSeries) {
            lcChart.dragSeries = [];
        }
        if(!lcChart.dragSeries[chartIndex]) {
            lcChart.dragSeries[chartIndex] = [];
        }

        let dragSeries: LineSeries;

        if(!lcChart.dragSeries[chartIndex][serieIndex]) {
            const chart = lcChart.charts[chartIndex];
            const axes = lcChart.getXYaxisForEntity(serieDef.entityName);

            dragSeries = chart.addLineSeries({
                yAxis: axes.y
            });

            lcChart.dragSeries[chartIndex][serieIndex] = dragSeries;
        } else {
            dragSeries = lcChart.dragSeries[chartIndex][serieIndex];
        }

        dragSeries
            .setStrokeStyle(
                new SolidLine({
                    thickness: 2,
                    fillStyle: new SolidFill({ color: ColorHEX(serieDef.color) }),
                }));
    }

    private createChart(lcChart: LightningChartObject, config: LightningChartConfig, index: number, chartCount: number, yAxesCount: number): ChartXY {
        const rowSpan = 2;
        const rowIndex = rowSpan * index;
        // We need to define it as 'linear-highPrecision' to be able to zoom more: https://lightningchart.com/lightningchart-js-api-documentation/v4.1.0/interfaces/AxisOptions.html
        const chart = lcChart.dashboard.createChartXY({
            columnIndex: 0,
            rowIndex: rowIndex,
            columnSpan: 1,
            rowSpan: rowSpan,
            defaultAxisX: {
                type: 'linear-highPrecision'
            }
        });

        chart
            .setTitle('')
            .setPadding({ left: 0, top: 16, bottom: 0 })
            .setAutoCursorMode(AutoCursorModes.disabled)
            .setBackgroundStrokeStyle(emptyLine)
            .setMouseInteractionRectangleFit(false);

        this.applyTickStrategy(chart.getDefaultAxisX())
            .setAnimationScroll(false)
            .setStrokeStyle(emptyLine)
            .setNibStyle(emptyLine);

        const xAxis = chart.getDefaultAxisX();
        const yAxis = chart.getDefaultAxisY();

        const startTime = lcChart.startTime;
        const endTime = lcChart.endTime;

        /** #39770 Has to set animations to false to fix this one.
            Issue is that animation does tick every frame and calls ScrollStrategy start() and end() to resolve it is allowed.
            We have a workaround to present a gap for a chart due to first and last point bug in LC.
            We want to display this gap only at max zoom level.
            So the very first(s) animation tick does check ScrollStrategy if they are allowed
            However they do fall into GAP range, which is allowed only for max zoom.
            If we turn animations off, then there are no animations ticks, it goes imidiatelly to a zoom requested by user.
        */
        chart.setAnimationsEnabled(false);

        chart.onResize((obj, w, h, ew, eh) => {
            // #38929 Apply extra zoom pixels on zoom change
            if(lcChart.isExtraZoom()) {
                const interval = lcChart.getExtraZoomInterval();
                chart.getDefaultAxisX().setInterval({...interval, stopAxisAfter: false});
            }
        });

        xAxis.onStoppedStateChanged((obj, isStopped) => {
            // #39344 Mouse events does stop scroll strategy, we want it to be always ON as we use it to limit ranges
            /** This may need larger explanation, so here we go:
                Currently, most likely due to LC bug when we have synchronizeAxisIntervals then every time it does call setScrollStrategy.
                Issue is when there is just one chart, because we always do synchronizeAxisIntervals.
                When there is one chart then it does not go through setScrollStrategy.
                Limiting bounds with setScrollStrategy is a lot more straightforward and easier then doing the same with onIntervalChange.
                onIntervalChange request to be called recursive to achieve this goal.
                So during implementation it was all good until a point where we found out that it does not work with single charts.
                Currently we're waiting for a LC realease with margins on axes (we have it implemented in setScrollStrategy).
                Missing margins is also a reason why we do not use setIntervalRestrictions to limit bounds.
                Once they will deliver it then we should checkout if we should remove current workaround for it and switch to setIntervalRestrictions for bounds.
                */
            // TODO: Review this on LC update with margins for setIntervalRestrictions
            if(isStopped) xAxis.setStopped(false);
        });
        xAxis.setScrollStrategy({
            start: (scaleStart: number, scaleEnd: number, contentMin: number, contentMax: number) => {
                const extraZoomInterval = lcChart.getExtraZoomInterval();
                const isExtraZoomScaleStart = scaleStart > extraZoomInterval.start && scaleStart < extraZoomInterval.end;
                const isExtraZoomScaleEnd = scaleEnd < extraZoomInterval.end && scaleEnd > extraZoomInterval.start;
                const isExtraZoom = isExtraZoomScaleStart && isExtraZoomScaleEnd;

                const isScaleStart = scaleStart > startTime && scaleStart < endTime;
                const isScaleEnd = scaleEnd < endTime && scaleEnd > startTime;
                const isInScale = isScaleStart && isScaleEnd;

                if(!isInScale && isExtraZoom) return isScaleStart ? scaleStart : startTime;
                if(!isInScale && !isExtraZoom) return extraZoomInterval.start;

                return scaleStart;

            },
            end: (scaleStart: number, scaleEnd: number, contentMin: number, contentMax: number) => {

                const extraZoomInterval = lcChart.getExtraZoomInterval();
                const isExtraZoomScaleStart = scaleStart > extraZoomInterval.start && scaleStart < extraZoomInterval.end;
                const isExtraZoomScaleEnd = scaleEnd < extraZoomInterval.end && scaleEnd > extraZoomInterval.start;
                const isExtraZoom = isExtraZoomScaleStart && isExtraZoomScaleEnd;

                const isScaleStart = scaleStart > startTime && scaleStart < endTime;
                const isScaleEnd = scaleEnd < endTime && scaleEnd > startTime;
                const isInScale = isScaleStart && isScaleEnd;

                if(!isInScale && isExtraZoom) return isScaleEnd ? scaleEnd : endTime;
                if(!isInScale && !isExtraZoom) return extraZoomInterval.end;

                return scaleEnd;
            },
            allowIntervalLengthChange: true
        });

        lcChart.installxAxisOnIntervalChange(xAxis, yAxis, index);

        const yAxes = [];
        yAxes[0] = chart.getDefaultAxisY();

        this.setupYAxis(chart.getDefaultAxisY());

        chart.getDefaultAxisY().setTickStrategy(AxisTickStrategies.Numeric,
            ticks => ticks
                .setMinorTickStyle(emptyTick)
                .setFormattingFunction((value: number, range: FormattingRange, locale?: string) => value > 10000 ? value.toExponential(1) : `${value}`
        ));

        if(yAxesCount > 1) {
            yAxes[1] = chart.addAxisY({opposite: true});
            this.setupYAxis(yAxes[1]);
            lcChart.yAxes[index] = yAxes[1];

            chart.setPadding({ right: 0 })
        } else {
            chart.setPadding({ right: LC_YAXIS_THICKNESS_RIGHT })
        }

        lcChart.installChartEvents(chart, index);

        this.seriesFactory.createSelectionSeries(lcChart, chart, index);

        return chart;
    }

    private setupYAxis(yAxis: Axis) {
        yAxis
            .setTitleFont(new FontSettings({size: Y_AXIS_FONT_SIZE}))
            .setChartInteractions(false)
            .setNibInteractionScaleByDragging(false)
            .setNibInteractionScaleByWheeling(false)
            .setAxisInteractionReleaseByDoubleClicking(false)
            .setAxisInteractionPanByDragging(false)
            .setAxisInteractionZoomByDragging(false)
            .setAnimationScroll(undefined)
            .setThickness(LC_YAXIS_THICKNESS_LEFT)
            .setAxisInteractionZoomByWheeling(false);

        // #36673 format Y axes values in scientific notation if they are greater then margin
        yAxis.setTickStrategy(AxisTickStrategies.Numeric,
            ticks => ticks
                .setMinorTickStyle(emptyTick)
                .setFormattingFunction((value: number, range: FormattingRange, locale?: string) => value > 10000 ? value.toExponential(1) : `${value}`
        ));
    }

    private addSerie(lcChart: LightningChartObject, serieDef: LightningChartSerie): SeriesXY {
        let serieData: SeriesXY = null;

        const {chartIndex} = lcChart.seriePlacement(serieDef);

        const customRanges = lcChart.customRangesForEntity(serieDef.entityId);

        switch(serieDef.seriesType) {
            case ChartSerieType.Column:
                serieData = this.seriesFactory.addRectangleSerie(lcChart, serieDef, customRanges);
                break;
            case ChartSerieType.ScatterOiriginalEdits:
            case ChartSerieType.ScatterConfirmation:
                serieData = this.seriesFactory.addScatterSerie(lcChart, serieDef, customRanges);
                break;
            case ChartSerieType.Line:
            default:
                if(lcChart.userSettings && lcChart.userSettings.markDataPoints && serieDef.entityId !== null && serieDef.entityId !== undefined ) {
                    serieData = this.seriesFactory.addPointLineSerie(lcChart, serieDef, customRanges);
                } else {
                    serieData = this.seriesFactory.addLineSerie(lcChart, serieDef, customRanges);
                }
                break;
        }

        if(!lcChart.seriesDef[chartIndex]) {
            lcChart.seriesDef[chartIndex] = [];
        }
        lcChart.seriesDef[chartIndex].push(serieDef);

        if(!lcChart.chartSeries[chartIndex]) {
            lcChart.chartSeries[chartIndex] = [];
        }
        lcChart.chartSeries[chartIndex].push(serieData);
        this.createDragSerie(lcChart, serieDef, chartIndex, lcChart.chartSeries[chartIndex].length - 1)

        if(serieData) {
            const chart = serieData.chart;
            serieData.onMouseMove(lcChart.createMouseMoveHandler(chart, lcChart));
        }

        return serieData;
    }

    private addPlotBands(lcChart: LightningChartObject, eventDef: GraphEventSerieConfiguration) {
        const serieData = eventDef.data;
        if(serieData) {
            const chart = lcChart.charts[0];
            const xAxis = chart.getDefaultAxisX();

            lcChart.eventChartSeries[eventDef.etype] = [];

            for(const entry of serieData) {
                if(entry.x && entry.xto) {
                    const band = xAxis.addBand(false);
                    band.setFillStyle(new SolidFill({color: ColorHEX(eventDef.color + '66')}));
                    band.setValueStart(entry.x).setValueEnd(entry.xto);
                    band.setMouseInteractions(false);
                    lcChart.eventChartSeries[eventDef.etype].push(band);

                    const eventButton = chart.addUIElement(
                        UIElementBuilders.CheckBox
                            .setButtonShape(PointShape.Square)
                        ,
                        { x: chart.getDefaultAxisX(), y: chart.getDefaultAxisY() })
                        .setText("")
                        .setButtonSize(16)
                        .setPosition({x: entry.x, y: 0})
                        .setOrigin(UIOrigins.Center)
                        .setVisible(true)
                        .setButtonOnFillStyle(emptyFill)
                        .setButtonOffFillStyle(emptyFill)
                        .setDraggingMode(UIDraggingModes.notDraggable)

                    const eventIcon = new Image();
                    eventIcon.src =  eventDef.eventSymbol.substring(0, eventDef.eventSymbol.length - 1);

                    eventIcon.onload = () => {
                        eventButton.setBackground(
                            (background) => background.setFillStyle(new ImageFill({
                                source: eventIcon,
                            }))
                        )};

                    eventButton.onMouseClick((click) => {
                        if(lcChart.lightningChartReceiver.showEvent) {
                            lcChart.lightningChartReceiver.showEvent(entry.guid);
                        }
                    });

                    eventButton.onMouseEnter((element) => {
                        lcChart.tooltip?.displayEventTooltip(element, entry);
                    });

                    eventButton.onMouseLeave(() => {
                        lcChart.tooltip?.tooltipHide();
                    })
                }
            }
        }
    }

    private generateEdit(lcChart: LightningChartObject, allowEditMode: boolean) {
        if(!allowEditMode) return;

        if(!lcChart.showEditingMenu()) {
            if(lcChart.edit !== null && lcChart.edit !== undefined) {
                lcChart.edit.clearEditingParams();
            }
        }

        lcChart.edit ??= new LightningChartEdit(lcChart);
        lcChart.edit.editService = this.editService;
    }

    private generateTooltip(lcChart: LightningChartObject, darkTheme: boolean) {
        if(lcChart.tooltip !== null && lcChart.tooltip !== undefined) return;

        lcChart.tooltip = new LightningChartTooltip(lcChart);

        lcChart.tooltip.tooltipDateFormat = this.createTooltipDateFormat();

        lcChart.tooltip.tooltipBox = document.createElement('div');
        lcChart.dashboard.engine.container.appendChild(lcChart.tooltip.tooltipBox);

        LightningChartBuilder.applyStyles(lcChart.tooltip.tooltipBox, darkTheme ? TOOLTIP_BOX_DM_STYLES : TOOLTIP_BOX_STYLES);

        lcChart.tooltip.htmlContent = document.createElement('div');
        lcChart.tooltip.tooltipBox.appendChild(lcChart.tooltip.htmlContent);
        lcChart.dashboard.engine.container.append(lcChart.tooltip.tooltipBox);

        lcChart.tooltip.info = document.createElement('div');
        LightningChartBuilder.applyStyles(lcChart.tooltip.info, darkTheme ? TOOLTIP_INFO_DM_STYLES : TOOLTIP_INFO_STYLES);
        lcChart.dashboard.engine.container.append(lcChart.tooltip.info);

        lcChart.tooltip.showCursorAt = (chart, clientCoords, nearestDataPoints) => {
            lcChart.tooltip.tooltipShow(chart, clientCoords, nearestDataPoints);
        }

        lcChart.tooltip.itTooltipStatic = this.usersService.staticTracerSetting.getValue();

        lcChart.tooltip.translations = {
            description: this.translate.instant('COMMON.DESCRIPTION'),
            duration: this.translate.instant('COMMON.DURATION'),
            started: this.translate.instant('COMMON.STARTED'),
        }
    }

    public static applyStyles(element: HTMLDivElement , styles: Partial<CSSStyleDeclaration>) {
        for (const [key, value] of Object.entries(styles)) {
            element.style[key] = value;
        }
    }

    public enableJumpScroll(btn: UITextBox<UIBackground> & UIElement, isEnabled: boolean, darkTheme: boolean) {
        btn.setMouseInteractions(isEnabled);
        btn.setMouseStyle(isEnabled ? MouseStyles.Point : MouseStyles.Default);

        let textStyle: SolidFill;

        if (isEnabled && darkTheme) {
            textStyle = JUMP_SCROLL_FONT_ENABLED_DARK;
        } else if (isEnabled) {
            textStyle = JUMP_SCROLL_FONT_ENABLED;
        } else {
            textStyle = JUMP_SCROLL_FONT_DISABLED;
        }

        btn.setTextFillStyle(textStyle);
    }

    private applyDefaultJumpScrollOptions(btn: UITextBox<UIBackground> & UIElement, darkTheme: boolean) {
        btn.setDraggingMode(UIDraggingModes.notDraggable);
        btn.setTextFont((font) => font.setSize(JUMP_SCROLL_FONT_SIZE));
        this.enableJumpScroll(btn, true, darkTheme);
    }

    private generateJumpScrolls(lcChart: LightningChartObject, darkTheme: boolean) {
        const dashboard = lcChart.dashboard;
        const boundingClientRect = dashboard.engine.container.getBoundingClientRect();
        const width = boundingClientRect.right - boundingClientRect.left;


        lcChart.jumpScrollBtnLeft = dashboard.addUIElement(UIElementBuilders.TextBox, dashboard.coordsRelative)
            .setText("<");

        lcChart.jumpScrollBtnRight = dashboard.addUIElement(UIElementBuilders.TextBox, dashboard.coordsRelative)
            .setText(">");

        this.applyDefaultJumpScrollOptions(lcChart.jumpScrollBtnLeft, darkTheme);
        this.applyDefaultJumpScrollOptions(lcChart.jumpScrollBtnRight, darkTheme);

        lcChart.installJumpScrollListeners();
    }

    public mapWithIgnoredPoints(
        entities: BasicSeriesData[],
        ignored: EntityData[],
        filterSeriesFn: (arg: BasicSeriesData[]) => BasicSeriesData[]
    ) {
        const filteredEntities = filterSeriesFn(entities);

        if(ignored && ignored.length) {
            const xId = ignored[0].xId;
            const yId = ignored[0].yId;
            const xAssoc = [];
            const yAssoc = [];

            ignored.forEach((point) => {
                xAssoc[point.dateTime as number] = true;
                yAssoc[point.dateTime as number] = true;
            });

            // Has to clone entities, otherwise changes are made to original data source
            const clonedEntities: BasicSeriesData[] = JSON.parse(JSON.stringify(filteredEntities));
            for(const entityData of clonedEntities) {
                if(entityData.entityId !== xId && entityData.entityId !== yId) continue;

                const assoc = entityData.entityId === xId ? xAssoc : yAssoc;

                entityData.data.forEach(point => point.ignored = !!assoc[point.x]);
            };

            return clonedEntities;
        } else {
            return filteredEntities;
        }
    }
}


