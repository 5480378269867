<div id="FlowMonitoringReportId">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin no-text-case">{{ 'FLOW_MONITORING.TITLE' | translate }} {{ 'SLICER_PAGE.DASHBOARD' | translate }}</div>
            <div class="app-flex-filler"></div>
        </mat-card-header>
        <mat-divider></mat-divider>
        <div *ngIf="isLoadingState; else fmrTemplateTable">
            <app-loader [isLoading]="isLoadingState"></app-loader>
        </div>
        <ng-template #fmrTemplateTable>
            <mat-card-content class="sliicer-study-dashboard-content">
                <ng-container *ngIf="!showTemplateDashboard">
                    <div class="fmr-dashboard-new-page">
                        <div class="sliicer-no-studies">
                            <img
                                class="slicer_image"
                                [ngSrc]="'assets/images/slicer_empty_dashboard_image.png'"
                                alt="image"
                                height="216"
                                width="209"
                            >
                            <div class="page-content">
                                <h3>{{ 'SLICER_PAGE.EMPTY' | translate }}</h3>
                                <p class="lines">
                                    <b>{{ 'FLOW_MONITORING.ADD_REPORT' | translate }}</b> <i class="material-icons">add</i
                                    ><b>{{ 'SLICER_PAGE.BUTTON' | translate }}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div [hidden]="!showTemplateDashboard">
                    <div class="dashboard-search-container">
                        <!-- mat input form  -->
                        <mat-form-field class="full-width case-study-search-container">
                            <mat-icon matPrefix class="search-icon">search</mat-icon>
                            <input matInput placeholder="Search" class="full-width" [formControl]="templateSearch" />
                            <mat-icon class="clickable" matTooltip="Clear" matSuffix (click)="clearSearch($event)">clear</mat-icon
                        >
                        </mat-form-field>

                        <mat-checkbox [(ngModel)]="listMyTemplatesOnly" (ngModelChange)="onShowMyTemplatesChecked()">
                            {{ 'FLOW_MONITORING.LIST_MY_TEMPLATES' | translate }}
                        </mat-checkbox>
                    </div>

                    <!-- mat table for fmr template dashboard  -->
                    <div id="fmrtable">
                        <mat-table #table [dataSource]="fmrTemplateDataSource" matSort>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                    'FLOW_MONITORING.FMR_NAME' | translate
                                }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Author">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                    'FLOW_MONITORING.FMR_AUTHOR' | translate
                                }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.createdBy }}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="FmrDates">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                    'FLOW_MONITORING.FMR_DATES' | translate
                                }}</mat-header-cell>
                                <!-- <mat-cell *matCellDef="let element">{{ element.fmrStartDate | date: customerDateFormat }} to {{ element.fmrEndDate | date: customerDateFormat }} </mat-cell> -->
                                <mat-cell *matCellDef="let element">{{ element?.FmrDates }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="createdDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'FLOW_MONITORING.CREATED' | translate }}</mat-header-cell
                                >
                                <mat-cell
                                    *matCellDef="let element"
                                >
                                    <span *ngIf="element.createdDate">{{
                                        element.createdDate | date: customerDateFormat
                                    }}</span>
                                    <span *ngIf="!element.createdDate">-</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="modifiedDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{ 'FLOW_MONITORING.LAST_MODIFIED' | translate }}</mat-header-cell
                                >
                                <mat-cell
                                    *matCellDef="let element"
                                >
                                    <span *ngIf="element.modifiedDate">{{
                                        element.modifiedDate | date: customerDateFormat
                                    }}</span>
                                    <span *ngIf="!element.modifiedDate">-</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="editTemplate">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'FLOW_MONITORING.EDIT' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <button mat-button matTooltip="{{ 'FLOW_MONITORING.EDIT_TEMPLATE' | translate }}" (click)="editFMRTemplate(element)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="cloneTemplate">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'FLOW_MONITORING.CLONE' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">  
                                    <button mat-button matTooltip="{{ 'FLOW_MONITORING.CLONE_TEMPLATE' | translate }}" (click)="cloneFMRTemplate(element)">                    
                                        <mat-icon>file_copy</mat-icon>
                                    </button>  
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="deleteTemplate">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'FLOW_MONITORING.DELETE' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <button
                                        mat-button matTooltip="{{ 'FLOW_MONITORING.DELETE_TEMPLATE' | translate }}"
                                        (click)="deleteFMRTemplate(element)"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="generateTemplate">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'FLOW_MONITORING.GENERATE' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <button
                                        mat-button matTooltip="{{ 'FLOW_MONITORING.GENERATE_TEMPLATE' | translate }}"
                                        (click)="generateFMRTemplate(element)"
                                    >
                                        <mat-icon>feed</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'FLOW_MONITORING.STATUS' | translate }}</mat-header-cell
                                >
                                <mat-cell *matCellDef="let element">
                                    <button
                                        mat-button matTooltip="{{ 'FLOW_MONITORING.DOWNLOAD_TEMPLATE' | translate }}"
                                        (click)="downloadFMRTemplate(element)"
                                    >
                                        <mat-icon>file_download</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="fmrTemplateColumns"></mat-header-row>
                            <mat-row
                                *matRowDef="let row; columns: fmrTemplateColumns"
                            >
                            </mat-row>
                        </mat-table>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions>
                <div>
                    <button
                        mat-fab
                        color="accent"
                        id="addNewfmrTemplate"
                        matTooltip="{{ 'FLOW_MONITORING.ADD_NEWTEMPLATE' | translate }}"
                        (click)="addNewfmrTemplate()"
                        matTooltipPosition="above"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div class="app-flex-filler"></div>
                <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
                    <mat-paginator
                        #caseStudySummaryPaginator
                        [length]="totalPaginationLength"
                        [pageIndex]="0"
                        [pageSize]="25"
                        [pageSizeOptions]="[10, 25, 100]"
                    >
                    </mat-paginator>
                </div>
            </mat-card-actions>
        </ng-template>
    </mat-card>
</div>
