<div>
    <div *ngIf="isLoading">
        <app-loader [isLoading]="isLoading"></app-loader>
    </div>

    <div class="location-info-text" *ngIf="overviewReport">
        <span>{{ 'BLOCKAGE_PREDICT.REVIEW' | translate }}: {{ overviewReport.processingErrorText }} ({{ overviewReport.reviewSitesText }})</span>
    </div>

    <mat-card [ngClass]="{ 'dialog-format': !!dialogData }">
        <mat-card-header>
            <app-custom-paginator *ngIf="overviewReport && overviewReport.sites && overviewReport.sites.length"
                (pageChange)="pageChange($event)" [currentPage]="selectedReportIndex + 1"
                [totalPages]="overviewReport.sites.length" [visiblePagesLimit]="15">
            </app-custom-paginator>
            <div class="dialog-header" *ngIf="!!dialogData">
                <div></div>
                <h3>{{ 'BLOCKAGE_PREDICT.HUMAN_REVIEW' | translate }}</h3>
                <div class="custom-flat-button">
                    <button mat-button tabindex="-1" (click)="closeDialog.emit()">
                        {{ 'COMMON.CLOSE' | translate }}
                    </button>
                </div>
            </div>
        </mat-card-header>

        <mat-card-content *ngIf="!locationReport" class="no-data-message">
            <div class="flex items-center justify-center p-8 text-center">
                <span class="material-symbols-outlined mr-2">info</span>
                <span>{{ 'BLOCKAGE_PREDICT.NO_DATA_MSG' | translate }}</span>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="locationReport">
            <div class="report-title-container">
                <span class="location-score transparent">{{ 'BLOCKAGE_PREDICT.SCORE' | translate }}: {{ reportScore }}</span>
                <div class="report-title-text">
                    <span class="location-info">{{ locationReport.custname }} |
                        {{ locationReport.lname }} |
                        ({{ locationReport.cid }} {{ locationReport.lid }}) |
                        {{ locationReport.ms }} |
                        {{ reportDate | date : bpDateFormat }}
                        {{ locationReport.ldesc ? ('| ' + locationReport.ldesc) : '' }}
                    </span>
                </div>
                <span class="location-score" [ngStyle]="{ 'color': reportScoreColor }">{{ 'BLOCKAGE_PREDICT.SCORE' | translate }}: {{ reportScore }}</span>
            </div>

            <div class="hr-legends">
                <div class="main-legends">
                    <ng-container *ngIf="hydrographLegends && hydrographLegends.length">
                        <span class="legend-item" *ngFor="let legend of hydrographLegends; trackBy: trackByIndex">
                            <div [ngStyle]="{'background': legend.color }" 
                            [ngClass]="{
                                'line-legend': legend.shape === legendShapes.Line, 
                                'square-legend': legend.shape === legendShapes.Square, 
                                'dot-legend': legend.shape === legendShapes.Dot 
                            }" ></div>
                            <span>{{ legend.name }}</span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="bargraphLegends && bargraphLegends.length">
                        <span class="legend-item" *ngFor="let legend of bargraphLegends; trackBy: trackByIndex">
                            <div [ngStyle]="{'background': legend.color }" 
                            [ngClass]="{
                                'line-legend': legend.shape === legendShapes.Line, 
                                'square-legend': legend.shape === legendShapes.Square, 
                                'dot-legend': legend.shape === legendShapes.Dot 
                            }"></div>
                            <span>{{ legend.name }}</span>
                        </span>
                    </ng-container>
                </div>
                <div *ngIf="scattergraphLegens && scattergraphLegens.length" class="scatter-legends">
                    <span class="legend-item" *ngFor="let legend of scattergraphLegens; trackBy: trackByIndex">
                        <div [ngStyle]="{'background': legend.color }" 
                        [ngClass]="{
                            'line-legend': legend.shape === legendShapes.Line, 
                            'square-legend': legend.shape === legendShapes.Square, 
                            'dot-legend': legend.shape === legendShapes.Dot 
                        }"></div>
                        <span>{{ legend.name }}</span>
                    </span>
                </div>
            </div>
            <div class="flex">
                <div [ngClass]="{'w-60': displayScattergraph, 'full-width': !displayScattergraph }">
                    <div>
                        <app-bp-review-bar-graph *ngIf="locationReport && locationReport.days" [isDialog]="!!dialogData"
                            [data]="locationReport.days" (overrideLastDay)="overrideLastDay($event)"></app-bp-review-bar-graph>
                    </div>
                    <div>
                        <app-bp-hydro *ngIf="seriesData" [seriesData]="seriesData" [startTs]="graphMinDateTs"
                            [endTs]="graphMaxDateTs"></app-bp-hydro>
                    </div>
                </div>

                <div class="w-40" *ngIf="seriesData && displayScattergraph">
                    <app-bp-scattergraph [seriesData]="seriesData" [startTs]="graphMinDateTs"
                        [endTs]="graphMaxDateTs" [invertScatter]="invertScatter"></app-bp-scattergraph>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>